import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_ProductCostData,
    API_POST_ProductCostData,
    API_GET_HomeData
} from "../../global/constants";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, InitJS, SetOnbeforeunload, MenuSet, SetHasChange } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
import List from "../ProductCost/List";

const ProductCostView = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const [onbeforeunloadFlag, setOnbeforeunloadFlag] = useState(false);
    const [monthState, setMonthState] = useState(false); //月結狀態
    const [permission, setPermission] = useState(0);
    const [isDisabled, setIsDisabled] = useState(true);
    var onbeforeunloadFlag2 = false;

    useEffect(() => {
        //把menu顏色置換
        MenuSet("ProductCost");
        //第一次近來先執行抓取product資料
        getData();
        getOkta();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    useEffect(() => {
        if (onbeforeunloadFlag2 == false) {
            SetOnbeforeunload();
            onbeforeunloadFlag2 = true;
        }
    }, [onbeforeunloadFlag]);

    function getData() {

        var dt = {
            companyId: parseInt(queryParams.get("companyId")),
            yearMonth: queryParams.get("yearMonth"),
        }

        console.log("產品成本傳送資料", JSON.stringify(dt));
        API_GET_ProductCostData(dt)
            .then((response) => response.data)
            .then((data) => {
                console.log("產品成本回傳資料", data.result);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                if (data.code != "0000") {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }

                setCompanyId(data.result.data.companyId);
                setCompanyName(data.result.data.companyName);
                setCurrencyArray(data.result.data.currencyArray);
                setProductTypeArray(data.result.data.productTypeArray);
                setExchangeRate(data.result.data.exchangeRate);
                console.log("全部匯率", data.result.data.exchangeRate);
                setCompanyCurrencyId(data.result.data.companyCurrencyId);
                setData(data.result.data.data);
                console.log("成本列表", data.result.data.data)
                setCostYM(data.result.data.costYM);
                setMonthState(data.result.data.monthState);
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function getOkta() {
        var fromOkta_ = (localStorage.getItem("post_logout_redirect_uri") != null ? 1 : 0);
        API_GET_HomeData({ fromOkta: fromOkta_ }).then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                let okta = data.result;
                if (okta.isMonth1 == 1) {
                    setPermission(1);
                }
                if (okta.isMonth2 == 1) {
                    setPermission(2);
                }
            })
            .catch(err => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }
    //公司名稱
    const [companyName, setCompanyName] = useState();
    const [companyId, setCompanyId] = useState();
    //資料
    const [data, setData] = useState([]);
    const [costYM, setCostYM] = useState();
    const [currencyArray, setCurrencyArray] = useState([{}]);
    const [productTypeArray, setProductTypeArray] = useState([{}]);
    const [exchangeRate, setExchangeRate] = useState([{}]);
    const [companyCurrencyId, setCompanyCurrencyId] = useState();
    const [modalStr, setModalStr] = useState();
    //送出

    function submit(e) {
        e.target.classList.add("disabled");
        var result = checkData();
        //console.log(JSON.stringify(data));
        if (result) {
            //console.log(data);
            //延遲一下,避免addBalanceExplanation還沒塞成空字串
            setTimeout(function () {
                API_POST_ProductCostData(data)
                    .then((response) => response.data)
                    .then((data) => {
                        //console.log(data);
                        e.target.classList.remove("disabled");
                        if (!checkAuthToken(data)) {
                            history.push("/");
                        }
                        if (!CheckResponseCode(data)) { return; } //檢查response code
                        window.onbeforeunload = null;
                        setOnbeforeunloadFlag(false);
                        if (data.code == "0000") {
                            OpenModal("modal-success");
                        } else {
                            setModalStr(data.message);
                            OpenModal("modal-error");
                            SetHasChange(false);
                        }
                    })
                    .catch((err) => {
                        e.target.classList.remove("disabled");

                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push("/");
                        }
                    });
            }, 300);
        } else {
            e.target.classList.remove("disabled");
        }
    }

    function checkData() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {
            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });
        var arr = document.getElementsByClassName("error");
        if (arr.length > 0) {
            result = false;
        }
        setData(function (prev) {
            return prev.map((item) => {
                item.addBalanceExplanation =
                    item.addBalanceExplanation == null ? "" : item.addBalanceExplanation;
                return item;
            });
        });
        return result;
    }

    function goProuductNew() {
        history.push("/Main?path=ProductNew");
    }

    function reflash() {
        window.location.reload();
    }

    useEffect(() => {

        if (permission >= monthState)
            setIsDisabled(false);
        else
            setIsDisabled(true);

    }, [monthState, permission])

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container full">
                    <h3 className="fz-B has-border">{companyName}</h3>
                </div>
            </div>
            <div className="container full">
                <div className="info-holder">
                    <div className="left">成本月份：{costYM}</div>
                    <div className="right">
                        幣別：
                        {currencyArray.map((e) =>
                            e.currID == companyCurrencyId ? e.currName : null
                        )}
                    </div>
                </div>
                {
                    data.length == 0 ?
                        <div className="form-item btn-area sp">
                            <div className="left">
                                <a className="btn btn-border" onClick={() => history.push("/Main?path=ProductCost")}>
                                    <span>回查詢頁</span>
                                </a>
                            </div>
                        </div>
                        :
                        <>
                            <List
                                isDisabled={isDisabled}
                                exchangeRate={exchangeRate}
                                dataList={data}
                                currencyArray={currencyArray}
                                companyCurrencyId={companyCurrencyId}
                                productTypeArray={productTypeArray}
                                changeData={setData}
                                setFlag={setOnbeforeunloadFlag}
                            />
                            <div className="form-item btn-area sp">
                                <div className="left">
                                    <a className={`btn btn-border ${isDisabled == true ? "disabled" : ""}`} onClick={goProuductNew}>
                                        <span>新增成本項目</span>
                                    </a>
                                </div>
                                <div className="right">
                                    <a className={`btn ${isDisabled == true ? "disabled" : ""}`} onClick={submit}>
                                        <span>儲存</span>
                                    </a>
                                </div>
                            </div>
                        </>
                }



            </div>
            <div id="modal-success" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">儲存成功</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={reflash}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCostView;
