import React, { useState, useEffect } from "react";

import {
    API_GET_Customer3Name,
    API_GET_Vendor3Name,
    API_GET_AdNameList,
    API_GET_InterViewGetData,
    API_PUT_InterViewEdit,
} from "../../global/constants";
import { OpenModal, CloseModal, NumFormart, InitJS, SetOnbeforeunload, MenuSet, SetHasChange } from "../../global/ToolUtil";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const InterViewEdit = () => {
    var history = useHistory();
    var onbeforeunloadFlag = false;
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        //把menu顏色置換
        MenuSet("InterViewQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    const [tbid, setTbid] = useState();
    //幣別
    const [currencyArray, setCurrencyArray] = useState([{}]);
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyId, setCompanyId] = useState();
    const [companyError, setCompanyError] = useState(false);
    //身分別
    const [typeId, setTypeId] = useState();
    const [typeArray, setTypeArray] = useState([
        { id: 0, name: "客戶" },
        { id: 1, name: "供應商" },
    ]);
    const [typeIdError, setTypeIdError] = useState(false);

    const [custom3NameDisabled, setCustom3NameDisabled] = useState(true);

    //客戶別/供應商名稱
    const [iNameArray, setINameArray] = useState([{}]);
    const [iName, setIName] = useState();
    function iNameChange(e) {
        setIName(e.target.value);

        if (e.target.value == null || e.target.value == "") {
            setINameArray([]);
            return;
        }

        if (typeId == 0) {
            //客戶
            API_GET_Customer3Name({
                customerName: e.target.value,
                companyId: companyId
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.result.data != null) {
                        setINameArray(data.result.data);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else if (typeId == 1) {
            //供應商
            API_GET_Vendor3Name({
                vendorName: e.target.value,
                companyId: companyId
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setINameArray(data.result.data);
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }
    //客戶別/供應商抬頭
    const [iTitleArray, setITitleArray] = useState([{}]);
    const [iTitle, setITitle] = useState();
    function iTitleChange(e) {
        setITitle(e.target.value);

        if (e.target.value == null || e.target.value == "") {
            setITitleArray([]);
            return;
        }

        if (typeId == 0) {
            //客戶
            API_GET_Customer3Name({
                customerTitle: e.target.value,
                companyId: companyId
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.result.data != null) {
                        setITitleArray(data.result.data);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else if (typeId == 1) {
            //供應商

            API_GET_Vendor3Name({
                vendorTitle: e.target.value,
                companyId: companyId
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.result.data != null) {
                        setITitleArray(data.result.data);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }
    //客戶別/供應商抬頭
    const [iTaxIDArray, setITaxIDArray] = useState([{}]);
    const [iTaxID, setITaxID] = useState();
    function iTaxIDChange(e) {

        setITaxID(e.target.value);

        if (e.target.value == null || e.target.value == "") {
            setITaxIDArray([]);
            return;
        }

        if (typeId == 0) {
            //客戶
            API_GET_Customer3Name({
                customerTaxId: e.target.value,
                companyId: companyId
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.result.data != null) {
                        setITaxIDArray(data.result.data);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else if (typeId == 1) {
            //供應商
            API_GET_Vendor3Name({
                vendorTaxId: e.target.value,
                companyId: companyId
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.result.data != null) {
                        setITaxIDArray(data.result.data);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }
    //訪談日期
    const [interviewDate, setInterviewDate] = useState();
    //受訪客戶別供應商ID
    const [intervieweeID, setIntervieweeID] = useState();
    //訪談人員
    const [interviewPersonID, setInterviewPersonID] = useState();
    const [interviewPersonArray, setInterviewPersonArray] = useState([{}]);
    const [interviewPersonName, setInterviewPersonName] = useState();
    function interviewPersonNameChange(e) {

        setInterviewPersonName(e.target.value);
        //抓取list
        getInterviewPersonName();
    }
    //訪談人員
    function getInterviewPersonName() {

        API_GET_AdNameList({
            input: document.getElementById("interviewPersonNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setInterviewPersonArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //陪同人員
    const [accompniedPersonID, setAccompniedPersonID] = useState();
    const [accompniedPersonArray, setAccompniedPersonArray] = useState([{}]);
    const [accompniedPersonName, setAccompniedPersonName] = useState();
    function accompniedPersonNameChange(e) {

        setAccompniedPersonName(e.target.value);
        //抓取list
        getAccompniedPersonName();
    }
    //陪同人員
    function getAccompniedPersonName() {
        API_GET_AdNameList({
            input: document.getElementById("accompniedPersonNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setAccompniedPersonArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //訪談主旨
    const [interviewTitle, setInterviewTitle] = useState();
    //訪談內容
    const [interviewContent, setInterviewContent] = useState();
    //交通費
    const [interviewTravelFee, setInterviewTravelFee] = useState();
    //交通費幣別ID
    const [interviewTravelFeeCurr, setInterviewTravelFeeCurr] = useState("");
    //交通費備註
    const [interviewTravelFeeComment, setInterviewTravelFeeComment] = useState();
    //餐飲費
    const [interviewDiningFee, setInterviewDiningFee] = useState();
    //餐飲費幣別ID
    const [interviewDiningFeeCurr, setInterviewDiningFeeCurr] = useState("");
    //餐飲費備註
    const [interviewDiningFeeComment, setInterviewDiningFeeComment] = useState();
    //住宿費
    const [interviewAccomdFee, setInterviewAccomdFee] = useState();
    //住宿費幣別ID
    const [interviewAccomdFeeCurr, setInterviewAccomdFeeCurr] = useState("");
    //住宿費備註
    const [interviewAccomdFeeComment, setInterviewAccomdFeeComment] = useState();
    //其他費用
    const [interviewOtherFee, setInterviewOtherFee] = useState();
    //其他費用幣別ID
    const [interviewOtherFeeCurr, setInterviewOtherFeeCurr] = useState("");
    //其他費用備註
    const [interviewOtherFeeComment, setInterviewOtherFeeComment] = useState();
    //新增失敗訊息
    const [modalStr, setModalStr] = useState();

    ///Main?path=InterViewEdit&interviewId=10
    function getData() {
        API_GET_InterViewGetData({
            interviewId: parseInt(queryParams.get("interviewId")),
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    //幣別陣列
                    setCompanyArray(data.result.data.companyList);
                    setCurrencyArray(data.result.data.currList);
                    //-----------------------------------------
                    //id
                    setTbid(data.result.data.id);
                    //
                    setIntervieweeID(data.result.data.intervieweeId);
                    //公司
                    setCompanyId(data.result.data.companyId);
                    //身分別
                    setTypeId(data.result.data.typeId);
                    //客戶別/供應商
                    setIName(data.result.data.intervieweeName);
                    setITitle(data.result.data.intervieweeTitle);
                    setITaxID(data.result.data.intervieweeTaxId);
                    //訪談日期
                    setInterviewDate(
                        data.result.data.interviewDate != null
                            ? data.result.data.interviewDate.split("/")[0] +
                            "-" +
                            data.result.data.interviewDate.split("/")[1] +
                            "-" +
                            data.result.data.interviewDate.split("/")[2]
                            : ""
                    );
                    //訪談人員
                    setInterviewPersonID(data.result.data.interviewerId);
                    setInterviewPersonName(data.result.data.interviewer);
                    document.getElementById("interviewPersonNameText").value = data.result.data.interviewer;
                    //陪同人員
                    if (data.result.data.accompaniedId != null) {
                        setAccompniedPersonID(data.result.data.accompaniedId);
                        setAccompniedPersonName(data.result.data.accompaniedName);
                        document.getElementById("accompniedPersonNameText").value = data.result.data.accompaniedName;
                    }
                    //主旨內容
                    setInterviewTitle(data.result.data.interviewTitle);
                    setInterviewContent(data.result.data.interviewContent);
                    //交通費
                    setInterviewTravelFee(data.result.data.interviewTravelFee);
                    //交通費幣別
                    setInterviewTravelFeeCurr(data.result.data.interviewTravelCurrID);
                    //交通費備註
                    setInterviewTravelFeeComment(data.result.data.interviewTravelComment);
                    //餐飲費
                    setInterviewDiningFee(data.result.data.interviewDiningFee);
                    //餐飲費幣別
                    setInterviewDiningFeeCurr(data.result.data.interviewDiningCurrID);
                    //餐飲費備註
                    setInterviewDiningFeeComment(data.result.data.interviewDiningComment);
                    //住宿費金額
                    setInterviewAccomdFee(data.result.data.interviewAccomFee);
                    //住宿費幣別
                    setInterviewAccomdFeeCurr(data.result.data.interviewAccomCurrID);
                    //住宿費備註
                    setInterviewAccomdFeeComment(data.result.data.interviewAccomComment);
                    //其他金額
                    setInterviewOtherFee(data.result.data.interviewOtherFee);
                    //其他幣別
                    setInterviewOtherFeeCurr(data.result.data.interviewOtherCurrID);
                    //其他備註
                    setInterviewOtherFeeComment(data.result.data.interviewOtherComment);
                }
            });
    }

    //前往查詢
    function returnQry() {
        history.push("/Main?path=InterViewQry");
    }

    //送出
    function submit() {
        //檢查資料
        var result = checkData();
        if (interviewPersonID == accompniedPersonID) {
            setModalStr("訪談人員與陪同人員不可一樣");
            OpenModall("modal-error");
            result = false;
        }
        //如果資料檢查有通過 則送出
        if (result) {
            var dt = {
                id: tbid,
                companyId: companyId,
                typeId: typeId,
                interviewPersonID: interviewPersonID,
                intervieweeID: intervieweeID,
                interviewDate: interviewDate,
                accompniedPersonID: accompniedPersonID,
                interviewTitle: interviewTitle,
                interviewContent: interviewContent,
                interviewTravelFee: interviewTravelFee,
                interviewTravelFeeCurr: interviewTravelFeeCurr,
                interviewTravelFeeComment: interviewTravelFeeComment,
                interviewDiningFee: interviewDiningFee,
                interviewDininglFeeCurr: interviewDiningFeeCurr,
                interviewDiningFeeComment: interviewDiningFeeComment,
                interviewAccomdFee: interviewAccomdFee,
                interviewAccomdFeeCurr: interviewAccomdFeeCurr,
                interviewAccomdFeeComment: interviewAccomdFeeComment,
                interviewOtherFee: interviewOtherFee,
                interviewOtherFeeCurr: interviewOtherFeeCurr,
                interviewOtherFeeComment: interviewOtherFeeComment,
            };

            API_PUT_InterViewEdit(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        OpenModal("modal-success");
                        window.onbeforeunload = null;
                        onbeforeunloadFlag = false;
                        SetHasChange(false);
                    }
                    else {
                        setModalStr(data.message);
                        OpenModal("modal-error");
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                    else {
                        setModalStr("未知錯誤");
                        OpenModal("modal-error");

                    }
                });
        }
    }

    //資料檢查
    function checkData() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {
            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });
        if (companyId == undefined) {
            setCompanyError(true);
            result = false;
        } else {
            setCompanyError(false);
        }
        if (typeId == undefined) {
            setTypeIdError(true);
            result = false;
        } else {
            setTypeIdError(false);
        }
        return result;
    }

    function reflash() {
        window.location.reload();
    }

    useEffect(() => {
        if (companyId == null || companyId === '' || typeId == null || typeId === '')
            setCustom3NameDisabled(true);
        else {
            setCustom3NameDisabled(false);
        }
    }, [companyId, typeId])

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">{iName}</h3>
                </div>
            </div>
            <div className="container">
                <form action="" className="form-container">
                    <div className={`form-item must ${companyError ? " show-help" : ""}`}>
                        <div className="field">隸屬公司</div>
                        <div className="value">
                            {companyArray.map((x) => (
                                <div>
                                    <input
                                        type="radio"
                                        key={`company_${x.id}`}
                                        id={`company_${x.id}`}
                                        name="companyCheckBox"
                                        value={x.id}
                                        checked={companyId == x.id ? true : false}
                                        onClick={(e) => {
                                            setCompanyId(x.id)

                                            setIName("");
                                            setINameArray([]);

                                            setITitle("");
                                            setITitleArray([]);

                                            setITaxID("");
                                            setITaxIDArray([]);
                                        }}
                                    />
                                    <label htmlFor={`company_${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇隸屬公司</span>
                    </div>
                    <div
                        className={`form-item must half ${typeIdError ? " show-help" : ""}`}
                    >
                        <div className="field">身分別</div>
                        <div className="value">
                            {typeArray.map((x) => (
                                <div>
                                    <input
                                        type="radio"
                                        name="iType"
                                        id={`iType_${x.id}`}
                                        checked={typeId == x.id ? true : false}
                                        onClick={(e) => {
                                            setTypeId(x.id)
                                            
                                            setIName("");
                                            setINameArray([]);

                                            setITitle("");
                                            setITitleArray([]);

                                            setITaxID("");
                                            setITaxIDArray([]);
                                        }}
                                    />
                                    <label htmlFor={`iType_${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇身分別</span>
                    </div>
                    <div className="form-item must half has-datalist" data-datalist>
                        <div className="field">客戶別/供應商</div>
                        <div className="value multi-inline">
                            <input
                                type="text"
                                id="iNameText"
                                name="iName"
                                value={iName}
                                onChange={iNameChange}
                                disabled={custom3NameDisabled}
                                data-required
                            />
                            <ul className="datalist scrollbar">
                                {iNameArray.map((x) =>
                                    typeId == 0 ? (
                                        <li
                                            data-value={x.custName}
                                            onClick={(e) => {
                                                setIntervieweeID(x.custID),
                                                    setIName(x.custName),
                                                    setITitle(x.custTitle),
                                                    setITaxID(x.custTaxID);
                                            }}
                                        >
                                            <span className="flex-4 bold">{x.custName}</span>
                                            <span className="flex-3">{x.custTitle}</span>
                                            <span className="flex-3">{x.custTaxID}</span>
                                        </li>
                                    ) : (
                                        <li
                                            data-value={x.vendorName}
                                            onClick={(e) => {
                                                setIntervieweeID(x.vendorID),
                                                    setIName(x.vendorName),
                                                    setITitle(x.vendorTitle),
                                                    setITaxID(x.vendorTaxID);
                                            }}
                                        >
                                            <span className="flex-4 bold">{x.vendorName}</span>
                                            <span className="flex-3">{x.vendorTitle}</span>
                                            <span className="flex-3">{x.vendorTaxID}</span>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                        <span className="help-word">請輸入客戶別/供應商</span>
                    </div>
                    <div className="form-item must half has-datalist" data-datalist>
                        <div className="field">公司抬頭</div>
                        <div className="value">
                            <input
                                type="text"
                                id="iTitleText"
                                name="iTitleText"
                                value={iTitle}
                                onChange={iTitleChange}
                                disabled={custom3NameDisabled}
                                data-required
                            />
                            <ul className="datalist scrollbar">
                                {iTitleArray.map((x) =>
                                    typeId == 0 ? (
                                        <li
                                            data-value={x.custTitle}
                                            onClick={(e) => {
                                                setIntervieweeID(x.custID),
                                                    setIName(x.custName),
                                                    setITitle(x.custTitle),
                                                    setITaxID(x.custTaxID);
                                            }}
                                        >
                                            <span className="flex-4 bold">{x.custTitle}</span>
                                            <span className="flex-3">{x.custName}</span>
                                            <span className="flex-3">{x.custTaxID}</span>
                                        </li>
                                    ) : (
                                        <li
                                            data-value={x.vendorTitle}
                                            onClick={(e) => {
                                                setIntervieweeID(x.vendorID),
                                                    setIName(x.vendorName),
                                                    setITitle(x.vendorTitle),
                                                    setITaxID(x.vendorTaxID);
                                            }}
                                        >
                                            <span className="flex-4 bold">{x.vendorTitle}</span>
                                            <span className="flex-3">{x.vendorName}</span>
                                            <span className="flex-3">{x.vendorTaxID}</span>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item half has-datalist" data-datalist>
                        <div className="field">公司統編/編號</div>
                        <div className="value">
                            <input
                                type="text"
                                id="iTaxIDText"
                                name="iTaxIDText"
                                value={iTaxID}
                                onChange={iTaxIDChange}
                                disabled={custom3NameDisabled}
                            />
                            <ul className="datalist scrollbar">
                                {iTaxIDArray.map((x) =>
                                    typeId == 0 ? (
                                        <li
                                            data-value={x.custTaxID}
                                            onClick={(e) => {
                                                setIntervieweeID(x.custID),
                                                    setIName(x.custName),
                                                    setITitle(x.custTitle),
                                                    setITaxID(x.custTaxID);
                                            }}
                                        >
                                            <span className="flex-4 bold">{x.custTitle}</span>
                                            <span className="flex-3">{x.custName}</span>
                                            <span className="flex-3">{x.custTaxID}</span>
                                        </li>
                                    ) : (
                                        <li
                                            data-value={x.vendorTaxID}
                                            onClick={(e) => {
                                                setIntervieweeID(x.vendorID),
                                                    setIName(x.vendorName),
                                                    setITitle(x.vendorTitle),
                                                    setITaxID(x.vendorTaxID);
                                            }}
                                        >
                                            <span className="flex-4 bold">{x.vendorTitle}</span>
                                            <span className="flex-3">{x.vendorName}</span>
                                            <span className="flex-3">{x.vendorTaxID}</span>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                        <span className="help-word">請輸入公司統編/編號</span>
                    </div>
                    <div className="form-item must half has-datalist" data-datalist>
                        <div className="field">訪談人員</div>
                        <div className="value">
                            <input
                                id="interviewPersonNameText"
                                onChange={interviewPersonNameChange}
                                type="text"
                                data-required
                            />

                            <ul className="datalist scrollbar">
                                {interviewPersonArray.map((x) => (
                                    <li
                                        data-value={`${x.enName}(${x.tc_name})`}
                                        onClick={(e) => setInterviewPersonID(x.id)}
                                    >
                                        <span className="flex-4">{x.enName}({x.tc_name})</span>
                                        <span className="flex-5">{x.mail}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入訪談人員</span>
                    </div>

                    <div className="form-item must half">
                        <div className="field">訪談日期</div>
                        <div className="value">
                            <input
                                type="date"
                                value={interviewDate}
                                onChange={(e) => setInterviewDate(e.target.value)}
                                data-required
                            />
                        </div>
                        <span className="help-word">請輸入訪談日期</span>
                    </div>

                    <div className="form-item half  has-datalist" data-datalist>
                        <div className="field">陪同人員</div>
                        <div className="value">
                            <input
                                id="accompniedPersonNameText"
                                type="text"
                                onChange={accompniedPersonNameChange}
                            />
                            <ul className="datalist scrollbar">
                                {accompniedPersonArray.map((x) => (
                                    <li
                                        data-value={`${x.enName}(${x.tc_name})`}
                                        onClick={(e) => setAccompniedPersonID(x.id)}
                                    >
                                        <span className="flex-4">{x.enName}({x.tc_name})</span>
                                        <span className="flex-5">{x.mail}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <hr />

                    <div className="form-item must">
                        <div className="field">訪談主旨</div>
                        <div className="value">
                            <input
                                id="interviewTitle"
                                type="text"
                                value={interviewTitle}
                                onChange={(e) => setInterviewTitle(e.target.value)}
                                data-required
                            />
                        </div>
                        <span className="help-word">請輸入訪談主旨</span>
                    </div>

                    <div className="form-item">
                        <div className="field"></div>
                        <div className="value">
                            <textarea
                                name="interviewContent"
                                id="interviewContent"
                                value={interviewContent}
                                onChange={(e) => setInterviewContent(e.target.value)}
                                cols="30"
                                rows="10"
                                data-required
                                placeholder = "訪談內容"
                            ></textarea>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <hr />

                    <div className="form-item col">
                        <div className="field">訪談花費</div>
                        <div className="value">
                            <ul className="list-table">
                                <li className="lt-row lt-th">
                                    <div className="flex-3">項目</div>
                                    <div className="flex-2">費用金額</div>
                                    <div className="flex-2">幣別</div>
                                    <div className="flex-2">備註</div>
                                </li>
                                <li className="lt-row">
                                    <div className="flex-3">交通費</div>
                                    <div className="flex-2">
                                        <input
                                            type="text"
                                            value={NumFormart(interviewTravelFee)}
                                            onChange={(e) =>
                                                setInterviewTravelFee(e.target.value.replace(/,/g, ""))
                                            }
                                        />
                                    </div>
                                    <div className="flex-2">
                                        <select
                                            onChange={(e) =>
                                                setInterviewTravelFeeCurr(e.target.value)
                                            }
                                        >
                                            <option value="" selected disabled>請選擇</option>
                                            {currencyArray.map((x) => (
                                                <option
                                                    value={x.id}
                                                    selected={
                                                        x.id == interviewTravelFeeCurr ? true : false
                                                    }
                                                >
                                                    {x.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="flex-2">
                                        <input
                                            type="text"
                                            value={interviewTravelFeeComment}
                                            onChange={(e) =>
                                                setInterviewTravelFeeComment(e.target.value)
                                            }
                                        />
                                    </div>
                                </li>
                                <li className="lt-row">
                                    <div className="flex-3">餐飲費</div>
                                    <div className="flex-2">
                                        <input
                                            type="text"
                                            value={NumFormart(interviewDiningFee)}
                                            onChange={(e) =>
                                                setInterviewDiningFee(e.target.value.replace(/,/g, ""))
                                            }
                                        />
                                    </div>
                                    <div className="flex-2">
                                        <select
                                            onChange={(e) =>
                                                setInterviewDiningFeeCurr(e.target.value)
                                            }
                                        >
                                            <option value="" selected disabled>請選擇</option>
                                            {currencyArray.map((x) => (
                                                <option
                                                    value={x.id}
                                                    selected={
                                                        x.id == interviewDiningFeeCurr ? true : false
                                                    }
                                                >
                                                    {x.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="flex-2">
                                        <input
                                            type="text"
                                            value={interviewDiningFeeComment}
                                            onChange={(e) =>
                                                setInterviewDiningFeeComment(e.target.value)
                                            }
                                        />
                                    </div>
                                </li>
                                <li className="lt-row">
                                    <div className="flex-3">住宿費</div>
                                    <div className="flex-2">
                                        <input
                                            type="text"
                                            value={NumFormart(interviewAccomdFee)}
                                            onChange={(e) =>
                                                setInterviewAccomdFee(e.target.value.replace(/,/g, ""))
                                            }
                                        />
                                    </div>
                                    <div className="flex-2">
                                        <select
                                            onChange={(e) =>
                                                setInterviewAccomdFeeCurr(e.target.value)
                                            }
                                        >
                                            <option value="" selected disabled>請選擇</option>
                                            {currencyArray.map((x) => (
                                                <option
                                                    value={x.id}
                                                    selected={
                                                        x.id == interviewAccomdFeeCurr ? true : false
                                                    }
                                                >
                                                    {x.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="flex-2">
                                        <input
                                            type="text"
                                            value={interviewAccomdFeeComment}
                                            onChange={(e) =>
                                                setInterviewAccomdFeeComment(e.target.value)
                                            }
                                        />
                                    </div>
                                </li>
                                <li className="lt-row">
                                    <div className="flex-3">其他費用</div>
                                    <div className="flex-2">
                                        <input
                                            type="text"
                                            value={NumFormart(interviewOtherFee)}
                                            onChange={(e) =>
                                                setInterviewOtherFee(e.target.value.replace(/,/g, ""))
                                            }
                                        />
                                    </div>
                                    <div className="flex-2">
                                        <select
                                            onChange={(e) => setInterviewOtherFeeCurr(e.target.value)}
                                        >
                                            <option value="" selected disabled>請選擇</option>
                                            {currencyArray.map((x) => (
                                                <option
                                                    value={x.id}
                                                    selected={
                                                        x.id == interviewOtherFeeCurr ? true : false
                                                    }
                                                >
                                                    {x.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="flex-2">
                                        <input
                                            type="text"
                                            value={interviewOtherFeeComment}
                                            onChange={(e) =>
                                                setInterviewOtherFeeComment(e.target.value)
                                            }
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="form-item btn-area sp">
                        <div className="left"></div>
                        <div className="right">
                            <a className="btn btn-border" onClick={returnQry}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={submit}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
            <div id="modal-success" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">修改成功</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={reflash}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InterViewEdit;
