import React, { useState, useEffect } from "react";
import {
    API_GET_HomeData,
    API_GET_ProductHome,
    API_GET_ProductName1List,
    API_GET_ProductName2,
    API_GET_AdNameList,
    API_GET_SupplierNameList,
    API_GET_ProductDataList,
    API_PUT_ProductType,
    API_POST_ProductType,
    API_GET_ProductType,
    API_DEL_ProductType,
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, InitJS, MenuSet } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const Product = () => {
    var history = useHistory();

    useEffect(() => {
        //把menu顏色置換
        MenuSet("Product");
        
        getHomeData();

        //第一次近來先執行抓取product資料
        getProduct();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);


    const [hasReviewAuth, setHasReviewAuth] = useState(false);
    const [reGetHomeData, setReGetHomeData] = useState(false);

    function getHomeData(setReady = null) {
        API_GET_HomeData({ fromOkta: 0 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code

                console.log(data);

                let authDatas = data.result.authdata;

                let authIndex = authDatas.findIndex((x) => x.funcListID === 9);

                if (authIndex == -1) {
                    setHasReviewAuth(false);
                }
                else {
                    setHasReviewAuth(true);
                }

                if (setReady != null) {
                    setReady(true);
                }

            })
    }

    useEffect(() => {
        if (reGetHomeData == true) {
            getHomeData();
            setReGetHomeData(false);
        }
    }, [reGetHomeData])

    //產品別
    const [productData, setProductData] = useState([{}]);
    //產品細項總稱
    const [productNameData, setProductNameData] = useState([]);
    const [productName1, setProductName1] = useState("");
    function productName1Change(e) {
        setProductName1(e.target.value);
    }
    //產品細項
    const [productDetail, setProductDetail] = useState("");
    function productDetailChange(e) {
        setProductDetail(e.target.value);
        getProductDetail();
    }
    //產品細項datalist
    const [productDetailArray, setProductDetailArray] = useState([{}]);
    //產品負責人
    const [adID, setAdID] = useState("");
    const [adName, setAdName] = useState("");
    function adNameChange(e) {
        if (e.target.value == "") {
            setAdName("");
            setAdID(null);
        } else {
            setAdName(e.target.value);
            getAdName();
        }

    }

    //產品負責人datalist
    const [adNameArray, setAdNameArray] = useState([{}]);

    //供應商名稱
    const [supplierName, setSupplierName] = useState("");
    function supplierNameChange(e) {
        setSupplierName(e.target.value);
        getSupplierName();
    }
    //供應商名稱datalist
    const [supplierNameArray, setSupplierNameArray] = useState([{}]);

    //新增修改的modal文字
    const [modalStr, setModalStr] = useState("");
    //刪除失敗的modal文字
    const [modalDelStr, setModalDelStr] = useState("");

    //新增產品別
    const [addProductType, setAddProductType] = useState("");
    function addProductTypeChange(e) {
        if (
            document.getElementById("addProductTypeDiv").classList.contains("error")
        ) {
            setAddProductType("");
        }
        setAddProductType(e.target.value);
    }
    function addProductTypeClick(e) {
        if (
            document.getElementById("addProductTypeDiv").classList.contains("error")
        ) {
            setAddProductType("");
        }
    }
    const [addProductInvoice, setAddProductInvoice] = useState("");
    function addProductInvoiceChange(e) {
        setAddProductInvoice(e.target.value);
    }

    const [addProductReady, setAddProductReady] = useState(false);

    //執行新增產品別
    function AddProductType() {
        getHomeData(setAddProductReady);        
    }

    useEffect(() => {
        if (addProductReady == false) {
            return;
        }

        setAddProductReady(false);

        if (hasReviewAuth == false) {
            setModalStr("操作失敗，沒有檢核權限");
            OpenModal("modal-error");
            CloseModal("modal-4");
            return;
        }

        var isOk = 1;
        if (addProductType == "" || addProductType == "不可空白") {
            document.getElementById("addProductTypeDiv").classList.add("error");
            isOk = 0;
        } else {
            document.getElementById("addProductTypeDiv").classList.remove("error");
        }
        if (addProductInvoice == "" || addProductInvoice == "不可空白") {
            document.getElementById("addProductInvoiceDiv").classList.add("error");
            setAddProductInvoice("不可空白");
            isOk = 0;
        } else {
            document.getElementById("addProductInvoiceDiv").classList.remove("error");
        }

        var target = document.getElementById("modal-4");
        if (isOk == 1) {


            API_POST_ProductType({
                ProdTPName: addProductType,
                ProdTPInvName: addProductInvoice,
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        //代表有完成新增
                        getProduct();
                        target.classList.remove("active");
                        target.classList.remove("layer-1");
                        setModalStr("產品別新增完成");
                        OpenModal("modal-3");
                    } else if (data.code == "2004") {
                        document.getElementById("addProductTypeDiv").classList.add("error");
                        setAddProductType(addProductType + "(" + data.message + ")");
                    } else {
                        alert("新增失敗(" + data.code + ")" + data.message);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }, [addProductReady])


    //修改產品別
    const [editProductType, setEditProductType] = useState("");
    const [prevProTPName, setPrevProTPName] = useState("");
    function editProductTypeChange(e) {
        if (
            document.getElementById("editProductTypeDiv").classList.contains("error")
        ) {
            setEditProductType("");
        }
        setEditProductType(e.target.value);
    }
    function editProductTypeClick(e) {
        if (
            document.getElementById("editProductTypeDiv").classList.contains("error")
        ) {
            setEditProductType("");
        }
    }
    const [editProductInvoice, setEditProductInvoice] = useState("");
    function editProductInvoiceChange(e) {
        setEditProductInvoice(e.target.value);
    }

    const [editProductReady, setEditProductReady] = useState(false);


    //執行修改產品別
    function EditProductType() {
        getHomeData(setEditProductReady);        
    }

    useEffect(() => {
        if (editProductReady == false) {
            return;
        }

        setEditProductReady(false);

        if (hasReviewAuth == false) {
            setModalStr("操作失敗，沒有檢核權限");
            OpenModal("modal-error");
            CloseModal("modal-5");
            return;
        }

        var isOk = 1;
        if (editProductType == "" || editProductType == "不可空白") {
            document.getElementById("editProductTypeDiv").classList.add("error");
            setEditProductType("不可空白");
            isOk = 0;
        } else {
            document.getElementById("editProductTypeDiv").classList.remove("error");
        }
        if (editProductInvoice == "" || editProductInvoice == "不可空白") {
            document.getElementById("editProductInvoiceDiv").classList.add("error");
            setEditProductInvoice("不可空白");
            isOk = 0;
        } else {
            document
                .getElementById("editProductInvoiceDiv")
                .classList.remove("error");
        }
        if (document.getElementById("editProductTypeId").value == "") {
            isOk = 0;
        }
        var target = document.getElementById("modal-5");

        if (isOk == 1) {
            API_PUT_ProductType({
                ProdTPID: document.getElementById("editProductTypeId").value,
                ProdTPName: editProductType,
                ProdTPInvName: editProductInvoice,
                PrevProTPName: prevProTPName
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        //代表有完成修改
                        getProduct();
                        target.classList.remove("active");
                        target.classList.remove("layer-1");
                        setModalStr("產品別修改完成");
                        OpenModal("modal-3");
                    }
                    else if (data.code == "2004") {
                        document
                            .getElementById("editProductTypeDiv")
                            .classList.add("error");
                        setEditProductType(addProductType + "(" + data.message + ")");
                    } else {
                        alert("修改失敗(" + data.code + ")" + data.message);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }, [editProductReady])

    const [delProductReady, setDelProductReady] = useState(false);

    //要刪除產品別 先跳提示訊息
    function DelProductType(e) {
        getHomeData(setDelProductReady);
    }

    useEffect(() => {
        if (delProductReady == false) {
            return;
        }

        setEditProductReady(false);

        CloseModal("modal-5");
        if (hasReviewAuth) {
            OpenModal("modal-1");        
        }
        else {
            setModalStr("操作失敗，沒有檢核權限");
            OpenModal("modal-error");
        }

    }, [delProductReady])


    function ConfirmDelProductType(e) {
        var ProdTPID = document.getElementById("editProductTypeId").value;
        if (ProdTPID != "") {
            var target = document.getElementById("modal-1");

            API_DEL_ProductType({
                ProdTPID: ProdTPID,
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    //不管失敗或成功 都關閉提示的modal
                    target.classList.remove("active");
                    target.classList.remove("layer-1");
                    if (data.code == "0000") {
                        setModalDelStr("產品別刪除成功");
                    } else {
                        setModalDelStr(data.message);
                    }
                    getProduct();
                    OpenModal("modal-2");
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    const [productListArray, setProductListArray] = useState([{}]);
    function productChange(e) {
        document.getElementById("qryProduct").value = e.target.dataset.id;
        setProductName1("");
        if (e.target.dataset.id != "") {
            API_GET_ProductName1List({
                productTypeId: e.target.dataset.id,
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setProductNameData(data.result.productName1List);
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            setProductNameData([]);
        }
    }

    function openModalQuery(e) {
        var dt =
        {
            productTypeId: document.getElementById("qryProduct").value,
            productName1: document.getElementById("productName1List").value,
            productName2: document.getElementById("productDetailText").value,
            supplierName: document.getElementById("supplierNameText").value,
            personInChargeID: adID,
        }
        //console.log(dt);
        API_GET_ProductDataList(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                //更新查詢列表
                if (data.code == "0000") {
                    setProductListArray(data.result.data);
                    OpenModal("modal-query");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
        e.preventDefault();
    }


    //打開編輯產品別
    function openModal5(e) {
        OpenModal("modal-5");

        var prodTPID = e.target.dataset.id;
        document.getElementById("editProductTypeId").value = prodTPID;

        API_GET_ProductType({
            prodTPID: prodTPID,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                //寫入產品別跟發票到編輯產品別model的text
                if (data.result.data != null) {
                    setPrevProTPName(data.result.data[0].prodTPName);
                    setEditProductType(data.result.data[0].prodTPName);
                    setEditProductInvoice(data.result.data[0].prodTPInvName);
                    //避免有error class 抓到資料就先取消error樣式
                    document
                        .getElementById("editProductTypeDiv")
                        .classList.remove("error");
                    document
                        .getElementById("editProductInvoiceDiv")
                        .classList.remove("error");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        e.preventDefault();
    }
    function getProduct() {
        API_GET_ProductHome({})
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setProductData(data.result.productTypeArray);
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    function getProductDetail() {
        API_GET_ProductName2({
            input: document.getElementById("productDetailText").value,
            productTypeId: document.getElementById("qryProduct").value,
            productName1: document.getElementById("productName1List").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.companyArray != null) {
                    setProductDetailArray(data.result.companyArray);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    function getAdName() {
        API_GET_AdNameList({
            input: document.getElementById("adNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setAdNameArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function getSupplierName() {
        API_GET_SupplierNameList({
            input: document.getElementById("supplierNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setSupplierNameArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
    }

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container narrow">
                    <h3 className="fz-B has-border">查詢產品</h3>
                </div>
            </div>
            <div className="container narrow">
                <div className="form-item">
                    <div className="field">產品別</div>
                    <div className="value">
                        <div className="dropmenu-container" data-dropmenu>
                            <div className="dropmenu-value" data-id="" onClick={() => setReGetHomeData(true)}>
                                全部
                            </div>
                            <div className="dropmenu">
                                <input type="hidden" id="qryProduct" />
                                <ul>
                                    <li className="option">
                                        <div data-id="" data-value="" onClick={productChange}>
                                            全部
                                        </div>
                                        {/*<div className="fn" data-openmodal="modal-5" onClick={openModal5}> <i className="icon icon-39"></i></div>*/}
                                    </li>
                                    {productData.map((x) => (
                                        <li className="option">
                                            <div
                                                data-id={x.id}
                                                data-value={x.name}
                                                onClick={productChange}
                                            >
                                                {x.name}
                                            </div>
                                            {hasReviewAuth == false ? null : (
                                                <div className="fn" onClick={openModal5} data-id={x.id}>
                                                    <i className="icon icon-39"></i>
                                                </div>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                                {hasReviewAuth == false ? null : (
                                    <div className="additional" onClick={() => OpenModal("modal-4")}>
                                        <a href="#">新增產品別</a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <span className="help-word">請輸入正確格式</span>
                </div>

                <div className="form-item">
                    <div className="field">產品細項總稱</div>
                    <div className="value">
                        <select name="" id="productName1List" value={productName1} onChange={(e) => productName1Change(e)}>
                            <option value="">全部</option>
                            {productNameData.map((x) => (
                                <option value={x}>{x}</option>
                            ))}
                        </select>
                    </div>
                    <span className="help-word">請輸入正確格式</span>
                </div>

                <div className="form-item has-datalist" data-datalist>
                    <div className="field">產品細項</div>
                    <div className="value">
                        <input
                            id="productDetailText"
                            type="text"
                            onChange={productDetailChange}
                        />
                        <ul className="datalist scrollbar">
                            {productDetailArray.map((x) => (
                                <li
                                    data-value={x.productName2}
                                    onClick={(e) => {
                                        setProductDetail(x.productName2);
                                    }}
                                >
                                    <span className="flex-3 bold">{x.productName2}</span>
                                    <span className="flex-7">{x.productName1}</span>
                                    {/*<span className="flex-2">12345678</span>*/}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <span className="help-word">請輸入正確格式</span>
                </div>

                <div className="form-item has-datalist" data-datalist>
                    <div className="field">產品負責人</div>
                    <div className="value">
                        <input id="adNameText" type="text" autoComplete="off" onChange={adNameChange} />
                        <ul className="datalist scrollbar">
                            {adNameArray.map((x) => (
                                <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => { setAdName(x.enName); setAdID(x.id) }}>
                                    <span className="flex-4">{x.enName}({x.tc_name})</span>
                                    <span className="flex-5">{x.mail}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <span className="help-word">請輸入正確格式</span>
                </div>

                <div className="form-item has-datalist" data-datalist>
                    <div className="field">供應商名稱</div>
                    <div className="value">
                        <input id="supplierNameText" type="text" value={supplierName} onChange={supplierNameChange} />
                        <ul className="datalist scrollbar">
                            {supplierNameArray.map((x) => (
                                <li
                                    data-value={x.name}
                                    onClick={(e) => {
                                        setSupplierName(x.name);
                                    }}
                                >
                                    <span className="flex-10 bold">{x.name}</span>

                                    {/*<span className="flex-2">12345678</span>*/}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <span className="help-word">請輸入正確格式</span>
                </div>

                <div className="form-item btn-area sp">
                    <div className="left"></div>
                    <div className="right">
                        <a className="btn" data-openmodal="modal-query" onClick={openModalQuery} >
                            <span>查詢</span>
                        </a>
                    </div>
                </div>
            </div>

            <div id="modal-query" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">查詢結果</h3>
                        <span className="close" onClick={() => CloseModal("modal-query")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <ul className="list-table">
                            <li className="lt-row lt-th">
                                <div className="flex-3">產品別</div>
                                <div className="flex-3">產品細項</div>
                                <div className="flex-3">產品負責人</div>
                                <div className="flex-5">供應商名稱</div>
                            </li>
                            {productListArray.map((x) => (
                                <li className="lt-row">
                                    <div className="flex-3">{x.productName}</div>
                                    <div className="flex-3">
                                        <a
                                            href={`/Main?path=ProductEdit&productName2=${x.productName2}&reqFrmID=${x.reqFrmID}`}
                                        >
                                            {x.productName2}
                                        </a>
                                    </div>
                                    <div className="flex-3">{x.personInChargeName}</div>
                                    <div className="flex-5"> {x.supplierName} </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div id="modal-1" className="modal small alert" data-modal  >
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確定要刪除這產品別？</div>
                        <div className="btn-holder sp">
                            <button className="btn btn-border" onClick={() => CloseModal("modal-1")}>
                                <span>取消</span>
                            </button>
                            <a className="btn" onClick={ConfirmDelProductType}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-2" className="modal small alert" data-modal  >
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalDelStr}</div>
                        <div className="btn-holder">
                            <button className="btn" onClick={() => CloseModal("modal-2")}>
                                {" "}
                                <span>確認</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-3" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-3")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-4" className="modal" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">新增產品別</h3>
                        <span className="close" onClick={() => CloseModal("modal-4")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-container">
                            <div className="form-item must" id="addProductTypeDiv">
                                <div className="field">產品別</div>
                                <div className="value">
                                    <input
                                        type="text"
                                        id="addProductType"
                                        value={addProductType}
                                        onChange={addProductTypeChange}
                                        onClick={addProductTypeClick}
                                    />
                                </div>
                                <span className="help-word">已被使用，無法刪除</span>
                            </div>

                            <div className="form-item must" id="addProductInvoiceDiv">
                                <div className="field">會計產品別</div>
                                <div className="value">
                                    <input
                                        type="text"
                                        id="addProductInvoice"
                                        value={addProductInvoice}
                                        onChange={addProductInvoiceChange}
                                    />
                                </div>
                                <span className="help-word">
                                    xxx
                                </span>
                            </div>
                            <div className="form-item btn-area">
                                <a className="btn btn-border" onClick={() => CloseModal("modal-4")}>
                                    <span>取消</span>
                                </a>
                                <a className="btn" onClick={AddProductType}>
                                    <span>新增</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-5" className="modal" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">編輯產品別</h3>
                        <span className="close" onClick={() => CloseModal("modal-5")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-container">
                            <div className="form-item must" id="editProductTypeDiv">
                                <div className="field">產品別</div>
                                <div className="value">
                                    <input type="hidden" id="editProductTypeId" />
                                    <input
                                        type="text"
                                        id="editProductType"
                                        value={editProductType}
                                        onChange={editProductTypeChange}
                                        onClick={editProductTypeClick}
                                    />
                                </div>
                                <span className="help-word">已被使用，無法刪除</span>
                            </div>

                            <div className="form-item must" id="editProductInvoiceDiv">
                                <div className="field">會計產品別</div>
                                <div className="value">
                                    <input
                                        type="text"
                                        id="editProductInvoice"
                                        value={editProductInvoice}
                                        onChange={editProductInvoiceChange}
                                    />
                                </div>
                                <span className="help-word">
                                    加上 show-help error 樣式來呈現
                                </span>
                            </div>
                            <div className="form-item btn-area">
                                <a className="btn btn-border" onClick={DelProductType}>
                                    <span>刪除產品別</span>
                                </a>
                                <a className="btn" onClick={EditProductType}>
                                    <span>確定修改</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error", 2)}>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;
