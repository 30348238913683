import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_AllCompany,
    API_GET_SupplierNameList,
    API_GET_SupplierTitleList,
    API_GET_SupplierTaxId,
    API_GET_PrepassVendorDataList,
} from "../../global/constants";
import { checkAuthToken } from "../../global/TokenUtil";
import { NumFormart, OpenModal, CloseModal, InitJS } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const Prepaid_SupplierQry = () => {
    var history = useHistory();

    useEffect(() => {
        //把menu顏色置換
        document.getElementById("menuitem-Prepaid_CustomerQry").classList.add("active");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    function getData() {
        API_GET_AllCompany()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setCompanyArray(data.result.data);
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    function getSupplierName() {
        API_GET_SupplierNameList({
            input: document.getElementById("supplierNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setSupplierNameArray(data.result.data);
            })
            .catch((err) => {
                 
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //重新取得 供應商抬頭 List
    function getSupplierTitleList() {
        API_GET_SupplierTitleList({
            input: document.getElementById("supplierTitleText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setSupplierTitleArray(data.result.data);
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //重新取得 供應商統編 List
    function getSupplierTaxIdList() {
        API_GET_SupplierTaxId({
            input: document.getElementById("supplierTaxIdText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setSupplierTaxArray(data.result.data);
            })
            .catch((err) => {
                 
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //查詢列表
    const [qryList, setQryList] = useState([{}]);
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);

    const [F1401, setF1401] = useState();
    const [F1402, setF1402] = useState(1);
    //供應商
    const [supplierName, setSupplierName] = useState();
    const [supplierNameArray, setSupplierNameArray] = useState([{}]);
    function supplierNameChange(e) {
        setSupplierName(e.target.value);
        getSupplierName();
    }

    //供應商抬頭
    const [supplierTitle, setSupplierTitle] = useState();
    const [supplierTitleArray, setSupplierTitleArray] = useState([{}]);
    function supplierTitleChange(e) {
        setSupplierTitle(e.target.value);
        getSupplierTitleList();
    }
    //客戶統編
    const [supplierTaxId, setSupplierTaxId] = useState();
    const [supplierTaxArray, setSupplierTaxArray] = useState([]);
    function supplierTaxIdChange(e) {
        var v = e.target.value.replace(/[^A-Za-z0-9]/g, ''); 
        setSupplierTaxId(v);
        getSupplierTaxIdList();
    }
    function openModal1() {
        if (F1401 == undefined) {
            document.getElementById("companyDiv").classList.add("show-help");
            return false;
        } else {
            document.getElementById("companyDiv").classList.remove("show-help");
        }
        //客戶查詢
        API_GET_PrepassVendorDataList({
            companyId: F1401,
            supplierName: document.getElementById("supplierNameText").value,
            supplierTitle: document.getElementById("supplierTitleText").value,
            supplierTaxId: document.getElementById("supplierTaxIdText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setQryList(data.result.data);
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
        OpenModal("modal-1");
    }
    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container narrow">
                    <h3 className="fz-B has-border">供應商預付保證金查詢</h3>
                </div>
            </div>
            <div className="container narrow">
                <form action="" className="form-container">
                    <div className="form-item must" id="companyDiv">
                        <div className="field">負責公司</div>
                        <div className="value">
                            {companyArray.map((x) => (
                                <div key={x.id}>
                                    <input
                                        type="radio"
                                        id={`f1401-${x.cmpID}`}
                                        name="f1401"
                                        value={x.cmpID}
                                        checked={F1401 == x.cmpID ? "checked" : ""}
                                        onClick={(e) => setF1401(x.cmpID)}
                                    />
                                    <label htmlFor={`f1401-${x.cmpID}`}>{x.cmpName}</label>
                                </div>
                            ))}
                        </div>
                        <div className="help-word">請選擇負責公司</div>
                    </div>

                    <div className="form-item must">
                        <div className="field">查詢項目</div>
                        <div className="value">
                            <div>
                                <input
                                    type="radio"
                                    id="f1402-1"
                                    name="f1402"
                                    value="1"
                                    checked={F1402 == 1 ? "checked" : ""}
                                    onClick={(e) => setF1402(1)}
                                />
                                <label htmlFor="f1402-1">預付金</label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    id="f1402-2"
                                    name="f1402"
                                    value="2"
                                    checked={F1402 == 2 ? "checked" : ""}
                                    onClick={(e) => setF1402(2)}
                                />
                                <label htmlFor="f1402-2">保證金</label>
                            </div>
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <hr />

                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">供應商</div>
                        <div className="value">
                            <input
                                id="supplierNameText"
                                type="text"
                                value={supplierName}
                                onChange={supplierNameChange}
                            />
                            <ul className="datalist scrollbar">
                                {supplierNameArray.map((x) => (
                                    <li
                                        data-value={x.name}
                                        onClick={(e) => {
                                            setSupplierName(x.name);
                                        }}
                                    >
                                        <span className="flex-10 bold">{x.name}</span>

                                        {/*<span className="flex-2">12345678</span>*/}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">供應商抬頭</div>
                        <div className="value">
                            <input
                                id="supplierTitleText"
                                type="text"
                                onChange={supplierTitleChange}
                                value={supplierTitle}
                            />
                            <ul className="datalist scrollbar">
                                {supplierTitleArray.map((x) => (
                                    <li
                                        data-value={x.venTitle}
                                        onClick={(e) => {
                                            setSupplierTitle(x.venTitle);
                                        }}
                                        key={x.venID}
                                    >
                                        <span className="flex-5 bold">{x.venName}</span>
                                        <span className="flex-5">{x.venTitle}</span>
                                        {/*<span className="flex-2">12345678</span>*/}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">供應商統編 / 編號</div>
                        <div className="value">
                            <input
                                id="supplierTaxIdText"
                                type="text"
                                onChange={supplierTaxIdChange}
                                value={supplierTaxId}
                                maxLength="20"
                            />
                            <ul className="datalist scrollbar">
                                {supplierTaxArray.map((x) => (
                                    <li data-value={x} onClick={(e) => { setSupplierTaxId(x); }}  >
                                        <span className="flex-10 bold">{x}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item btn-area sp">
                        <div className="left"></div>
                        <div className="right">
                            <a className="btn" onClick={openModal1}>
                                <span>查詢</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
            <div id="modal-1" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">查詢結果</h3>
                        <span className="close" onClick={() => CloseModal("modal-1")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <ul className="list-table">
                            <li className="lt-row lt-th">
                                <div className="flex-3">供應商</div>
                                <div className="flex-4">供應抬頭</div>
                                <div className="flex-3">統編/ 編號</div>
                            </li>
                            {qryList.map((x) => (
                                <li className="lt-row">
                                    <div className="flex-3">
                                        <a onClick={() => history.push(`/Main?path=Prepaid_SupplierView&type=${F1402}&venId=${x.venID}`)} style={{ "cursor": "pointer" }}>
                                            {x.venName}
                                        </a>

                                    </div>
                                    <div className="flex-4">{x.venTitle}</div>
                                    <div className="flex-3">{x.venBIZNo}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Prepaid_SupplierQry;
