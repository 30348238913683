import React, { useState, useEffect } from "react";
import {
    API_POST_PermissionListPriv,
    API_GET_Permission_ListAll,
    API_SetPrivilege,
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, InitJS, SetOnbeforeunload, MenuSet, SetHasChange } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const PermissionEdit = () => {
    var history = useHistory();
    var onbeforeunloadFlag = false;
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        //把menu顏色置換
        MenuSet("Permission");
        //dataCheck();
        getlistAll();
        getlistPriv();
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    //抓取資料
    function getlistPriv() {
        API_POST_PermissionListPriv({
            UserID: queryParams.get("userid"),
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                if (data.code != "0000" || data.result.length == 0) {
                    history.push("/Main?path=Permission");
                }
                else {
                    setUserName(data.result.user.userName);
                    setUserTitle(data.result.user.userTitle);
                    setUserDept(data.result.user.userDept);
                    setUserNo(data.result.user.userNo);
                    setUserID(data.result.user.userID);
                    setListPriv(data.result.authCtrls);
                    setOriAdmin(false);
                    setNowAdmin(
                        listPriv.filter((x) => x.functionType == -1).length > 0
                            ? true
                            : false
                    );
                }
            })
            .catch((err) => {
               
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //const [getuserid, setGetUserID] = useState();
    function getlistAll() {
        
        API_GET_Permission_ListAll()
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setListAll(data.result);
                }
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //取消 回查詢頁
    function cancel() {
        history.push("/Main?path=Permission");
    }
    
     
     
    function changetState(e) {
       
        if (e.target.checked) {
            setListPriv(function (prev) {
                return [...prev, { AuthID: null, funcListID: e.target.value }];
            });
        } else {
            setListPriv(function (prev) {
                return prev.filter((item) => item.funcListID != e.target.value);
            });
        }
    }
    //變更超級管理者
    function changetSuperState(e) {
       
        setNowAdmin(e.target.checked);

        if (e.target.checked) {
            setListPriv(function (prev) {
                return [...prev, { AuthID: null, funcListID: e.target.value }];
            });
        } else {
            setListPriv(function (prev) {
                return prev.filter((item) => item.funcListID != e.target.value);
            });
        }
    }
    //修改
    function confirmSet(e) {
        CloseModal("modal-1");
        if (nowAdmin != oriAdmin && nowAdmin == true) {
            setModalStr("本次操作將賦予" + username + "權限控制");
            //改過權限控制 跳確認視窗
            OpenModal("modal-check");
        } else {
            //進行修改
            ModifyData();
        }
    }
    //確定修改
    function ModifyData() {
        CloseModal("modal-check");
        //更新資料
        var tmpArray = [];
        var ch1 = document.getElementsByName("privCheckBox");
        for (var i = 0; i < ch1.length; i++) {
            if (ch1[i].checked) {
                tmpArray.push(parseInt(ch1[i].value));
            }
        }
        var dt = {
            UserID: userID,
            UserName: username,
            UserNo: userNo,
            PrivIds: tmpArray,
        };
        API_SetPrivilege(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    SetHasChange(false);
                    window.onbeforeunload = null;
                    onbeforeunloadFlag = false; 
                }  
                setModalStr(data.message);
                OpenModal("modal-2");
            })
            .catch((err) => {
                 
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [username, setUserName] = useState("");
    const [title, setUserTitle] = useState("");
    const [userDept, setUserDept] = useState("");
    const [userNo, setUserNo] = useState("");
    const [userID, setUserID] = useState("");
    const [listAll, setListAll] = useState([{}]);
    const [listPriv, setListPriv] = useState([{}]);
    const [oriAdmin, setOriAdmin] = useState();
    const [nowAdmin, setNowAdmin] = useState();
    const [modalStr, setModalStr] = useState("");
    const [modalStr2, setModalStr2] = useState("確定要進行？");

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">權限設定</h3>
                </div>
            </div>

            <div className="container">
                <form action="">
                    <div className="form-container">
                        <div className="block-area">
                            <div className="row field" style={{ alignItems: "end" }}>
                                <div className="left">
                                    <span>使用者姓名：{username}</span>
                                    <span>職稱：{title}</span>
                                </div>

                                <div className="right" >
                                    <span style={{ fontWeight: "500" }}>隸屬單位：{userDept}</span>
                                </div>
                            </div>

                            <div className="form-item col">
                                <div className="field jcsb">
                                    <span> 功能權限</span>
                                    {listAll
                                        .filter((item) => item.functionType == -1)
                                        .map((x) => (
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id="permission"
                                                    name="privCheckBox"
                                                    value={x.funcListID}
                                                    key={x.funcListID}
                                                    checked={
                                                        listPriv.filter((s) => s.funcListID == x.funcListID)
                                                            .length > 0
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    onClick={changetSuperState}
                                                />
                                                <label className="orange" htmlFor="permission">
                                                    權限控制
                                                </label>
                                            </div>
                                        ))}
                                </div>
                                <div className="value">
                                    {listAll
                                        .filter((item) => item.functionType == 0)
                                        .map((x) => (
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox_${x.funcListID}`}
                                                    name="privCheckBox"
                                                    value={x.funcListID}
                                                    key={x.funcListID}
                                                    checked={
                                                        listPriv.filter((s) => s.funcListID == x.funcListID)
                                                            .length > 0
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    onClick={changetState}
                                                />
                                                <label htmlFor={`checkbox_${x.funcListID}`}>
                                                    {x.functionName}
                                                </label>
                                                {/*{listPriv.filter(s => s.FuncListID == x.funcListID).length}*/}
                                            </div>
                                        ))}
                                </div>
                                <span className="help-word">請至少選擇一個</span>
                            </div>
                            <div className="form-item col">
                                <div className="field">報表權限</div>
                                <div className="value">
                                    {listAll
                                        .filter((item) => item.functionType == 1)
                                        .map((x) => (
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox_${x.funcListID}`}
                                                    name="privCheckBox"
                                                    value={x.funcListID}
                                                    key={x.funcListID}
                                                    checked={
                                                        listPriv.filter((s) => s.funcListID == x.funcListID)
                                                            .length > 0
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    onClick={changetState}
                                                />
                                                <label htmlFor={`checkbox_${x.funcListID}`}>
                                                    {x.functionName}
                                                </label>
                                            </div>
                                        ))}
                                </div>
                                <span className="help-word">請至少選擇一個</span>
                            </div>
                        </div>
                        <div className="form-item btn-area sp">
                            <div className="left"></div>
                            <div className="right">
                                <a className="btn btn-border" onClick={cancel}>
                                    <span>取消</span>
                                </a>
                                <a className="btn" onClick={()=>OpenModal("modal-1")}>
                                    <span>確定</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div id="modal-1" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確定要送出權限設定？</div>
                        <div className="btn-holder sp">
                            <a className="btn btn-border" onClick={ ()=>CloseModal("modal-1")}>
                                {" "}
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={confirmSet}>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-check" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                            <br />
                            {modalStr2}
                        </div>
                        <div className="btn-holder sp">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-check")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={ModifyData}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-2" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-2")}>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PermissionEdit;
