import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";
import Notify from "../components/Dashboard/Notify";
import {
    API_OKTA_AccountMerge,
    API_OKTA_AccountRevoke,
    API_OKTA_AccountHome,
    API_GET_UserList,
    API_Account_UpdateStatus,
    API_GET_HomeData
} from "../global/constants";
import { checkAuthToken } from "../global/TokenUtil";
import { OpenModal, CloseModal, InitJS, UrlDecode, Clone } from "../global/ToolUtil"
import { CheckResponseCode } from '../global/ResponseUtil';

const UserManage = () => {
    const history = useHistory();
    const [notifyArray, setNotifyArray] = useState([]);
    const [oktaList, setOktaList] = useState([]);
    const [username, setUserName] = useState('');
    const [userList, setUserList] = useState([]);

    setInterval(tick, 1000);
    const [nowTime, setNowTime] = useState('');
    const [modalStr, setModalStr] = useState('');
    const [showUserList, setShowUserList] = useState();
    const [tmpUserID, setTmpUserID] = useState();
    const [tmpUser, setTmpUser] = useState();
    const [tmpEmail, setTmpEmail] = useState();

    useEffect(() => {
        document.body.classList.remove('login');
        document.body.classList.add('dashboard');
        document.getElementById('root').classList.remove('center');
        setUserName(localStorage.getItem("userName"));
        //抓取資料
        getData();
        //JS事件
        //const timeout = setTimeout(() => {
        //    InitJS();
        //}, 300)
        //return () => clearTimeout(timeout)
    }, []);
    //預設0 都抓 給1只抓右邊的使用者列表
    function getData(type = 0, userID = 0) {
        if (type == 0) {
            API_OKTA_AccountHome()
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        //負責公司
                        setOktaList(data.result.data);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
        API_GET_UserList({
            //UserName: document.getElementById("qryUserNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (userID > 0) {
                    var tmpDT = Clone(userList);
                    for (var i = 0; i < tmpDT.length; i++) {
                        for (var j = 0; j < data.result.length; j++) {
                            if (tmpDT[i].userID == data.result[j].userID) {
                                tmpDT[i].userInvalid = data.result[j].userInvalid;
                                break;
                            }
                        }
                    }
                    setUserList(tmpDT);
                } else {
                    setUserList(data.result);
                }
            });
        var fromOkta_ = (localStorage.getItem("post_logout_redirect_uri") != null ? 1 : 0);
        API_GET_HomeData({ fromOkta: fromOkta_ })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code

                setNotifyArray(data.result.notify);

            });
    }

    function tick() {
        setNowTime(new Date().toLocaleDateString() + " " + new Date().toDateString().split(" ")[0] + " " + new Date().toTimeString().split(" ")[0]);
    }

    //登出
    function logout() {
        var pth = resultPth();
        if (pth.indexOf("okta") >= 0) {
            window.location.href = pth;
        }
        history.push(pth);
    }

    function resultPth() {
        var result = "/";
        var id_token = localStorage.getItem('id_token');
        var okta_url = localStorage.getItem('okta_url');
        var post_logout_redirect_uri = localStorage.getItem('post_logout_redirect_uri');
        var check_okta_url = window.okta_url;
        var check_post_logout_redirect_uri = window.post_logout_redirect_uri;
        if (post_logout_redirect_uri != null && post_logout_redirect_uri != '') {
            //jwt解析
            var jwttoken = decodeToken(id_token);
            //token過期 
            if ((jwttoken.exp * 1000) < Date.now()) {
                result = "/";
            }
            //判斷後台傳遞的位址是否與前端JS設定一致
            else if (check_okta_url.trim() == okta_url && check_post_logout_redirect_uri.trim() == post_logout_redirect_uri) {
                result = UrlDecode(okta_url + "?id_token_hint=" + id_token + "&post_logout_redirect_uri=" + post_logout_redirect_uri);
            }
        }
        localStorage.clear();
        return result;
    }

    //打開更新狀態的確認視窗
    function OpenModalStatus(userID, userNo, userName, userInvalid) {
        setTmpUserID(userID);
        var statusStr = "停止";
        if (userInvalid != 0) {
            statusStr = "恢復";
        }
        setModalStr("確定要" + statusStr + "【" + userName + "】的權限？");
        OpenModal("modal-StatusCheck");

    }
    //
    function updateStatus() {
        CloseModal("modal-StatusCheck");
        if (tmpUserID != "") {
            API_Account_UpdateStatus({
                UserId: tmpUserID,
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        getData(1, tmpUserID);
                    } else {
                        setModalStr(data.message);
                        OpenModal("modal-error");
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        history.push("/");
                    }
                });
        }
    }
    //篩選
    function textfilter(e) {
        setUserList(function (prev) {
            return prev.map((item, i) => {
                if (e.target.value != "") {
                    if (item.userNo.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0 ||
                        item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0) {
                        item.isShow = true;
                    } else {
                        item.isShow = false;
                    }
                } else {
                    item.isShow = true;
                }
                return item;
            });
        });
    }

    //防呆確認
    function oktaCheck(obj, funType) {
        setTmpUser(obj.latestName);
        setTmpEmail(obj.email);
        var userListStr = "";
        if (obj.nameList.length > 0) {
            for (var i = 0; i < obj.nameList.length; i++) {
                userListStr += obj.nameList[i] + "<br/>";
            }
        }
        setShowUserList(userListStr);
        if (funType == 1) {
            OpenModal("modal-mergecheck");
        } else {
            OpenModal("modal-revokecheck");
        }
    }
    //合併
    function Merge() {
        CloseModal("modal-mergecheck");
        API_OKTA_AccountMerge({
            email: tmpEmail
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                getData();
            })
    }
    //捨棄
    function Revoke() {
        CloseModal("modal-revokecheck");
        API_OKTA_AccountRevoke({
            email: tmpEmail
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                getData();
            })
    }
    return (
        <>
            <header className="header">
                <a href="/Dashboard" className="logo">
                    <i className="icon icon-31"></i>
                </a>
                <div className="fn-bar">
                    <div className="left">
                        <span className="time">{nowTime}</span>
                    </div>
                    <div className="right">
                        <a href="" className={notifyArray == null || notifyArray.length == 0 ? "" : "notice"} onClick={() => history.push('/Dashboard')}>
                            <i class="icon icon-42"></i>
                        </a>
                        <a href="">
                            <i className="icon icon-40"></i>
                            <span>{username}</span>
                        </a>
                        <a onClick={logout} className="logout" style={{ "cursor": "pointer" }}>
                            <i className="icon icon-38"></i>
                            <span>登出</span>
                        </a>
                    </div>
                </div>
            </header>
            <div className="main">
                <div className="dashboard-body for-user-management">
                    <div className="title-area">
                        <div className="container">
                            <h3 className="fz-B has-border">管理使用人員帳號</h3>
                            <a className="btn btn-border btn-middle" onClick={() => history.push('/Dashboard')}>
                                <span>回上一頁</span>
                            </a>
                        </div>
                    </div>
                    <div className="container">
                        <div className="left">
                            <section className="title">
                                <h3 className="fz-C">帳號資料合併 / 捨棄</h3>
                                <p>下列帳號在後台有多筆資料，請選擇合併或捨棄原帳號</p>
                            </section>
                            <section className="scrollbar">
                                {
                                    oktaList.length == 0 ? null :
                                        oktaList.map(x =>
                                            <div className="um-item">
                                                <div className="um-info">
                                                    <div className="email">{x.email}</div>
                                                    <div className="name">{x.latestName}</div>
                                                </div>
                                                <ul className="um-list">
                                                    <li>以下為將合併/捨棄的同帳號資料</li>
                                                    {
                                                        x.nameList.length == 0 ? null :
                                                            x.nameList.map(x2 =>
                                                                <li>{x2}</li>
                                                            )
                                                    }
                                                </ul>
                                                <div className="um-fn">
                                                    <button className="btn btn-small" onClick={() => oktaCheck(x, 1)}>
                                                        <span>合併資料</span>
                                                    </button>
                                                    <button className="btn btn-small" onClick={() => oktaCheck(x, 2)}>
                                                        <span>捨棄原帳號</span>
                                                    </button>
                                                </div>


                                            </div>
                                        )
                                }
                            </section>
                        </div>
                        <div className="right">
                            <div className="title">
                                <h3 className="fz-C">所有帳號</h3>
                                <div className="form-item no-field search">
                                    <div className="value">
                                        <input type="search" id="searchText" placeholder="搜尋帳號" onChange={textfilter} />
                                    </div>

                                </div>
                            </div>
                            <div className="scrollbar">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-5">帳號</div>
                                        <div className="flex-3">姓名</div>
                                        <div className="flex-1 fn max-60"></div>
                                    </li>
                                    {
                                        userList.length == 0 ? null :
                                            userList.map(x =>
                                                x.isShow == false ? null :
                                                    <>
                                                        <li className="lt-row">
                                                            <div className={`flex-5 ${x.userInvalid ? "fc-disabled" : ""} `}>{x.userNo}</div>
                                                            <div className={`flex-3 ${x.userInvalid ? "fc-disabled" : ""} `}>{x.userName}</div>
                                                            <div className="flex-1 fn max-60">
                                                                <div className="btn btn-icon" onClick={() => OpenModalStatus(x.userID, x.userNo, x.userName, x.userInvalid)}>
                                                                    <i className={`icon icon-${x.userInvalid ? "36" : "35"}`}></i>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </>
                                            )
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="bottom copyright">
                            <span>2021 Digicentre Company Limited. All Rights Reserved.</span>
                        </div>
                    </div>
                </div>

                <div id="modal-mergecheck" className="modal small alert" data-modal data-mid="0.1.1.2">
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-41"></i>
                            </div>
                            <div className="text-holder">
                                確定要將下列帳號資料合併至 <strong>{tmpUser}</strong> 的帳號？ 合併後將無法還原
                                <br /><br />
                                <div className="fz-middle fc-lightgray" dangerouslySetInnerHTML={{ __html: showUserList }}>

                                </div>
                            </div>
                            <div className="btn-holder">
                                <button className="btn btn-border" onClick={() => CloseModal("modal-mergecheck")}> <span>取消</span></button>
                                <button className="btn" onClick={Merge}> <span>合併資料</span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-revokecheck" className="modal small alert" data-modal data-mid="0.1.1.3">
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-41"></i>
                            </div>
                            <div className="text-holder">
                                確定要捨棄下列帳號僅保留 <strong>{tmpUser}</strong> 的帳號？ 捨棄後將無法還原
                                <br /><br />
                                <div className="fz-middle fc-lightgray" dangerouslySetInnerHTML={{ __html: showUserList }}>

                                </div>
                            </div>
                            <div className="btn-holder">
                                <button className="btn btn-border" onClick={() => CloseModal("modal-revokecheck")}> <span>取消</span></button>
                                <button className="btn" onClick={Revoke}> <span>捨棄原資料</span></button>
                            </div>

                        </div>

                    </div>
                </div>
                <div id="modal-StatusCheck" className="modal small alert">
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-41"></i>
                            </div>
                            <div className="text-holder">
                                {modalStr}
                            </div>
                            <div className="btn-holder">
                                <button className="btn btn-border" onClick={() => CloseModal("modal-StatusCheck")}> <span>取消</span></button>
                                <button className="btn" onClick={updateStatus}><span>確定</span></button>
                            </div>

                        </div>

                    </div>
                </div>
                <div id="modal-error" className="modal small alert" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-41"></i>
                            </div>
                            <div className="text-holder">{modalStr}</div>
                            <div className="btn-holder">
                                <a className="btn" onClick={() => CloseModal("modal-error")}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default UserManage;