import React, { useState, useEffect } from "react";
import { API_GET_SaleOrderList } from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { NumFormart, InitJS, MenuSet, OpenModal } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const SaleOrderList = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        //把menu顏色置換
        MenuSet("SaleOrderQry"); 
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);


    const [dataList, setDataList] = useState([{}]);




    function getData() {
        var dt = {
            companyId: parseInt(queryParams.get("companyId")),
            orderNumber: queryParams.get("orderNumber"),
            customerName: queryParams.get("customerName"),
            customerTaxId: queryParams.get("customerTaxId"),
            incomeMonth: queryParams.get("IncomeMonth"),
        };

        API_GET_SaleOrderList(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setDataList(data.result.data);
            })
            .catch((err) => {
                 
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //展開
    function dataOpen(index) {
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.isOpen = (item.isOpen == null || item.isOpen == false) ? true : false;
                }
                return item;
            });
        });
    }

    function goQry() {
        history.push("/Main?path=SaleOrderQry");
    }
    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container full">
                    <h3 className="fz-B has-border">查詢結果</h3>
                </div>
            </div>
            <div className="container full">
                <ul className="list-table">
                    <li className="lt-row lt-th">
                        <div className="flex-2">營收公司</div>
                        <div className="flex-2">發票狀態</div>
                        <div className="flex-3">訂單編號</div>
                        <div className="flex-2">銷售類型</div>
                        <div className="flex-2">客戶別</div>
                        <div className="flex-3">產品細項</div>
                        <div className="flex-3">說明</div>
                        <div className="flex-3">營收小計</div>
                        <div className="flex-1 fn"></div>
                    </li>
                    {dataList.map((x, i) => (
                        <>
                            <li className={`lt-row ${x.isOpen == true ? 'highlight' : ''}`}>
                                <div className="flex-2">{x.soCompanyName}</div>
                                <div className="flex-2">
                                    {(() => {
                                        if (x.spInvState == 0) {
                                            return "未開";
                                        } else if (x.spInvState == 1) {
                                            return "預開";
                                        } else if (x.spInvState == 2) {
                                            return "列管";
                                        } else if (x.spInvState == 3) {
                                            return "作廢";
                                        } else if (x.spInvState == 4) {
                                            return "作廢重開";
                                        } else if (x.spInvState == 5) {
                                            return "處理中";
                                        } else if (x.spInvState == 6) {
                                            return "已開";
                                        } else if (x.spInvState == 7) {
                                            return "列管已開";
                                        } else if (x.spInvState == 8) {
                                            return "作廢重開已開";
                                        } else if (x.spInvState == 100) {
                                            return "為預開(未開)";
                                        }
                                    })()}
                                </div>
                                <div className="flex-3">
                                    <a onClick={() => history.push(`/Main?path=SaleOrderEdit&orderId=${x.soid}`)} className={x.spInvState == 2 || x.isSupervised == 1 ? 'alert' : ''}>
                                        {x.soNo}
                                    </a>
                                </div>
                                <div className="flex-2">
                                    {(() => {
                                        if (x.spType == 0) {
                                            return "月租型";
                                        } else if (x.spType == 1) {
                                            return "一次型";
                                        } else if (x.spType == 2) {
                                            return "攤提型";
                                        }
                                    })()}
                                </div>
                                <div className="flex-2">{x.soCustomerName}</div>
                                <div className="flex-3">{x.spProductFullName}</div>
                                <div className="flex-3">{x.spRem}</div>
                                <div className="flex-3">{x.currencyEName} {NumFormart(x.soSubTotal)}</div>
                                <div className="flex-1 fn">
                                    <div className="fn-bar">
                                        <div className={`btn btn-icon ${x.isOpen == true ? 'rotate' : ''}`}  onClick={() => dataOpen(i)}>
                                            <i className="icon icon-28"></i>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className={`lt-row ${x.isOpen == true ? '' : 'hide'}`} id={`st-${i}`}>
                                <ul className="list-table nested">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1">營收月份</div>
                                        <div className="flex-1">經銷類別</div>
                                        <div className="flex-3">公司抬頭</div>
                                        <div className="flex-2">統編/ 編號</div>
                                        <div className="flex-2">信用額度</div>
                                        <div className="flex-2">信用餘額</div>
                                        <div className="flex-2">預付餘額</div>
                                        <div className="flex-2">產品別</div>
                                        <div className="flex-1">數量</div>
                                        <div className="flex-2">銷售成本</div>
                                    </li>
                                    <li className="lt-row">
                                        <div className="flex-1">{x.spSellYM}</div>
                                        <div className="flex-1">
                                            {(() => {
                                                if (x.soDealerType == 0) {
                                                    return "否";
                                                } else if (x.soDealerType == 1) {
                                                    return "是";
                                                }
                                            })()}
                                        </div>
                                        <div className="flex-3">{x.soCustomerTitle}</div>
                                        <div className="flex-2">{x.soCustomerTaxId}</div>
                                        <div className="flex-2">{NumFormart(x.soCredit)}</div>
                                        <div className="flex-2">{NumFormart(x.soCreditBalance)}</div>
                                        <div className="flex-2">{NumFormart(x.soPrepBalance)}</div>
                                        <div className="flex-2">{x.spProdType}</div>
                                        <div className="flex-1">{NumFormart(x.spQuantity)}</div>
                                        <div className="flex-2">{NumFormart(x.soitSecSalCost)} </div>
                                    </li>
                                </ul>
                            </li>
                        </>
                    ))}
                </ul>

                <div className="form-item btn-area sp">
                    <div className="left"></div>
                    <div className="right">
                        <a className="btn" onClick={goQry}>
                            <span>回上一頁</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default SaleOrderList;
