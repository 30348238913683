import React, { useState, useEffect } from "react";
import {
    API_GET_VendorReview,
    API_DEL_VendorData,
    API_POST_VendorData,
    API_PUT_VendorData,
    API_PUT_VendorReview,
    API_GET_AdNameList,
    API_POST_Revoke

} from "../../../global/constants";
import { CloseModal, OpenModal } from "../../../global/ToolUtil";
import { checkAuthToken } from "../../../global/TokenUtil";
import { useHistory } from "react-router-dom";
import { CheckResponseCode } from '../../../global/ResponseUtil';

const VendorDetail = ({ reqFrmID, setModalStr, reGetData }) => {
    var history = useHistory();
    useEffect(() => {
        if (reqFrmID != null) {
            getData();
        }
    }, [reqFrmID]);

    function getData() {
        setReason("");
        OpenModal("modal-supplierreview");
        API_GET_VendorReview({
            reqFrmID: reqFrmID,
        })
            .then((response) => response.data)
            .then((data) => {
                console.log("供應商維護單子回傳資料", data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setCompanyIdArray(data.result.data.vendor.companyIdArray);
                setOldVendoridArray(data.result.data.vendor.oldVendoridArray);
                setSupplierName(data.result.data.vendor.venName);
                setPmName(data.result.data.pmName);
                setPmID(data.result.data.vendor.pmid);
                setSetlementId(data.result.data.vendor.venMonID);
                setVenBIZNo(data.result.data.vendor.venBIZNo);
                setVenNumber(data.result.data.vendor.venNumber);
                setVenPhone(data.result.data.vendor.venPhone);
                setVenFax(data.result.data.vendor.venFax);
                setVenAddress(data.result.data.vendor.venAddress);
                setSupplierTitle(data.result.data.vendor.venTitle);
                setCmpDivID(data.result.data.vendor.cmpDivID);
                setMemo(data.result.data.vendor.venREM);
                if (data.result.data.vendor.vendorBanks != undefined && data.result.data.vendor.vendorBanks != null) {
                    setBankData(data.result.data.vendor.vendorBanks);
                }
                else {
                    setBankData([]);
                }
                setContactData(data.result.data.vendor.vendorContacts);
                setReqFrmType(data.result.data.reqFrmType);
                setReqFrmCFState(data.result.data.reqFrmCFState);
                //陣列
                setCompanyArray(data.result.data.dataCard.companyArray);
                setDepartmentArray(data.result.data.dataCard.departmentArray);
                setSettlementArray(data.result.data.dataCard.monthSettleDaysArray);
                setCurrencyBaseArray(data.result.data.dataCard.currencyBaseList);
                //退回原因
                setReason(data.result.data.reqFrmProcData);
                //審查人
                setAuditorName(data.result.data.auditorName);

            })
            .catch((err) => {
                //console.log(err);
            });
    }
    //審核
    const [reqFrmCFState, setReqFrmCFState] = useState();
    const [reqFrmType, setReqFrmType] = useState();
    //審核人
    const [auditorName, setAuditorName] = useState('');
    const [reason, setReason] = useState();
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyIdArray, setCompanyIdArray] = useState([]);
    const [oldVendoridArray, setOldVendoridArray] = useState([]);
    function companyIdArrayChange(id) {
        var tmpArray = [];
        var f821 = document.getElementsByName(id);
        for (var i = 0; i < f821.length; i++) {
            if (f821[i].checked) {
                tmpArray.push(parseInt(f821[i].value));
            }
        }

        //有找到香港果核
        if (tmpArray.find(x => x == 1) != null) {
            let tmp = settlementArray.find(x => x.name == 90);
            if (tmp && tmp.id == setlementId) {
                setSetlementId(null);
            }
        }

        setCompanyIdArray(tmpArray);
    }

    //產品負責單位
    const [departmentArray, setDepartmentArray] = useState([{}]);
    const [cmpDivID, setCmpDivID] = useState();
    //交易幣別Array
    const [currencyBaseArray, setCurrencyBaseArray] = useState([{}]);

    //備註
    const [memo, setMemo] = useState();
    //月結天數
    const [settlementArray, setSettlementArray] = useState([{}]);
    const [setlementId, setSetlementId] = useState();
    //統編
    const [venBIZNo, setVenBIZNo] = useState("");
    //ERPID
    const [venNumber, setVenNumber] = useState();
    //公司電話
    const [venPhone, setVenPhone] = useState();
    //傳真
    const [venFax, setVenFax] = useState();
    //地址
    const [venAddress, setVenAddress] = useState();
    //供應商抬頭
    const [supplierTitle, setSupplierTitle] = useState("");

    //帳務聯絡人(供應商)
    const [contactData, setContactData] = useState([{ venConName: null, venConTel: null, venConEmail: null },]);
    function addContactData() {
        setContactData(function (prev) {
            return [...prev, { flag: "A", venConName: null, venConTel: null, venConEmail: null },];
        });
    }
    function contactChange(e, index, col) {
        setContactData(function (prev) {
            return prev.map((item, i) => {
                if (item.venConID && item.flag != "D") {
                    item.flag = "M";
                }

                if (i == index) {
                    if (col == "venConName") {
                        item.venConName = e.target.value;
                    }
                    else if (col == "venConTel") {
                        item.venConTel = e.target.value;
                    }
                    else if (col == "venConEmail") {
                        item.venConEmail = e.target.value;
                    }
                }
                return item;
            });
        });
    }
    const [pmName, setPmName] = useState();
    const [pmID, setPmID] = useState();
    const [pmNameArray, setPmNameArray] = useState([{}]);
    function pmNameChange(e) {
        setPmName(e.target.value);
        getPmName();
    }

    //供應商名稱
    const [supplierName, setSupplierName] = useState("");

    function getPmName() {
        if (document.getElementById("pmNameText").value == "") {
            return false;
        }
        API_GET_AdNameList({
            input: document.getElementById("pmNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setPmNameArray(data.result.data);
                }
            })
            .catch((err) => {
                //console.log(err);
            });
    }

    //銀行(供應商)
    const [bankData, setBankData] = useState([{ venBankRemitBank: null, benBankRemitBranch: null, venBankRemitAcnt: null, VenBankExRates: [], },]);
    function venBankChange(e, index, col) {
        setBankData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (item.venBankID && item.flag != "D") {
                        item.flag = "M"
                    }

                    if (col == "venBankRemitBank") {
                        item.venBankRemitBank = e.target.value;
                    } else if (col == "venBankRemitAcnt") {
                        item.venBankRemitAcnt = e.target.value;
                    } else if (col == "venBankRemitBranch") {
                        item.venBankRemitBranch = e.target.value;
                    } else if (col == "venBankRemitSWIFTCode") {
                        item.venBankRemitSWIFTCode = e.target.value;
                    }
                }
                return item;
            });
        });
    }
    //新增匯款銀行
    function addBankData() {
        setBankData(function (prev) {
            return [
                ...prev,
                { flag: "A", venBankRemitBank: null, benBankRemitBranch: null, venBankRemitAcnt: null, VenBankExRates: [] },
            ];
        });
    }
    //刪除聯絡人(供應商)
    function delContactItem(index) {
        var newContactData = [...contactData];

        var tempContactData = newContactData[index];
        if (tempContactData.venConID && tempContactData.venConID > 0) {
            tempContactData.flag = "D";

            newContactData[index] = tempContactData;
            setContactData(newContactData);
        }
        else {
            setContactData(function (prev) {
                return prev.filter((x, i) => i != index);
            });
        }
    }

    //刪除匯款銀行(供應商)
    function delBankItem(index) {
        var newBankData = [...bankData];

        var tempBankData = newBankData[index];
        if (tempBankData.venBankID && tempBankData.venBankID > 0) {
            tempBankData.flag = "D";

            newBankData[index] = tempBankData;
            setBankData(newBankData);
        }
        else {
            setBankData(function (prev) {
                return prev.filter((x, i) => i != index);
            });
        }
    }
    //交易幣別 
    function currencyDataChange() {
        var tmpArray = [];
        var f827 = document.getElementsByName("f827");
        for (var i = 0; i < f827.length; i++) {
            if (f827[i].checked) {
                tmpArray.push({ venBankCurrID: parseInt(f827[i].value) });
            }
        }
        setBankData(function (prev) {
            return prev.map((item, i) => {
                if (i == currencyIndex) {
                    item.venBankExRates = tmpArray;
                }
                return item;
            });
        });
    }
    //資料檢查(供應商)
    function checkData() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-requiredven]");
        requiredlist.forEach((element) => {
            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });
        //負責公司
        if (companyIdArray.length == 0) {
            result = false;
            document.getElementById("companyDIV_V").classList.add("show-help");
        } else {
            document.getElementById("companyDIV_V").classList.remove("show-help");
        }
        //負責單位
        var bf822 = false;
        var f822 = document.getElementsByName("f822");
        for (var i = 0; i < f822.length; i++) {
            if (f822[i].checked) {
                bf822 = true;
                break;
            }
        }
        if (bf822 == false) {
            document.getElementById("cmpDiv").classList.add("show-help");
            result = false;
        } else {
            document.getElementById("cmpDiv").classList.remove("show-help");
        }
        //月結天數
        var bf823 = false;
        var f823 = document.getElementsByName("f823");
        for (var i = 0; i < f823.length; i++) {
            if (f823[i].checked) {
                bf823 = true;
                break;
            }
        }
        if (bf823 == false) {
            document.getElementById("custPayDiv").classList.add("show-help");
            result = false;
        } else {
            document.getElementById("custPayDiv").classList.remove("show-help");
        }
        return result;
    }
    //撤銷
    function Revoke() {
        document.querySelector("#modal-supplierreview").classList.remove("active");
        OpenModal("modal-supplierrevoke");
    }

    //確認再次送審
    function AgainSend() {
        CloseModal("modal-supplieragain");
        if ((reqFrmType == 0 || reqFrmType == 1)) {
            var result = checkData();
            if (result == false) {
                return false;
            }
        }

        if (reqFrmType == 0) {
            //新增
            var VendorsArray = [];
            for (var i = 0; i < companyIdArray.length; i++) {
                VendorsArray.push({
                    VenName: supplierName,
                    VenTitle: supplierTitle,
                    VenBIZNo: venBIZNo,
                    VenNumber: venNumber,
                    VenPhone: venPhone,
                    VenFax: venFax,
                    VenAddress: venAddress,
                    CmpID: parseInt(companyIdArray[i]), //
                    CmpDivID: parseInt(document.querySelector('input[name="f822"]:checked').value), //負責單位
                    VenMonID: parseInt(
                        document.querySelector('input[name="f823"]:checked').value
                    ), //月結天數
                    PMID: pmID,
                    VenRem: memo,
                });
            }
            var dt = {
                companyIdArray: companyIdArray,
                oldVendoridArray: [],
                VendorContacts: contactData,
                VendorBanks: bankData,
                Vendors: VendorsArray,
            };
            //console.log(JSON.stringify(dt));
            API_POST_VendorData(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setModalStr(data.message);
                    if (data.code == "0000" || data.code == "0001") {
                        reGetData();
                        CloseModal("modal-supplierreview");
                        OpenModal("modal-ok");
                    } else {
                        OpenModal("modal-error");
                    }
                })
        } else if (reqFrmType == 1) {
            //修改
            var VendorsArray = [];
            for (var i = 0; i < companyIdArray.length; i++) {
                VendorsArray.push({
                    VenName: supplierName,
                    VenTitle: supplierTitle,
                    VenBIZNo: venBIZNo,
                    VenNumber: venNumber,
                    VenPhone: venPhone,
                    VenAddress: venAddress,
                    VenFax: venFax,
                    CmpID: parseInt(companyIdArray[i]), //
                    CmpDivID: parseInt(
                        document.querySelector('input[name="f822"]:checked').value
                    ), //負責單位
                    VenMonID: parseInt(
                        document.querySelector('input[name="f823"]:checked').value
                    ), //月結天數
                    PMID: pmID,
                    VenRem: memo,
                    reqFrmID: reqFrmID,
                });
            }
            var dt = {
                companyIdArray: companyIdArray,
                oldVendoridArray: oldVendoridArray,
                VendorContacts: contactData,
                VendorBanks: bankData,
                Vendors: VendorsArray,
            };
            //console.log(JSON.stringify(dt));
            API_PUT_VendorData(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setModalStr(data.message);
                    if (data.code == "0000" || data.code == "0001") {
                        reGetData();
                        CloseModal("modal-supplierreview");
                        OpenModal("modal-ok");
                    } else {
                        OpenModal("modal-error");
                    }
                })
        } else if (reqFrmType == 2) {
            //刪除
            var dt = {
                vendorName: supplierName,
                reqFrmID: reqFrmID,
                vendorBanks: bankData,
                vendorContacts: contactData,
                companyIdArray: companyIdArray,
                oldVendoridArray: oldVendoridArray,
            };
            //console.log(JSON.stringify(dt));
            API_DEL_VendorData(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setModalStr(data.message);
                    if (data.code == "0000" || data.code == "0001") {
                        reGetData();
                        CloseModal("modal-supplierreview");
                        OpenModal("modal-ok");
                    } else {
                        OpenModal("modal-error");
                    }
                })
        }
        //if (isOK) {

        //    if (reqFrmCFState == 2) {
        //        setModalStr("再次送審完成");
        //    } else {
        //        setModalStr("審核完成");
        //    }
        //    CloseModal("modal-supplierreview");
        //    OpenModal("modal-ok");
        //} else {
        //    OpenModal("modal-error");
        //}
    }
    //產品審核 退回
    function Reason(index) {
        var isOK = true;
        CloseModal("modal-suppliercheckreason");
        API_PUT_VendorReview({
            reqFrmID: reqFrmID,
            reviewStatus: 2,
            reason: reason,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setModalStr(data.message);
                if (data.code == "0000" || data.code == "0001") {
                    reGetData();
                    CloseModal("modal-supplierreview");
                    OpenModal("modal-ok");
                }
                else {
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {
                //console.log(err);
            });

        //if (isOK) {
        //    if (reqFrmCFState == 2) {
        //        setModalStr("再次送審完成");
        //    } else {
        //        setModalStr("審核完成");
        //    }
        //    reGetData();
        //    CloseModal("modal-supplierreview");
        //    OpenModal("modal-ok");
        //} else {
        //    OpenModal("modal-error");
        //}
    }

    //撤銷
    function RevokeSend() {
        CloseModal("modal-supplierrevoke");
        API_POST_Revoke({
            category: "Vendor",
            reqFrmID: reqFrmID,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setModalStr("撤銷成功");
                    reGetData();
                    OpenModal("modal-ok");
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            });
    }
    //通過
    function Agree() {
        CloseModal("modal-suppliercheckagree");
        var dt = {
            reqFrmID: reqFrmID,
            reviewStatus: 1,
            reason: reason == null ? "同意" : reason,
        };
        //console.log(dt);
        API_PUT_VendorReview(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setModalStr(data.message);
                if (data.code == "0000" || data.code == "0001") {
                    reGetData();
                    CloseModal("modal-supplierreview");
                    OpenModal("modal-ok");
                } else {
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {
                //console.log(err);
            });
    }
    //陣列幾
    const [currencyIndex, setCurrencyIndex] = useState();
    //打開幣別
    function openModalCurrency(index) {
        setCurrencyIndex(index);
        var a = bankData[index].venBankExRates;
        if (a == null) a = [];

        var tmpb = [];
        for (var i = 0; i < a.length; i++) {
            tmpb.push(a[i].venBankCurrID);
        }
        //console.log(tmpb);
        var f827 = document.getElementsByName("f827");
        for (var i = 0; i < f827.length; i++) {
            if (tmpb.indexOf(parseInt(f827[i].value)) >= 0) {
                f827[i].checked = true;
            } else {
                f827[i].checked = false;
            }
        }
        OpenModal("modal-currency8", 2);
    }

    function openModalReason() {
        if (reason == "" || reason === undefined || reason == null) {
            document.getElementById("reason8").classList.add("error");
            return false;
        } else {
            document.getElementById("reason8").classList.remove("error");
        }

        OpenModal("modal-suppliercheckreason", 2);

    }

    function IsAllDisabled() {

        if (reqFrmCFState == 2) {
            if (reqFrmType == 2)
                return true;
            else
                return false;
        }

        return true;
    }

    return (
        <>
            <div id="modal-supplierreview" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-stamp">
                        <div className="circle">
                            <strong>
                                {(() => {
                                    if (reqFrmCFState == 2) {
                                        return "退回";
                                    } else {
                                        if (reqFrmType == 0) {
                                            return "新增";
                                        } else if (reqFrmType == 1) {
                                            return "修改";
                                        } else if (reqFrmType == 2) {
                                            return "刪除";
                                        }
                                    }
                                })()}
                            </strong>
                        </div>
                    </div>
                    <div className="modal-header">
                        <h3 className="fz-B has-border">{supplierName}</h3>
                        <div className="close" data-close>
                            <i className="icon icon-44"></i>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="form-container">
                            <div className="form-item must" id="companyDIV_V">
                                <div className="field">負責公司</div>
                                <div className="value">
                                    {companyArray.map((x) => (
                                        <div>
                                            <input
                                                type="checkbox"
                                                id={`f821-${x.id}`}
                                                name="f821"
                                                value={x.id}
                                                checked={companyIdArray.findIndex((e) => e == x.id) >= 0 ? true : false}
                                                onClick={e => companyIdArrayChange("f821")}
                                                disabled={IsAllDisabled()}
                                            />
                                            <label htmlFor={`f821-${x.id}`}>{x.name}</label>
                                        </div>
                                    ))}
                                </div>
                                <span className="help-word">請選擇負責公司</span>
                            </div>
                            <div className="form-item must half">
                                <div className="field">供應商</div>
                                <div className="value">
                                    <input type="text" value={supplierName} onChange={e => setSupplierName(e.target.value)} disabled={IsAllDisabled()} />
                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>
                            <div className="form-item  half">
                                <div className="field">供應商Number</div>
                                <div className="value">
                                    <input type="text" value={venNumber} onChange={e => setVenNumber(e.target.value)} disabled={IsAllDisabled()} />
                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>

                            <div className="form-item must half">
                                <div className="field">供應商抬頭</div>
                                <div className="value">
                                    <input type="text" value={supplierTitle} onChange={e => setSupplierTitle(e.target.value)} disabled={IsAllDisabled()} />
                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>

                            <div className="form-item  half">
                                <div className="field">供應商統編/ 編號</div>
                                <div className="value">
                                    <input type="text" value={venBIZNo} onChange={e => setVenBIZNo(e.target.value)} disabled={IsAllDisabled()} />
                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>
                            <div className="form-item  half">
                                <div className="field">公司電話</div>
                                <div className="value">
                                    <input type="text" value={venPhone} onChange={e => setPhone(e.target.value)} disabled={IsAllDisabled()} />
                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>
                            <div className="form-item half">
                                <div className="field">公司傳真</div>
                                <div className="value">
                                    <input type="text" value={venFax} onChange={e => setVenFax(e.target.value)} disabled={IsAllDisabled()} />
                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>
                            <div className="form-item must">
                                <div className="field">公司地址</div>
                                <div className="value">
                                    <input type="text" value={venAddress} onChange={e => setVenAddress(e.target.value)} disabled={IsAllDisabled()} />
                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>
                            <div className="form-item must half" id="cmpDiv">
                                <div className="field">業務單位</div>
                                <div className="value">
                                    {departmentArray.map((x) => (
                                        <div>
                                            <input
                                                type="radio"
                                                id={`822-${x.id}`}
                                                name="f822"
                                                value={x.id}
                                                key={x.id}
                                                disabled={IsAllDisabled()}
                                                onClick={(e) => setCmpDivID(x.id)}
                                                checked={cmpDivID == x.id ? "checked" : ""}
                                            />
                                            <label htmlFor={`822-${x.id}`}>{x.name}</label>
                                        </div>
                                    ))}
                                </div>
                                <span className="help-word">請輸入業務單位</span>
                            </div>

                            <div className="form-item must half has-datalist" data-datalist>
                                <div className="field">負責 PM</div>
                                <div className="value">
                                    <input id="pmNameText" type="text" value={pmName} onChange={pmNameChange} disabled={IsAllDisabled()} data-requiredven />
                                    <ul className="datalist scrollbar">
                                        {pmNameArray.map((x) => (
                                            <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => { setPmName(x.enName), setPmID(x.id) }}>
                                                <span className="flex-4">{x.enName}({x.tc_name})</span>
                                                <span className="flex-5">{x.mail}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>

                            <hr />

                            <div className="form-item must" id="custPayDiv">
                                <div className="field">月結天數</div>
                                <div className="value">
                                    {settlementArray.map((x) => {
                                        if (companyIdArray.find(x => x == 1) != null && x.name == 90) {

                                        }
                                        else {
                                            return (
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id={`823-${x.id}`}
                                                        name="f823"
                                                        value={x.id}
                                                        key={x.id}
                                                        onClick={(e) => setSetlementId(x.id)}
                                                        disabled={IsAllDisabled()}
                                                        checked={setlementId == x.id ? "checked" : ""}
                                                    />
                                                    <label htmlFor={`823-${x.id}`}>
                                                        {
                                                            x.name == "1" ? "即期" : x.name + "天"
                                                        }
                                                    </label>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <span className="help-word">請輸入月結天數</span>
                            </div>

                            <div className="form-item col">
                                <div className="field">匯款銀行</div>
                                <div className="value">
                                    <ul className="list-table">
                                        <li className="lt-row lt-th">
                                            <div className="flex-1">序號</div>
                                            <div className="flex-3">銀行名稱</div>
                                            <div className="flex-3">分行</div>
                                            <div className="flex-3">SWIFT Code</div>
                                            <div className="flex-3">匯款帳號</div>
                                            <div className="flex-2">幣別</div>
                                            <div className="flex-2"></div>
                                        </li>
                                        {(() => {
                                            let rows = [];
                                            let index = 1;

                                            bankData.map((x, i) => {
                                                if (IsAllDisabled() == false && x.flag != "D") {
                                                    rows.push(
                                                        <li className="lt-row">
                                                            <div className="flex-1">
                                                                {index < 10 ? `0${index}` : index}
                                                            </div>
                                                            <div className="flex-3">
                                                                <input
                                                                    type="text"
                                                                    value={x.venBankRemitBank ? x.venBankRemitBank : ""}
                                                                    onChange={e => venBankChange(e, i, "venBankRemitBank")}
                                                                    data-requiredven
                                                                />
                                                            </div>
                                                            <div className="flex-3">
                                                                <input
                                                                    type="text"
                                                                    value={x.venBankRemitBranch ? x.venBankRemitBranch : ""}
                                                                    onChange={e => venBankChange(e, i, "venBankRemitBranch")}
                                                                    placeholder="非必填"
                                                                />
                                                            </div>
                                                            <div className="flex-3">
                                                                <input
                                                                    type="text"
                                                                    value={x.venBankRemitSWIFTCode ? x.venBankRemitSWIFTCode : ""}
                                                                    onChange={e => venBankChange(e, i, "venBankRemitSWIFTCode")}
                                                                    placeholder="非必填"
                                                                />
                                                            </div>
                                                            <div className="flex-3">
                                                                <input
                                                                    type="text"
                                                                    value={x.venBankRemitAcnt ? x.venBankRemitAcnt : ""}
                                                                    onChange={e => venBankChange(e, i, "venBankRemitAcnt")}
                                                                    data-requiredven
                                                                />
                                                            </div>
                                                            <div className="flex-2">
                                                                {
                                                                    x.venBankExRates == null || x.venBankExRates.length == 0 ?
                                                                        <div className="btn btn-small btn-border" id={`bank_${i}`} onClick={(e) => openModalCurrency(i)}>
                                                                            <span>選擇幣別</span>
                                                                        </div>
                                                                        :
                                                                        <a href="#" onClick={(e) => openModalCurrency(i)}>
                                                                            {
                                                                                x.venBankExRates.map((x2, i2) =>
                                                                                    currencyBaseArray.map(x3 =>
                                                                                        x3.id == x2.venBankCurrID ? x3.name + (i2 == x.venBankExRates.length - 1 ? '' : '/') : null
                                                                                    )
                                                                                )
                                                                            }
                                                                        </a>
                                                                }
                                                            </div>
                                                            <div className="flex-2">
                                                                {
                                                                    bankData.filter(y => y.flag != "D").length > 1 ?
                                                                        <div className="btn btn-small btn-border" onClick={(e) => delBankItem(i)}>
                                                                            <span>刪除</span>
                                                                        </div>
                                                                        : <></>
                                                                }
                                                            </div>
                                                        </li>
                                                    );
                                                }
                                                else {
                                                    if (x.flag != "D") {
                                                        rows.push(
                                                            <li className="lt-row">
                                                                <div className="flex-1">
                                                                    {index < 10 ? `0${index}` : index}
                                                                </div>
                                                                <div className="flex-3">{x.venBankRemitBank} </div>
                                                                <div className="flex-3">{x.venBankRemitBranch}</div>
                                                                <div className="flex-3">{x.venBankRemitSWIFTCode}</div>
                                                                <div className="flex-3">{x.venBankRemitAcnt}</div>
                                                                <div className="flex-2">
                                                                    {
                                                                        x.venBankExRates == null || x.venBankExRates.length == 0
                                                                            ? ""
                                                                            : x.venBankExRates.map((x2, i2) =>
                                                                                currencyBaseArray.map(x3 =>
                                                                                    x3.id == x2.venBankCurrID ? x3.name + (i2 == x.venBankExRates.length - 1 ? '' : '/') : null
                                                                                )
                                                                            )
                                                                    }
                                                                </div>
                                                                <div className="flex-2"></div>
                                                            </li>
                                                        );
                                                    }
                                                }

                                                index++;
                                            })

                                            return rows;
                                        })()}
                                    </ul>
                                    {
                                        IsAllDisabled() == false ?

                                            <div className="btn-area">
                                                <div class="info"></div>
                                                <div className="btn btn-middle btn-border" onClick={addBankData} >
                                                    <span>新增匯款銀行</span>
                                                </div>
                                            </div> : null
                                    }
                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>

                            <hr />

                            <div className="form-item col must">
                                <div className="field">聯絡人</div>
                                <div className="value">
                                    <ul className="list-table">
                                        <li className="lt-row lt-th">
                                            <div className="flex-1">序號</div>
                                            <div className="flex-3">姓名</div>
                                            <div className="flex-3">聯絡電話</div>
                                            <div className="flex-3">聯絡信箱</div>
                                        </li>
                                        {(() => {
                                            let rows = [];
                                            let index = 1;

                                            contactData.map((x, i) => {
                                                if (IsAllDisabled() == false && x.flag != "D") {
                                                    rows.push(
                                                        <li className="lt-row">
                                                            <div className="flex-1">
                                                                {index < 10 ? `0${index}` : index}
                                                            </div>
                                                            <div className="flex-3">
                                                                <input
                                                                    type="text"
                                                                    value={x.venConName ? x.venConName : ""}
                                                                    onChange={e => contactChange(e, i, "venConName")}
                                                                    data-requiredven
                                                                />
                                                            </div>
                                                            <div className="flex-3">
                                                                <input
                                                                    type="tel"
                                                                    value={x.venConTel ? x.venConTel : ""}
                                                                    onChange={e => contactChange(e, i, "venConTel")}
                                                                    maxLength="12"
                                                                    data-requiredven
                                                                />
                                                            </div>
                                                            <div className="flex-3">
                                                                <input
                                                                    type="email"
                                                                    value={x.venConEmail ? x.venConEmail : ""}
                                                                    onChange={e => contactChange(e, i, "venConEmail")}
                                                                    data-requiredven
                                                                />
                                                            </div>
                                                            <div className="flex-2">
                                                                {
                                                                    contactData.filter(y => y.flag != "D").length > 1 ?
                                                                        <div className="btn btn-small btn-border" onClick={(e) => delContactItem(i)}>
                                                                            <span>刪除</span>
                                                                        </div> : <></>
                                                                }
                                                            </div>
                                                        </li>
                                                    );
                                                }
                                                else {
                                                    if (x.flag != "D") {
                                                        rows.push(
                                                            <li className="lt-row">
                                                                <div className="flex-1">
                                                                    {index < 10 ? `0${index}` : index}
                                                                </div>
                                                                <div className="flex-3">{x.venConName}</div>
                                                                <div className="flex-3">{x.venConTel}</div>
                                                                <div className="flex-3">{x.venConEmail}</div>
                                                            </li>
                                                        );
                                                    }
                                                }

                                                index++;
                                            })

                                            return rows;
                                        })()}
                                    </ul>
                                    {
                                        IsAllDisabled() == false ?
                                            <div className="btn-area">
                                                <div class="info"></div>
                                                <div className="btn btn-small btn-border" onClick={addContactData} >
                                                    <span>新增聯絡人</span>
                                                </div>
                                            </div> : null
                                    }
                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>

                            <div className="form-item aifs">
                                <div className="field">備註</div>
                                <div className="value">
                                    <textarea
                                        name="memo"
                                        id="memo"
                                        cols="30"
                                        rows="10"
                                        value={memo}
                                        disabled={IsAllDisabled()}
                                        onChange={e => setMemo(e.target.value)}
                                    ></textarea>
                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>
                            <div className="form-item btn-area">
                                {
                                    reqFrmCFState == 2 ?
                                        <>
                                            <a className="btn btn-border" onClick={Revoke} >
                                                <span>撤銷</span>
                                            </a>
                                            <a className="btn" onClick={(e) => OpenModal("modal-supplieragain", 2)}>
                                                <span>再次送審</span>
                                            </a>
                                        </>
                                        :
                                        reqFrmCFState == 3 ? null :
                                            <>
                                                <a className="btn btn-border" onClick={openModalReason}>
                                                    <span>退回</span>
                                                </a>
                                                <a className="btn" onClick={(e) => OpenModal("modal-suppliercheckagree", 2)}>
                                                    <span>確定</span>
                                                </a>
                                            </>
                                }

                            </div>
                            <div className="form-item must col feedback">
                                <div className="field">退回原因</div>
                                <div className="value">
                                    <textarea name="reason" id="reason8" cols="30" rows="6" placeholder="請輸入退回原因"
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                        disabled={reqFrmCFState == 2 || reqFrmCFState == 3 ? true : false}
                                    ></textarea>
                                </div>
                                <span className="help-word">請輸入退回原因</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-suppliercheckreason" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確認要退回此筆資料？</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-suppliercheckreason")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={Reason}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-suppliercheckagree" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確認要通過此筆資料？</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-suppliercheckagree")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={Agree}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            <div id="modal-supplieragain" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定再次送審?
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-supplieragain")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={AgainSend}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-supplierrevoke" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定撤銷?
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-supplierrevoke")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={() => RevokeSend()}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-currency8" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">選擇幣別</h3>
                        <span className="close" onClick={() => CloseModal("modal-currency8")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-item no-field">
                            <div className="field">選擇幣別</div>
                            <div className="value">
                                {currencyBaseArray.map((x) => (
                                    <div>
                                        <input
                                            type="checkbox"
                                            id={`f827-${x.id}`}
                                            name="f827"
                                            value={x.id}
                                            key={x.id}
                                            onClick={currencyDataChange}
                                            disabled={reqFrmCFState == 2 ? null : 'disabled'}
                                        />
                                        <label htmlFor={`f827-${x.id}`}>{x.name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-currency8")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VendorDetail;
