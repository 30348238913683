import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_SettlementRevenueDetail
} from "../../global/constants";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, InitJS, NumFormart, MenuSet } from '../../global/ToolUtil';
import OrderDetail from "../Common/OrderDetail"
import { CheckResponseCode } from '../../global/ResponseUtil';
import HtmlParse from 'html-react-parser';

const SettlementProductList = () => {
    const [currencyName, setCurrencyName] = useState("");
    const [currency, setCurrency] = useState("");
    var history = useHistory();
    const [reGet, setReGet] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const [modalStr, setModalStr] = useState();

    useEffect(() => {
        //把menu顏色置換
        MenuSet("SettlementQry");
        getData();

        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    useEffect(() => {
        if (reGet) {
            //內頁資料有刪除/修改 重抓
            getData();
        }
        setReGet(false);
    }, [reGet]);
    function getData() {
        var dt = {
            productName2: queryParams.get("productName2"),
            incomeMonth: queryParams.get("incomeMonth"),
            companyId: queryParams.get("companyId"),
        };
        API_GET_SettlementRevenueDetail(dt)
            .then((response) => response.data)
            .then((data) => {
                ////console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setDataList(data.result.data.data);
                    setProductName(data.result.data.productName2);

                    let currency = data.result.data.currencyName;
                    setCurrency(currency);
                    if (currency == "USD") {
                        setCurrencyName("美金");
                    }
                    else if (currency == "TWD") {
                        setCurrencyName("新台幣");
                    }
                    else {
                        setCurrencyName(currency);
                    }
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error-back-qry");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [dataList, setDataList] = useState([{}]);
    const [productName, setProductName] = useState();
    const [orderId, setOrderId] = useState();
    //打開訂單詳細modal
    function openModalOrder(orderid) {
        setOrderId(orderid);
        OpenModal("modal-order");
    }
    //展開
    function dataOpen(index) {
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.isOpen = (item.isOpen == null || item.isOpen == false) ? true : false;
                }
                return item;
            });
        });
    }
    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container full">
                    <h3 className="fz-B has-border">營收明細：{productName}</h3>
                </div>
            </div>
            <div className="container full">
                <div className="info-holder">
                    <div className="left"></div>
                    <div className="right">幣別：{currencyName}</div>
                </div>
                <ul className="list-table">
                    <li className="lt-row lt-th">
                        <div className="flex-3">客戶別</div>
                        <div className="flex-3">訂單編號</div>
                        <div className="flex-5">公司抬頭</div>
                        <div className="flex-2">統編/ 編號</div>
                        <div className="flex-2">發票狀態</div>
                        <div className="flex-2">入帳月份</div>
                        <div className="flex-2">營收公司</div>
                        <div className="flex-3">產品別</div>
                        <div className="flex-1 fn"></div>

                    </li>
                    {
                        dataList.map((x, i) =>
                            <>
                                <li className={`lt-row ${x.isOpen == true ? 'highlight' : ''}`}>
                                    <div className="flex-3">
                                        <div className="elips-1">{x.customerName}</div>
                                    </div>
                                    <div className="flex-3">
                                        <a href={`/Main?path=SaleOrderEdit&orderId=${x.orderId}`} target="_blank" className={` ${(x.invoiceStatus == 2 || x.invoiceState == 2) ? 'alert' : ''} `}>{x.orderNumber}</a>
                                    </div>
                                    <div className="flex-5">
                                        <div className="elips-1">{x.customerTitle}</div>
                                    </div>
                                    <div className="flex-2">
                                        <div className="elips-1">{x.customerTaxId}</div>
                                    </div>
                                    <div className="flex-2">
                                        <div className="elips-1">
                                            {(() => {
                                                if (x.invoiceState == 0) {
                                                    return "未開";
                                                } else if (x.invoiceState == 1) {
                                                    return "預開";
                                                } else if (x.invoiceState == 2) {
                                                    return "列管";
                                                } else if (x.invoiceState == 3) {
                                                    return "作廢";
                                                } else if (x.invoiceState == 4) {
                                                    return "作廢重開";
                                                } else if (x.invoiceState == 5) {
                                                    return "處理中";
                                                } else if (x.invoiceState == 6) {
                                                    return "已開";
                                                } else if (x.invoiceState == 7) {
                                                    return "列管已開";
                                                } else if (x.invoiceState == 8) {
                                                    return "作廢重開已開";
                                                } else if (x.invoiceState == 100) {
                                                    return "為預開(未開)";
                                                }
                                            })()}
                                        </div>
                                    </div>
                                    <div className="flex-2">
                                        <div className="elips-1">{queryParams.get("incomeMonth")}</div>
                                    </div>
                                    <div className="flex-2">
                                        <div className="elips-1">{x.companyName}</div>
                                    </div>
                                    <div className="flex-3">
                                        <div className="elips-1">{x.productTypeName}</div>
                                    </div>
                                    <div className="flex-1 fn jcfe">
                                        <div className="fn-bar">

                                            <div className={`btn btn-icon ${x.isOpen == true ? 'rotate' : ''}`} onClick={() => dataOpen(i)}>
                                                <i className="icon icon-28"></i>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className={`lt-row ${x.isOpen == true ? '' : 'hide'}`} id={`st-${i}`}>
                                    <ul className="list-table nested">
                                        <li className="lt-row lt-th">
                                            <div className="flex-4">銷售類型</div>
                                            <div className="flex-3">經銷別</div>
                                            <div className="flex-2 jcfe">數量</div>
                                            <div className="flex-4 jcfe">單價</div>
                                            <div className="flex-3 jcfe">營收小計</div>
                                        </li>
                                        <li className="lt-row">

                                            <div className="flex-4">
                                                <div className="elips-1">
                                                    {x.salesTypeName}
                                                </div>
                                            </div>
                                            <div className="flex-3">
                                                <div className="elips-1">{x.costToOther == 1 ? '是' : '否'}</div>
                                            </div>
                                            <div className="flex-2 jcfe">
                                                <div className="elips-1">{NumFormart(x.quantity)}</div>
                                            </div>
                                            <div className="flex-4 jcfe">
                                                <div className="elips-1">{x.exCurrId} {NumFormart(x.unitPrice, 2)}</div>
                                            </div>
                                            <div className="flex-3 jcfe">
                                                <div className="elips-1">{x.exCurrId} {NumFormart(x.unitPrice * x.quantity, 2)} {
                                                    currency == x.exCurrId ? null : '(' + x.baseCurrId + ' ' + NumFormart(x.unirPriceExRate * x.quantity, 2) + ')'
                                                }</div>
                                            </div>


                                        </li>
                                    </ul>

                                </li>
                            </>
                        )
                    }

                </ul>
                <div className="form-container">
                    <div className="form-item btn-area sp">
                        <div className="left">

                        </div>
                        <div className="right">

                            <a className="btn" onClick={() => history.push(`/Main?path=SettlementAnalysisList&companyId=` + queryParams.get("companyId") + "&incomeMonth=" + queryParams.get("incomeMonth"))}>
                                <span>回上一頁</span>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
            <div id="modal-order" className="modal large" data-modal>
                <OrderDetail orderId={orderId} setReGet={setReGet} />
            </div>

            <div id="modal-error-back-qry" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr == null ? '' : HtmlParse(modalStr)}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => {
                                CloseModal("modal-error-back-qry")
                                history.push("/Main?path=SettlementQry");
                            }}>
                                <span>確定</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettlementProductList;
