import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";


const ProductRevokeList = ({ revokeList, setReqFrmID }) => {
    var history = useHistory();

    function openModalReview(frmID) {
        setReqFrmID(null);
        const timeout = setTimeout(() => {
            setReqFrmID(frmID);
        }, 300)
        return () => clearTimeout(timeout)
    }

    return (
        <div id="modal-list-6-2" className="modal large" data-modal>
            <div className="modal-container">
                <div className="modal-header">
                    <h3 className="fz-B has-border">產品新增/修改撤銷</h3>
                    <span className="close" data-close>
                        <i className="icon icon-44">
                        </i>
                    </span>
                </div>
                <div className="modal-body">
                    <div>
                        <ul className="list-table">
                            <li className="lt-row lt-th">
                                <div className="flex-2">類別</div>
                                <div className="flex-2">審核者</div>
                                <div className="flex-2">產品細項</div>
                                <div className="flex-2">產品負責人</div>
                                <div className="flex-2">時間</div>
                                <div className="flex-2"></div>
                            </li>
                            {(revokeList != undefined && revokeList != null ? revokeList.map((x) => (

                                <li className="lt-row">
                                    <div className="flex-2">
                                        {(() => {
                                            if (x.reqFrmType == 0) {
                                                return "新增";
                                            } else if (x.reqFrmType == 1) {
                                                return "修改";
                                            } else if (x.reqFrmType == 2) {
                                                return "刪除";
                                            }
                                        })()}
                                        撤銷
                                    </div>
                                    <div className="flex-2">{x.applier}</div>
                                    <div className="flex-2">{x.productFullName}</div>
                                    <div className="flex-2">{x.personADName}</div>
                                    <div className="flex-2">{x.createdTimeStr}</div>
                                    <div className="flex-2">
                                        <div className="fn-bar">
                                            <div className="btn btn-small btn-border" onClick={(e) => openModalReview(x.reqFrmID)} >
                                                <span>查看</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )) : null)}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductRevokeList;
