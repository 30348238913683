import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_AllCompany,
    API_GET_CustomerTaxId,
    API_GET_SaleOrderDataCard,
    API_GET_OrderNumber,
    API_GET_CustomerName,
    API_GET_SaleOrderUnclosedYearMonth,
} from "../../global/constants";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, InitJS, MenuSet } from '../../global/ToolUtil';
import { CheckResponseCode } from '../../global/ResponseUtil';

const SaleOrderQry = () => {
    var history = useHistory();

    useEffect(() => {
        //把menu顏色置換
        MenuSet("SaleOrderQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    var moment = require("moment");

    function getData() {
        getDataCard();
        
        var deArray = [];
        for (var i = 0; i <= 23; i++) {
            var futureMonth = moment().add(i, "M").format("YYYY/MM");
            deArray.push(futureMonth);
        }
        setDateArray(deArray);
    }

    function getDataCard() {
        API_GET_SaleOrderDataCard()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) { history.push('/') };
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setCompanyArray(data.result.data.companyArray);
            })
            .catch(err => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            });
    }


    //營收月份
    const [incomeMonth, setIncomeMonth] = useState();
    const [dateArray, setDateArray] = useState([]);
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [cmpName, setCmpName] = useState();

    //選擇的營收公司
    const [F101, setF101] = useState();

    const [orderNumber, setOrderNumber] = useState();
    const [orderNumberArray, setOrderNumberArray] = useState([{}]);

    function orderNumberChange(e) {
        setOrderNumber(e.target.value);
        getOrderNumber();
    }

    //客戶別
    const [customerName, setCustomerName] = useState();
    const [customerNameArray, setCustomerNameArray] = useState([{}]);
    function customerNameChange(e) {
        setCustomerName(e.target.value);
        getCustomerName();
    }

    //統編
    const [customerTaxId, setCustomerTaxId] = useState();
    const [customerTaxArray, setCustomerTaxArray] = useState([{}]);
    function customerTaxIdChange(e) {
        var v = e.target.value.replace(/[^A-Za-z0-9]/g, '');
        setCustomerTaxId(v);
        getCustomerTaxId();
    }

    function goQry() {
        var p = "/Main?path=SaleOrderList";
        var isok = true;
        if (F101 === undefined) {
            //xx跳出提醒
            document.getElementById("companyDiv").classList.add("show-help");
            isok = false;
        } else {
            document.getElementById("companyDiv").classList.remove("show-help");
            p += "&companyId=" + F101;
            if (orderNumber != undefined) {
                p += "&orderNumber=" + orderNumber;
            }
            if (customerName != undefined) {
                p += "&customerName=" + customerName;
            }
            if (customerTaxId != undefined) {
                p += "&customerTaxId=" + customerTaxId;
            }
            if (document.getElementById("deArrayText").value != "") {
                p += "&IncomeMonth=" + document.getElementById("deArrayText").value;
            }
        }
        if (isok) {
            history.push(p);
        }

        //history.push(path);
    }
    function getOrderNumber() {
        API_GET_OrderNumber({
            input: document.getElementById("orderNumberText").value,
            companyId: F101,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setOrderNumberArray(data.result.data);
            })
    }

    function getCustomerName() {
        API_GET_CustomerName({
            input: document.getElementById("customerNameText").value,
            companyId: F101
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setCustomerNameArray(data.result.data);
                }
            });
    }
    function getCustomerTaxId() {
        API_GET_CustomerTaxId({
            input: customerTaxId,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    if (data.result.data != null) {
                        setCustomerTaxArray(data.result.data);
                    }
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });;
    }

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container narrow">
                    <h3 className="fz-B has-border">查詢訂單</h3>
                </div>
            </div>
            <div className="container narrow">
                <form action="" className="form-container">
                    <div className="form-item must" id="companyDiv">
                        <div className="field">營收公司</div>
                        <div className="value">
                            {companyArray.map((x) => (
                                <div key={x.id}>
                                    <input
                                        type="radio"
                                        id={`F101-${x.cmpID}`}
                                        name="F101"
                                        value={x.cmpID}
                                        checked={F101 == x.cmpID ? "checked" : ""}
                                        onClick={(e) => {
                                            setF101(x.cmpID);
                                            setCmpName(x.cmpName);
                                            setCustomerName("");
                                        }}
                                    />
                                    <label htmlFor={`F101-${x.cmpID}`}>{x.cmpName}</label>
                                </div>
                            ))}
                        </div>
                        <div className="help-word">請選擇營收公司</div>
                    </div>

                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">訂單編號</div>
                        <div className="value">
                            <input
                                id="orderNumberText"
                                type="text"
                                onChange={orderNumberChange}
                                value={orderNumber}
                            />
                            <ul className="datalist scrollbar">
                                {orderNumberArray.map((x) => (
                                    <li
                                        data-value={x.name}
                                        onClick={(e) => {
                                            setOrderNumber(x.name);
                                        }}
                                    >
                                        <span className="flex-4 bold">{x.name}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">客戶別</div>
                        <div className="value">
                            <input
                                id="customerNameText"
                                type="text"
                                onChange={customerNameChange}
                                value={customerName}
                            />
                            <ul className="datalist scrollbar">
                                {customerNameArray.map((x) => (
                                    <li
                                        data-value={x.name}
                                        onClick={(e) => {
                                            setCustomerName(x.name);
                                        }}
                                    >
                                        <span className="flex-4 bold">{x.name}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">統一編號</div>
                        <div className="value">
                            <input
                                id="customerTaxIdText"
                                type="text"
                                onChange={customerTaxIdChange}
                                value={customerTaxId}
                                maxLength="20"
                            />
                            <ul className="datalist scrollbar">
                                {customerTaxArray.map((x) => (
                                    <li
                                        data-value={x.name}
                                        onClick={(e) => {
                                            setCustomerTaxId(x.name);
                                        }}
                                    >
                                        <span className="flex-4 bold">{x.name}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item">
                        <div className="field">營收月份</div>
                        <div className="value">
                            <select name="" id="deArrayText">
                                <option value="">全部</option>
                                {dateArray.map((x) => (
                                    <option value={x}>{x}</option>
                                ))}
                            </select>
                        </div>
                        <div className="help-word">請輸入營收月份</div>
                    </div>
                    <div className="form-item btn-area sp">
                        <div className="left"></div>
                        <div className="right">
                            <a className="btn" onClick={goQry}>
                                <span>查詢</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SaleOrderQry;
