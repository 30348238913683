import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_SettlementHome,
    API_GET_AdNameList,
    API_GET_Customer3Name,
    API_GET_ProductName1
} from "../../global/constants";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, InitJS, MenuSet } from '../../global/ToolUtil';
import { CheckResponseCode } from '../../global/ResponseUtil';
const SettlementQry = () => {
    var history = useHistory();

    useEffect(() => {
        //把menu顏色置換
        MenuSet("SettlementQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    function getData() {
        API_GET_SettlementHome()
            .then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //負責公司
                    setCompanyArray(data.result.data.companyArray);
                    //營收月份
                    setIncomeMonth(data.result.data.incomeMonth);
                    //細項總稱
                    setProductTypeArray(data.result.data.productTypeArray);
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //共用G
    function productName1Change(e) {
        API_GET_ProductName1({
            input: e.target.value,
            productTypeId: productTypeId
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push('/');
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setProductName1Array(data.result.data);
                }
            })
    }



    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyId, setCompanyId] = useState();
    //產品負責人datalistg
    const [companyError, setCompanyError] = useState(false);

    //營收月份
    const [incomeMonth, setIncomeMonth] = useState();
    //客戶別
    const [custID, setCustID] = useState();
    const [custName, setCustName] = useState("");
    const [custNameArray, setCustNameArray] = useState([{}]);
    function custNameChange(e) {
        setCustName(e.target.value);
        getCustomer3Name(1);
    }
    //客戶抬頭
    const [custTitle, setCustTitle] = useState("");
    const [custTitleArray, setCustomerTitleArray] = useState([{}]);
    function custTitleChange(e) {
        setCustTitle(e.target.value);
        getCustomer3Name(2);
    }
    //客戶統編
    const [custTaxID, setCustTaxID] = useState("");
    const [custTaxIDArray, setCustomerTaxArray] = useState([{}]);
    function custTaxIDChange(e) {
        setCustTaxID(e.target.value);
        getCustomer3Name(3);
    }
    function getAdName() {
        API_GET_AdNameList({
            input: document.getElementById("adNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setAdNameArray(data.result.data);
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [personADName, setPersonADName] = useState("");
    const [personADID, setPersonADID] = useState();
    const [adNameArray, setAdNameArray] = useState([{}]);
    function adNameChange(e) {
        if (e.target.value == '') {
            setPersonADName("");
            setPersonADID(null);
        } else {
            console.log(e.target.value)
            setPersonADName(e.target.value);
            getAdName();
        }
    }

    //產品別
    const [productTypeArray, setProductTypeArray] = useState([{}]);
    const [productTypeId, setProductTypeId] = useState();
    const [productName1, setProductName1] = useState();

    const [productName1Array, setProductName1Array] = useState([{}]);
    //僅顯示未結項目
    const [showNonCloseOnly, setShowNonCloseOnly] = useState();
    //
    const [modalStr, setModalStr] = useState();
    //重新取得 客戶別 客戶別Title 客戶統編 List
    function getCustomer3Name(index) {
        var dt = {};
        if (index == 1) {
            dt = { customerName: document.getElementById("customerNameText").value };
        } else if (index == 2) {
            dt = {
                customerTitle: document.getElementById("customerTitleText").value,
            };
        } else if (index == 3) {
            dt = {
                customerTaxId: document.getElementById("customerTaxIdText").value,
            };
        }

        dt.companyId = companyId;

        API_GET_Customer3Name(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                var dataName = [];
                var dataTitle = [];
                var dataTaxId = [];

                if (data.result != null && data.result.data != null) {
                    if (index == 1) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataName.length; j++) {
                                if (dataName[j].custName == data.result.data[i].custName) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataName.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                    personADName: data.result.data[i].personADName,
                                    personADID: data.result.data[i].personADID,
                                });
                            }
                        }
                        setCustNameArray(dataName);
                    } else if (index == 2) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTitle.length; j++) {
                                if (dataTitle[j].custTitle == data.result.data[i].custTitle) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTitle.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                    personADName: data.result.data[i].personADName,
                                    personADID: data.result.data[i].personADID,
                                });
                            }
                        }
                        setCustomerTitleArray(dataTitle);
                    } else if (index == 3) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTaxId.length; j++) {
                                if (dataTaxId[j].custTaxID == data.result.data[i].custTaxID) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTaxId.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                    personADName: data.result.data[i].personADName,
                                    personADID: data.result.data[i].personADID,
                                });
                            }
                        }
                        setCustomerTaxArray(dataTaxId);
                    }
                }
            })

    }


    function Qry() {
        var path = "/Main?path=SettlementList";
        if (companyId == undefined) {
            setCompanyError(true);
            return false;
        } else {
            setCompanyError(false);
        }
        path += "&companyId=" + companyId;
        path += "&sellYM=" + incomeMonth;
        //console.log(path);
        let confirm = document.querySelector("#c3121")
        var show = confirm.checked == true ? 1 : 0;
        path += "&showNonCloseOnly=" + show;

        if (custName != '' && custName != undefined) {
            path += "&customerName=" + custName;
        }
        if (custTitle != '' && custTitle != undefined) {
            path += "&customerTitle=" + custTitle;
        }
        if (custTaxID != '' && custTaxID != undefined) {
            path += "&customerTaxId=" + custTaxID;
        }
        if (personADID != '' && personADID != undefined) {
            path += "&personInChargeID=" + personADID;
        }
        //console.log(productTypeId);
        if (productTypeId != '' && productTypeId != undefined && productTypeId != NaN) {
            path += "&productTypeId=" + productTypeId;
        }
        if (productName1 != '' && productName1 != undefined) {
            path += "&productName1=" + productName1;
        }
        history.push(path);
    }

    return (
        <>
            <div className="content-body">
                <div className="title-area">
                    <div className="container">
                        <h3 className="fz-B has-border">月結作業</h3>
                    </div>
                </div>
                <div className="container">
                    <div className="form-container">
                        <div className={`form-item must ${companyError ? 'show-help' : ''}`}>
                            <div className="field">營收公司</div>
                            <div className="value">
                                {
                                    companyArray.map((x, index) =>
                                        <div key={index}>
                                            <input type="radio" id={`cmp_${x.id}`} name="company" value={x.id} disabled={x.settlementState == 3 ? true : false} onClick={e => {
                                                setCustName("");
                                                setCustTitle("");
                                                setCustTaxID("");
                                                setCompanyId(x.id)
                                            }} />
                                            <label htmlFor={`cmp_${x.id}`}>{x.name} {x.settlementState == 3 ? '(已月結)' : ''}</label>
                                        </div>
                                    )
                                }
                            </div>
                            <span className="help-word">請選擇營收公司</span>
                        </div>
                        <div className="form-item half disabled">
                            <div className="field">營收月份</div>
                            <div className="value">
                                <input type="text" value={incomeMonth} disabled="disabled" />
                            </div>
                            <div className="help-word">請輸入訂單編號</div>
                        </div>
                        <div className="form-item half">
                            <div className="field">僅顯示未結項目</div>
                            <div className="value">
                                <div>
                                    <input type="checkbox" id="c3121" name="c312" value="c3121" />
                                    <label htmlFor="c3121"></label>
                                </div>
                            </div>
                            <div className="help-word">請輸入訂單編號</div>
                        </div>
                        <div className="form-item half  has-datalist" data-datalist>
                            <div className="field">客戶別</div>
                            <div className="value">
                                <input
                                    id="customerNameText"
                                    type="text"
                                    value={custName}
                                    onChange={custNameChange}
                                    data-required
                                />
                                <ul className="datalist scrollbar">
                                    {custNameArray.map((x, index) => (
                                        <li
                                            key={index}
                                            data-value={x.custName}
                                            onClick={(e) => {
                                                setCustName(x.custName),
                                                    setCustTitle(x.custTitle),
                                                    setCustTaxID(x.custTaxID),
                                                    setCustID(x.custID),
                                                    setPersonADName(x.personADName),
                                                    setPersonADID(x.personADID);
                                            }}
                                        >
                                            <span className="flex-4 bold">{x.custName}</span>
                                            <span className="flex-3">{x.custTitle}</span>
                                            <span className="flex-3">{x.custTaxID}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="help-word">請輸入正確格式</div>
                        </div>

                        <div className="form-item half  has-datalist" data-datalist>
                            <div className="field">公司抬頭</div>
                            <div className="value">
                                <input
                                    id="customerTitleText"
                                    type="text"
                                    onChange={custTitleChange}
                                    value={custTitle}
                                    data-required
                                />
                                <ul className="datalist scrollbar">
                                    {custTitleArray.map((x, index) => (
                                        <li
                                            key={index}
                                            data-value={x.custTitle}
                                            onClick={(e) => {
                                                setCustName(x.custName),
                                                    setCustTitle(x.custTitle),
                                                    setCustTaxID(x.custTaxID),
                                                    setCustID(x.custID),
                                                    setPersonADName(x.personADName),
                                                    setPersonADID(x.personADID);
                                            }}
                                        >
                                            <span className="flex-4 bold">{x.custName}</span>
                                            <span className="flex-3">{x.custTitle}</span>
                                            <span className="flex-3">{x.custTaxID}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <span className="help-word">請輸入正確格式</span>
                        </div>
                        <div className="form-item half  has-datalist" data-datalist>
                            <div className="field">公司統編/ 編號</div>
                            <div className="value">
                                <input
                                    id="customerTaxIdText"
                                    type="text"
                                    onChange={custTaxIDChange}
                                    value={custTaxID}
                                />
                                <ul className="datalist scrollbar">
                                    {custTaxIDArray.map((x, index) => (
                                        <li
                                            key={index}
                                            data-value={x.custTaxID}
                                            onClick={(e) => {
                                                setCustName(x.custName),
                                                    setCustTitle(x.custTitle),
                                                    setCustTaxID(x.custTaxID),
                                                    setCustID(x.custID),
                                                    setPersonADName(x.personADName),
                                                    setPersonADID(x.personADID);
                                            }}
                                        >
                                            <span className="flex-4 bold">{x.custName}</span>
                                            <span className="flex-3">{x.custTitle}</span>
                                            <span className="flex-3">{x.custTaxID}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <span className="help-word">請輸入正確格式</span>
                        </div>

                        <div className="form-item half has-datalist" data-datalist>
                            <div className="field">負責業務</div>
                            <div className="value">
                                <input type="text"
                                    id="adNameText"
                                    autoComplete="off"
                                    onChange={adNameChange} />
                                <ul className="datalist scrollbar">
                                    {adNameArray.map((x, index) => (
                                        <li
                                            key={index}
                                            data-value={`${x.enName}(${x.tc_name})`}
                                            onClick={(e) => {
                                                setPersonADName(x.enName);
                                                setPersonADID(x.id);
                                            }}
                                        >
                                            <span className="flex-4">{x.enName}({x.tc_name})</span>
                                            <span className="flex-5">{x.mail}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <span className="help-word">請輸入正確格式</span>
                        </div>
                        <div className="form-item half">
                            <div className="field">產品別</div>
                            <div className="value">
                                <select name="" id="" onChange={e => setProductTypeId(e.target.value)}>
                                    <option value="">全部</option>
                                    {
                                        productTypeArray.map((x, index) =>
                                            <option key={index} value={x.id}>{x.name}</option>
                                        )
                                    }



                                </select>
                            </div>
                            <span className="help-word">請輸入正確格式</span>
                        </div>

                        <div className="form-item half   has-datalist" data-datalist>
                            <div className="field">產品細項總稱</div>
                            <div className="value">
                                <input type="text" onChange={productName1Change} />
                                <ul className="datalist scrollbar">
                                    {productName1Array.map((x, index) => (
                                        <li
                                            key={index}
                                            data-value={x.productName1}
                                            onClick={(e) => { setProductName1(x.productName1) }}
                                        >
                                            <span className="flex-10 bold">{x.productName1}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="help-word">請輸入訂單編號</div>
                        </div>

                        <div className="form-item btn-area sp">
                            <div className="left"></div>
                            <div className="right">
                                <a className="btn" onClick={Qry}>
                                    <span>確定</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-error")}>

                                <span>確認</span>
                            </a>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default SettlementQry;
