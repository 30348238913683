import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
const CloudAcntAgreeList = ({ agreeList }) => {
    var history = useHistory();

    return (
        <div id="modal-list-11-3" className="modal large" data-modal>
            <div className="modal-container">
                <div className="modal-header">
                    <h3 className="fz-B has-border">雲端新增/ 修改通過</h3>
                    <span className="close" data-close>
                        <i className="icon icon-44"></i>
                    </span>
                </div>
                <div className="modal-body">
                    <ul className="list-table">
                        <li className="lt-row lt-th">
                            <div className="flex-2">類別</div>
                            <div className="flex-2">審核者</div>
                            <div className="flex-2">隸屬公司</div>
                            <div className="flex-2">客戶別</div>
                            <div className="flex-2">公司抬頭</div>
                            <div className="flex-2">雲端細項總稱</div>
                            <div className="flex-2">負責業務</div>
                            <div className="flex-2"></div>
                        </li>
                        {agreeList.map((x) => (
                            <li className="lt-row">
                                <div className="flex-2">
                                    {(() => {
                                        if (x.reqFrmType == 0) {
                                            return "新增";
                                        } else if (x.reqFrmType == 1) {
                                            return "異動";
                                        } else if (x.reqFrmType == 2) {
                                            return "刪除";
                                        }
                                    })()}
                                    通過
                                </div>
                                <div className="flex-2">{x.applicantName}</div>
                                <div className="flex-2">{x.companyName}</div>
                                <div className="flex-2">{x.customerName}</div>
                                <div className="flex-2">{x.customerTitle}</div>
                                <div className="flex-2">{x.productName1}</div>
                                <div className="flex-2">{x.personInChargeName}</div>
                                <div className="flex-2">
                                    {
                                        x.reqFrmType != 2 ?
                                            <div className="fn-bar">

                                                <div className="btn btn-small btn-border" data-close onClick={(e) => history.push('/Main?path=CloudAcntList3&companyId=' + x.ids.split('_')[0] + "&productName1=" + x.productName1 + "&customerId=" + x.ids.split('_')[1] + "&personInChargeId=" + x.userID + "&fromNotify=" + 1 )} >
                                                    <span>查看</span>
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CloudAcntAgreeList;
