import React, { useState, useEffect } from "react";
import {
    API_GET_InvoiceDeleteQry,
    API_GET_InvoiceDelete,
    API_POST_Revoke,
    API_POST_InvoiceDeleteReAudit
} from "../../../global/constants";
import { CloseModal, OpenModal, NumFormart } from "../../../global/ToolUtil";
import { checkAuthToken } from "../../../global/TokenUtil";
import { CheckResponseCode } from '../../../global/ResponseUtil';
import { useHistory } from "react-router-dom";

const InvoiceDetail = ({ reqFrmID, customerName, personInChargeName, companyName, invoiceDate, setModalStr, reGetData }) => {
    var history = useHistory();
    useEffect(() => {
        if (reqFrmID != null &&
            customerName != null &&
            personInChargeName != null &&
            companyName != null &&
            invoiceDate != null
        ) {
            getData();
        }
    }, [reqFrmID, customerName, personInChargeName, companyName, invoiceDate]);

    //重新抓取資料
    function getData() {
        setReason("");
        OpenModal("modal-invoicereview");
        var dt = {
            reqFrmID: reqFrmID,
            customerName: customerName,
            personInChargeName: personInChargeName,
            companyName: companyName,
            invoiceDate: invoiceDate,
        };
        console.log("發票作廢詳細資料傳送資料", JSON.stringify(dt));
        API_GET_InvoiceDeleteQry(dt)
            .then((response) => response.data)
            .then((data) => {
                console.log("發票作廢詳細資料", data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setCompanyId(data.result.data.companyId);
                setCustomerTaxID(data.result.data.customerTaxId);
                setCustomerTitle(data.result.data.customerTitle);
                setComment(data.result.data.comment);
                setTaxable(data.result.data.taxable);
                setExplanation(data.result.data.explanation);
                setDataList(data.result.data.list);
                setS1(data.result.data.totalAmount);
                setS2(data.result.data.totalAmtExTax);
                setS3(data.result.data.tax);
                setReqFrmCFState(data.result.data.reqFrmCFState);
                setReqFrmType(data.result.data.reqFrmType);
                setAuditorName(data.result.data.auditorName);
                setReason(data.result.data.reason);
            })
            .catch((err) => {
                console.log("API_GET_InvoiceDeleteQry的失敗回傳", err)
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [customerTaxId, setCustomerTaxID] = useState();
    const [customerTitle, setCustomerTitle] = useState();
    const [s1, setS1] = useState(0);
    const [s2, setS2] = useState(0);
    const [s3, setS3] = useState(0);
    //公司id
    const [companyId, setCompanyId] = useState();
    //作廢原因
    const [comment, setComment] = useState();
    //備註
    const [explanation, setExplanation] = useState();
    //資料列
    const [dataList, setDataList] = useState();
    //應稅免稅
    const [taxable, setTaxable] = useState(true);

    //審核
    const [reqFrmCFState, setReqFrmCFState] = useState();
    const [reqFrmType, setReqFrmType] = useState();
    //審核人
    const [auditorName, setAuditorName] = useState('');

    //退回原因
    const [reason, setReason] = useState();

    //撤銷
    function Revoke() {
        document.querySelector("#modal-invoicereview").classList.remove("active");
        OpenModal("modal-invoicerevoke");
    }

    //產品審核 退回
    function Reason() {
        var isOK = true;
        CloseModal("modal-accChargecheckreason");
        API_GET_InvoiceDelete({
            reqFrmID: reqFrmID,
            reviewStatus: 2,
            reason: reason,
        })
            .then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code != "0000") {
                    setModalStr(data.message);
                    isOK = false;
                } else {
                    setModalStr("審核完成");
                    reGetData();
                }
            })


        if (isOK) {
            if (reqFrmCFState == 2) {
                setModalStr("再次送審完成");
            } else {
                setModalStr("審核完成");
            }
            CloseModal("modal-invoicereview");
            OpenModal("modal-ok");
        } else {
            OpenModal("modal-error");
        }

    }

    //確認再次送審
    function AgainSend() {
        CloseModal("modal-invoiceagain");
        //if (reqFrmType == 0 || reqFrmType == 1) {//修改 執行checkdata
        //    var result = checkData();
        //    if (result == false) {
        //        return false;
        //    }
        //}


        if (reqFrmType == 0) {
            //新增
            var dt = {
                reqFrmID: reqFrmID,
                select: dataList
            };
            //API 4-21
            console.log(JSON.stringify(dt));
            API_POST_InvoiceDeleteReAudit(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setModalStr(data.message);
                    if (data.code == "0000") {
                        reGetData();
                        CloseModal("modal-invoicereview");
                        OpenModal("modal-ok");
                    } else {
                        OpenModal("modal-error");
                    }
                })
        }
    }

    //撤銷
    function RevokeSend() {
        CloseModal("modal-invoicerevoke");
        API_POST_Revoke({
            category: "InvoiceVoid",
            reqFrmID: reqFrmID,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    reGetData();
                    setModalStr("撤銷成功");
                    OpenModal("modal-ok");
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {

                setModalStr("撤銷失敗");
                OpenModal("modal-error");
            });
    }
    //通過
    function Agree() {
        CloseModal("modal-invoicecheckagree");
        var dt = {
            reqFrmID: reqFrmID,
            reviewStatus: 1,
            reason: reason == null ? "同意" : reason,
        };
        API_GET_InvoiceDelete(dt)
            .then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000" || data.code == "0001") {
                    setModalStr("審核完成");
                    reGetData();
                    CloseModal("modal-invoicereview");
                    OpenModal("modal-ok");
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })

    }
    function openModalReason() {
        if (reason == "" || reason === undefined || reason == null) {
            document.getElementById("reason4").classList.add("error");
            return false;
        } else {
            document.getElementById("reason4").classList.remove("error");
        }
        OpenModal("modal-accChargecheckreason", 2);
    }

    return (
        <>
            <div id="modal-invoicereview" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-stamp">
                        <div className="circle">
                            <strong>
                                {(() => {
                                    if (reqFrmCFState == 2) {
                                        return "退回";
                                    } else {
                                        if (reqFrmCFState == 1) {
                                            return "通過";
                                        } else if (reqFrmCFState == 3) {
                                            return "撤銷";
                                        } else {
                                            return "審核";
                                        }
                                    }
                                })()}
                            </strong>
                        </div>
                    </div>
                    <div className="modal-header">
                        <h3 className="fz-B has-border"> {customerTitle}  {taxable ? '發票' : 'Invoice'}作廢申請表  </h3>
                        <span className="close" onClick={() => CloseModal("modal-invoicereview")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-container">
                            <div className="form-item half">
                                <div className="field">負責業務</div>
                                <div className="value">
                                    <input type="text" value={personInChargeName} disabled="disabled" />
                                </div>
                                <span className="help-word">
                                    請選擇負責公司
                                </span>
                            </div>
                            <div className="form-item half">
                                <div className="field">發票開立月份</div>
                                <div className="value">
                                    <input type="text" value={invoiceDate} disabled="disabled" />

                                </div>
                                <span className="help-word">
                                    請選擇負責公司
                                </span>
                            </div>
                            <div className="form-item">
                                <div className="field">作廢原因</div>
                                <div className="value">
                                    <input type="text" value={explanation} disabled="disabled" />

                                </div>
                                <span className="help-word">
                                    請選擇負責公司
                                </span>
                            </div>
                            <div className="form-item half">
                                <div className="field">公司抬頭</div>
                                <div className="value">
                                    <input type="text" value={customerTitle} disabled="disabled" />

                                </div>
                                <span className="help-word">
                                    請選擇負責公司
                                </span>
                            </div>
                            <div className="form-item half">
                                <div className="field">統一編號</div>
                                <div className="value">
                                    <input type="text" value={customerTaxId} disabled="disabled" />

                                </div>
                                <span className="help-word">
                                    請選擇負責公司
                                </span>
                            </div>
                            <div className="form-item">
                                <div className="field">備註</div>
                                <div className="value">
                                    <input type="text" value={comment} disabled="disabled" />
                                </div>
                                <span className="help-word">
                                    請選擇負責公司
                                </span>
                            </div>
                            <br />
                            <div className="form-item col no-field">
                                <div className="value">
                                    <ul className="list-table no-border">

                                        <li className="lt-row lt-th">
                                            <div className="flex-4">發票品名</div>
                                            <div className="flex-4">產品</div>
                                            <div className="flex-1 center max-60">數量</div>
                                            <div className="flex-1 center max-60">幣別</div>
                                            <div className="flex-2 right">單價</div>
                                            <div className="flex-2 right">費用金額</div>
                                        </li>
                                        {
                                            dataList == null || dataList.length == 0 ? null :
                                                dataList.map(x =>
                                                    <li className="lt-row no-border">
                                                        <div className="flex-4">{x.item1} {" "}{x.item2} {" "}{x.item3} {" "}{x.item4} {" "}{x.item5} </div>
                                                        <div className="flex-4">{x.actPName}</div>
                                                        <div className="flex-1 center max-60">{NumFormart(x.quantity)}</div>
                                                        <div className="flex-1 center max-60">{x.currencyName}</div>
                                                        <div className="flex-2 right">{NumFormart(x.unitPrice)}</div>
                                                        <div className="flex-2 right">{NumFormart(x.unitPrice * x.quantity)}</div>
                                                    </li>
                                                )

                                        }

                                        <li className="lt-row no-hover">
                                            <div className="flex-4"></div>
                                            <div className="flex-4"></div>
                                            <div className="flex-1 center max-60"></div>
                                            <div className="flex-1 center max-60"></div>
                                            <div className="flex-2 right"></div>
                                            <div className="flex-2 right"></div>

                                        </li>
                                        <li className="lt-row no-border">
                                            <div className="flex-1"></div>
                                            <div className="flex-7 center">總計</div>
                                            <div className="flex-1 center max-60"></div>
                                            <div className="flex-1 center max-60">含稅</div>
                                            <div className="flex-2 right"></div>
                                            <div className="flex-2 right fw-bold">{taxable ? NumFormart(s1) : ""}</div>

                                        </li>
                                        <li className="lt-row no-border">
                                            <div className="flex-1"></div>
                                            <div className="flex-7 center">銷售額合計</div>
                                            <div className="flex-1 center max-60"></div>
                                            <div className="flex-1 center max-60">未稅</div>
                                            <div className="flex-2 right"></div>
                                            <div className="flex-2 right fw-bold">{NumFormart(s2)}</div>

                                        </li>
                                        <li className="lt-row no-border">
                                            <div className="flex-1"></div>
                                            <div className="flex-7 center">
                                                <div className="form-item auto-field for-4521">
                                                    <div className="value multi-inline">
                                                        <div>營業稅</div>
                                                        <div>
                                                            <input type="radio" id="f4521-1" checked={taxable ? true : false} disabled />
                                                            <label for="f4521-1">應稅</label>
                                                        </div>
                                                        <div>
                                                            <input type="radio" id="f4521-3" checked={false} disabled />
                                                            <label for="f4521-1">零稅</label>
                                                        </div>
                                                        <div>
                                                            <input type="radio" id="f4521-2" checked={!taxable ? true : false} disabled />
                                                            <label for="f4521-2">免稅</label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="flex-1 center max-60"></div>
                                            <div className="flex-1 center max-60">5%</div>
                                            <div className="flex-2 right"></div>
                                            <div className="flex-2 right fw-bold">{taxable ? NumFormart(s3) : ""}</div>

                                        </li>
                                        <li className="lt-row no-border">
                                        </li>
                                    </ul>
                                </div>
                                <div className="help-word">
                                    請輸入正確格式
                                </div>
                            </div>
                            <div className="form-item btn-area">
                                {
                                    reqFrmCFState == 1 || reqFrmCFState == 3 ? //同意或撤銷
                                        <>
                                            <a className="btn" onClick={(e) => CloseModal("modal-invoicereview")}>
                                                <span>確定</span>
                                            </a>
                                        </>
                                        :
                                        reqFrmCFState == 2 ? //退回
                                            <>
                                                <a className="btn btn-border" onClick={Revoke} >
                                                    <span>撤銷</span>
                                                </a>
                                                <a className="btn" onClick={() => OpenModal("modal-invoiceagain", 2)}>
                                                    <span>再次送審</span>
                                                </a>
                                            </>
                                            :
                                            reqFrmCFState == 0 ?  //請求
                                                <>
                                                    <a className="btn btn-border" onClick={openModalReason}>
                                                        <span>退回</span>
                                                    </a>
                                                    <a className="btn" onClick={(e) => OpenModal("modal-invoicecheckagree", 2)}>
                                                        <span>確定</span>
                                                    </a>

                                                </> :
                                                null

                                }
                            </div>
                            {
                                reqFrmCFState != 1 ?
                                    <div className="form-item must col feedback">
                                        <div className="field">退回原因</div>
                                        <div className="value">
                                            <textarea
                                                name="reason"
                                                id="reason4"
                                                cols="30"
                                                rows="6"
                                                placeholder="請輸入退回原因"
                                                value={reason}
                                                onChange={(e) => setReason(e.target.value)}
                                                disabled={reqFrmCFState == 2 || reqFrmCFState == 3 ? true : false}
                                            ></textarea>
                                        </div>
                                        <span className="help-word">請輸入退回原因</span>
                                    </div> : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-accChargecheckreason" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確認要退回此筆資料？</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-accChargecheckreason")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={Reason}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-invoicecheckagree" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確認要通過此筆資料？</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-invoicecheckagree")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={Agree}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-invoiceagain" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定再次送審?
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-invoiceagain")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={AgainSend}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-invoicerevoke" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定撤銷?
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-invoicerevoke")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={RevokeSend}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default InvoiceDetail;
