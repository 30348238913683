import React, { useState, useEffect } from "react";
import HtmlParse from 'html-react-parser';
import { useHistory } from "react-router-dom";
import { DebounceInput } from 'react-debounce-input';
import { NewNumFormart, NumFormart, Clone, CloseModal, OpenModal } from "../../../global/ToolUtil";
import { checkAuthToken } from "../../../global/TokenUtil";
import { CheckResponseCode } from '../../../global/ResponseUtil';
import { API_GET_AccChargeOffReviewAuditMain, API_POST_AccountChargeOffauditSign } from "../../../global/constants";
import { Tooltip } from 'react-tooltip'

const AccountChargeOffDetail = ({ reqFrmID, ReviewType, setModalStr, reGetData }) => {
    var history = useHistory();
    //const [comment, setComment] = useState(); //作廢原因
    //const [explanation, setExplanation] = useState(); //備註
    const [ARStr, setARstr] = useState(); //金額不符原因
    const defaultArr = {
        submitor: '',
        applicant: '',
        recAccount: '',
        manager: '',
        payNumber: '',
        recAccountType: '',
        applyDate: '',
        baseAmt: 0,
        baseCurr: '',
        exAmt: 0,
        exCurr: '',
        isAccountant: 0,
        companyName: '',
        recDetailList: [
            {
                custName: '',
                amt: '',
                exRate: 0,
                ticketNo: '',
                lst: [
                    {
                        arNumber: '',
                        invoiceDate: '',
                        invoiceNo: '',
                        baseAmt: 0,
                        recAmt: 0,
                        writeOffAmt: '',
                        adjustAmt: 0,
                        remark: ''
                    }
                ],
                tempList: [
                    {
                        cawid: '',
                        cawCustName: '',
                        cawAmtsIn: 0,
                        cawAmtsExRate: 0,
                        cawRem: '',
                        datatype: 0,
                        createdTime: null
                    }
                ]
            }
        ]
    };
    const [dataList, setDataList] = useState(defaultArr); //主要資料
    const [reqFrmCFState, setReqFrmCFState] = useState(); //審核
    const [reqFrmType, setReqFrmType] = useState();
    const [auditorName, setAuditorName] = useState(''); //審核人
    const [reason, setReason] = useState(); //退回原因
    const [exRateError, setexRateError] = useState(false); //Error
    const [exadjustAmtError, setexadjustAmtError] = useState(false);

    //useEffect(() => {
    //    const timeout = setTimeout(() => {

    //    }, 1000)
    //    return () => clearTimeout(timeout)
    //}, []);

    //#region 依據繳款憑單id取得資料
    useEffect(() => {
        if (reqFrmID != null) {
            getData();
        }
    }, [reqFrmID]);
    //#endregion

    //#region 取得資料
    function getData() {
        OpenModal("modal-AccChargereview");
        setReason("");
        var dt = {
            cawoAuditId: reqFrmID,
        };
        API_GET_AccChargeOffReviewAuditMain(dt)
            .then(res => res.data)
            .then(data => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setDataList(data.result.data);
                }
                else {
                    setDataList(defaultArr);
                }
            })

    }
    //#endregion

    ////第一層
    //function DataListChangeLV1(e, index, col) {

    //    setDataList(function (prev) {
    //        return prev.recDetailList.map((item, i) => {
    //            if (i == index) {
    //                if (col == "exRate") {
    //                    item.exRate = e.target.value;
    //                }
    //            }
    //            return item;
    //        });
    //    });
    //}

    //第二層(原本寫法)
    //function DataListChangeLV2(e, index, index2, col) {
    //    var tmpbaseAmt = 0;
    //    setDataList(function (prev) {
    //        prev.recDetailList = prev.recDetailList.map((item, i) => {
    //            if (i == index) {
    //                if (col == "exRate") {

    //                    var arr = (e.target.value + "").split('.');
    //                    if (arr.length >= 2) {
    //                        e.target.value = (arr[0] + "").replace(/[^\d]/g, '') + "." + (arr[1] + "").replace(/[^\d]/g, '').substring(0, 15);
    //                    }

    //                    item.exRate = ((e.target.value + "").replace(/[^0-9_.]/g, ''));
    //                    //item.exRate = e.target.value;
    //                }
    //                //第二層index不小於等於0
    //                if (index2 >= 0) {
    //                    for (var i2 = 0; i2 < item.lst.length; i2++) {
    //                        if (i2 == index2) {
    //                            if (col == "adjustAmt") {
    //                                //e.target.value.replace(/\,/g, '')
    //                                item.lst[i2].adjustAmt = (e.target.value + "").replace(/\,/g, '').replace("^[0-9]+(.[0-9]{3})?$", '');
    //                            }
    //                        }
    //                    }
    //                }
    //            }

    //            for (var i2 = 0; i2 < item.lst.length; i2++) {
    //                var val1 = parseFloat((item.lst[i2].recAmt + "").replace(/,/g, ''));
    //                if (isNaN(val1)) {
    //                    val1 = 0;
    //                }
    //                var val2 = new Intl.NumberFormat().format(parseFloat(item.exRate));// 230630 貨幣格式
    //                if (isNaN(val2)) {
    //                    val2 = 0;
    //                }
    //                var val3 = parseFloat((item.lst[i2].adjustAmt + "").replace(/,/g, ''));
    //                if (isNaN(val3)) {
    //                    val3 = 0;
    //                }
    //                if (prev.companyName == "台灣果核" || prev.companyName == "台灣核智") {
    //                    tmpbaseAmt += parseFloat(Math.round(val1 * val2)) + val3;
    //                }
    //                else {
    //                    tmpbaseAmt += (val1 * val2) + val3;
    //                }
    //                tmpbaseAmt = parseFloat(new Intl.NumberFormat().format(tmpbaseAmt));
    //            }
    //            for (var i2 = 0; i2 < item.tempList.length; i2++) {
    //                var val1 = parseFloat((item.tempList[i2].cawAmtsIn + "").replace(/,/g, ''));
    //                if (isNaN(val1)) {
    //                    val1 = 0;
    //                }
    //                var val2 = new Intl.NumberFormat().format(parseFloat(item.exRate));
    //                if (isNaN(val2)) {
    //                    val2 = 0;
    //                }
    //                var val3 = parseFloat((item.tempList[i2].adjustAmt + "").replace(/,/g, ''));
    //                if (isNaN(val3)) {
    //                    val3 = 0;
    //                }
    //                if (prev.companyName == "台灣果核" || prev.companyName == "台灣核智") {
    //                    tmpbaseAmt += parseFloat(Math.round(val1 * val2)) + val3;
    //                }
    //                else {
    //                    tmpbaseAmt += parseFloat(parseFloat(parseFloat(val1 * val2).toFixed(2)) + val3);
    //                }
    //                tmpbaseAmt = parseFloat(new Intl.NumberFormat().format(tmpbaseAmt));
    //            }

    //            return item;
    //        });
    //        return Clone(prev);
    //    });
    //    setDataList(function (prev) {
    //        prev.baseAmt = tmpbaseAmt.toFixed(2).replace(".00", "");
    //        //alert(prev.baseAmt);
    //        return prev;
    //    })
    //}

    //#region 出納審查(修改[匯率]或[調節金額])
    function DataListChangeLV2(e, index, index2, col) {
        var tmpbaseAmt = 0.00;

        var newDateList = { ...dataList };

        if (col == "exRate") { //匯率
            var arr = (e.target.value + "").split('.');
            if (arr.length >= 2) {
                e.target.value = (arr[0] + "").replace(/[^\d]/g, '') + "." + (arr[1] + "").replace(/[^\d]/g, '').substring(0, 15);
            }

            newDateList.recDetailList[index].exRate = (e.target.value + "").replace(/[^0-9.]/g, '');
        }
        else if (col == "adjustAmt") { //調節金額
            //.replace("^[0-9]+(.[0-9]{3})?$", '') 不懂這段目的是為了啥
            if ((e.target.value + "").replace(/\,/g, '') == "") {
                newDateList.recDetailList[index].lst[index2].adjustAmt = 0;
            }
            else {
                newDateList.recDetailList[index].lst[index2].adjustAmt = (e.target.value + "").replace(/\,/g, '');
            }
        }

        newDateList.recDetailList.forEach((recDetail, recDetailIndex) => {
            var exRate = parseFloat(recDetail.exRate);
            exRate = !isNaN(exRate) ? exRate : 0;

            //一般款
            recDetail.lst.forEach((item, itemIndex) => {
                var recAmt = parseFloat((item.recAmt + "").replace(/,/g, ''));
                recAmt = !isNaN(recAmt) ? recAmt : 0;

                var adjustAmt = parseFloat((item.adjustAmt + "").replace(/,/g, ''));
                adjustAmt = !isNaN(adjustAmt) ? adjustAmt : 0;

                if (newDateList.companyName == "台灣果核" || newDateList.companyName == "台灣核智") {
                    tmpbaseAmt += parseFloat((recAmt * exRate).toFixed()) + adjustAmt;
                }
                else {
                    tmpbaseAmt += parseFloat((recAmt * exRate + adjustAmt).toFixed());
                }
            });

            //暫收款
            recDetail.tempList.forEach((item, itemIndex) => {
                var cawAmtsIn = parseFloat((item.cawAmtsIn + "").replace(/,/g, ''));
                cawAmtsIn = !isNaN(cawAmtsIn) ? cawAmtsIn : 0;

                if (newDateList.companyName == "台灣果核" || newDateList.companyName == "台灣核智") {
                    tmpbaseAmt += parseFloat((cawAmtsIn * exRate).toFixed());
                }
                else {
                    tmpbaseAmt += parseFloat((cawAmtsIn * exRate).toFixed(2));
                }
            });
        });

        newDateList.baseAmt = tmpbaseAmt;
        setDataList(newDateList);
    }
    //#endregion

    //#region 確認1/2 按鈕
    function Apply() {
        var ARList = "";
        dataList.recDetailList.forEach(f => {
            f.lst.forEach(fl => {
                var recAmt = parseFloat((fl.recAmt + "").toString().replace(/,/g, ''));
                var adjustAmt = parseFloat((fl.adjustAmt + "").toString().replace(/,/g, ''));

                //console.log(fl.baseAmt + "_" + (recAmt * f.exRate + adjustAmt))

                //if (fl.baseAmt != (recAmt * f.exRate + adjustAmt)) {
                //    ARList += fl.arNumber + ',';
                //}

                var tempAmount = 0.00;
                if (dataList.companyName == "台灣果核" || dataList.companyName == "台灣核智") {
                    tempAmount = parseFloat((recAmt * f.exRate).toFixed()) + adjustAmt;
                }
                else {
                    tempAmount = parseFloat(((recAmt * f.exRate) + adjustAmt).toFixed(2));
                }

                if (fl.baseAmt != tempAmount) {
                    ARList += fl.arNumber + ',';
                }
            })
        })
        if (ARList.endsWith(",")) {
            ARList = ARList.substring(0, ARList.length - 1)
        }
        if (ARList != "") {
            setARstr("AR編號:[" + ARList + "]");
            OpenModal("modal-AccChargeMoneyNC", 3);
        }
        else {
            OpenModal('modal-AccChargeecheckagree', 3);
        }
    }
    //#endregion

    //#region 確認退回按鈕
    function Reason() {
        var isOK = true;
        CloseModal("modal-AccChargecheckreason");
        SendApi(2);
        //API_GET_InvoiceDelete({
        //    reqFrmID: reqFrmID,
        //    reviewStatus: 2,
        //    reason: reason,
        //})
        //    .then((response) => response.data)
        //    .then((data) => {
        //        //console.log(data);
        //        if (!checkAuthToken(data)) {
        //            history.push("/");
        //        }
        //        if (data.code != "0000") {
        //            setModalStr(data.message);
        //            isOK = false;
        //        } else {
        //            setModalStr("審核完成");
        //        }
        //    })


        //if (isOK) {
        //    if (reqFrmCFState == 2) {
        //        setModalStr("再次送審完成");
        //    } else {
        //        setModalStr("審核完成");
        //    }
        //    CloseModal("modal-AccChargereview");
        //    OpenModal("modal-ok");
        //} else {
        //    OpenModal("modal-error");
        //}
    }
    //#endregion

    //#region 確認通過按鈕
    function Agree() {
        CloseModal("modal-AccChargeecheckagree");
        SendApi(1);

        //setModalStr("審核完成");
        //OpenModal("modal-ok");
        //var dt = {
        //    reqFrmID: reqFrmID,
        //    reviewStatus: 1,
        //    reason: reason == null ? "同意" : reason,
        //};
        //API_GET_InvoiceDelete(dt)
        //    .then((response) => response.data)
        //    .then((data) => {
        //        //console.log(data);
        //        if (!checkAuthToken(data)) {
        //            history.push("/");
        //        }
        //        if (data.code != "0000") {
        //            setModalStr(data.message);
        //            OpenModal("modal-error");
        //        } else {
        //            setModalStr("審核完成");
        //            CloseModal("modal-AccChargereview");
        //            OpenModal("modal-ok");
        //        }
        //    })
    }
    //#endregion

    //#region 撤銷(未使用)
    function Revoke() {
        document.querySelector("#modal-AccChargereview").classList.remove("active");
        OpenModal("modal-invoicerevoke");
    }
    //#endregion

    //#region 儲存通過/退回 繳款憑單
    function SendApi(result) {
        var lstArray = [];
        var tmpArray = [];
        for (var i = 0; i < dataList.recDetailList.length; i++) {

            if (dataList.recDetailList[i].lst != null && dataList.recDetailList[i].lst.length > 0) {
                for (var j = 0; j < dataList.recDetailList[i].lst.length; j++) {
                    dataList.recDetailList[i].lst[j].exRate = dataList.recDetailList[i].exRate;
                    lstArray.push(dataList.recDetailList[i].lst[j]);
                }
            }
            if (dataList.recDetailList[i].tempList != null && dataList.recDetailList[i].tempList.length > 0) {
                for (var j = 0; j < dataList.recDetailList[i].tempList.length; j++) {
                    dataList.recDetailList[i].tempList[j].exRate = dataList.recDetailList[i].exRate;
                    tmpArray.push(dataList.recDetailList[i].tempList[j]);
                }
            }
        }
        var dt = {
            auditFormId: reqFrmID,
            result: result,
            comment: result == 1 ? reason : "",
            rejectReason: result == 2 ? reason : "",
            lst: lstArray,
            tempList: tmpArray,
        };

        API_POST_AccountChargeOffauditSign(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code != "0000") {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                } else {
                    reGetData();
                    setModalStr("審核完成");
                    OpenModal("modal-ok");
                    CloseModal("modal-AccChargereview");
                }
            })
            .catch((err) => {
                setModalStr("錯誤");
                OpenModal("modal-error");
            });
    }
    //#endregion

    //#region 開啟退回確認modal
    function openModalReason() {
        if (reason == "" || reason === undefined || reason == null) {
            document.getElementById("reason4").classList.add("error");
            OpenModal("modal-rejectReason");
            return false;
        } else {
            document.getElementById("reason4").classList.remove("error");
        }
        OpenModal("modal-AccChargecheckreason", 2);
    }
    //#endregion

    return (
        <>
            <div id="modal-AccChargereview" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-stamp">
                        <div className="circle">
                            <strong>
                                {(() => {
                                    if (dataList.auditStatus == 4) {
                                        return "退回";
                                    }
                                    else if (dataList.auditStatus < 3) {
                                        return "審核";
                                    }
                                    else if (dataList.auditStatus == 3) {
                                        return "通過";
                                    }
                                })()}
                            </strong>
                        </div>
                    </div>
                    <div className="modal-header">
                        <h3 className="fz-B has-border">{dataList.companyName}繳款憑單</h3>
                        {/*  <div className="right">審查人：Nick Fury (尼克福瑞) </div>*/}
                        <span className="close" onClick={() => CloseModal("modal-AccChargereview")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-container">
                            <div className="form-item middle-field no-help half">
                                <div className="field">填寫人</div>
                                <div className="value">
                                    <input type="text" value={dataList.submitor} disabled="disabled" />
                                </div>
                                <span className="help-word">
                                </span>
                            </div>
                            <div className="form-item middle-field no-help half">
                                <div className="field">繳款單號</div>
                                <div className="value">
                                    <input type="text" value={dataList.payNumber} disabled="disabled" />
                                </div>
                                <span className="help-word">
                                </span>
                            </div>
                            <div className="form-item middle-field no-help half">
                                <div className="field">申請人</div>
                                <div className="value">
                                    <input type="text" value={dataList.applicant} disabled="disabled" />
                                </div>
                                <span className="help-word">
                                </span>
                            </div>
                            <div className="form-item middle-field no-help four">
                                <div className="field">收款類別</div>
                                <div className="value">
                                    <input type="text" value={dataList.recAccountType} disabled="disabled" />
                                </div>
                                <span className="help-word">
                                </span>
                            </div>
                            <div className="form-item middle-field no-help four">
                                <div className="field">申請日期</div>
                                <div className="value">
                                    <input type="text" value={dataList.applyDate} disabled="disabled" />
                                </div>
                                <span className="help-word">
                                </span>
                            </div>
                            <div className="form-item middle-field no-help half">
                                <div className="field">收款帳號</div>
                                <div className="value">
                                    <input type="text" value={dataList.recAccount} disabled="disabled" />
                                </div>
                                <span className="help-word">
                                </span>
                            </div>
                            <div className="form-item middle-field no-help four">
                                <div className="field">沖銷帳本總金額</div>
                                <div className="value">
                                    <input type="text" value={(dataList.companyName == "台灣果核" || dataList.companyName == "台灣核智" ? NewNumFormart(Math.round(dataList.baseAmt)) : NewNumFormart(dataList.baseAmt))} disabled="disabled" />
                                </div>
                                <span className="help-word">
                                </span>
                            </div>
                            <div className="form-item middle-field no-help four">
                                <div className="field">幣別</div>
                                <div className="value">
                                    <input type="text" value={dataList.baseCurr} disabled="disabled" />
                                </div>
                                <span className="help-word">
                                </span>
                            </div>
                            <div className="form-item middle-field no-help half">
                                {dataList.auditStatus == 2 ?
                                    <>
                                        <div className="field">審核主管</div>
                                        <div className="value">
                                            <input type="text" value={dataList.manager} disabled="disabled" />
                                        </div>
                                        <span className="help-word">
                                        </span>
                                    </>
                                    :
                                    <>
                                    </>
                                }
                            </div>
                            <div className="form-item middle-field no-help four">
                                <div className="field">沖銷外幣總金額</div>
                                <div className="value">
                                    <input type="text" value={NumFormart(parseFloat(dataList.exAmt.toFixed(2)))} disabled="disabled" />
                                </div>
                                <span className="help-word">
                                </span>
                            </div>
                            <div className="form-item middle-field no-help four">
                                <div className="field">幣別</div>
                                <div className="value">
                                    <input type="text" value={dataList.exCurr} disabled="disabled" />

                                </div>
                                <span className="help-word">

                                </span>
                            </div>
                        </div>
                        <hr style={{marginTop:"35px"}} />
                        <h3 className="fz-C">收款明細</h3>
                        {
                            dataList.recDetailList.map((x, xi) => (
                                <ul className="list-table" style={{ border: "1px #ccc solid", "margin": "10px" }}>
                                    <li className="lt-row lt-th">
                                        <div className="flex-4">客戶名稱</div>
                                        <div className="flex-4 center">項目說明</div>
                                        <div className="flex-1 center">收款來源</div>
                                        <div className="flex-4 center">單號</div>
                                    </li>

                                    <li className="lt-row no-border">
                                        <div className="flex-4">{x.custName}</div>
                                        <div className="flex-4 center">收款</div>
                                        <div className="flex-1 center">發票</div>
                                        <div className="flex-4 center"></div>
                                    </li>
                                    <li className="lt-row lt-th">
                                        <div className="flex-4">Receipt Number</div>
                                        <div className="flex-4 center">收款總額</div>
                                        <div className="flex-1 center">匯率</div>
                                        <div className="flex-4 center">備註</div>
                                    </li>
                                    <li className="lt-row no-border">
                                        <div className="flex-4">
                                        </div>
                                        <div className="flex-4 center">{/*收款總額*/}
                                            {(() => {
                                                var tempTotalAmount = 0.00;

                                                {/*一般*/ }
                                                x.lst.map((item) => {
                                                    tempTotalAmount += item.recAmt;
                                                });

                                                {/*暫收款*/ }
                                                x.tempList.map((item) => {
                                                    tempTotalAmount += item.cawAmtsIn
                                                });

                                                return NewNumFormart(parseFloat(tempTotalAmount.toFixed(2)));
                                            })()}

                                            {/*原本寫法*/}
                                            {/*{*/}
                                            {/*    dataList.companyName == "台灣果核" || dataList.companyName == "台灣核智" ?*/}
                                            {/*        NewNumFormart((x.lst.reduce(function (accumulator, curValue) { // 是台灣果核或台灣核智*/}
                                            {/*            var val1 = parseFloat((curValue.recAmt + "").replace(/,/g, ''));*/}
                                            {/*            if (isNaN(val1)) {*/}
                                            {/*                val1 = 0;*/}
                                            {/*            }*/}
                                            {/*            var val2 = parseFloat(x.exRate);*/}
                                            {/*            if (isNaN(val2)) {*/}
                                            {/*                val2 = 0;*/}
                                            {/*            }*/}
                                            {/*            var val3 = parseFloat((curValue.adjustAmt + "").replace(/,/g, ''));*/}
                                            {/*            if (isNaN(val3)) {*/}
                                            {/*                val3 = 0;*/}
                                            {/*            }*/}
                                            {/*            return (accumulator + Math.round(val1 * val2) + val3) //- val3*/}
                                            {/*        }, 0) +*/}
                                            {/*            x.tempList.reduce(function (accumulator, curValue) {*/}
                                            {/*                var val1 = parseFloat(curValue.cawAmtsIn);*/}
                                            {/*                if (isNaN(val1)) {*/}
                                            {/*                    val1 = 0;*/}
                                            {/*                }*/}
                                            {/*                var val2 = parseFloat(x.exRate);*/}
                                            {/*                if (isNaN(val2)) {*/}
                                            {/*                    val2 = 0;*/}
                                            {/*                }*/}
                                            {/*                return (accumulator + Math.round(val1 * val2))*/}
                                            {/*            }, 0) - x.lst.reduce(function (accumulator, curValue) {*/}
                                            {/*                var val3 = parseFloat((curValue.adjustAmt + "").replace(/,/g, ''));*/}
                                            {/*                if (isNaN(val3)) {*/}
                                            {/*                    val3 = 0;*/}
                                            {/*                }*/}
                                            {/*                return (accumulator + val3) //- val3*/}
                                            {/*            }, 0)) / x.exRate*/}
                                            {/*        )*/}
                                            {/*        :*/}
                                            {/*        NewNumFormart((x.lst.reduce(function (accumulator, curValue) {// 不是台灣果核或台灣核智*/}
                                            {/*            var val1 = parseFloat((curValue.recAmt + "").replace(/,/g, ''));*/}
                                            {/*            if (isNaN(val1)) {*/}
                                            {/*                val1 = 0;*/}
                                            {/*            }*/}
                                            {/*            var val2 = parseFloat(x.exRate);*/}
                                            {/*            if (isNaN(val2)) {*/}
                                            {/*                val2 = 0;*/}
                                            {/*            }*/}
                                            {/*            var val3 = parseFloat((curValue.adjustAmt + "").replace(/,/g, ''));*/}
                                            {/*            if (isNaN(val3)) {*/}
                                            {/*                val3 = 0;*/}
                                            {/*            }*/}
                                            {/*            return (accumulator + val1 * val2 + val3) //- val3*/}
                                            {/*        }, 0) +*/}
                                            {/*            x.tempList.reduce(function (accumulator, curValue) {*/}
                                            {/*                var val1 = parseFloat(curValue.cawAmtsIn);*/}
                                            {/*                if (isNaN(val1)) {*/}
                                            {/*                    val1 = 0;*/}
                                            {/*                }*/}
                                            {/*                var val2 = parseFloat(x.exRate);*/}
                                            {/*                if (isNaN(val2)) {*/}
                                            {/*                    val2 = 0;*/}
                                            {/*                }*/}
                                            {/*                return (accumulator + val1 * val2)*/}
                                            {/*            }, 0) - x.lst.reduce(function (accumulator, curValue) {*/}
                                            {/*                var val3 = parseFloat((curValue.adjustAmt + "").replace(/,/g, ''));*/}
                                            {/*                if (isNaN(val3)) {*/}
                                            {/*                    val3 = 0;*/}
                                            {/*                }*/}
                                            {/*                return (accumulator + val3) //- val3*/}
                                            {/*            }, 0)) / x.exRate*/}
                                            {/*        )}*/}
                                        </div>
                                        <div className="flex-1 center">
                                            <input className={exRateError && x.isChecked ? 'error center' : 'center'}
                                                type="text"
                                                value={x.exRate}
                                                onChange={(e) => { DataListChangeLV2(e, xi, -1, "exRate"), setexRateError(false) }}
                                                disabled={dataList.isAccountant == 1 && dataList.auditStatus == 2 ? "" : true} />
                                        </div>
                                        <div className="flex-4 center"></div>
                                    </li>
                                    <li className="lt-row lt-th">
                                        <div className="flex-1 max-60">項次</div>
                                        <div className="flex-3 center">AR 編號</div>
                                        <div className="flex-3 center">發票日期</div>
                                        <div className="flex-3 center">發票號碼</div>
                                        <div className="flex-3 right">帳本幣別餘額</div>
                                        <div className="flex-3 right">收帳金額</div>
                                        <div className="flex-3 right">沖帳金額</div>
                                        <div className="flex-3 right">調節金額</div>
                                        <div className="flex-4 ">調整項目說明/備註</div>
                                    </li>
                                    {x.lst.map((y, yi) => (
                                        <li key={yi} className="lt-row no-border ovv">
                                            <div className="flex-1 max-60">{yi + 1}</div>
                                            <div className="flex-3 center">{y.arNumber}</div>{/*AR 編號*/}
                                            <div className="flex-3 center">{y.invoiceDate}</div>{/*發票日期*/}
                                            <div className="flex-3 center">{y.invoiceNo}</div>{/*發票號碼*/}
                                            <div className="flex-3 right">{NumFormart(y.baseAmt)}</div>{/*帳本幣別餘額*/}
                                            <div className="flex-3 right">{NumFormart(y.recAmt)}</div>{/*收帳金額*/}
                                            <div className="flex-3 right">{/*沖帳金額*/}
                                                {(() => {
                                                    //#region 重新轉換為數字

                                                    //#region 調節金額
                                                    var adjustAmt = parseFloat((y.adjustAmt + "").replace(/,/g, ''))
                                                    adjustAmt = !isNaN(adjustAmt) ? adjustAmt : 0;
                                                    //#endregion

                                                    //#endregion

                                                    var tempAmount = 0.00;

                                                    if (dataList.companyName == "台灣果核" || dataList.companyName == "台灣核智") {
                                                        tempAmount = parseFloat((y.recAmt * x.exRate).toFixed()) + adjustAmt;
                                                    }
                                                    else {
                                                        tempAmount = parseFloat(((y.recAmt * x.exRate) + adjustAmt).toFixed(2));
                                                    }

                                                    return NewNumFormart(parseFloat(tempAmount.toFixed(2)));
                                                })()}

                                                {/*原本寫法*/}
                                                {/*{*/}
                                                {/*    (dataList.companyName == "台灣果核" || dataList.companyName == "台灣核智" ?*/}
                                                {/*        (y.recAmt * x.exRate) + parseFloat((y.adjustAmt + "").replace(/,/g, ''))*/}
                                                {/*        :*/}
                                                {/*        NewNumFormart(Math.round(y.recAmt * x.exRate) + parseFloat((y.adjustAmt + "").replace(/,/g, ''))))*/}
                                                {/*}*/}
                                            </div>
                                            <div className="flex-3 right">{/*調節金額*/}
                                                <DebounceInput
                                                    debounceTimeout={300}
                                                    className={exadjustAmtError && x.isChecked ? 'error' : ''}
                                                    type="text"
                                                    value={NumFormart(y.adjustAmt)}
                                                    onChange={(e) => { DataListChangeLV2(e, xi, yi, "adjustAmt"), setexadjustAmtError(false) }}
                                                    onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                                    onBlur={(e) => e.target.value = NumFormart(y.adjustAmt)}
                                                    disabled={dataList.isAccountant == 1 && dataList.auditStatus == 2 ? "" : true}
                                                />
                                            </div>
                                            <div className="flex-4">{/*調整項目說明/備註*/}
                                                <div className="tooltip">
                                                    <b id={`remark-${xi}-${yi}`} className="text-primary-600">
                                                        {y.remark && y.remark.length > 5 ? y.remark.substring(0, 5) + '...' : y.remark}
                                                    </b>
                                                    {
                                                        y.remark && y.remark.length > 5 ?
                                                            <Tooltip
                                                                className="tooltip-zindex"
                                                                anchorId={`remark-${xi}-${yi}`}
                                                                place="bottom"
                                                                content={HtmlParse(y.remark)}
                                                                multiline={true}
                                                            /> : null
                                                    }
                                                </div>
                                                {
                                                    /*dataList.recAccountType == "票據" ? "銀行名稱/帳號/票據號碼/到期日備註" : ""*/
                                                    //y.remark != null && y.remark.length > 5 ? y.remark.substring(0, 5) + '...' : y.remark
                                                }
                                            </div>
                                            {/*<div className="flex-4 right">*/}
                                            {/*    <div className="elips-1">Lorem ipsum dolor sit amet</div>*/}
                                            {/*</div>*/}
                                        </li>
                                    ))}
                                    {
                                        x.tempList.map((y, yi) => (
                                            <li className="lt-row no-border ovv">
                                                <div className="flex-1 max-60">{yi + 1 + x.lst.length}</div>
                                                <div className="flex-3 center">暫收款</div>
                                                <div className="flex-3 center"></div>
                                                <div className="flex-3 center"></div>
                                                <div className="flex-3 right"></div>
                                                <div className="flex-3 right">{NumFormart(y.cawAmtsIn)}</div>
                                                <div className="flex-3 right">{/*沖帳金額*/}
                                                    {(() => {
                                                        var tempAmount = 0.00;

                                                        if (dataList.companyName == "台灣果核" || dataList.companyName == "台灣核智") {
                                                            tempAmount = parseFloat((y.cawAmtsIn * x.exRate).toFixed());
                                                        }
                                                        else {
                                                            tempAmount = parseFloat((y.cawAmtsIn * x.exRate).toFixed(2));
                                                        }

                                                        return NewNumFormart(parseFloat(tempAmount.toFixed(2)));
                                                    })()}

                                                    {/*原本寫法*/}
                                                    {/*{(*/}
                                                    {/*    dataList.companyName == "台灣果核" || dataList.companyName == "台灣核智" ?*/}
                                                    {/*        (y.cawAmtsIn * x.exRate)*/}
                                                    {/*        :*/}
                                                    {/*        NewNumFormart(Math.round(y.cawAmtsIn * x.exRate))*/}
                                                    {/*)}*/}
                                                </div>
                                                <div className="flex-3 right">
                                                </div>
                                                <div className="flex-4">
                                                    <div className="tooltip">
                                                        <b id={`cawRem-${xi}-${yi}`} className="text-primary-600">
                                                            {y.cawRem != null && y.cawRem.length > 5 ? y.cawRem.substring(0, 5) + '...' : y.cawRem}
                                                        </b>
                                                        {
                                                            y.cawRem && y.cawRem.length > 5 ?
                                                                <Tooltip
                                                                    className="tooltip-zindex"
                                                                    anchorId={`cawRem-${xi}-${yi}`}
                                                                    place="bottom"
                                                                    content={y.cawRem}
                                                                    multiline={true}
                                                                /> : null
                                                        }
                                                    </div>
                                                    {/*<p> {y.cawRem != null && y.cawRem.length > 5 ? y.cawRem.substring(0, 5) + '...' : y.cawRem}</p>*/}
                                                </div>
                                                {/*<div className="flex-4 right">*/}
                                                {/*    <div className="elips-1">Lorem ipsum dolor sit amet</div>*/}
                                                {/*</div>*/}
                                            </li>
                                        ))
                                    }
                                    <li className="lt-row lt-th">
                                        <div className="flex-1 max-60"></div>
                                        <div className="flex-3 center"></div>
                                        <div className="flex-3 center"></div>
                                        <div className="flex-3 center"></div>
                                        <div className="flex-3 right"></div>
                                        <div className="flex-3 right">小計</div>
                                        <div className="flex-3 right">
                                            {(() => {
                                                var tempSubTotalAmount = 0.00;

                                                if (dataList.companyName == "台灣果核" || dataList.companyName == "台灣核智") {
                                                    {/*一般*/ }
                                                    x.lst.map((item) => {
                                                        //#region 重新轉換為數字

                                                        //#region 收帳金額
                                                        var recAmt = parseFloat((item.recAmt + "").replace(/,/g, ''))
                                                        recAmt = !isNaN(recAmt) ? recAmt : 0;
                                                        //#endregion

                                                        //#region 調節金額
                                                        var adjustAmt = parseFloat((item.adjustAmt + "").replace(/,/g, ''))
                                                        adjustAmt = !isNaN(adjustAmt) ? adjustAmt : 0;
                                                        //#endregion

                                                        //#endregion

                                                        tempSubTotalAmount += parseFloat((recAmt * x.exRate).toFixed()) + adjustAmt;
                                                    });

                                                    {/*暫收款*/ }
                                                    x.tempList.map((item) => {
                                                        //#region 重新轉換為數字

                                                        //#region 收帳金額
                                                        var cawAmtsIn = parseFloat((item.cawAmtsIn + "").replace(/,/g, ''))
                                                        cawAmtsIn = !isNaN(cawAmtsIn) ? cawAmtsIn : 0;
                                                        //#endregion

                                                        //#endregion

                                                        tempSubTotalAmount += parseFloat((cawAmtsIn * x.exRate).toFixed());
                                                    });
                                                }
                                                else {
                                                    {/*一般*/ }
                                                    x.lst.map((item) => {
                                                        //#region 重新轉換為數字

                                                        //#region 收帳金額
                                                        var recAmt = parseFloat((item.recAmt + "").replace(/,/g, ''))
                                                        recAmt = !isNaN(recAmt) ? recAmt : 0;
                                                        //#endregion

                                                        //#region 調節金額
                                                        var adjustAmt = parseFloat((item.adjustAmt + "").replace(/,/g, ''))
                                                        adjustAmt = !isNaN(adjustAmt) ? adjustAmt : 0;
                                                        //#endregion

                                                        //#endregion

                                                        tempSubTotalAmount += parseFloat(((recAmt * x.exRate) + adjustAmt).toFixed(2));
                                                    });

                                                    {/*暫收款*/ }
                                                    x.tempList.map((item) => {
                                                        //#region 重新轉換為數字

                                                        //#region 收帳金額
                                                        var cawAmtsIn = parseFloat((item.cawAmtsIn + "").replace(/,/g, ''))
                                                        cawAmtsIn = !isNaN(cawAmtsIn) ? cawAmtsIn : 0;
                                                        //#endregion

                                                        //#endregion

                                                        tempSubTotalAmount += parseFloat((cawAmtsIn * x.exRate).toFixed(2));
                                                    });
                                                }

                                                return NewNumFormart(parseFloat(tempSubTotalAmount.toFixed(2)));
                                            })()}

                                            {/*原本寫法*/}
                                            {/*{*/}
                                            {/*    (dataList.companyName == "台灣果核" || dataList.companyName == "台灣核智" ?*/}
                                            {/*        NewNumFormart(x.lst.reduce(function (accumulator, curValue) {*/}
                                            {/*            var val1 = parseFloat((curValue.recAmt + "").replace(/,/g, ''));*/}
                                            {/*            if (isNaN(val1)) {*/}
                                            {/*                val1 = 0;*/}
                                            {/*            }*/}
                                            {/*            var val2 = parseFloat(x.exRate);*/}
                                            {/*            if (isNaN(val2)) {*/}
                                            {/*                val2 = 0;*/}
                                            {/*            }*/}
                                            {/*            var val3 = parseFloat((curValue.adjustAmt + "").replace(/,/g, ''));*/}
                                            {/*            if (isNaN(val3)) {*/}
                                            {/*                val3 = 0;*/}
                                            {/*            }*/}
                                            {/*            return accumulator + Math.round(val1 * val2) + val3*/}
                                            {/*        }, 0) +*/}
                                            {/*            x.tempList.reduce(function (accumulator, curValue) {*/}
                                            {/*                var val1 = parseFloat(curValue.cawAmtsIn);*/}
                                            {/*                if (isNaN(val1)) {*/}
                                            {/*                    val1 = 0;*/}
                                            {/*                }*/}
                                            {/*                var val2 = parseFloat(x.exRate);*/}
                                            {/*                if (isNaN(val2)) {*/}
                                            {/*                    val2 = 0;*/}
                                            {/*                }*/}
                                            {/*                return (accumulator + Math.round(val1 * val2))*/}
                                            {/*            }, 0)*/}
                                            {/*        ) : NewNumFormart(x.lst.reduce(function (accumulator, curValue) {*/}
                                            {/*            var val1 = parseFloat((curValue.recAmt + "").replace(/,/g, ''));*/}
                                            {/*            if (isNaN(val1)) {*/}
                                            {/*                val1 = 0;*/}
                                            {/*            }*/}
                                            {/*            var val2 = parseFloat(x.exRate);*/}
                                            {/*            if (isNaN(val2)) {*/}
                                            {/*                val2 = 0;*/}
                                            {/*            }*/}
                                            {/*            var val3 = parseFloat((curValue.adjustAmt + "").replace(/,/g, ''));*/}
                                            {/*            if (isNaN(val3)) {*/}
                                            {/*                val3 = 0;*/}
                                            {/*            }*/}
                                            {/*            return accumulator + (val1 * val2) + val3*/}
                                            {/*        }, 0) +*/}
                                            {/*            x.tempList.reduce(function (accumulator, curValue) {*/}
                                            {/*                var val1 = parseFloat(curValue.cawAmtsIn);*/}
                                            {/*                if (isNaN(val1)) {*/}
                                            {/*                    val1 = 0;*/}
                                            {/*                }*/}
                                            {/*                var val2 = parseFloat(x.exRate);*/}
                                            {/*                if (isNaN(val2)) {*/}
                                            {/*                    val2 = 0;*/}
                                            {/*                }*/}
                                            {/*                return (accumulator + (val1 * val2))*/}
                                            {/*            }, 0)*/}
                                            {/*        ))}*/}
                                        </div>
                                        <div className="flex-3 right">{NewNumFormart(x.lst.reduce(function (accumulator, curValue) {
                                            var val = curValue.adjustAmt;
                                            val += "";
                                            var res = parseFloat(accumulator) + parseFloat(val.replace(/,/g, ''));
                                            if (isNaN(res)) {
                                                return 0;
                                            }
                                            return res;

                                        }, 0))}</div>
                                        <div className="flex-4 right"></div>
                                    </li>

                                    <li className="lt-row no-border">
                                    </li>
                                </ul>
                            ))}

                        {/*<ul className="list-table no-border">*/}
                        {/*    <li className="lt-row lt-th">*/}
                        {/*    <div className="flex-4 center">客戶名稱</div>*/}
                        {/*        <div className="flex-2 center">收款金額</div>*/}
                        {/*        <div className="flex-1 center">匯率</div>*/}
                        {/*        <div className="flex-4 center">備註</div>*/}
                        {/*    </li>*/}
                        {/*{dataList.tempList.map((y, i) => (*/}
                        {/*    <li className="lt-row no-border ovv">*/}
                        {/*        <div className="flex-4 center">{y.cawCustName}</div>*/}
                        {/*        <div className="flex-2 center">{NumFormart(y.cawAmtsIn)}</div>*/}
                        {/*        <div className="flex-1 right">{y.cawAmtsExRate}</div>*/}
                        {/*        <div className={y.cawRem != null && y.cawRem.length > 0 ? "flex-4 right tip " : "flex-3 right "  } data-tip={y.cawRem}>*/}
                        {/*            <p> {y.cawRem != null && y.cawRem.length > 5 ? y.cawRem.substring(0, 5) + '...' : y.cawRem}</p>*/}
                        {/*        </div>*/}
                        {/*    </li>*/}
                        {/*    ))}*/}
                        {/*</ul>*/}
                        {
                            dataList.auditStatus < 3 ?
                                <div className="form-item btn-area">

                                    <button className="btn btn-border" onClick={openModalReason}>
                                        <span>退回</span>
                                    </button>
                                    <button className="btn" onClick={Apply}>
                                        <span>{"確定" + ReviewType}</span>
                                    </button>
                                </div>
                                : <></>
                        }
                        {dataList.auditStatus < 3 ?
                            <div className="form-item must col feedback">
                                <div className="field">退回原因</div>
                                <div className="value">
                                    <textarea name="" id="reason4" cols="30" rows="6" placeholder="請輸入退回原因" value={reason} onChange={(e) => { setReason(e.target.value) }} ></textarea>
                                </div>
                                <span className="help-word">
                                    請輸入退回原因
                                </span>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>


            <div id="modal-AccChargecheckreason" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確認要退回此筆資料？</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-AccChargecheckreason")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={Reason}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-AccChargeecheckagree" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確認要通過此筆資料？</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-AccChargeecheckagree")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={Agree}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-rejectReason" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            請寫退回原因
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => { CloseModal("modal-rejectReason") }}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-AccChargeMoneyNC" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            提醒
                            <br />
                            {ARStr}
                            <br />
                            帳本幣別餘額 不等於 沖帳金額加總
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => { CloseModal("modal-AccChargeMoneyNC"), OpenModal('modal-AccChargeecheckagree', 3) }}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountChargeOffDetail;
