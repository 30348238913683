import React, { useState, useEffect } from "react";
import HtmlParse from 'html-react-parser';
import { useHistory } from "react-router-dom";
import {
    API_GET_HomeData,
    API_GET_StatementData,
    API_POST_StatementMailSend,
    API_POST_StatementData,
    API_POST_StatementPDF,
    API_GET_FileDownload,
    API_GET_CompanyCurrNames,

} from "../../global/constants";
import { NewNumFormart, UrlDecode, CloseModal, OpenModal, InitJS, Clone, MenuSet, SetOnbeforeunload, SetHasChange } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
import OrderDetail from "../Common/OrderDetail"
import { isDisabled } from "@testing-library/user-event/dist/utils";

const StatementItem = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const [reGet, setReGet] = useState(false);
    const [companyName, setCompanyName] = useState();
    const [incomeMonth, setIncomeMonth] = useState();
    const [dataList, setDataList] = useState([]);
    const [hasChange, setHasChange] = useState(false);
    const [modalStr, setModalStr] = useState();
    const [canSendStatements, setCanSendStatements] = useState(0);
    const [isTempSaveSuccess, setIsTempSaveSuccess] = useState(false);
    const [hasSendDetail, setHasSendDetail] = useState(false);
    const [isDownlaodPDF, setIsDownlaodPDF] = useState(false);
    const [isControlDisabled, setIsControlDisabled] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [currency, setCurrency] = useState();
    const [dataListFilter, setDataListFilter] = useState([]);
    const [dataFilter, setDataFilter] = useState("");
    const [isClearFilter, setIsClearFilter] = useState(false);

    useEffect(() => {
        //把menu顏色置換
        MenuSet("StatementNew");

        //
        var fromOkta_ = (localStorage.getItem("post_logout_redirect_uri") != null ? 1 : 0);
        API_GET_HomeData({ fromOkta: fromOkta_ })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setCanSendStatements(data.result.canSendStatements);
                }
            });

        //抓取資料 
        getdata();
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    useEffect(() => {
        if (reGet) {
            //內頁資料有刪除/修改 重抓
            getdata();
        }
        setReGet(false);
    }, [reGet]);

    useEffect(() => {
        if (hasError && isClearFilter) {
            let section = document.querySelector('.error');
            //section.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
            var rect = section.getBoundingClientRect();
            //控制x軸
            let list = document.querySelector(`.list-table-wrapper`);
            list.scrollLeft = 0;
            //控制y軸
            let page = document.querySelector(`.list-table-wrapper`);
            const scrollTop = page.scrollTop;
            page.scrollTop = rect.top + scrollTop - 280;

            setHasError(false);
            setIsClearFilter(false);
        }

    }, [hasError, isClearFilter])

    useEffect(() => {
        console.log("公司幣別", currency);

    }, [currency])

    function getdata() {
        getCurrency();
        getDataList();
    }

    function getCurrency() {
        var dt = {
            companyId: parseInt(queryParams.get("companyId"))
        }

        API_GET_CompanyCurrNames(dt)
            .then((resp) => resp.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                if (data.code == "0000") {
                    setCurrency(data.result.data);
                }

            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function getDataList() {
        var dt = {
            companyId: parseInt(queryParams.get("companyId")),
            incomeMonth: queryParams.get("incomeMonth"),
            customerName: queryParams.get("customerName") == "" ? null : queryParams.get("customerName").replace(/&/g, "%26"),
            customerTitle: queryParams.get("customerTitle") == "" ? null : queryParams.get("customerTitle").replace(/&/g, "%26"),
            customerTaxId: queryParams.get("customerTaxId") == "" ? null : queryParams.get("customerTaxId"),
            productTypeId: (queryParams.get("productId") == null || queryParams.get("productId") == "") ? null : parseInt(queryParams.get("productId")),
            productName1: queryParams.get("productName1") == "" ? null : queryParams.get("productName1").replace(/&/g, "%26"),
            supplierName: queryParams.get("supplierName") == "" ? null : queryParams.get("supplierName").replace(/&/g, "%26"),
            //personInChargeName: queryParams.get("personInChargeName") == "" ? null : queryParams.get("personInChargeName"),
            personInChargeID: (queryParams.get("personInChargeID") == "" || queryParams.get("personInChargeID") == "") ? null : queryParams.get("personInChargeID"),
        }

        API_GET_StatementData(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setDataList([...data.result.data.invoiceData]);
                    setDataListFilter([...data.result.data.invoiceData]);

                    setCompanyName(data.result.data.companyName);
                    setIncomeMonth(data.result.data.incomeMonth);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function dataChange(e, source, col) {
        SetHasChange(true);

        if (col != "isChecked")
            setHasChange(true);
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (item.orderId == source.orderId && item.orderProductId == source.orderProductId) {
                    if (col == "explanation") {
                        item.explanation = e.target.value;
                    }
                    else if (col == "isChecked") {
                        item.isChecked = e.target.checked;
                        //ReChecked(item.invoiceItem, item.invoiceProject, e.target.checked);
                    }
                }
                return item;
            });
        });
    }

    useEffect(() => {
        if (dataList && dataList.length > 0 && dataList.filter(x => x.isChecked).length == dataList.length) {
            setC40checked(true);
        }
        else {
            setC40checked(false);
        }

        dataList.some(function (item) {
            if (item.statementSendDate && item.statementSendDate != "") {
                setHasSendDetail(true);
                return true;
            }

        });
    }, [dataList]);

    const [c40checked, setC40checked] = useState(false);

    function setAllChecked(checked) {
        setC40checked(checked);
        setDataList(function (prev) {
            return prev.map((item, i) => {
                item.isChecked = checked;
                return item;
            });
        });
    }

    //暫存
    function tmpSave() {
        setIsControlDisabled(true);
        CloseModal("modal-pdf");
        var result = false;
        var dt = [];
        for (var i = 0; i < dataList.length; i++) {
            dt.push({
                ioid: dataList[i].ioid,
                orderId: dataList[i].orderId,
                orderProductId: dataList[i].orderProductId,
                incomeMonth: incomeMonth,
                invoiceProject: dataList[i].invoiceProject,
                invoiceItem: dataList[i].invoiceItem,
                invoiceName1: dataList[i].invoiceName1,
                invoiceName2: dataList[i].invoiceName2,
                invoiceName3: dataList[i].invoiceName3,
                invoiceName4: dataList[i].invoiceName4,
                invoiceName5: dataList[i].invoiceName5,
                explanation: dataList[i].explanation,
                fineTuneId: dataList[i].fineTuneId,
            });
        }
        console.log("對帳單暫存傳送資料", JSON.stringify(dt));
        API_POST_StatementData(dt)
            .then((response) => response.data)
            .then((data) => {
                CloseModal("modal-save");

                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    result = true;
                    setIsTempSaveSuccess(true);
                    setHasChange(false);
                    setModalStr("暫存成功");
                    SetHasChange(false);
                    OpenModal("modal-ok-tempSave");
                    filterClear();
                } else {
                    setIsTempSaveSuccess(false);
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {
                setIsTempSaveSuccess(false);
            })
            .finally(() => {
                CloseModal("modal-save");
                setIsControlDisabled(false);
            });
        return result;
    }

    function CloseModalTempSave(id) {
        CloseModal(id);
        if (isTempSaveSuccess && isDownlaodPDF) {
            GetAndDownloadPDF();
        }
    }

    //對帳單-寄出對帳單
    function Send() {
        setIsControlDisabled(true);
        var isValid = false;
        var dt = [];
        for (var i = 0; i < dataList.length; i++) {
            if (dataList[i].isChecked == true) {
                let explanation = dataList[i].explanation.trim();

                dt.push({
                    orderProductId: dataList[i].orderProductId,
                    orderId: dataList[i].orderId,
                    fineTuneId: dataList[i].fineTuneId,
                    explanation: explanation,
                });

                dataList[i].isExplanationValid = (explanation === '' || explanation === null || explanation === undefined) ? false : true
            }
        }


        let allValid = dataList.filter(function (item) {
            return item.isChecked == true;
        }).every(function (item) {
            return item.isExplanationValid === true
        });

        if (allValid) {
            var param = {
                incomeMonth: incomeMonth,
                data: dt,

            };
            //console.log(JSON.stringify(dt));
            API_POST_StatementMailSend(param)
                .then((response) => response.data)
                .then((data) => {
                    CloseModal("modal-send");
                    document.getElementById('SendBtn').classList.remove("disabled");
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        setModalStr("已寄出對帳單");
                        OpenModal("modal-ok");
                        SetHasChange(false);
                        setDataFilter("");
                        getdata();
                    }
                    else {
                        setModalStr(data.message);
                        OpenModal("modal-error");
                    }
                })
                .catch((err) => {
                    document.getElementById('SendBtn').classList.remove("disabled");
                })
                .finally(() => {
                    CloseModal("modal-send");
                    setIsControlDisabled(false);
                });
        }
        else {
            setIsControlDisabled(false);
            CloseModal("modal-send");

            setHasError(true);
        }


    }

    function filterClear() {
        setDataListFilter([...dataList]);
        setDataFilter("");
        setIsClearFilter(true);
    }

    //檢視PDF 
    const FileDownload = require("js-file-download");
    function downLoad(path) {
        //console.log(path);
        var dt = {
            fileName: path
        };
        console.log(JSON.stringify(dt));
        API_GET_FileDownload(dt)
            .then((response) => {

                const disposition = response.headers['content-disposition'];
                var filename = disposition.split("''")[1];
                FileDownload(response.data, UrlDecode(filename));
                CloseModal("modal-downlonding");
            }).catch((err) => {

                CloseModal("modal-downlonding");
                setModalStr("下載檔案錯誤：" + err.message);
                OpenModal("modal-error");
            });

    }

    function viewPDF() {
        setIsControlDisabled(true);
        var isOK = true;
        setIsDownlaodPDF(true);

        GetAndDownloadPDF();
    }

    function GetAndDownloadPDF() {
        if (dataList.filter(x => x.isChecked == true).length == 0) {
            setModalStr("請選擇訂單編號");
            OpenModal("modal-error");
        } else {
            var dt = [];
            for (var i = 0; i < dataList.length; i++) {
                if (dt.indexOf(dataList[i].orderProductId) < 0 && dataList[i].isChecked) {
                    dt.push(
                        {
                            "orderProductId": dataList[i].orderProductId,
                            "incomeYM": dataList[i].incomeMonth,
                            "orderId": dataList[i].orderId
                        }
                    );
                }

            }
            var dt = [];
            for (var i = 0; i < dataList.length; i++) {
                dt.push({
                    ioid: dataList[i].ioid,
                    orderId: dataList[i].orderId,
                    orderProductId: dataList[i].orderProductId,
                    incomeMonth: incomeMonth,
                    invoiceProject: dataList[i].invoiceProject,
                    invoiceItem: dataList[i].invoiceItem,
                    invoiceName1: dataList[i].invoiceName1,
                    invoiceName2: dataList[i].invoiceName2,
                    invoiceName3: dataList[i].invoiceName3,
                    invoiceName4: dataList[i].invoiceName4,
                    invoiceName5: dataList[i].invoiceName5,
                    explanation: dataList[i].explanation,
                    fineTuneId: dataList[i].fineTuneId,
                });
            }
            // 產生對帳單前先暫存資料
            console.log("對帳單暫存傳送資料", JSON.stringify(dt));
            API_POST_StatementData(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        let allValid = dataList.filter(function (item) {
                            return item.isChecked == true;
                        }).every(function (item) {
                            return item.isExplanationValid === true
                        });


                        if (allValid) {
                            var dt = [];
                            for (var i = 0; i < dataList.length; i++) {
                                if (dt.indexOf(dataList[i].orderProductId) < 0 && dataList[i].isChecked) {
                                    dt.push(
                                        {
                                            "orderProductId": dataList[i].orderProductId,
                                            "incomeYM": dataList[i].incomeMonth,
                                            "orderId": dataList[i].orderId
                                        }
                                    );
                                }
                            }

                            //console.log(JSON.stringify(dt));
                            API_POST_StatementPDF(dt)
                                .then((response) => response.data)
                                .then((data) => {
                                    CloseModal("modal-pdf");
                                    if (!checkAuthToken(data)) {
                                        history.push("/");
                                    }
                                    if (!CheckResponseCode(data)) { return; } //檢查response code
                                    if (data.code == "0000") {
                                        downLoad(data.result.data.downloadPath);
                                    } else {
                                        setModalStr(data.message);
                                        CloseModal("modal-downlonding");
                                        OpenModal("modal-error");
                                    }
                                })
                                .catch((err) => {
                                    console.log("檢視PDF錯誤", err);
                                    setModalStr("檢視PDF錯誤");
                                    CloseModal("modal-downlonding");
                                    OpenModal("modal-error");
                                })
                                .finally(() => {
                                    CloseModal("modal-pdf");
                                    setIsControlDisabled(false);
                                });
                        }
                        else {
                            setIsControlDisabled(false);
                            CloseModal("modal-pdf");
                        }
                        //result = true;
                        //setIsTempSaveSuccess(true);
                        //setHasChange(false);
                        // SetHasChange(false);
                    } else {
                        //setIsTempSaveSuccess(false);
                        //setModalStr(data.message);
                        //OpenModal("modal-error");
                    }
                })
                .catch((err) => {
                    setIsTempSaveSuccess(false);
                })
                .finally(() => {
                    setIsDownlaodPDF(false);
                });

        }
    }

    //檢視PDF
    function openModalPDF() {
        filterClear();

        for (var i = 0; i < dataList.length; i++) {
            if (dataList[i].isChecked == true) {
                let explanation = dataList[i].explanation.trim();
                dataList[i].isExplanationValid = (explanation === '' || explanation === null || explanation === undefined) ? false : true
            }
            else {
                dataList[i].isExplanationValid = true;
            }
        }

        let allValid = dataList.filter(function (item) {
            return item.isChecked == true;
        }).every(function (item) {
            return item.isExplanationValid === true
        });


        if (allValid) {
            setIsTempSaveSuccess(false);
            var id = "modal-pdf";

            if (!OpenSendModalValid()) {
                id = "modal-error"
            }

            setIsDownlaodPDF(true);
            OpenModal(id);
        }
        else {
            setHasError(true);
        }
    }

    //寄出對帳單
    function openModalSend(e) {
        filterClear();

        for (var i = 0; i < dataList.length; i++) {
            if (dataList[i].isChecked == true) {
                let explanation = dataList[i].explanation.trim();
                dataList[i].isExplanationValid = (explanation === '' || explanation === null || explanation === undefined) ? false : true
            }
            else {
                dataList[i].isExplanationValid = true;
            }
        }

        let allValid = dataList.filter(function (item) {
            return item.isChecked == true;
        }).every(function (item) {
            return item.isExplanationValid === true
        });

        if (allValid) {
            var id = "modal-send";
            //標記選取項目是否有列管
            var hasTube = false;

            if (!OpenSendModalValid()) {
                id = "modal-error"
            }

            OpenModal(id);
        }
        else {
            setHasError(true);
        }

    }

    function OpenSendModalValid() {
        let checked = dataList.filter(x => x.isChecked == true);

        if (checked.length == 0) {
            setModalStr("請選擇訂單編號");
            return false;
        }

        return true;
    }

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container full">
                    <h3 className="fz-B has-border">訂單公司:{companyName} </h3>
                </div>
            </div>
            <div className="container full">
                <div className="info-holder">
                    <div className="left">營收月份：{incomeMonth}</div>
                    <div className="right">
                        <input type="text" style={{ width: "350px" }} value={dataFilter} placeholder="請輸入欲篩選的客戶別或公司抬頭" onChange={(e) => {
                            SetHasChange(false);

                            let value = e.target.value;
                            setDataFilter(value);

                            let tempList = [...dataList];

                            try {
                                if (value != null && value != '') {
                                    let testValue = value.replace(/\\/g, '\\\\');
                                    tempList = tempList.filter(x => {
                                        let regex = new RegExp(testValue, "i");
                                        return regex.test(x.customerName) || regex.test(x.customerTitle);
                                    });
                                }
                            } catch (ex) {
                                setDataListFilter([]);
                            }

                            setDataListFilter(tempList);
                        }} />

                    </div>
                </div>
                <div className={`scroll-wrapper for-4-1`}>
                    <div className="list-table-wrapper">
                        <ul className="list-table">
                            <li className="lt-row lt-th sticky-head">
                                <div className="flex-1 max1 fixed-1">
                                    <input type="checkbox" id="c40" name="c40" onClick={(e) => setAllChecked(!c40checked)} checked={c40checked} />
                                    <label htmlFor="c40"></label>
                                </div>
                                <div className="flex-3 fixed-2">訂單編號</div>
                                <div className="flex-3 fixed-3">客戶別</div>
                                <div className="flex-4 fixed-4">產品細項</div>
                                {
                                    hasSendDetail ? <div className="flex-3 fixed-5"></div> : ''
                                }
                                <div className="flex-1">銷售類型</div>
                                <div className="flex-5 must">服務品名</div>
                                <div className="flex-1 right">數量</div>
                                <div className="flex-1 right">幣別</div>
                                <div className="flex-1 right">匯率</div>
                                <div className="flex-1 right">單價</div>
                                <div className="flex-3 right">費用金額</div>
                                <div className="flex-2 center">寄雲端明細</div>
                                {
                                    hasSendDetail ? <div className="flex-3"></div> : ''
                                }
                                <div className="flex-3">發票產品別</div>
                                <div className="flex-5">公司抬頭</div>
                                <div className="flex-3">負責業務</div>
                            </li>
                            {
                                dataListFilter.map((x, i) =>
                                    <li className="lt-row">
                                        <div className="flex-1 max1 fixed-1">
                                            <input type="checkbox" id={`c41_${i}`} name="c41" value={i} onClick={e => dataChange(e, x, "isChecked")} checked={x.isChecked == null ? false : x.isChecked} />
                                            <label htmlFor={`c41_${i}`}></label>
                                        </div>
                                        <div className="flex-3 fixed-2"> {/*訂單編號*/}
                                            <a href={`/Main?path=SaleOrderEdit&orderId=${x.orderId}`} target="_blank" className={x.spInvState == 2 || x.tradableBalance < 0 ? 'alert' : ''}>{x.orderNumber}</a>
                                        </div>
                                        <div className="flex-3 fixed-3"> {/*客戶別*/}
                                            <div className="elips-1">{x.customerName}</div>
                                        </div>
                                        <div className="flex-4 fixed-4"> {/*產品細項*/}
                                            <div className="elips-1">{x.productName2}</div>
                                        </div>
                                        {
                                            hasSendDetail ? <div className="flex-3 fixed-5 center">
                                                {
                                                    x.statementSendDate && x.statementSendDate != "" ?
                                                        <>
                                                            <i class="icon icon-21 orange small-mg"></i>
                                                        </> : <></>
                                                }
                                            </div> : ''
                                        }
                                        <div className="flex-1"> {/*銷售類型*/}
                                            <div className="elips-1">{x.salesTypeName}</div>
                                        </div>
                                        <div className="flex-5"> {/*服務品名*/}
                                            <input type="text" value={x.explanation} className={x.isExplanationValid === false ? 'error' : 'false'} onChange={e => dataChange(e, x, "explanation")} />
                                        </div>
                                        <div className="flex-1 right"> {/*數量*/}
                                            <div className="elips-1">{NewNumFormart(x.quantity)}</div>
                                        </div>
                                        <div className="flex-1 right"> {/*幣別*/}
                                            <div className="elips-1">{x.currencyName}</div>
                                        </div>
                                        <div className="flex-1 right"> {/*匯率*/}
                                            <div className="elips-1">{parseFloat(x.exRate)}</div>
                                        </div>
                                        <div className="flex-1 right"> {/*單價*/}
                                            <div className="elips-1">{NewNumFormart(x.price)}</div>
                                        </div>
                                        <div className="flex-3 right"> {/*費用金額*/}
                                            <div className="elips-1">{currency ? currency.currencyEName : ''} {NewNumFormart(x.amount)}</div>
                                        </div>
                                        <div className="flex-2 center"> {/*寄雲端明細*/}
                                            <div className="elips-1">{x.isCloudDetail == 1 ? "是" : "否"}</div>
                                        </div>
                                        {
                                            hasSendDetail ? <div className="flex-3">
                                                {
                                                    x.statementSendDate && x.statementSendDate != "" ?
                                                        <>
                                                            <i class="icon icon-21 orange small-mg"></i>
                                                            <div className="elips-1">{x.statementSendDate}</div>
                                                        </> : <></>
                                                }
                                            </div>
                                                : ''

                                        }

                                        <div className="flex-3"> {/*發票產品別*/}
                                            <div className="elips-1">{x.accountProdTPName}</div>
                                        </div>
                                        <div className="flex-5"> {/*公司抬頭*/}
                                            <div className="elips-1">{x.customerTitle}</div>
                                        </div>
                                        <div className="flex-3"> {/*負責業務*/}
                                            <div className="elips-1">{x.personInChargeName}</div>
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>

                </div>
                <div className="form-container">
                    <div className="form-item btn-area sp">
                        <div className="left">
                            <a className="btn btn-border" onClick={openModalPDF}>
                                <span>檢視 PDF</span>
                            </a>
                        </div>
                        <div className="right">
                            <a className="btn btn-border" onClick={() => OpenModal("modal-save")}>
                                <span>暫存</span>
                            </a>
                            {canSendStatements == 1 ?
                                <a className="btn" onClick={openModalSend} id="SendBtn">
                                    <span>寄出對帳單</span>
                                </a> : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-send" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要寄出對帳單？
                        </div>
                        <div className="btn-holder">
                            <a className={`btn btn-border ${isControlDisabled ? 'disabled' : ''}`} onClick={() => CloseModal("modal-send")}> <span>取消</span></a>
                            <a className={`btn ${isControlDisabled ? 'disabled' : ''}`} onClick={Send}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-save" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要儲存目前資訊？
                        </div>
                        <div className="btn-holder">
                            <a className={`btn btn-border ${isControlDisabled ? 'disabled' : ''}`} onClick={() => CloseModal("modal-save")}> <span>取消</span></a>
                            <a className={`btn ${isControlDisabled ? 'disabled' : ''}`} onClick={tmpSave}> <span>確認</span></a>
                        </div>

                    </div>

                </div>
            </div>

            <div id="modal-pdf" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {
                                hasChange ? "發現您有更動內容，是否先儲存再產出PDF ?" : " 確定要產出PDF？"
                            }
                        </div>
                        <div className="btn-holder">
                            <a className={`btn btn-border ${isControlDisabled ? 'disabled' : ''}`} onClick={() => CloseModal("modal-pdf")}> <span>取消</span></a>
                            <a className={`btn ${isControlDisabled ? 'disabled' : ''}`} onClick={hasChange ? tmpSave : viewPDF}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-ok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-ok")}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-ok-tempSave" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModalTempSave("modal-ok-tempSave")}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr == null ? '' : HtmlParse(modalStr)}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatementItem;
