import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API_GET_ProcurementHome, API_GET_ProcurementDataList } from "../../global/constants";
import { NumFormart, InitJS, MenuSet, OpenModal } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
import moment from 'moment';

const PurchaseRequestList = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        //把menu顏色置換
        MenuSet("PurchaseRequestQry");
        getCompanyName();

        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)

    }, []);
    const [companyName, setCompanyName] = useState("");
    const [companyArray, setCompanyArray] = useState([]);
    const [dataList, setDataList] = useState([{}]);

    function getCompanyName() {
        API_GET_ProcurementHome()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //負責公司
                    setCompanyArray(data.result.data.companyArray);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    useEffect(() => {
        if (companyArray && companyArray.length > 0) {
            getData();
        }
    }, [companyArray]);

    function getData() {
        var dt = {
            companyId: parseInt(queryParams.get("companyId")),
            purchaseSignatureType: queryParams.get("purchaseSignatureType"),
            signatureNumber: queryParams.get("signatureNumber"),
            purchaseType: queryParams.get("purchaseType"),
            customerName: queryParams.get("customerName"),
            productTypeId: queryParams.get("productTypeId") == "" ? null : queryParams.get("productTypeId"),
            purchaseOrderNumber: queryParams.get("purchaseOrderNumber") == "" ? null : queryParams.get("purchaseOrderNumber"),
            askPaymentNumber: queryParams.get("askPaymentNumber"),
            supplierName: queryParams.get("supplierName"),
            purchasePriceStart: queryParams.get("purchasePriceStart"),
            purchasePriceEnd: queryParams.get("purchasePriceEnd"),
            purchaseDateStart: queryParams.get("purchaseDateStart"),
            purchaseDateEnd: queryParams.get("purchaseDateEnd"),
            askPaymentDateStart: queryParams.get("askPaymentDateStart"),
            askPaymentDateEnd: queryParams.get("askPaymentDateEnd"),
        };
        console.log(JSON.stringify(dt));
        API_GET_ProcurementDataList(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {

                    var temp = companyArray.find(x => x.id == parseInt(queryParams.get("companyId")))
                    if (temp) {
                        setCompanyName(temp.name)
                    }

                    if (data.result.data != null) {
                        setDataList(data.result.data);
                    }
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //展開
    function dataOpen(index) {
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.isOpen = (item.isOpen == null || item.isOpen == false) ? true : false;
                }
                return item;
            });
        });
    }
    //var moment = require('moment');

    function returnQry() {
        var path = "/Main?path=PurchaseRequestQry";
        history.push(path);
    }

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container full">
                    <h3 className="fz-B has-border">{companyName}採購紀錄</h3>
                </div>
            </div>
            <div className="container full">
                <ul className="list-table">
                    <li className="lt-row lt-th">
                        <div className="flex-2">請購單號</div>
                        <div className="flex-4">採購主旨</div>
                        <div className="flex-2">採購日期</div>
                        <div className="flex-2">採購類別</div>
                        <div className="flex-2">產品別</div>
                        <div className="flex-2">客戶別</div>
                        <div className="flex-1 fn"></div>
                    </li>
                    {dataList.map((x, i) => (
                        <>
                            <li className={`lt-row ${x.isOpen == true ? 'highlight' : ''}`}>
                                <div className="flex-2">
                                    <a href={`/Main?path=PurchaseRequestEdit&purchaseId=${x.purchaseId}`}>
                                        {x.purchaseOrderNumber}
                                    </a>
                                </div>
                                <div className="flex-4">{x.purchaseSubject}</div>
                                <div className="flex-2">{x.purchaseDate? moment(x.purchaseDate).format('YYYY/MM/DD') : ""}</div>
                                <div className="flex-2">
                                    {(() => {
                                        if (x.purchaseType == 0) {
                                            return "事務性採購";
                                        } else if (x.purchaseType == 1) {
                                            return "業務性採購";
                                        }
                                    })()}
                                </div>
                                <div className="flex-2">{x.productTypeName}</div>
                                <div className="flex-2">{x.customerName}</div>
                                <div className="flex-1 fn jcfe">
                                    <div className="fn-bar">
                                        <div className={`btn btn-icon ${x.isOpen == true ? 'rotate' : ''}`} onClick={() => dataOpen(i)}>
                                            <i className="icon icon-28"></i>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className={`lt-row ${x.isOpen == true ? '' : 'hide'}`} id={`st-${i}`}>
                                <ul className="list-table nested">
                                    <li className="lt-row lt-th">
                                        <div className="flex-5">採購單號</div>
                                        <div className="flex-5">MA 期間</div>
                                        <div className="flex-4">請款單號</div>
                                        <div className="flex-4">採購金額</div>
                                        <div className="flex-5">供應商</div>
                                    </li>
                                    <li className="lt-row">
                                        <div className="flex-5">{x.signatureNumber}</div>
                                        <div className="flex-5">{x.maTime}</div>
                                        <div className="flex-4">{x.askPaymentNumber}</div>
                                        <div className="flex-4">{`${x.purchaseCurrName} ${x.purchaseAmount? NumFormart(x.purchaseAmount) : 0}`}</div>
                                        <div className="flex-5">{x.supplierName}</div>
                                    </li>
                                </ul>
                            </li>
                        </>
                    ))}
                </ul>
                <div className="form-item btn-area sp">
                    <div className="left">
                        <a className="btn" onClick={returnQry}>
                            <span>回上一頁</span>
                        </a>
                    </div>
                    <div className="right"></div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseRequestList;
