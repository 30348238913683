import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_CloudAccountingProductGroup,
    API_GET_CloudAccountingPreview
} from "../../global/constants";
import { InitJS, MenuSet } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const CATPreview = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        //把menu顏色置換
        MenuSet("CATRowSetQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    var moment = require("moment");
    function getData() {
        API_GET_CloudAccountingProductGroup()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setProductName1Array(data.result.data.productName1Array);
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
                var futureMonth = moment().add(-1, "M").format("YYYY/MM");
                setIncomeYM(futureMonth);
              
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [productName, setProductName] = useState();
    const [productNameError, setProductNameError] = useState(false);
    const [productName1Array, setProductName1Array] = useState([]);
    const [incomeYM, setIncomeYM] = useState(); 
    function goQry() {
        if (productName == null || productName == '') {
            setProductNameError(true);
        } else {
            setProductNameError(false);
            var path = "/Main?path=CATPreviewList&productName1=" + productName;
            history.push(path);
        }
    }
    return (
        <>
            <div className="content-body">
                <div className="title-area">
                    <div className="container narrow">
                        <h3 className="fz-B has-border">預覽出帳</h3>
                    </div>
                </div>
                <div className="container narrow">
                    <form action="" className="form-container">
                        <div className={`form-item must ${productNameError ? 'error' : ''}`}>
                            <div className="field">雲端細項</div>
                            <div className="value">
                                <select name="" id="" onChange={(e) => setProductName(e.target.value)}>
                                    <option value="">請選擇</option>
                                    {
                                        productName1Array.map(x =>
                                            <option value={x}>{x}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <span className="help-word">
                                請選擇隸屬公司
                            </span>
                        </div> 
                        <div className="form-item">
                            <div className="field">月結月份</div>
                            <div className="value">
                                <input type="text" value={incomeYM} disabled="disabled" />

                            </div>
                            <div className="help-word">
                                請輸入正確格式
                            </div>
                        </div>

                        <div className="form-item btn-area sp">
                            <div className="left">

                            </div>
                            <div className="right">

                                <a className="btn" onClick={ goQry}>
                                    <span>確定</span>
                                </a>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </>
    );
};

export default CATPreview;
