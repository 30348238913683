import React, { useState, useEffect } from "react";
import {
    API_GET_ProductReview,
    API_GET_SupplierNameList,
    API_GET_AdNameList,
    API_DEL_ProductData,
    API_PUT_ProductData,
    API_POST_ProductData,
    API_POST_Revoke,
    API_PUT_ProductReview
} from "../../../global/constants";
import { CloseModal, OpenModal } from "../../../global/ToolUtil";
import { checkAuthToken } from "../../../global/TokenUtil";
import { useHistory } from "react-router-dom";
import { CheckResponseCode } from '../../../global/ResponseUtil';

const ProductDetail = ({ reqFrmID, setModalStr, reGetData }) => {
    var history = useHistory();
    useEffect(() => {
        if (reqFrmID != null) {
            getData();
        }
    }, [reqFrmID]);

    function getData() {
        setReason("");
        OpenModal("modal-productreview");
        //console.log(reqFrmID);
        API_GET_ProductReview({
            reqFrmID: reqFrmID,
        })
            .then((response) => response.data)
            .then((data) => {
                console.log("產品維護單子回傳資料", data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setProductidArray(data.result.data.product.oldProductidArray);
                setProductFullName(data.result.data.product.productFullName);
                setPrevProductFullName(data.result.data.product.productFullName);
                setProductName(data.result.data.product.productName);
                setPrevProductName(data.result.data.product.productName);
                setProdCLID(data.result.data.product.prodCLID);
                setCompanyIdArray(data.result.data.product.companyIdArray);
                setSupplierName(data.result.data.product.venName);
                // setAdName(data.result.data.product.personADName);
                setAdName(data.result.data.personADName);
                setCmpDivID(data.result.data.product.cmpDivID);
                setActID(data.result.data.product.actID);
                setProductInCost(data.result.data.product.productInCost);
                setActPID(data.result.data.product.actPID);
                setActCID(data.result.data.product.actCID);
                setProdTPID(data.result.data.product.prodTPID);
                setReqFrmType(data.result.data.reqFrmType);
                setReqFrmCFState(data.result.data.reqFrmCFState);
                //陣列
                setProductClassArray(data.result.data.dataCard.productClassArray);
                setCompanyArray(data.result.data.dataCard.companyArray);
                setDepartmentArray(data.result.data.dataCard.departmentArray);
                setAccounting(data.result.data.dataCard.accounting);
                setAccountingProductType(data.result.data.dataCard.accountingProductType);
                setAccountingBranch(data.result.data.dataCard.accountingBranch);
                setProductData(data.result.data.dataCard.productTypeArray);
                //審查人
                setAuditorName(data.result.data.auditorName);
                //退回原因
                setReason(data.result.data.reqFrmProcData);

            })

    }
    //審核
    const [reqFrmCFState, setReqFrmCFState] = useState();
    const [reqFrmType, setReqFrmType] = useState();
    //審核人
    const [auditorName, setAuditorName] = useState('');
    const [productFullName, setProductFullName] = useState();
    function productDetailChange(e) {
        //先算細項總稱數量
        var p1 = productName.length;
        //變更的文字數量有>總稱
        if (e.target.value.length >= p1) {
            //如果前面一樣才可以讓他異動
            if (e.target.value.substr(0, p1) == productName) {
                setProductFullName(e.target.value);
            }
        }
    }

    //產品細項(改變前)
    const [prevProductFullName, setPrevProductFullName] = useState();
    const [productName, setProductName] = useState();
    function productNameChange(e) {
        //先算細項總稱數量
        var prodTPName = productData.find(x => x.id == prodTPID).name;
        var p1 = prodTPName.length;
        //變更的文字數量有>總稱
        if (e.target.value.length >= p1) {
            //如果前面一樣才可以讓他異動
            if (e.target.value.substr(0, p1) == prodTPName) {
                setProductName(e.target.value);
                setProductFullName(e.target.value);
            }
        }
    }

    //產品細項總稱(改變前)
    const [prevProductName, setPrevProductName] = useState();
    //產品類
    //產品ID
    const [productidArray, setProductidArray] = useState([]);
    const [productClassArray, setProductClassArray] = useState([{}]);
    const [prodCLID, setProdCLID] = useState();
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyIdArray, setCompanyIdArray] = useState([]);
    function companyIdArrayChange(id) {
        var tmpArray = [];
        var f622 = document.getElementsByName(id);
        for (var i = 0; i < f622.length; i++) {
            if (f622[i].checked) {
                tmpArray.push(parseInt(f622[i].value));
            }
        }
        setCompanyIdArray(tmpArray);
    }
    const [actCID, setActCID] = useState();
    const [actPID, setActPID] = useState();
    const [adeID, setAdeID] = useState();
    //退回原因
    const [reason, setReason] = useState();

    //供應商名稱
    const [supplierName, setSupplierName] = useState("");
    function supplierNameChange(e) {
        setSupplierName(e.target.value);
        getSupplierName();
    }
    function getSupplierName() {
        API_GET_SupplierNameList({
            input: document.getElementById("supplierNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setSupplierNameArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
    }
    //供應商名稱datalist
    const [supplierNameArray, setSupplierNameArray] = useState([{}]);
    //產品負責人
    const [adName, setAdName] = useState("");
    //產品負責人datalist
    const [adNameArray, setAdNameArray] = useState([{}]);
    function adNameChange(e) {
        if (e.target.value == "") {
            setAdeID(null);
            setAdName("");
        } else {
            setAdName(e.target.value);
            getAdName();
        }
    }
    function getAdName() {
        API_GET_AdNameList({
            input: document.getElementById("adNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {

                    setAdNameArray(data.result.data);
                }
            })

    }
    //產品負責單位
    const [departmentArray, setDepartmentArray] = useState([{}]);
    const [cmpDivID, setCmpDivID] = useState();
    //產品別ID
    const [prodTPID, setProdTPID] = useState();

    //會計子目
    const [accountingBranch, setAccountingBranch] = useState([{}]);
    //會計科目
    const [accounting, setAccounting] = useState([{}]);
    const [actID, setActID] = useState();
    //會計產品別
    const [accountingProductType, setAccountingProductType] = useState([{}]);
    //產品別
    const [productData, setProductData] = useState([{}]);
    //列入產品成本
    const [productInCost, setProductInCost] = useState(true);
    //撤銷
    function Revoke() {
        document.querySelector("#modal-productreview").classList.remove("active");
        OpenModal("modal-productrevoke");
    }

    function checkData() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-requiredproduct]");
        requiredlist.forEach((element) => {
            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });

        //負責公司
        if (companyIdArray.length == 0) {
            result = false;
            document.getElementById("companyArray_DIV").classList.add("show-help");
        } else {
            document.getElementById("companyArray_DIV").classList.remove("show-help");
        }
        return result;
    }
    //確認再次送審
    function AgainSend() {
        CloseModal("modal-productagain");
        //var isOK = true;
        if (reqFrmType == 0 || reqFrmType == 1) {//修改 執行checkdata
            var result = checkData();
            if (result == false) {
                return false;
            }
        }
        if (reqFrmType == 0) {
            //新增
            var dt = {
                prodTPID: prodTPID,
                productName: productName,
                prevProductName: prevProductName,
                productFullName: productFullName,
                prevProductFullName: prevProductFullName,
                prodCLID: prodCLID,
                companyIdArray: companyIdArray,
                venName: supplierName,
                personADName: adName,
                cmpDivID: cmpDivID,
                actID: actID,
                actCID: parseInt(actCID),
                actPID: parseInt(actPID),
                productInCost: productInCost,
            };
            //console.log(JSON.stringify(dt));
            API_POST_ProductData(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setModalStr(data.message);
                    if (data.code == "0000" || data.code == "0001") {
                        reGetData();
                        CloseModal("modal-productreview");
                        OpenModal("modal-ok");
                    } else {
                        OpenModal("modal-error");
                    }
                })

        } else if (reqFrmType == 1) {
            //修改
            var dt = {
                oldProductidArray: productidArray,
                prodTPID: prodTPID,
                productName: productName,
                prevProductName: prevProductName,
                productFullName: productFullName,
                prevProductFullName: prevProductFullName,
                prodCLID: prodCLID,
                companyIdArray: companyIdArray,
                venName: supplierName,
                personADName: adName,
                cmpDivID: cmpDivID,
                actID: actID,
                actCID: parseInt(actCID),
                actPID: parseInt(actPID),
                productInCost: productInCost,
                reqFrmID: reqFrmID,
                PersonADID: adeID,
            };
            //console.log(JSON.stringify(dt));
            API_PUT_ProductData(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setModalStr(data.message);
                    if (data.code == "0000" || data.code == "0001") {
                        reGetData();
                        CloseModal("modal-productreview");
                        OpenModal("modal-ok");
                    } else {
                        OpenModal("modal-error");
                    }
                })

        } else if (reqFrmType == 2) {
            //console.log(productFullName, reqFrmID);
            //刪除
            API_DEL_ProductData({
                productName2: productFullName,
                reqFrmID: reqFrmID,

            })
                .then((response) => response.data)
                .then((data) => {
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setModalStr(data.message);
                    if (data.code == "0000" || data.code == "0001") {
                        reGetData();
                        CloseModal("modal-productreview");
                        OpenModal("modal-ok");
                    } else {
                        OpenModal("modal-error");
                    }
                })
        }
        //if (isOK) {
        //    if (reqFrmCFState == 2) {
        //        setModalStr("再次送審完成");
        //    } else {
        //        setModalStr("審核完成");
        //    }
        //    CloseModal("modal-productreview");
        //    OpenModal("modal-ok");
        //} else {
        //    OpenModal("modal-error");
        //}
    }
    //產品審核 退回
    function Reason(index) {
        //var isOK = true;
        CloseModal("modal-productcheckreason");
        API_PUT_ProductReview({
            reqFrmID: reqFrmID,
            reviewStatus: 2,
            reason: reason,
        })
            .then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setModalStr(data.message);
                if (data.code == "0000" || data.code == "0001") {
                    reGetData();
                    CloseModal("modal-productreview");
                    OpenModal("modal-ok");
                }
                else {
                    OpenModal("modal-error");
                }

                //if (data.code != "0000") {
                //    setModalStr(data.message);
                //    reGetData();
                //    isOK = false;
                //} else {
                //    reGetData();
                //    setModalStr("審核完成");
                //}
            });
        //if (isOK) {
        //    if (reqFrmCFState == 2) {
        //        setModalStr("再次送審完成");
        //    } else {
        //        setModalStr("審核完成");
        //    }
        //    reGetData();
        //    CloseModal("modal-productreview");
        //    OpenModal("modal-ok");
        //} else {
        //    OpenModal("modal-error");
        //}
    }

    //撤銷
    function RevokeSend() {
        CloseModal("modal-productrevoke");
        API_POST_Revoke({
            category: "Product",
            reqFrmID: reqFrmID,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setModalStr("撤銷成功");
                    reGetData();
                    OpenModal("modal-ok");
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
    }
    //通過
    function Agree() {
        CloseModal("modal-productcheckagree");
        API_PUT_ProductReview({
            reqFrmID: reqFrmID,
            reviewStatus: 1,
            reason: reason == null ? "同意" : reason,
        })
            .then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000" || data.code == "0001") {
                    setModalStr("審核完成");
                    reGetData();
                    CloseModal("modal-productreview");
                    OpenModal("modal-ok");
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })

    }
    function openModalReason() {
        if (reason == "" || reason === undefined || reason == null) {
            document.getElementById("reason6").classList.add("error");
            return false;
        } else {
            document.getElementById("reason6").classList.remove("error");
        }
        OpenModal("modal-productcheckreason", 2);
    }

    function IsAllDisabled() {

        if (reqFrmCFState == 2) {
            if (reqFrmType == 2)
                return true;
            else
                return false;
        }

        return true;
    }
    return (
        <>
            <div id="modal-productreview" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-stamp">
                        <div className="circle">
                            <strong>
                                {(() => {
                                    if (reqFrmCFState == 2) {
                                        return "退回";
                                    } else {
                                        if (reqFrmType == 0) {
                                            return "新增";
                                        } else if (reqFrmType == 1) {
                                            return "修改";
                                        } else if (reqFrmType == 2) {
                                            return "刪除";
                                        }
                                    }
                                })()}
                            </strong>
                        </div>
                    </div>
                    <div className="modal-header">
                        <h3 className="fz-B has-border">{productFullName}</h3>
                        <div className="close" data-close>
                            <i className="icon icon-44"></i>
                        </div>
                        {
                            reqFrmCFState == 2 ?
                                <div className="right">
                                    審查人：{auditorName}
                                </div> :
                                null
                        }

                    </div>

                    <div className="modal-body">
                        <div className="form-container">
                            <div className="form-item must half">
                                <div className="field">產品別</div>
                                <div className="value">
                                    <select id="productDataList" onChange={(e) => {
                                        setProdTPID(e.target.value);

                                        var target = productData.find(x => x.id == e.target.value);
                                        if (target == null)
                                            return;
                                        var prodTPName = target.name;
                                        setProductName(prodTPName);
                                        setProductFullName(prodTPName);
                                        
                                    }} disabled={IsAllDisabled() ? true : reqFrmCFState == 2 ? false : true}>
                                        {productData.map((x) => (
                                            <option value={x.id} selected={prodTPID == x.id}>
                                                {x.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <span className="help-word">請輸入產品別</span>
                            </div>
                            <div className="form-item must half">
                                <div className="field">產品細項總稱</div>
                                <div className="value">
                                    <input type="text" value={productName} onChange={productNameChange} disabled={IsAllDisabled() ? true : reqFrmCFState == 2 ? false : true} />
                                </div>
                                <span className="help-word">請輸入產品細項總稱</span>
                            </div>

                            <div className="form-item must half">
                                <div className="field">產品細項</div>
                                <div className="value">
                                    <input type="text" value={productFullName} onChange={productDetailChange} disabled={IsAllDisabled() ? true : reqFrmCFState == 2 ? false : true} />
                                </div>
                                <span className="help-word">請輸入產品細項</span>
                            </div>

                            <div className="form-item must half">
                                <div className="field">產品類</div>
                                <div className="value">
                                    {productClassArray.map((x) => (
                                        <div>
                                            <input
                                                key={`f621-${x.id}`}
                                                type="radio"
                                                id={`f621-${x.id}`}
                                                name="f621"
                                                value={x.id}
                                                checked={x.id == prodCLID ? true : false}
                                                onClick={e => setProdCLID(x.id)}
                                                disabled={IsAllDisabled()}
                                            />
                                            <label htmlFor={`f621-${x.id}`}>{x.name}</label>
                                        </div>
                                    ))}
                                </div>
                                <span className="help-word">請輸入產品類</span>
                            </div>

                            <div className="form-item must" id="companyArray_DIV">
                                <div className="field">隸屬公司</div>
                                <div className="value">
                                    {companyArray.map((x) => (
                                        <div>
                                            <input type="checkbox" key={`f622-${x.id}`} id={`f622-${x.id}`} name="f622" value={x.id}
                                                onClick={e => companyIdArrayChange('f622')}
                                                checked={companyIdArray.findIndex((e) => e == x.id) >= 0 ? true : false}
                                                disabled={IsAllDisabled()}
                                            />
                                            <label htmlFor={`f622-${x.id}`}>{x.name}</label>
                                        </div>
                                    ))}
                                </div>
                                <span className="help-word">請輸入隸屬公司</span>
                            </div>

                            <div className="form-item must half has-datalist" data-datalist>
                                <div className="field">供應商名稱</div>
                                <div className="value">
                                    <input type="text" id="supplierNameText" value={supplierName}
                                        onChange={supplierNameChange}
                                        disabled={IsAllDisabled()} data-requiredproduct />
                                    <ul className="datalist scrollbar">
                                        {supplierNameArray.map((x) => (
                                            <li data-value={x.name} onClick={(e) => { setSupplierName(x.name); }}>
                                                <span className="flex-10 bold">{x.name}</span>
                                                {/*<span className="flex-2">12345678</span>*/}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <span className="help-word">請輸入供應商名稱</span>
                            </div>

                            <div className="form-item half"></div>

                            <div className="form-item half  has-datalist" data-datalist>
                                <div className="field">產品負責人</div>
                                <div className="value">
                                    <input type="text" id="adNameText" onChange={adNameChange} value={adName} autoComplete="off" disabled={IsAllDisabled()} />
                                    <ul className="datalist scrollbar">
                                        {adNameArray.map((x) => (
                                            <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => { setAdName(x.enName); setAdeID(x.id) }}>
                                                <span className="flex-4">{x.enName}({x.tc_name})</span>
                                                <span className="flex-5">{x.mail}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <span className="help-word">請輸入產品負責人</span>
                            </div>

                            <div className="form-item must half">
                                <div className="field">產品負責單位</div>
                                <div className="value">
                                    {departmentArray.map((x) => (
                                        <div>
                                            <input
                                                type="radio"
                                                key={`f623-${x.id}`}
                                                id={`f623-${x.id}`}
                                                name="f623"
                                                value={x.id}
                                                checked={x.id == cmpDivID ? true : false}
                                                onClick={e => setCmpDivID(parseInt(e.target.value))}
                                                disabled={IsAllDisabled()}
                                            />
                                            <label htmlFor={`f623-${x.id}`}>{x.name}</label>
                                        </div>
                                    ))}
                                </div>
                                <span className="help-word">請輸入產品負責單位</span>
                            </div>

                            <hr />

                            <div className="form-item must half">
                                <div className="field">會計科目</div>
                                <div className="value">
                                    {accounting.map((x) => (
                                        <div>
                                            <input
                                                type="radio"
                                                key={`f624-${x.id}`}
                                                id={`f624-${x.id}`}
                                                name="f624"
                                                value={x.id}
                                                checked={x.id == actID ? true : false}
                                                onClick={e => setActID(parseInt(e.target.value))}
                                                disabled={IsAllDisabled()}
                                            />
                                            <label htmlFor={`f624-${x.id}`}>
                                                {x.name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <span className="help-word">請選擇會計科目</span>
                            </div>

                            <div className="form-item must half">
                                <div className="field">列入產品成本</div>
                                <div className="value">
                                    <div>
                                        <input type="radio" id="f625-1" name="f625" value="true"
                                            checked={productInCost}
                                            onClick={e => setProductInCost(e.target.value == "true" ? true : false)}
                                            disabled={IsAllDisabled()}
                                        />
                                        <label htmlFor="f625-1">是</label>
                                    </div>
                                    <div>
                                        <input type="radio" id="f625-2" name="f625" value="false"
                                            checked={!productInCost}
                                            onClick={e => setProductInCost(e.target.value == "false" ? false : true)}
                                            disabled={IsAllDisabled()}
                                        />
                                        <label htmlFor="f625-2">否</label>
                                    </div>
                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>

                            <div className="form-item must half">
                                <div className="field">會計子目</div>
                                <div className="value">
                                    <select name="" id="" onChange={e => setActCID(e.target.value)} disabled={IsAllDisabled()} data-requiredproduct>
                                        {accountingBranch.map((x) => (
                                            <option value={x.id} selected={actCID == x.id}>
                                                {x.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>

                            <div className="form-item must half">
                                <div className="field">會計產品別</div>
                                <div className="value">
                                    <select name="" id="" onChange={e => setActPID(e.target.value)} disabled={IsAllDisabled()} data-requiredproduct>
                                        <option value="">請選擇會計產品別</option>
                                        {accountingProductType.map((x) => (
                                            <option value={x.id} selected={actPID == x.id}>
                                                {x.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>

                            <div className="form-item btn-area">
                                {
                                    reqFrmCFState == 2 ?
                                        <>
                                            <a className="btn btn-border" onClick={Revoke} >
                                                <span>撤銷</span>
                                            </a>
                                            <a className="btn" onClick={() => OpenModal("modal-productagain", 2)}>
                                                <span>再次送審</span>
                                            </a>
                                        </>
                                        :
                                        reqFrmCFState == 3 ? null :
                                            <>
                                                <a className="btn btn-border" onClick={openModalReason}>
                                                    <span>退回</span>
                                                </a>
                                                <a className="btn" onClick={(e) => OpenModal("modal-productcheckagree", 2)}>
                                                    <span>確定</span>
                                                </a>
                                            </>
                                }
                            </div>
                            <div className="form-item must col feedback">
                                <div className="field">退回原因</div>
                                <div className="value">
                                    <textarea
                                        name="reason"
                                        id="reason6"
                                        cols="30"
                                        rows="6"
                                        placeholder="請輸入退回原因"
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                        disabled={reqFrmCFState == 2 || reqFrmCFState == 3 ? true : false}
                                    ></textarea>
                                </div>
                                <span className="help-word">請輸入退回原因</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-productcheckreason" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確認要退回此筆資料？</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-productcheckreason")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={Reason}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-productcheckagree" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確認要通過此筆資料？</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-productcheckagree")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={Agree}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            <div id="modal-productagain" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定再次送審?
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-productagain")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={AgainSend}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-productrevoke" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定撤銷?
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-productrevoke")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={() => RevokeSend()}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>


        </>

    );
};
export default ProductDetail;
