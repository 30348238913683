import React, { useState, useEffect } from "react";
import '../css/style.css';
import { FormattedMessage } from "react-intl"; //多國語言套件
const ErrorCommon = () => {
    useEffect(() => {
        document.body.classList.add('login');
        document.body.classList.remove('dashboard');
        document.getElementById('root').classList.remove('center');
    }, []);

    return (
        <div className="full center">
            <div className="login-box">
                <div className="title">
                    <i className="icon icon-31"></i>
                    <h1 className="fz-hero">
                        <FormattedMessage id="systemName" /> {/*系統名稱*/}
                    </h1>
                </div>
                <div className="form-container">
                    <div style={{ width: "100%", textAlign: 'center', fontWeight: 'bolder' }}>
                        Sorry,您的帳號已停用
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ErrorCommon;