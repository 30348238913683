import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

function Error() {
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        var type = queryParams.get('type');
        if (type == "2") {
            setMsgStr('您的帳號已停用');
        } else if (type == '1') {
            setMsgStr('授權失敗');
        } else if (type == '3') {
            setMsgStr('系統發生錯誤，請與系統管理員聯繫');
        }
    }, []);

    const [msgStr, setMsgStr] = useState("");


    return (
        <div style={{ width: "100%", textAlign: 'center', fontWeight: 'bold' }}>
            Sorry,{msgStr}
        </div>
    );
}
export default Error;
