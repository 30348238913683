import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { DebounceInput } from 'react-debounce-input';
import {
    API_HOST,
    API_GET_CloudAcntNewDataCard,
    API_GET_Customer3Name,
    API_GET_GetExRates,
    API_GET_AdNameList,
    API_GET_AllCurrency,
    API_GET_CloudAcntCloudProduct,
    API_GET_CloudAcntGetRawDataList,
    API_POST_CloudAcntData,
    API_GET_CloudAcntSalesGetCustomer,
    API_POST_CloudAcntBatchImport,
    API_GET_CloudAcntTemplateDownload,
    API_GET_CloudAcntCloudProductName1
} from "../../global/constants";
import { Clone, NewNumFormart, NumFormart, CloseModal, OpenModal, InitJS, UrlDecode, SetOnbeforeunload, MenuSet, SetHasChange } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const CloudAcntNew = () => {
    var history = useHistory();
    var onbeforeunloadFlag = false;
    const [username, setUserName] = useState('');
    useEffect(() => {
        //把menu顏色置換
        MenuSet("CloudAcntQry");
        getData();
        setUserName(localStorage.getItem("userName"));
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    function getData() {
        API_GET_CloudAcntNewDataCard()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (data.code == "0000") {
                    //負責公司
                    setCompanyArray(data.result.data.companyArray);
                    //是否為PM
                    setIsPM(data.result.data.isPM);
                    setMyID(data.result.data.myId);
                    if (data.result.data.isPM == 0) {//業務權限 負責業務只能是自己
                        setPersonInChargeId(data.result.data.myId);
                        setPersonInChargeName(data.result.data.myName);

                        setOldPersonInChargeId(data.result.data.myId);
                        setOldPersonInChargeName(data.result.data.myName);
                    }
                    //雲端細項總稱
                    //setCloudProductName1Array(data.result.data.cloudProductName1);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        API_GET_AllCurrency()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //負責公司
                    setCurrencyArray(data.result.data);
                }
            })
    }
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyId, setCompanyId] = useState(0);
    const [companyIdError, setCompanyIdError] = useState(false);
    useEffect(() => {
        console.log("SWITCH ON");
        setCustomerName("");
        setCustomerTitle("");
        setCustomerTaxId("");
        setCloudProductName1("");
    }, [companyId])
    //是否為PM
    const [isPM, setIsPM] = useState(0);
    //自己ID
    const [myID, setMyID] = useState();
    //幣別
    const [currencyArray, setCurrencyArray] = useState([{}]);
    //雲端細項總稱
    const [cloudProductName1Array, setCloudProductName1Array] = useState([]);
    const [cloudProductName1, setCloudProductName1] = useState();
    function dataOpen(index) {
        setAccountData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.isOpen = (item.isOpen == null || item.isOpen == false) ? true : false;
                }
                return item;
            });
        });
    }

    useEffect(() => { //切換公司就要更新[雲端細項總稱]、[產品細項]
        API_GET_CloudAcntCloudProductName1({
            companyId: companyId
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (data.code == "0000") {
                    //雲端細項總稱
                    setCloudProductName1Array(data.result.data.productName1Array);
                }
            });
    }, [companyId]);

    //暫存index
    const [tmpIndex1, setTmpIndex1] = useState(0);
    const [tmpIndex2, setTmpIndex2] = useState(0);
    const [tmpIndex3, setTmpIndex3] = useState(0);
    const [subAccountComments, setSubAccountComments] = useState([]); //選中的備註
    //0:備註 1:PM備註
    const [remarkType, setRemarkType] = useState(0);

    function cloudProductName1Change(e) {
        if (e.target.value != '') {
            setCloudProductName1(e.target.value);
            API_GET_CloudAcntCloudProduct({
                productName1: e.target.value,
                companyId: companyId
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        setCloudProductName2Array(data.result.data.productName2Array);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }

    }
    //雲端細項
    const [cloudProductName2Array, setCloudProductName2Array] = useState([]);

    //折扣
    const [wholeDiscount, setWholeDiscount] = useState("0%");
    //負責業務ID
    const [adNameState, setAdNameState] = useState(true);
    const [personInChargeId, setPersonInChargeId] = useState();
    const [oldPersonInChargeId, setOldPersonInChargeId] = useState();
    const [personInChargeNameArray, setPersonInChargeNameArray] = useState([{}]);
    const [personInChargeName, setPersonInChargeName] = useState();
    const [oldPersonInChargeName, setOldPersonInChargeName] = useState();

    //#region 修改負責業務
    function personInChargeNameChange(e) {
        setPersonInChargeName(e.target.value);
        getPersonInChargeName();
    }
    //#endregion

    //#region 取得負責業務列表
    function getPersonInChargeName() {
        if (document.getElementById("personInChargeNameText").value == "") {
            return false;
        }

        API_GET_AdNameList({
            input: document.getElementById("personInChargeNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setPersonInChargeNameArray(data.result.data);
                }
            }).catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //#endregion

    //客戶ID
    const [customerId, setCustomerId] = useState();
    //客戶名
    const [customerName, setCustomerName] = useState();
    const [customerNameArray, setCustomerNameArray] = useState([{}]);
    function customerNameChange(e) {
        if (e.target.value == null || e.target.value == "") {
            setCustomerName("");
            setCustomerNameArray([]);
            setCustomerTitle("");
            setCustomerTitleArray([]);
            setCustomerTaxId("");
            setCustomerTaxIdArray([]);
            if (isPM == 1) {
                setPersonInChargeName("");
                setOldPersonInChargeName("");
            }
        } else {
            setCustomerName(e.target.value);
            getCustomer3Name(1);
        }
    }

    //客戶抬頭
    const [customerTitle, setCustomerTitle] = useState();
    const [customerTitleArray, setCustomerTitleArray] = useState([{}]);
    function customerTitleChange(e) {
        if (e.target.value == null || e.target.value == "") {
            setCustomerName("");
            setCustomerNameArray([]);
            setCustomerTitle("");
            setCustomerTitleArray([]);
            setCustomerTaxId("");
            setCustomerTaxIdArray([]);
            if (isPM == 1) {
                setPersonInChargeName("");
                setOldPersonInChargeName("");
            }
        } else {
            setCustomerTitle(e.target.value);
            getCustomer3Name(2);
        }
    }
    //客戶統編
    const [customerTaxId, setCustomerTaxId] = useState();
    const [customerTaxIdArray, setCustomerTaxIdArray] = useState([{}]);
    function customerTaxIdChange(e) {
        if (e.target.value == null || e.target.value == "") {
            setCustomerName("");
            setCustomerNameArray([]);
            setCustomerTitle("");
            setCustomerTitleArray([]);
            setCustomerTaxId("");
            setCustomerTaxIdArray([]);
            if (isPM == 1) {
                setPersonInChargeName("");
                setOldPersonInChargeName("");
            }
        } else {
            setCustomerTaxId(e.target.value);
            getCustomer3Name(3);
        }
    }

    var moment = require("moment");
    //雲端帳號列表
    const [accountData, setAccountData] = useState([
        {
            acntId: null,
            acntName: null,
            acntCode: null,
            productId: null,
            quotaCurrencyId: "",
            quota: null,
            serviceCharge: 0,
            state: 1,
            stateDate: moment().format("YYYY-MM-DD"),
            help: 0,
            subAccountComments: [],
            specialPricing: [{
                id: null,
                title: null,
                isCoupon: 0,
                rawData: null,
                discountPrice: 0,
                discountUnit: 0,
                formula: [{
                    rawData: null,
                    isNumber: 0,
                    formulaSigns: null,
                    lowerLimit: null,
                    upperLimit: null,
                    value: null,
                }],
            }],
        }
    ]);
    //資料修改 第一層的
    function accountDataChange(e, index, col) {
        var newAccountData = [...accountData];
        var selectAccountData = newAccountData[index];

        if (col == "acntId") {
            selectAccountData.acntId = e.target.value;
        } else if (col == "acntName") {
            selectAccountData.acntName = e.target.value;
        } else if (col == "acntCode") {
            selectAccountData.acntCode = e.target.value;
        } else if (col == "productId") {
            selectAccountData.productId = e.target.value;
        } else if (col == "quotaCurrencyId") {
            selectAccountData.quotaCurrencyId = e.target.value;
        } else if (col == "quota") {
            let tmp = parseFloat(e.target.value.replaceAll(/,/g, '').replace(/[^0-9.]/g, ''));
            selectAccountData.quota = !isNaN(tmp) ? tmp : "";
        } else if (col == "serviceCharge") {
            let tmp = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
            selectAccountData.serviceCharge = !isNaN(tmp) ? tmp : 0;
        } else if (col == "state") {
            selectAccountData.state = selectAccountData.state == 1 ? 2 : 1; //如果啟用狀態本來是1 就給他2 反之2 就給1
        } else if (col == "stateDate") {
            selectAccountData.stateDate = e.target.value;
        } else if (col == "help") {
            selectAccountData.help = selectAccountData.help == 1 ? 0 : 1; //如果技術支援本來是1 就給他0 反之0 就給1
        } else if (col == "isOpen") {
            selectAccountData.isOpen = (selectAccountData.isOpen == null || selectAccountData.isOpen == false) ? true : false;
        }

        newAccountData[index] = selectAccountData;
        setAccountData(newAccountData);

        //setAccountData(function (prev) {
        //    return prev.map((item, i) => {
        //        if (i == index) {
        //            if (col == "acntId") {
        //                item.acntId = e.target.value;
        //            } else if (col == "acntName") {
        //                item.acntName = e.target.value;
        //            } else if (col == "acntCode") {
        //                item.acntCode = e.target.value;
        //            } else if (col == "productId") {
        //                item.productId = e.target.value;
        //            } else if (col == "quotaCurrencyId") {
        //                item.quotaCurrencyId = e.target.value;
        //            } else if (col == "quota") {
        //                let tmp = parseFloat(e.target.value.replaceAll(/,/g, '').replace(/[^0-9.]/g, ''));
        //                item.quota = !isNaN(tmp) ? tmp : "";
        //            } else if (col == "serviceCharge") {
        //                let tmp = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
        //                item.serviceCharge = !isNaN(tmp) ? tmp : 0;
        //            } else if (col == "state") {
        //                item.state = item.state == 1 ? 2 : 1; //如果啟用狀態本來是1 就給他2 反之2 就給1
        //            } else if (col == "stateDate") {
        //                item.stateDate = e.target.value;
        //            } else if (col == "help") {
        //                item.help = item.help == 1 ? 0 : 1; //如果技術支援本來是1 就給他0 反之0 就給1
        //            } else if (col == "isOpen") {
        //                item.isOpen = (item.isOpen == null || item.isOpen == false) ? true : false;
        //            }
        //        }
        //        return item;
        //    });
        //});
    }
    //資料修改 第二層的特殊產品計價
    function accountDataChange2(e, index, index2, col) {

        setAccountData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    for (var i2 = 0; i2 < item.specialPricing.length; i2++) {
                        if (i2 == index2) {

                            if (col == "title") {
                                item.specialPricing[i2].title = e.target.value;
                            } else if (col == "isCoupon") {
                                item.specialPricing[i2].isCoupon = item.specialPricing[i2].isCoupon == 1 ? 0 : 1;
                            } else if (col == "rawData") {
                                item.specialPricing[i2].rawData = e.target.value;
                            } else if (col == "discountPrice") {
                                item.specialPricing[i2].discountPrice = e.target.value;
                            } else if (col == "discountUnit") {
                                item.specialPricing[i2].discountUnit = e.target.value;
                            }
                            //rawData  discount discountUnit 是在內頁修改 所以修改玩要先把內頁的資料也更新
                            var dt = Clone(item);
                            setSpecialPricingData(dt);
                        }
                    }

                }
                return item;
            });
        });
    }
    //新增帳號ID
    function AddUserID() {
        //先關閉全部的展開
        setAccountData(function (prev) {
            return prev.map((item, i) => {
                item.isOpen = false;
                return item;
            });
        });

        setAccountData(function (prev) {
            return [...prev,
            {
                isOpen: true,
                acntId: null,
                acntName: null,
                acntCode: null,
                productId: null,
                quotaCurrencyId: "",
                quota: null,
                serviceCharge: 0,
                state: 1,
                stateDate: moment().format("YYYY-MM-DD"),
                help: 0,
                subAccountComments: [],
                specialPricing: [{
                    id: null,
                    title: null,
                    isCoupon: 0,
                    rawData: null,
                    discountPrice: 0,
                    discountUnit: 0,
                    formula: [{
                        rawData: null,
                        isNumber: null,
                        formulaSigns: null,
                        lowerLimit: null,
                        upperLimit: null,
                        value: null,
                    }],
                }],
            }
            ];
        });
    }

    //#region 刪除雲端帳號
    const handleDelAccount = async (index) => {
        let newAccountData = [...accountData];
        newAccountData.splice(index, 1);
        setAccountData(newAccountData);
    }
    //#endregion

    useEffect(() => {
        console.log("accountData", accountData);
    }, [accountData]);

    //重新取得 客戶別 客戶別Title 客戶統編 List
    function getCustomer3Name(index) {
        var dt = {};
        if (index == 1) {
            dt = {
                companyId: companyId,
                customerName: document.getElementById("customerNameText").value,
                personInChadgeId: (personInChargeId == null ? null : personInChargeId),
            };
        } else if (index == 2) {
            dt = {
                companyId: companyId,
                customerTitle: document.getElementById("customerTitleText").value,
                personInChadgeId: (personInChargeId == null ? null : personInChargeId),
            };
        } else if (index == 3) {
            dt = {
                companyId: companyId,
                customerTaxId: document.getElementById("customerTaxIdText").value,
                personInChadgeId: (personInChargeId == null ? null : personInChargeId),
            };
        }

        API_GET_Customer3Name(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                var dataName = [];
                var dataTitle = [];
                var dataTaxId = [];
                if (data.result != null && data.result.data != null) {
                    if (index == 1) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataName.length; j++) {
                                if (dataName[j].custName == data.result.data[i].custName) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataName.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxId: data.result.data[i].custTaxID,
                                    personADName: data.result.data[i].personADName,
                                    personADID: data.result.data[i].personADID,
                                });
                            }
                        }
                        //console.log(dataName);
                        setCustomerNameArray(dataName);
                    } else if (index == 2) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTitle.length; j++) {
                                if (dataTitle[j].custTitle == data.result.data[i].custTitle) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTitle.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxId: data.result.data[i].custTaxID,
                                    personADName: data.result.data[i].personADName,
                                    personADID: data.result.data[i].personADID,
                                });
                            }
                        }
                        setCustomerTitleArray(dataTitle);
                    } else if (index == 3) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTaxId.length; j++) {
                                if (dataTaxId[j].custTaxID == data.result.data[i].custTaxID) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTaxId.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxId: data.result.data[i].custTaxID,
                                    personADName: data.result.data[i].personADName,
                                    personADID: data.result.data[i].personADID,
                                });
                            }
                        }
                        setCustomerTaxIdArray(dataTaxId);
                    }
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //重新取得 客戶別 客戶別Title 客戶統編 List(未使用)
    function getCustomer3Name2(index) {

        var dt = {
            companyId: companyId,
            personInChargeId: personInChargeId
        };
        API_GET_CloudAcntSalesGetCustomer(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                var dataName = [];
                var dataTitle = [];
                var dataTaxId = [];
                if (data.result != null && data.result.data != null) {
                    if (index == 1) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataName.length; j++) {
                                if (dataName[j].custName == data.result.data[i].customerName) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataName.push({
                                    custID: data.result.data[i].customerId,
                                    custName: data.result.data[i].customerName,
                                    custTitle: data.result.data[i].customerTitle,
                                    custTaxId: data.result.data[i].customerTaxId,
                                    personADName: personInChargeName,
                                    personADID: personInChargeId,
                                });
                            }
                        }
                        setCustomerNameArray(dataName);
                    } else if (index == 2) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTitle.length; j++) {
                                if (dataTitle[j].custTitle == data.result.data[i].customerTitle) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTitle.push({
                                    custID: data.result.data[i].customerId,
                                    custName: data.result.data[i].customerName,
                                    custTitle: data.result.data[i].customerTitle,
                                    custTaxId: data.result.data[i].customerTaxId,
                                    personADName: personInChargeName,
                                    personADID: personInChargeId,
                                });
                            }
                        }
                        setCustomerTitleArray(dataTitle);
                    } else if (index == 3) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTaxId.length; j++) {
                                if (dataTaxId[j].custTaxId == data.result.data[i].customerTaxId) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTaxId.push({
                                    custID: data.result.data[i].customerId,
                                    custName: data.result.data[i].customerName,
                                    custTitle: data.result.data[i].customerTitle,
                                    custTaxId: data.result.data[i].customerTaxId,
                                    personADName: personInChargeName,
                                    personADID: personInChargeId,
                                });
                            }
                        }
                        setCustomerTaxIdArray(dataTaxId);
                    }
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //#region 打開備註
    function OpenModalRemark(index, type) {
        setTmpRemark("");
        setTmpIndex1(index);
        setRemarkType(type);
        setSubAccountComments(accountData[index].subAccountComments.filter(x => x.type == type));
        OpenModal("modal-remark");
    }
    //#endregion

    //#region 修改備註
    function editRemark() {
        var newAccountData = [...accountData];
        newAccountData[tmpIndex1].subAccountComments.push({
            id: null,
            accountId: newAccountData[tmpIndex1].acntId,
            type: remarkType,
            content: tmpRemark,
            userId: parseInt(myID),
            userName: username,
            createdTime: new Date()
        });
        setAccountData(newAccountData);
        CloseModal("modal-remark");
    }
    //#endregion

    //新增特殊產品計費項目
    function AddSpecialPricing(index) {

        setAccountData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    var dt = Clone(accountData[i].specialPricing);
                    //console.log(dt);
                    dt.push({
                        id: null,
                        title: null,
                        isCoupon: 0,
                        rawData: null,
                        discountPrice: 0,
                        discountUnit: 0,
                        formula: [{
                            rawData: null,
                            isNumber: null,
                            formulaSigns: null,
                            lowerLimit: null,
                            upperLimit: null,
                            value: null,
                        }]
                    });
                    item.specialPricing = dt;

                }
                return item;
            });
        });
    }
    //刪除特殊產品計價
    function DelSpecialPricing(index, index2) {

        setAccountData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    var dt = Clone(accountData[i].specialPricing);
                    var tmpDt = [];
                    for (var idx = 0; idx < dt.length; idx++) {
                        if (index2 != idx) {
                            tmpDt.push(dt[idx]);
                        }
                    }
                    item.specialPricing = tmpDt;
                }
                return item;
            });
        });
    }
    const [rawDataArray, setRawDataArray] = useState([{}]);
    const [formulaData, setFormulaData] = useState([{}]);
    const [specialPricingData, setSpecialPricingData] = useState([{}]);
    function specialPricingDataChange(e, col) {

        var dt = Clone(specialPricingData);
        if (col == "discountPrice") {
            dt.discountPrice = parseFloat((e.target.value + "").replace(/[^0-9.-]/g, ''));
        }
        if (col == "rawData") {
            dt.rawData = e.target.value;
        }
        if (col == "discountUnit") {
            dt.discountUnit = e.target.value;
        }
        setSpecialPricingData(dt);
    }

    //#region 打開 編輯計費項目 modal
    function OpenModalFormula(index, index2, productId) {
        setTmpIndex1(index);
        setTmpIndex2(index2);
        API_GET_CloudAcntGetRawDataList({ productId: productId })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setRawDataArray(data.result.data);
                }
            })

        var dt = Clone(accountData[index].specialPricing[index2].formula);
        //console.log(dt);
        setFormulaData(dt);
        var dt2 = Clone(accountData[index].specialPricing[index2]);
        //console.log(dt2);
        setSpecialPricingData(dt2);
        OpenModal("modal-formula");
    }
    //#endregion

    //#region 修改 編輯計費項目
    function formulaDataChange(e, index, col) {
        setFormulaData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "formulaSigns") {
                        item.formulaSigns = e.target.value;
                    } else if (col == "isNumber") {
                        item.formulaSigns = item.isNumber == 0 ? "<Y<" : "=";
                        item.isNumber = item.isNumber == 0 ? 1 : 0;
                    } else if (col == "rawData") {
                        item.rawData = e.target.value;
                    } else if (col == "lowerLimit") {
                        item.lowerLimit = parseFloat((e.target.value + "").replace(/[^0-9.]/g, ''));
                    } else if (col == "upperLimit") {
                        item.upperLimit = parseFloat((e.target.value + "").replace(/[^0-9.]/g, ''));
                    } else if (col == "value") {
                        item.value = e.target.value;
                    }
                }
                return item;
            });
        });
    }
    //#endregion

    //#region 新增 編輯計費項目
    function AddFormulaData() {

        setFormulaData(function (prev) {
            return [...prev,
            {
                rawData: null,
                isNumber: 0,
                formulaSigns: "<Y<",
                lowerLimit: null,
                upperLimit: null,
                value: null,

            }];


        });
    }
    //#endregion

    //#region 刪除 編輯計費項目
    function DelFormulaData(index) {
        setFormulaData(function (prev) {
            return prev.filter((x, i) => i != index);
        });
    }
    //#endregion

    //#region  編輯計費項目 確定算式
    function CheckFormulaData() {

        //console.log(formulaData);
        //console.log(specialPricingData);
        //更新 折扣計費欄位 折扣 / 單價
        //更新整個formula 雲端帳號計算公式
        setAccountData(function (prev) {
            return prev.map((item, i) => {
                if (i == tmpIndex1) {

                    item.specialPricing.map((item2, i2) => {
                        if (i2 == tmpIndex2) {
                            item2.discountPrice = specialPricingData.discountPrice;
                            item2.discountUnit = specialPricingData.discountUnit;
                            item2.rawData = specialPricingData.rawData;
                            //
                            item2.formula = formulaData;
                        }
                    })
                }
                return item;
            });
        });
        CloseModal("modal-formula");
    }
    //#endregion

    //#region  編輯計費項目 清空欄位
    function ClearFormulaData() {

        var dt = [{
            rawData: null,
            isNumber: 0,
            formulaSigns: "<Y<",
            lowerLimit: null,
            upperLimit: null,
            value: null,
        }];
        setFormulaData(dt);
        //setFormulaData(initFormulaData);
        var dt = Clone(specialPricingData);
        dt.rawData = null;
        dt.discountPrice = 0;
        dt.discountUnit = 0;
        setSpecialPricingData(dt);

    }
    //#endregion

    const [modalStr, setModalStr] = useState();
    //新增
    function AddCloudAcnt(e) {
        e.target.classList.add("disabled");
        var result = true;
        result = checkData();
        if (result == true) {
            var dt = {
                companyId: companyId,
                customerId: customerId,
                customerName: customerName,
                customerTitle: customerTitle,
                customerTaxId: customerTaxId,
                personInChargeId: personInChargeId,
                wholeDiscount: (wholeDiscount == "" ? null : parseFloat(wholeDiscount.replace(/[^0-9.]/g, ''))),
                accountData: accountData,
            };
            //API 11-6
            console.log("新增雲端帳號的json", JSON.stringify(dt));

            API_POST_CloudAcntData(dt)
                .then((response) => response.data)
                .then((data) => {
                    e.target.classList.remove("disabled");
                    if (!checkAuthToken(data)) {
                        setPersonInChargeName(localStorage.getItem(userName));
                        setPersonInChargeId(localStorage.getItem(userID));

                        setOldPersonInChargeName(localStorage.getItem(userName));
                        setOldPersonInChargeId(localStorage.getItem(userID));
                        setIsPM(0);
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        OpenModal("modal-success");
                        window.onbeforeunload = null;
                        onbeforeunloadFlag = false;
                        SetHasChange(false);
                    }
                    else if (data.code == "4018") {
                        setPersonInChargeName(oldPersonInChargeName);
                        setPersonInChargeId(oldPersonInChargeId);
                        setModalStr(data.message);
                        OpenModal("modal-error");
                    }
                    else {
                        setModalStr(data.message);
                        OpenModal("modal-error");
                    }
                })
        } else {
            e.target.classList.remove("disabled");
        }
    }
    function checkData() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {
            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });
        if (companyId == null) {
            setCompanyIdError(true);
            result = false;
        } else {
            setCompanyIdError(false)
        }
        accountData.forEach((item, i) => {
            if (!item.quota || !item.quotaCurrencyId)
                if (!item.isOpen)
                    dataOpen(i);
        });
        return result;
    }
    const [tmpRemark, setTmpRemark] = useState();

    //下載資料卡
    const FileDownload = require("js-file-download");
    function GETTemplate() {
        API_GET_CloudAcntTemplateDownload()
            .then((response) => {
                //console.log(response);
                const disposition = response.headers['content-disposition'];
                var filename = disposition.split("''")[1];
                FileDownload(response.data, UrlDecode(filename));
            });
    }

    function getFile() {
        document.getElementById("file").click();
    }
    //const [selectedFile, setSelectedFile] = useState();
    function getFileExt(filename) {
        var a = filename.split(".");
        if (a.length === 1 || (a[0] === "" && a.length === 2)) {
            return "";
        }
        return a.pop();

    }
    function selectedFileChange(e) {
        var fileExt = getFileExt(e.target.files[0].name);
        if (fileExt != "xlsx" && fileExt != "csv" && fileExt != "xls") {
            setModalStr("檔案格式錯誤");
            OpenModal("modal-error2");
            return false;
        } else {
            const formData = new FormData();
            formData.append("formFile", e.target.files[0]);
            const requestOptions = {
                method: "POST",
                headers: {
                    //"Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                body: formData,
            };

            var path = API_HOST + "/cloudAcnt/batchImport";
            fetch(path, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        var dt = data.result.data;
                        var tmpAccountData = Clone(accountData);
                        var addSum = 0;
                        //跑excel資料的迴圈
                        for (var i = 0; i < dt.length; i++) {
                            var hasAcntId = false;
                            //跑現有的雲端帳號迴圈
                            for (var j = 0; j < accountData.length; j++) {
                                if (dt[i].acntId == accountData[j].acntId) {
                                    //如果有這個帳號ID 註記已經有了(不匯入)
                                    hasAcntId = true;
                                    break;
                                } else {
                                    addSum++;
                                }
                            }
                            //都確認過沒有這個帳號ID 寫進去
                            if (hasAcntId == false) {
                                setAccountData(function (prev) {
                                    return [...prev, dt[i]]
                                })
                            }
                        }

                    } else {
                        setModalStr(data.message);
                        OpenModal("modal-error2");

                    }
                });
        }


    }

    function reflash() {
        window.location.reload();
    }
    function customerChange(custName, custTitle, custTaxId, custID, personADName, personADID) {
        if (!custID) {
            setModalStr("後端客戶ID為空白，請確認客戶資料是否由下拉選單帶出");
            OpenModal("modal-error");
            return;
        }
        setCustomerName(custName);
        setCustomerTitle(custTitle);
        setCustomerTaxId(custTaxId);
        setCustomerId(custID);
        if (isPM == 1) {
            setPersonInChargeId(personADID);
            setOldPersonInChargeId(personADID);
            setPersonInChargeName(personADName)
            setOldPersonInChargeName(personADName)
            
        }
    }
    return (
        <>
            <div className="content-body">
                <div className="title-area">
                    <div className="container full">
                        <h3 className="fz-B has-border">新增雲端帳號</h3>
                    </div>
                </div>
                <div className="container full">

                    <div className="form-container scale-to-normall">


                        <div className={`form-item must ${companyIdError ? 'show-help' : ''}`}>
                            <div className="field">隸屬公司</div>
                            <div className="value">
                                {
                                    companyArray.map(x =>
                                        <div>
                                            <input type="radio" id={`company_${x.id}`} name="f111" checked={companyId == x.id ? true : false} onClick={() => setCompanyId(x.id)} />
                                            <label htmlFor={`company_${x.id}`}>{x.name}</label>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="help-word">
                                請選擇隸屬公司
                            </div>
                        </div>

                        <div className="form-item must half has-datalist" data-datalist>
                            <div className="field">客戶別</div>
                            <div className="value">
                                <input id="customerNameText" type="text" value={customerName} onChange={customerNameChange} data-required />
                                <ul className="datalist scrollbar">
                                    {customerNameArray.map((x) => (
                                        <li
                                            data-value={x.custName} onClick={() => customerChange(x.custName, x.custTitle, x.custTaxId, x.custID, x.personADName, x.personADID)}>
                                            <span className="flex-4 bold">{x.custName}</span>
                                            <span className="flex-3">{x.custTitle}</span>
                                            <span className="flex-3">{x.custTaxId}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="help-word">
                                請輸入正確格式
                            </div>
                        </div>

                        <div className="form-item half jcfe">
                            <div className="btn btn-middle btn-border" onClick={() => window.open("/Main?path=CustomerNew", '_blank')}>
                                <span>新增客戶</span>
                            </div>
                        </div>

                        <div className="form-item must has-datalist half" data-datalist>
                            <div className="field">公司抬頭</div>
                            <div className="value">
                                <input id="customerTitleText" type="text" onChange={customerTitleChange} value={customerTitle} data-required />
                                <ul className="datalist scrollbar">
                                    {customerTitleArray.map((x) => (
                                        <li
                                            data-value={x.custTitle} onClick={() => customerChange(x.custName, x.custTitle, x.custTaxId, x.custID, x.personADName, x.personADID)}>
                                            <span className="flex-4 bold">{x.custName}</span>
                                            <span className="flex-3">{x.custTitle}</span>
                                            <span className="flex-3">{x.custTaxId}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <span className="help-word">
                                請輸入正確格式
                            </span>
                        </div>
                        <div className="form-item half  has-datalist" data-datalist>
                            <div className="field">公司統編/ 編號</div>
                            <div className="value">
                                <input id="customerTaxIdText" type="text" onChange={customerTaxIdChange} value={customerTaxId} />
                                <ul className="datalist scrollbar">
                                    {
                                        customerTaxIdArray.map(x =>
                                            <li data-value={x.custTaxId} onClick={() => customerChange(x.custName, x.custTitle, x.custTaxId, x.custID, x.personADName, x.personADID)}>
                                                <span className="flex-4 bold">{x.custName}</span>
                                                <span className="flex-3">{x.custTitle}</span>
                                                <span className="flex-3">{x.custTaxId}</span>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                            <div className="help-word">
                                請輸入正確格式
                            </div>
                        </div>
                        <div className={`form-item  half has-datalist ${isPM ? "must" : ""}`} data-datalist>
                            <div className="field">負責業務</div>
                            <div className="value multi-inline">
                                <input
                                    id="personInChargeNameText"
                                    type="text"
                                    value={personInChargeName}
                                    onChange={(e) => personInChargeNameChange(e)}
                                    disabled={!isPM || adNameState}
                                    data-required />
                                <ul className="datalist scrollbar">
                                    {
                                        personInChargeNameArray.map(x =>
                                            <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => { setPersonInChargeName(`${x.enName}(${x.tc_name})`), setPersonInChargeId(x.id) }}>
                                                <span className="flex-4">{x.enName}({x.tc_name})</span>
                                                <span className="flex-5">{x.mail}</span>
                                            </li>
                                        )
                                    }
                                </ul>
                                {isPM ?
                                    <a className="btn btn-border btn-middle" onClick={(e) => setAdNameState(!adNameState)}>
                                        <span>修改</span>
                                    </a>
                                    : <></>
                                }
                            </div>
                            <span className="help-word">
                                請輸入正確格式
                            </span>
                        </div>
                        <hr />
                        <div className="form-item must half">
                            <div className="field">雲端細項總稱</div>
                            <div className="value">
                                <select value={cloudProductName1} onChange={cloudProductName1Change} data-required>
                                    <option value="" selected disabled>請選擇</option>
                                    {
                                        cloudProductName1Array.map((x, index) =>
                                            <option key={index} value={x.name}>{x.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="help-word">
                                請輸入正確格式
                            </div>
                        </div>
                        <div className="form-item half">

                        </div>
                        <div className="form-item must half">
                            <div className="field">折扣</div>
                            <div className="value">
                                <DebounceInput
                                    debounceTimeout={100}
                                    type="text"
                                    className="right max-120"
                                    value={wholeDiscount}
                                    onClick={(e) => e.target.select()}
                                    onChange={(e) => {
                                        let tmp = e.target.value.replace(/[^0-9.]/g, '');
                                        if (tmp == "") {
                                            tmp = 0;
                                        }
                                        setWholeDiscount("");
                                        setWholeDiscount(parseFloat(tmp) + "%");
                                    }}
                                    placeholder="%"
                                    data-required />
                            </div>
                            <div className="help-word">
                                請輸入正確格式
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <hr />
                        <div className="form-item col must">
                            <div className="field">新增雲端帳號

                                <div className="fn">
                                    <input type="file" id="file" className="real" style={{ "display": "none" }} onChange={selectedFileChange} />
                                    <a className={`btn btn-middle btn-border `} onClick={getFile}  >
                                        <span>批次上傳帳號</span>
                                    </a>

                                    <div className="btn btn-middle btn-text" onClick={GETTemplate}>
                                        <span>下載格式</span>
                                    </div>
                                </div>
                            </div>
                            <div className="value">

                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1 max-60 center">序號</div>
                                        <div className="flex-3">帳號 ID</div>
                                        <div className="flex-3">帳號名稱</div>
                                        <div className="flex-3">帳號代號</div>
                                        <div className="flex-3">產品細項</div>
                                        <div className="flex-2 fn jcfe max-100"></div>

                                    </li>
                                    {
                                        accountData.map((x, i) =>
                                            <>
                                                <li className={`lt-row ${x.isOpen == true ? 'highlight' : ''}`}>
                                                    <div className="flex-1 max-60 center">{i + 1 >= 10 ? i + 1 : '0' + (i + 1)}</div>
                                                    <div className="flex-3">
                                                        <input type="text" value={x.acntId} onChange={e => accountDataChange(e, i, "acntId")} data-required />
                                                    </div>
                                                    <div className="flex-3">
                                                        <input type="text" value={x.acntName} onChange={e => accountDataChange(e, i, "acntName")} data-required />
                                                    </div>
                                                    <div className="flex-3">
                                                        <input type="text" value={x.acntCode} onChange={e => accountDataChange(e, i, "acntCode")} data-required />
                                                    </div>
                                                    <div className="flex-3">
                                                        <select name="" id="" onChange={e => accountDataChange(e, i, "productId")} data-required>
                                                            <option value="" selected disabled>請選擇</option>
                                                            {
                                                                cloudProductName2Array.map(c =>
                                                                    <option value={c.id} selected={x.productId == c.id ? true : false}>{c.name}</option>
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="flex-2 fn jcfe max-100">
                                                        <div className="fn-bar">
                                                            {accountData.length > 1 ?
                                                                <div className="btn btn-icon" onClick={() => handleDelAccount(i)}>
                                                                    <i className="icon icon-37"></i>
                                                                </div>
                                                                : <></>
                                                            }
                                                            <div className={`btn btn-icon ${x.isOpen == true ? 'rotate' : ''}`} onClick={(e) => accountDataChange(e, i, "isOpen")}>
                                                                <i className="icon icon-28"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className={`lt-row ${x.isOpen == true ? '' : 'hide'}`} id={`st-${i}`}>
                                                    <ul className="list-table nested">
                                                        <li className="lt-row lt-th">
                                                            <div className="flex-1 max-60 center"></div>
                                                            <div className="flex-3">額度幣別</div>
                                                            <div className="flex-3 right">帳號額度</div>
                                                            <div className="flex-1 right">服務費</div>
                                                            <div className="flex-1 center">測試</div>
                                                            <div className="flex-3">
                                                                {x.state == 1 ? '正式啟用日期' : '測試到期日'}
                                                            </div>
                                                            <div className="flex-1 center">技術支援</div>
                                                            <div className="flex-2 fn jcfe"></div>
                                                        </li>
                                                        <li className="lt-row">
                                                            <div className="flex-1 max-60 center"></div>
                                                            <div className="flex-3"> {/*額度幣別*/}
                                                                <select name="" id="" onChange={e => accountDataChange(e, i, "quotaCurrencyId")} data-required>
                                                                    <option value="" selected disabled>請選擇</option>
                                                                    {
                                                                        currencyArray.map(c =>
                                                                            <option value={c.currID} selected={x.quotaCurrencyId != "" && x.quotaCurrencyId == c.currID}>{c.currName}</option>
                                                                        )
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className="flex-3 right"> {/*帳號額度*/}
                                                                {/*<input type="number" value={x.quota} onChange={e => accountDataChange(e, i, "quota")} data-required />*/}

                                                                <input
                                                                    type="text"
                                                                    defaultValue={NewNumFormart(x.quota)}
                                                                    onChange={(e) => accountDataChange(e, i, "quota")}
                                                                    onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                                                    onBlur={(e) => e.target.value = NewNumFormart(x.quota)}
                                                                    data-required />
                                                            </div>
                                                            <div className="flex-1 right"> {/*服務費*/}
                                                                <input
                                                                    type="text"
                                                                    defaultValue={x.serviceCharge + "%"}
                                                                    onClick={(e) => e.target.select()}
                                                                    onChange={e => accountDataChange(e, i, "serviceCharge")}
                                                                    onBlur={(e) => e.target.value = x.serviceCharge + "%"}
                                                                    placeholder="%"
                                                                    data-required />
                                                            </div>
                                                            <div className="flex-1 center"> {/*測試*/}
                                                                <div>
                                                                    <input type="radio" id={`state_${i}`} checked={x.state == 1 ? false : true} onClick={e => accountDataChange(e, i, "state")} data-required />
                                                                    <label htmlFor={`state_${i}`}></label>
                                                                </div>
                                                            </div>
                                                            <div className="flex-3"> {/*正式啟用日期/測試到期日*/}
                                                                <input type="date" value={x.stateDate} onChange={e => accountDataChange(e, i, "stateDate")} data-required />
                                                            </div>
                                                            <div className="flex-1 center"> {/*技術支援*/}
                                                                <div>
                                                                    <input type="radio" id={`help_${i}`} checked={x.help == 0 ? false : true} onClick={e => accountDataChange(e, i, "help")} data-required />
                                                                    <label htmlFor={`help_${i}`}></label>
                                                                </div>
                                                            </div>
                                                            <div className="flex-2 fn jcfe">
                                                                <a className="btn btn-small btn-text" onClick={() => OpenModalRemark(i, 0)}>
                                                                    <span>備註</span>
                                                                </a>
                                                                {
                                                                    isPM == 1 ?
                                                                        <a className="btn btn-small btn-text" onClick={() => OpenModalRemark(i, 1)}>
                                                                            <span>PM 備註</span>
                                                                        </a>
                                                                        : null
                                                                }

                                                            </div>
                                                        </li>
                                                        <li className="lt-row no-border">
                                                            <div className="flex-1 max-60 center"></div>
                                                            <div className="flex-4 fc-black fw-bold">特殊產品計價</div>
                                                            <div className="flex-2 center fc-black fw-bold">{isPM == 1 ? '使用代金券' : ''}</div>
                                                            <div className="flex-3"></div>
                                                            <div className="flex-2"></div>
                                                            <div className="flex-3 fn jcfe">

                                                            </div>
                                                        </li>
                                                        {
                                                            x.specialPricing == null ? null :
                                                                x.specialPricing.map((x2, i2) =>
                                                                    <li className="lt-row no-border">
                                                                        <div className="flex-1 max-60 center">
                                                                            {
                                                                                i2 > 0 ?
                                                                                    <a className="btn btn-icon" onClick={() => DelSpecialPricing(i, i2)}>
                                                                                        <i className="icon icon-37"></i>
                                                                                    </a>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                        <div className="flex-4">
                                                                            <input type="text" value={x2.title} onChange={(e) => accountDataChange2(e, i, i2, "title")} />
                                                                        </div>
                                                                        <div className="flex-2 center">
                                                                            {isPM == 1 ?
                                                                                <div>
                                                                                    <input type="radio" id={`isCoupon_${i}_${i2}`} onClick={(e) => accountDataChange2(e, i, i2, "isCoupon")} checked={x2.isCoupon == 1 ? true : false} />
                                                                                    <label htmlFor={`isCoupon_${i}_${i2}`}></label>
                                                                                </div>
                                                                                : null}

                                                                        </div>
                                                                        <div className="flex-3">
                                                                            {
                                                                                isPM == 1 && x.productId != null ?
                                                                                    <a className="btn btn-text btn-small" onClick={() => OpenModalFormula(i, i2, x.productId)}>
                                                                                        <span>編輯計費項目</span>
                                                                                    </a>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                        <div className="flex-2"></div>
                                                                        <div className="flex-3 fn jcfe">
                                                                            {
                                                                                //最後一比的話要顯示新增特殊產品計價
                                                                                x.specialPricing.length == i2 + 1 ?
                                                                                    <a className="btn btn-small btn-border" onClick={() => AddSpecialPricing(i)}>
                                                                                        <span>新增特殊產品計價</span>
                                                                                    </a>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </li>
                                                                )
                                                        }
                                                    </ul>
                                                </li>
                                            </>
                                        )
                                    }


                                </ul>
                            </div>

                        </div>

                    </div>
                    <div className="form-container">
                        <div className="form-item btn-area sp">
                            <div className="left">
                                <a className="btn btn-border" onClick={AddUserID}>
                                    <span>新增帳號 ID</span>
                                </a>
                            </div>
                            <div className="right">
                            </div>
                        </div>
                    </div>
                    <div className="form-container">
                        <div className="form-item btn-area sp">
                            <div className="left">
                            </div>
                            <div className="right">
                                <a className="btn" onClick={AddCloudAcnt}>
                                    <span>新增</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-formula" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">雲端帳號計費公式</h3>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <ul className="list-table transparent formula-group">
                            <li className="lt-row lt-th">
                                <div className="flex-1 fn max-60 no-form"></div>
                                <div className="flex-4">
                                    計費項目
                                </div>
                                <div className="flex-1">值為數字</div>
                                <div className="flex-8">
                                    <div className="flex-3"></div>
                                    <div className="flex-2"></div>
                                    <div className="flex-3"></div>
                                </div>
                                <div className="flex-1"></div>
                                <div className="flex-1"></div>

                            </li>
                            {
                                formulaData.map((x, i) =>
                                    <li className="lt-row for-formula">
                                        <div className="flex-1 fn max-60 no-form">
                                            {
                                                i > 0 ?
                                                    <a className="btn btn-icon" onClick={() => DelFormulaData(i)}>
                                                        <i className="icon icon-37"></i>
                                                    </a>
                                                    : null
                                            }
                                        </div>
                                        <div className="flex-4">
                                            <div className="form-item in-table no-field">
                                                <div className="value">
                                                    <select onChange={(e) => formulaDataChange(e, i, "rawData")}>
                                                        <option value="" disabled>Raw Data</option>
                                                        {
                                                            rawDataArray.map(x2 =>
                                                                <option value={x2.id} selected={x.rawData == x2.id ? true : false}>{x2.name}</option>
                                                            )
                                                        }

                                                    </select>
                                                </div>
                                                <div className="help-word right">
                                                    請選擇計費項目
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 center">
                                            <div className="form-item in-table no-field center">
                                                <div className="value">
                                                    <input type="radio" id={`isNumber_${i}`} onClick={(e) => formulaDataChange(e, i, "isNumber")} checked={x.isNumber == 0 ? false : true} />
                                                    <label htmlFor={`isNumber_${i}`}></label>
                                                </div>
                                                <div className="help-word right">

                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-8 has-nested">
                                            {
                                                x.isNumber == 1 ?
                                                    <>
                                                        <div className="flex-3">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <input
                                                                        type="number"
                                                                        placeholder="請輸入前範圍"
                                                                        value={x.lowerLimit}
                                                                        onChange={(e) => formulaDataChange(e, i, "lowerLimit")}
                                                                        disabled={
                                                                            x.formulaSigns == "Y=" || x.formulaSigns == "Y≦" || x.formulaSigns == "Y<" ?
                                                                                true : false
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-2">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <select onChange={(e) => formulaDataChange(e, i, "formulaSigns")}>
                                                                        <option value="<Y<">{"<"} Y {"<"}</option>
                                                                        <option value="≦Y≦">{"≦"} Y {"≦"}</option>
                                                                        <option value="<Y≦">{"<"} Y {"≦"}</option>
                                                                        <option value="≦Y<">{"≦"} Y {"<"}</option>
                                                                        <option value="Y="> Y {"="}</option>
                                                                        <option value="Y≦"> Y {"≦"}</option>
                                                                        <option value="≦Y">{"≦"} Y </option>
                                                                        <option value="Y<"> Y {"<"}</option>
                                                                        <option value="<Y">{"<"} Y </option>
                                                                    </select>
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-3">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <input
                                                                        type="number"
                                                                        placeholder="請輸入後範圍"
                                                                        value={x.upperLimit}
                                                                        onChange={(e) => formulaDataChange(e, i, "upperLimit")}
                                                                        disabled={
                                                                            x.formulaSigns == "≦Y" || x.formulaSigns == "<Y" ?
                                                                                true : false
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入算式條件
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> :
                                                    <>
                                                        <div className="flex-2">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <select onChange={(e) => formulaDataChange(e, i, "formulaSigns")}>
                                                                        <option value="=">=</option>
                                                                        <option value="≠">≠</option>
                                                                    </select>
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-6">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <input type="text" placeholder="請輸入參考值" value={x.value} onChange={(e) => formulaDataChange(e, i, "value")} />
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入算式條件
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                        <div className="flex-1 no-form center">
                                            {
                                                formulaData.length > 1 && i != formulaData.length - 1 ? 'and' :
                                                    <a className="btn btn-icon" onClick={AddFormulaData}>
                                                        <i className="icon icon-25"></i>
                                                    </a>
                                            }
                                        </div>
                                        <div className="flex-1"></div>
                                    </li>
                                )
                            }
                            <li className="lt-row border">
                            </li>
                            <li className="lt-row lt-th">
                                <div className="flex-1 fn max-60 no-form">

                                </div>
                                <div className="flex-4">
                                    折扣計費欄位
                                </div>
                                <div className="flex-3">
                                    折扣/ 單價
                                </div>
                                <div className="flex-2"></div>
                                <div className="flex-5"></div>


                            </li>
                            <li className="lt-row for-formula">
                                <div className="flex-1 fn max-60 no-form">

                                </div>
                                <div className="flex-4">
                                    <div className="form-item in-table no-field">
                                        <div className="value">
                                            <select onChange={e => specialPricingDataChange(e, "rawData")}>
                                                <option value="" disabled>Raw Data</option>
                                                {
                                                    rawDataArray.map(x2 =>
                                                        <option value={x2.id} selected={specialPricingData.rawData == x2.id ? true : false}>{x2.name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className="help-word right">
                                            請選擇計費項目
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-3 right no-form">
                                    <div className="multi-form">
                                        <input type="number" value={specialPricingData.discountPrice} onChange={e => specialPricingDataChange(e, "discountPrice")} className="" />
                                        <div className="line"></div>
                                        <select onChange={e => specialPricingDataChange(e, "discountUnit")}  >
                                            <option value="0" selected={specialPricingData.discountUnit == "0" ? true : false}>%</option>
                                            <option value="1" selected={specialPricingData.discountUnit == "1" ? true : false}>元</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="flex-2"></div>
                                <div className="flex-5 no-form jcfe">
                                    <a className="btn btn-middle btn-border" onClick={ClearFormulaData}>
                                        <span>清空欄位</span>
                                    </a>
                                    <a className="btn btn-middle" style={{ "color": "#FFFFFF" }} onClick={CheckFormulaData}>
                                        <span>確定算式</span>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div id="modal-remark" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">
                            新增{" "}{accountData[tmpIndex1] && accountData[tmpIndex1].acntId ? accountData[tmpIndex1].acntId : ""}{" "}{remarkType == 0 ? '' : 'PM'}備註
                        </h3>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-container">
                            <div className="form-item no-field">
                                <div className="field">隸屬公司</div>
                                <div className="value">
                                    <div className="msg-record">
                                        {
                                            subAccountComments.map((remark, index) => {

                                                let isShowDate = true;

                                                //判斷要不要顯示日期
                                                if (index > 0 && moment(remark.createdTime).format('YYYY/MM/DD') == moment(subAccountComments[index - 1].createdTime).format('YYYY/MM/DD')) {
                                                    isShowDate = false;
                                                }
                                                return (
                                                    <ul key={index}>
                                                        {isShowDate ?
                                                            <li className="date">{moment(remark.createdTime).format('YYYY/MM/DD')}</li> : null
                                                        }
                                                        <li>[{moment(remark.createdTime).format('HH:mm:ss')}{" "}{remark.userName}] {remark.content}</li>
                                                    </ul>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="help-word">
                                    請選擇負責公司
                                </div>
                            </div>
                            <div className="form-item remark-block">
                                <div>
                                    <textarea name="" id="" cols="30" rows="5" value={tmpRemark} onChange={(e) => setTmpRemark(e.target.value)}></textarea>
                                    <div className="btn-area">
                                        <a className={`btn btn-middle btn-border ${tmpRemark == null || tmpRemark == "" ? "disabled" : ""}`} onClick={editRemark} disabled={tmpRemark == null || tmpRemark == "" ? "disabled" : ""} >
                                            <span>確定送出</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-success" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">新增成功</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={reflash}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {/*新增失敗 <br />*/}
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error2" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            上傳失敗 <br />
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error2")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CloudAcntNew;
