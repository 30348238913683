import React, { useState, useEffect } from "react";
import {
    API_HOST,
    API_GET_PrepassVendorData,
    API_GET_PrepassVendorPreData,
    API_GET_Balance,
} from "../../global/constants";
import { NumFormart, OpenModal, CloseModal, InitJS, SetOnbeforeunload, SetHasChange } from "../../global/ToolUtil";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const Prepaid_SupplierEdit = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    var onbeforeunloadFlag = false;
    useEffect(() => {
        //把menu顏色置換
        document.getElementById("menuitem-Prepaid_CustomerQry").classList.add("active");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    const [typeName, setTypeName] = useState();
    const [type, setType] = useState();
    const [dataid, setDataid] = useState();
    const [currID, setCurrID] = useState();
    const [currName, setCurrName] = useState("");
    const [currEName, setCurrEName] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [venId, setVenId] = useState();
    const [companyName, setCompanyName] = useState();
    const [vendorName, setVendorName] = useState();
    const [vendorTaxId, setVendorTaxId] = useState();
    const [vendorTitle, setVendorTitle] = useState();
    const [balance, setBalance] = useState();
    const [historyExplanation, setHistoryExplanation] = useState();
    const [beforeBalance, setBeforeBalance] = useState();

    const [exRateRate, setExRateRate] = useState();
    //set會有延遲 故還是開一個變數nowExRateRate
    var nowExRateRate = 0;
    const [exRateYM, setExRateYM] = useState();
    const [currencyArray, setCurrencyArray] = useState([{}]);
    const [exchangeRateList, setExchangeRateList] = useState([{}]);
    const [modalStr, setModalStr] = useState();
    const [addBalance, setaddBalance] = useState(0);
    function addBalanceChange(e) {
        //先更新新增金額
        var reducenum = 0;
        var val = reduceBalance.toString().replace(/-/g, "");
        if (val.indexOf(currEName + " ") >= 0) {
            val = val.replace(currEName, "");
        }
        val = NumFormart(val == '' ? '0' : val) + "";
        reducenum = parseFloat(val.replace(/,/g, ""));


        var num = 0;
        var val = e.target.value;
        val = NumFormart(val == '' ? '0' : val) + "";
        setaddBalance(val);
        num = parseFloat(val.replace(/,/g, ""));
        

        var tmpbalance = 0;
        var tmpbalanceVal = NumFormart(balance) + "";
        tmpbalance = parseFloat(tmpbalanceVal.replace(/,/g, ""));


        //var num = 0;
        //var numstr = e.target.value.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, "");
       
        //if (numstr == "") {
        //    setaddBalance(0);
        //} else {
        //    numstr = numstr.replace(",", "");
        //    num = parseInt(numstr.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, ""));
        //    setaddBalance(NumFormart(num));
        //}

        ////重算結餘金額
        //var reducenum = 0;
        //var val = e.target.value;
        //if (val.indexOf(currEName + " ") >= 0) {
        //    val = val.replace(currEName, "");
        //}
        //var reducenumstr = reduceBalance.toString();
        //if (reducenumstr.indexOf(currEName + " ") >= 0) {
        //    reducenumstr = reducenumstr.replace(currEName, "");
        //}
        //reducenumstr = reducenumstr.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, "");
        //if (reducenumstr != "") {
        //    reducenumstr = reducenumstr.replace(",", "");
        //    reducenum = parseInt(
        //        reducenumstr.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, "")
        //    );
        //}

        //var tmpbalance = 0;
        //var tmpbalancestr = balance.toString();
        //if (tmpbalancestr.indexOf(currEName + " ") >= 0) {
        //    tmpbalancestr = tmpbalancestr.replace(currEName, "");
        //}
        //if (tmpbalancestr != "") {
        //    tmpbalancestr = tmpbalancestr.replace(",", "");
        //    tmpbalance = parseInt(
        //        tmpbalancestr.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, "")
        //    );
        //}


        var dt = {
            balance: tmpbalance,
            addBalance: num,
            exRate: exRateRate,
            reduceBalance: reducenum,
        };
        //console.log(dt);
        API_GET_Balance(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code != "0000" || data.result.length == 0) {
                    history.push("/");
                } else {
                    //更新結餘金額
                    setBeforeBalance(NumFormart(data.result.data));
                }
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [customerCurrencyId, setCustomerCurrencyId] = useState();
    function customerCurrencyIdChange(e) {
        setCustomerCurrencyId(e.target.value);

        for (var i = 0; i < exchangeRateList.length; i++) {
            if (exchangeRateList[i].exCurrID == e.target.value) {
                setExRateRate(exchangeRateList[i].exRateRate);
                nowExRateRate = exchangeRateList[i].exRateRate;
                setExRateYM(exchangeRateList[i].exRateYM);
                break;
            }
        }
        var num = 0;
        var reducestr = reduceBalance.toString();
        if (reducestr.indexOf(currEName + " ") >= 0) {
            reducestr = reducestr.replace(currEName, "");
        }        
        num = parseFloat(reducestr.replace(/,/g, ""));

        var addnum = 0;
        var addbalanceVal = NumFormart(addBalance) + "";
        addnum = parseFloat(addbalanceVal.replace(/,/g, ""));

        var tmpbalance = 0;
        var tmpbalanceVal = NumFormart(balance) + "";
        tmpbalance = parseFloat(tmpbalanceVal.replace(/,/g, ""));

        //var reducestr = reduceBalance.toString();
        //var reducenum = 0;
        ////從算結存金額
        //if (reducestr.indexOf(currEName + " ") >= 0) {
        //    reducestr = reducestr.replace(currEName, "");
        //}
        //reducestr = reducestr.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, "");
        //if (reducestr != "") {
        //    reducestr = reducestr.replace(",", "");
        //    reducenum = parseFloat(
        //        reducestr.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, "")
        //    );
        //}
        //var addnum = 0;
        //var addstr = addBalance.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, "");
        //if (addstr != "") {
        //    addstr = addstr.replace(",", "");
        //    addnum = parseFloat(addstr.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, ""));
        //}

        //if (parseInt(document.getElementById("addCurrID").value) != 5) {
        //    addnum = Math.floor(parseFloat(addnum) * parseFloat(nowExRateRate), -1);
        //}
        //var tmpbalance = 0;
        //var tmpbalancestr = balance.toString();
        //if (tmpbalancestr.indexOf(currEName + " ") >= 0) {
        //    tmpbalancestr = tmpbalancestr.replace(currEName, "");
        //}
        //if (tmpbalancestr != "") {
        //    tmpbalancestr = tmpbalancestr.replace(",", "");
        //    tmpbalance = parseInt(
        //        tmpbalancestr.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, "")
        //    );
        //}
        var dt = {
            balance: tmpbalance,
            addBalance: addnum,
            exRate: parseFloat(nowExRateRate),
            reduceBalance: num,
        };
        
        API_GET_Balance(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code != "0000" || data.result.length == 0) {
                    history.push("/");
                } else {
                    setBeforeBalance(NumFormart(data.result.data));
                }
            })
            .catch((err) => {
                 
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [reduceBalance, setReduceBanlance] = useState(0);
    function reduceBalanceChange(e) {
        //先更新減少金額
        var num = 0;
        var val = e.target.value.replace(/-/g, "");
        if (val.indexOf(currEName + " ") >= 0) {
            val = val.replace(currEName, "");
        }
        val = NumFormart(val == '' ? '0' : val) + "";

        setReduceBanlance(currEName + " " + val);
        num = parseFloat(val.replace(/,/g, ""));
        var addnum = 0;
        var addbalanceVal = NumFormart(addBalance) + "";
        addnum = parseFloat(addbalanceVal.replace(/,/g, ""));

        var tmpbalance = 0;
        var tmpbalanceVal = NumFormart(balance) + "";
        tmpbalance = parseFloat(tmpbalanceVal.replace(/,/g, ""));

        //var num = 0;
        //var val = e.target.value;
        //if (val.indexOf(currEName + " ") >= 0) {
        //    val = val.replace(currEName, "");
        //}  
        //var numstr = val.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, "");
        //if (numstr == "") {
        //    setReduceBanlance(0);
        //} else {
        //    numstr = numstr.replace(",", "");
        //    num = parseInt(numstr.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, ""));
        //    var s = currEName + " " + NumFormart(num);
        //    setReduceBanlance(s);
        //}
        ////要算結存餘額 故處理增加金額
        //var addnum = 0;
        //var addstr = addBalance
        //    .toString()
        //    .replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, "");
        //if (addstr != "") {
        //    addstr = addstr.replace(",", "");
        //    addnum = parseInt(addstr.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, ""));
        //}

        //var tmpbalance = 0;
        //var tmpbalancestr = balance.toString();
        //if (tmpbalancestr.indexOf(currEName + " ") >= 0) {
        //    tmpbalancestr = tmpbalancestr.replace(currEName, "");
        //}
        //if (tmpbalancestr != "") {
        //    tmpbalancestr = tmpbalancestr.replace(",", "");
        //    tmpbalance = parseInt(
        //        tmpbalancestr.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, "")
        //    );
        //}
        var dt = {
            balance: tmpbalance,
            addBalance: addnum,
            exRate: exRateRate,
            reduceBalance: num,
        };
        //console.log(dt);
        API_GET_Balance(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code != "0000" || data.result.length == 0) {
                    history.push("/");
                } else {
                    //更新結餘金額
                    setBeforeBalance(NumFormart(data.result.data));
                }
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    function historyExplanationChange(e) {
        setHistoryExplanation(e.target.value);
    }

    function getData() {
        setVenId(queryParams.get("venId"));

        if (queryParams.get("type") == "1") {
            setType("1");
            setTypeName("儲值金");
            //path = API_GET_PrepassVendorPreData + '?vendorId=' + queryParams.get('venId');
            API_GET_PrepassVendorPreData({
                vendorId: queryParams.get("venId"),
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setCurrencyArray(data.result.data.currencyArray);
                    for (var i = 0; i < data.result.data.currencyArray.length; i++) {
                        if (data.result.data.currencyArray[i].currID == data.result.data.companyCurrId) {
                            setCurrID(data.result.data.currencyArray[i].currID);
                            setCurrName(data.result.data.currencyArray[i].currName);
                            setCurrEName(data.result.data.currencyArray[i].currEName);
                            break;
                        }
                    }
                    setCompanyName(data.result.data.companyName);
                    setVendorName(data.result.data.vendorName);
                    setVendorTaxId(data.result.data.vendorTaxId);
                    setVendorTitle(data.result.data.vendorTitle);
                    setDataid(data.result.data.vendorId);
                    setBalance(NumFormart(data.result.data.balance == null ? 0 : data.result.data.balance));
                    setBeforeBalance(NumFormart(data.result.data.balance == null ? 0 : data.result.data.balance));
                    //if (queryParams.get("type") == "1") {
                    setCustomerCurrencyId(data.result.data.companyCurrId);
                    //} else {
                    //    setCustomerCurrencyId(data.result.data.customerCurrencyId);
                    //}

                    setExchangeRateList(data.result.data.exchangeRateList);
                    for (var i = 0; i < data.result.data.exchangeRateList.length; i++) {
                        if (queryParams.get("type") == "1") {
                            if (data.result.data.exchangeRateList[i].exCurrID == data.result.data.companyCurrId
                            ) {
                                setExRateRate(data.result.data.exchangeRateList[i].exRateRate);
                                setExRateYM(data.result.data.exchangeRateList[i].exRateYM);
                                break;
                            }
                        } else {
                            if (data.result.data.exchangeRateList[i].exCurrID == data.result.data.companyCurrId) {
                                setExRateRate(data.result.data.exchangeRateList[i].exRateRate);
                                nowExRateRate = data.result.data.exchangeRateList[i].exRateRate;
                                setExRateYM(data.result.data.exchangeRateList[i].exRateYM);
                                break;
                            }
                        }
                    }
                })
                .catch((err) => {
                    
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else if (queryParams.get("type") == "2") {
            setType("2");
            setTypeName("保證金");
            API_GET_PrepassVendorData({
                vendorId: queryParams.get("venId"),
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setCurrencyArray(data.result.data.currencyArray);
                    for (var i = 0; i < data.result.data.currencyArray.length; i++) {
                        if (data.result.data.currencyArray[i].currID == data.result.data.companyCurrId) {
                            setCurrID(data.result.data.currencyArray[i].currID);
                            setCurrName(data.result.data.currencyArray[i].currName);
                            setCurrEName(data.result.data.currencyArray[i].currEName);
                            break;
                        }
                    }
                    setCompanyName(data.result.data.companyName);
                    setVendorName(data.result.data.vendorName);
                    setVendorTaxId(data.result.data.vendorTaxId);
                    setVendorTitle(data.result.data.vendorTitle);
                    setDataid(data.result.data.vendorId);
                    setBalance(NumFormart(data.result.data.balance == null ? 0 : data.result.data.balance));
                    setBeforeBalance(NumFormart(data.result.data.balance == null ? 0 : data.result.data.balance));
                    /*if (queryParams.get("type") == "1") {*/
                    setCustomerCurrencyId(data.result.data.companyCurrId);
                    //} else { 
                    //    setCustomerCurrencyId(data.result.data.companyCurrId);
                    //}

                    setExchangeRateList(data.result.data.exchangeRateList);
                    for (var i = 0; i < data.result.data.exchangeRateList.length; i++) {
                        if (queryParams.get("type") == "1") {
                            if (data.result.data.exchangeRateList[i].exCurrID == data.result.data.companyCurrId) {
                                setExRateRate(data.result.data.exchangeRateList[i].exRateRate);
                                //console.log(data.result.data.exchangeRateList[i].exRateRate);
                                setExRateYM(data.result.data.exchangeRateList[i].exRateYM);
                                break;
                            }
                        } else {
                            if (data.result.data.exchangeRateList[i].exCurrID == data.result.data.companyCurrId) {
                                setExRateRate(data.result.data.exchangeRateList[i].exRateRate);
                                nowExRateRate = data.result.data.exchangeRateList[i].exRateRate;
                                setExRateYM(data.result.data.exchangeRateList[i].exRateYM);
                                break;
                            }
                        }
                    }
                })
                .catch((err) => {
                     
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }


    function submit(e) {
        //if (selectedFile != null && selectedFile.size > 0) {
        //    document.getElementById("file").classList.remove("error");
        //} else {
        //    document.getElementById("file").classList.add("error");
        //    return false;
        //}

        const formData = new FormData();

        var reducestr = reduceBalance.toString();
        var reducenum = 0;
        //從算結存金額
        if (reducestr.indexOf(currEName + " ") >= 0) {
            reducestr = reducestr.replace(currEName, "");
        }
        reducestr = reducestr.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, "");
        if (reducestr != "") {
            reducestr = reducestr.replace(",", "");
            reducenum = parseFloat(
                reducestr.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, "")
            );
        }
        var addnum = 0;
        var addstr = "";
        if (addBalance > 999) {
            addstr = addBalance.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, "");
        }
        else {
            addstr = addBalance;
        }
        if (addstr != "") {
            addstr = addstr.replace(",", "");
            addnum = parseFloat(addstr.replace(/^-?\\d+$|^(-?\\d+)(\\.\\d+)?$/, ""));
        }
        var tmpArray = {
            vendorId: parseInt(venId),
            addBalance: addnum,
            currencyId: parseInt(customerCurrencyId),
            reduceBalance: reducenum,
            explanation: historyExplanation,
        };

        formData.append("view", JSON.stringify(tmpArray));
        formData.append("files", selectedFile);

        const requestOptions = {
            method: "PUT",
            headers: {
                //"Content-Type": "multipart/form-data",
                Authorization: localStorage.getItem("token"),
            },
            body: formData,
        };
        //console.log(tmpArray);

        var path = "";
        if (queryParams.get("type") == "1") {
            //path = API_GET_PrepassVendorPreData;
            path = API_HOST + "/prepass/vendorpredata";
        } else if (queryParams.get("type") == "2") {
            //path = API_GET_PrepassPreData;
            path = API_HOST + "/prepass/vendordata";
        }
        //console.log(path);
        fetch(path, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    openModalSuccess();
                    window.onbeforeunload = null;
                    onbeforeunloadFlag = false;
                    SetHasChange(false);
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            });
    }
    function openModalSuccess() {
        getData();
        //setSelectedFile('');
        setReduceBanlance(0);
        setaddBalance(0);
        setHistoryExplanation("");
        OpenModal("modal-success");
    }

    function goQry() {
        var path = "/Main?path=Prepaid_SupplierQry";
        history.push(path);
    }
    function reflash() {
        window.location.reload();
    }
    function getFile() {
        document.getElementById("file").click();
    }
    return (
        <>
            <div className="content-body">
                <div className="title-area">
                    <div className="container">
                        <h3 className="fz-B has-border">{companyName}</h3>
                        <span>幣別：{currName}</span>
                    </div>
                </div>
                <div className="container">
                    <form action="" className="form-container" id="formData">
                        <div className="form-item half">
                            <div className="field">客戶別</div>
                            <div className="value">
                                <input type="text" value={vendorName} disabled="disabled" />
                            </div>
                            <div className="help-word">請輸入正確格式</div>
                        </div>
                        <div className="form-item half"></div>
                        <div className="form-item half">
                            <div className="field">公司抬頭</div>
                            <div className="value">
                                <input type="text" value={vendorTitle} disabled="disabled" />
                            </div>
                            <div className="help-word">請輸入正確格式</div>
                        </div>

                        <div className="form-item half">
                            <div className="field">公司統編/ 編號</div>
                            <div className="value">
                                <input type="text" value={vendorTaxId} disabled="disabled" />
                            </div>
                            <div className="help-word">請輸入正確格式</div>
                        </div>

                        <hr />

                        <div className="form-item half">
                            <div className="field">{typeName}餘額</div>
                            <div className="value multi-inline">
                                <input type="text" value={`${currEName} ${balance}`} disabled="disabled" />
                            </div>
                            <div className="help-word">請輸入正確格式</div>
                        </div>

                        <div class="form-item half">
                            <div class="field">新增{typeName}</div>
                            <div class="value multi-inline">
                                <input type="text" value={addBalance} onChange={addBalanceChange} />
                                <select name="" id="addCurrID" value={customerCurrencyId} onChange={customerCurrencyIdChange}  >
                                    {currencyArray.map((x) => (
                                        <option
                                            value={x.currID}
                                            selected={customerCurrencyId == x.currID ? "selected" : ""}
                                        >
                                            {x.currName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div class="help-word">請輸入正確格式</div>
                            <div class="extra-info">
                                {customerCurrencyId == currID
                                    ? null
                                    : `${exRateRate}(${exRateYM}匯率)`}
                                <input type="hidden" id="exRateRate" value={exRateRate} />
                            </div>
                        </div>

                        <div class="form-item half">
                            <div class="field">減少金額</div>
                            <div class="value">
                                <input type="text" value={reduceBalance} onChange={reduceBalanceChange} />
                            </div>
                            <div class="help-word">請輸入正確格式</div>
                        </div>

                        <div class="form-item half"></div>

                        <div class="form-item half">
                            <div class="field">結存金額</div>
                            <div class="value">
                                <input
                                    type="text"
                                    value={`${currEName} ${beforeBalance}`}
                                    disabled="disabled"
                                />
                            </div>
                            <div class="help-word">請輸入正確格式</div>
                        </div>

                        <div class="form-item half">
                            <div class="field">憑證檔案</div>
                            <div class="value multi-inline">
                                <div class="fake-upload" data-fakeUpload>
                                    <input type="file" id="file" className="real" style={{ "display": "none" }} onChange={(e) => setSelectedFile(e.target.files[0])} />
                                    <input type="text" className="fake" readonly="readonly" placeholder="請選擇檔案" />
                                </div>
                                <a className="btn btn-middle btn-border" onClick={getFile}>
                                    <span>上傳檔案</span>
                                </a>
                            </div>
                            <div className="help-word">請輸入正確格式</div>
                        </div>

                        <div className="form-item">
                            <div className="field">說明</div>
                            <div className="value">
                                <textarea name="" id="" cols="30" rows="10"
                                    value={historyExplanation}
                                    onChange={historyExplanationChange}
                                ></textarea>
                            </div>
                            <span className="help-word">請輸入正確格式</span>
                        </div>
                        <div className="form-item btn-area sp">
                            <div className="left">
                                <a className="btn" onClick={goQry}>
                                    <span>返回查詢</span>
                                </a>
                            </div>
                            <div className="right">
                                <a className="btn" onClick={submit}>
                                    <span>確定</span>
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div id="modal-success" className="modal small alert" data-modal >
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">維護成功</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={reflash}>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal   >
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" data-close>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Prepaid_SupplierEdit;
