import React, { useState, useEffect } from "react";

import {
    API_GET_ADList,
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, InitJS } from '../../global/ToolUtil';
const ADList = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        //把menu顏色置換
        document.getElementById("menuitem-InterViewQry").classList.add("active");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);


    //資料列
    const [dataList, setDataList] = useState([{}]);

    function getData() {
        //公司
        API_GET_ADList()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                //if (!CheckResponseCode(data)) { return; } //檢查response code
                setDataList(data.result.data);
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });


    }

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">AD清單</h3>
                </div>
            </div>

            <div className="container">
                <div className="form-container">
                    <table style={{ border: "1px solid #333" }}>
                        <thead style={{ backgroundColor: "#333 ", color: "#fff"} }>
                        <tr> 
                            <td><div className="flex-2">adid</div></td>
                            <td><div className="flex-2">adCode</div></td>
                            <td><div className="flex-2">adScimid</div></td>
                            <td><div className="flex-2">adOktaID</div></td>
                                <td><div className="flex-2">adStatus</div></td>
                                <td><div className="flex-2">adPrevStatus</div></td>
                            <td><div className="flex-2">adTitle</div></td>
                            <td><div className="flex-2">adName</div></td>
                            <td><div className="flex-2">adeName</div></td>
                            <td><div className="flex-2">adCompanyName</div></td>
                            <td><div className="flex-2">adCompanyCode</div></td>
                            <td><div className="flex-2">adDeptName</div></td>
                            <td><div className="flex-2">adDeptCode</div></td>
                            <td><div className="flex-2">adEmail</div></td>
                            <td><div className="flex-2">adManagerCName</div></td>
                            <td><div className="flex-2">adManagerEName</div></td>
                            <td><div className="flex-2">adManagerEMail</div></td>
                            <td><div className="flex-2">adInvalid</div></td>
                            <td><div className="flex-2">createdTime</div></td>
                        </tr>
                        </thead>
                    {dataList.map((x) => (
                        <tr >
                            <td  style={{ border: "1px solid #333" }}><div className="flex-2">{x.adid}</div></td>
                            <td  style={{ border: "1px solid #333" }}><div className="flex-2">{x.adCode}</div></td>
                            <td  style={{ border: "1px solid #333" }}><div className="flex-2">{x.adScimid}</div></td>
                            <td  style={{ border: "1px solid #333" }}><div className="flex-2">{x.adOktaID}</div></td>
                            <td style={{ border: "1px solid #333" }}><div className="flex-2">{x.adStatus}</div></td>
                            <td style={{ border: "1px solid #333" }}><div className="flex-2">{x.adPrevStatus}</div></td>
                            <td  style={{ border: "1px solid #333" }}><div className="flex-2">{x.adTitle}</div></td>
                            <td  style={{ border: "1px solid #333" }}><div className="flex-2">{x.adName}</div></td>
                            <td  style={{ border: "1px solid #333" }}><div className="flex-2">{x.adeName}</div></td>
                            <td  style={{ border: "1px solid #333" }}><div className="flex-2">{x.adCompanyName}</div></td>
                            <td  style={{ border: "1px solid #333" }}><div className="flex-2">{x.adCompanyCode}</div></td>
                            <td  style={{ border: "1px solid #333" }}><div className="flex-2">{x.adDeptName}</div></td>
                            <td  style={{ border: "1px solid #333" }}><div className="flex-2">{x.adDeptCode}</div></td>
                            <td  style={{ border: "1px solid #333" }}><div className="flex-2">{x.adEmail}</div></td>
                            <td  style={{ border: "1px solid #333" }}><div className="flex-2">{x.adManagerCName}</div></td>
                            <td  style={{ border: "1px solid #333" }}><div className="flex-2">{x.adManagerEName}</div></td>
                            <td  style={{ border: "1px solid #333" }}><div className="flex-2">{x.adManagerEMail}</div></td>
                            <td  style={{ border: "1px solid #333" }}><div className="flex-2">{x.adInvalid}</div></td>
                            <td style={{ border: "1px solid #333" }}><div className="flex-2">{x.createdTime}</div></td>
                        </tr>
                    ))}
                    </table>

            </div>
        </div>
        </div >
    );
};

export default ADList;
