import { useState, useEffect } from "react";
import { API_DEL_ProductCostData } from "../../global/constants";
import { NumFormart, CloseModal, OpenModal } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
const List = ({ isDisabled, exchangeRate, dataList, currencyArray, companyCurrencyId, productTypeArray, changeData, setFlag }) => {

    const [tmpDeleteProductID, setTmpDeleteProductID] = useState();
    const [msgStr, setMsgStr] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    //刪除
    function deleteData() {
        setFlag(true);
        //console.log(tmpDeleteProductID);
        //呼叫2-4刪除
        API_DEL_ProductCostData({
            oldProductId: tmpDeleteProductID,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                //console.log(data);
                //CloseModal("modal-2");
                setShowDeleteModal(false)
                if (data.code == "0000") {
                    //前端移除(後端會回傳該資料列,直接蓋過去)
                    var newDataList = dataList.filter(x => x.productId != tmpDeleteProductID)
                    changeData(newDataList);
                } else {
                    setMsgStr(data.message);
                    setShowErrorModal(true);
                    //OpenModal("modal-error-2");
                }
            })
    }
    //說明 
    function explanationChange(e, index, col) {
        setFlag(true);
        changeData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == 1) {
                        item.explanation1 = e.target.value;
                    } else if (col == 2) {
                        item.explanation2 = e.target.value;
                    }

                }
                return item;
            });
        });
    }

    //幣別
    function currencyChange(e, col) {
        let rate = exchangeRate.find(obj => obj.exCurrID == e.target.value);
        console.log("選擇的匯率", rate);
        if (rate != null) {
            setFlag(true);
            var index = e.target.dataset.index;
            changeData(function (prev) {
                return prev.map((item, i) => {
                    if (i == index) {
                        if (col == "a") {
                            item.advanceReduceBalanceCurrId = e.target.value;
                        }
                        else if (col == "r") {
                            item.requisitionCostCurrId = e.target.value;
                        }
                        else if (col == "e") {
                            item.estimatedCostCurrId = e.target.value;
                        }
                        else if (col == "add") {
                            item.addBalanceCurrId = e.target.value;
                        }

                        //預付轉成本匯率
                        let advRate = exchangeRate.find(obj => { return obj.exCurrID == item.advanceReduceBalanceCurrId }).exRateRate;
                        //請款成本匯率
                        let reqRate = exchangeRate.find(obj => { return obj.exCurrID == item.requisitionCostCurrId }).exRateRate;
                        //預估成本匯率
                        let estRate = exchangeRate.find(obj => { return obj.exCurrID == item.estimatedCostCurrId }).exRateRate;
                        //新增預付金 (供)
                        let addRate = exchangeRate.find(obj => { return obj.exCurrID == item.addBalanceCurrId }).exRateRate;

                        //重新計算本期成本
                        item.confirmedCost = (
                            parseFloat((item.advanceReduceBalance * advRate).toFixed(2)) +
                            parseFloat((item.requisitionCost * reqRate).toFixed(2)) +
                            parseFloat((item.estimatedCost * estRate).toFixed(2))
                        ).toFixed(0);

                        //重新本期預付成本餘額
                        item.newAdvanceBalance = (
                            item.advanceBalance - parseFloat((item.advanceReduceBalance * advRate).toFixed(2)) + parseFloat((item.addBalance * addRate).toFixed(2))
                        ).toFixed(0);
                    }
                    return item;
                });
            });
        }
        else {  //查不到幣別匯率

            //跳出通知 「尚未設定xx幣兌yy幣的匯率」
            var origCurrency = currencyArray.find(x => x.currID == companyCurrencyId)
            var targetCurrency = currencyArray.find(x => x.currID == e.target.value)
            setMsgStr(`尚未設定${targetCurrency.currName}兌${origCurrency.currName}的匯率`);
            setShowErrorModal(true);

            let productIdSplit = e.currentTarget.id.split("_");
            var origdata = dataList.find(x => x.productId == productIdSplit[productIdSplit.length - 1]);

            //復原原本選的內容
            switch (col) {
                case "a":
                    document.getElementById(e.currentTarget.id).value = origdata.advanceReduceBalanceCurrId;
                case "r":
                    document.getElementById(e.currentTarget.id).value = origdata.requisitionCostCurrId;
                case "e":
                    document.getElementById(e.currentTarget.id).value = origdata.estimatedCostCurrId;
                case "add":
                    document.getElementById(e.currentTarget.id).value = origdata.addBalanceCurrId;
            }

        }
    }

    //預付轉成本
    function advanceReduceBalanceChange(e) {
        setFlag(true);
        var index = e.target.dataset.index;
        changeData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    let tmp = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
                    item.advanceReduceBalance = !isNaN(tmp) ? tmp : "";

                    if (item.advanceReduceBalance == "") {
                        item.advanceReduceBalance = 0;
                    }

                    //預付轉成本匯率
                    let advRate = exchangeRate.find(obj => { return obj.exCurrID == item.advanceReduceBalanceCurrId }).exRateRate;
                    //請款成本匯率
                    let reqRate = exchangeRate.find(obj => { return obj.exCurrID == item.requisitionCostCurrId }).exRateRate;
                    //預估成本匯率
                    let estRate = exchangeRate.find(obj => { return obj.exCurrID == item.estimatedCostCurrId }).exRateRate;
                    //新增預付金 (供)
                    let addRate = exchangeRate.find(obj => { return obj.exCurrID == item.addBalanceCurrId }).exRateRate;

                    //重新計算本期成本
                    item.confirmedCost = (
                        parseFloat((item.advanceReduceBalance * advRate).toFixed(2)) +
                        parseFloat((item.requisitionCost * reqRate).toFixed(2)) +
                        parseFloat((item.estimatedCost * estRate).toFixed(2))
                    ).toFixed(0);
                    //item.confirmedCost =
                    //    (
                    //        (item.advanceReduceBalance * (exchangeRate.find(obj => { return obj.exCurrID == item.advanceReduceBalanceCurrId }).exRateRate)) +
                    //        (item.requisitionCost * ()) +
                    //        (item.estimatedCost * (exchangeRate.find(obj => { return obj.exCurrID == item.estimatedCostCurrId }).exRateRate))
                    //    ).toFixed(0)

                    //重新本期預付成本餘額
                    item.newAdvanceBalance = (
                        item.advanceBalance - parseFloat((item.advanceReduceBalance * advRate).toFixed(2)) + parseFloat((item.addBalance * addRate).toFixed(2))
                    ).toFixed(0);
                    //item.newAdvanceBalance = ((item.advanceReduceBalance * (exchangeRate.find(obj => { return obj.exCurrID == item.advanceReduceBalanceCurrId }).exRateRate)) - parseInt(item.advanceBalance)).toFixed(0)
                }
                return item;
            });
        });
    }
    //請款成本
    function requisitionCostChange(e) {
        setFlag(true);
        var index = e.target.dataset.index;
        changeData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    let tmp = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
                    item.requisitionCost = !isNaN(tmp) ? tmp : "";

                    if (item.requisitionCost == "") {
                        item.requisitionCost = 0;
                    }

                    //預付轉成本匯率
                    let advRate = exchangeRate.find(obj => { return obj.exCurrID == item.advanceReduceBalanceCurrId }).exRateRate;
                    //請款成本匯率
                    let reqRate = exchangeRate.find(obj => { return obj.exCurrID == item.requisitionCostCurrId }).exRateRate;
                    //預估成本匯率
                    let estRate = exchangeRate.find(obj => { return obj.exCurrID == item.estimatedCostCurrId }).exRateRate;
                    //新增預付金 (供)
                    let addRate = exchangeRate.find(obj => { return obj.exCurrID == item.addBalanceCurrId }).exRateRate;

                    //重新計算本期成本
                    item.confirmedCost = (
                        parseFloat((item.advanceReduceBalance * advRate).toFixed(2)) +
                        parseFloat((item.requisitionCost * reqRate).toFixed(2)) +
                        parseFloat((item.estimatedCost * estRate).toFixed(2))
                    ).toFixed(0);

                    //重新本期預付成本餘額
                    item.newAdvanceBalance = (
                        item.advanceBalance - parseFloat((item.advanceReduceBalance * advRate).toFixed(2)) + parseFloat((item.addBalance * addRate).toFixed(2))
                    ).toFixed(0);
                }
                return item;
            });
        });
    }
    //預估成本
    function estimatedCostChange(e) {
        setFlag(true);
        var index = e.target.dataset.index;
        changeData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    let tmp = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
                    item.estimatedCost = !isNaN(tmp) ? tmp : "";

                    if (item.estimatedCost == "") {
                        item.estimatedCost = 0;
                    }

                    //預付轉成本匯率
                    let advRate = exchangeRate.find(obj => { return obj.exCurrID == item.advanceReduceBalanceCurrId }).exRateRate;
                    //請款成本匯率
                    let reqRate = exchangeRate.find(obj => { return obj.exCurrID == item.requisitionCostCurrId }).exRateRate;
                    //預估成本匯率
                    let estRate = exchangeRate.find(obj => { return obj.exCurrID == item.estimatedCostCurrId }).exRateRate;
                    //新增預付金 (供)
                    let addRate = exchangeRate.find(obj => { return obj.exCurrID == item.addBalanceCurrId }).exRateRate;

                    //重新計算本期成本
                    item.confirmedCost = (
                        parseFloat((item.advanceReduceBalance * advRate).toFixed(2)) +
                        parseFloat((item.requisitionCost * reqRate).toFixed(2)) +
                        parseFloat((item.estimatedCost * estRate).toFixed(2))
                    ).toFixed(0);

                    //重新本期預付成本餘額
                    item.newAdvanceBalance = (
                        item.advanceBalance - parseFloat((item.advanceReduceBalance * advRate).toFixed(2)) + parseFloat((item.addBalance * addRate).toFixed(2))
                    ).toFixed(0);
                }
                return item;
            });
        });
    }
    //新增預付金 (供)
    function addBalanceChange(e) {
        setFlag(true);
        var index = e.target.dataset.index;
        changeData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    let tmp = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
                    item.addBalance = !isNaN(tmp) ? tmp : "";

                    if (item.addBalance == "") {
                        item.addBalance = 0;
                    }

                    //預付轉成本匯率
                    let advRate = exchangeRate.find(obj => { return obj.exCurrID == item.advanceReduceBalanceCurrId }).exRateRate;
                    //新增預付金 (供)
                    let addRate = exchangeRate.find(obj => { return obj.exCurrID == item.addBalanceCurrId }).exRateRate;

                    //重新本期預付成本餘額
                    item.newAdvanceBalance = (
                        item.advanceBalance - parseFloat((item.advanceReduceBalance * advRate).toFixed(2)) + parseFloat((item.addBalance * addRate).toFixed(2))
                    ).toFixed(0);
                }
                return item;
            });
        });
    }
    //新增預付金 (備註)
    function addBalanceExplanationChange(e) {
        setFlag(true);
        var index = e.target.dataset.index;
        changeData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.addBalanceExplanation = e.target.value;
                }
                return item;
            });
        });
    }
    //打開Open
    function dataOpen(index) {
        changeData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.isOpen = (item.isOpen == null || item.isOpen == false) ? true : false;
                }
                return item;
            });
        });
    }

    //#region 關閉刪除訊息視窗
    function CloseDeleteModal() {
        setShowDeleteModal(false);
    }
    //#endregion

    //#region 關閉錯誤訊息視窗
    function CloseErrorModal() {
        setShowErrorModal(false);
    }
    //#endregion

    return (
        <>
            <ul className="list-table">
                <li className="lt-row lt-th">
                    <div className="flex-2">供應商</div>
                    <div className="flex-2">產品別</div>
                    <div className="flex-4">產品細項</div>
                    <div className="flex-4">說明 1</div>
                    <div className="flex-4">說明 2</div>
                    <div className="flex-2">上期成本</div>
                    <div className="flex-2 fn"></div>
                </li>
                {dataList.map((x, i) => (
                    <>
                        <li className={`lt-row ${x.isOpen == true ? 'highlight' : ''}`}>
                            <div className="flex-2">{x.supplierName}</div>
                            <div className="flex-2">
                                {x.productTypeName}
                            </div>
                            <div className="flex-4">{x.productName2}</div>
                            <div className="flex-4">
                                <input
                                    type="text"
                                    value={x.explanation1}
                                    onChange={(e) => explanationChange(e, i, 1)}
                                    placeholder="非必填"
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className="flex-4">
                                <input
                                    type="text"
                                    value={x.explanation2}
                                    onChange={(e) => explanationChange(e, i, 2)}
                                    placeholder="非必填"
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className="flex-2">{NumFormart(x.lastTimeCost)}</div>
                            <div className="flex-2 fn jcfe">
                                <div className="fn-bar">
                                    <div className={`btn btn-icon ${x.isOpen == true ? 'rotate' : ''}`} onClick={() => dataOpen(i)}>
                                        <i className="icon icon-28"></i>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className={`lt-row ${x.isOpen == true ? '' : 'hide'}`} id={`st-${i}`}>
                            <ul className="list-table nested">
                                <li className="lt-row lt-th">
                                    <div className="flex-1"> </div>
                                    <div className="flex-2">幣別</div>
                                    <div className="flex-2">項目</div>
                                    <div className="flex-8">金額</div>
                                    <div className="flex-1"> </div>
                                </li>
                                <li className="lt-row aifs">
                                    <div className="flex-1"></div>
                                    <div className="flex-2">
                                        <ul class="col-table">
                                            <li>
                                                <div className="form-item independent no-field">
                                                    <div className="field">請選擇幣別</div>
                                                    <div className="value">
                                                        <select
                                                            name={`advanceReduceBalance_${x.productId}`}
                                                            id={`advanceReduceBalance_${x.productId}`}
                                                            className={`radius ${isDisabled == true ? "disabled" : ""}`}
                                                            data-index={i}
                                                            onChange={e => currencyChange(e, "a")}
                                                            disabled={isDisabled}
                                                            data-required
                                                        >
                                                            {currencyArray.map((x2) => (
                                                                <option value={x2.currID} selected={x.advanceReduceBalanceCurrId == x2.currID ? true : false}>
                                                                    {x2.currName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-item independent no-field">
                                                    <div className="field">請選擇幣別</div>
                                                    <div className="value">
                                                        <select name={`requisitionCost_${x.productId}`}
                                                            id={`requisitionCost_${x.productId}`}
                                                            className="radius"
                                                            data-index={i}
                                                            onChange={e => currencyChange(e, "r")}
                                                            disabled={isDisabled}
                                                            data-required
                                                        >
                                                            {currencyArray.map((x2) => (
                                                                <option value={x2.currID} selected={x.requisitionCostCurrId == x2.currID ? true : false}>
                                                                    {x2.currName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-item independent no-field">
                                                    <div className="field">請選擇幣別</div>
                                                    <div className="value">
                                                        <select name={`estimatedCost_${x.productId}`}
                                                            id={`estimatedCost_${x.productId}`}
                                                            className="radius"
                                                            data-index={i}
                                                            onChange={e => currencyChange(e, "e")}
                                                            disabled={isDisabled}
                                                            data-required
                                                        >
                                                            {currencyArray.map((x2) => (
                                                                <option value={x2.currID} selected={x.estimatedCostCurrId == x2.currID ? true : false}>
                                                                    {x2.currName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </li>
                                            <li></li>
                                            <li class="space"></li>
                                            <li>
                                                <div className="form-item independent no-field">
                                                    <div className="field">請選擇幣別</div>
                                                    <div className="value">
                                                        <select name={`addBalance_${x.productId}`}
                                                            id={`addBalance_${x.productId}`}
                                                            className="radius"
                                                            data-index={i}
                                                            onChange={e => currencyChange(e, "add")}
                                                            disabled={isDisabled}
                                                            data-required
                                                        >
                                                            {currencyArray.map((x2) => (
                                                                <option value={x2.currID} selected={x.addBalanceCurrId == x2.currID ? true : false}>
                                                                    {x2.currName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="flex-2">
                                        <ul className="col-table">
                                            <li>預付轉成本</li>
                                            <li>請款成本</li>
                                            <li>預估成本</li>
                                            <li>預付成本餘額</li>
                                            <li className="space"></li>
                                            <li>新增預付金 (供)</li>
                                        </ul>
                                    </div>
                                    <div className="flex-8">
                                        <ul className="col-table flex-3">
                                            <li>{/*預付轉成本*/}
                                                <input
                                                    type="text"
                                                    className="right"
                                                    defaultValue={NumFormart(x.advanceReduceBalance, 2)}
                                                    data-index={i}
                                                    onClick={(e) => e.target.select()}
                                                    onChange={advanceReduceBalanceChange}
                                                    onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                                    onBlur={(e) => e.target.value = NumFormart(x.advanceReduceBalance, 2)}
                                                    disabled={isDisabled}
                                                    data-required
                                                />
                                            </li>
                                            <li>{/*請款成本*/}
                                                <input
                                                    type="text"
                                                    className="right"
                                                    defaultValue={NumFormart(x.requisitionCost, 2)}
                                                    data-index={i}
                                                    onClick={(e) => e.target.select()}
                                                    onChange={requisitionCostChange}
                                                    onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                                    onBlur={(e) => e.target.value = NumFormart(x.requisitionCost, 2)}
                                                    disabled={isDisabled}
                                                    data-required
                                                />
                                            </li>
                                            <li>{/*預估成本*/}
                                                <input
                                                    type="text"
                                                    className="right"
                                                    defaultValue={NumFormart(x.estimatedCost, 2)}
                                                    data-index={i}
                                                    onClick={(e) => e.target.select()}
                                                    onChange={estimatedCostChange}
                                                    onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                                    onBlur={(e) => e.target.value = NumFormart(x.estimatedCost, 2)}
                                                    disabled={isDisabled}
                                                    data-required
                                                />
                                            </li>
                                            <li>{/*預付成本餘額*/}
                                                <input
                                                    type="text"
                                                    className="right"
                                                    value={x.advanceBalance}
                                                    disabled="disabled"
                                                />
                                            </li>
                                            <li className="space"></li>
                                            <li>{/*新增預付金 (供)*/}
                                                <input
                                                    type="text"
                                                    className="right"
                                                    defaultValue={NumFormart(x.addBalance, 2)}
                                                    data-index={i}
                                                    onClick={(e) => e.target.select()}
                                                    onChange={addBalanceChange}
                                                    onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                                    onBlur={(e) => e.target.value = NumFormart(x.addBalance, 2)}
                                                    disabled={isDisabled}
                                                    data-required
                                                />
                                            </li>
                                        </ul>
                                        <ul className="col-table flex-5">
                                            <li>  </li>
                                            <li>  </li>
                                            <li>
                                                <div className="flex">
                                                    <div className="field right">本期成本</div>
                                                    <div className="value">
                                                        <input type="text" disabled="disabled" value={NumFormart(x.confirmedCost)} />
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="flex">
                                                    <div className="field right">本期預付成本餘額</div>
                                                    <div className="value">
                                                        <input
                                                            type="text"
                                                            value={NumFormart(x.newAdvanceBalance)}
                                                            disabled="disabled"
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="space"></li>
                                            <li>
                                                <input
                                                    type="text"
                                                    value={x.addBalanceExplanation}
                                                    data-index={i}
                                                    onChange={addBalanceExplanationChange}
                                                    className={
                                                        x.addBalance > 0 &&
                                                            (x.addBalanceExplanation == null ||
                                                                x.addBalanceExplanation == "")
                                                            ? "error"
                                                            : ""
                                                    }
                                                    placeholder={
                                                        x.addBalance > 0 &&
                                                            (x.addBalanceExplanation == null ||
                                                                x.addBalanceExplanation == "")
                                                            ? "不可空白"
                                                            : ""
                                                    }
                                                    disabled={isDisabled}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="flex-1"></div>
                                </li>
                            </ul>
                        </li>
                    </>
                ))}
            </ul>
            <div id="modal-2"
                className={`modal small alert ${showDeleteModal ? "active layer-1" : ""}`}>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">該產品確定不再列入成本？</div>
                        <div className="btn-holder sp">
                            <a className="btn btn-border" onClick={(e) => CloseDeleteModal()}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={deleteData}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="modal-error-2"
                className={`modal small alert ${showErrorModal ? "active layer-1" : ""}`}>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{msgStr}</div>
                        <div className="btn-holder sp">
                            <a className="btn btn-border" onClick={(e) => CloseErrorModal()}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={(e) => CloseErrorModal()}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default List;
