import React, { useState, useEffect } from "react";
import {
    API_GET_ExchangeRateHome,
    API_POST_ExchangeRateNewCurrency,
    API_POST_ExchangeRateData
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, InitJS, SetOnbeforeunload, MenuSet, SetHasChange } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
import moment from 'moment';

const ExchangeRateNew = () => {
    var history = useHistory();
    const [reGet, setReGet] = useState(false);

    useEffect(() => {
        //把menu顏色置換
        MenuSet("ExchangeRateQry");
        //第一次近來先執行抓取資料
        getData();
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    useEffect(() => {
        if (reGet) {
            //內頁資料有刪除/修改 重抓
            getData();
        }
        setReGet(false);
    }, [reGet]);

    function getData() {
        API_GET_ExchangeRateHome()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setCompanyArray(data.result.data.companyArray);
                setCurrencyArray(data.result.data.currencyArray);
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        //年度下拉
        //年度下拉
        var yArr = [];
        var now = new Date(moment().format('YYYY-MM-DD'));
        var firstYearMonth = new Date(moment().format('YYYY-MM-DD'));
        firstYearMonth.setMonth(firstYearMonth.getMonth() - 24);
        while (firstYearMonth <= now) {
            var nowYear = firstYearMonth.getFullYear();
            var nowMonth = firstYearMonth.getMonth() + 1;

            if (nowMonth < 10) {
                yArr.push(nowYear + "/" + "0" + (nowMonth));
            }
            else {
                yArr.push(nowYear + "/" + (nowMonth));
            }

            firstYearMonth.setMonth(firstYearMonth.getMonth() + 1);
        }
        setYearArray(yArr);
    }
    //公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyId, setCompanyId] = useState();
    const [companyIdError, setCompanyIdError] = useState(false);
    //設定公司 鎖定該公司幣別
    function changeCompanyId(currId) {
        setCurrencyId(currId);
        setExchangeRateAmount("");
        setExchangeRateCurr("");
        setExchangeRateYearMonth("");
        for (var i = 0; i < currencyArray.length; i++) {
            if (currencyArray[i].id == currId) {
                setCurrencyName(currencyArray[i].name);
            }
        }
    }
    //幣別
    const [currencyArray, setCurrencyArray] = useState([{}]);
    const [currencyId, setCurrencyId] = useState(-1);
    const [currencyName, setCurrencyName] = useState();

    //年月下拉選單
    const [yearArray, setYearArray] = useState([]);

    //新增幣別
    const [addName, setAddName] = useState();
    const [addEName, setAddEName] = useState();
    const [addNameError, setAddNameError] = useState(false);
    const [addENameError, setAddENameError] = useState(false);

    //回傳訊息
    const [modalStr, setModalStr] = useState();
    //再次確認新增幣別
    function OpenModalCheckCurrency() {
        let hasError = false;
        if (addName == '' || addName == null) {
            setAddNameError(true);
            hasError = true;
        }
        if (addEName == '' || addName == null) {
            setAddENameError(true);
            hasError = true;
        }

        if (hasError)
            return;

        setAddNameError(false);
        setAddENameError(false);
        OpenModal("modal-checkcurrency", 2);
    }

    function AddCurrency() {
        CloseModal("modal-checkcurrency", 2);
        CloseModal("modal-add");

        API_POST_ExchangeRateNewCurrency({
            name: addName,
            iso: addEName,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setModalStr(addName + "已新增完成");
                    OpenModal("modal-ok");
                    setAddName('');
                    setAddEName('');
                    setReGet(true);
                    SetHasChange(false);
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }

            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
    }

    const [exchangeRateCurr, setExchangeRateCurr] = useState("");
    const [exchangeRateCurrError, setExchangeRateCurrError] = useState(false);
    const [exchangeRateName, setExchangeRateName] = useState();
    const [exchangeRateNameError, setExchangeRateNameError] = useState(false);
    const [exchangeRateAmount, setExchangeRateAmount] = useState();
    const [exchangeRateAmountError, setExchangeRateAmountError] = useState(false);
    const [exchangeRateAmountZeroError, setExchangeRateAmountZeroError] = useState(false);
    const [exchangeRateYearMonth, setExchangeRateYearMonth] = useState();
    const [exchangeRateYearMonthError, setExchangeRateYearMonthError] = useState(false);
    function OpenModalCheck() {
        var result = true;
        if (companyId == null) {
            setCompanyIdError(true);
            result = false;
        } else {
            setCompanyIdError(false);
        }
        if (exchangeRateCurr == '' || exchangeRateCurr == null) {
            setExchangeRateCurrError(true);
            result = false;
        } else {
            setExchangeRateCurrError(false);
        }
        if (exchangeRateName == '') {
            setExchangeRateNameError(true);
            result = false;
        } else {
            setExchangeRateNameError(false);
        }

        if (exchangeRateAmount === undefined || exchangeRateAmount === '' || exchangeRateAmount === null) {
            setExchangeRateAmountError(true);
            result = false;
        }
        else if (parseFloat(exchangeRateAmount) == 0) {
            setExchangeRateAmountError(false);
            setExchangeRateAmountZeroError(true);
            result = false;
        }
        else {
            setExchangeRateAmountError(false);
            setExchangeRateAmountZeroError(false);
        }

        if (exchangeRateYearMonth == null || exchangeRateYearMonth == '') {
            setExchangeRateYearMonthError(true);
            result = false;
        } else {
            setExchangeRateYearMonthError(false);
        }

        if (result) {
            OpenModal("modal-check");
        }

    }
    function exchangeRateAmountChange(e) {
        let tmp = e.target.value.replace(/[^0-9.]*/g, '');
        if (!isNaN(tmp)) {
            setExchangeRateAmount(tmp);
        }
    }

    function addNameChange(e) {
        setAddName(e.target.value);
    }
    function addENameChange(e) {
        setAddEName(e.target.value);
    }
    //新增匯率
    function Add() {
        var dt = {
            mainCurrency: currencyId,
            toCurrency: exchangeRateCurr,
            time: exchangeRateYearMonth,
            amount: exchangeRateAmount
        };
        //console.log(JSON.stringify(dt));
        API_POST_ExchangeRateData(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    CloseModal("modal-check");
                    setModalStr("匯率設定成功");
                    setExchangeRateAmount("");
                    setExchangeRateCurr("");
                    setExchangeRateYearMonth("");
                    SetHasChange(false);
                    OpenModal("modal-ok");
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">新增匯率</h3>

                </div>
            </div>
            <div className="container">
                <div className="form-container">
                    <div className={`form-item must ${companyIdError ? 'show-help' : ''}`}>
                        <div className="field">設定公司</div>
                        <div className="value">
                            {
                                companyArray.map(x =>
                                    <div className="item">
                                        <input type="radio" id={`company_${x.id}`} checked={companyId == x.id ? true : false} onChange={() => { setCompanyId(x.id), changeCompanyId(x.currency) }} />
                                        <label htmlFor={`company_${x.id}`}>{x.name}</label>
                                    </div>
                                )
                            }
                        </div>
                        <div className="help-word">
                            請選擇設定公司
                        </div>
                    </div>

                    <div className={`form-item half ${exchangeRateCurrError ? 'show-help' : ''}`}>
                        <div className="field">幣別</div>
                        <div className="value">
                            <select name="" id="ExchangeRateCurr" value={exchangeRateCurr} onChange={(e) => setExchangeRateCurr(e.target.value)}>
                                <option value="" selected disabled>請選擇</option>
                                {
                                    currencyArray.map(x =>
                                        x.id != currencyId ? <option value={x.id}>{x.name}</option> : <></>
                                    )
                                }
                            </select>

                        </div>
                        <div className="help-word">
                            請選擇幣別
                        </div>
                    </div>

                    <div className={`form-item half ${exchangeRateYearMonthError ? 'show-help' : ''}`}>
                        <div className="field">年/ 月</div>
                        <div className="value">
                            <select name="" id="ExchangeRateYearMonth" value={exchangeRateYearMonth} onChange={(e) => setExchangeRateYearMonth(e.target.value)}>
                                <option value="" selected disabled>請選擇</option>
                                {
                                    yearArray.map(x =>
                                        <option value={x}>{x}</option>
                                    )
                                }
                            </select>

                        </div>
                        <div className="help-word">
                            請選擇年月
                        </div>
                    </div>

                    <div className={`form-item half ${exchangeRateAmountError || exchangeRateAmountZeroError ? 'show-help' : ''}`}>
                        <div className="field">匯率{exchangeRateAmountError}</div>
                        <div className="value">
                            <input
                                type="text"
                                value={exchangeRateAmount}
                                onChange={exchangeRateAmountChange} />
                        </div>
                        <div className="help-word">
                            {
                                exchangeRateAmountError ? <>請輸入匯率</> : exchangeRateAmountZeroError ? <>匯率不可為零</> : <></>
                            }
                        </div>
                    </div>

                    <div className="form-item half">
                        <div className="field">兌換幣別</div>
                        <div className="value">
                            <input type="text" value={currencyName} disabled="disabled" />
                        </div>
                        <div className="help-word">
                            請輸入正確格式
                        </div>
                    </div>



                    <div className="form-item btn-area sp">
                        <div className="left">
                            <a className="btn btn-border" onClick={() => OpenModal("modal-add")}>
                                <span>新增幣別</span>
                            </a>
                        </div>
                        <div className="right">

                            <a className="btn" onClick={OpenModalCheck}>
                                <span>新增</span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
            <div id="modal-add" className="modal" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">新增幣別</h3>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-container">
                            <div className="form-item">
                                <div className="field">幣別名稱</div>
                                <div className="value">
                                    <input type="text" value={addName} onChange={addNameChange} className={addNameError ? 'error' : ''} placeholder="例：新台幣" />
                                </div>
                                <div className="help-word">
                                    請輸入幣別名稱
                                </div>
                            </div>
                            <div className="form-item">
                                <div className="field">幣別英文縮寫</div>
                                <div className="value">
                                    <input type="text" value={addEName} onChange={addENameChange} className={addENameError ? 'error' : ''} placeholder="例：TWD" />
                                </div>
                                <div className="help-word">
                                    請輸入幣別名稱
                                </div>
                            </div>
                            <div className="form-item btn-area">
                                <a className="btn btn-border" onClick={() => CloseModal("modal-add")}> <span>取消</span></a>
                                <a className="btn" onClick={OpenModalCheckCurrency} > <span>確認</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-checkcurrency" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要新增 「{addName}」幣別? <br />
                            新增後將不可刪除!!
                        </div>
                        <div className="btn-holder sp">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-checkcurrency", 2)}> <span>取消</span></a>
                            <a className="btn" onClick={AddCurrency}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-check" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {exchangeRateYearMonth}
                            <br />
                            確定 1
                            {
                                currencyArray.map(x =>
                                    x.id == exchangeRateCurr ? x.name : null
                                )
                            }
                            兌換
                            {exchangeRateAmount}
                            {currencyName}
                            ?
                        </div>
                        <div className="btn-holder sp">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-check")}> <span>取消</span></a>
                            <a className="btn" onClick={Add}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-ok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-ok")}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-47"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">

                            <a className="btn" onClick={() => CloseModal("modal-error")}> <span>確認</span></a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExchangeRateNew;
