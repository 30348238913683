import '../css/style.css';
import { HashRouter, Route, BrowserRouter, Switch, useLocation } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";
import { API_GET_HomeData } from '../global/constants'
import { OpenModal, CloseModal, UrlDecode } from '../global/ToolUtil'
import { CheckResponseCode } from '../global/ResponseUtil';

import MenuItem from '../components/Main/MenuItem'
import UserManage from './UserManage';
import ADList from '../components/AD/ADList';

import SaleOrderQry from '../components/SaleOrder/SaleOrderQry';
import SaleOrderList from '../components/SaleOrder/SaleOrderList';
import SaleOrderNew from '../components/SaleOrder/SaleOrderNew';
import SaleOrderEdit from '../components/SaleOrder/SaleOrderEdit';

import SettlementQry from '../components/Settlement/SettlementQry';
import SettlementLockQry from '../components/Settlement/SettlementLockQry';
import SettlementList from '../components/Settlement/SettlementList';
import SettlementAnalysisList from '../components/Settlement/SettlementAnalysisList';
import SettlementProductList from '../components/Settlement/SettlementProductList';

import Product from '../components/Product/Product';
import ProductNew from '../components/Product/ProductNew';
import ProductEdit from '../components/Product/ProductEdit';

import ProductCost from '../components/ProductCost/ProductCost';
import ProductCostView from '../components/ProductCost/ProductCostView';

import SupplierQry from '../components/Supplier/SupplierQry';
import SupplierNew from '../components/Supplier/SupplierNew';
import SupplierEdit from '../components/Supplier/SupplierEdit';

import Customer from '../components/Customer/Customer';
import CustomerNew from '../components/Customer/CustomerNew';
import CustomerEdit from '../components/Customer/CustomerEdit';

import PurchaseRequestQry from '../components/PurchaseRequest/PurchaseRequestQry';
import PurchaseRequestList from '../components/PurchaseRequest/PurchaseRequestList';
import PurchaseRequestNew from '../components/PurchaseRequest/PurchaseRequestNew';
import PurchaseRequestEdit from '../components/PurchaseRequest/PurchaseRequestEdit';

import Permission from '../components/Permission/Permission';
import PermissionEdit from '../components/Permission/PermissionEdit';

import Prepaid_CustomerQry from '../components/Prepaid/Prepaid_CustomerQry';
import Prepaid_CustomerView from '../components/Prepaid/Prepaid_CustomerView';
import Prepaid_CustomerEdit from '../components/Prepaid/Prepaid_CustomerEdit';
import Prepaid_CustomerList from '../components/Prepaid/Prepaid_CustomerList';
import Prepaid_SupplierQry from '../components/Prepaid/Prepaid_SupplierQry';
import Prepaid_SupplierView from '../components/Prepaid/Prepaid_SupplierView';
import Prepaid_SupplierEdit from '../components/Prepaid/Prepaid_SupplierEdit';
import Prepaid_SupplierList from '../components/Prepaid/Prepaid_SupplierList';

import CreditsQry from '../components/Credits/CreditsQry';
import CreditsView from '../components/Credits/CreditsView';

import InterViewQry from '../components/InterView/InterViewQry';
import InterViewNew from '../components/InterView/InterViewNew';
import InterViewEdit from '../components/InterView/InterViewEdit';
import InterViewList from '../components/InterView/InterViewList';

import CloudAcntQry from '../components/CloudAcnt/CloudAcntQry';
import CloudAcntNew from '../components/CloudAcnt/CloudAcntNew';
import CloudAcntList1 from '../components/CloudAcnt/CloudAcntList1';
import CloudAcntList2 from '../components/CloudAcnt/CloudAcntList2';
import CloudAcntList3 from '../components/CloudAcnt/CloudAcntList3';

import StatementNew from '../components/StatementInvoice/StatementNew';
import StatementItem from '../components/StatementInvoice/StatementItem';
import InvoiceVoid from '../components/StatementInvoice/InvoiceVoid';
import InvoiceVoidItem from '../components/StatementInvoice/InvoiceVoidItem';
//import InvoiceReduction from '../components/StatementInvoice/InvoiceReduction';
//import InvoiceReductionItem from '../components/StatementInvoice/InvoiceReductionItem';
import InvoiceNew from '../components/StatementInvoice/InvoiceNew';
//import InvoiceLock from '../components/StatementInvoice/InvoiceLock';
//import InvoiceLockItem from '../components/StatementInvoice/InvoiceLockItem';
import InvoiceItem from '../components/StatementInvoice/InvoiceItem';

import AccChargeQry from '../components/AccountChargeOff/AccChargeQry';
import AccChargeList from '../components/AccountChargeOff/AccChargeList';

import ExchangeRateQry from '../components/ExchangeRate/ExchangeRateQry';
import ExchangeRateNew from '../components/ExchangeRate/ExchangeRateNew';

import CATRowSetQry from '../components/CloudAccounting/CATRowSetQry';
import CATRowSetAdd from '../components/CloudAccounting/CATRowSetAdd';
import CATCostSet from '../components/CloudAccounting/CATCostSet';
import CATPreview from '../components/CloudAccounting/CATPreview';
import CATPreviewList from '../components/CloudAccounting/CATPreviewList';
import CATQry from '../components/CloudAccounting/CATQry';
import CATQryList1 from '../components/CloudAccounting/CATQryList1';
import CATQryList2 from '../components/CloudAccounting/CATQryList2';
import CATDownLoad from '../components/CloudAccounting/CATDownLoad';
import CATExport from '../components/CloudAccounting/CATExport';
import CATAdjust from '../components/CloudAccounting/CATAdjust';

import UserInfo from '../components/Dashboard/UserInfo';

import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'

function main() {
    const history = useHistory();
    const location = useLocation();
    const [username, setUserName] = useState('');

    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get('path');
    const pageArray = JSON.parse(localStorage.getItem('pagelist'));
    const menuArray = JSON.parse(localStorage.getItem('menulist'));

    useEffect(() => {
        // 在這裡處理URL參數的變化
        // 例如，你可以從location.search中獲取URL的參數
        //const queryParams = new URLSearchParams(location.search);
        //const path = queryParams.get('path');
        //// 做你想要的處理
        //console.log('URL參數變化了：', path);

        var fromOkta_ = (localStorage.getItem("post_logout_redirect_uri") != null ? 1 : 0);
        API_GET_HomeData({ fromOkta: fromOkta_ })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
            })
    }, [location]);

    useEffect(() => {

        //const navigationHandler = (e) => {
        //    if (e.persisted) {
        //        // 通過瀏覽器的"上一頁按鈕"觸發的頁面回到這裡
        //        console.log('Component is mounted or updated via browser back button.');
        //        // 執行重新加載的操作
        //        window.location.reload();
        //    }
        //};

        //// 添加事件監聽器
        //window.addEventListener('pageshow', navigationHandler);


        if (localStorage.getItem('islogin') == null && localStorage.getItem('islogin') != '1') {
            localStorage.clear();
            history.push('/');
        }
        document.body.classList.remove('login');
        document.body.classList.remove('dashboard');
        document.getElementById('root').classList.remove('center');
        setUserName(localStorage.getItem("userName"));

        setInterval(tick, 1000);
        //抓取資料
        getData();

        //// 卸載組件時移除事件監聽器
        //return () => {
        //    window.removeEventListener('pageshow', navigationHandler);
        //};
    }, []);

    function getData() {
        var fromOkta_ = (localStorage.getItem("post_logout_redirect_uri") != null ? 1 : 0);
        API_GET_HomeData({ fromOkta: fromOkta_ }).then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                //setNotifyArray(data.result.notify);
            })
            .catch(err => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }
    //const menuArrayLen = menuArray.filter(item => item.FuncListType === "-1").length;
    //const action = localStorage.getItem('action');

    const [nowTime, setNowTime] = useState('');
    function tick() {
        setNowTime(new Date().toLocaleDateString() + " " + new Date().toDateString().split(" ")[0] + " " + new Date().toTimeString().split(" ")[0]);
    }
    //重新導頁
    function rePath(type = 2) {
        //新開分頁
        CloseModal("modal-leavecheck");
        window.onbeforeunload = null;
        var TPath = getTPath();
        if (TPath == "/") {
            localStorage.clear();
            history.push('/');
        } else {
            if (type == 2) {
                //直接離開
                if (TPath.indexOf(path) >= 0) {  //如果是原本頁面,用重整
                    window.location.reload();
                } else {
                    history.push(TPath);
                }
            } else {
                window.open(TPath, '_blank');
            }
        }
    }
    function getTPath() {
        var tmpPath = localStorage.getItem("temppath");
        var hasHref = false;
        for (var i = 0; i < pageArray.length; i++) {
            if (pageArray[i].PageCode == tmpPath.toLowerCase()) {
                hasHref = true;
                break;
            }
        }
        console.log(hasHref);
        if (hasHref == false) {
            //如果欲導頁的網址不再,直接回傳/DashBoard
            return "/DashBoard";
        } else {
            if (tmpPath == null || tmpPath == "") {
                tmpPath = "/";
            }
            tmpPath = tmpPath.replace("'", "");
            if (tmpPath == "/") {
                return "/";
            } else {
                return '/Main?path=' + tmpPath;
            }
        }

    }
    //確認登出
    function logoutcheck() {
        var haschangetext = "0";
        if (document.getElementById('haschangetext')) {
            haschangetext = document.getElementById('haschangetext').value;
        }
        if (haschangetext != "1") {
            logout();
        } else {
            //改過資料
            OpenModal("modal-logoutcheck", 6);
            localStorage.setItem("temppath", "/");
        }
        //localStorage.clear();
        //history.push('/');
    }

    //登出
    function logout() {

        var pth = resultPth();
        if (pth.indexOf(path) >= 0) {
            window.location.reload();
        } else {
            if (pth.indexOf("okta") >= 0) {
                window.location.href = pth;
            }
            history.push(pth);
        }
    }
   
    function resultPth() {
        var result = "/";
        var id_token = localStorage.getItem('id_token');
        var okta_url = localStorage.getItem('okta_url');
        var post_logout_redirect_uri = localStorage.getItem('post_logout_redirect_uri');
        var check_okta_url = window.okta_url;
        var check_post_logout_redirect_uri = window.post_logout_redirect_uri;
        if (post_logout_redirect_uri != null && post_logout_redirect_uri != '') {
            //jwt解析
            var jwttoken = decodeToken(id_token);
            //token過期 
            if ((jwttoken.exp * 1000) < Date.now()) {
                result = "/";
            }
            //判斷後台傳遞的位址是否與前端JS設定一致
            else if (check_okta_url.trim() == okta_url && check_post_logout_redirect_uri.trim() == post_logout_redirect_uri) {
                result = UrlDecode(okta_url + "?id_token_hint=" + id_token + "&post_logout_redirect_uri=" + post_logout_redirect_uri);
            }
        }
        localStorage.clear();
        return result;
    }
    function ruturnDashBoard() {
        history.push('/Dashboard');
    }
    return (
        <>
            <header className="header">
                <a href="/Dashboard" className="logo">
                    <i className="icon icon-31"></i>
                </a>
                <div className="fn-bar">
                    <div className="left">
                        <span className="time">{nowTime}</span>
                    </div>
                    <div className="right">
                        {/*<a href="" className="notice" onClick={ openModalReview}>*/}
                        {/*    <i className="icon icon-42"></i>*/}
                        {/*</a>*/}
                        <a href="">
                            <i className="icon icon-40"></i>
                            <span>{username}</span>
                        </a>
                        <a onClick={logoutcheck} className="logout">
                            <i className="icon icon-38"></i>
                            <span>登出</span>
                        </a>
                    </div>
                </div>
            </header>

            <div className="main">
                <nav className="nav">
                    <div className="nav-container">
                        {
                            menuArray.map(x =>
                                <MenuItem menuID={x.menuID} menuName={x.menuCodeName} subMenu={x.subMenu} menuDUrl={x.menuDUrl} key={x.menuID} />)
                        }

                    </div>
                </nav>
                <div className="page-content" style={{ scrollBehavior: "smooth" }}>

                    {(() => {
                        if (menuArray.filter((item) => item.funcListType == "-1").length === 1) {
                            if (path.toLowerCase() == "adlist") {
                                return <ADList />;
                            } else if (path.toLowerCase() == "userinfo") {
                                return <UserInfo />;
                            } else if (path.toLowerCase() == "usermanage") {
                                return <UserManage />;
                            }
                        }
                        if (pageArray.filter(x => x.PageCode == path.toLowerCase()).length == 0) {
                            return ruturnDashBoard();
                        }
                        else {
                            switch (path.toLowerCase()) {
                                case 'saleorderqry': //產品查詢
                                    return <SaleOrderQry />;
                                case 'saleorderlist': //產品列表
                                    return <SaleOrderList />;
                                case 'saleordernew': //產品新增
                                    return <SaleOrderNew />;
                                case 'saleorderedit': //產品修改
                                    return <SaleOrderEdit />;
                                case 'settlementqry': //月結作業-查詢
                                    return <SettlementQry />;
                                case 'settlementlockqry': //月結作業-月結鎖定
                                    return <SettlementLockQry />;
                                case 'settlementlist': //月結作業-
                                    return <SettlementList />;
                                case 'settlementanalysislist': //月結作業-
                                    return <SettlementAnalysisList />;
                                case 'settlementproductlist': //月結作業-
                                    return <SettlementProductList />;
                                case 'product': //產品查詢
                                    return <Product />;
                                case 'productnew': //產品新增
                                    return <ProductNew />;
                                case 'productedit': //產品修改
                                    return <ProductEdit />;
                                case 'productcost': //產品成本查詢
                                    return <ProductCost />;
                                case 'productcostview': //產品成本話面
                                    return <ProductCostView />;
                                case 'customer': //客戶查詢
                                    return <Customer />;
                                case 'customernew': //客戶新增
                                    return <CustomerNew />;
                                case 'customeredit': //客戶修改
                                    return <CustomerEdit />;
                                case 'supplierqry': //供應商查詢
                                    return <SupplierQry />;
                                case 'suppliernew': //供應商新增
                                    return <SupplierNew />;
                                case 'supplieredit': //供應商新增
                                    return <SupplierEdit />;
                                case 'purchaserequestqry': //採購請款查詢
                                    return <PurchaseRequestQry />;
                                case 'purchaserequestlist': //採購請款列表
                                    return <PurchaseRequestList />;
                                case 'purchaserequestnew': //採購請款新增
                                    return <PurchaseRequestNew />;
                                case 'purchaserequestedit': //採購請款修改
                                    return <PurchaseRequestEdit />;
                                case 'permission': //權限
                                    return <Permission />;
                                case 'permissionedit': //權限修改
                                    return <PermissionEdit />;
                                case 'prepaid_customerqry': //客戶預付儲值/保證金查詢
                                    return <Prepaid_CustomerQry />;
                                case 'prepaid_customerview': //客戶預付儲值/保證金檢視畫面
                                    return <Prepaid_CustomerView />;
                                case 'prepaid_customeredit': //客戶預付儲值/保證金修改畫面
                                    return <Prepaid_CustomerEdit />;
                                case 'prepaid_customerlist': //客戶預付儲值/保證金紀錄
                                    return <Prepaid_CustomerList />;
                                case 'prepaid_supplierqry': //供應商預付儲值/保證金查詢
                                    return <Prepaid_SupplierQry />;
                                case 'prepaid_supplierview': //供應商預付儲值/保證金檢視畫面
                                    return <Prepaid_SupplierView />;
                                case 'prepaid_supplieredit': //供應商預付儲值/保證金修改畫面
                                    return <Prepaid_SupplierEdit />;
                                case 'prepaid_supplierlist': //供應商預付儲值/保證金紀錄
                                    return <Prepaid_SupplierList />;
                                case 'creditsqry': //信用額度查詢
                                    return <CreditsQry />;
                                case 'creditsview': //信用額度檢視
                                    return <CreditsView />;
                                case 'cloudacntqry'://雲端帳號查詢 11-1
                                    return <CloudAcntQry />;
                                case 'cloudacntlist1':// 雲端帳號 11-1-1
                                    return <CloudAcntList1 />;
                                case 'cloudacntnew'://雲端帳號新增 11-2
                                    return <CloudAcntNew />;
                                case 'cloudacntlist2':// 雲端帳號  11-3
                                    return <CloudAcntList2 />;
                                case 'cloudacntlist3':// 雲端帳號 11-3-1
                                    return <CloudAcntList3 />;
                                case 'catrowsetqry':// 雲端帳務
                                    return <CATRowSetQry />;
                                case 'catrowsetadd':// 雲端帳務
                                    return <CATRowSetAdd />;
                                case 'catcostset':// 雲端帳務-成本
                                    return <CATCostSet />;
                                case 'catexport':// 雲端帳務-匯入資料
                                    return <CATExport />;
                                case 'catadjust':// 雲端帳務-手動調帳
                                    return <CATAdjust />;
                                case 'catpreview':// 雲端帳務-預覽出帳
                                    return <CATPreview />;
                                case 'catpreviewlist':// 雲端帳務-預覽出帳
                                    return <CATPreviewList />;
                                case 'catqry':// 雲端帳務-查詢
                                    return <CATQry />;
                                case 'catqrylist1':// 雲端帳務-查詢
                                    return <CATQryList1 />;
                                case 'catqrylist2':// 雲端帳務-查詢
                                    return <CATQryList2 />;
                                case 'catdownload':// 雲端帳務-下載對帳單
                                    return <CATDownLoad />;

                                case 'interviewqry'://訪談紀錄
                                    return <InterViewQry />;
                                case 'interviewnew'://訪談紀錄新增
                                    return <InterViewNew />;
                                case 'interviewlist'://訪談紀錄編輯
                                    return <InterViewList />;
                                case 'interviewedit'://訪談紀錄編輯
                                    return <InterViewEdit />;
                                case 'statementnew'://開立對帳單
                                    return <StatementNew />;
                                case 'statementitem'://對帳單項目
                                    return <StatementItem />;
                                case 'invoicenew'://開立發票
                                    return <InvoiceNew />;
                                case 'invoiceitem'://發票項目
                                    return <InvoiceItem />;
                                case 'invoicevoid'://發票作廢
                                    return <InvoiceVoid />;
                                case 'invoicevoiditem'://發票作廢
                                    return <InvoiceVoidItem />;
                                //case 'invoicereduction'://發票回復
                                //    return <InvoiceReduction />;
                                //case 'invoicereductionitem'://發票回復項目
                                //    return <InvoiceReductionItem />;
                                //case 'invoicelock'://發票鎖定
                                //    return <InvoiceLock />;
                                //case 'invoicelockitem'://發票鎖定項目
                                //    return <InvoiceLockItem />;
                                case 'accchargeqry'://帳務沖銷
                                    return <AccChargeQry />;
                                case 'accchargelist'://帳務沖銷/未沖銷憑證
                                    return <AccChargeList />;
                                case 'exchangerateqry'://匯率查詢
                                    return <ExchangeRateQry />;
                                case 'exchangeratenew'://匯率新增
                                    return <ExchangeRateNew />;
                                default:
                                    return ruturnDashBoard();
                            }
                        }

                    })()}

                    <footer className="footer copyright">
                        <span>2021 Digicentre Company Limited. All Rights Reserved.</span>
                    </footer>
                </div>

            </div>
            <div id="modal-leavecheck" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <span className="close" onClick={() => CloseModal("modal-leavecheck", 6)}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">

                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            <input type="hidden" id="haschangetext" />
                            <div id="div_leave">
                            </div>
                        </div>
                        <div className="btn-holder sp">
                            <button className="btn btn-border" onClick={() => rePath(1)}>
                                <span>保留並另開分頁</span>
                            </button>
                            <button className="btn" onClick={() => rePath(2)}>
                                <span>放棄</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-logoutcheck" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <span className="close" onClick={() => CloseModal("modal-leavecheck", 6)}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            <div id="div_logout">
                                是否放棄編輯此紀錄？
                            </div>
                        </div>
                        <div className="btn-holder sp">
                            <button className="btn btn-border" onClick={logout}>
                                <span>放棄並登出</span>
                            </button>
                            <button className="btn" onClick={() => CloseModal("modal-logoutcheck", 6)}>
                                {" "}
                                <span>留在此頁</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-1004" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            <div id="div_1004">

                            </div>
                        </div>
                        <div className="btn-holder sp">
                            <button className="btn" onClick={() => history.push('/DashBoard')}>
                                <span>確定</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default main;