import React, { useState, useEffect } from "react"; 
const UserItem = ({ userID, userName, userNo, userPwd, userInvalid }) => {
    const pwdStr = userPwd == null ? "******" : "已更改";

    function openModal3(e) { 
        var id = "modal-3";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-3";
        var showLayerClass = "has-layer-3";
        target.classList.add("active");
        target.classList.add(layerClass);
        document.getElementById("resetName").innerHTML = userName;
        document.getElementById("resetUserID").value = userID;
        document.body.classList.add("has-modal", showLayerClass);
        e.preventDefault();
    }
    function openModal4(e) {
        var id = "modal-4";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-3";
        var showLayerClass = "has-layer-3";
        target.classList.add("active");
        target.classList.add(layerClass);
        document.getElementById("updateStatus").innerHTML =
            userInvalid == true ? "恢復" : "停止";
        document.getElementById("updateName").innerHTML = userName;
        document.getElementById("updateStatusUserID").value = userID;

        document.body.classList.add("has-modal", showLayerClass);
        e.preventDefault();
    }

    function openModal5(e) {
        var id = "modal-5";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-3";
        var showLayerClass = "has-layer-3";
        target.classList.add("active");
        target.classList.add(layerClass);

        document.getElementById("delName").innerHTML = userName;
        document.getElementById("delUserID").value = userID;

        document.body.classList.add("has-modal", showLayerClass);
        e.preventDefault();
    }

    return (
        <li className="lt-row">
            <div className="flex-4">{userNo}</div>
            <div className="flex-4">{userName}</div>
            <div className="flex-4">{pwdStr}</div>
            <div className="flex-5 fn">
                <div className="fn-bar">
                    <div
                        className={`btn btn-small btn-border${userInvalid ? " disabled" : ""
                            }`}
                        data-openmodal="modal-3"
                        onClick={userInvalid ? undefined : openModal3}
                    >
                        <span>重置密碼</span>
                    </div>
                    <div
                        className="btn btn-icon"
                        data-openmodal="modal-4"
                        onClick={openModal4}
                    >
                        <i className={`icon icon-${userInvalid ? "36" : "35"}`}></i>
                    </div>
                    <div
                        className={`btn btn-icon${userInvalid ? " disabled" : ""}`}
                        data-openmodal="modal-5"
                        onClick={openModal5}
                    >
                        <i className="icon icon-37"></i>
                    </div>
                </div>
            </div>
        </li>
    );
};

export default UserItem;
