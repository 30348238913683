import React, { useState, useEffect } from "react";

import {
    API_GET_PrepassRecord,
    API_GET_PrepassPreRecord,
    API_DOWNLOAD_FILE,
} from "../../global/constants";
import { NumFormart, InitJS } from "../../global/ToolUtil";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const Prepaid_CustomerList = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        //把menu顏色置換
        document.getElementById("menuitem-Prepaid_CustomerQry").classList.add("active");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    function returnView() {
        history.push("/Main?path=Prepaid_CustomerView&type=" + queryParams.get("type") + "&customerId=" + queryParams.get("customerId"));
    }
    const [typeName, setTypeName] = useState();
    const [type, setType] = useState();
    const [dataid, setDataid] = useState();

    const [customerId, setCustomerId] = useState();
    const [dataList, setDataList] = useState([{}]);

    const [customername, setCustomername] = useState(
        queryParams.get("customername")
    );

    function getData() {
        //var path = "";
        if (queryParams.get("type") == "1") {
            setType("1");
            setTypeName("儲值金");
            //path = API_GET_PrepassPreRecord + "?prepaidAmtId=" + queryParams.get("dataid");

            API_GET_PrepassPreRecord({
                prepaidAmtId: queryParams.get("dataid"),
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code != "0000" || data.result.length == 0) {
                        history.push("/");
                    } else {
                        var tmpData = [];
                        if (data.result.data.length > 0) {
                            for (var i = 0; i < data.result.data.length; i++) {
                                tmpData.push({
                                    createdTime: data.result.data[i].createdTimeStr, //建立日期
                                    cusAmtHisAmtAfter: NumFormart(data.result.data[i].cusAmtHisAmtAfter), //結存金額
                                    cusAmtHisAmtBefore: NumFormart(data.result.data[i].cusAmtHisAmtBefore), //餘額
                                    cusAmtHisAmtDec: NumFormart(data.result.data[i].cusAmtHisAmtDec), //減少金額
                                    cusAmtHisAmtInc: NumFormart(data.result.data[i].cusAmtHisAmtInc), //新增金額
                                    cusAmtHisAmtCert: data.result.data[i].cusAmtHisAmtCert,
                                    cusAmtHisAmtDate: data.result.data[i].cusAmtHisAmtDate,
                                    cusAmtHisAmtExRate: data.result.data[i].cusAmtHisAmtExRate, //匯率
                                    cusAmtHisAmtRem: data.result.data[i].cusAmtHisAmtRem, //說明
                                    cusAmtHisAmtType: data.result.data[i].cusAmtHisAmtType,
                                    cusAmtHisID: data.result.data[i].cusAmtHisID,
                                    cusAmtHisAmtCurName: data.result.data[i].cusAmtHisAmtCurName,
                                    custID: data.result.data[i].custID,
                                    fileName: data.result.data[i].fileName,
                                });
                            }

                            setDataList(tmpData);
                        }
                    }
                })
                .catch((err) => {
                     
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else if (queryParams.get("type") == "2") {
            setType("2");
            setTypeName("保證金");
            API_GET_PrepassRecord({
                customerAssureId: queryParams.get("dataid"),
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    var tmpData = [];
                    if (data.result.data.length > 0) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            tmpData.push({
                                createdTime: data.result.data[i].createdTimeStr, //建立日期
                                cusAmtHisAmtAfter: NumFormart(data.result.data[i].cusAmtHisAmtAfter), //結存金額
                                cusAmtHisAmtBefore: NumFormart(data.result.data[i].cusAmtHisAmtBefore), //餘額
                                cusAmtHisAmtDec: NumFormart(data.result.data[i].cusAmtHisAmtDec), //減少金額
                                cusAmtHisAmtInc: NumFormart(data.result.data[i].cusAmtHisAmtInc), //新增金額
                                cusAmtHisAmtCert: data.result.data[i].cusAmtHisAmtCert,
                                cusAmtHisAmtDate: data.result.data[i].cusAmtHisAmtDate,
                                cusAmtHisAmtExRate: data.result.data[i].cusAmtHisAmtExRate, //匯率
                                cusAmtHisAmtRem: data.result.data[i].cusAmtHisAmtRem, //說明
                                cusAmtHisAmtType: data.result.data[i].cusAmtHisAmtType,
                                cusAmtHisID: data.result.data[i].cusAmtHisID,
                                cusAmtHisAmtCurName: data.result.data[i].cusAmtHisAmtCurName,
                                custID: data.result.data[i].custID,
                                fileName: data.result.data[i].fileName,
                            });
                        }

                        setDataList(tmpData);
                    }

                })
                .catch((err) => {
                    
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
        setCustomerId(queryParams.get("customerId"));
    }

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container full">
                    <h3 className="fz-B has-border">
                        {customername}
                        {typeName}紀錄
                    </h3>
                </div>
            </div>
            <div className="container full">
                <ul className="list-table">
                    <li className="lt-row lt-th">
                        <div className="flex-3">日期</div>
                        <div className="flex-3">儲值金餘額</div>
                        <div className="flex-3">新增金額</div>
                        <div className="flex-3">減少金額</div>
                        <div className="flex-2">匯率</div>
                        <div className="flex-3">結存金額</div>
                        <div className="flex-3">憑證</div>
                        <div className="flex-3">說明</div>
                    </li>
                    {dataList.map((x) =>
                        x.custID != null ? (
                            <li className="lt-row ovv">
                                <div className="flex-3">{x.createdTime}</div>
                                <div className="flex-3">
                                    {x.cusAmtHisAmtBefore == "" ? 0 : x.cusAmtHisAmtBefore}
                                </div>
                                <div className="flex-3">
                                    {x.cusAmtHisAmtCurName}{" "}
                                    {x.cusAmtHisAmtInc == "" ? 0 : x.cusAmtHisAmtInc}
                                </div>
                                <div className="flex-3">
                                    NTD {x.cusAmtHisAmtDec == "" ? 0 : x.cusAmtHisAmtDec}
                                </div>
                                <div className="flex-2">1 : {x.cusAmtHisAmtExRate}</div>

                                <div className="flex-3">
                                    NTD {x.cusAmtHisAmtAfter == "" ? 0 : x.cusAmtHisAmtAfter}
                                </div>
                                <div className="flex-3">
                                    <a href={`${API_DOWNLOAD_FILE}${x.cusAmtHisAmtCert}`}>
                                        {x.fileName}
                                    </a>
                                </div>
                                <div className="flex-3 tip" data-tip={x.cusAmtHisAmtRem}>
                                    <p>{x.cusAmtHisAmtRem}</p>
                                </div>
                            </li>
                        ) : null
                    )}
                </ul>

                <div className="form-item btn-area sp">
                    <div className="left"></div>
                    <div className="right">
                        <a className="btn" onClick={returnView}>
                            <span>回上一頁</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Prepaid_CustomerList;
