import { OpenModal } from './ToolUtil'
/*
 * 檢查response code
 */
export const CheckResponseCode = (data) => {
    let result = true;
    if (data && data.code) {
        switch (data.code) {
            case "1004": //您沒有權限申請新增 / 刪除 / 修改 / 查詢相關資訊 => 開啟modal
                document.getElementById('div_1004').innerHTML = data.message;
                OpenModal('modal-1004');
                result = false;
                break;
            case "1099": //您的AD帳號尚未啟用 => 跳轉"帳號停用"頁面
                console.log("哈哈哈")
                window.location.replace("/ErrorInvalid");
                result = false;
                break;
            
        }
    }

    return result;
}