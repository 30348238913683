import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
const PermissionBtn = ({ menuid, targetHref, name }) => {
    const history = useHistory();

    function redirect() {
        if (targetHref == null) {
            //不動作
        } else {
            localStorage.setItem("action", targetHref);
            history.push({ pathname: "/Main?path=" + targetHref });
        }

    }
    //檢查是否有href
    function checkHasH() {

        if (targetHref == null) {
            return true;
        } else {
            return false;
        }
    }
    ////console.log(menuid);
    return (
        <a href="" className="db-item" onClick={redirect} >
            <i className={`icon icon-${menuid}`}></i>
            <h4 className="fz-C">{name}</h4>
        </a>
    );
};

export default PermissionBtn;
