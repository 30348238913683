import React, { useState, useEffect } from "react";
import HtmlParse from 'html-react-parser';
import { useHistory } from "react-router-dom";
import {
    API_POST_InvoiceHome,
    API_PUT_InvoiceOutputBatch,
    API_PUT_InvoiceData,
    API_PUT_InvoiceOutputApply,
    API_GET_FileDownload
} from "../../global/constants";
import { Clone, NumFormart, UrlDecode, CloseModal, OpenModal, InitJS, MenuSet, SetOnbeforeunload, SetHasChange } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
import OrderDetail from "../Common/OrderDetail";
import _, { cloneDeep, forEach } from 'lodash';

const InvoiceItem = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const [reGet, setReGet] = useState(false);

    const [dataListFilter, setDataListFilter] = useState([]);
    const [dataFilter, setDataFilter] = useState("");
    const [isClearFilter, setIsClearFilter] = useState(false);
    const [doApply, setDoApply] = useState({
        do: false,
        param: {}
    });

    const [doError, setDoError] = useState(false);

    useEffect(() => {
        //把menu顏色置換
        MenuSet("StatementNew");
        //抓取資料 
        getdata();
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    useEffect(() => {
        if (reGet) {
            //內頁資料有刪除/修改 重抓
            getdata();
        }
        setReGet(false);
    }, [reGet]);

    useEffect(() => {
        if (doApply.do && isClearFilter) {
            let checked = dataCheck();
            let dt = checked.data;
            let isTxn = doApply.param.isTxn;

            if (checked.hasError == true) {
                setDoError(true);
                scrollToError();
            }
            else {
                setDoError(false);

                var sendData = {
                    view1: dt,
                    isTxn: isTxn
                }

                console.log("傳送資料", sendData);
                setIsControlDisabled(true);

                API_PUT_InvoiceOutputApply(sendData)
                    .then((response) => response.data)
                    .then((data) => {
                        CloseModal("modal-apply1");
                        CloseModal("modal-apply2");
                        console.log("開立發票回傳內容", data);
                        document.getElementById('ApplyBtn')?.classList.remove('disabled');
                        if (!CheckResponseCode(data)) { return; } //檢查response code
                        if (data.code == "0000") {
                            if (isTxn == 0) {
                                downLoad(data.result.data.downloadPath);
                            }
                            else {
                                SetHasChange(false);
                                getdata();
                                setModalStr("發票開立成功");
                                OpenModal("modal-ok");
                            }
                        } else {
                            setModalStr(data.message);
                            OpenModal("modal-error-callback");
                        }
                    })
                    .catch((err) => {
                        CloseModal("modal-apply1");
                        CloseModal("modal-apply2");

                        document.getElementById('ApplyBtn')?.classList.remove('disabled');
                    })
                    .finally(() => {
                        setIsControlDisabled(false);
                    });
            }

            setDoApply({
                do: false,
                param: {}
            });
            setIsClearFilter(false);
        }
        //#endregion

    }, [doApply, isClearFilter])

    function scrollToError() {
        //發票狀態
        var invalidInvoiceStates = dataList.filter(x => x.isChecked == true && [0, 2, 3].some(y => y == parseInt(x.invoiceState)))
        if (invalidInvoiceStates.length > 0) {
            let indexKey = GetListIndexKey(invalidInvoiceStates[0]);
            let section = document.querySelector(`#invoiceState_${indexKey}`);

            var rect = section.getBoundingClientRect();
            //控制x軸
            let list = document.querySelector(`.list-table-wrapper`);
            list.scrollLeft = 0;
            //控制y軸
            let page = document.querySelector(`.list-table-wrapper`);
            const scrollTop = page.scrollTop;
            page.scrollTop = rect.top + scrollTop - 280;

            document.getElementById('ApplyBtn')?.classList.remove("disabled");
            CloseModal("modal-apply1");
            CloseModal("modal-apply2");
            return false;
        }

        //發票序
        var invalidInvoiceProjects = dataList.filter(x => x.isChecked == true && x.invoiceProject == null || x.invoiceProject == "")
        if (invalidInvoiceProjects.length > 0) {
            let indexKey = GetListIndexKey(invalidInvoiceProjects[0]);
            let section = document.querySelector(`#invoiceProject_${indexKey}`);
            var rect = section.getBoundingClientRect();
            //控制x軸
            let list = document.querySelector(`.list-table-wrapper`);
            list.scrollLeft = 0;
            //控制y軸
            let page = document.querySelector(`.list-table-wrapper`);
            const scrollTop = page.scrollTop;
            page.scrollTop = rect.top + scrollTop - 180;

            document.getElementById('ApplyBtn')?.classList.remove("disabled");
            CloseModal("modal-apply1");
            return false;
        }

        //發票項
        var invalidInvoiceItems = dataList.filter(x => x.isChecked == true && x.invoiceItem == null || x.invoiceItem == "")
        if (invalidInvoiceItems.length > 0) {
            let indexKey = GetListIndexKey(invalidInvoiceItems[0]);
            let section = document.querySelector(`#invoiceItem_${indexKey}`);
            var rect = section.getBoundingClientRect();
            //控制x軸
            let list = document.querySelector(`.list-table-wrapper`);
            list.scrollLeft = 0;
            //控制y軸
            let page = document.querySelector(`.list-table-wrapper`);
            const scrollTop = page.scrollTop;
            page.scrollTop = rect.top + scrollTop - 180;

            document.getElementById('ApplyBtn')?.classList.remove("disabled");
            CloseModal("modal-apply1");
            return false;
        }

        //發票品名
        for (var i = 0; i < dataList.length; i++) {
            if (dataList[i].isChecked == true) {
                let indexKey = GetListIndexKey(dataList[i]);

                var name1 = document.getElementById('invoiceName1_' + indexKey)?.classList.contains('error');
                var name2 = document.getElementById('invoiceName2_' + indexKey)?.classList.contains('error');
                var name3 = document.getElementById('invoiceName3_' + indexKey)?.classList.contains('error');
                var name4 = document.getElementById('invoiceName4_' + indexKey)?.classList.contains('error');
                var name5 = document.getElementById('invoiceName4_' + indexKey)?.classList.contains('error');

                if (name1 || name2 || name3 || name4 || name5) {
                    //let section = document.querySelector(`#invoiceName1_${i}`);
                    //section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    let section = document.querySelector(`.list-table-wrapper`);
                    var rect = section.getBoundingClientRect();
                    //控制x軸
                    let list = document.querySelector(`.list-table-wrapper`);
                    list.scrollLeft = 450;
                    //控制y軸
                    let page = document.querySelector(`.list-table-wrapper`);
                    const scrollTop = page.scrollTop;
                    page.scrollTop = rect.top + scrollTop - 180;

                    document.getElementById('ApplyBtn')?.classList.remove("disabled");
                    CloseModal("modal-apply1");
                    return false;
                }
            }
        }

        //發票備註
        for (var i = 0; i < dataList.length; i++) {
            if (dataList[i].isChecked == true) {
                let indexKey = GetListIndexKey(dataList[i]);

                var explanation = document.getElementById('explanation_' + indexKey)?.classList.contains('error');

                if (explanation) {
                    //let section = document.querySelector(`#invoiceName1_${i}`);
                    //section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    let section = document.querySelector(`.list-table-wrapper`);
                    var rect = section.getBoundingClientRect();
                    //控制x軸
                    let list = document.querySelector(`.list-table-wrapper`);
                    list.scrollLeft = 1500;
                    //控制y軸
                    let page = document.querySelector(`.list-table-wrapper`);
                    const scrollTop = page.scrollTop;
                    page.scrollTop = rect.top + scrollTop - 180;

                    document.getElementById('ApplyBtn')?.classList.remove("disabled");
                    CloseModal("modal-apply1");
                    return false;
                }
            }
        }
    }

    function getdata() {

        var dt = {
            companyId: parseInt(queryParams.get("companyId")),
            incomeMonth: queryParams.get("incomeMonth"),
            customerName: queryParams.get("customerName") == "" ? null : queryParams.get("customerName"),
            customerTitle: queryParams.get("customerTitle") == "" ? null : queryParams.get("customerTitle"),
            customerTaxId: queryParams.get("customerTaxId") == "" ? null : queryParams.get("customerTaxId"),
            productId: queryParams.get("productId") == "" ? null : parseInt(queryParams.get("productId")),
            productTypeId: queryParams.get("productTypeId") == "" ? null : parseInt(queryParams.get("productTypeId")),
            productName1: queryParams.get("productName1") == "" ? null : queryParams.get("productName1"),
            supplierName: queryParams.get("supplierName") == "" ? null : queryParams.get("supplierName"),
            orderNumber: queryParams.get("orderNumber") == "" ? null : queryParams.get("orderNumber"),
            personInChargeName: queryParams.get("personInChargeName") == "" ? null : queryParams.get("personInChargeName"),
            personInChargeID: queryParams.get("personInChargeID") == "" ? null : parseInt(queryParams.get("personInChargeID")),
        }
        //console.log(JSON.stringify(dt));
        API_POST_InvoiceHome(dt)
            .then((response) => response.data)
            .then((data) => {
                console.log("開立發票詳細資料列表", data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    var dt = data.result.data.list;

                    dt.forEach(item => setElementDisabled(["invoiceName1", "invoiceName2", "invoiceName3", "invoiceName4", "invoiceName5", "explanation"], GetListIndexKey(item), false))

                    setDataList([...dt]);
                    setDataListFilter([...dt]);
                    setCompanyName(data.result.data.companyName);
                    setIncomeMonth(data.result.data.incomeMonth)
                    setCurrName(data.result.data.currName);
                }
            })
            .catch((err) => {
                console.log("開立發票詳細資料列表錯誤", err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [companyName, setCompanyName] = useState();
    const [incomeMonth, setIncomeMonth] = useState();
    const [dataList, setDataList] = useState([]);
    const [dataList2, setDataList2] = useState([]);
    const [orderId, setOrderId] = useState();

    const [modalStr, setModalStr] = useState();
    const [currName, setCurrName] = useState();
    //發票品名主要要被複製文字
    const [copyNameMain1, setCopyNameMain1] = useState();
    const [copyNameMain2, setCopyNameMain2] = useState();
    const [copyNameMain3, setCopyNameMain3] = useState();
    const [copyNameMain4, setCopyNameMain4] = useState();
    const [copyNameMain5, setCopyNameMain5] = useState();

    const [isControlDisabled, setIsControlDisabled] = useState(false);
    const [groupItemKeyMapping, setGroupItemKeyMapping] = useState(null)
    const [dataListCheckResult, setDataListCheckResult] = useState([]);

    //發票品名主要要被複製文字替換
    function copyNameMainChange(e, index) {
        if (index == 1) {
            setCopyNameMain1(e.target.value);
        } else if (index == 2) {
            setCopyNameMain2(e.target.value);
        } else if (index == 3) {
            setCopyNameMain3(e.target.value);
        } else if (index == 4) {
            setCopyNameMain4(e.target.value);
        } else if (index == 5) {
            setCopyNameMain5(e.target.value);
        }
    }
    //執行批次複製
    function copyNameMainCopy(index) {
        setDataList2(function (prev) {
            return prev.map((item) => {
                if (index == 1) {
                    item.invoiceName1 = copyNameMain1;
                }
                else if (index == 2) {
                    item.invoiceName2 = copyNameMain2;
                }
                else if (index == 3) {
                    item.invoiceName3 = copyNameMain3;
                }
                else if (index == 4) {
                    item.invoiceName4 = copyNameMain4;
                }
                else if (index == 5) {
                    item.invoiceName5 = copyNameMain5;
                }
                return item;
            });
        });
    }

    function GetListIndexKey(item) {
        return item.orderId + '_' + item.orderProductId;
    }

    function InvoiceNameDisableHandle() {

        let disabledIndex = [];

        dataList.forEach(item => setElementDisabled(["invoiceName1", "invoiceName2", "invoiceName3", "invoiceName4", "invoiceName5", "explanation"], GetListIndexKey(item), false))

        let groupDatas = dataList
            .filter(item => item.invoiceProject != null && item.invoiceItem != null && item.isChecked)
            .reduce((group, item) => {
                const { invoiceProject, invoiceItem } = item;
                let key = `${invoiceProject}_${invoiceItem}`;
                group[key] = group[key] ?? [];
                group[key].push(item);
                return group;
            }, {});

        let groupItemNameCollect = [];

        let i = 0;
        for (let group in groupDatas) {
            let groupItenName = {
                mainKey: null,
                subKey: []
            };

            if (groupDatas.hasOwnProperty(group)) {
                let isFirst = true;
                let groupArray = groupDatas[group];
                if (groupArray.length > 0) {
                    groupArray.forEach(item => {
                        if (isFirst) {
                            isFirst = false;
                            groupItenName.mainKey = GetListIndexKey(item);
                            groupItenName.subKey.push(GetListIndexKey(item));
                        }
                        else {
                            let indexKey = GetListIndexKey(item);

                            setElementDisabled(["invoiceName1", "invoiceName2", "invoiceName3", "invoiceName4", "invoiceName5", "explanation"], indexKey, true);
                            disabledIndex.push(indexKey)
                            groupItenName.subKey.push(GetListIndexKey(item));
                        }
                    });
                }
            }

            groupItemNameCollect.push(groupItenName);
        }

        setGroupItemKeyMapping(groupItemNameCollect);

        return disabledIndex;
    }

    function setElementDisabled(elementNameArray, index, isDisabled) {
        elementNameArray.forEach(x => {
            let elementId = `${x}_${index}`;
            document.getElementById(elementId) && (document.getElementById(elementId).disabled = isDisabled);
        })

    }

    //主畫面資料修改
    function dataChange(e, data, col) {
        SetHasChange(true);
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (GetListIndexKey(item) == GetListIndexKey(data)) {
                    if (col == "invoiceItem") {
                        item.invoiceItem = e.target.value;
                    }
                    else if (col == "invoiceProject") {
                        item.invoiceProject = e.target.value;
                    }
                    else if (col == "invoiceName1") {
                        item.invoiceName1 = e.target.value;
                    }
                    else if (col == "invoiceName2") {
                        item.invoiceName2 = e.target.value;
                    }
                    else if (col == "invoiceName3") {
                        item.invoiceName3 = e.target.value;
                    }
                    else if (col == "invoiceName4") {
                        item.invoiceName4 = e.target.value;
                    }
                    else if (col == "invoiceName5") {
                        item.invoiceName5 = e.target.value;
                    }
                    else if (col == "explanation") {
                        item.explanation = e.target.value;
                    } else if (col == "invoiceState") {
                        item.invoiceState = e.target.value;
                    }
                    else if (col == "isChecked") {
                        item.isChecked = e.target.checked;
                    }
                }

                return item;
            });
        });
    }

    function refreshContentDisabled() {
        let disabledIndex = InvoiceNameDisableHandle();

        dataList.forEach(element => {
            let indexKey = GetListIndexKey(element);
            if (disabledIndex.includes(indexKey)) {
                element.invoiceName1 = "";
                element.invoiceName2 = "";
                element.invoiceName3 = "";
                element.invoiceName4 = "";
                element.invoiceName5 = "";
                element.explanation = "";
            }
        });
    }

    useEffect(() => {
        refreshContentDisabled();

        if (dataList && dataList.length > 0 && dataList.filter(x => x.isChecked).length == dataList.length) {
            setC40checked(true);
        }
        else {
            setC40checked(false);
        }

        if (doError) {
            dataCheck();
        }

    }, [dataList, dataListFilter])

    //發票批次品名 單筆
    function copyNameChange(e, data, col) {
        setDataList2(function (prev) {
            return prev.map((item, i) => {
                if (GetListIndexKey(item) == GetListIndexKey(data)) {
                    if (col == 1) {
                        item.invoiceName1 = e.target.value;
                    }
                    else if (col == 2) {
                        item.invoiceName2 = e.target.value;
                    }
                    else if (col == 3) {
                        item.invoiceName3 = e.target.value;
                    }
                    else if (col == 4) {
                        item.invoiceName4 = e.target.value;
                    }
                    else if (col == 5) {
                        item.invoiceName5 = e.target.value;
                    }
                }
                return item;
            });
        });
    }

    const [c40checked, setC40checked] = useState(false);

    function setAllChecked(checked) {
        setC40checked(checked);
        setDataList(function (prev) {
            return prev.map((item, i) => {
                item.isChecked = checked;

                let disabledIndex = InvoiceNameDisableHandle();
                if (disabledIndex.length > 0) {
                    if (disabledIndex.includes(GetListIndexKey(item))) {
                        item.invoiceName1 = "";
                        item.invoiceName2 = "";
                        item.invoiceName3 = "";
                        item.invoiceName4 = "";
                        item.invoiceName5 = "";
                        item.explanation = "";
                    }
                }
                return item;
            });
        });
    }

    function dataCheck() {
        //暫存處理過的序號
        var tmpDataList = [];
        let checkArray = [];
        let hasError = false;
        let dt = [];

        console.log("資料", dataList);

        //#region 錯誤檢查
        for (var i = 0; i < dataList.length; i++) {
            let indexKey = GetListIndexKey(dataList[i]);
            let currentExist = dataListFilter.some((item) => {
                return GetListIndexKey(item) == indexKey;

            })

            if (currentExist) {
                //發票狀態
                if (dataList[i].isChecked == true && [0, 2, 3].some(y => y == parseInt(dataList[i].invoiceState))) {
                    document.getElementById(`invoiceState_${indexKey}`)?.classList.add("error");
                    hasError = true;
                }
                else {
                    document.getElementById(`invoiceState_${indexKey}`)?.classList.remove("error");
                }

                //發票序
                if (dataList[i].isChecked == true && (dataList[i].invoiceProject == null || dataList[i].invoiceProject == "")) {
                    document.getElementById(`invoiceProject_${indexKey}`)?.classList.add("error");
                    hasError = true;
                }
                else {
                    document.getElementById(`invoiceProject_${indexKey}`)?.classList.remove("error");
                }

                //發票項
                if (dataList[i].isChecked == true && (dataList[i].invoiceItem == null || dataList[i].invoiceItem == "")) {
                    document.getElementById(`invoiceItem_${indexKey}`)?.classList.add("error");
                    hasError = true;
                } else {
                    document.getElementById(`invoiceItem_${indexKey}`)?.classList.remove("error");
                }

                //發票品名
                let invoiceNameLengthTotalError = false;
                if (dataList[i].isChecked == true) {
                    //找出有勾選的訂單編號是不是有相同發票序項
                    let tmpInvoice = tmpDataList.find(x => x.isChecked && x.invoiceProject == dataList[i].invoiceProject && x.invoiceItem == dataList[i].invoiceItem)

                    if (tmpInvoice == null) {
                        tmpDataList.push(dataList[i]);

                        //有勾選 第一個序品名和備註都必填
                        //20230317更改成品名輸入任何一個都可以

                        let totalLength =
                            (dataList[i].invoiceName1 == null || dataList[i].invoiceName1 == "" ? 0 : dataList[i].invoiceName1.length) +
                            (dataList[i].invoiceName2 == null || dataList[i].invoiceName2 == "" ? 0 : dataList[i].invoiceName2.length) +
                            (dataList[i].invoiceName3 == null || dataList[i].invoiceName3 == "" ? 0 : dataList[i].invoiceName3.length) +
                            (dataList[i].invoiceName4 == null || dataList[i].invoiceName4 == "" ? 0 : dataList[i].invoiceName4.length) +
                            (dataList[i].invoiceName5 == null || dataList[i].invoiceName5 == "" ? 0 : dataList[i].invoiceName5.length);

                        if (totalLength == 0) {
                            document.getElementById('invoiceName1_' + indexKey)?.classList.add('error');
                            hasError = true;
                        }
                        else if (totalLength > 100) {
                            document.getElementById('invoiceName1_' + indexKey)?.classList.add('error');
                            document.getElementById('invoiceName2_' + indexKey)?.classList.add('error');
                            document.getElementById('invoiceName3_' + indexKey)?.classList.add('error');
                            document.getElementById('invoiceName4_' + indexKey)?.classList.add('error');
                            document.getElementById('invoiceName5_' + indexKey)?.classList.add('error');

                            invoiceNameLengthTotalError = true;
                            hasError = true;
                        } else {
                            document.getElementById('invoiceName1_' + indexKey)?.classList.remove('error');
                            document.getElementById('invoiceName2_' + indexKey)?.classList.remove('error');
                            document.getElementById('invoiceName3_' + indexKey)?.classList.remove('error');
                            document.getElementById('invoiceName4_' + indexKey)?.classList.remove('error');
                            document.getElementById('invoiceName5_' + indexKey)?.classList.remove('error');
                        }
                    }
                }
                else {
                    document.getElementById('invoiceName1_' + indexKey)?.classList.remove('error');
                    document.getElementById('invoiceName1_' + indexKey)?.classList.remove('error');
                    document.getElementById('invoiceName2_' + indexKey)?.classList.remove('error');
                    document.getElementById('invoiceName3_' + indexKey)?.classList.remove('error');
                    document.getElementById('invoiceName4_' + indexKey)?.classList.remove('error');
                    document.getElementById('invoiceName5_' + indexKey)?.classList.remove('error');
                }

                let remarkLengthError = false;
                //發票備註
                if (dataList[i].isChecked == true) {
                    let remarkLength = dataList[i].explanation == null || dataList[i].explanation == '' ? 0 : dataList[i].explanation.length;

                    if (remarkLength > 100) {
                        document.getElementById(`explanation_${indexKey}`)?.classList.add("error");
                        remarkLengthError = true;
                        hasError = true;
                    }
                    else {
                        document.getElementById(`explanation_${indexKey}`)?.classList.remove("error");
                    }
                }
                else {
                    document.getElementById(`explanation_${indexKey}`)?.classList.remove("error");
                }

                if (dataList[i].isChecked == true) {
                    var foundItem = groupItemKeyMapping.find(function (item) {
                        return item.subKey.includes(GetListIndexKey(dataList[i]));
                    });

                    var mainItem = null;
                    if (foundItem != null) {
                        mainItem = tmpDataList.find(x => GetListIndexKey(x) == foundItem.mainKey);
                    }

                    dt.push({
                        orderId: parseInt(dataList[i].orderId),
                        incomeMonth: dataList[i].sellYM,
                        orderProductId: parseInt(dataList[i].orderProductId),
                        fineTuneId: parseInt(dataList[i].fineTuneId),
                        invoiceOrder: parseInt(dataList[i].invoiceProject),
                        invoiceItem: parseInt(dataList[i].invoiceItem),
                        invoiceState: parseInt(dataList[i].invoiceState),
                        item1: mainItem ? mainItem.invoiceName1 : null,
                        item2: mainItem ? mainItem.invoiceName2 : null,
                        item3: mainItem ? mainItem.invoiceName3 : null,
                        item4: mainItem ? mainItem.invoiceName4 : null,
                        item5: mainItem ? mainItem.invoiceName5 : null,
                        explanation: mainItem ? mainItem.explanation : null,
                    });
                }

                checkArray.push({
                    indexKey: indexKey,
                    invoiceNameLengthTotal: invoiceNameLengthTotalError,
                    remarkLength: remarkLengthError
                });
            }
        }
        //#endregion

        setDataListCheckResult(checkArray);

        return {
            hasError: hasError,
            data: dt
        }
    }

    //暫存
    function tmpSave() {
        //用data-close還是有延遲 所以這邊直接JS移除彈跳視窗
        CloseModal("modal-3");

        var result = false;
        var dt = [];
        for (var i = 0; i < dataList.length; i++) {
            dt.push({
                //ioid: dataList[i].ioid,
                orderId: dataList[i].orderId,
                orderProductId: dataList[i].orderProductId,
                incomeMonth: dataList[i].sellYM,
                invoiceState: dataList[i].invoiceState,
                invoiceProject: dataList[i].invoiceProject,
                invoiceItem: dataList[i].invoiceItem,
                invoiceName1: dataList[i].invoiceName1,
                invoiceName2: dataList[i].invoiceName2,
                invoiceName3: dataList[i].invoiceName3,
                invoiceName4: dataList[i].invoiceName4,
                invoiceName5: dataList[i].invoiceName5,
                explanation: dataList[i].explanation,
                fineTuneId: dataList[i].fineTuneId,

            });
        }
        //console.log(JSON.stringify(dt));
        setIsControlDisabled(true);

        API_PUT_InvoiceData(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    SetHasChange(false);
                    result = true;
                    setModalStr("暫存成功");
                    OpenModal("modal-ok");
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error-callback");
                }
            })
            .finally(() => {
                setIsControlDisabled(false);
            });

        return result;
    }

    //對帳單-產出發票申請單
    function OutputApply(isTxn = 1) {
        filterClear();

        setDoApply({
            do: true,
            param: {
                isTxn: isTxn
            }
        });
    }

    //檢視發票開立單
    const FileDownload = require("js-file-download");
    function downLoad(path) {
        setIsControlDisabled(true);

        API_GET_FileDownload({
            fileName: path,
        }).then((response) => {
            const disposition = response.headers['content-disposition'];
            var filename = disposition.split("''")[1];
            FileDownload(response.data, decodeURI(filename));
        })
            .finally(() => {
                setIsControlDisabled(false);
            });
    }

    //打開發票批次品名 modal
    function openModalCopyName() {
        //清空原本的內容值
        setCopyNameMain1("");
        setCopyNameMain2("");
        setCopyNameMain3("");
        setCopyNameMain4("");
        setCopyNameMain5("");
        setDataList2([]);

        let recodeinvoiceItemProject = []; //紀錄是否有存在的發票序項
        let removeIndex = []; //要排除相同的發票序項Index

        var newDateList = _.cloneDeep(dataList);
        let tmpArr = newDateList.filter(x => x.isChecked == true);

        tmpArr.forEach((item, i) => {
            if (item.invoiceItem != null && item.invoiceProject != null) {
                if (recodeinvoiceItemProject.find(x => x.invoiceItem == item.invoiceItem && x.invoiceProject == item.invoiceProject)) {
                    removeIndex.push(GetListIndexKey(item));
                }
                else {
                    recodeinvoiceItemProject.push({
                        invoiceItem: item.invoiceItem,
                        invoiceProject: item.invoiceProject
                    });
                }
            }
        });

        let newTmpArr = tmpArr.filter((item, index) => {
            return !removeIndex.includes(GetListIndexKey(item));
        });

        if (tmpArr.length > 0) {
            setDataList2(newTmpArr);
            OpenModal("modal-copyName");
        }
    }

    //產生發票申請單
    function openModalApply(e, isTxn = 1) {
        e.target.classList.add('disabled');

        if (dataList.filter(x => x.isChecked == true).length == 0) {
            setModalStr("請選擇訂單編號");
            e.target.classList.remove('disabled');
            OpenModal("modal-error");
            return;
        }

        if (isTxn == 0) {
            OutputApply(isTxn);
        }
        else {
            var id = "modal-apply1";
            //標記選取項目是否有列管
            var hasTube = false;
            for (var i = 0; i < dataList.length; i++) {
                if (dataList[i].isChecked == true && dataList[i].invoiceState == 2) {
                    hasTube = true;
                    break;
                }
            }
            if (hasTube) {
                id = "modal-apply2";
            }

            OpenModal(id);
        }


        e.target.classList.remove('disabled');
    }


    //儲存 發票批次品名
    function saveCopyName() {
        setDataList(function (prev) {
            return prev.map((item, i) => {

                var selectData = dataList2.find(x => x.orderId == item.orderId && x.orderProductId == item.orderProductId);
                if (selectData) {
                    item.item1 = selectData.invoiceName1;
                    item.item2 = selectData.invoiceName2;
                    item.item3 = selectData.invoiceName3;
                    item.item4 = selectData.invoiceName4;
                    item.item5 = selectData.invoiceName5;
                }

                return item;
            });
        });
        CloseModal("modal-copyName");
    }

    function Capply() {
        CloseModal("modal-apply1");
        CloseModal("modal-apply2");
        document.getElementById('ApplyBtn')?.classList.remove('disabled');
    }

    function filterClear() {
        setDataListFilter([...dataList]);
        setDataFilter("");
        setIsClearFilter(true);
    }

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container full">
                    <h3 className="fz-B has-border">訂單公司:{companyName} </h3>
                </div>
            </div>
            <div className="container full">
                <div className="info-holder">
                    <div className="left">營收月份：{incomeMonth}</div>
                    <div className="right">
                        <a className={`btn btn-border btn-small  ${dataList.filter(x => x.isChecked == true).length > 0 && !isControlDisabled ? '' : 'disabled'}`} onClick={e => dataList.filter(x => x.isChecked == true).length > 0 ? openModalCopyName() : null}>
                            <span>發票批次命名 {`${dataList.filter(x => x.isChecked == true).length > 0 ? "(" + dataList.filter(x => x.isChecked == true).length + ")" : ''}`}</span>
                        </a>

                        <input type="text" style={{ width: "350px", marginLeft: "5px" }} value={dataFilter} placeholder="請輸入欲篩選的客戶別或公司抬頭" onChange={(e) => {
                            SetHasChange(false);

                            let value = e.target.value;
                            setDataFilter(value);

                            let tempList = [...dataList];

                            try {
                                if (value != null && value != '') {
                                    let testValue = value.replace(/\\/g, '\\\\');
                                    tempList = tempList.filter(x => {
                                        let regex = new RegExp(testValue, "i");
                                        return regex.test(x.customerName) || regex.test(x.customerTitle);
                                    });
                                }
                            } catch (ex) {
                                setDataListFilter([]);
                            }

                            setDataListFilter(tempList);
                        }} />
                    </div>
                </div>
                <div className="scroll-wrapper for-4-1">
                    <div className="list-table-wrapper">
                        <ul className="list-table">
                            <li className="lt-row lt-th sticky-head">
                                <div className="flex-1 max1 fixed-1">
                                    <input type="checkbox" id="c40" name="c40" onClick={(e) => setAllChecked(!c40checked)} checked={c40checked} />
                                    <label htmlFor="c40"></label>
                                </div>
                                <div className="flex-3 fixed-2">訂單編號</div>
                                <div className="flex-3 fixed-3">客戶別</div>
                                <div className="flex-4 fixed-4">產品細項</div>
                                <div className="flex-2 ">發票狀態</div>
                                <div className="flex-1">發票序</div>
                                <div className="flex-1">發票項</div>
                                <div className="flex-3 right">費用金額</div>
                                <div className="flex-3">發票品名</div>
                                <div className="flex-3"></div>
                                <div className="flex-3"></div>
                                <div className="flex-3"></div>
                                <div className="flex-3"></div>
                                <div className="flex-2 center">寄雲端明細</div>
                                <div className="flex-3">發票產品別</div>
                                <div className="flex-5">公司抬頭</div>
                                <div className="flex-3">銷售類型</div>
                                <div className="flex-3">負責業務</div>
                                <div className="flex-4">備註</div>
                            </li>
                            {
                                dataListFilter.map((x, i) =>
                                    <li className={`lt-row ${(() => {
                                        let isShow = false;
                                        let checkResult = dataListCheckResult.find(y => y.indexKey == GetListIndexKey(x));
                                        if (checkResult != null) {
                                            isShow = checkResult.invoiceNameLengthTotal || checkResult.remarkLength;
                                        }

                                        return isShow ? 'ovv' : ''

                                    })()}`}>
                                        <div className="flex-1 max1 fixed-1">
                                            <input type="checkbox" id={`c41_${GetListIndexKey(x)}`} value={x.isChecked ?? ""} name="c41" onClick={e => dataChange(e, x, "isChecked")} checked={x.isChecked == null ? false : x.isChecked} />
                                            <label htmlFor={`c41_${GetListIndexKey(x)}`}></label>
                                        </div>
                                        <div className="flex-3 fixed-2">
                                            <a href={`/Main?path=SaleOrderEdit&orderId=${x.orderId}`} target="_blank" className={x.spInvState == 2 || x.tradableBalance < 0 ? 'alert' : ''}>{x.orderNumber} </a>
                                        </div>
                                        <div className="flex-3 fixed-3">

                                            <div className="elips-1">{x.customerName}</div>
                                        </div>
                                        <div className="flex-4 fixed-4">
                                            <div className="elips-1">{x.productName2}</div>
                                        </div>
                                        <div className="flex-2">
                                            {
                                                x.availInvState == null ? null :
                                                    <select
                                                        id={`invoiceState_${GetListIndexKey(x)}`}
                                                        className="no-border" onChange={e => dataChange(e, x, "invoiceState")} >
                                                        {
                                                            x.availInvState.map((x2, i2) =>
                                                                <option value={x2.code} selected={x2.code == x.invoiceState ? true : false} >{x2.name}</option>
                                                            )
                                                        }
                                                    </select>
                                            }
                                        </div>
                                        <div className="flex-1">
                                            <input
                                                type="text"
                                                id={`invoiceProject_${GetListIndexKey(x)}`}
                                                value={x.invoiceProject ? x.invoiceProject : ""}
                                                onChange={e => dataChange(e, x, "invoiceProject")} />
                                        </div>
                                        <div className="flex-1">
                                            <input
                                                type="text"
                                                id={`invoiceItem_${GetListIndexKey(x)}`}
                                                value={x.invoiceItem ? x.invoiceItem : ""}
                                                onChange={e => dataChange(e, x, "invoiceItem")} />
                                        </div>
                                        <div className="flex-3 right">
                                            <div className="elips-1">{x.currName} {NumFormart(x.amount == null ? 0 : x.amount)}</div>
                                        </div>
                                        <div className="flex-3">
                                            <input
                                                type="text"
                                                className="invoiceName1"
                                                id={`invoiceName1_${GetListIndexKey(x)}`}
                                                value={x.invoiceName1 ? x.invoiceName1 : ""}
                                                onChange={e => dataChange(e, x, "invoiceName1")} />
                                        </div>
                                        <div className="flex-3">
                                            <input
                                                type="text"
                                                className="invoiceName2"
                                                id={`invoiceName2_${GetListIndexKey(x)}`}
                                                value={x.invoiceName2 ? x.invoiceName2 : ""}
                                                onChange={e => dataChange(e, x, "invoiceName2")} />
                                        </div>
                                        <div className="flex-3">
                                            <input
                                                type="text"
                                                className="invoiceName3"
                                                id={`invoiceName3_${GetListIndexKey(x)}`}
                                                value={x.invoiceName3 ? x.invoiceName3 : ""}
                                                onChange={e => dataChange(e, x, "invoiceName3")} />
                                        </div>
                                        <div className="flex-3">
                                            <input
                                                type="text"
                                                className="invoiceName4"
                                                id={`invoiceName4_${GetListIndexKey(x)}`}
                                                value={x.invoiceName4 ? x.invoiceName4 : ""}
                                                onChange={e => dataChange(e, x, "invoiceName4")} />
                                        </div>
                                        <div className={`flex-3 ${(() => {
                                            let isShow = false;
                                            let checkResult = dataListCheckResult.find(y => y.indexKey == GetListIndexKey(x));
                                            if (checkResult != null) {
                                                isShow = checkResult.invoiceNameLengthTotal;
                                            }

                                            return (
                                                isShow
                                                    ? 'show-help'
                                                    : ''
                                            )
                                        })()
                                            }`}>
                                            <input
                                                type="text"
                                                className="invoiceName5"
                                                id={`invoiceName5_${GetListIndexKey(x)}`}
                                                value={x.invoiceName5 ? x.invoiceName5 : ""}
                                                onChange={e => dataChange(e, x, "invoiceName5")} />
                                            {(() => {
                                                let isShow = false;
                                                let checkResult = dataListCheckResult.find(y => y.indexKey == GetListIndexKey(x));
                                                if (checkResult != null) {
                                                    isShow = checkResult.invoiceNameLengthTotal;
                                                }

                                                return (
                                                    isShow
                                                        ? <span className="help-word">5個欄位加起來不可超過100個字元</span>
                                                        : ''
                                                )

                                            })()}
                                        </div>
                                        <div className="flex-2 center">
                                            <div className="elips-1">{x.isCloudDetail == 1 ? "是" : "否"}</div>
                                        </div>
                                        <div className="flex-3">
                                            <div className="elips-1">{x.productInvoiceName}</div>
                                        </div>
                                        <div className="flex-5">
                                            <div className="elips-1">{x.customerTitle}</div>
                                        </div>
                                        <div className="flex-3">
                                            <div className="elips-1">{x.salesTypeName}</div>
                                        </div>
                                        <div className="flex-3">
                                            <div className="elips-1">{x.personInChargeName}</div>
                                        </div>
                                        <div className={`flex-4 ${(() => {
                                            let isShow = false;
                                            let checkResult = dataListCheckResult.find(y => y.indexKey == GetListIndexKey(x));
                                            if (checkResult != null) {
                                                isShow = checkResult.remarkLength;
                                            }

                                            return (
                                                isShow
                                                    ? 'show-help'
                                                    : ''
                                            )
                                        })()
                                            }`}>
                                            <input
                                                type="text"
                                                className="explanation"
                                                id={`explanation_${GetListIndexKey(x)}`}
                                                value={x.explanation ? x.explanation : ""}
                                                onChange={e => dataChange(e, x, "explanation")} />

                                            {(() => {
                                                let isShow = false;
                                                let checkResult = dataListCheckResult.find(y => y.indexKey == GetListIndexKey(x));
                                                if (checkResult != null) {
                                                    isShow = checkResult.remarkLength;
                                                }

                                                return (
                                                    isShow
                                                        ? <span className="help-word">不可超過100個字元</span>
                                                        : ''
                                                )

                                            })()}
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
                <div className="form-container">
                    <div className="form-item btn-area sp">
                        <div className="left">
                            <a className={`btn btn-border ${isControlDisabled ? 'disabled' : ''}`} onClick={(e) => openModalApply(e, 0)}>
                                <span>檢視發票開立單</span>
                            </a>
                        </div>
                        <div className="right">
                            <a className={`btn btn-border ${isControlDisabled ? 'disabled' : ''}`} onClick={() => OpenModal("modal-3")}>
                                <span>暫存</span>
                            </a>
                            <a className={`btn ${isControlDisabled ? 'disabled' : ''}`} onClick={(e) => openModalApply(e, 1)} id="ApplyBtn">
                                <span>開立發票</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-copyName" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">發票批次品名</h3>
                        <span className="close" onClick={() => CloseModal("modal-copyName")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <ul className="list-table">
                            <li className="lt-row lt-th">
                                <div className="flex-1">訂單編號</div>
                                <div className="flex-1">發票品名 1</div>
                                <div className="flex-1">發票品名 2</div>
                                <div className="flex-1">發票品名 3</div>
                                <div className="flex-1">發票品名 4</div>
                                <div className="flex-1">發票品名 5</div>
                            </li>
                            <li className="lt-row for-modal-4">
                                <div className="flex-1"></div>
                                <div className="flex-1">
                                    <div className="center-item">
                                        <input type="text" value={copyNameMain1} onChange={e => copyNameMainChange(e, 1)} />
                                        <a className="btn btn-border btn-small" onClick={e => copyNameMainCopy(1)}>
                                            <span>批次複製</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="flex-1">
                                    <div className="center-item">
                                        <input type="text" value={copyNameMain2} onChange={e => copyNameMainChange(e, 2)} />
                                        <a className="btn btn-border btn-small" onClick={e => copyNameMainCopy(2)}>
                                            <span>批次複製</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="flex-1">
                                    <div className="center-item">
                                        <input type="text" value={copyNameMain3} onChange={e => copyNameMainChange(e, 3)} />
                                        <a className="btn btn-border btn-small" onClick={e => copyNameMainCopy(3)}>
                                            <span>批次複製</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="flex-1">
                                    <div className="center-item">
                                        <input type="text" value={copyNameMain4} onChange={e => copyNameMainChange(e, 4)} />
                                        <a className="btn btn-border btn-small" onClick={e => copyNameMainCopy(4)}>
                                            <span>批次複製</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="flex-1">
                                    <div className="center-item">
                                        <input type="text" value={copyNameMain5} onChange={e => copyNameMainChange(e, 5)} />
                                        <a className="btn btn-border btn-small" onClick={e => copyNameMainCopy(5)}>
                                            <span>批次複製</span>
                                        </a>
                                    </div>
                                </div>
                            </li>
                            {
                                dataList2.map((x, i) =>
                                    <li className="lt-row">
                                        <div className="flex-1">{x.orderNumber}</div>
                                        <div className="flex-1"><input type="text" value={x.invoiceName1 ? x.invoiceName1 : ""} onChange={e => copyNameChange(e, x, 1)} /></div>
                                        <div className="flex-1"><input type="text" value={x.invoiceName2 ? x.invoiceName2 : ""} onChange={e => copyNameChange(e, x, 2)} /></div>
                                        <div className="flex-1"><input type="text" value={x.invoiceName3 ? x.invoiceName3 : ""} onChange={e => copyNameChange(e, x, 3)} /></div>
                                        <div className="flex-1"><input type="text" value={x.invoiceName4 ? x.invoiceName4 : ""} onChange={e => copyNameChange(e, x, 4)} /></div>
                                        <div className="flex-1"><input type="text" value={x.invoiceName5 ? x.invoiceName5 : ""} onChange={e => copyNameChange(e, xi, 5)} /></div>
                                    </li>
                                )
                            }
                        </ul>

                        <div className="form-container">
                            <div className="form-item btn-area sp">
                                <div className="left"></div>
                                <div className="right">
                                    <a className="btn" onClick={saveCopyName}>
                                        <span>儲存</span>
                                    </a>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div id="modal-apply1" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要產出發票申請單？
                        </div>
                        <div className="btn-holder">
                            <a className={`btn btn-border ${isControlDisabled ? 'disabled' : ''}`} onClick={Capply}> <span>取消</span></a>
                            <a className={`btn ${isControlDisabled ? 'disabled' : ''}`} onClick={() => OutputApply(1)}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-apply2" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            您勾選的項目中含有列管發票 <br />
                            即使這樣也要開發票嗎？
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={Capply}> <span>取消</span></a>
                            <a className="btn" onClick={() => OutputApply(1)}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-3" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要儲存目前資訊？
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-3")}> <span>取消</span></a>
                            <a className="btn" onClick={tmpSave}> <span>確認</span></a>
                        </div>

                    </div>

                </div>
            </div>

            <div id="modal-ok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-ok")}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr == null ? '' : HtmlParse(modalStr)}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-error-callback" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-47"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr == null ? '' : HtmlParse(modalStr)}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error-callback")}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-order" className="modal large" data-modal>
                <OrderDetail orderId={orderId} setReGet={setReGet} />
            </div>
        </div>
    );
};

export default InvoiceItem;
