import React, { useState, useEffect } from "react";
import {
    API_GET_ExchangeRateHome,
    API_GET_ExchangeRateData,
    API_POST_ExchangeRateNewCurrency,
    API_PUT_ExchangeRateData
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, InitJS, MenuSet } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const ExchangeRateQry = () => {
    var history = useHistory();

    const [reGet, setReGet] = useState(false);

    useEffect(() => {
        //把menu顏色置換
        MenuSet("ExchangeRateQry");
        //第一次近來先執行抓取資料
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    useEffect(() => {
        if (reGet) {
            //內頁資料有刪除/修改 重抓
            getData();
        }
        setReGet(false);
    }, [reGet]);

    function getData() {
        API_GET_ExchangeRateHome()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setCompanyArray(data.result.data.companyArray);
                setCurrencyArray(data.result.data.currencyArray);
                setMinY(parseInt(data.result.data.minYm.split('/')[0]));
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        //年度下拉
        var yArr = [];
        for (var i = minY; i <= new Date().getFullYear(); i++) {
            yArr.push(i);
        }
        setYearArray(yArr);
        //月份下拉
        var mArr = [];
        for (var i = 1; i < 13; i++) {
            mArr.push(i);
        }
        setMonArray(mArr);
    }
    const [minY, setMinY] = useState(2022);
    //公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyId, setCompanyId] = useState();
    const [companyIdError, setCompanyIdError] = useState(false);
    //幣別
    const [currencyArray, setCurrencyArray] = useState([{}]);
    const [currencyId, setCurrencyId] = useState();
    //要被鎖定的幣別
    const [lockCurrencyId, setLockCurrency] = useState();
    //年月下拉選單
    const [yearArray, setYearArray] = useState([]);
    const [monArray, setMonArray] = useState([]);
    //查詢年月
    const [qryYear, setQryYear] = useState();
    const [qryMonth, setQryMonth] = useState();
    const [qryYearList, setQryYearList] = useState([]);
    const [qryList, setQryList] = useState([{}]);
    //回傳訊息
    const [modalStr, setModalStr] = useState();
    //設定公司 鎖定該公司幣別
    function changeCompanyId(id, currId) {
        setCompanyId(id);
        setLockCurrency(currId);

    }
    //查詢列表
    function Qry() {
        if (companyId == null) {
            setCompanyIdError(true);
            return false;
        } else {
            setCompanyIdError(false);
        }
        var dt = {
            companyId: companyId,
            currencyId: currencyId,
            year: qryYear,
            month: qryMonth != "" ? qryMonth < 10 ? ("0" + qryMonth) : qryMonth : "",
        };
        console.log(JSON.stringify(dt));
        API_GET_ExchangeRateData(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                var yearDt = [];
                if (data.code == "0000") {
                    var dt = data.result.data.data;
                    for (var i = 0; i < dt.length; i++) {
                        if (yearDt.indexOf(dt[i].time) < 0) {
                            yearDt.push(dt[i].time);
                        }
                    }
                    console.log(data.result.data.data);
                    console.log(yearDt);
                    setQryList(data.result.data.data);
                    setQryYearList(yearDt);
                    OpenModal("modal-qry", 2);


                } else {
                    OpenModal("modal-error", 5);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //新增幣別
    const [addName, setAddName] = useState();
    const [addEName, setAddEName] = useState();
    const [addNameError, setAddNameError] = useState(false);
    const [addENameError, setAddENameError] = useState(false);
    //再次確認新增幣別
    function OpenModalCheck() {
        let hasError = false;
        if (addName == '' || addName == null) {
            setAddNameError(true);
            hasError = true;
        }
        if (addEName == '' || addName == null) {
            setAddENameError(true);
            hasError = true;
        }

        if (hasError)
            return;

        setAddNameError(false);
        setAddENameError(false);
        OpenModal("modal-checkcurrency", 2);
    }

    function AddCurrency() {
        CloseModal("modal-checkcurrency", 2);
        CloseModal("modal-add");
        API_POST_ExchangeRateNewCurrency({
            name: addName,
            iso: addEName,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setModalStr(addName + "已新增完成");
                    OpenModal("modal-ok");
                    setAddName('');
                    setAddEName('');
                    setReGet(true);
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }
    const [exchangeRateId, setExchangeRateId] = useState();
    const [exchangeRateName, setExchangeRateName] = useState();
    const [exchangeRateAmount, setExchangeAmount] = useState();
    const [exchangeRateYearMonth, setExchangeRateYearMonth] = useState();
    //打開修改匯率
    //yearMonth : 2022/11
    //id : 真實資料ID
    //currID : 幣別
    //amount : 目前匯率
    function OpenModalEdit(yearMonth, id, currID, amount) {
        setExchangeRateYearMonth(yearMonth);
        setExchangeRateId(id);
        for (var i = 0; i < currencyArray.length; i++) {
            if (currencyArray[i].id == currID) {
                setExchangeRateName(currencyArray[i].name);
                break;
            }
        }
        setExchangeAmount(amount);
        OpenModal("modal-edit", 3);
    }

    function exchangeAmountChange(e) {
        let tmp = e.target.value.replace(/[^0-9.]/g, '');
        if (!isNaN(tmp)) {
            setExchangeAmount(tmp);

        }
    }

    //修改匯率
    function Edit() {

        if (exchangeRateAmount === undefined || exchangeRateAmount === '' || exchangeRateAmount === null) {
            setModalStr("請輸入匯率");
            OpenModal("modal-error", 5);
            return
        }
        else if (parseFloat(exchangeRateAmount) == 0) {
            setModalStr("匯率不可為零");
            OpenModal("modal-error", 5);
            return
        }


        var dt = {
            exchangeRateId: exchangeRateId,
            amount: exchangeRateAmount
        };
        //console.log(JSON.stringify(dt));
        API_PUT_ExchangeRateData(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //更新前端匯率資料
                    setQryList(function (prev) {
                        return prev.map((item, i) => {
                            if (item.id == exchangeRateId) {
                                item.amount = exchangeRateAmount;
                            }
                            return item;
                        });
                    });
                    CloseModal("modal-edit", 3);
                    CloseModal("modal-check", 4);
                    setModalStr("匯率設定成功");
                    OpenModal("modal-ok", 5);
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error", 5);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">查詢匯率</h3>

                </div>
            </div>
            <div className="container">
                <div className="form-container">
                    <div className={`form-item must ${companyIdError ? 'show-help' : ''}`}>
                        <div className="field">設定公司</div>
                        <div className="value">
                            {
                                companyArray.map(x =>
                                    <div className="item">
                                        <input type="radio" id={`company_${x.id}`} checked={companyId == x.id ? true : false} onClick={() => changeCompanyId(x.id, x.currency)} />
                                        <label htmlFor={`company_${x.id}`}>{x.name}</label>
                                    </div>
                                )
                            }

                        </div>
                        <div className="help-word">
                            請選擇設定公司
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="field">幣別</div>
                        <div className="value">
                            {
                                currencyArray.map(x =>
                                    <div className="item">
                                        <input type="radio" id={`currency_${x.id}`} checked={currencyId == x.id ? true : false} onClick={() => { currencyId == x.id ? setCurrencyId(null) : setCurrencyId(x.id) }} disabled={lockCurrencyId == x.id ? true : false} />
                                        <label htmlFor={`currency_${x.id}`}>{x.name}</label>
                                    </div>
                                )
                            }
                        </div>
                        <div className="help-word">
                            請選擇幣別
                        </div>
                    </div>

                    <div className="form-item">
                        <div className="field">查詢年/ 月</div>
                        <div className="value multi-inline">
                            <div className="item">
                                <select name="qryYear" id="qryYear" onChange={(e) => setQryYear(e.target.value)}>
                                    <option value="" >全部</option>
                                    {
                                        yearArray.map(x =>
                                            <option value={x}>{x}年</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="item">
                                <select name="qryMonth" id="qryMonth" onChange={e => setQryMonth(e.target.value)}>
                                    <option value="">全部</option>
                                    {
                                        monArray.map(x =>
                                            <option value={x}>{x}月</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="item"></div>
                        </div>
                        <div className="help-word">
                            請輸入正確格式
                        </div>
                    </div>


                    <div className="form-item btn-area sp">
                        <div className="left">
                            <a className="btn btn-border" onClick={() => OpenModal("modal-add")}>
                                <span>新增幣別</span>
                            </a>
                        </div>
                        <div className="right">

                            <a className="btn" onClick={Qry}>
                                <span>查詢</span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>

            <div id="modal-qry" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">查詢公司：{
                            companyArray.map(x =>
                                x.id == companyId ? x.name : null
                            )
                        }</h3>
                        <div className="right">

                        </div>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div style={{ "textAlign": "right" }}>
                            幣別：
                            {
                                currencyArray.map(x =>
                                    x.id == lockCurrencyId ? x.name : null
                                )
                            }
                        </div>

                        <ul className="list-table">
                            <li className="lt-row lt-th">
                                <div className="flex-1">年/ 月</div>
                                {
                                    currencyId == null ?
                                        <>
                                            {
                                                currencyArray.map(x =>
                                                    x.id != lockCurrencyId ?
                                                        <div className="flex-1">{x.name}</div> : null
                                                )
                                            }


                                        </>
                                        :
                                        currencyArray.map(x =>
                                            x.id == currencyId ?
                                                <div className="flex-1">
                                                    {x.name}
                                                </div> : null
                                        )
                                }


                            </li>
                            {
                                qryYearList.map((x, i) =>
                                    <li className="lt-row">
                                        <div className="flex-1">{x}</div>
                                        {
                                            currencyId == null ?
                                                <>
                                                    {
                                                        currencyArray.map((x2, i2) =>
                                                            x2.id != lockCurrencyId ?
                                                                <div className="flex-1">
                                                                    {(() => {
                                                                        let amountStr;
                                                                        var selectQry = qryList.find(x3 => x3.time == x && x3.currencyId == x2.id);

                                                                        if (selectQry) {
                                                                            amountStr = selectQry.amount
                                                                        }
                                                                        else {
                                                                            amountStr = "無"
                                                                        }
                                                                        return (
                                                                            <>
                                                                                <div className="elips-1">
                                                                                    {amountStr}
                                                                                </div>
                                                                                {
                                                                                    selectQry && selectQry.monthLock != 1 ?
                                                                                        <div className="btn btn-icon">
                                                                                            <i className="icon icon-30" onClick={() => OpenModalEdit(x, selectQry.id, selectQry.currencyId, selectQry.amount)}></i>
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </>
                                                                        )
                                                                    })()}
                                                                </div>
                                                                : null
                                                        )
                                                    }
                                                </>
                                                :
                                                qryList.map(x3 =>
                                                    x3.time == x && x3.currencyId == currencyId && x3.currencyId != lockCurrencyId ?
                                                        <div className="flex-1">
                                                            <div className="elips-1">
                                                                {x3.amount}
                                                            </div>
                                                            {
                                                                x3.monthLock == 1 ? null :
                                                                    <div className="btn btn-icon">
                                                                        <i className="icon icon-30" onClick={() => OpenModalEdit(x, x3.id, x3.currencyId, x3.amount)}></i>
                                                                    </div>
                                                            }
                                                        </div> : null
                                                )
                                        }

                                    </li>
                                )
                            }
                        </ul>
                    </div>

                </div>

            </div>

            <div id="modal-add" className="modal" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">新增幣別</h3>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-container">
                            <div className="form-item">
                                <div className="field">幣別名稱</div>
                                <div className="value">
                                    <input type="text" value={addName} onChange={(e) => setAddName(e.target.value)} className={addNameError ? 'error' : ''} placeholder="例：新台幣" />
                                </div>
                                <div className="help-word">
                                    請輸入幣別名稱
                                </div>
                            </div>
                            <div className="form-item">
                                <div className="field">幣別英文縮寫</div>
                                <div className="value">
                                    <input type="text" value={addEName} onChange={(e) => setAddEName(e.target.value)} className={addENameError ? 'error' : ''} placeholder="例：NTD" />
                                </div>
                                <div className="help-word">
                                    請輸入幣別名稱
                                </div>
                            </div>
                            <div className="form-item btn-area">
                                <a className="btn btn-border" data-close> <span>取消</span></a>
                                <a className="btn" onClick={OpenModalCheck} > <span>確認</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-edit" className="modal" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">修改匯率</h3>
                        <span className="close" onClick={() => CloseModal("modal-edit", 3)}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-container">
                            <div className="form-item no-field">
                                <div className="value multi-inline">
                                    <div className="item flex-3">
                                        <input type="text" value={`1 ${exchangeRateName}`} disabled="disabled" />
                                    </div>
                                    <div className="item flex-auto">兌換</div>
                                    <div className="item flex-3">
                                        <input
                                            type="text"
                                            value={exchangeRateAmount}
                                            onClick={(e) => e.target.select()}
                                            onChange={(e) => exchangeAmountChange(e)} />
                                    </div>
                                    <div className="item flex-auto">
                                        {
                                            currencyArray.map(x =>
                                                x.id == lockCurrencyId ? x.name : null
                                            )
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="form-item btn-area center">
                                <a className="btn btn-border" onClick={() => CloseModal("modal-edit", 3)}> <span>取消</span></a>
                                <a className="btn" onClick={() => OpenModal("modal-check", 4)}> <span>確認</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-checkcurrency" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要新增 「{addName}」幣別? <br />
                            新增後將不可刪除!!
                        </div>
                        <div className="btn-holder sp">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-checkcurrency", 2)}> <span>取消</span></a>
                            <a className="btn" onClick={AddCurrency}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-check" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {exchangeRateYearMonth}
                            <br />
                            確定 1 {exchangeRateName}
                            兌換 {exchangeRateAmount}
                            {
                                currencyArray.map(x =>
                                    x.id == lockCurrencyId ? x.name : null
                                )
                            }
                            ?
                        </div>
                        <div className="btn-holder sp">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-check", 4)}> <span>取消</span></a>
                            <a className="btn" onClick={Edit}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-ok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-ok", 5)}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-47"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">

                            <a className="btn" onClick={() => CloseModal("modal-error", 5)}> <span>確認</span></a>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default ExchangeRateQry;
