import React, { useState, useEffect, useRef } from 'react';

const CustomerSelectComponent = ({ selectTitle, getDataHandle, selectOptionConfig, onElementChange, resetState, shareElementName, elementNameField, fromItemClass = '', isDisabled = false }) => {
    const arrayRef = useRef();
    const [element, setElement] = useState("");
    const [elementArray, setElementArray] = useState([]);
    const [elementComplete, setElementComplete] = useState(false);
    const [isSetElemented, setIsSetElemented] = useState(false);

    const elementChange = (value) => {
        setElement(value);
        setElementComplete(true);
        setIsSetElemented(false);
    };

    const selectOptionClickHandle = (element, value) => {
        if (onElementChange) {
            onElementChange(element);
        }
        else {
            setElement(value);
        }

        setIsSetElemented(true);
        if (arrayRef.current) {
            arrayRef.current.classList.remove("show-datalist");
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (arrayRef.current && !arrayRef.current.contains(event.target)) {
                arrayRef.current.classList.remove("show-datalist");
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };


    }, []);

    useEffect(() => {
        if (resetState) {
            setElement('');
            setElementArray([]);
            setElementComplete(false);
            setIsSetElemented(false);
        }
    }, [resetState])

    useEffect(() => {
        if (shareElementName) {
            setElement(shareElementName);
            setIsSetElemented(true);
        }
    }, [shareElementName])

    useEffect(() => {
        if (isSetElemented) return;

        if (element == "" && elementComplete == false) {
            setElementArray([]);
            return;
        }

        setElementComplete(false);

        getDataHandle(element, setElementArray);
    }, [element, elementComplete]);


    useEffect(() => {
        if (elementArray.length == 0) {
            if (arrayRef.current) {
                arrayRef.current.classList.remove("show-datalist")
            }
        }
        else {
            if (arrayRef.current) {
                arrayRef.current.classList.add("show-datalist")
            }
        }
    }, [elementArray])

    return (
        <div className={`form-item has-datalist ${fromItemClass}`}>
            <div className="field">{selectTitle}</div>
            <div className="value">
                <input type="text" value={element} disabled={isDisabled}
                    onInput={(e) => {
                        if (e.target.composing) {
                            setElement(e.target.value)
                        }
                        else {
                            elementChange(e.target.value);
                        }
                    }}
                    onCompositionStart={(e) => {
                        e.target.composing = true;
                    }}
                    onCompositionEnd={(e) => {
                        if (!e.target.composing) return;
                        e.target.composing = false;
                        elementChange(e.target.value);
                    }} />

                <ul className="datalist scrollbar" ref={arrayRef}>
                    {selectOptionConfig == null ? elementArray.map((x) => (
                        <li data-value={x[elementNameField]} onClick={() => selectOptionClickHandle(x, x[elementNameField])}>
                            <span className="flex-10 bold">{x[elementNameField]}</span>
                        </li>
                    )) : selectOptionConfig(elementArray, selectOptionClickHandle)}
                </ul>
            </div>
            <span className="help-word">�п�J���T�榡</span>
        </div>
    );
}

export default CustomerSelectComponent;
