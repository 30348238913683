import '../css/style.css';
import Loginform from '../components/Login/loginform';
import Cpwdform from '../components/Login/cpwdform';
import Authenticator from '../components/Login/authenticator';
import Error from '../components/Login/error';
import {
    API_HOST,
    OKTA_HOST,
    IS_TEST
} from "../global/constants";
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from "react-intl"; //多國語言套件

function login() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get('path');
    const apiHost = API_HOST;
    const oktaHost = OKTA_HOST + '/login';
    if (apiHost != "https://demo-digicentreacnt-api.summit-edu.com.tw/api" && !isTest){
        window.location.href = oktaHost;
        return;
    } else {
        useEffect(() => {
            document.getElementById('root').classList.add('center');
            document.body.classList.remove('dashboard');
            document.body.classList.add('login');

            //檢查是否有登入過(完整資料) 有的話導頁回去dashboard ,有缺的話全部清除
            const islogin = localStorage.getItem('islogin');
            const user = localStorage.getItem('user');
            //console.log(token, user, username, userID, menulist, authctrl, notify);
            if (islogin == "1") {
                //有登入過還是要進變更密碼 不導回
                if (path != null && localStorage.getItem('validRemainDays') != null && localStorage.getItem('validRemainDays') <= 7 && path.toLowerCase() == 'cpwdform') {

                } else {
                    history.push('/Dashboard');
                }

            } else {
                if (path != 'authenticator' && path != 'cpwdform' && path != 'error' && user == null) {
                    localStorage.clear();
                }
            }
            //console.log(process.env)
        }, []);

        return (
            <div className="full center">
                <div className="login-box">
                    <div className="title">
                        <i className="icon icon-31"></i>
                        <h1 className="fz-hero">
                            <FormattedMessage id="systemName" /> {/*系統名稱 */}
                        </h1>
                    </div>

                    <div className="form-container">

                        {(() => {
                            if (path == null) {
                                return <Loginform />;
                            } else {

                                switch (path.toLowerCase()) {
                                    case 'authenticator':
                                        return <Authenticator />;
                                    case 'cpwdform':
                                        return <Cpwdform />;
                                    case 'error':
                                        return <Error />;
                                    default:
                                        return <Loginform />;
                                }
                            }
                        })()}

                    </div>
                </div>
            </div>
        );
    }
}
export default login;
