import React, { useState, useEffect } from "react";

import { 
    API_DOWNLOAD_FILE,
    API_GET_PrepassVendorRecord,
    API_GET_PrepassVendorPreRecord,
} from "../../global/constants";
import { NumFormart, InitJS } from "../../global/ToolUtil";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const Prepaid_SupplierList = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        //把menu顏色置換
        document.getElementById("menuitem-Prepaid_CustomerQry").classList.add("active");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    function returnView() {
        history.push(
            "/Main?path=Prepaid_SupplierView&type=" +
            queryParams.get("type") +
            "&venId=" +
            queryParams.get("venId")
        );
    }
    const [typeName, setTypeName] = useState();
    const [type, setType] = useState();
    const [dataid, setDataid] = useState();

    const [venId, setVendorId] = useState();
    const [dataList, setDataList] = useState([{}]);

    const [vendorname, setVendorname] = useState(queryParams.get("vendorname"));

    function getData() {
        setVendorId(queryParams.get("venId"));
        var tmpData = [];

        if (queryParams.get("type") == "1") {
            setType("1");
            setTypeName("儲值金");
            //path = API_GET_PrepassVendorPreRecord + '?vendorprepaidid=' + queryParams.get('dataid');
            API_GET_PrepassVendorPreRecord({
                vendorPrepaidId: queryParams.get("dataid"),
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.result.data.length > 0) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            tmpData.push({
                                createdTime: data.result.data[i].createdTimeStr, //建立日期
                                venAmtHisAmtAfter: NumFormart(
                                    data.result.data[i].venAmtHisAmtAfter
                                ), //結存金額
                                venAmtHisAmtBefore: NumFormart(
                                    data.result.data[i].venAmtHisAmtBefore
                                ), //餘額
                                venAmtHisAmtDec: NumFormart(
                                    data.result.data[i].venAmtHisAmtDec
                                ), //減少金額
                                venAmtHisAmtInc: NumFormart(
                                    data.result.data[i].venAmtHisAmtInc
                                ), //新增金額
                                venAmtHisAmtCert: data.result.data[i].venAmtHisAmtCert,
                                venAmtHisAmtDate: data.result.data[i].venAmtHisAmtDate,
                                venAmtHisAmtExRate: data.result.data[i].venAmtHisAmtExRate, //匯率
                                venAmtHisAmtRem: data.result.data[i].venAmtHisAmtRem, //說明
                                venAmtHisAmtType: data.result.data[i].venAmtHisAmtType,
                                venAmtHisID: data.result.data[i].venAmtHisID,
                                venAmtHisAmtCurName: data.result.data[i].venAmtHisAmtCurName,
                                venAmtID: data.result.data[i].venAmtID,
                                fileName: data.result.data[i].fileName,
                                venAmtHisAmtCertFile: data.result.data[i].venAmtHisAmtCertFile,
                            });
                        }
                        setDataList(tmpData);
                    }
                })
                .catch((err) => {
                    
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else if (queryParams.get("type") == "2") {
            setType("2");
            setTypeName("保證金");
            ////console.log("vendorAssuId", queryParams.get("dataid"));
            API_GET_PrepassVendorRecord({
                vendorAssuId: queryParams.get("dataid"),
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.result.data.length > 0) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            tmpData.push({
                                createdTime: data.result.data[i].createdTimeStr, //建立日期
                                venAmtHisAmtAfter: NumFormart(
                                    data.result.data[i].venAmtHisAmtAfter
                                ), //結存金額
                                venAmtHisAmtBefore: NumFormart(
                                    data.result.data[i].venAmtHisAmtBefore
                                ), //餘額
                                venAmtHisAmtDec: NumFormart(
                                    data.result.data[i].venAmtHisAmtDec
                                ), //減少金額
                                venAmtHisAmtInc: NumFormart(
                                    data.result.data[i].venAmtHisAmtInc
                                ), //新增金額
                                venAmtHisAmtCert: data.result.data[i].venAmtHisAmtCert,
                                venAmtHisAmtDate: data.result.data[i].venAmtHisAmtDate,
                                venAmtHisAmtExRate: data.result.data[i].venAmtHisAmtExRate, //匯率
                                venAmtHisAmtRem: data.result.data[i].venAmtHisAmtRem, //說明
                                venAmtHisAmtType: data.result.data[i].venAmtHisAmtType,
                                venAmtHisID: data.result.data[i].venAmtHisID,
                                venAmtHisAmtCurName: data.result.data[i].venAmtHisAmtCurName,
                                venAmtID: data.result.data[i].venAmtID,
                                fileName: data.result.data[i].fileName,
                                venAmtHisAmtCertFile: data.result.data[i].venAmtHisAmtCertFile,
                            });
                        }
                        setDataList(tmpData);
                    }
                })
                .catch((err) => {
                    
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }


    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container full">
                    <h3 className="fz-B has-border">
                        {vendorname}
                        {typeName}紀錄
                    </h3>
                </div>
            </div>
            <div className="container full">
                <ul className="list-table">
                    <li className="lt-row lt-th">
                        <div className="flex-3">日期</div>
                        <div className="flex-3">儲值金餘額</div>
                        <div className="flex-3">新增金額</div>
                        <div className="flex-3">減少金額</div>
                        <div className="flex-2">匯率</div>
                        <div className="flex-3">結存金額</div>
                        <div className="flex-3">憑證</div>
                        <div className="flex-3">說明</div>
                    </li>
                    {dataList.map((x) =>
                        x.venAmtID != null ? (
                            <li className="lt-row ovv">
                                <div className="flex-3">{x.createdTime}</div>
                                <div className="flex-3">
                                    {x.venAmtHisAmtBefore == "" ? 0 : x.venAmtHisAmtBefore}
                                </div>
                                <div className="flex-3">
                                    {x.venAmtHisAmtCurName}{" "}
                                    {x.venAmtHisAmtInc == "" ? 0 : x.venAmtHisAmtInc}
                                </div>
                                <div className="flex-3">
                                    NTD {x.venAmtHisAmtDec == "" ? 0 : x.venAmtHisAmtDec}
                                </div>
                                <div className="flex-2">1 : {x.venAmtHisAmtExRate}</div>
                                <div className="flex-3">
                                    NTD {x.venAmtHisAmtAfter == "" ? 0 : x.venAmtHisAmtAfter}
                                </div>
                                <div className="flex-3">
                                    <a href={`${API_DOWNLOAD_FILE}${parseInt(x.venAmtHisAmtCertFile)}`}>
                                        {x.fileName}
                                    </a>
                                </div>
                                <div className="flex-3 tip" data-tip={x.venAmtHisAmtRem}>
                                    <p>{x.venAmtHisAmtRem}</p>
                                </div>
                            </li>
                        ) : null
                    )}
                </ul>

                <div className="form-item btn-area sp">
                    <div className="left"></div>
                    <div className="right">
                        <a className="btn" onClick={returnView}>
                            <span>回上一頁</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Prepaid_SupplierList;
