import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_InvoiceCtrlHome,
    API_GET_AdNameList,
    API_GET_Customer3Name,
    API_GET_CurrencyComExchangeRate,
    API_GET_OrderNumber
} from "../../global/constants";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, InitJS, MenuSet, SetOnbeforeunload } from '../../global/ToolUtil';
import { CheckResponseCode } from '../../global/ResponseUtil';
const InvoiceVoid = () => {
    var history = useHistory();
    const [reGet, setReGet] = useState(false);

    useEffect(() => {
        //把menu顏色置換
        MenuSet("StatementNew");
        getdata();
        //預設為台灣果核
        setCompanyId(0);
        const timeout = setTimeout(() => {
            InitJS();
            //SetOnbeforeunload();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    var moment = require("moment");

    function getdata() {
        API_GET_InvoiceCtrlHome({
            companyId: companyId
        })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                //營收公司
                setCompanyArray(data.result.data.companyArray);
                //產品別
                //setProductTypeArray(data.result.data.data.productTypeArray);
                //幣別
                setCurrencyBaseList(data.result.data.currencyLst);
                setMonthSettleDaysArray(data.result.data.yeatMonthList);
                setIncomeMonth(data.result.data.incomeMonth);
            })
            .catch((err) => {
                //
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //幣別
    const [currencyBaseList, setCurrencyBaseList] = useState([{}]);
    //營收公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyError, setCompanyError] = useState(false);
    const [companyId, setCompanyId] = useState();

    useEffect(() => {
        setReGet(true);
    }, [companyId])

    useEffect(() => {
        if (reGet == true) {
            clearData();
            getdata();
            setReGet(false);
        }
    }, [reGet])

    //客戶別
    const [custID, setCustID] = useState();
    const [custName, setCustName] = useState("");
    const [custNameArray, setCustNameArray] = useState([{}]);
    function custNameChange(e) {
        setCustName(e.target.value);
        getCustomer3Name(1);
    }
    //客戶抬頭
    const [custTitle, setCustTitle] = useState("");
    const [custTitleArray, setCustomerTitleArray] = useState([{}]);
    function custTitleChange(e) {
        setCustTitle(e.target.value);
        getCustomer3Name(2);
    }
    //訂單編號
    const [orderNumber, setOrderNumber] = useState();
    const [orderNumberArray, setOrderNumberArray] = useState([{}]);
    function orderNumberChange(e) {
        setOrderNumber(e.target.value);
        //呼叫共用K
        getOrderNumber();
    }
    //請購單號 共用K
    function getOrderNumber() {
        API_GET_OrderNumber({
            input: document.getElementById("orderNumberText").value,
            companyId: companyId
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setOrderNumberArray(data.result.data);
            })
            .catch((err) => {

            });
    }
    const [custTaxID, setCustTaxID] = useState("");
    const [custTaxIDArray, setCustomerTaxArray] = useState([{}]);
    function custTaxIDChange(e) {
        setCustTaxID(e.target.value);
        getCustomer3Name(3);
    }


    //入賬月份
    const [monthSettleDaysArray, setMonthSettleDaysArray] = useState([]);


    const [incomeMonth, setIncomeMonth] = useState();
    const [currencyId, setCurrencyId] = useState(5);
    //modal文字
    const [modalStr, setModalStr] = useState();

    //重新取得 客戶別 客戶別Title 客戶統編 List
    function getCustomer3Name(index) {
        var dt = [];
        if (index == 1) {
            dt = {
                customerName: document.getElementById("customerNameText").value
            }
        }
        else if (index == 2) {
            dt = {
                customerTitle: document.getElementById("customerTitleText").value
            }

        }
        else if (index == 3) {
            dt = {
                customerTaxId: document.getElementById("customerTaxIdText").value,
            }
        }
        API_GET_Customer3Name(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                var dataName = [];
                var dataTitle = [];
                var dataTaxId = [];

                if (data.result != null && data.result.data != null) {
                    if (index == 1) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataName.length; j++) {
                                if (dataName[j].custName == data.result.data[i].custName) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataName.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustNameArray(dataName);
                    } else if (index == 2) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTitle.length; j++) {
                                if (dataTitle[j].custTitle == data.result.data[i].custTitle) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTitle.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxId: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerTitleArray(dataTitle);
                    } else if (index == 3) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTaxId.length; j++) {
                                if (dataTaxId[j].custTaxID == data.result.data[i].custTaxID) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTaxId.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerTaxArray(dataTaxId);
                    }
                }
            })
            .catch((err) => {

            });
    }


    const [currencyComExchangeRateArray, setCurrencyComExchangeRateArray] = useState([{}]);

    useEffect(() => {
        API_GET_CurrencyComExchangeRate({
            companyId: companyId,
            ym: incomeMonth
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push('/');
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setCurrencyComExchangeRateArray(data.result.data);
                var arr = data.result.data;
                if (arr.length > 0) {
                    for (var i = 0; i < arr; i++) {
                        if (arr[i].exRateRate == 1) {
                            setCurrencyId(arr[i].exCurrID);
                        }
                    }
                }
            })
    }, [companyId, incomeMonth])

    function goItem() {
        var path = "/Main?path=InvoiceVoidItem&companyId=" + companyId;
        if (incomeMonth != undefined) {
            path += "&incomeMonth=" + incomeMonth;
        }
        if (custName != null) {
            path += "&customerName=" + custName;
        }
        if (custTitle != null) {
            path += "&customerTitle=" + custTitle;
        }
        if (custTaxID != null) {
            path += "&customerTaxId=" + custTaxID;
        }


        if (orderNumber != null) {
            path += "&orderNumber=" + orderNumber;
        }

        history.push(path);
    }

    function clearData() {
        setCurrencyBaseList([]);
        setCustID("");
        setCustName("");
        setCustNameArray([]);
        setCustTitle("");
        setCustomerTitleArray([]);
        setCustTaxID("");
        setCustomerTaxArray([]);
        setMonthSettleDaysArray([]);
        setIncomeMonth("");
        setOrderNumber("");
        setOrderNumber([]);
    }

    useEffect(()=>{

        console.log(incomeMonth,monthSettleDaysArray)
    },[monthSettleDaysArray,incomeMonth])
    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">發票作廢</h3>
                </div>
            </div>
            <div className="container">
                <div className="form-container">
                    <div className={`form-item must ${companyError ? 'show-help' : ''}`}>
                        <div className="field">營收公司</div>
                        <div className="value">
                            {
                                companyArray.map(x =>
                                    <div>
                                        <input type="radio" id={`company_${x.id}`} name="company" value={x.id} onClick={e => setCompanyId(x.id)} checked={companyId == x.id ? true : false} />
                                        <label htmlFor={`company_${x.id}`}>{x.name}</label>
                                    </div>
                                )
                            }
                        </div>
                        <span className="help-word">
                            請選擇營收公司
                        </span>
                    </div>
                    <div className="form-item half must">
                        <div className="field">入帳月份</div>
                        <div className="value">
                            <select name="" id="" onChange={e => setIncomeMonth(e.target.value)}>
                                {
                                    monthSettleDaysArray.map(x =>
                                        <option value={x} selected={x == incomeMonth ? true : false}> {x} </option>
                                    )
                                }
                            </select>
                        </div>
                        <div className="help-word">
                            請輸入訂單編號
                        </div>
                    </div>
                    <div className="form-item half has-datalist" data-datalist>
                        <div className="field">客戶別</div>
                        <div className="value">
                            <input
                                id="customerNameText"
                                type="text"
                                value={custName}
                                onChange={custNameChange}
                            />
                            <ul className="datalist scrollbar">
                                {custNameArray.map((x) => (
                                    <li
                                        data-value={x.custName} onClick={(e) => { setCustName(x.custName), setCustTitle(x.custTitle), setCustTaxID(x.custTaxID), setCustID(x.custID); }}>
                                        <span className="flex-4 bold">{x.custName}</span>
                                        <span className="flex-3">{x.custTitle}</span>
                                        <span className="flex-3">{x.custTaxID}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item half has-datalist" data-datalist>
                        <div className="field">訂單編號</div>
                        <div className="value">
                            <input type="text" id="orderNumberText" value={orderNumber} onChange={orderNumberChange} />
                            <ul className="datalist scrollbar">
                                {orderNumberArray.map((x) => (
                                    <li data-value={x.name} onClick={(e) => setOrderNumber(x.name)} >
                                        <span className="flex-10 bold">{x.name}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>



                    <div className="form-item half  has-datalist" data-datalist>
                        <div className="field">公司抬頭</div>
                        <div className="value">
                            <input id="customerTitleText" type="text" onChange={custTitleChange} value={custTitle} />
                            <ul className="datalist scrollbar">
                                {custTitleArray.map((x) => (
                                    <li data-value={x.custTitle} onClick={(e) => { setCustName(x.custName), setCustTitle(x.custTitle), setCustTaxID(x.custTaxId); }}>
                                        <span className="flex-10 bold">{x.custTitle}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item  half  has-datalist" data-datalist>
                        <div className="field">公司統編/ 編號</div>
                        <div className="value">
                            <input id="customerTaxIdText" type="text" onChange={custTaxIDChange} value={custTaxID} />
                            <ul className="datalist scrollbar">
                                {custTaxIDArray.map((x) => (
                                    <li data-value={x.custTaxID} onClick={(e) => { setCustName(x.custName), setCustTitle(x.custTitle), setCustTaxID(x.custTaxId); }}>
                                        <span className="flex-10 bold">{x.custName}</span>

                                        {/*<span className="flex-2">12345678</span>*/}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <hr />

                    <div className="form-item">
                        <div className="field">目前匯率</div>
                        <div className="value multi-inline impwarp">
                            {
                                currencyBaseList.map(x =>
                                    x.id != currencyId ? //不是自己
                                        <div className="box currency-box">
                                            <span>{x.name}</span>
                                            <div className="info currency-item">
                                                <span>

                                                    {
                                                        currencyComExchangeRateArray != null ?
                                                            currencyComExchangeRateArray.find(obj => { return obj.exCurrID == x.id }) != null ?
                                                                currencyComExchangeRateArray.find(obj => { return obj.exCurrID == x.id }).exRateRate
                                                                : '' :
                                                            null
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        : null
                                )
                            }
                        </div>
                    </div>
                    <div className="form-item btn-area sp">
                        <div className="left">

                        </div>
                        <div className="right">
                            <a className="btn" onClick={goItem}>
                                <span>顯示項目</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceVoid;
