import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_Account_Create,
    API_Auth_GetQrcode,
    API_GET_UserList,
    API_Account_ResetPWD,
    API_Account_UpdateStatus,
    API_Account_DEL,
} from "../../global/constants";
import UserItem from "./UserItem";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const AddUser = () => {
    const history = useHistory();
    const [userList, setUserList] = useState([]);

    function userListChange() {
        API_GET_UserList({
            UserName: document.getElementById("qryUserNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setUserList(data.result);
            })

    }
    useEffect(() => {
        //第一次近來先執行抓取userList
        userListChange();
    }, []);
    var currentModal;
    let currentLevel = 1;

    const [userName, setUserName] = useState("");
    function userNameChange(e) {
        setUserName(e.target.value);
    }
    const [qryUserName, setQryUserName] = useState();
    function qryUserNameChange(e) {
        setQryUserName(e.target.value);
        userListChange();
    }
    const [userNo, setUserNo] = useState("");
    function userNoChange(e) {
        setUserNo(e.target.value);
    }
    const [addUserName, setAddUserName] = useState("");
    function adduserNameChange(e) {
        setAddUserName(e.target.value);
    }
    const [addEmail, setAddEmail] = useState("");

    const [emailError, setEmailError] = useState(false);
    const [addUserNo, setAddUserNo] = useState("");
    function adduserNoChange(e) {
        setAddUserNo(e.target.value);
    }
    const [userpwd, setUserPwd] = useState("");

    const showpassword = () => {
        if (document.getElementById("m1Password").type === "password") {
            document.getElementById("m1Password").type = "text";
            document.getElementById("m1eye").classList.add("active");
        } else {
            document.getElementById("m1Password").type = "password";
            document.getElementById("m1eye").classList.remove("active");
        }

        if (document.getElementById("m2Password").type === "password") {
            document.getElementById("m2Password").type = "text";
            document.getElementById("m2eye").classList.add("active");
        } else {
            document.getElementById("m2Password").type = "password";
            document.getElementById("m2eye").classList.remove("active");
        }
    };
    function copyPwd() {
        var pType = 0;
        //如果該型態還是密碼,先換成text
        if (document.getElementById("m1Password").type === "password") {
            document.getElementById("m1Password").type = "text";
            pType = 1;
        }
        //先移除readonly 不然無法複製
        document.getElementById("m1Password").removeAttribute = "readonly";
        const inputText = document.querySelector("#m1Password");
        ////console.log(inputText);
        inputText.select();
        document.execCommand("copy");
        //複製好了 補回readonly
        document.getElementById("m1Password").setAttribute("readonly", true);
        //如果有替換成1 代表要把他換回密碼型態
        if (pType == 1) {
            document.getElementById("m1Password").type = "password";
        }
    }
    function copyPwd2() {
        var pType = 0;
        //如果該型態還是密碼,先換成text
        if (document.getElementById("m2Password").type === "password") {
            document.getElementById("m2Password").type = "text";
            pType = 1;
        }
        //先移除readonly 不然無法複製
        document.getElementById("m2Password").removeAttribute = "readonly";
        const inputText = document.querySelector("#m2Password");
        ////console.log(inputText);
        inputText.select();
        document.execCommand("copy");
        //複製好了 補回readonly
        document.getElementById("m2Password").setAttribute("readonly", true);
        //如果有替換成1 代表要把他換回密碼型態
        if (pType == 1) {
            document.getElementById("m2Password").type = "password";
        }
    }
    function getQrcode(index, get_userNo) {
        API_Auth_GetQrcode({
            account: get_userNo == "" ? userNo : get_userNo,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                if (index == 1) {
                    document.getElementById("addQrcode").innerHTML =
                        data.result.qrCodeText;
                } else if (index == 2) {
                    document.getElementById("resetQrcode").innerHTML =
                        data.result.qrCodeText;
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
    }
    function checkemail(mail) {
        var reg = new RegExp(/^\S+@\S+\.\S{2,}$/);
        if (!reg.test(mail)) {
            return false;
        } else {
            return true;
        }
    }
    function AddUserBtn(e) {
        if (addUserName == "" || addUserNo == "") {
            alert("請輸入使用者帳號與姓名");
            return false;
        }
        //如果有輸入email  判斷格式
        if (addEmail != '') {
            var result = checkemail(addEmail);
            if (result == true) {
                setEmailError(false);
            } else {
                setEmailError(true);
                return;
            }
        }
        var dt = {

            UserName: addUserName,
            UserNo: addUserNo,
            UserEmail: addEmail
        };
        //console.log(dt);

        API_Account_Create(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    var target = document.getElementById("modal-1");
                    var layerClass = "layer-3";
                    var showLayerClass = "has-layer-3";
                    currentModal = target;
                    target.classList.add("active");
                    target.classList.add(layerClass);
                    document.body.classList.add("has-modal", showLayerClass);
                    //更新密碼
                    setUserPwd(data.result.userPwd);
                    //從抓列表
                    userListChange();
                    //抓取QRCODE
                    getQrcode(1, addUserNo);
                }
                else {
                    alert("建立失敗:(" + data.code + ")" + data.message);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
    }

    function resetPWD() {
        var userID = document.getElementById("resetUserID").value;
        if (userID != "") {
            API_Account_ResetPWD({
                id: userID,
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        var targetNow = document.getElementById("modal-3");
                        var target = document.getElementById("modal-2");
                        currentLevel = currentLevel + 1;
                        var layerClass = "layer-4";
                        var showLayerClass = "has-layer-4";
                        currentModal = target;
                        target.classList.add("active");
                        target.classList.add(layerClass);
                        targetNow.classList.remove("active");
                        document.body.classList.add("has-modal", showLayerClass);
                        setUserName(data.result.userName);
                        setUserNo(data.result.userNo);
                        setUserPwd(data.result.userPwd);
                        //抓取QRCODE
                        getQrcode(2, data.result.userNo);
                    }
                    else {
                        alert("重設失敗:(" + data.code + ")" + data.message);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        history.push("/");
                    }
                });
        }
    }
    function updateStatus() {
        var UpuserID = document.getElementById("updateStatusUserID").value;
        if (UpuserID != "") {
            API_Account_UpdateStatus({
                UserId: UpuserID,
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        userListChange();
                    }
                    else {
                        alert("更新失敗:(" + data.code + ")" + data.message);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        history.push("/");
                    }
                });
        }
    }
    function delUser() {
        var DeluserID = document.getElementById("delUserID").value;
        if (DeluserID != "") {
            API_Account_DEL({
                UserId: DeluserID,
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        userListChange();
                    }
                    else {
                        alert("刪除失敗:(" + data.code + ")" + data.message);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        history.push("/");
                    }
                });
        }
    }

    return (
        <>
            <div id="modal-add" className="modal large" data-modal data-mid="0.1.1.1">
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">新增/ 管理使用人員帳號</h3>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <form action="">
                            <h6 className="fz-D">新增使用者</h6>
                            <div className="form-container inline">
                                <div className="form-item">
                                    <div className="field">帳號</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            id="AddUserNo"
                                            value={addUserNo}
                                            onChange={adduserNoChange}
                                        />
                                    </div>
                                    {/*<span className="help-word"> 加上 show-help 樣式來呈現，必須包含8的英文數字大小寫  </span>*/}
                                </div>
                                <div className="form-item">
                                    <div className="field">姓名</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            id="AddUserName"
                                            value={addUserName}
                                            onChange={adduserNameChange}
                                        />
                                    </div>
                                    {/*<span className="help-word">加上 error 樣式來呈現</span>*/}
                                </div>
                                <div className={`form-item ${emailError ? 'show-help' : ''}`}>
                                    <div className="field">email</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            id="AddEmail"
                                            value={addEmail}
                                            onChange={e => setAddEmail(e.target.value)}
                                        />
                                    </div>
                                    <span className="help-word">格式錯誤</span>
                                </div>
                                <div className="form-item btn-area">
                                    <a
                                        className="btn btn-small"
                                        data-openmodal="modal-1"
                                        onClick={AddUserBtn}
                                    >
                                        <span>確定新增</span>
                                    </a>
                                </div>
                                <hr />
                                <input
                                    type="search"
                                    style={{ width: "30%" }}
                                    id="qryUserNameText"
                                    value={qryUserName}
                                    onChange={qryUserNameChange}
                                    placeholder="搜尋帳號"
                                />
                                <br />
                                <br />
                                <ul className="list-table" style={{ width: "100%" }}>
                                    <li className="lt-row lt-th">
                                        <div className="flex-4">帳號</div>
                                        <div className="flex-4">姓名</div>
                                        <div className="flex-4">密碼</div>
                                        <div className="flex-5 fn"></div>
                                    </li>
                                    {userList.map((x) => (
                                        <UserItem
                                            userID={x.userID}
                                            userName={x.userName}
                                            userNo={x.userNo}
                                            userPwd={x.userPwd}
                                            userInvalid={x.userInvalid}
                                        />
                                    ))}
                                </ul>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div id="modal-1" className="modal small alert" data-modal data-mid="0.1.1.2">
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="title-holder">已完成新增使用者：{addUserName}</div>
                        <div className="content-holder">
                            <ul className="form-container">
                                <li className="form-item read-only small-field">
                                    <div className="field">帳號</div>
                                    <div className="value">
                                        <input type="text" value={addUserNo} readonly="readonly" />
                                    </div>
                                </li>
                                <li className="form-item read-only small-field">
                                    <div className="field">密碼</div>
                                    <div className="value multi-inline">
                                        <div className="password" data-togglepassword>
                                            <input type="password" value={userpwd} id="m1Password" />
                                            <i className="eye" onClick={showpassword} id="m1eye"></i>
                                        </div>
                                        <div className="btn btn-small btn-border" onClick={copyPwd}>
                                            <span>複製密碼</span>
                                        </div>
                                    </div>
                                </li>
                                <li className="form-item col">
                                    <div className="field">帳號專屬 QR code</div>
                                    <div className="value" id="addQrcode">
                                        {/*<img src="./images/fake/qrcode.png" alt=""/>*/}
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="btn-holder">
                            <button className="btn" data-close>
                                <span>關閉</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-2" className="modal small alert" data-modal data-mid="0.1.1.3">
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="title-holder">已重置使用者密碼</div>
                        <div className="content-holder">
                            <ul className="form-container">
                                <li className="form-item read-only small-field">
                                    <div className="field">帳號</div>
                                    <div className="value">
                                        <input type="text" value={userNo} readonly="readonly" />
                                    </div>
                                </li>
                                <li className="form-item read-only small-field">
                                    <div className="field">密碼</div>
                                    <div className="value multi-inline">
                                        <div className="password" data-togglepassword>
                                            <input type="password" value={userpwd} id="m2Password" />
                                            <i className="eye" onClick={showpassword} id="m2eye"></i>
                                        </div>
                                        <div
                                            className="btn btn-small btn-border"
                                            onClick={copyPwd2}
                                        >
                                            <span>複製密碼</span>
                                        </div>
                                    </div>
                                </li>
                                <li className="form-item col">
                                    <div className="field">帳號專屬 QR code</div>
                                    <div className="value" id="resetQrcode"></div>
                                </li>
                            </ul>
                        </div>
                        <div className="btn-holder">
                            <button className="btn" data-close="1">
                                {" "}
                                <span>確認</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div
                id="modal-3"
                className="modal small alert"
                data-modal
                data-mid="0.1.0.4"
            >
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要重置【 <span id="resetName"></span> 】的密碼？
                            <input type="hidden" id="resetUserID" value="" />
                        </div>
                        <div className="btn-holder">
                            <button className="btn btn-border" data-back>
                                {" "}
                                <span>取消</span>
                            </button>
                            <button
                                className="btn"
                                data-openmodal="modal-2"
                                onClick={resetPWD}
                            >
                                {" "}
                                <span>確認</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div
                id="modal-4"
                className="modal small alert"
                data-modal
                data-mid="0.1.0.1"
            >
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要<span id="updateStatus"></span> 【{" "}
                            <span id="updateName"></span> 】的權限？
                            <input type="hidden" id="updateStatusUserID" value="" />
                        </div>
                        <div className="btn-holder">
                            <button className="btn btn-border" data-back>
                                {" "}
                                <span>取消</span>
                            </button>
                            <button className="btn" data-close onClick={updateStatus}>
                                {" "}
                                <span>確認</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/*
             <div id="modal-5" className="modal small alert" data-modal data-modal data-mid="0.1.0.2">
                <div className="modal-container">

                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要 {statusStr}【 natasharomanoff 】的權限？
                        </div>
                        <div className="btn-holder">
                            <button className="btn btn-border" data-back> <span>取消</span></button>
                            <button className="btn" data-close> <span>確認</span></button>
                        </div>
                    </div>
                </div>
            </div>
             */}

            <div
                id="modal-5"
                className="modal small alert"
                data-modal
                data-mid="0.1.0.2"
            >
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要刪除該帳號：【 <span id="delName"></span> 】?
                            <input type="hidden" id="delUserID" value="" />
                        </div>
                        <div className="btn-holder">
                            <button className="btn btn-border" data-back>
                                {" "}
                                <span>取消</span>
                            </button>
                            <button className="btn" data-close onClick={delUser}>
                                {" "}
                                <span>確認</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddUser;
