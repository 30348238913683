import React, { useState, useEffect } from "react";
import {
    API_GET_ProductCostHome,
    API_GET_ProductName1List,
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { MenuSet, OpenModal } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
import moment from 'moment';

const ProductCost = () => {
    var history = useHistory();
    const [yearArray, setYearArray] = useState([]); //年月下拉選單

    useEffect(() => {
        //把menu顏色置換
        MenuSet("ProductCost");
        //getData();

        //年度下拉
        var yArr = [];
        var now = new Date(moment().format('YYYY-MM-DD'));
        var firstYearMonth = new Date(moment().format('YYYY-MM-DD'));
        firstYearMonth.setMonth(firstYearMonth.getMonth() - 24);
        while (firstYearMonth <= now) {
            var nowYear = firstYearMonth.getFullYear();
            var nowMonth = firstYearMonth.getMonth() + 1;

            if (nowMonth < 10) {
                yArr.push(nowYear + "/" + "0" + (nowMonth));
            }
            else {
                yArr.push(nowYear + "/" + (nowMonth));
            }

            firstYearMonth.setMonth(firstYearMonth.getMonth() + 1);
        }
        setYearArray(yArr);
        setQryMonth(yArr[yArr.length - 1]);
    }, []);
    //選擇公司
    const [companyId, setCompanyId] = useState(0);
    //查詢月
    const [qryMonth, setQryMonth] = useState();
    useEffect(() => {
        if (qryMonth) {
            API_GET_ProductCostHome({
                yearMonth: qryMonth
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setCompanyArray(data.result.data.companyArray);
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });

        }
    }, [qryMonth]);



    //公司
    const [companyArray, setCompanyArray] = useState([]);

    useEffect(() => {
        console.log("公司清單", companyArray);

    }, [companyArray])
    //查詢資料
    //function getData() {
    //    API_GET_ProductCostHome()
    //        .then((response) => response.data)
    //        .then((data) => {
    //            if (!checkAuthToken(data)) {
    //                history.push("/");
    //            }
    //            if (!CheckResponseCode(data)) { return; } //檢查response code

    //            ////console.log(data);
    //            setQryMonth(data.result.data.date);
    //            setCompanyArray(data.result.data.companyArray);
    //        })
    //        .catch((err) => {

    //            if (err.response.status === 401 || err.response.data.code == "1001") {
    //                localStorage.clear();
    //                history.push("/");
    //            }
    //        });
    //}
    function goView() {
        if (companyId == null) {
            document.getElementById("companyDiv").classList.add("show-help");
        } else {
            history.push("/Main?path=ProductCostView&companyId=" + companyId + "&yearMonth=" + qryMonth);
        }
    }

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container narrow">
                    <h3 className="fz-B has-border">產品成本查詢</h3>
                </div>
            </div>
            <div className="container narrow">
                <div class="form-container">
                    <div class="form-item disabled">
                        <div class="field">成本月份</div>
                        <div class="value">
                            {/*<input type="text" value={qryMonth} />*/}
                            <select
                                name=""
                                id="qryMonth"
                                value={qryMonth}
                                onChange={(e) => setQryMonth(e.target.value)} >
                                {yearArray.map((x) => (
                                    <option value={x}>{x}</option>
                                ))}
                            </select>
                        </div>
                        <div className="help-word">請輸入訂單編號</div>
                    </div>

                    <div className="form-item must no-field" id="companyDiv">
                        <div className="field">營收公司</div>
                        <div className="value">
                            {companyArray.map((x) => (
                                <div>
                                    <input
                                        type="radio"
                                        key={`company_${x.id}`}
                                        id={`company_${x.id}`}
                                        name="companyCheckBox"
                                        value={x.id}
                                        checked={companyId == x.id ? true : false}
                                        onClick={(e) => setCompanyId(x.id)}
                                    />
                                    <label htmlFor={`company_${x.id}`}>
                                        {x.name} {x.settlementState == 3 ? "(已月結)" : null}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇營收公司</span>
                    </div>

                    <div className="form-item btn-area sp">
                        <div className="left"></div>
                        <div className="right">
                            <a className="btn" onClick={goView}>
                                <span>查詢</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCost;
