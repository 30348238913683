import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_ProcurementHome,
    API_GET_SupplierNameList,
    API_GET_CustomerName,
    API_GET_OrderNumber,
} from "../../global/constants";
import { checkAuthToken } from "../../global/TokenUtil";
import { InitJS, MenuSet, OpenModal } from '../../global/ToolUtil';
import { CheckResponseCode } from '../../global/ResponseUtil';

const PurchaseRequestQry = () => {
    var history = useHistory();

    useEffect(() => {
        //把menu顏色置換
        MenuSet("PurchaseRequestQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    //採購公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyId, setCompanyId] = useState();
    const [companyIdError, setCompanyIdError] = useState(false);
    //產品別
    const [productTypeArray, setProductTypeArray] = useState([{}]);
    //產品別Id
    const [productTypeId, setProductTypeId] = useState();
    //採購簽呈
    const [purchaseSignatureTypeArray, setPurchaseSignatureTypeArray] = useState([
        { id: 0, name: "定型化契約" },
        { id: 1, name: "合約" },
    ]);
    const [purchaseSignatureType, setPurchaseSignatureType] = useState();
    //簽呈單號
    const [signatureNumber, setSignatureNumber] = useState();
    //採購類別
    const [purchaseTypeArray, setPurchaseTypeArray] = useState([
        { id: 0, name: "事務性採購" },
        { id: 1, name: "業務性採購" },
    ]);
    const [purchaseType, setPurchaseType] = useState();
    //銷售客戶
    const [customerName, setCustomerName] = useState();
    function customerNameChange(e) {
        setCustomerName(e.target.value);
        //共用D
        getCustomerName();
    }
    const [customerNameArray, setCustomerNameArray] = useState([{}]);

    //請購單號
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState();
    function purchaseOrderNumberChange(e) {
        setPurchaseOrderNumber(e.target.value);
        //共用K
        getOrderNumber();
    }
    const [orderNumberArray, setOrderNumberArray] = useState([{}]);

    //請款單號
    const [askPaymentNumber, setAskPaymentNumber] = useState();
    function askPaymentNumberChange(e) {
        setAskPaymentNumber(e.target.value);
    }
    //供應商
    const [supplierName, setSupplierName] = useState();
    function supplierNameChange(e) {
        setSupplierName(e.target.value);
        //共用B
        getSupplierName();
    }
    const [supplierNameArray, setSupplierNameArray] = useState([{}]);
    //採購金額-起
    const [purchasePriceStart, setPurchasePriceStart] = useState();
    function purchasePriceStartChange(e) {
        setPurchasePriceStart(e.target.value);
    }
    //採購金額-迄
    const [purchasePriceEnd, setPurchasePriceEnd] = useState();
    function purchasePriceEndChange(e) {
        setPurchasePriceEnd(e.target.value);
    }
    //採購日期-起
    const [purchaseDateStart, setPurchaseDateStart] = useState();
    function purchaseDateStartChange(e) {
        setPurchaseDateStart(e.target.value);
    }
    //採購日期-迄
    const [purchaseDateEnd, setPurchaseDateEnd] = useState();
    function purchaseDateEndChange(e) {
        setPurchaseDateEnd(e.target.value);
    }
    //請款日期-起
    const [askPaymentDateStart, setAskPaymentDateStart] = useState();
    function askPaymentDateStartChange(e) {
        setAskPaymentDateStart(e.target.value);
    }
    //請款日期-迄
    const [askPaymentDateEnd, setAskPaymentDateEnd] = useState();
    function askPaymentDateEndChange(e) {
        setAskPaymentDateEnd(e.target.value);
    }
    //請購單號 共用K
    function getOrderNumber() {
        API_GET_OrderNumber({
            input: document.getElementById("purchaseOrderNumberText").value,
            companyId: companyId
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if(data.code=="4038"){
                    return;
                }
                
                setOrderNumberArray(data.result.data);
            })

    }
    //銷售客戶 共用D
    function getCustomerName() {
        API_GET_CustomerName({
            input: document.getElementById("customerNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setCustomerNameArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });;
    }
    //共用B
    function getSupplierName() {
        API_GET_SupplierNameList({
            input: document.getElementById("supplierNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setSupplierNameArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    var moment = require("moment");
    function getData() {
        API_GET_ProcurementHome()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //負責公司
                    setCompanyArray(data.result.data.companyArray);
                    //產品別
                    setProductTypeArray(data.result.data.productTypeArray);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function submit() {
        var path = "/Main?path=PurchaseRequestList";
        if (companyId === undefined) {
            setCompanyIdError(true);
        } else {
            setCompanyIdError(false);
            path += "&companyId=" + companyId;
            if (purchaseSignatureType != undefined) {
                //採購簽呈
                path += "&purchaseSignatureType=" + purchaseSignatureType;
            }
            if (signatureNumber != undefined) {
                //簽呈單號
                path += "&signatureNumber=" + signatureNumber;
            }
            if (purchaseType != undefined) {
                //採購類別
                path += "&purchaseType=" + purchaseType;
            }
            if (document.getElementById("customerNameText").value != "") {
                //銷售客戶
                path +=
                    "&customerName=" + document.getElementById("customerNameText").value;
            }
            if (document.getElementById("productType").value != undefined) {
                //產品別ID
                path +=
                    "&productTypeId=" + document.getElementById("productType").value;
            }
            if (
                document.getElementById("purchaseOrderNumberText").value != undefined
            ) {
                //請購單號
                path +=
                    "&purchaseOrderNumber=" +
                    document.getElementById("purchaseOrderNumberText").value;
            }
            if (askPaymentNumber != undefined) {
                //請款單號
                path += "&askPaymentNumber=" + askPaymentNumber;
            }
            if (document.getElementById("supplierNameText").value != "") {
                //供應商
                path += "&supplierName=" + supplierName;
            }
            if (purchasePriceStart != undefined && purchasePriceEnd != undefined) {
                //採購金額
                path += "&purchasePriceStart=" + purchasePriceStart;
                path += "&purchasePriceEnd=" + purchasePriceEnd;
            }
            if (purchaseDateStart != undefined && purchaseDateEnd != undefined) {
                //採購日期
                path += "&purchaseDateStart=" + purchaseDateStart;
                path += "&purchaseDateEnd=" + purchaseDateEnd;
            }
            if (askPaymentDateStart != undefined && askPaymentDateEnd != undefined) {
                //請款日期
                path += "&askPaymentDateStart=" + askPaymentDateStart;
                path += "&askPaymentDateEnd=" + askPaymentDateEnd;
            }
            history.push(path);
        }
        ////console.log(path);
    }

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">採購紀錄查詢</h3>
                </div>
            </div>
            <div className="container">
                <div className="form-container">
                    <div
                        className={`form-item must ${companyIdError == true ? " show-help" : ""
                            }`}
                    >
                        <div className="field">採購公司</div>
                        <div className="value">
                            {companyArray.map((x) => (
                                <div key={x.id}>
                                    <input
                                        type="radio"
                                        id={`f1011-${x.id}`}
                                        name="F1011"
                                        value={x.id}
                                        onClick={(e) => setCompanyId(x.id)}
                                    />
                                    <label htmlFor={`f1011-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <div className="help-word">請選擇採購公司</div>
                    </div>

                    <div className="form-item half">
                        <div className="field">採購簽呈</div>
                        <div className="value">
                            {purchaseSignatureTypeArray.map((x) => (
                                <div>
                                    <input
                                        type="radio"
                                        id={`f1012-${x.id}`}
                                        name="f1012"
                                        value={`f1012-${x.id}`}
                                        onClick={(e) => setPurchaseSignatureType(x.id)}
                                    />
                                    <label htmlFor={`f1012-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>
                    <div className="form-item half">
                        <div className="field">採購單號</div>
                        <div className="value">
                            <input
                                type="text"
                                value={signatureNumber}
                                onChange={(e) => setSignatureNumber(e.target.value)}
                            />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>
                    <div className="form-item ">
                        <div className="field">採購分類</div>
                        <div className="value">
                            {purchaseTypeArray.map((x) => (
                                <div>
                                    <input
                                        type="radio"
                                        id={`f1013-${x.id}`}
                                        name="f1013"
                                        value={`f1013-${x.id}`}
                                        onClick={(e) => setPurchaseType(x.id)}
                                    />
                                    <label htmlFor={`f1013-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <div className="form-item half has-datalist" data-datalist>
                        <div className="field">銷售客戶</div>
                        <div className="value">
                            <input
                                type="text"
                                id="customerNameText"
                                onChange={customerNameChange}
                            />
                            <ul className="datalist scrollbar">
                                {customerNameArray.map((x) => (
                                    <li data-value={x.name}>
                                        <span className="flex-10 bold">{x.name}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>
                    <div className="form-item half">
                        <div className="field">產品別</div>
                        <div className="value">
                            <select name="productType" id="productType">
                                <option value="">全部</option>
                                {productTypeArray.map((x) => (
                                    <option value={x.id}>{x.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>
                    <div className="form-item half has-datalist" data-datalist>
                        <div className="field">請購單號</div>
                        <div className="value">
                            <input
                                type="text"
                                id="purchaseOrderNumberText"
                                onChange={purchaseOrderNumberChange}
                            />
                            <ul className="datalist scrollbar">
                                {orderNumberArray.map((x) => (
                                    <li data-value={x.name}>
                                        <span className="flex-10 bold">{x.name}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>
                    <div className="form-item half">
                        <div className="field">請款單號</div>
                        <div className="value">
                            <input
                                type="text"
                                value={askPaymentNumber}
                                onChange={askPaymentNumberChange}
                            />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>
                    <div className="form-item half has-datalist" data-datalist>
                        <div className="field">供應商</div>
                        <div className="value">
                            <input
                                type="text"
                                id="supplierNameText"
                                onChange={supplierNameChange}
                            />
                            <ul className="datalist scrollbar">
                                {supplierNameArray.map((x) => (
                                    <li data-value={x.name}>
                                        <span className="flex-10 bold">{x.name}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <hr />
                    <div className="form-item">
                        <div className="field">採購金額</div>
                        <div className="value multi-inline">
                            <div className="flex-5">
                                <input
                                    type="text"
                                    value={purchasePriceStart}
                                    onChange={purchasePriceStartChange}
                                />
                            </div>
                            <div className="flex-1 center">~</div>
                            <div className="flex-5 last">
                                <input
                                    type="text"
                                    value={purchasePriceEnd}
                                    onChange={purchasePriceEndChange}
                                />
                            </div>
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>
                    <div className="form-item">
                        <div className="field">採購日期</div>
                        <div className="value multi-inline">
                            <div className="flex-5">
                                <input
                                    type="date"
                                    value={purchaseDateStart}
                                    onChange={purchaseDateStartChange}
                                />
                            </div>
                            <div className="flex-1 center">~</div>
                            <div className="flex-5 last">
                                <input
                                    type="date"
                                    value={purchaseDateEnd}
                                    onChange={purchaseDateEndChange}
                                />
                            </div>
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>
                    <div className="form-item">
                        <div className="field">請款日期</div>
                        <div className="value multi-inline">
                            <div className="flex-5">
                                <input
                                    type="date"
                                    value={askPaymentDateStart}
                                    onChange={askPaymentDateStartChange}
                                />
                            </div>
                            <div className="flex-1 center">~</div>
                            <div className="flex-5 last">
                                <input
                                    type="date"
                                    value={askPaymentDateEnd}
                                    onChange={askPaymentDateEndChange}
                                />
                            </div>
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>
                    <div className="form-item btn-area sp">
                        <div className="left"></div>
                        <div className="right">
                            <a className="btn" onClick={submit}>
                                <span>查詢</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseRequestQry;
