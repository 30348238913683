//千分位
export function NumFormart(num, digits = 3) {

    if (num) {
        if (num == "-") {
            return "-";
        }

        num = parseFloat(num.toString().replace(/,/g, ''), 10);
        var options = {
            minimumFractionDigits: 0,
            maximumFractionDigits: digits,
        };
        var str = new Intl.NumberFormat(undefined, options).format(num);// Number.prototype.toLocaleString.call(isNaN(int) ? 0 : int) + (arr.length == 2 ? "." + arr[1] : "");

        if (str == "-0") {
            str = "0";
        }

        return str;
        num += "";
        var arr = parseFloat(num).toFixed(2).replace(/,/g, '').split(".");
        var int = parseFloat(arr[0]);

        var str = Number.prototype.toLocaleString.call(isNaN(int) ? 0 : int) + (arr.length == 2 ? "." + arr[1] : "");
        return str;
        //return Number.prototype.toLocaleString.call(parseInt(num))
        //num += "";
        //var arr = num.split(".");
        //var re = /(\d{1,3})(?=(\d{3})+$)/g;
        //return arr[0].replace(re, "$1,") + (arr.length == 2 ? "." + arr[1] : "");
    } else {
        return num;
    }
}
export function TestNumFormart(num) {

    //console.log(num);
    if (num) {
        if (num == "-") {
            return "-";
        }
        num += "";
        var arr = num.replace(/,/g, '').split(".");
        var int = parseFloat(arr[0]);

        var str = Number.prototype.toLocaleString.call(isNaN(int) ? 0 : int) + (arr.length == 2 ? "." + arr[1] : "");
        return str;
        //return Number.prototype.toLocaleString.call(parseInt(num))
        //num += "";
        //var arr = num.split(".");
        //var re = /(\d{1,3})(?=(\d{3})+$)/g;
        //return arr[0].replace(re, "$1,") + (arr.length == 2 ? "." + arr[1] : "");
    } else {
        return num;
    }
}
export function RoundTo2Compare(num) {
    var res = parseFloat((num + "").replace(/,/g, ''));
    if (!isNaN(res)) {

        num = Math.round((res + Number.EPSILON) * 100) / 100;

    }
    else {

        num = 0;
    }
    return (num) >= 0;
}
// 金額加上千分位
export function NewNumFormart(num) {
    if (num) {
        if (num == "-") {
            return "-";
        }

        if (num == 0) {
            return 0;
        }

        if (num == null) {
            return "";
        }

        num = parseFloat(num);
        var roundedNumber = num.toFixed(2);
        var roundedFloat = parseFloat(roundedNumber);

        var str = new Intl.NumberFormat().format(roundedFloat);// Number.prototype.toLocaleString.call(isNaN(int) ? 0 : int) + (arr.length == 2 ? "." + arr[1] : "");

        if (str == "-0") {
            str = "0";
        }

        return str;
    } else {
        return num;
    }
}
//用此方法 否則新增的資料會物件相等全部變null
//https://blog.csdn.net/arthur_d/article/details/7323733
export function Clone(obj) {
    var o, obj;
    if (obj.constructor == Object) {
        o = new obj.constructor();

    } else {
        o = new obj.constructor(obj.valueOf());

    }

    for (var key in obj) {
        if (o[key] != obj[key]) {
            if (typeof (obj[key]) == 'object') {
                if (obj[key].length == 0) {
                    //console.log(obj[key]);
                    o[key] = obj[key];
                }
                else {
                    o[key] = Clone(obj[key]);
                }
            } else {
                o[key] = obj[key];
            }
        }
    }
    o.toString = obj.toString;
    o.valueOf = obj.valueOf;
    return o;
}
///Url轉碼
export function UrlDecode(str) {
    var uzipStr = '';
    for (var i = 0; i < str.length; i++) {
        var chr = str.charAt(i);
        if (chr === '+') {
            uzipStr = ' ';
        } else if (chr === '%') {
            var asc = str.substring(i + 1, i + 3)
            if (parseInt('0x' + asc) > 0x7f) {
                uzipStr += decodeURI('%' + asc.toString() + str.substring(i + 3, i + 9).toString())
                i += 8;
            } else {
                uzipStr += String.fromCharCode(parseInt('0x' + asc));
                i += 2;
            }
        } else {
            uzipStr += chr;
        }
    }
    return uzipStr;
}
//判斷日期是否存在 https://www.cythilya.tw/2017/05/19/javascript-is-date-exist/
export function isExistDate(dateStr) {
    var dateObj = dateStr.split('-'); // yyyy/mm/dd

    //列出12個月，每月最大日期限制
    var limitInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var theYear = parseInt(dateObj[0]);
    var theMonth = parseInt(dateObj[1]);
    var theDay = parseInt(dateObj[2]);
    var isLeap = new Date(theYear, 1, 29).getDate() === 29; // 是否為閏年?

    if (isLeap) {
        // 若為閏年，最大日期限制改為 29
        limitInMonth[1] = 29;
    }

    // 比對該日是否超過每個月份最大日期限制
    return theDay <= limitInMonth[theMonth - 1];
}
//打開Modal
export function OpenModal(id, layer = 1) {
    if (document.getElementById(id)) {
        var target = document.querySelector('#' + id);
        var layerClass = 'layer-' + layer;
        target.classList.add('active');
        target.classList.add(layerClass);
    }
}

//關閉Modal
export function CloseModal(id, layer = 1) {
    if (document.getElementById(id)) {
        var target = document.querySelector('#' + id);
        var layerClass = 'layer-' + layer;
        target.classList.remove('active');
        target.classList.remove(layerClass);
    }

}
//JS參數
export function InitJS() {
    const body = document.querySelector('body');
    window.onbeforeunload = null;

    SetHasChange(false);

    /*hover秀出小選單*/
    if (document.querySelector('[data-hoverTrigger]')) {
        const hoverBtns = document.querySelectorAll('[data-hoverTrigger]');
        const hoverItems = document.querySelectorAll('[data-hoverItems]');
        //先移除監聽模式
        hoverBtns.forEach(element => {
            element.mouseenter = null;
        });
        hoverItems.forEach(element => {
            element.mouseleave = null;
        });
        //賦予監聽
        hoverBtns.forEach(element => {
            var parent = element.closest('.lt-row');
            element.addEventListener('mouseenter', function () {
                parent.classList.add('show-hovers')
            })
        });
        hoverItems.forEach(element => {
            var parent = element.closest('.lt-row');

            element.addEventListener('mouseleave', function () {
                parent.classList.remove('show-hovers')
            })
        });
    }

    /*顯示下拉*/
    const datalist = document.querySelectorAll("[data-datalist]");

    datalist.forEach((element) => {
        var dropmenu = element.querySelector(".datalist");
        var input = element.querySelector("input");
        if (input != null) {
            //移除監聽
            input.keyup = null;
            input.addEventListener("keyup", function () {
                element.classList.add("show-datalist");
            });
            document.body.addEventListener("click", function (e) {
                var target = e.target;
                if (element.contains(target)) {
                    //console.log(target);
                    if (dropmenu.contains(target)) {
                        var value = target.dataset.value;
                        input.value = value;
                        element.classList.remove("show-datalist");
                    } else {
                        //console.log('ddd');
                    }
                } else {
                    element.classList.remove("show-datalist");
                }
            });
        }
    });


    var currentModal;
    let currentLevel = 1;
    const modals = document.querySelectorAll('[data-modal]');
    const modalBtns = document.querySelectorAll('[data-openmodal]');
    modals.forEach(element => {
        var modalBody = element.querySelector('.modal-container');
        var modalClose = element.querySelectorAll('[data-close]');
        var modalsBack = element.querySelector('[data-back]');
        element.click = null;
        //console.log(modalClose);
        element.addEventListener('click', function (e) {
            if (e.target == modalsBack) {
                var showLayerClass = 'has-layer-' + (currentLevel);
                currentLevel = currentLevel - 1;
                element.classList.remove('active');
                body.classList.remove(showLayerClass);
            } else if (e.target.hasAttribute('data-close')) {
                /*這邊可能要再增加一個判斷，判斷這個[data-close]是否要到指定層級*/

                var showLayerClass = 'has-layer-' + (currentLevel);
                if (typeof currentModal !== "undefined") {
                    currentModal.classList.remove('active');
                } else { }
                currentLevel = currentLevel - 1;
                element.classList.remove('active', 'layer-1', 'layer-2', 'layer-3', 'layer-4', 'layer-5');
                body.classList.remove('has-modal', showLayerClass);
            }
        });
    });

    /*上傳*/
    if (document.querySelector('[data-fakeUpload]')) {
        const item = document.querySelector('[data-fakeUpload]');
        const real = item.querySelector('.real');
        const fake = item.querySelector('.fake');
        real.change = null;
        real.addEventListener('change', function (e) {
            var path = e.target.value;
            var filename = path.replace(/^.*[\\\/]/, '');
            fake.value = filename;

        })
    }


    /*slide toggle 往下展開*/
    //if (type == 1 && document.querySelector('[data-slidetoggle]')) {
    //    const items = document.querySelectorAll('[data-slidetoggle]');
    //    items.forEach(element => {
    //        var targetID = element.dataset.slidetoggle;
    //        var parent = element.closest('.lt-row');
    //        var target = document.querySelector('#' + targetID)
    //        //console.log(target);
    //        element.click = null;
    //        element.addEventListener('click', function () {
    //            element.classList.toggle('rotate');
    //            target.classList.toggle('hide');
    //            parent.classList.toggle('highlight');
    //        })

    //    });
    //}

    /*客製化下拉選單*/
    const dps = document.querySelectorAll("[data-dropmenu]");
    dps.forEach((element) => {
        var selectedValue;
        var trigger = element.querySelector(".dropmenu-value");
        var dropmenu = element.querySelector(".dropmenu");
        var options = element.querySelector("[data-value]");

        //trigger.click = null;

        if (trigger != undefined && trigger != null) {
            trigger.addEventListener("click", function (e) {
                element.classList.add("active");
            });
        }

        //dropmenu.click = null;
        if (dropmenu != undefined && dropmenu != null) {
            dropmenu.addEventListener("click", function (e) {
                if (e.target.matches("[data-value]")) {
                    selectedValue = e.target.dataset.value;
                    if (selectedValue != "") {
                        trigger.textContent = selectedValue;
                        element.classList.remove("active");
                    }
                    else {
                        trigger.textContent = e.target.innerText;
                        element.classList.remove("active");
                    }
                }
            });
        }

        document.addEventListener("click", function (e) {
            //e.stopPropagation();
            // console.log(e.target);
            if (!element.contains(e.target)) {
                element.classList.remove("active");
            } else {
                // console.log("相同物件");
            }
        });
    });

}
//進入頁面判斷active
export function MenuSet(menuName) {
    var dmuMenu = document.querySelectorAll(".dmu");
    dmuMenu.forEach(el => {
        if (el.id != ("menuitem-" + menuName)) {
            el.classList.remove('active');
        } else {
            document.getElementById("menuitem-" + menuName).classList.add("active");
        }
    });
}
//export function Init_Menu() {
/*menu*/
//var hasSubMenu = document.querySelectorAll(".has-dropmenu");
//hasSubMenu.forEach(element => {
//    element.click = null;
//    element.addEventListener('click', function (e) {
//        var menu = document.querySelectorAll(".item");
//        menu.forEach((e) => {
//            if (e.classList.contains("active") && element !== e) {
//                e.classList.remove("active");
//            }
//        });
//        if (element.classList.contains('active')) {
//            element.classList.remove('active');
//        } else {
//            element.classList.add('active');
//        }
//    });
//});

//}
//判斷是否需要離開提示
export function SetOnbeforeunload(leavestr = "是否放棄編輯此紀錄？", leavetype = 1) {
    var changeFlag = false;
    const elements = document.querySelectorAll("*");

    let handleArray = [];
    elements.forEach(element => {
        if (element.tagName === "INPUT" || element.tagName === "TEXTAREA") {
            handleArray.push(element);
            element.addEventListener("input", () => {
                handleChange();
            });
        } else if (element.tagName === "SELECT") {
            handleArray.push(element);
            element.addEventListener("change", () => {
                handleChange();
            });
        }
    });

    function handleChange() {
        if (!changeFlag) {
            changeFlag = true;

            // 以下是你的其余代码，用于设置 window.onbeforeunload 以及其他操作
            window.onbeforeunload = function (e) {
                var e = window.event || e;
                e.returnValue = ("確定離開當前頁面嗎？");
            }

            SetHasChange(true, leavestr, leavetype);
        }
    }
}

export function SetHasChange(hasChange = true, leavestr = "是否放棄編輯此紀錄？", leavetype = 1) {
    window.onbeforeunload = null;

    if (document.getElementById('haschangetext')) {
        document.getElementById('haschangetext').value = hasChange == true ? "1" : "0";
    }

    if (hasChange == true) {
        if (document.getElementById('div_leave')) {
            if (leavetype == 1) {
                document.getElementById('div_leave').innerHTML = leavestr;
            } else {
                document.getElementById('div_leave').innerHTML = leavestr + "<br />是否放棄編輯此紀錄？";
            }
        }
    }
}
