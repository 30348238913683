import React, { useState, useEffect } from "react";
import {
    API_GET_VendorCurrNames,
    API_GET_PrepassVendorData,
    API_GET_PrepassVendorPreData,
} from "../../global/constants"; 
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { NumFormart,InitJS } from "../../global/ToolUtil";
const Prepaid_SupplierView = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        //把menu顏色置換
        document.getElementById("menuitem-Prepaid_CustomerQry").classList.add("active");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    const [typeName, setTypeName] = useState();
    const [type, setType] = useState();
    const [dataid, setDataid] = useState();
    const [currId, setCurrId] = useState();
    const [currencyEName, setCurrencyEName] = useState();
    const [companyName, setCompanyName] = useState();
    const [currencyName, setCurrencyName] = useState();
    const [venId, setVenId] = useState();
    const [vendorName, setVendorName] = useState();
    const [vendorTaxId, setVendorTaxId] = useState();
    const [vendorTitle, setVendorTitle] = useState();
    const [balance, setBalance] = useState();
    const [historyExplanation, setHistoryExplanation] = useState();

    function getData() {
        var tmpStr = "";
        setVenId(queryParams.get("venId"));
        if (queryParams.get("type") == "1") {
            setType("1");
            setTypeName("儲值金");
            API_GET_PrepassVendorPreData({
                vendorId: queryParams.get("venId"),
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setCompanyName(data.result.data.companyName);
                    setVendorName(data.result.data.vendorName);
                    setVendorTaxId(data.result.data.vendorTaxId);
                    setVendorTitle(data.result.data.vendorTitle);
                    setDataid(data.result.data.vendorPrepAmtId);
                    setBalance(NumFormart(data.result.data.balance));
                    for (var i = 0; i < data.result.data.historyExplanation.length; i++) {
                        if (i == 0) {
                            tmpStr = data.result.data.historyExplanation[i];
                        } else {
                            tmpStr += "\n" + data.result.data.historyExplanation[i];
                        }
                    }
                    setHistoryExplanation(tmpStr);
                })
                .catch((err) => {
                    
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else if (queryParams.get("type") == "2") {
            setType("2");
            setTypeName("保證金");
            API_GET_PrepassVendorData({
                vendorId: queryParams.get("venId"),
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setCompanyName(data.result.data.companyName);
                    setVendorName(data.result.data.vendorName);
                    setVendorTaxId(data.result.data.vendorTaxId);
                    setVendorTitle(data.result.data.vendorTitle);
                    setDataid(data.result.data.vendorPrepAmtId);
                    setBalance(NumFormart(data.result.data.balance));
                    for (var i = 0; i < data.result.data.historyExplanation.length; i++) {
                        if (i == 0) {
                            tmpStr = data.result.data.historyExplanation[i];
                        } else {
                            tmpStr += "\n" + data.result.data.historyExplanation[i];
                        }
                    }
                    setHistoryExplanation(tmpStr);
                })
                .catch((err) => {
                    
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
        //共用X抓幣別
        API_GET_VendorCurrNames({ vendorId: queryParams.get("venId"), })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setCurrencyEName(data.result.data.currencyEName);
                    setCurrencyName(data.result.data.currencyName);
                    setCurrId(data.result.data.currId);
                }
            })
    }

    function goEdit() {
        var path =
            "/Main?path=Prepaid_SupplierEdit&type=" + type + "&venId=" + venId;
        history.push(path);
    }
    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">{companyName}</h3>
                    <span>幣別：{currencyName}</span>
                </div>
            </div>
            <div className="container">
                <form action="" className="form-container">
                    <div className="form-item half">
                        <div className="field">供應商</div>
                        <div className="value">
                            <input type="text" value={vendorName} disabled="disabled" />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>
                    <div className="form-item half"></div>
                    <div className="form-item half">
                        <div className="field">供應商抬頭</div>
                        <div className="value">
                            <input type="text" value={vendorTitle} disabled="disabled" />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <div className="form-item half">
                        <div className="field">供應商統編/ 編號</div>
                        <div className="value">
                            <input type="text" value={vendorTaxId} disabled="disabled" />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <hr />

                    <div className="form-item half">
                        <div className="field">{typeName}餘額</div>
                        <div className="value multi-inline">
                            <input type="text" value={balance} disabled="disabled" />
                            <div className="btn-area">
                                <a
                                    className="btn btn-middle btn-border"
                                    onClick={() => history.push(`/Main?path=Prepaid_SupplierList&dataid=${dataid}&type=${type}&venId=${venId}&Vendorname=${vendorName}`)}
                                >
                                    <span>查看紀錄</span>
                                </a>
                            </div>
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <div className="form-item half"></div>

                    <div className="form-item">
                        <div className="field">歷程說明</div>
                        <div className="value">
                            <textarea
                                name=""
                                id=""
                                cols="30"
                                rows="10"
                                value={historyExplanation}
                                disabled="disabled"
                            ></textarea>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item btn-area sp">
                        <div className="left"></div>
                        <div className="right">
                            <a className="btn" onClick={goEdit}>
                                <span>維護資料</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Prepaid_SupplierView;
