import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_Get_Vendor_dataCard,
    API_POST_VendorData,
    API_GET_AdNameList,
    API_GET_VendorDataCard,
    API_GET_VendorQuery,
    API_POST_VendorDownLoad,
} from "../../global/constants";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, UrlDecode, CloseModal, InitJS, SetOnbeforeunload, MenuSet, SetHasChange } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const SupplierNew = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        //把menu顏色置換
        MenuSet("SupplierQry");
        if (queryParams.get('vName') != null && queryParams.get('vName') != '') {
            getdata();
        } else {
            getdataCard();
        }
        //JS事件
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload("是否放棄新增此紀錄");
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    function reflash() {
        window.location.reload();
    }
    function returnQry() {
        history.push("/Main?path=SupplierQry");
    }
    //負責PM
    function getAdName() {
        API_GET_AdNameList({
            input: document.getElementById("adNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setAdNameArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
    }

    //複製
    function getdata() {
        var dt = {
            vendorName: queryParams.get("vName"),
            ReqFrmID: parseInt(queryParams.get("reqFrmID")),
        };
        //console.log(JSON.stringify(dt));
        API_GET_VendorQuery(dt)
            .then((response) => response.data)
            .then((data) => {
                console.log("供應商複製新增回傳資料", data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                //負責公司
                var dt = [];
                for (var i = 0; i < data.result.data.dataCard.companyArray.length; i++) {
                    dt.push({ cmpID: data.result.data.dataCard.companyArray[i].id, cmpName: data.result.data.dataCard.companyArray[i].name });
                }
                setCompanyArray(dt);
                //業務單位
                var dt2 = [];
                for (var i = 0; i < data.result.data.dataCard.departmentArray.length; i++) {
                    dt2.push({ cmpDivID: data.result.data.dataCard.departmentArray[i].id, cmpDivName: data.result.data.dataCard.departmentArray[i].name });
                }
                setDepartmentArray(dt2);
                //付款條件(月結天數)
                var dt3 = [];
                for (var i = 0; i < data.result.data.dataCard.monthSettleDaysArray.length; i++) {
                    dt3.push({ venMonID: data.result.data.dataCard.monthSettleDaysArray[i].id, venMonDay: data.result.data.dataCard.monthSettleDaysArray[i].name });
                }
                setSettlementArray(dt3);
                //交易幣別Array
                var dt4 = [];
                for (var i = 0; i < data.result.data.dataCard.currencyBaseList.length; i++) {
                    dt4.push({ currID: data.result.data.dataCard.currencyBaseList[i].id, currName: data.result.data.dataCard.currencyBaseList[i].name });
                }
                setCurrencyBaseArray(dt4);

                //-----------------------------------------//
                //setTitleName(data.result.data.vendor.venName);
                setCompanyIdArray(data.result.data.vendor.companyIdArray);
                //setOldVendoridArray(data.result.data.vendor.oldVendoridArray);
                setVenBIZNo(data.result.data.vendor.venBIZNo);
                setVenNumber(data.result.data.vendor.venNumber);
                setVenPhone(data.result.data.vendor.venPhone);
                setVenFax(data.result.data.vendor.venFax);
                setVenAddress(data.result.data.vendor.venAddress);
                setVenID(data.result.data.vendor.venID);
                setAdName(data.result.data.pmName);
                setAdID(data.result.data.vendor.pmid);
                setSupplierName(data.result.data.vendor.venName);
                setSupplierTitle(data.result.data.vendor.venTitle);
                //月結天數
                setSetlementId(data.result.data.vendor.venMonID);
                //負責單位
                setCmpDivID(data.result.data.vendor.cmpDivID);
                setMemo(data.result.data.vendor.venREM);
                setBankData(data.result.data.vendor.vendorBanks);
                setContactData(data.result.data.vendor.vendorContacts);
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //全新
    function getdataCard() {
        API_GET_VendorDataCard({})
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //負責公司
                    setCompanyArray(data.result.companyArray);
                    //業務單位
                    setDepartmentArray(data.result.departmentArray);
                    //付款條件
                    setSettlementArray(data.result.settlementArray);
                    //交易幣別Array
                    setCurrencyBaseArray(data.result.currencyBaseArray);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //修改文字
    const [modalStr, setModalStr] = useState();
    //帳務聯絡人
    const [contactData, setContactData] = useState([
        { venConName: null, venConTel: null, venConEmail: null },
    ]);
    function addContactData() {

        setContactData(function (prev) {
            return [...prev, { venConName: null, venConTel: null, venConEmail: null },];
        });
    }
    function contactDataChange(e, index, col) {

        setContactData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "venConName") {
                        item.venConName = e.target.value;
                    } else if (col == "venConTel") {
                        item.venConTel = e.target.value;
                    } else if (col == "venConEmail") {
                        item.venConEmail = e.target.value;
                    }

                }
                return item;
            });
        });
    }

    //交易幣別Array
    const [currencyBaseArray, setCurrencyBaseArray] = useState([{}]);
    function currencyDataChange() {

        var tmpArray = [];
        var f827 = document.getElementsByName("f827");
        for (var i = 0; i < f827.length; i++) {
            if (f827[i].checked) {
                tmpArray.push({ venBankCurrID: parseInt(f827[i].value) });
            }
        }
        setBankData(function (prev) {
            return prev.map((item, i) => {
                if (i == currencyIndex) {
                    item.venBankExRates = tmpArray;
                }
                return item;
            });
        });
        //console.log(bankData);
    }

    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [isCompanyError, setIsCompanyError] = useState(false);
    const [companyIdArray, setCompanyIdArray] = useState([]);
    function companyIdArrayChange() {

        var tmpArray = [];
        var f821 = document.getElementsByName("f821");
        for (var i = 0; i < f821.length; i++) {
            if (f821[i].checked) {
                tmpArray.push(parseInt(f821[i].value));
            }
        }

        //有找到香港果核
        if (tmpArray.find(x => x == 1) != null) {
            let tmp = settlementArray.find(x => x.venMonDay == 90);
            if (tmp && tmp.venMonID == setlementId) {
                setSetlementId(null);
            }
        }

        setCompanyIdArray(tmpArray);
    }

    const [adName, setAdName] = useState("");
    function adNameChange(e) {

        setAdName(e.target.value);
        getAdName();
    }
    const [adID, setAdID] = useState();
    //產品負責人datalist
    const [adNameArray, setAdNameArray] = useState([{}]);

    //開畫面被選擇的幣別
    const [currencyData, setCurrencyData] = useState([]);

    //刪除帳務聯絡人
    function delContactItem(index) {
        SetHasChange(true, "是否放棄新增此紀錄");
        setContactData(function (prev) {
            return prev.filter((x, i) => i != index);
        });
    }

    //業務單位
    const [departmentArray, setDepartmentArray] = useState([{}]);
    const [isCmpError, setIsCmpError] = useState(false);
    const [cmpDivID, setCmpDivID] = useState();
    //月結天數
    const [settlementArray, setSettlementArray] = useState([{}]);
    const [setlementId, setSetlementId] = useState();
    const [isCustPayError, setIsCustPayError] = useState(false);
    //供應商
    const [supplierName, setSupplierName] = useState("");
    function supplierNameChange(e) {

        setSupplierName(e.target.value);
    }
    //供應商抬頭
    const [supplierTitle, setSupplierTitle] = useState("");
    function supplierTitleChange(e) {

        setSupplierTitle(e.target.value);
    }
    const [venID, setVenID] = useState();
    //統編
    const [venBIZNo, setVenBIZNo] = useState("");
    function venBIZNoChange(e) {
        var v = e.target.value.replace(/[^A-Za-z0-9]/g, '');
        setVenBIZNo(v);
    }
    //ERPID
    const [venNumber, setVenNumber] = useState();
    //公司電話
    const [venPhone, setVenPhone] = useState();
    //傳真
    const [venFax, setVenFax] = useState();
    //地址
    const [venAddress, setVenAddress] = useState();
    //銀行序號
    const [bankData, setBankData] = useState([
        {
            venBankRemitBank: null,
            venBankRemitBranch: null,
            venBankRemitAcnt: null,
            venBankExRates: [],
        },
    ]);
    //新增匯款銀行
    function addBankData() {
        SetHasChange(true, "是否放棄新增此紀錄");

        setBankData(function (prev) {
            return [
                ...prev,
                {
                    venBankRemitBank: null,
                    venBankRemitBranch: null,
                    venBankRemitAcnt: null,
                    venBankExRates: [],
                },
            ];
        });
    }
    //匯款銀行資料修改
    function venBankDataChange(e, index, col) {

        setBankData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "venBankRemitBank") {
                        item.venBankRemitBank = e.target.value;
                    } else if (col == "venBankRemitAcnt") {
                        item.venBankRemitAcnt = e.target.value;
                    } else if (col == "venBankRemitBranch") {
                        item.venBankRemitBranch = e.target.value;
                    } else if (col == "venBankRemitSWIFTCode") {
                        item.venBankRemitSWIFTCode = e.target.value;
                    }

                }
                return item;
            });
        });
    }

    //刪除匯款銀行
    function delBankItem(index) {
        SetHasChange(true, "是否放棄新增此紀錄");

        setBankData(function (prev) {
            return prev.filter((x, i) => i != index);
        });
    }
    //備註
    const [memo, setMemo] = useState();

    //陣列幾
    const [currencyIndex, setCurrencyIndex] = useState();
    //打開幣別
    function openModal10(index) {
        setCurrencyIndex(index);
        var a = bankData[index].venBankExRates;
        if (a == null) a = [];
        var tmpb = [];
        for (var i = 0; i < a.length; i++) {
            tmpb.push(a[i].venBankCurrID);
        }

        var f827 = document.getElementsByName("f827");
        for (var i = 0; i < f827.length; i++) {
            if (tmpb.indexOf(parseInt(f827[i].value)) >= 0) {
                f827[i].checked = true;
            } else {
                f827[i].checked = false;
            }
        }
        OpenModal("modal-10");
    }
    //資料檢查
    function checkData() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {
            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });
        //負責公司
        if (companyIdArray.length == 0) {
            result = false;
            setIsCompanyError(true);
        } else {
            setIsCompanyError(false);
        }

        //負責單位
        var bf822 = false;
        var f822 = document.getElementsByName("f822");
        for (var i = 0; i < f822.length; i++) {
            if (f822[i].checked) {
                bf822 = true;
                break;
            }
        }
        if (bf822 == false) {
            setIsCmpError(true);
            result = false;
        } else {
            setIsCmpError(false);
        }
        //月結天數
        var bf823 = false;
        var f823 = document.getElementsByName("f823");
        for (var i = 0; i < f823.length; i++) {
            if (f823[i].checked) {
                bf823 = true;
                break;
            }
        }
        if (bf823 == false) {
            setIsCustPayError(true);
            result = false;
        } else {
            setIsCustPayError(false);
        }

        for (var i = 0; i < bankData.length; i++) {
            if (bankData[i].venBankExRates == null || bankData[i].venBankExRates.length == 0) {
                if (document.getElementById("bank_" + i)) {
                    document.getElementById("bank_" + i).setAttribute("style", "border-color:#F37402;color:#F37402");
                }
                result = false;
            } else {
                if (document.getElementById("bank_" + i)) {
                    document.getElementById("bank_" + i).removeAttribute("style");
                }
            }
        }

        return result;
    }

    function GeneratePostData() {
        var vendorsArray = [];

        for (var i = 0; i < companyIdArray.length; i++) {
            vendorsArray.push({
                preVenName: supplierName,
                VenName: supplierName,
                VenTitle: supplierTitle,
                VenBIZNo: venBIZNo,
                VenNumber: venNumber,
                VenPhone: venPhone,
                VenFax: venFax,
                VenAddress: venAddress,
                CmpID: parseInt(companyIdArray[i]), //
                CmpDivID: parseInt(cmpDivID), //負責單位
                VenMonID: parseInt(setlementId), //月結天數
                PMID: adID,
                VenRem: memo,
            });
        }

        return {
            companyIdArray: companyIdArray,
            oldVendoridArray: [],
            vendorContacts: contactData,
            vendorBanks: bankData,
            vendors: vendorsArray,
            fromCopy: queryParams.get('fromCopy') != undefined && queryParams.get('fromCopy') != null ? parseInt(queryParams.get('fromCopy')) : 0
        }
    }

    //確定修改
    function Edit(e) {
        e.target.classList.add("disabled");
        var result = checkData();
        if (result) {

            var dt = GeneratePostData();
            console.log(JSON.stringify(dt));
            API_POST_VendorData(dt)
                .then((response) => response.data)
                .then((data) => {
                    e.target.classList.remove("disabled");
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setModalStr(data.message);
                    if (data.code == "0000" || data.code == "0001") {
                        OpenModal("modal-add");
                        SetHasChange(false);
                    } else {
                        OpenModal("modal-error");
                    }
                })
                .catch((err) => {

                    e.target.classList.remove("disabled");
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            e.target.classList.remove("disabled");
        }
    }

    const FileDownload = require("js-file-download");
    function downLoad(e) {
        var result = checkData();
        if (result) {
            var dt = GeneratePostData();
            dt.DownloadFrom = 0;

            API_POST_VendorDownLoad(dt).then((response) => {
                const disposition = response.headers['content-disposition'];
                var filename = disposition.split("''")[1];
                FileDownload(response.data, UrlDecode(filename));
            })
                .catch((err) => {
                    if (err.response.status === 401 || (err.response.data != null && err.response.data.code == "1001")) {
                        localStorage.clear();
                        history.push("/");
                    }

                    setModalStr(err.message);
                    OpenModal("modal-error");
                });
        }
    }

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">新增供應商資料</h3>
                </div>
            </div>
            <div className="container">
                <div className="form-container">
                    <div className={`form-item must ${isCompanyError ? 'show-help' : ''}`} >
                        <div className="field">負責公司</div>
                        <div className="value">
                            {companyArray.map((x) => (
                                <div>
                                    <input
                                        type="checkbox"
                                        id={`f821-${x.cmpID}`}
                                        name="f821"
                                        value={x.cmpID}
                                        key={x.cmpID}
                                        onClick={companyIdArrayChange}
                                        checked={
                                            companyIdArray.findIndex((e) => e == x.cmpID) >= 0
                                                ? true
                                                : false
                                        }
                                    />
                                    <label for={`f821-${x.cmpID}`}>{x.cmpName}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇負責公司</span>
                    </div>
                    <div className="form-item must half">
                        <div className="field">供應商</div>
                        <div className="value">
                            <input
                                type="text"
                                value={supplierName}
                                onChange={supplierNameChange}
                                data-required
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item  half">
                        <div className="field">供應商Number</div>
                        <div className="value">
                            <input
                                type="text"
                                value={venNumber}
                                onChange={(e) => setVenNumber(e.target.value)}
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item must half">
                        <div className="field">供應商抬頭</div>
                        <div className="value">
                            <input
                                type="text"
                                value={supplierTitle}
                                onChange={supplierTitleChange}
                                data-required
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item half">
                        <div className="field">供應商統編/ 編號</div>
                        <div className="value">
                            <input
                                type="text"
                                value={venBIZNo}
                                onChange={venBIZNoChange}
                                maxLength="20"
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item half">
                        <div className="field">公司電話</div>
                        <div className="value">
                            <input
                                type="text"
                                value={venPhone}
                                onChange={(e) => setVenPhone(e.target.value)}
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item half">
                        <div className="field">公司傳真</div>
                        <div className="value">
                            <input
                                type="text"
                                value={venFax}
                                onChange={(e) => setVenFax(e.target.value)}
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item must">
                        <div className="field">公司地址</div>
                        <div className="value">
                            <input
                                type="text"
                                value={venAddress}
                                onChange={(e) => setVenAddress(e.target.value)}
                                data-required
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className={`form-item must half ${isCmpError ? 'show-help' : ''}`}  >
                        <div className="field">負責單位</div>
                        <div className="value">
                            {departmentArray.map((x) => (
                                <div>
                                    <input
                                        type="radio"
                                        id={`822-${x.cmpDivID}`}
                                        name="f822"
                                        value={x.cmpDivID}
                                        key={x.cmpDivID}
                                        onClick={e => setCmpDivID(x.cmpDivID)}
                                        checked={cmpDivID == x.cmpDivID ? true : false}
                                    />
                                    <label htmlFor={`822-${x.cmpDivID}`}>{x.cmpDivName}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇負責單位</span>
                    </div>

                    <div className="form-item must half has-datalist" data-datalist>
                        <div className="field">負責PM</div>
                        <div className="value">
                            <input
                                id="adNameText"
                                type="text"
                                value={adName}
                                onChange={adNameChange}
                                autoComplete="off" data-required />
                            <ul className="datalist scrollbar">
                                {adNameArray.map((x) => (
                                    <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => { setAdName(`${x.enName}(${x.tc_name})`), setAdID(x.id); }} >
                                        <span className="flex-4">{x.enName}({x.tc_name})</span>
                                        <span className="flex-5">{x.mail}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <hr />

                    <div className={`form-item must ${isCustPayError ? 'show-help' : ''}`} >
                        <div className="field">月結天數</div>
                        <div className="value">
                            {settlementArray.map((x) => {
                                if (companyIdArray.find(x => x == 1) != null && x.venMonDay == 90) {

                                }
                                else {
                                    return (
                                        <div>
                                            <input
                                                type="radio"
                                                id={`823-${x.venMonID}`}
                                                name="f823"
                                                value={x.venMonID}
                                                key={x.venMonID}
                                                onClick={e => setSetlementId(x.venMonID)}
                                                checked={setlementId == x.venMonID ? true : false}
                                            />
                                            <label htmlFor={`823-${x.venMonID}`}>
                                                {
                                                    x.venMonDay == "1" ? "即期" : x.venMonDay + "天"
                                                }
                                            </label>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <span className="help-word">請選擇月結天數</span>
                    </div>

                    <div className="form-item col must">
                        <div className="field">匯款銀行</div>
                        <div className="value">
                            <ul className="list-table">
                                <li className="lt-row lt-th">
                                    <div className="flex-1">序號</div>
                                    <div className="flex-3">銀行名稱</div>
                                    <div className="flex-3">分行</div>
                                    <div className="flex-3">SWIFT Code</div>
                                    <div className="flex-3">匯款帳號</div>
                                    <div className="flex-1">幣別</div>

                                    <div className="flex-2 fn"></div>
                                </li>
                                {bankData.map((x, i) => (
                                    <li className="lt-row">
                                        <div className="flex-1">{i < 11 ? `0${i + 1}` : i + 1}</div>
                                        <div className="flex-3">
                                            <input
                                                type="text"
                                                value={x.venBankRemitBank ? x.venBankRemitBank : ""}
                                                onChange={(e) => venBankDataChange(e, i, "venBankRemitBank")}
                                                data-required
                                            />
                                        </div>
                                        <div className="flex-3">
                                            <input
                                                type="text"
                                                value={x.venBankRemitBranch ? x.venBankRemitBranch : ""}
                                                onChange={(e) => venBankDataChange(e, i, "venBankRemitBranch")}
                                                placeholder="非必填"
                                            />
                                        </div>
                                        <div className="flex-3">
                                            <input
                                                type="text"
                                                value={x.venBankRemitSWIFTCode ? x.venBankRemitSWIFTCode : ""}
                                                onChange={(e) => venBankDataChange(e, i, "venBankRemitSWIFTCode")}
                                                placeholder="非必填"
                                            />
                                        </div>
                                        <div className="flex-3">
                                            <input
                                                type="text"
                                                value={x.venBankRemitAcnt ? x.venBankRemitAcnt : ""}
                                                onChange={(e) => venBankDataChange(e, i, "venBankRemitAcnt")}
                                                data-required
                                            />
                                        </div>
                                        <div className="flex-2">
                                            {
                                                x.venBankExRates == null || x.venBankExRates.length == 0 ?
                                                    <div className="btn btn-small btn-border" id={`bank_${i}`} onClick={(e) => openModal10(i)}>
                                                        <span>選擇幣別</span>
                                                    </div>
                                                    :
                                                    <a href="#" onClick={(e) => openModal10(i)}>
                                                        {
                                                            x.venBankExRates.map((x2, i2) =>
                                                                currencyBaseArray.map((x3, i3) =>
                                                                    x3.currID == x2.venBankCurrID ? x3.currName + (i2 == x.venBankExRates.length - 1 ? '' : '/') : null
                                                                )
                                                            )
                                                        }
                                                    </a>


                                            }

                                        </div>

                                        <div className="flex-1 fn">
                                            {bankData.length > 1 ?
                                                <div className="btn btn-icon" onClick={() => { delBankItem(i); }} data-index={i} >
                                                    <i className="icon icon-37"></i>
                                                </div>
                                                : <></>
                                            }
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className="btn-area">
                                <div class="info"></div>
                                <div className="btn btn-middle btn-border" onClick={addBankData} >
                                    <span>新增匯款銀行</span>
                                </div>
                            </div>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item col must">
                        <div className="field">聯絡人</div>
                        <div className="value">
                            <ul className="list-table">
                                <li className="lt-row lt-th">
                                    <div className="flex-1">序號</div>
                                    <div className="flex-3">姓名</div>
                                    <div className="flex-3">連絡電話</div>
                                    <div className="flex-3">聯絡信箱</div>
                                    <div className="flex-1"></div>
                                </li>
                                {contactData.map((x, i) => (
                                    <li className="lt-row">
                                        <div className="flex-1">{i < 11 ? `0${i + 1}` : i + 1}</div>
                                        <div className="flex-3">
                                            <input
                                                type="text"
                                                value={x.venConName ? x.venConName : ""}
                                                onChange={(e) => contactDataChange(e, i, "venConName")}
                                                data-required
                                            />
                                        </div>
                                        <div className="flex-3">
                                            <input
                                                type="tel"
                                                value={x.venConTel ? x.venConTel : ""}
                                                onChange={(e) => contactDataChange(e, i, "venConTel")}
                                                maxLength="12"
                                                data-required
                                            />
                                        </div>
                                        <div className="flex-3">
                                            <input
                                                type="email"
                                                value={x.venConEmail ? x.venConEmail : ""}
                                                onChange={(e) => contactDataChange(e, i, "venConEmail")}
                                                data-required
                                            />
                                        </div>
                                        <div className="flex-1">
                                            {
                                                contactData.length > 1 ?
                                                    <div className="btn btn-icon" onClick={() => { delContactItem(i); }} data-index={i} >
                                                        <i className="icon icon-37"></i>
                                                    </div>
                                                    : <></>
                                            }
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className="btn-area">
                                <div class="info"></div>
                                <div className="btn btn-small btn-border" onClick={addContactData} >
                                    <span>新增聯絡人</span>
                                </div>
                            </div>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item aifs">
                        <div className="field">備註</div>
                        <div className="value">
                            <textarea
                                name="memo"
                                id="memo"
                                cols="30"
                                rows="10"
                                value={memo}
                                onChange={(e) => setMemo(e.target.value)}
                            ></textarea>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item btn-area sp">
                        <div className="left">
                            <a className="btn btn-border" onClick={() => downLoad()}>
                                <span>產出供應商資料卡</span>
                            </a>
                        </div>
                        <div className="right">
                            <a className="btn btn-border" onClick={returnQry}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={Edit}>
                                <span>新增</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-10" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">選擇幣別</h3>
                        <span className="close" onClick={() => CloseModal("modal-10")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-item no-field">
                            <div className="field">選擇幣別</div>
                            <div className="value">
                                {currencyBaseArray.map((x) => (
                                    <div>
                                        <input
                                            type="checkbox"
                                            id={`f827-${x.currID}`}
                                            name="f827"
                                            value={x.currID}
                                            key={x.currID}
                                            onClick={currencyDataChange}
                                        />
                                        <label htmlFor={`f827-${x.currID}`}>{x.currName}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-10")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-add" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={reflash}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupplierNew;
