import React, { Component } from 'react';
import { IntlProvider } from "react-intl"; //多國語言套件
import { LangContext } from "./contexts/LangContext"; //多國語言Context
import { apiLangSetting } from "./global/constants";

export default class I18n extends Component {

    constructor(props) {
        super(props);

        this.getlanguageSetting = this.getlanguageSetting.bind(this); //取得所有語系設定
        this.getLocaleMsg = this.getLocaleMsg.bind(this); //取得語言內容
        this.handleErroeMsg = this.handleErroeMsg.bind(this); //語言套件錯誤訊息

        this.state = {
            messages: "",
            language: navigator.language,
            languageName: "",
            setLanguage: this.setLanguage,
            languageSettingDatas: []
        }
    }

    setLanguage = language => {

        const select = this.state.languageSettingDatas.find((item) => {
            return item.languageCode == language
        });
        ////console.log(language, select.languageName);
        this.setState({ language: language, languageName: select.languageName }, () => {
            this.getLocaleMsg()
        });
    };

    //#region 取得語言設定
    getlanguageSetting() {
        apiLangSetting({
        })
            .then(res => {
                const select = res.data.result.find((item) => {
                    return item.languageCode == this.state.language
                });
                this.setState({ languageName: select.languageName, languageSettingDatas: res.data.result})
            })
            .catch(err => {
                //
            })
    }
    //#endregion

    //#region 取得語言內容
    getLocaleMsg() {
        fetch(`/lang/${this.state.language}.json`
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json()
            })
            .then((rel) => {
                this.setState({ messages: rel })
            })
            .catch(err => {
                //console.log(err)
            })

    }
    //#endregion

    //#region 語言套件錯誤訊息
    handleErroeMsg(event) {
        ////console.log(event)
    }
    //#endregion

    componentDidMount() {
        this.getlanguageSetting();
        this.getLocaleMsg()
    }

    render() {
        return (
            <LangContext.Provider value={{ language: this.state.language, languageName: this.state.languageName, setLanguage: this.state.setLanguage, languageSettingDatas: this.state.languageSettingDatas }}>
                <IntlProvider messages={this.state.messages} locale="zh-TW" onError={this.handleErroeMsg}>
                    {this.props.children}
                </IntlProvider>
            </LangContext.Provider>
        );
    }
}