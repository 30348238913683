import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API_POST_CloudAcntList2 } from "../../global/constants";
import { InitJS, MenuSet, OpenModal } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const CloudAcntList1 = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const [modalStr, setModalStr] = useState();

    useEffect(() => {
        //把menu顏色置換
        MenuSet("CloudAcntQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    function getData() {
        var productNameStr = queryParams.get("productName");
        var parProductName = productNameStr.split(',');
        var parProductArray = [];
        for (var i = 0; i < parProductName.length; i++) {
            parProductArray.push(parProductName[i]);
        }

        var dt = {
            companyId: parseInt(queryParams.get("companyId")),
            personInChargeId: parseInt(queryParams.get("personInChargeId")),
            productName1Array: parProductArray
        }
        API_POST_CloudAcntList2(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "3071") {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                    return;
                }


                if (data.code == "0000") {
                    //負責公司
                    setCompanyName(data.result.data.companyName);
                    setDataList(data.result.data.data);
                }
            })
            .catch((err) => {
                console.log("List1頁面裡用API_POST_CloudAcntList2 catch err", err)
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [companyName, setCompanyName] = useState();
    const [dataList, setDataList] = useState([{}]);
    function goList3(productName1, customerId, personInChargeId) {
        history.push('/Main?path=CloudAcntList3&companyId=' + queryParams.get("companyId") + "&productName1=" + productName1 + "&customerId=" + customerId + "&personInChargeId=" + personInChargeId);
    }

    function goQry() {
        history.push('/Main?path=CloudAcntQry');
    }

    return (
        <>
            <div className="content-body">
                <div className="title-area">
                    <div className="container full">
                        <h3 className="fz-B has-border">隸屬公司：{companyName}</h3>
                    </div>
                </div>
                {
                    dataList.map(x =>
                        <>
                            <div className="container full">
                                <div className="info-holder">
                                    <div className="left fc-realblack">{x.productName1}</div>
                                </div>
                                <div className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-3">帳號 ID</div>
                                        <div className="flex-3">帳號名稱</div>
                                        <div className="flex-3">帳號代號</div>
                                        <div className="flex-3">客戶別</div>
                                        <div className="flex-5">公司抬頭</div>
                                        <div className="flex-3">負責業務</div>
                                    </li>
                                    {
                                        x.accountArray != null && x.accountArray.length > 0 ?
                                            x.accountArray.map(x2 =>
                                                <li className="lt-row">
                                                    <div className="flex-3">
                                                        <div className="elips-1">
                                                            <a href={void (0)} onClick={() => goList3(x.productName1, x2.customerId, x2.personInChargeId)}>{x2.id}</a>
                                                        </div>
                                                    </div>
                                                    <div className="flex-3">
                                                        <div className="elips-1">{x2.name} </div>
                                                    </div>
                                                    <div className="flex-3">
                                                        <div className="elips-1">{x2.code} </div>
                                                    </div>
                                                    <div className="flex-3">
                                                        <div className="elips-1">{x2.customerName}</div>
                                                    </div>
                                                    <div className="flex-5">
                                                        <div className="elips-1">{x2.customerTitle}</div>
                                                    </div>
                                                    <div className="flex-3">
                                                        <div className="elips-1">{x2.personInChargeName}</div>
                                                    </div>

                                                </li>
                                            )
                                            : null
                                    }
                                </div>
                            </div>
                            <br />
                        </>
                    )
                }
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => goQry()}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CloudAcntList1;
