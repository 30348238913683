import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_ProcurementDataCard,
    API_GET_CustomerName,
    API_GET_OrderNumber,
    API_GET_ProcurementSupplier,
    API_GET_SupplierNameList,
    API_POST_ProcurementData,
    API_GET_AdNameList,
} from "../../global/constants";
import { NewNumFormart, OpenModal, CloseModal, InitJS, SetOnbeforeunload, MenuSet, SetHasChange } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const PurchaseRequestNew = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        //把menu顏色置換
        MenuSet("PurchaseRequestQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload("是否放棄新增此單請款單？");
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    //採購公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyId, setCompanyId] = useState();
    useEffect(() => {
        if (companyId) {
            document.getElementById("salesOrderText").value = "";
            setIsStock(false);
            setCustomerName("");
            setCustomerDisabled(false);
            document.getElementById("supplierNameText").value = "";
            setSupplierData([]);
        }
    }, [companyId]);
    const [companyIdError, setCompanyIdError] = useState(false);
    //產品別
    const [productTypeArray, setProductTypeArray] = useState([{}]);
    const [productTypeId, setProductTypeId] = useState();
    //幣別
    const [currencyBaseList, setCurrencyBaseList] = useState([{}]);
    const [currencyId, setCurrencyId] = useState();
    //採購簽呈
    const [purchaseSignatureTypeArray, setPurchaseSignatureTypeArray] = useState([
        { id: 0, name: "定型化契約" },
        { id: 1, name: "合約" },
    ]);
    const [purchaseSignatureType, setPurchaseSignatureType] = useState();
    const [purchaseSignatureTypeError, setPurchaseSignatureTypeError] =
        useState(false);
    //簽呈單號
    const [signatureNumber, setSignatureNumber] = useState();
    //採購類別
    const [purchaseTypeArray, setPurchaseTypeArray] = useState([
        { id: 0, name: "事務性採購" },
        { id: 1, name: "業務性採購" },
    ]);
    const [purchaseType, setPurchaseType] = useState();
    const [purchaseTypeError, setPurchaseTypeError] = useState(false);
    useEffect(() => {
        if (purchaseType == 0) {
            //document.getElementById("salesOrderText").classList.remove("error");
            document.getElementById("salesOrderText").removeAttribute("data-required");
            //document.getElementById("customerNameText").classList.remove("error");
            document.getElementById("customerNameText").removeAttribute("data-required");
        }
    }, [purchaseType]);


    //印花稅
    const [taxIdArray, setTaxIdArray] = useState([
        { id: 0, name: "無" },
        { id: 1, name: "銷售 (12元)" },
        { id: 2, name: "服務 (1/1000)" },
    ]);
    const [taxId, setTaxId] = useState();
    const [taxIdError, setTaxIdError] = useState(false);
    //銷售訂單
    const [salesOrder, setSalesOrder] = useState();
    const [isStock, setIsStock] = useState(false);
    function isStockChange(e) {
        var isCheck = !isStock;
        setIsStock(isCheck);
        if (isCheck) {
            //銷售訂單
            var salesOrderElem = document.querySelector("#salesOrderText");
            salesOrderElem.removeAttribute("data-required");
            setCustomerDisabled(false);
        } else {
            //console.log(document.getElementById("salesOrderText").value);
            if (document.getElementById("salesOrderText").value != '') {
                setCustomerDisabled(true);
            }
        }
    }

    const [orderNumberArray, setOrderNumberArray] = useState([{}]);
    function salesOrderChange(e) {
        setSalesOrder(e.target.value);
        if (e.target.value != '') {
            setCustomerName("");
            setCustomerDisabled(true);
        } else {
            setCustomerDisabled(false);
        }

        //共用K
        getOrderNumber();
    }
    //銷售客戶
    const [customerName, setCustomerName] = useState();
    const [customerID, setCustomerID] = useState();
    const [customerDisabled, setCustomerDisabled] = useState(false);
    const [customerNameArray, setCustomerNameArray] = useState([{}]);
    function customerNameChange(e) {
        setCustomerName(e.target.value);
        //共用D
        getCustomerName();
    }
    //主旨
    const [purchaseOrderSubject, setPurchaseOrderSubject] = useState();
    //採購說明
    const [purchaseOrderInstruction, setPurchaseOrderInstruction] = useState();
    //MA日期
    const [MADateStart, setMADateStart] = useState();
    const [MADateEnd, setMADateEnd] = useState();
    //AD人
    const [AdName, setAdName] = useState();
    const [AdID, setAdID] = useState();

    const [AdNameArray, setAdNameArray] = useState([{}]);
    function AdNameChange(e) {
        setAdName(e.target.value);
        API_GET_AdNameList({
            input: e.target.value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    if (data.result.data != null) {
                        setAdNameArray(data.result.data);
                    }
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
    }
    //請購日期
    const [purchaseRequisitionDate, setPurchaseRequisitionDate] = useState();
    //請購單號
    const [purchaseRequisitionNumber, setPurchaseRequisitionNumber] = useState();
    //請購金額
    const [purchaseRequisitionPrice, setPurchaseRequisitionPrice] = useState();
    function purchaseRequisitionPriceChange(e) {
        let tmp = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
        setPurchaseRequisitionPrice(!isNaN(tmp) ? tmp : "");
    }
    //採購日期
    const [purchaseOrderDate, setPurchaseOrderDate] = useState();
    //採購金額
    const [purchaseAmount, setPurchaseAmount] = useState();
    function purchaseAmountChange(e) {
        let tmp = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
        setPurchaseAmount(!isNaN(tmp) ? tmp : "");
    }

    //銷售金額
    const [salesAmount, setSalesAmount] = useState();
    function salesAmountChange(e) {
        let tmp = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
        setSalesAmount(!isNaN(tmp) ? tmp : "");
    }

    //議價
    const [negotiatedPrice, setNegotiatedPrice] = useState();
    //議價計算
    useEffect(() => {
        //請購金額
        let tempPurchaseRequisitionPrice = purchaseRequisitionPrice && !isNaN(parseFloat(purchaseRequisitionPrice)) ? parseFloat(purchaseRequisitionPrice) : "";
        //採購金額
        let tempPurchaseAmount = purchaseAmount && !isNaN(parseFloat(purchaseAmount)) ? parseFloat(purchaseAmount) : "";

        if (tempPurchaseRequisitionPrice != "" && tempPurchaseAmount != "") {
            setNegotiatedPrice(parseFloat(((tempPurchaseRequisitionPrice - tempPurchaseAmount) / tempPurchaseRequisitionPrice * 100).toFixed(1)) + "%");
        }
        else {
            setNegotiatedPrice("0%");
        }
    }, [purchaseRequisitionPrice, purchaseAmount]);

    //毛利
    const [ml, setML] = useState();
    //毛利計算
    useEffect(() => {
        //銷售金額
        let tempSalesAmount = salesAmount && !isNaN(parseFloat(salesAmount)) ? parseFloat(salesAmount) : "";
        //採購金額
        let tempPurchaseAmount = purchaseAmount && !isNaN(parseFloat(purchaseAmount)) ? parseFloat(purchaseAmount) : "";

        if (tempSalesAmount != "" && tempPurchaseAmount != "") {
            setML(parseFloat(((tempSalesAmount - tempPurchaseAmount) / tempSalesAmount * 100).toFixed(1)) + "%");
        }
        else {
            setML("0%");
        }
    }, [salesAmount, purchaseAmount]);

    //備註
    const [remark, setRemark] = useState();
    //供應商備註
    const [supplierRemark, setSupplierRemark] = useState();
    //請款單號
    const [askPaymentNumber, setAskPaymentNumber] = useState();
    //聯絡人資料
    const [supplierData, setSupplierData] = useState([]);
    //請款日期
    const [paymentDate, setPaymentDate] = useState();

    //攤提
    const [amortTime, setAmortTime] = useState();
    const [amortUnit, setAmortUnit] = useState();

    function amortChange(x) {
        setSalesOrder(x.name);
        setAmortTime(x.amortTime);
        setAmortUnit(x.amortUnit);
        document.getElementById("customerNameText").value = x.custName;
        setCustomerName(x.custName);
        setCustomerID(x.custId);
        setPurchaseRequisitionPrice(x.salesCost);
        setSalesAmount(x.salesOrderTotalAmount);
    }
    const [modalStr, setModalStr] = useState();

    //品項
    const [productDetail, setProductData] = useState([]);
    function AddProduct() {

        setProductData(function (prev) {
            return [...prev, { itemName: null, itemQuantity: null, itemPrice: null }];
        });
    }
    function delProduct(index) {
        let newProductDetail = productDetail.map((x, i) => {
            if (i !== index) {
                return x;
            } else {
                return null;
            }
        }).filter(x => x !== null);

        setProductData(newProductDetail);
    }
    function itemDataChange(e, index, col) {
        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "itemName") {
                        item.itemName = e.target.value;
                    } else if (col == "itemQuantity") {
                        let tmp = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
                        item.itemQuantity = !isNaN(tmp) ? tmp : "";
                    } else if (col == "itemPrice") {
                        let tmp = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
                        item.itemPrice = !isNaN(tmp) ? tmp : "";
                    }
                }
                return item;
            });
        });
    }

    const [supplierNameList, setSupplierNameList] = useState([]);
    const [supplierID, setSupplierID] = useState();
    //供應商列表
    function supplierNameChange(e) {

        if (e.target.value == "") {
            setSupplierData([]);
        }

        API_GET_SupplierNameList({
            input: e.target.value,
            companyId: companyId
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setSupplierNameList(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });;
    }
    function supplierNameListChange(id, name) {

        setSupplierID(id);
        //呼叫10-4
        API_GET_ProcurementSupplier({
            supplierName: name,
            companyId: companyId
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setSupplierData(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });;
    }

    //請購單號 共用K
    function getOrderNumber() {

        API_GET_OrderNumber({
            input: document.getElementById("salesOrderText").value,
            companyId: companyId
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                if(data.code == "4038"){
                    return;
                }

                setOrderNumberArray(data.result.data);
            })
            .catch((err) => {
                //console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //銷售客戶 共用D
    function getCustomerName() {

        API_GET_CustomerName({
            input: document.getElementById("customerNameText").value,
            companyId: companyId
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setCustomerNameArray(data.result.data);
                }
            })
            .catch((err) => {
                //console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });;
    }
    function returnQry() {
        var path = "/Main?path=PurchaseRequestQry";
        history.push(path);
    }

    //var moment = require('moment');
    function getData() {
        API_GET_ProcurementDataCard()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //負責公司
                    setCompanyArray(data.result.data.companyArray);
                    //產品別
                    setProductTypeArray(data.result.data.productTypeArray);
                    //幣別
                    setCurrencyBaseList(data.result.data.currencyBaseList);
                }
            })
            .catch((err) => {
                //console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        //if (queryParams.get('isSuccess') == "1") {
        //    OpenModal("modal-success");
        //}
    }
    function submit(e) {
        e.target.classList.add("disabled");
        var result = checkData();
        if (!result) {
            e.target.classList.remove("disabled");
            return;
        }
        //採購金額與明細金額要相同
        var tmpSum = 0;
        for (var i = 0; i < productDetail.length; i++) {
            tmpSum = tmpSum + (parseFloat(productDetail[i].itemPrice * productDetail[i].itemQuantity));
        }
        if (purchaseAmount && purchaseAmount != "") {
            if (purchaseAmount != tmpSum) {
                setModalStr("採購金額與品項明細金額不同");
                OpenModal("modal-error");
                e.target.classList.remove("disabled");
                return;
            }
        }
        var dt = {
            companyId: parseInt(companyId), //公司
            contractId: purchaseSignatureType, //採購簽呈
            contractNumber: (signatureNumber == null ? "" : signatureNumber), //簽呈單號
            contractType: purchaseType, //採購分類
            taxId: taxId, //印花稅
            salesOrder: document.getElementById("salesOrderText").value, //銷售訂單
            isStock: isStock ? 1 : 0, //是否存貨
            salesCustomerID: customerID, // 銷售客戶(客戶別)ID
            productTypeId: parseInt(productTypeId), //產品別ID
            MADateStart: MADateStart, //MA日期起始
            MADateEnd: MADateEnd,
            purchaseOrderSubject: purchaseOrderSubject, //採購主旨
            purchaseOrderInstruction: purchaseOrderInstruction, //採購說明
            purchaseRequisitionADID: AdID, //請購申請人ID
            purchaseRequisitionDate: purchaseRequisitionDate, //請購日期
            purchaseRequisitionNumber: purchaseRequisitionNumber, //請購單號
            purchaseRequisitionPrice: purchaseRequisitionPrice, //請購金額
            purchaseOrderDate: purchaseOrderDate, //採購日期
            currencyId: (currencyId != null ? parseInt(currencyId) : null), //幣別id
            purchaseOrderPrice: purchaseAmount, //採購金額
            salesAmount: salesAmount, //銷售金額
            paymentNumber: askPaymentNumber, //請款單號
            paymentDate: paymentDate, //請款日期
            remark: remark, //備註
            supplierID: supplierID, //供應商ID
            supplierRemark: supplierRemark, //供應商備註
            itemDetail: productDetail, //品項明細
        };
        //console.log(JSON.stringify(dt));
        API_POST_ProcurementData(dt)
            .then((response) => response.data)
            .then((data) => {
                e.target.classList.remove("disabled");
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    OpenModal("modal-success");
                    window.onbeforeunload = null;
                    SetHasChange(false);
                    //window.location.href = "/Main?path=PurchaseRequestNew&isSuccess=1";
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {
                e.target.classList.remove("disabled");
                //console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //資料檢查
    function checkData() {
        var result = true;
        //業務性採購則銷售訂單/銷售客戶=>必填
        if (purchaseType == 1) {
            //銷售訂單
            var salesOrderElem = document.querySelector("#salesOrderText");
            if (!isStock) { //未勾選「為存貨」
                salesOrderElem.dataset.required = true;
            }
            else {
                salesOrderElem.classList.remove("error");
            }
            //銷售客戶
            var elem = document.querySelector("#customerNameText");
            elem.dataset.required = true;
        } else {
            //銷售訂單
            var salesOrderElem = document.querySelector("#salesOrderText");
            salesOrderElem.removeAttribute("data-required");
            salesOrderElem.classList.remove("error");
            //銷售客戶
            var elem = document.querySelector("#customerNameText");
            elem.removeAttribute("data-required");
            elem.classList.remove("error");
        }

        //如果產品別=8 MA期間要變必填
        if (document.getElementById("productType").value == "8") {
            var elem = document.querySelector("#MADateStart");
            elem.dataset.required = true;
            elem = document.querySelector("#MADateEnd");
            elem.dataset.required = true;
        } else {
            var elem = document.querySelector("#MADateStart");
            elem.removeAttribute("data-required");
            elem = document.querySelector("#MADateEnd");
            elem.removeAttribute("data-required");
        }

        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {
            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });
        //採購公司
        if (companyId == undefined) {
            setCompanyIdError(true);
            result = false;
        } else {
            setCompanyIdError(false);
        }
        //採購簽呈
        if (purchaseSignatureType == undefined) {
            setPurchaseSignatureTypeError(true);
            result = false;
        } else {
            setPurchaseSignatureTypeError(false);
        }
        //採購分類
        if (purchaseType == undefined) {
            setPurchaseTypeError(true);
            result = false;
        } else {
            setPurchaseTypeError(false);
        }
        //印花稅
        if (taxId == undefined) {
            setTaxIdError(true);
            result = false;
        } else {
            setTaxIdError(false);
        }

        return result;
    }

    function reflash() {
        window.location.reload();
    }

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">新增採購紀錄</h3>
                </div>
            </div>
            <div className="container">
                <div className="form-container">
                    <div className={`form-item must${companyIdError == true ? " show-help" : ""}`}  >
                        <div className="field">採購公司</div>
                        <div className="value">
                            {companyArray.map((x) => (
                                <div>
                                    <input
                                        type="radio"
                                        id={`f1021-${x.id}`}
                                        name="f1021"
                                        value={x.id}
                                        checked={companyId == x.id ? true : false}
                                        onClick={(e) => setCompanyId(x.id)}
                                    />
                                    <label htmlFor={`f1021-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇負責公司</span>
                    </div>
                    <div className={`form-item must half ${purchaseSignatureTypeError == true ? " show-help" : ""}`} >
                        <div className="field">採購簽呈</div>
                        <div className="value">
                            {purchaseSignatureTypeArray.map((x) => (
                                <div>
                                    <input
                                        type="radio"
                                        id={`f1022-${x.id}`}
                                        name="f1022"
                                        value={x.id}
                                        checked={purchaseSignatureType == x.id ? true : false}
                                        onClick={(e) => setPurchaseSignatureType(x.id)}
                                    />
                                    <label htmlFor={`f1022-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇採購簽呈</span>
                    </div>
                    <div className="form-item half">
                        <div className="field">採購單號</div>
                        <div className="value">
                            <input type="text" value={signatureNumber} onChange={(e) => setSignatureNumber(e.target.value.replace(/,/g, ""))} />
                        </div>
                        <span className="help-word">請選擇採購單號</span>
                    </div>

                    <div className={`form-item must half ${purchaseTypeError == true ? " show-help" : ""}`} >
                        <div className="field">採購分類</div>
                        <div className="value">
                            {purchaseTypeArray.map((x) => (
                                <div>
                                    <input
                                        type="radio"
                                        id={`f1023-${x.id}`}
                                        name="f1023"
                                        value={x.id}
                                        checked={purchaseType == x.id ? true : false}
                                        onClick={(e) => setPurchaseType(x.id)}
                                    />
                                    <label htmlFor={`f1023-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇採購分類</span>
                    </div>

                    <div className={`form-item must half ${taxIdError == true ? " show-help" : ""}`} >
                        <div className="field">印花稅</div>
                        <div className="value">
                            {taxIdArray.map((x) => (
                                <div>
                                    <input
                                        type="radio"
                                        id={`f1024-${x.id}`}
                                        name="f1024"
                                        value={x.id}
                                        checked={taxId == x.id ? true : false}
                                        onClick={(e) => setTaxId(x.id)}
                                    />
                                    <label htmlFor={`f1024-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇印花稅</span>
                    </div>

                    <div className={`form-item  half has-datalist ${purchaseType == 1 ? " must" : ""}`} data-datalist>
                        <div className="field">銷售訂單</div>
                        <div className="value multi-inline">
                            <div className="flex-3">
                                <input
                                    type="text"
                                    id="salesOrderText"
                                    name="salesOrderText"
                                    onChange={salesOrderChange}
                                    disabled={!companyId ? true : false}
                                />
                                <ul className="datalist scrollbar">
                                    {orderNumberArray.map((x) => (
                                        <li data-value={x.name} onClick={(e) => amortChange(x)}  >
                                            <span className="flex-10 bold">{x.name}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="last flex-1">
                                <input
                                    type="checkbox"
                                    id="isStock"
                                    name="isStock"
                                    onChange={isStockChange}
                                    checked={isStock ? true : false}
                                    disabled={!companyId ? true : false}
                                />
                                <label htmlFor="isStock">為存貨</label>
                            </div>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div
                        className={`form-item  half has-datalist ${purchaseType == 1 ? " must" : ""
                            }`}
                        data-datalist
                    >
                        <div className="field">銷售客戶</div>
                        <div className="value">
                            <input
                                type="text"
                                id="customerNameText"
                                name="customerNameText"
                                value={customerName}
                                onChange={customerNameChange}
                                disabled={!companyId || customerDisabled ? true : false}
                                data-required
                            />
                            <ul className="datalist scrollbar">
                                {customerNameArray.map((x) => (
                                    <li data-value={x.name} onClick={(e) => { setCustomerID(x.id), setCustomerName(x.name); }}>
                                        <span className="flex-10 bold">{x.name}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item must half">
                        <div className="field">產品別</div>
                        <div className="value">
                            <select name="productType" id="productType" data-required onChange={(e) => setProductTypeId(e.target.value)} >
                                <option value="" selected disabled>請選擇</option>
                                {productTypeArray.map((x) => (
                                    <option value={x.id}>{x.name}</option>
                                ))}
                            </select>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div
                        className={`form-item half ${productTypeId == "8" ? "must" : ""}`}
                    >
                        <div className="field">MA 期間</div>
                        <div className="value multi-inline">
                            <div className="flex-5">
                                <input
                                    type="date"
                                    id="MADateStart"
                                    value={MADateStart}
                                    min="2010-01-01"
                                    max="2099-12-31"
                                    onKeyDown={(e) => { e.target.value == "" ? setMADateStart(e.target.value) : null }}
                                    onChange={(e) => setMADateStart(e.target.value)}
                                    data-required
                                />
                            </div>
                            <div className="flex-1 center">~</div>
                            <div className="flex-5 last">
                                <input
                                    type="date"
                                    id="MADateEnd"
                                    value={MADateEnd}
                                    min="2010-01-01"
                                    max="2099-12-31"
                                    onKeyDown={(e) => { e.target.value == "" ? setMADateEnd(e.target.value) : null }}
                                    onChange={(e) => setMADateEnd(e.target.value)}
                                    data-required
                                />
                            </div>
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <div className="form-item must half">
                        <div className="field">採購主旨</div>
                        <div className="value">
                            <input
                                type="text"
                                value={purchaseOrderSubject}
                                onChange={(e) => setPurchaseOrderSubject(e.target.value)}
                                data-required
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item aifs">
                        <div className="field">採購說明</div>
                        <div className="value">
                            <textarea
                                name="purchaseOrderInstruction"
                                id="purchaseOrderInstruction"
                                cols="30"
                                rows="3"
                                value={purchaseOrderInstruction}
                                onChange={(e) => setPurchaseOrderInstruction(e.target.value)}
                            ></textarea>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <hr />

                    <div className="form-item half must has-datalist" data-datalist>
                        <div className="field">請購申請人</div>
                        <div className="value">
                            <input
                                type="text"
                                id="AdNameText"
                                onChange={AdNameChange}
                                data-required
                            />
                            <ul className="datalist scrollbar">
                                {AdNameArray.map((x) => (
                                    <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => setAdID(x.id)}>
                                        <span className="flex-5 bold">{x.enName} ({x.tc_name})</span>
                                        <span className="flex-5">{x.mail}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item must half">
                        <div className="field">請購日期</div>
                        <div className="value">
                            <input
                                type="date"
                                value={purchaseRequisitionDate}
                                min="2010-01-01"
                                max="2099-12-31"
                                onKeyDown={(e) => { e.target.value == "" ? setPurchaseRequisitionDate(e.target.value) : null }}
                                onChange={(e) => setPurchaseRequisitionDate(e.target.value)}
                                data-required
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item must  half">
                        <div className="field">請購單號</div>
                        <div className="value">
                            <input
                                type="text"
                                value={purchaseRequisitionNumber}
                                onChange={(e) => setPurchaseRequisitionNumber(e.target.value)}
                                data-required
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item must half">
                        <div className="field">請購金額(含稅)</div>
                        <div className="value multi-inline">
                            <div className="flex-3">
                                <input
                                    type="text"
                                    defaultValue={NewNumFormart(purchaseRequisitionPrice)}
                                    onChange={purchaseRequisitionPriceChange}
                                    onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                    onBlur={(e) => e.target.value = NewNumFormart(purchaseRequisitionPrice)}
                                    data-required
                                />
                            </div>
                            {amortTime != null && amortUnit != "" ? (
                                <div className="info flex-1">
                                    <span>
                                        攤提 {amortTime} {amortUnit}
                                    </span>
                                </div>
                            ) : null}
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <hr />
                    <div className="form-item  half">
                        <div className="field">採購日期</div>
                        <div className="value">
                            <input
                                type="date"
                                value={purchaseOrderDate}
                                min="2010-01-01"
                                max="2099-12-31"
                                onKeyDown={(e) => { e.target.value == "" ? setPurchaseOrderDate(e.target.value) : null }}
                                onChange={(e) => setPurchaseOrderDate(e.target.value)}

                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item  half">
                        <div className="field">幣別</div>
                        <div className="value">
                            <select
                                name="currencyBase"
                                id="currencyBase"
                                className="radius"
                                onChange={(e) => setCurrencyId(e.target.value)}

                            >
                                <option value="">請選擇</option>
                                {currencyBaseList.map((x) => (
                                    <option value={x.id}>{x.name}</option>
                                ))}
                            </select>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item  half">
                        <div className="field">採購金額(含稅)</div>
                        <div className="value multi-inline">
                            <div className="flex-5">
                                <input type="text"
                                    //value={NewNumFormart(purchaseAmount)}
                                    onChange={purchaseAmountChange}
                                    onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                    onBlur={(e) => e.target.value = NewNumFormart(purchaseAmount)}
                                />
                            </div>
                            <div className="flex-1">議價</div>
                            <div className="flex-3 last">
                                <input
                                    type="text"
                                    value={negotiatedPrice}
                                    placeholder="0%"
                                    disabled
                                />
                            </div>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item  half">
                        <div className="field">銷售金額(含稅)</div>
                        <div className="value multi-inline">
                            <div className="flex-5">
                                <input
                                    type="text"
                                    defaultValue={NewNumFormart(salesAmount)}
                                    onChange={salesAmountChange}
                                    onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                    onBlur={(e) => e.target.value = NewNumFormart(salesAmount)}
                                />
                            </div>
                            <div className="flex-1">毛利</div>
                            <div className="flex-3 last">
                                <input type="text" value={ml} placeholder="0%" disabled />
                            </div>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item  half">
                        <div className="field">請款單號</div>
                        <div className="value">
                            <input
                                type="text"
                                value={askPaymentNumber}
                                onChange={(e) => setAskPaymentNumber(e.target.value)}

                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item  half">
                        <div className="field">請款日期</div>
                        <div className="value">
                            <input
                                type="date"
                                value={paymentDate}
                                min="2010-01-01"
                                max="2099-12-31"
                                onKeyDown={(e) => { e.target.value == "" ? setPaymentDate(e.target.value) : null }}
                                onChange={(e) => setPaymentDate(e.target.value)}

                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item aifs">
                        <div className="field">備註</div>
                        <div className="value">
                            <textarea
                                name="remark"
                                id="remark"
                                cols="30"
                                rows="3"
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                            ></textarea>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <hr />

                    <div className="form-item col">
                        <div className="field">品項明細</div>
                        <div className="value">
                            <ul className="list-table">
                                <li className="lt-row lt-th">
                                    <div className="flex-1 max1">序號</div>
                                    <div className="flex-6">品名 _ 規格</div>
                                    <div className="flex-2">數量</div>
                                    <div className="flex-2">單價(含稅)</div>
                                    <div className="flex-3">金額(含稅)</div>
                                    <div className="flex-1 fn"></div>
                                </li>
                                {productDetail.map((x, i) => (
                                    <>
                                        <li className="lt-row">
                                            <div className="flex-1 max1">
                                                {i < 9 ? "0" + (i + 1) : i + 1}
                                            </div>
                                            <div className="flex-6">
                                                <input
                                                    type="text"
                                                    value={productDetail[i].itemName}
                                                    onChange={(e) => itemDataChange(e, i, "itemName")}
                                                />
                                            </div>
                                            <div className="flex-2"> {/*數量*/}
                                                <input
                                                    type="text"
                                                    value={productDetail[i].itemQuantity}
                                                    onClick={e => e.target.select()}
                                                    onChange={(e) => itemDataChange(e, i, "itemQuantity")}
                                                    onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                                    onBlur={(e) => e.target.value = NewNumFormart(productDetail[i].itemQuantity)}
                                                />
                                            </div>
                                            <div className="flex-2"> {/*單價*/}
                                                <input
                                                    type="text"
                                                    value={productDetail[i].itemPrice}
                                                    onClick={e => e.target.select()}
                                                    onChange={(e) => itemDataChange(e, i, "itemPrice")}
                                                    onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                                    onBlur={(e) => e.target.value = NewNumFormart(productDetail[i].itemPrice)}
                                                />
                                            </div>
                                            <div className="flex-3">
                                                <input
                                                    type="text"
                                                    value={NewNumFormart(
                                                        productDetail[i].itemPrice *
                                                        productDetail[i].itemQuantity
                                                    )}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="flex-1 fn">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => delProduct(i)}
                                                    >
                                                        <i className="icon icon-37"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </>
                                ))}
                            </ul>
                            <div className="btn-area">
                                <div className="info"></div>
                                <div className="btn btn-middle btn-border" onClick={AddProduct}>
                                    <span>新增品項</span>
                                </div>
                            </div>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <hr />

                    <div className="form-item half must has-datalist" data-datalist>
                        <div className="field">供應商</div>
                        <div className="value multi-inline">
                            <input
                                type="text"
                                id="supplierNameText"
                                onChange={(e) => supplierNameChange(e)}
                                disabled={!companyId ? true : false}
                                data-required
                            />
                            <ul className="datalist scrollbar">
                                {
                                    supplierNameList == null ? null :
                                        supplierNameList.map((x) => (
                                            <li data-value={x.name} onClick={(e) => supplierNameListChange(x.id, x.name)}   >
                                                <span className="flex-10 bold">{x.name}</span>
                                            </li>
                                        ))}
                            </ul>
                            <div className="btn-area">
                                <a className="btn btn-middle btn-border" href="/Main?path=SupplierNew" target="_blank">
                                    <span>新增</span>
                                </a>
                            </div>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item half"></div>
                    <div className="form-item col must">
                        <div className="field">聯絡人</div>
                        <div className="value">
                            <ul className="list-table">
                                <li className="lt-row lt-th">
                                    <div className="flex-1 max1">序號</div>
                                    <div className="flex-3">姓名</div>
                                    <div className="flex-3">聯絡電話</div>
                                    <div className="flex-3">聯絡信箱</div>
                                </li>
                                {
                                    supplierData == null ? null :
                                        supplierData.map((x, i) => (
                                            <li className="lt-row">
                                                <div className="flex-1 max1">
                                                    {i < 9 ? "0" + (i + 1) : i + 1}
                                                </div>
                                                <div className="flex-3">{x.name}</div>
                                                <div className="flex-3">{x.phone}</div>
                                                <div className="flex-3">{x.email}</div>
                                            </li>
                                        ))}
                            </ul>
                            {/*<div className="btn-area">*/}
                            {/*    <div className="info">*/}

                            {/*    </div>*/}
                            {/*    <div className="btn btn-middle btn-border">*/}
                            {/*        <span>新增品項</span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item aifs">
                        <div className="field">供應商備註</div>
                        <div className="value">
                            <textarea
                                name="supplierRemark"
                                id="supplierRemark"
                                value={supplierRemark}
                                onChange={(e) => setSupplierRemark(e.target.value)}
                                cols="30"
                                rows="3"
                            ></textarea>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item btn-area sp">
                        <div className="left"></div>
                        <div className="right">
                            <a className="btn btn-border" onClick={returnQry}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={submit}>
                                <span>新增</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-success" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">新增成功</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => reflash()}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseRequestNew;
