import React, { useState, useEffect, useRef } from "react";

import {
    API_HOST,
    API_GET_SaleOrderData,
    API_DEL_SaleOrderData,
    API_GET_Customer3Name,
    API_POST_SaleOrderCustomerBalance,
    API_GET_AdNameList,
    API_GET_CurrencyComExchangeRate,
    API_GET_CompanyProductType,
    API_GET_ProductName1,
    API_GET_ProductName2,
    API_GET_SaleOrderRecord,
    API_GET_HomeData
} from "../../global/constants";
import { NewNumFormart, NumFormart, CloseModal, OpenModal, InitJS, SetOnbeforeunload, MenuSet, SetHasChange } from "../../global/ToolUtil";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
import HtmlParse from 'html-react-parser';


const SaleOrderEdit = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    var moment = require("moment");
    const openedInvState = useRef([1, 4, 6, 7]);

    const [isMonth1, setIsMonth1] = useState(0);
    const [isMonth2, setIsMonth2] = useState(0);

    const [dataLoaded, setDataLoaded] = useState(false);

    //訂單編號
    const [orderNumber, setOrderNumber] = useState("");
    const [orderCreateDate, setOrderCreateDate] = useState("");
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyId, setCompanyId] = useState();
    const [costToOther, setCostToOther] = useState();

    //公司匯率表
    const [exCurrID, setExCurrID] = useState();
    const [exchangeRateArray, setExchangeRateArray] = useState([]);
    //產品別
    const [productTypeArray, setProductTypeArray] = useState([{}]);
    //幣別
    const [currencyBaseArray, setCurrencyBaseArray] = useState([]);
    const [currencyEName, setCurrencyEName] = useState();
    //總金額
    const [totalAmount, setTotalAmount] = useState();
    const [exchangeError, setExchangeError] = useState(false);
    //上傳檔案
    const [selectedFile, setSelectedFile] = useState(null);

    //採購請款ID
    const [soProcApplyID, setSOProcApplyID] = useState();

    const [dateArray, setDateArray] = useState([]);

    //客戶別
    const [custName, setCustName] = useState("");
    const [custNameArray, setCustNameArray] = useState([{}]);

    //客戶抬頭
    const [custTitle, setCustTitle] = useState("");
    const [custTitleArray, setCustomerTitleArray] = useState([{}]);

    const [custTaxID, setCustTaxID] = useState("");
    const [custTaxIDArray, setCustomerTaxArray] = useState([{}]);

    //保證金餘額
    const [margin, setMargin] = useState();
    //信用額度
    const [creditFacilities, setCreditFacilities] = useState();
    //信用餘額
    const [creditBalance, setCreditBalance] = useState();
    //儲值金額
    const [advance, setAdvance] = useState();
    //可交易餘額
    const [tradableBalance, setTradableBalance] = useState();

    const [custID, setCustID] = useState();
    //資安成本金額
    const [orderCost, setOrderCost] = useState();

    //是否請購
    const [isPurchase, setIsPurchase] = useState();
    //額度選項
    const [priorityPayment, setPriorityPayment] = useState();
    //備註
    const [remark, setRemark] = useState();

    const [adNameState, setAdNameState] = useState(1);
    const [adNameArray, setADNameArray] = useState([{}]);
    const [adName, setAdName] = useState();
    const [adID, setAdID] = useState();
    const [modalStr, setModalStr] = useState();

    const [originalSalesType, setOriginalSalesType] = useState(null)
    const [newProductSalesType, setNewProductSalesType] = useState(null)

    const [mainDataLock, setMainDataLock] = useState(false);
    const [allDetailLock, setAllDetailLock] = useState(false);

    //歷史紀錄
    const [qryList, setQryList] = useState([{}]);

    const [saleTypeArray, setSaleTypeArray] = useState([
        { value: 0, name: "月租型" },
        { value: 1, name: "一次型" },
        { value: 2, name: "攤提型" }
    ]);

    const [invStateArray, setInvStateArray] = useState([
        { id: 0, name: "未開" },
        { id: 1, name: "預開" },
        { id: 2, name: "列管" },
        { id: 3, name: "作廢" },
        { id: 4, name: "作廢重開" },
        { id: 5, name: "處理中" },
        { id: 6, name: "已開" },
        { id: 7, name: "列管已開" },
        { id: 8, name: "作廢重開已開" },
        { id: 100, name: "為預開(未開)" },
    ]);

    //產品品項
    const [productData, setProductData] = useState([
        {
            spid: null,
            prodTPID: null,
            productName1: null,
            productName2: null,
            spQuantity: null,
            spUnitPrice: null,
            spCurrID: null,
            spInvState: null,
            spRem: null,
            exchangeRate: null,
            subTotal1: null,
            subTotal2: null,
            spModiStamp: null,
            isDelete: false,
            productName1Array: [{}],
            productName2Array: [{}],
        },
    ]);

    //抓取資料
    function getData() {
        API_GET_SaleOrderData({
            orderId: queryParams.get("orderId"),
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "4017" || data.code == "4038") {
                    //"找不到相對應的訂單id。"
                    setModalStr(data.message);
                    OpenModal("modal-success2");
                    return;
                }

                setExchangeRateArray(data.result.data.exchangeRateArray);
                setExCurrID(data.result.data.exchangeRateArray.find(x => x.exRateRate == 1).exCurrID);
                setCompanyId(data.result.data.companyId); //負責公司
                var newProductData = resetAmount_2(
                    data.result.data.orderProduct,
                    data.result.data.exchangeRateArray.find(x => x.exRateRate == 1).exCurrID,
                    data.result.data.currencyArray,
                    data.result.data.exchangeRateArray);

                if (newProductData.length > 0) {
                    newProductData.forEach(item => {
                        item.isNew = false;
                        item.isDelete = false;
                    });
                }

                newProductData.map((item, i) => {
                    item.index = i + 1;
                    return item;
                });


                setProductData(newProductData); //產品品項
                //--------------------------------
                setCompanyArray(data.result.data.companyArray);
                setProductTypeArray(data.result.data.productTypeArray);
                setCurrencyBaseArray(data.result.data.currencyArray);
                setCurrencyEName(data.result.data.currencyEName);
                setOrderNumber(data.result.data.orderNumber);
                setOrderCreateDate(data.result.data.createdTime != null ? data.result.data.createdTime.split('T')[0].replace(/-/g, "/") : "");

                setCostToOther(data.result.data.costToOther); //經銷類別
                setCustID(data.result.data.customerId); //客戶ID
                setCustName(data.result.data.customerName); //客戶別
                setCustTitle(data.result.data.customerTitle); //客戶抬頭
                setCustTaxID(data.result.data.customerTaxId); //客戶統編
                setAdName(data.result.data.personInChargeName); //負責業務
                setAdID(data.result.data.adid); //負責業務ID
                setPriorityPayment(data.result.data.priorityPayment); //額度選項
                setMargin(data.result.data.currencyEName + " " + NumFormart(data.result.data.margin)); //保證金餘額
                setCreditFacilities(data.result.data.currencyEName + " " + NumFormart(data.result.data.creditFacilities)); //信用額度
                setCreditBalance(data.result.data.currencyEName + " " + NumFormart(data.result.data.creditBalance)); //信用餘額
                if (data.result.data.advance != "null") {
                    setAdvance(data.result.data.currencyEName + " " + NumFormart(data.result.data.advance)); //儲值金額
                }
                else {
                    setAdvance(data.result.data.currencyEName + " 0"); //儲值金額
                }
                setTradableBalance(data.result.data.currencyEName + " " + NumFormart(data.result.data.tradableBalance)); //可交易餘額
                setOrderCost(data.result.data.orderCost); //協消成本
                setIsPurchase(data.result.data.isPurchase); //是否請夠
                setTotalAmount(data.result.data.currencyEName + " " + NewNumFormart(parseFloat(data.result.data.orderTotalAmount))); //訂單總金額
                setSOProcApplyID(data.result.data.soProcApplyId); //採購請款ID
                setRemark(data.result.data.remark);

                //產品別
                API_GET_CompanyProductType({
                    companyId: data.result.data.companyId,
                })
                    .then((response) => response.data)
                    .then((data) => {
                        if (!checkAuthToken(data)) {
                            history.push("/");
                        }
                        if (!CheckResponseCode(data)) { return; } //檢查response code

                        if (data.code != "0000") {
                            setModalStr(data.message);
                            OpenModal("modal-error");
                            return;
                        }

                        setProductTypeArray(data.result.data);
                    });

                //設定營收月份下拉列表
                var deArray = [];
                let firstyearMonth = moment();
                if (newProductData.length > 0) {
                    firstyearMonth = moment(newProductData[0].incomeMonth);
                }
                for (var i = -1; i <= 23; i++) {
                    var futureMonth = moment(firstyearMonth).add(i, "M").format("YYYY/MM");
                    deArray.push(futureMonth);
                }
                setDateArray(deArray);

                //設定訂單進程狀態
                let locked = false;
                if (data.result.data.orderProduct != null && data.result.data.orderProduct.length > 0) {
                    locked = data.result.data.orderProduct[0].isMonthlyLocked
                }

                setDataLoaded(true);
            })
            .catch((err) => {
                console.log("API_GET_SaleOrderData的失敗回傳", err)
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function costToOtherChange(v) {
        setCostToOther(v);
        //產品別
        var dt = {
            companyId: companyId,
            isCostToOther: (v == true ? 1 : 0)
        };
        //console.log(JSON.stringify(dt));
        API_GET_CompanyProductType(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push('/')
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setProductTypeArray(data.result.data);
                setProductData(function (prev) {
                    return prev.map((item, i) => {
                        item.productName1 = '';
                        item.productName2 = '';
                        item.productTypeId = '';
                        let pdTypeElement = document.getElementById("productType_" + i);
                        if (pdTypeElement != null) {
                            pdTypeElement.value = ""
                        }

                        return item;
                    });
                });

            })
            .catch(err => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })

    }

    function custNameChange(e) {
        if (e.target.value == '') {
            setCustName('');
            setCustTaxID('');
            setCustTitle('');
            setCustID(null);
        } else {
            setCustName(e.target.value);
            getCustomer3Name(1);
        }
    }

    function custTitleChange(e) {
        if (e.target.value == '') {
            setCustName('');
            setCustTaxID('');
            setCustTitle('');
            setCustID(null);
        } else {
            setCustTitle(e.target.value);
            getCustomer3Name(2);
        }
    }

    function custTaxIDChange(e) {
        if (e.target.value == '') {
            setCustName('');
            setCustTaxID('');
            setCustTitle('');
            setCustID(null);
        } else {
            var v = e.target.value.replace(/[^A-Za-z0-9]/g, '');
            setCustTaxID(v);
            getCustomer3Name(3);
        }
    }

    function orderCostChange(e) {
        var num = e.target.value.replace(/,/g, '');
        setOrderCost(num);
    }

    function remarkChange(e) {
        setRemark(e.target.value);
    }

    function adNameChange(e) {
        setAdName(e.target.value);
        getADName();
    }

    //換負責公司 要額外抓匯率表/產品別
    function companyIdChange(id, type = 0) {
        setCompanyId(id);
        //匯率表
        API_GET_CurrencyComExchangeRate({
            companyId: id,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data.length == 0) {
                    setModalStr("該負責公司尚未建立匯率表");
                    OpenModal("modal-error");
                }
                setExchangeRateArray(data.result.data);

                //有的資料沒有exRateRate == 1 因此if判斷
                if (data.result.data.filter(s => s.exRateRate == 1).length > 0) {
                    setExCurrID(data.result.data.find(s => s.exRateRate == 1).exCurrID);
                    var newProductData = resetAmount_2(productData, data.result.data.find(s => s.exRateRate == 1).exCurrID,
                        currencyBaseArray,
                        data.result.data
                    );
                    setProductData(newProductData);
                } else {
                    setExCurrID(5);

                    var newProductData = resetAmount_2(productData, 5,
                        currencyBaseArray,
                        data.result.data
                    );
                    setProductData(newProductData);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        //產品別
        API_GET_CompanyProductType({
            companyId: id,
            isCostToOther: (costToOther == true ? 1 : 0),
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                if (data.code != "0000") {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                    return;
                }
                setProductTypeArray(data.result.data);
                //第一次載入不要清空
                if (type == 0) {
                    setProductData(function (prev) {
                        return prev.map((item, i) => {
                            item.productName1 = "";
                            item.productName2 = "";
                            item.prodTPID = "";
                            let pdTypeElement = document.getElementById("productType_" + i);
                            if (pdTypeElement != null) {
                                pdTypeElement.value = ""
                            }
                            return item;
                        });
                    });
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function resetAmount(type, index, col = "", val = "") {
        //幣別
        var exEname = currencyBaseArray.filter(x => x.currID == exCurrID).length > 0 ? (currencyBaseArray.find(x => x.currID == exCurrID).currEName + ' ') : '';

        var tSum = 0.00;
        if (type == 1) { //全資料重新計算
            //換了公司 則重新處理 數量/單價/小計/總金額
            setProductData(function (prev) {
                return prev.map((item) => {
                    if (item.isDelete != true) {
                        //console.log(exCurrID, item.spCurrID);
                        if (item.spCurrID != null && exCurrID != item.spCurrID) {
                            var ename = currencyBaseArray.filter(x => x.currID == item.spCurrID).length > 0 ? (currencyBaseArray.find(x => x.currID == item.spCurrID).currEName + ' ') : '';
                            item.subTotal1 = ename + NumFormart(item.spQuantity * item.spUnitPrice);
                            var ename2 = currencyBaseArray.filter(x => x.currID == exCurrID).length > 0 ? (currencyBaseArray.find(x => x.currID == exCurrID).currEName + ' ') : ' ';

                            let rate = GetRate(item.currencyId, item.incomeMonth);

                            if (exchangeRateArray != null && exchangeRateArray.length > 0 && rate != null) {
                                item.subTotal2 = ename2 + NumFormart((item.spQuantity * item.spUnitPrice * rate.rate).toFixed(2));
                                tSum = parseFloat(tSum) + parseFloat((item.spQuantity * item.spUnitPrice * rate.rate).toFixed(2));
                            }
                        } else {
                            item.subTotal1 = exEname + NumFormart(item.spQuantity * item.spUnitPrice);
                            tSum = parseFloat(tSum) + parseFloat(item.spQuantity * item.spUnitPrice);
                        }
                    }
                    return item;
                });
            });
        } else if (type == 2) { //單筆更新與訂單總金額 
            //console.log(exCurrID, currencyBaseArray);
            var noHasExchange = false; //判斷有沒有幣別沒設定到還選到的
            if (currencyBaseArray.filter(x => x.currID == exCurrID).length > 0) {
                let newProductData = [...productData];
                if (col == "spCurrID") {
                    newProductData[index].spCurrID = parseInt(document.getElementById('currencyId_' + index).value);
                }
                else if (col == "spQuantity") {
                    let tmp = parseFloat(val.replace(/[^0-9]/g, ''));
                    newProductData[index].spQuantity = !isNaN(tmp) ? tmp : "";
                }
                else if (col == "spUnitPrice") {
                    let tmp = parseFloat(val.replace(/[^0-9.]/g, ''));
                    newProductData[index].spUnitPrice = !isNaN(tmp) ? tmp : "";
                }

                newProductData.map((item, index) => {
                    if (!item.isDelete) {
                        if (newProductData[index].spCurrID !== null && newProductData[index].spCurrID !== '' && exCurrID != item.spCurrID) {
                            var ename = currencyBaseArray.find(x => x.currID == item.spCurrID).currEName + ' ';
                            item.subTotal1 = ename + NewNumFormart(item.spQuantity * item.spUnitPrice);

                            var ename2 = currencyBaseArray.find(x => x.currID == exCurrID).currEName + ' ';
                            if (item.spQuantity == 0 || item.spUnitPrice == 0) {
                                item.subTotal2 = 0;
                            }
                            else {
                                let rate = GetRate(item.spCurrID, item.incomeMonth);

                                if (exchangeRateArray != null && exchangeRateArray.length > 0 && rate != null) {
                                    item.subTotal2 = ename2 + NewNumFormart(((item.spQuantity * item.spUnitPrice) * rate.rate).toFixed(2));
                                    tSum = parseFloat(tSum) + parseFloat(((item.spQuantity * item.spUnitPrice) * rate.rate).toFixed(2));
                                } else {
                                    item.subTotal2 = 0;
                                    noHasExchange = true;
                                }
                            }
                        }
                        else {
                            item.subTotal1 = exEname + NewNumFormart(item.spQuantity * item.spUnitPrice);
                            tSum = parseFloat(tSum) + parseFloat(item.spQuantity * item.spUnitPrice);
                        }
                    }
                });

                setProductData(newProductData);
                setTotalAmount(exEname + NewNumFormart(parseFloat(tSum.toFixed(2))));
            }
            if (noHasExchange) {
                setExchangeError(true);
            } else {
                setExchangeError(false);
            }
        }
    }

    //用resetAmount 一直遇到延遲的問題
    function resetAmount_2(productData, getCurrID, cArray, eArray) {
        //幣別
        var exEname = cArray.filter(x => x.currID == getCurrID).length > 0 ? (cArray.find(x => x.currID == getCurrID).currEName + ' ') : '';

        var tSum = 0.0;

        productData.map((item) => {
            if (item.isDelete != true) {
                if (item.spCurrID != null && getCurrID != item.spCurrID) {
                    var ename = cArray.filter(x => x.currID == item.spCurrID).length > 0 ? (cArray.find(x => x.currID == item.spCurrID).currEName + ' ') : '';
                    item.subTotal1 = ename + NumFormart(item.spQuantity * item.spUnitPrice);
                    var ename2 = cArray.filter(x => x.currID == getCurrID).length > 0 ? (cArray.find(x => x.currID == getCurrID).currEName + ' ') : ' ';
                    if (eArray != null && eArray.length > 0 && eArray.filter(s => s.exCurrID == item.spCurrID).length > 0) {
                        let rate = GetRate(item.spCurrID, item.incomeMonth, eArray);
                        item.subTotal2 = ename2 + NumFormart((item.spQuantity * item.spUnitPrice * rate.rate).toFixed(2));
                        tSum = parseFloat(tSum) + parseFloat((item.spQuantity * item.spUnitPrice * rate.rate).toFixed(2));
                    }
                } else {
                    //console.log(exEname);
                    item.subTotal1 = exEname + NumFormart(item.spQuantity * item.spUnitPrice);
                    tSum = parseFloat(tSum) + parseFloat(item.spQuantity * item.spUnitPrice);
                }
            }
        });
        setTotalAmount(exEname + NumFormart(parseFloat(tSum.toFixed(2))));
        return productData;
    }

    //重新取得 客戶別 客戶別Title 客戶統編 List
    function getCustomer3Name(index) {
        var dt = [];
        if (index == 1) {
            dt = {
                customerName: custName,
                companyId: companyId
            }
        }
        else if (index == 2) {
            dt = {
                customerTitle: custTitle,
                companyId: companyId
            }

        }
        else if (index == 3) {
            dt = {
                customerTaxId: custTaxID,
                companyId: companyId
            }
        }
        API_GET_Customer3Name(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                var dataName = [];
                var dataTitle = [];
                var dataTaxId = [];

                if (data.result != null && data.result.data != null) {
                    if (index == 1) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataName.length; j++) {
                                if (dataName[j].custName == data.result.data[i].custName) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataName.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        //console.log(dataName);
                        setCustNameArray(dataName);
                    } else if (index == 2) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTitle.length; j++) {
                                if (dataTitle[j].custTitle == data.result.data[i].custTitle) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTitle.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxId: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerTitleArray(dataTitle);
                    } else if (index == 3) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTaxId.length; j++) {
                                if (dataTaxId[j].custTaxID == data.result.data[i].custTaxID) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTaxId.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerTaxArray(dataTaxId);
                    }
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function custIDChange(e) {
        API_POST_SaleOrderCustomerBalance({
            customerId: e,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                //console.log(data);
                setAdName(data.result.data.personInChargeName);
                setAdID(data.result.data.adid);
                setMargin(data.result.data.currencyEnName + ' ' + NumFormart(data.result.data.margin));
                setCreditFacilities(data.result.data.currencyEnName + ' ' + NumFormart(data.result.data.creditFacilities));
                setCreditBalance(data.result.data.currencyEnName + ' ' + NumFormart(data.result.data.creditBalance));
                setAdvance(data.result.data.currencyEnName + ' ' + NumFormart(data.result.data.advance));
                setTradableBalance(data.result.data.currencyEnName + ' ' + NumFormart(data.result.data.tradableBalance));
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function addProductData() {
        SetHasChange(true, "是否放棄修改此筆訂單？");

        setProductData(function (prev) {
            let idx = 0;
            return [
                ...prev,
                {
                    prodTPID: "",
                    productName1: null,
                    productName2: null,
                    spQuantity: null,
                    spUnitPrice: null,
                    spCurrID: null,
                    spInvState: 0,
                    spRem: null,
                    exchangeRate: null,
                    subTotal1: null,
                    subTotal2: null,
                    spModiStamp: null,
                    incomeMonth: moment().format("YYYY/MM"),
                    isDelete: false,
                    productName1Array: [{}],
                    productName2Array: [{}],
                    isNew: true
                },
            ].map((item, i) => {
                if (item.isDelete == false) {
                    item.index = idx + 1;
                    idx += 1;
                }

                return item;
            });
        });

    }

    //按了新增 在按刪除的
    function delProductData(index) {
        SetHasChange(true, "是否放棄修改此筆訂單？");
        setProductData(function (prev) {
            let idx = 0;
            return prev.filter((x, i) => i != index).map((item, i) => {
                if (item.isDelete == false) {
                    item.index = idx + 1;
                    idx += 1;
                }

                return item;
            });
        });
    }

    function dataChange(val, index, col = "", e) {
        SetHasChange(true, "是否放棄修改此筆訂單？");
        setProductData(function (prev) {
            let idx = 0;
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "prodTPID") {
                        item.prodTPID = val;
                        //呼叫共用G
                        var dt = {
                            input: "",
                            productTypeId: val,
                            companyId: companyId,
                            isCostToOther: (costToOther == true ? 1 : 0),
                        };
                        API_GET_ProductName1(dt)
                            .then((response) => response.data)
                            .then((data) => {
                                if (!checkAuthToken(data)) {
                                    history.push("/");
                                }
                                if (!CheckResponseCode(data)) { return; } //檢查response code
                                if (data.code == "0000") {
                                    item.productName1Array = data.result.data;
                                }
                            });
                    } else if (col == "productName1") {
                        item.productName1 = val;
                        var firstShowDom = e.target.closest(".has-datalist");
                        firstShowDom.classList.remove("show-datalist");

                        //呼叫共用F
                        var dt = {
                            input: "",
                            productTypeId: item.productTypeId,
                            productName1: val,
                            companyId: companyId,
                            isCostToOther: (costToOther == true ? 1 : 0),
                        }
                        console.log(JSON.stringify(dt));
                        API_GET_ProductName2(dt).then((response) => response.data)
                            .then((data) => {
                                console.log("ProductName2", data);
                                if (!checkAuthToken(data)) {
                                    history.push('/');
                                }
                                if (!CheckResponseCode(data)) { return; } //檢查response code
                                if (data.code == "0000") {
                                    if (data.result.companyArray != null) {
                                        item.productName2Array = data.result.companyArray;
                                    }
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })

                    } else if (col == "incomeMonth") {
                        item.incomeMonth = val;
                    } else if (col == "productName2") {
                        item.productName2 = val;
                        var firstShowDom = e.target.closest(".has-datalist");
                        firstShowDom.classList.remove("show-datalist");
                    } else if (col == "spRem") {
                        item.spRem = val;
                    } else if (col == "salesType") {
                        item.salesType = val;
                        var elem = document.querySelector('#amortized_multi_' + index);
                        if (val == "2") {
                            //如果銷售類型是攤提型 單位預設 月
                            item.amortizedFrequency = "0";
                            elem.dataset.required = true;
                        } else {
                            item.amortizedFrequency = null;
                            item.amortized = null;
                            elem.removeAttribute('data-required');
                            elem.classList.remove('error');
                        }
                    } else if (col == "amortizedFrequency") {
                        item.amortizedFrequency = val;
                    } else if (col == "amortized") {
                        item.amortized = val.replace(/[^0-9]/g, '') == '' ? '' : parseInt(val);
                    } else if (col == "isDelete") {
                        item.isDelete = true;
                        item.spModiStamp = -1;
                    } else if (col == "productId") {
                        item.productId = val;
                    }

                }

                if (item.isDelete == false) {
                    item.index = idx + 1;
                    idx += 1;
                }

                return item;
            });
        });

        resetAmount(2, index, "incomeMonth", val);
    }

    function productName1Change(e) {
        var index = e.target.dataset.index;

        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.productName1 = e.target.value;
                    var dt = {
                        input: e.target.value,
                        productTypeId: item.prodTPID,
                        companyId: companyId,
                        isCostToOther: (costToOther == true ? 1 : 0),
                    };
                    //console.log(JSON.stringify(dt));
                    //呼叫共用G
                    API_GET_ProductName1(dt)
                        .then((response) => response.data)
                        .then((data) => {
                            if (!checkAuthToken(data)) {
                                history.push("/");
                            }
                            if (!CheckResponseCode(data)) { return; } //檢查response code
                            if (data.code == "0000") {
                                item.productName1Array = data.result.data;

                                //呼叫共用F
                                var dt = {
                                    input: "",
                                    productTypeId: item.productTypeId,
                                    productName1: e.target.value,
                                    companyId: companyId,
                                    isCostToOther: (costToOther == true ? 1 : 0),
                                }
                                console.log(JSON.stringify(dt));
                                API_GET_ProductName2(dt).then((response) => response.data)
                                    .then((data) => {
                                        console.log("ProductName2", data);
                                        if (!checkAuthToken(data)) {
                                            history.push('/');
                                        }
                                        if (!CheckResponseCode(data)) { return; } //檢查response code
                                        if (data.code == "0000") {
                                            if (data.result.companyArray != null) {
                                                item.productName2Array = data.result.companyArray;
                                            }
                                        }
                                    })
                            }
                        });
                }
                return item;
            });
        });
    }

    function productName2Change(e) {

        var index = e.target.dataset.index;

        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.productName2 = e.target.value;
                    //呼叫共用F
                    API_GET_ProductName2({
                        input: e.target.value,
                        productTypeId: item.productTypeId,
                        productName1: item.productName1,
                        companyId: companyId,
                        isCostToOther: (costToOther == true ? 1 : 0),
                    })
                        .then((response) => response.data)
                        .then((data) => {
                            if (!checkAuthToken(data)) {
                                history.push("/");
                            }
                            if (!CheckResponseCode(data)) { return; } //檢查response code
                            if (data.code == "0000") {
                                item.productName2Array = data.result.companyArray;
                            }
                        })
                        .catch((err) => {

                            if (err.response.status === 401 || err.response.data.code == "1001") {
                                localStorage.clear();
                                history.push("/");
                            }
                        });
                }
                return item;
            });
        });
    }

    function getADName() {
        if (document.getElementById("adNameText").value == "") {
            return false;
        }

        API_GET_AdNameList({
            input: document.getElementById("adNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setADNameArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
    }

    //前往新增客戶
    function goCustomerNew() {
        window.open("/Main?path=CustomerNew", '_blank');
    }

    //前往查詢
    function returnQry() {
        history.push("/Main?path=SaleOrderQry");
    }

    //刪除
    function delData() {
        CloseModal("modal-checkdel");
        var dt = {
            orderId: queryParams.get("orderId"),
        };
        API_DEL_SaleOrderData(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                if (data.code == "0000") {
                    setModalStr("刪除成功!");
                    OpenModal("modal-success2");
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //送出
    function submit(e) {
        e.target.classList.add("disabled");
        //檢查資料
        var result = checkData();

        //如果資料檢查有通過 則送出
        if (result) {
            const formData = new FormData();

            var tmpproductData = [];
            for (var i = 0; i < productData.length; i++) {
                var tmpModiStamp =
                    productData[i].spModiStamp == null
                        ? null
                        : productData[i].spModiStamp;

                if (productData[i].isDelete == true) {
                    tmpModiStamp = tmpModiStamp - 1;
                } else {
                    tmpModiStamp = tmpModiStamp != null ? tmpModiStamp + 1 : null;
                }
                if (productData[i].spid == null) {
                    var tArray = {
                        productId: parseInt(productData[i].spProdID),
                        spProdID: productData[i].spProdID,
                        salesType: productData[i].salesType,
                        productTypeId: productData[i].prodTPID,
                        productName1: productData[i].productName1,
                        productName2: productData[i].productName2,
                        quantity: parseInt(productData[i].spQuantity),
                        unitPrice: parseInt(productData[i].spUnitPrice),
                        currencyId: parseInt(productData[i].spCurrID),
                        explanation: productData[i].spRem,
                        modiStamp: tmpModiStamp,
                        incomeMonth: productData[i].incomeMonth,
                        amortized: productData[i].salesType == 2 ? productData[i].amortized : null, //攤提次數
                        amortizedFrequency:
                            productData[i].salesType == 2
                                ? parseInt(productData[i].amortizedFrequency)
                                : null, //攤提單位(月/季/年)
                    };
                    tmpproductData.push(tArray);
                } else {
                    var tArray = {
                        spid: productData[i].spid,
                        productId: parseInt(productData[i].spProdID),
                        spProdID: productData[i].spProdID,
                        salesType: productData[i].salesType,
                        productTypeId: productData[i].prodTPID,
                        productName1: productData[i].productName1,
                        productName2: productData[i].productName2,
                        quantity: parseInt(productData[i].spQuantity),
                        unitPrice: parseFloat(productData[i].spUnitPrice),
                        currencyId: parseInt(productData[i].spCurrID),
                        explanation: productData[i].spRem,
                        modiStamp: tmpModiStamp,
                        incomeMonth: productData[i].incomeMonth,
                        amortized: productData[i].salesType == 2 ? productData[i].amortized : null, //攤提次數
                        amortizedFrequency:
                            productData[i].salesType == 2
                                ? parseInt(productData[i].amortizedFrequency)
                                : null, //攤提單位(月/季/年)
                    };

                    tmpproductData.push(tArray);
                }
            }

            var tmpArray = {
                orderId: queryParams.get("orderId"),
                companyId: companyId,
                costToOther: costToOther, //是否為經銷類別
                customerId: custID, //客戶資料ID
                salesID: adID, //負責業務ID
                priorityPayment: priorityPayment, //額度選項
                isPurchase: isPurchase, //是否請購
                orderCost: parseInt(orderCost), //協銷/ 資安成本金額
                remark: remark,
                orderProduct: tmpproductData,
            };
            //console.log(JSON.stringify(tmpArray));
            formData.append("view", JSON.stringify(tmpArray));
            //檢查是否有檔案
            if (selectedFile != null && selectedFile.size > 0) {
                formData.append("files", selectedFile);
            }
            const requestOptions = {
                method: "PUT",
                headers: {
                    //"Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                body: formData,
            };

            var path = API_HOST + "/salesorder/data";
            fetch(path, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    e.target.classList.remove("disabled");
                    //if (!checkAuthToken(data)) { history.push('/') };
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        OpenModal("modal-success");
                        window.onbeforeunload = null;
                        //onbeforeunloadFlag = false;
                        SetHasChange(false);
                    } else {
                        setModalStr(data.message);
                        OpenModal("modal-error");
                    }
                })
                .catch((err) => {

                    e.target.classList.remove("disabled");
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            e.target.classList.remove("disabled");
        }
    }

    //資料檢查
    function checkData() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {
            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }

            if (element.classList.contains('multi-form')) {
                var inputs = element.getElementsByTagName("input");

                for (let i = 0; i < inputs.length; i++) {
                    if (inputs[i].value == null || inputs[i].value == '') {
                        if (element.getAttribute("data-required") == 'true') {
                            element.classList.add('error');
                            result = false;
                            break;
                        }
                    }

                    element.classList.remove('error');
                }

            }
        });

        //信用額度
        var bf122 = false;
        if (priorityPayment != null) {
            bf122 = true;
        }

        if (bf122 == false) {
            document.getElementById("paymentDiv").classList.add("show-help");
            result = false;
        } else {
            document.getElementById("paymentDiv").classList.remove("show-help");
        }

        //是否請購
        let bf123 = false;
        if (isPurchase != null) {
            bf123 = true;
        }

        if (bf123 == false) {
            document.getElementById("isPurchaseDIV").classList.add("show-help");
            result = false;
        } else {
            document.getElementById("isPurchaseDIV").classList.remove("show-help");
        }

        if (exchangeError) {
            result = false;
            setModalStr("匯率設定不完整，無法送出訂單");
            OpenModal("modal-error");

            return result;
        }

        let isSameSaleType = productData.
            filter(x => x.isDelete == false)
            .every((item, index, array) => {
                return item.salesType == array[0].salesType;
            })

        if (isSameSaleType == false) {
            console.log(productData);
            result = false;
            setModalStr("產品品項之銷售類型不同，請修改為同一類型");
            OpenModal("modal-error");

            return result;
        }

        let survivalProduct = productData.filter(x => x.isDelete == false);

        let firstIncomeMonth = survivalProduct[0].incomeMonth;

        let hasDifferentIncomeMonth = survivalProduct.some(function (obj) {
            return obj.incomeMonth !== firstIncomeMonth
        })

        if (hasDifferentIncomeMonth) {
            setModalStr("產品品項之營收月份不同，請修改為同一月份");
            OpenModal("modal-error");

            result = false;
            return result;
        }

        return result;
    }

    function reflash() {
        window.location.reload();
    }

    //打開歷史查詢
    function openModal4() {
        API_GET_SaleOrderRecord({
            orderId: parseInt(queryParams.get("orderId")),
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setQryList(data.result.data);
                }
            });
        OpenModal("modal-4");
    }

    function getFile() {
        document.getElementById("file").click();
    }

    function calculateCurrencyContent(productData) {
        if (
            exchangeRateArray != null &&
            exchangeRateArray.length > 0 &&
            productData.spCurrID !== exCurrID &&
            exchangeRateArray.filter(item => item.exCurrID === productData.spCurrID).length > 0
        ) {
            let rate = GetRate(productData.spCurrID, productData.incomeMonth);
            if (rate != null) {
                return (
                    rate.rate +
                    '(' + rate.rateYM.substring(2) + ')'
                )
            }
        }
        return null;
    }

    function GetRate(currencyId, incomeMonth, eArray = exchangeRateArray) {
        var incomeMonth = new Date(incomeMonth);
        var exchangeRateItemArray = eArray.filter(item => item.exCurrID === currencyId);
        exchangeRateItemArray.sort(function (lt, rt) {
            return new Date(rt.exRateYM) - new Date(lt.exRateYM);
        })

        for (var i = 0; i < exchangeRateItemArray.length; i++) {
            let exchangeRateItem = exchangeRateItemArray[i];
            var exRateYM = new Date(exchangeRateItem.exRateYM);
            if (exRateYM <= incomeMonth) {
                return {
                    rate: exchangeRateItem.exRateRate,
                    rateYM: exchangeRateItem.exRateYM
                };
            }
        }

        return null;
    }

    function PurductDetailLock(product) {

        if (product.isMonthlyLocked == true) {
            return true;
        }

        if (openedInvState.current.includes(product.spInvState)) {
            return true;
        }

        let permission = 0;
        if (isMonth1 == 1) {
            permission = 1;
        }
        if (isMonth2 == 1) {
            permission = 2;
        }

        if (product.spMonthCloseState > permission) {
            return true;
        }

        return false;
    }

    useEffect(() => {
        //把menu顏色置換
        MenuSet("SaleOrderQry");

        //先取得使用者權限再取得所有資料
        var fromOkta_ = (localStorage.getItem("post_logout_redirect_uri") != null ? 1 : 0);
        API_GET_HomeData({ fromOkta: fromOkta_ })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setIsMonth1(data.result.isMonth1); //月結1
                setIsMonth2(data.result.isMonth2); //月結2

                getData();
            })
    }, []);

    useEffect(() => {
        InitJS();
        SetOnbeforeunload("是否放棄修改此筆訂單？");

        setDataLoaded(false);
    }, [dataLoaded])

    useEffect(() => {
        if (custName == null || companyId == null || custName == '')
            return;

        var dt = {
            customerName: custName,
            companyId: companyId
        };
        API_GET_Customer3Name(dt).then((response) => response.data)
            .then((data) => {

                if (!checkAuthToken(data)) {
                    history.push('/');
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                var custNameList = data.result.data.map(x => x.custName);
                if (!custNameList.some(x => x.includes(dt.customerName))) {
                    setCustName('');
                    setCustTitle('');
                    setAdID('');
                    setAdName('');
                }
            });
    }, [companyId, custName])

    useEffect(() => {
        let hasLocked = productData.find(item => PurductDetailLock(item));
        if (hasLocked != null) {
            setMainDataLock(true);
        }

        setAllDetailLock(productData.every(item => PurductDetailLock(item)));
    }, [productData]);

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">訂單編號:{orderNumber}</h3>
                    <div class="right">
                        <span>建立日期:{orderCreateDate}</span>
                    </div>
                </div>
            </div>
            <div className="container">
                <form action="" id="mainForm" className="form-container">
                    <div className="form-item must">
                        <div className="field">負責公司</div>
                        <div className="value">
                            {
                                companyArray.map((x) => (
                                    <div key={x.id}>
                                        <input
                                            type="radio"
                                            id={`f101-${x.cmpID}`}
                                            name="f101"
                                            value={x.cmpID}
                                            checked={companyId == x.cmpID ? true : false}
                                            onClick={(e) => companyIdChange(x.cmpID)}
                                            disabled={mainDataLock}
                                        />
                                        <label htmlFor={`f101-${x.cmpID}`}>{x.cmpName}</label>
                                    </div>
                                ))
                            }
                        </div>
                        <span className="help-word">請選擇負責公司</span>
                    </div>

                    <div className="form-item must half">
                        <div className="field">經銷類別</div>
                        <div className="value">
                            <div>
                                <input type="radio" name="costToOther" id="costToOther_1" checked={costToOther ? true : false} onClick={(e) => costToOtherChange(true)} disabled={mainDataLock} />
                                <label htmlFor="costToOther_1">是</label>
                            </div>
                            <div>
                                <input type="radio" name="costToOther" id="costToOther_2" checked={!costToOther ? true : false} onClick={(e) => costToOtherChange(false)} disabled={mainDataLock} />
                                <label htmlFor="costToOther_2">否</label>
                            </div>

                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <hr />
                    <div className="form-item must half has-datalist" data-datalist>
                        <div className="field">客戶別</div>
                        <div className="value">
                            <input
                                id="customerNameText"
                                type="text"
                                value={custName}
                                onChange={custNameChange}
                                autoComplete="off"
                                data-required
                                disabled={mainDataLock}
                            />
                            <ul className="datalist scrollbar">
                                {custNameArray.map((x) => (
                                    <li
                                        data-value={x.custName} onClick={(e) => { setCustName(x.custName), setCustTitle(x.custTitle), setCustTaxID(x.custTaxID), custIDChange(x.custID), setCustID(x.custID); }}>
                                        <span className="flex-4 bold">{x.custName}</span>
                                        <span className="flex-3">{x.custTitle}</span>
                                        <span className="flex-3">{x.custTaxID}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item half jcfe">
                        <a className={`btn btn-border btn-middle`} onClick={goCustomerNew}>
                            <span>新增客戶</span>
                        </a>

                    </div>

                    <div className="form-item must half  has-datalist" data-datalist>
                        <div className="field">公司抬頭</div>
                        <div className="value">
                            <input
                                id="customerTitleText"
                                type="text"
                                onChange={custTitleChange}
                                value={custTitle}
                                autoComplete="off"
                                data-required
                                disabled={mainDataLock}
                            />
                            <ul className="datalist scrollbar">
                                {custTitleArray.map((x) => (
                                    <li data-value={x.name}>
                                        <span className="flex-10 bold">{x.name}</span>
                                    </li>
                                ))}
                            </ul>


                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item  half  has-datalist" data-datalist>
                        <div className="field">公司統編/ 編號</div>
                        <div className="value">
                            <input
                                id="customerTaxIdText"
                                type="text"
                                onChange={custTaxIDChange}
                                maxLength="20"
                                value={custTaxID}
                                autoComplete="off"
                                disabled={mainDataLock}
                            />
                            <ul className="datalist scrollbar">
                                {
                                    custTaxIDArray.map(x =>
                                        <li data-value={x.custTaxID} onClick={e => { setCustName(x.custName), setCustTitle(x.custTitle), setCustTaxID(x.custTaxID), custIDChange(x.custID), setCustID(x.custID) }}>
                                            <span className="flex-4 bold">{x.custName}</span>
                                            <span className="flex-3">{x.custTitle}</span>
                                            <span className="flex-3">{x.custTaxID}</span>
                                        </li>
                                    )
                                }
                            </ul>

                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item must half has-datalist" data-datalist>
                        <div className="field">負責業務</div>
                        <div className="value multi-inline">
                            <input id="adNameText" type="text" onChange={adNameChange} value={adName} disabled={adNameState == 1 ? true : false} autoComplete="off" data-required />
                            <ul className="datalist scrollbar">
                                {adNameArray.map((x) => (
                                    <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => { setAdName(`${x.enName}(${x.tc_name})`), setAdID(x.id); }} >
                                        <span className="flex-4">{x.enName}({x.tc_name})</span>
                                        <span className="flex-5">{x.mail}</span>
                                    </li>
                                ))}
                            </ul>
                            <a
                                className={`btn btn-border btn-middle ${mainDataLock ? "disabled" : ""}`}
                                onClick={(e) => setAdNameState(0)}
                            >
                                <span>修改</span>
                            </a>

                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <hr />

                    <div className="form-item must half" id="paymentDiv">
                        <div className="field">額度選項</div>
                        <div className="value">
                            <div>
                                <input type="radio" id="f122-1" name="f122" value="0" checked={priorityPayment == 0 ? "checked" : ""} onClick={(e) => setPriorityPayment(0)} disabled={mainDataLock} />
                                <label htmlFor="f122-1">信用額度</label>
                            </div>
                            <div>
                                <input type="radio" id="f122-2" name="f122" value="1" checked={priorityPayment == 1 ? "checked" : ""} onClick={(e) => setPriorityPayment(1)} disabled={mainDataLock} />
                                <label htmlFor="f122-2">預付額度</label>
                            </div>

                        </div>
                        <span className="help-word">請輸入額度選項</span>
                    </div>

                    <div className="form-item half">
                        <div className="field">保證金餘額</div>
                        <div className="value">
                            <input type="text" value={margin} disabled="disabled" />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item half">
                        <div className="field">信用額度</div>
                        <div className="value">
                            <input type="text" value={creditFacilities} disabled="disabled" />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item half">
                        <div className="field">信用餘額</div>
                        <div className="value">
                            <input type="text" value={creditBalance} disabled="disabled" />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item half">
                        <div className="field">儲值金額</div>
                        <div className="value">
                            <input type="text" value={advance} disabled="disabled" />

                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item half">
                        <div className="field">可交易餘額</div>
                        <div className="value">
                            <input type="text" value={tradableBalance} disabled="disabled" />

                        </div>
                        <span className=" " style={{
                            "width": "100%",
                            "flex-shrink": 0,
                            "font-size": "0.75rem",
                            "padding-left": "130px",
                            "transition": "opacity .35s ease",
                            "pointer-events": "none",
                            "color": "#888",
                        }}>
                            信用額度-應收款項總額-訂單金額+帳務沖銷金額+預付金餘額=可交易餘額
                        </span>
                    </div>

                    <hr />

                    <div className="form-item col must">
                        <div className="field">產品品項</div>
                        <div className="value">
                            {productData.map((x, i) =>
                                x.isDelete == true ? null : (
                                    <>
                                        <ul className="list-table">
                                            <li className="lt-row intensive lt-th">
                                                <div className="form-item flex-1 max1 text-center">序號</div>
                                                <div className="form-item flex-2">產品別</div>
                                                <div className="form-item flex-3">產品細項總稱</div>
                                                <div className="form-item flex-3">產品細項</div>
                                                <div className="form-item flex-2">營收月份</div>
                                                <div className="form-item flex-1"> </div>
                                            </li>
                                            <li className="lt-row intensive ovv">
                                                {/* 序號 */}
                                                <div className="form-item flex-1 max1 text-center">{x.index}</div>

                                                {/* 產品別 */}
                                                <div className="form-item flex-2">
                                                    {
                                                        PurductDetailLock(x) ? productTypeArray && productTypeArray.length > 0 && x.prodTPID ? ((() => {
                                                            let productType = productTypeArray.find(pd => pd.prodTPID == x.prodTPID);
                                                            if (productType) {
                                                                return productType.prodTPName;
                                                            }
                                                            else {
                                                                return ''
                                                            }
                                                        })()) : '' :
                                                            (
                                                                <select name={`productType_${i}`} id={`productType_${i}`} data-index={i} onChange={(e) => dataChange(e.target.value, i, "prodTPID")} data-required>
                                                                    <option value="" selected={x.prodTPID == '' ? true : false} disabled>請選擇</option>
                                                                    {productTypeArray.map((x2) => {
                                                                        return (
                                                                            <option value={x2.prodTPID} selected={x2.prodTPID == x.prodTPID ? true : false} >
                                                                                {x2.prodTPName}
                                                                            </option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            )

                                                    }
                                                </div>

                                                {/* 產品細項總稱 */}
                                                <div className="form-item flex-3 no-field no-help-word inline has-datalist" data-datalist >
                                                    {
                                                        PurductDetailLock(x) ? x.productName1 :
                                                            <div className="value">
                                                                <input
                                                                    type="text"
                                                                    id={`productName1_${i}`}
                                                                    defaultValue={x.productName1}
                                                                    onClick={(e) => {
                                                                        if (e.target.value == "") {
                                                                            var firstShowDom = e.target.closest(".has-datalist");
                                                                            firstShowDom.classList.add("show-datalist");
                                                                        }
                                                                    }}
                                                                    onChange={productName1Change}
                                                                    data-index={i}
                                                                    data-required
                                                                    autoComplete="off" />
                                                                <ul className="datalist scrollbar">
                                                                    {x.productName1Array != null
                                                                        ? x.productName1Array.map((x2) => (
                                                                            <li data-value={x2.productName1} onClick={e => dataChange(x2.productName1, i, "productName1", e)} >
                                                                                <span className="flex-3 bold">
                                                                                    {x2.productName1}
                                                                                </span>
                                                                            </li>
                                                                        ))
                                                                        : null}
                                                                </ul>
                                                            </div>
                                                    }

                                                </div>

                                                {/* 產品細項 */}
                                                <div className="form-item flex-3 no-field no-help-word inline has-datalist" data-datalist>

                                                    {
                                                        PurductDetailLock(x) ? x.productName2 :
                                                            <div className="value">
                                                                <input
                                                                    type="text"
                                                                    id={`productName2_${i}`}
                                                                    defaultValue={x.productName2}
                                                                    onClick={(e) => {
                                                                        if (e.target.value == "") {
                                                                            var firstShowDom = e.target.closest(".has-datalist");
                                                                            firstShowDom.classList.add("show-datalist");
                                                                        }
                                                                    }}
                                                                    onChange={productName2Change}
                                                                    data-index={i}
                                                                    data-required
                                                                    autoComplete="off" />
                                                                <ul className="datalist scrollbar">
                                                                    {x.productName2Array != null
                                                                        ? x.productName2Array.map((x2) => (
                                                                            <li data-value={x2.productName2} onClick={e => { dataChange(x2.productName2, i, "productName2", e), dataChange(x2.productId, i, "productId") }} >
                                                                                <span className="flex-3 bold">
                                                                                    {x2.productName1}
                                                                                </span>
                                                                                <span className="flex-3">
                                                                                    {x2.productName2}
                                                                                </span>
                                                                            </li>
                                                                        ))
                                                                        : null}
                                                                </ul>
                                                            </div>
                                                    }

                                                </div>

                                                {/* 營收月份 */}
                                                <div className="form-item flex-2 no-field no-help-word inline">
                                                    {
                                                        PurductDetailLock(x) ? x.incomeMonth :
                                                            <div className="value">
                                                                {
                                                                    <select name={`incomeMonth_${i}`} id={`incomeMonth_${i}`} onChange={e => dataChange(e.target.value, i, "incomeMonth")} data-required>
                                                                        {dateArray.map((x) => (
                                                                            <option value={x} selected={x == productData[i].incomeMonth ? true : false} > {x} </option>
                                                                        ))}
                                                                    </select>
                                                                }
                                                            </div>
                                                    }


                                                </div>

                                                {/* 刪除按鈕 */}
                                                <div className="form-item flex-1">
                                                    {
                                                        PurductDetailLock(x) ? '' :
                                                            productData != null && (productData.filter(p => p.isDelete != true).length > 1) ? (
                                                                <div className="btn btn-icon" onClick={x.spid == null ? (e) => delProductData(i) : (e) => dataChange("", i, "isDelete")} >
                                                                    <i className="icon icon-37"></i>
                                                                </div>
                                                            ) : null}
                                                </div>
                                            </li>
                                            <li className={`lt-row intensive lt-th`}>
                                                <div className="flex-1">發票狀態</div>
                                                <div className="flex-1">銷售類型</div>
                                                <div className="flex-1">攤提次數</div>
                                                <div className="flex-1 right">數量</div>
                                                <div className="flex-1 right">單價(含稅)</div>
                                                <div className="flex-3">
                                                    幣別 {calculateCurrencyContent(productData[i], exchangeRateArray, exCurrID)}
                                                </div>
                                                <div className="flex-3">小計(含稅)</div>
                                                <div className="flex-2">說明</div>
                                            </li>
                                            <li className={`lt-row intensive thin ${PurductDetailLock(x) ? '' : 'lt-th'}`}>
                                                {/* 發票狀態 */}
                                                <div className="flex-1">
                                                    {invStateArray.map((x2) =>
                                                        x.spInvState == x2.id ? x2.name : null
                                                    )}
                                                </div>

                                                {/* 銷售類型 */}
                                                <div className="flex-1">
                                                    {
                                                        PurductDetailLock(x) ? ((() => {
                                                            let target = saleTypeArray.find(item => item.value == x.salesType);
                                                            if (target) {
                                                                return target.name;
                                                            }
                                                        })()) :
                                                            <select name={`saleType_${i}`} id={`saleType_${i}`} onChange={(e) => dataChange(e.target.value, i, "salesType")} data-index={i}>
                                                                {saleTypeArray.map((x2) => (
                                                                    <option value={x2.value} selected={x2.value == x.salesType ? true : false}>
                                                                        {x2.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                    }

                                                </div>

                                                {/* 攤提次數 */}
                                                <div className="flex-1">
                                                    {
                                                        PurductDetailLock(x) ? (
                                                            x.salesType == 2 ?
                                                                <div>
                                                                    {x.amortized}
                                                                    <div className="line-inline"></div>
                                                                    {x.amortizedFrequency == 0 ? '月' : x.amortizedFrequency == 1 ? '季' : '年'}
                                                                </div>
                                                                : ''
                                                        )
                                                            :
                                                            <div id={`amortized_multi_${i}`} className={`multi-form ${x.salesType != 2 ? 'disabled' : ''}`} data-required={x.salesType == 2}>
                                                                {
                                                                    <>
                                                                        <input type="text" value={x.amortized} id={`amortized_${i}`} name={`amortized_${i}`} onChange={(e) => dataChange(e.target.value, i, "amortized")} data-index={i}
                                                                            disabled={x.salesType != 2 ? 'disabled' : null} />
                                                                        <div class="line"></div>
                                                                        <select name={`amortizedFrequency_${i}`} id={`amortizedFrequency_${i}`}
                                                                            disabled={x.salesType != 2 ? 'disabled' : null}
                                                                            onChange={(e) => dataChange(e.target.value, i, "amortizedFrequency")} data-index={i} >
                                                                            <option value="0" selected={x.amortizedFrequency == 0 ? true : false} > 月 </option>
                                                                            <option value="1" selected={x.amortizedFrequency == 1 ? true : false} > 季 </option>
                                                                            <option value="2" selected={x.amortizedFrequency == 2 ? true : false} > 年 </option>
                                                                        </select>
                                                                    </>
                                                                }

                                                            </div>

                                                    }
                                                </div>

                                                {/* 數量 */}
                                                <div className="flex-1 right">
                                                    {
                                                        PurductDetailLock(x) ? NewNumFormart(x.spQuantity) :
                                                            <input
                                                                type="text"
                                                                defaultValue={NewNumFormart(x.spQuantity)}
                                                                onChange={(e) => resetAmount(2, i, "spQuantity", e.target.value)}
                                                                onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                                                onBlur={(e) => e.target.value = NewNumFormart(x.spQuantity)}
                                                                data-index={i}
                                                                data-required />
                                                    }
                                                </div>

                                                {/* 單價 */}
                                                <div className="flex-1 right">
                                                    {
                                                        PurductDetailLock(x) ? NewNumFormart(x.spUnitPrice) :
                                                            <input
                                                                type="text"
                                                                defaultValue={NewNumFormart(x.spUnitPrice)}
                                                                onChange={(e) => resetAmount(2, i, "spUnitPrice", e.target.value)}
                                                                onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                                                onBlur={(e) => e.target.value = NewNumFormart(x.spUnitPrice)}
                                                                data-index={i}
                                                                data-required />
                                                    }
                                                </div>

                                                {/* 幣別 */}
                                                <div className="flex-3">
                                                    {
                                                        PurductDetailLock(x) ? ((() => {
                                                            let target = currencyBaseArray.find(item => item.currID == x.spCurrID);
                                                            if (target) {
                                                                return target.currName;
                                                            }
                                                        })()) :
                                                            <select name="currencyBase" id={`currencyId_${i}`} onChange={(e) => resetAmount(2, i, "spCurrID", e.target.value)} data-required>
                                                                <option value="" selected disabled>請選擇</option>
                                                                {currencyBaseArray.map((x2) => (
                                                                    <option
                                                                        value={x2.currID} selected={x2.currID == x.spCurrID ? true : false} > {x2.currName} </option>
                                                                ))}
                                                            </select>
                                                    }
                                                </div>

                                                {/* 小計 */}
                                                <div className="flex-3">
                                                    {
                                                        x.spCurrID === null || x.spCurrID === '' || x.spCurrID == exCurrID ? (
                                                            PurductDetailLock(x) ? x.subTotal1 :
                                                                <input type="text" value={x.subTotal1} data-index={i} disabled />
                                                        ) :
                                                            PurductDetailLock(x) ? `${x.subTotal1 ? x.subTotal1 : ""}(${x.subTotal2 ? x.subTotal2 : ""})` :
                                                                <input type="text" value={`${x.subTotal1 ? x.subTotal1 : ""}(${x.subTotal2 ? x.subTotal2 : ""})`} data-index={i} disabled />
                                                    }
                                                </div>

                                                {/* 說明 */}
                                                <div className="flex-2">
                                                    {
                                                        PurductDetailLock(x) ? x.spRem :
                                                            <input type="text" value={x.spRem} onChange={(e) => dataChange(e.target.value, i, "spRem")} />
                                                    }
                                                </div>
                                            </li>
                                        </ul>
                                    </>
                                )
                            )}
                            <div className="btn-area">
                                <div class="info"></div>
                                <a className={`btn btn-small btn-border ${mainDataLock ? 'disabled' : ''}`} onClick={addProductData} >
                                    <span>新增產品品項</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="form-item must half" id="isPurchaseDIV">
                        <div className="field">是否請購</div>
                        <div className="value">
                            <div>
                                <input
                                    type="radio" id="f123-1" name="f123" checked={isPurchase == true ? "checked" : ""} onClick={(e) => setIsPurchase(true)} disabled={mainDataLock} />
                                <label htmlFor="f123-1">是</label>
                            </div>
                            <div>
                                <input type="radio" id="f123-2" name="f123" checked={isPurchase == false ? "checked" : ""} onClick={(e) => setIsPurchase(false)} disabled={mainDataLock} />
                                <label htmlFor="f123-2">否</label>
                            </div>

                        </div>
                        <span className="help-word">請輸入是否請購</span>
                    </div>

                    <div className={`form-item half ${exchangeError ? 'show-help' : ''}`}>
                        <div className="field">訂單總金額(含稅)</div>
                        <div className="value">
                            <input type="text" value={exchangeError ? '' : totalAmount} disabled="disabled" />
                        </div>
                        <span className="help-word right">
                            該營收公司匯率設定不完整，無法計算
                        </span>
                    </div>

                    <div className="form-item half">
                        <div className="field">協銷/ 資安成本(含稅)</div>
                        <div className="value">
                            <input type="text"
                                defaultValue={NewNumFormart(orderCost)}
                                onChange={orderCostChange}
                                onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                onBlur={(e) => e.target.value = NewNumFormart(orderCost)}
                                disabled={soProcApplyID != null || mainDataLock ? true : false} />

                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item half">
                        <div className="field">訂單憑證</div>
                        <div className="value multi-inline">
                            <div class="fake-upload" data-fakeUpload>
                                <input type="file" id="file" className="real" style={{ "display": "none" }} onChange={(e) => setSelectedFile(e.target.files[0])} />
                                <input type="text" className="fake" readonly="readonly" placeholder="請選擇檔案" disabled={mainDataLock} />
                            </div>
                            <a className={`btn btn-middle btn-border ${mainDataLock ? "disabled" : ""}`} onClick={getFile}>
                                <span>上傳</span>
                            </a>

                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item">
                        <div className="field">備註</div>
                        <div className="value">
                            <textarea name="" id="form_remark" cols="30" rows="10" value={remark} onChange={remarkChange} disabled={mainDataLock} ></textarea>

                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item btn-area sp">
                        <div className="left">
                            <a class="btn btn-border" onClick={openModal4}>
                                <span>歷史紀錄</span>
                            </a>
                        </div>
                        <div className="right">
                            <a className={`btn btn-border ${mainDataLock ? "disabled" : ""}`} onClick={() => OpenModal("modal-checkdel")}>
                                <span>刪除訂單</span>
                            </a>
                            <a className={`btn ${allDetailLock ? "disabled" : ""}`} onClick={submit}>
                                <span>確認修改</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
            <div id="modal-success" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">修改成功</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={reflash}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-success2" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={returnQry}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr == null ? '' : HtmlParse(modalStr)}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-4" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">歷史紀錄</h3>
                        <span className="close" onClick={() => CloseModal("modal-4")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <ul className="list-table">
                            <li className="lt-row lt-th">
                                <div className="flex-3">時間</div>
                                <div className="flex-3">產品細項</div>
                                <div className="flex-2">負責業務</div>
                                <div className="flex-1">幣別</div>
                                <div className="flex-1">數量</div>
                                <div className="flex-1">單價</div>
                                <div className="flex-2">說明</div>
                            </li>
                            {qryList.map((x) => (
                                <li class="lt-row">
                                    <div class="flex-3">{x.time}</div>
                                    <div class="flex-3">{x.productName2}</div>
                                    <div class="flex-2">{x.personInChargeName}</div>
                                    <div class="flex-1">{x.currencyName}</div>
                                    <div class="flex-1">{NumFormart(x.quantity)}</div>
                                    <div class="flex-1">{NumFormart(x.unitPrice)}</div>
                                    <div class="flex-2">{x.explaination}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div id="modal-checkdel" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確定要刪除這筆訂單?</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-checkdel")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={delData}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default SaleOrderEdit;
