import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_CloudAccountingGetProducts
} from "../../global/constants";
import { OpenModal, CloseModal, InitJS, MenuSet } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const CATRowSetQry = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        //把menu顏色置換
        MenuSet("CATRowSetQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    function getData() {
        API_GET_CloudAccountingGetProducts()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setProductName2Array(data.result.data.productName2Array);
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {
               
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [modalStr, setModalStr] = useState();
    const [productName2Array, setProductName2Array] = useState([{}]);
    const [productNameId, setProductNameId] = useState();
    const [productNameIdError, setProductNameIdError] = useState(false);
    function Qry() {
        if (productNameId == null || productNameId == undefined) {
            setProductNameIdError(true);
            return false;
        } else {
            setProductNameIdError(false);
        } 
        var path = "/Main?path=CATRowSetAdd&productName2Id=" + productNameId;
        history.push(path);
    }
    return (
        <>
            <div className="content-body">
                <div className="title-area">
                    <div className="container">
                        <h3 className="fz-B has-border">報表欄位設定</h3>
                    </div>
                </div>
                <div className="container">
                    <div className="form-container">
                        <div className={`form-item col aifs for-c12 ${productNameIdError ? 'show-help' : ''}`}>
                            <div className="field">請選擇一項雲端細項</div>
                            <div className="value">
                                {
                                    productName2Array.map(x =>
                                        <div>
                                            <input type="radio" id={`productName_${x.id}`} name="productName" checked={productNameId == x.id ? true : false} onClick={(e) => setProductNameId(x.id) } />
                                            <label htmlFor={`productName_${x.id}`}>{x.name}</label>
                                        </div>
                                    )
                                } 
                            </div>
                            <span className="help-word">
                                請選擇雲端細項
                            </span>
                        </div>

                        <div className="form-item btn-area sp">
                            <div className="left">

                            </div>
                            <div className="right">

                                <a className="btn" onClick={ Qry}>
                                    <span>確定</span>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CATRowSetQry;
