import '../css/style.css';

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from "react-intl"; //多國語言套件
import { API_GET_HomeData } from "../global/constants";
import { CheckResponseCode } from '../global/ResponseUtil';
function ssoverify() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
   // const path = queryParams.get('path');
    //const myHeaders = new Headers();
    useEffect(() => {
        document.getElementById('root').classList.add('center');
        document.body.classList.remove('dashboard');
        document.body.classList.add('login');
        //setAuthorization();
        if (queryParams.get('token') == null || queryParams.get('token') == '') {
            history.push('/');
        } else {
            localStorage.setItem("token", queryParams.get('token'));
            localStorage.setItem("id_token", queryParams.get('id_token'));
            localStorage.setItem("okta_url", queryParams.get('okta_url'));
            localStorage.setItem("post_logout_redirect_uri", queryParams.get('post_logout_redirect_uri'));
            localStorage.setItem("fromOkta", 1);
            var dt = {
                fromOkta: 1
            };
            API_GET_HomeData({
                fromOkta: 1
            }).then((response) => response.data)
                .then((data) => {
                    //if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000" || data.code == "1098") {
                        localStorage.setItem("islogin", "1");
                        localStorage.setItem("user", data.result.user.userNo);
                        if (queryParams.get('userName') != null)
                            localStorage.setItem("userName", queryParams.get('userName'));
                        else
                            localStorage.setItem("userName", data.result.user.userName);
                        localStorage.setItem("userID", data.result.user.userID);
                        localStorage.setItem("validRemainDays", 1000);
                        localStorage.setItem("token", data.token.token);
                        localStorage.setItem("hasManyADAcnt", data.result.hasManyADAcnt);
                        localStorage.setItem(
                            "pagelist",
                            JSON.stringify(data.result.pagedata)
                        );
                        localStorage.setItem(
                            "menulist",
                            JSON.stringify(data.result.menudata)
                        );
                        localStorage.setItem(
                            "authctrl",
                            JSON.stringify(data.result.authdata)
                        );
                        localStorage.setItem("notify", JSON.stringify(data.result.notify));
                        if (data.code == "1098") {
                            window.location.href = "/Dashboard";
                        }
                        else {
                            history.push("/Dashboard");
                        }
                    } else if (data.code == "1001") {
                        localStorage.clear();
                        setMsgStr(data.message);
                        history.push("/");
                    }
                    else {
                        setMsgStr(data.message);
                    }
                })
                .catch((err) => {
                    
                });
        }
    }, []);
    const [authorization, setAuthorization] = useState();
    return (
        <div className="full center">
            <div className="login-box">
                <div className="title">
                    <i className="icon icon-31"></i>
                    <h1 className="fz-hero">
                        <FormattedMessage id="systemName" /> {/*系統名稱*/}
                    </h1>
                </div>

                <div className="form-container" style={{ textAlign:'center' }}>
                   
                    
                </div>
            </div>
        </div>
    );
}
export default ssoverify;