import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {

} from "../../global/constants";
import { checkAuthToken } from "../../global/TokenUtil";

const UserInfo = () => {
    const history = useHistory();

    useEffect(() => {
        //第一次近來先執行抓取userList
        //userListChange();
    }, []);

    return (
        <>
            <div class="dashboard-body for-user-management">

                <div class="title-area">
                    <div class="container">
                        <h3 class="fz-B has-border">管理使用人員帳號</h3>
                        <button class="btn btn-border btn-middle">
                            <span>回上一頁</span>
                        </button>
                    </div>
                </div>
                <div class="container">
                    <div class="left">
                        <section class="title">
                            <h3 class="fz-C">帳號資料合併 / 捨棄</h3>
                            <p>下列帳號在後台有多筆資料，請選擇合併或捨棄原帳號</p>
                        </section>
                        <section class="scrollbar">

                            <div class="um-item">
                                <div class="um-info">
                                    <div class="email">tonystark@digicentre.com</div>
                                    <div class="name">納塔莎 (Natasha Romanoff)</div>
                                </div>
                                <ul class="um-list">
                                    <li>以下為將合併/捨棄的同帳號資料</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                </ul>
                                <div class="um-fn">
                                    <button class="btn btn-small">
                                        <span>合併資料</span>
                                    </button>
                                    <button class="btn btn-small">
                                        <span>捨棄原帳號</span>
                                    </button>
                                </div>


                            </div>
                            <div class="um-item">
                                <div class="um-info">
                                    <div class="email">tonystark@digicentre.com</div>
                                    <div class="name">納塔莎 (Natasha Romanoff)</div>
                                </div>
                                <ul class="um-list">
                                    <li>以下為將合併/捨棄的同帳號資料</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                </ul>
                                <div class="um-fn">
                                    <button class="btn btn-small">
                                        <span>合併資料</span>
                                    </button>
                                    <button class="btn btn-small">
                                        <span>捨棄原帳號</span>
                                    </button>
                                </div>


                            </div>

                            <div class="um-item">
                                <div class="dev">
                                    <h3>這是可以刪除的，單純方便看彈窗</h3>
                                    <button class="btn" data-openmodal="modal-0112"><span>0.1.1.2</span></button>
                                    <button class="btn" data-openmodal="modal-0113"><span>0.1.1.3</span></button>
                                    <button class="btn" data-openmodal="modal-0101"><span>0.1.0.1</span></button>
                                    <button class="btn" data-openmodal="modal-0102"><span>0.1.0.2</span></button>
                                </div>
                            </div>


                            <div class="um-item">
                                <div class="um-info">
                                    <div class="email">tonystark@digicentre.com</div>
                                    <div class="name">納塔莎 (Natasha Romanoff)</div>
                                </div>
                                <ul class="um-list">
                                    <li>以下為將合併/捨棄的同帳號資料</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                </ul>
                                <div class="um-fn">
                                    <button class="btn btn-small">
                                        <span>合併資料</span>
                                    </button>
                                    <button class="btn btn-small">
                                        <span>捨棄原帳號</span>
                                    </button>
                                </div>


                            </div>
                            <div class="um-item">
                                <div class="um-info">
                                    <div class="email">tonystark@digicentre.com</div>
                                    <div class="name">納塔莎 (Natasha Romanoff)</div>
                                </div>
                                <ul class="um-list">
                                    <li>以下為將合併/捨棄的同帳號資料</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                </ul>
                                <div class="um-fn">
                                    <button class="btn btn-small">
                                        <span>合併資料</span>
                                    </button>
                                    <button class="btn btn-small">
                                        <span>捨棄原帳號</span>
                                    </button>
                                </div>


                            </div>


                            <div class="um-item">
                                <div class="um-info">
                                    <div class="email">tonystark@digicentre.com</div>
                                    <div class="name">納塔莎 (Natasha Romanoff)</div>
                                </div>
                                <ul class="um-list">
                                    <li>以下為將合併/捨棄的同帳號資料</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                </ul>
                                <div class="um-fn">
                                    <button class="btn btn-small">
                                        <span>合併資料</span>
                                    </button>
                                    <button class="btn btn-small">
                                        <span>捨棄原帳號</span>
                                    </button>
                                </div>


                            </div>
                            <div class="um-item">
                                <div class="um-info">
                                    <div class="email">tonystark@digicentre.com</div>
                                    <div class="name">納塔莎 (Natasha Romanoff)</div>
                                </div>
                                <ul class="um-list">
                                    <li>以下為將合併/捨棄的同帳號資料</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                </ul>
                                <div class="um-fn">
                                    <button class="btn btn-small">
                                        <span>合併資料</span>
                                    </button>
                                    <button class="btn btn-small">
                                        <span>捨棄原帳號</span>
                                    </button>
                                </div>


                            </div>


                            <div class="um-item">
                                <div class="um-info">
                                    <div class="email">tonystark@digicentre.com</div>
                                    <div class="name">納塔莎 (Natasha Romanoff)</div>
                                </div>
                                <ul class="um-list">
                                    <li>以下為將合併/捨棄的同帳號資料</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                </ul>
                                <div class="um-fn">
                                    <button class="btn btn-small">
                                        <span>合併資料</span>
                                    </button>
                                    <button class="btn btn-small">
                                        <span>捨棄原帳號</span>
                                    </button>
                                </div>
                            </div>
                            <div class="um-item">
                                <div class="um-info">
                                    <div class="email">tonystark@digicentre.com</div>
                                    <div class="name">納塔莎 (Natasha Romanoff)</div>
                                </div>
                                <ul class="um-list">
                                    <li>以下為將合併/捨棄的同帳號資料</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                </ul>
                                <div class="um-fn">
                                    <button class="btn btn-small">
                                        <span>合併資料</span>
                                    </button>
                                    <button class="btn btn-small">
                                        <span>捨棄原帳號</span>
                                    </button>
                                </div>
                            </div>
                            <div class="um-item">
                                <div class="um-info">
                                    <div class="email">tonystark@digicentre.com</div>
                                    <div class="name">納塔莎 (Natasha Romanoff)</div>
                                </div>
                                <ul class="um-list">
                                    <li>以下為將合併/捨棄的同帳號資料</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                </ul>
                                <div class="um-fn">
                                    <button class="btn btn-small">
                                        <span>合併資料</span>
                                    </button>
                                    <button class="btn btn-small">
                                        <span>捨棄原帳號</span>
                                    </button>
                                </div>
                            </div>
                            <div class="um-item">
                                <div class="um-info">
                                    <div class="email">tonystark@digicentre.com</div>
                                    <div class="name">納塔莎 (Natasha Romanoff)</div>
                                </div>
                                <ul class="um-list">
                                    <li>以下為將合併/捨棄的同帳號資料</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                </ul>
                                <div class="um-fn">
                                    <button class="btn btn-small">
                                        <span>合併資料</span>
                                    </button>
                                    <button class="btn btn-small">
                                        <span>捨棄原帳號</span>
                                    </button>
                                </div>
                            </div>
                            <div class="um-item">
                                <div class="um-info">
                                    <div class="email">tonystark@digicentre.com</div>
                                    <div class="name">納塔莎 (Natasha Romanoff)</div>
                                </div>
                                <ul class="um-list">
                                    <li>以下為將合併/捨棄的同帳號資料</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                </ul>
                                <div class="um-fn">
                                    <button class="btn btn-small">
                                        <span>合併資料</span>
                                    </button>
                                    <button class="btn btn-small">
                                        <span>捨棄原帳號</span>
                                    </button>
                                </div>
                            </div>
                            <div class="um-item">
                                <div class="um-info">
                                    <div class="email">tonystark@digicentre.com</div>
                                    <div class="name">納塔莎 (Natasha Romanoff)</div>
                                </div>
                                <ul class="um-list">
                                    <li>以下為將合併/捨棄的同帳號資料</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                    <li>羅塔莎 (Natasha Romanoff)</li>
                                </ul>
                                <div class="um-fn">
                                    <button class="btn btn-small">
                                        <span>合併資料</span>
                                    </button>
                                    <button class="btn btn-small">
                                        <span>捨棄原帳號</span>
                                    </button>
                                </div>
                            </div>
                        </section>

                    </div>
                    <div class="right">
                        <div class="title">
                            <h3 class="fz-C">所有帳號</h3>
                            <div class="form-item no-field search">
                                <div class="value">
                                    <input type="search" placeholder="搜尋帳號"/>
                                </div>

                            </div>
                        </div>
                        <div class="scrollbar">
                            <ul class="list-table">
                                <li class="lt-row lt-th">
                                    <div class="flex-5">帳號</div>
                                    <div class="flex-3">姓名</div>
                                    <div class="flex-1 fn max-60"></div>

                                </li>

                                <li class="lt-row">
                                    <div class="flex-5">clark_kent@digicentre.com</div>
                                    <div class="flex-3">克拉克 (Clark Kent)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0101">
                                            <i class="icon icon-35"></i>
                                        </div>
                                    </div>

                                </li>
                                <li class="lt-row">
                                    <div class="flex-5 fc-disabled">clark_kent@digicentre.com</div>
                                    <div class="flex-3 fc-disabled">布魯斯偉恩 (Bruce Wayne)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0102">
                                            <i class="icon icon-36"></i>
                                        </div>
                                    </div>

                                </li>


                                <li class="lt-row">
                                    <div class="flex-5">clark_kent@digicentre.com</div>
                                    <div class="flex-3">克拉克 (Clark Kent)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0101">
                                            <i class="icon icon-35"></i>
                                        </div>
                                    </div>

                                </li>
                                <li class="lt-row">
                                    <div class="flex-5 fc-disabled">clark_kent@digicentre.com</div>
                                    <div class="flex-3 fc-disabled">布魯斯偉恩 (Bruce Wayne)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0102">
                                            <i class="icon icon-36"></i>
                                        </div>
                                    </div>

                                </li>


                                <li class="lt-row">
                                    <div class="flex-5">clark_kent@digicentre.com</div>
                                    <div class="flex-3">克拉克 (Clark Kent)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0101">
                                            <i class="icon icon-35"></i>
                                        </div>
                                    </div>

                                </li>
                                <li class="lt-row">
                                    <div class="flex-5 fc-disabled">clark_kent@digicentre.com</div>
                                    <div class="flex-3 fc-disabled">布魯斯偉恩 (Bruce Wayne)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0102">
                                            <i class="icon icon-36"></i>
                                        </div>
                                    </div>

                                </li>


                                <li class="lt-row">
                                    <div class="flex-5">clark_kent@digicentre.com</div>
                                    <div class="flex-3">克拉克 (Clark Kent)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0101">
                                            <i class="icon icon-35"></i>
                                        </div>
                                    </div>

                                </li>
                                <li class="lt-row">
                                    <div class="flex-5 fc-disabled">clark_kent@digicentre.com</div>
                                    <div class="flex-3 fc-disabled">布魯斯偉恩 (Bruce Wayne)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0102">
                                            <i class="icon icon-36"></i>
                                        </div>
                                    </div>

                                </li>


                                <li class="lt-row">
                                    <div class="flex-5">clark_kent@digicentre.com</div>
                                    <div class="flex-3">克拉克 (Clark Kent)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0101">
                                            <i class="icon icon-35"></i>
                                        </div>
                                    </div>

                                </li>
                                <li class="lt-row">
                                    <div class="flex-5 fc-disabled">clark_kent@digicentre.com</div>
                                    <div class="flex-3 fc-disabled">布魯斯偉恩 (Bruce Wayne)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0102">
                                            <i class="icon icon-36"></i>
                                        </div>
                                    </div>

                                </li>


                                <li class="lt-row">
                                    <div class="flex-5">clark_kent@digicentre.com</div>
                                    <div class="flex-3">克拉克 (Clark Kent)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0101">
                                            <i class="icon icon-35"></i>
                                        </div>
                                    </div>

                                </li>
                                <li class="lt-row">
                                    <div class="flex-5 fc-disabled">clark_kent@digicentre.com</div>
                                    <div class="flex-3 fc-disabled">布魯斯偉恩 (Bruce Wayne)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0102">
                                            <i class="icon icon-36"></i>
                                        </div>
                                    </div>

                                </li>


                                <li class="lt-row">
                                    <div class="flex-5">clark_kent@digicentre.com</div>
                                    <div class="flex-3">克拉克 (Clark Kent)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0101">
                                            <i class="icon icon-35"></i>
                                        </div>
                                    </div>

                                </li>
                                <li class="lt-row">
                                    <div class="flex-5 fc-disabled">clark_kent@digicentre.com</div>
                                    <div class="flex-3 fc-disabled">布魯斯偉恩 (Bruce Wayne)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0102">
                                            <i class="icon icon-36"></i>
                                        </div>
                                    </div>

                                </li>


                                <li class="lt-row">
                                    <div class="flex-5">clark_kent@digicentre.com</div>
                                    <div class="flex-3">克拉克 (Clark Kent)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0101">
                                            <i class="icon icon-35"></i>
                                        </div>
                                    </div>

                                </li>
                                <li class="lt-row">
                                    <div class="flex-5 fc-disabled">clark_kent@digicentre.com</div>
                                    <div class="flex-3 fc-disabled">布魯斯偉恩 (Bruce Wayne)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0102">
                                            <i class="icon icon-36"></i>
                                        </div>
                                    </div>

                                </li>


                                <li class="lt-row">
                                    <div class="flex-5">clark_kent@digicentre.com</div>
                                    <div class="flex-3">克拉克 (Clark Kent)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0101">
                                            <i class="icon icon-35"></i>
                                        </div>
                                    </div>

                                </li>
                                <li class="lt-row">
                                    <div class="flex-5 fc-disabled">clark_kent@digicentre.com</div>
                                    <div class="flex-3 fc-disabled">布魯斯偉恩 (Bruce Wayne)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0102">
                                            <i class="icon icon-36"></i>
                                        </div>
                                    </div>

                                </li>


                                <li class="lt-row">
                                    <div class="flex-5">clark_kent@digicentre.com</div>
                                    <div class="flex-3">克拉克 (Clark Kent)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0101">
                                            <i class="icon icon-35"></i>
                                        </div>
                                    </div>

                                </li>
                                <li class="lt-row">
                                    <div class="flex-5 fc-disabled">clark_kent@digicentre.com</div>
                                    <div class="flex-3 fc-disabled">布魯斯偉恩 (Bruce Wayne)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0102">
                                            <i class="icon icon-36"></i>
                                        </div>
                                    </div>

                                </li>


                                <li class="lt-row">
                                    <div class="flex-5">clark_kent@digicentre.com</div>
                                    <div class="flex-3">克拉克 (Clark Kent)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0101">
                                            <i class="icon icon-35"></i>
                                        </div>
                                    </div>

                                </li>
                                <li class="lt-row">
                                    <div class="flex-5 fc-disabled">clark_kent@digicentre.com</div>
                                    <div class="flex-3 fc-disabled">布魯斯偉恩 (Bruce Wayne)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0102">
                                            <i class="icon icon-36"></i>
                                        </div>
                                    </div>

                                </li>


                                <li class="lt-row">
                                    <div class="flex-5">clark_kent@digicentre.com</div>
                                    <div class="flex-3">克拉克 (Clark Kent)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0101">
                                            <i class="icon icon-35"></i>
                                        </div>
                                    </div>

                                </li>
                                <li class="lt-row">
                                    <div class="flex-5 fc-disabled">clark_kent@digicentre.com</div>
                                    <div class="flex-3 fc-disabled">布魯斯偉恩 (Bruce Wayne)</div>
                                    <div class="flex-1 fn max-60">
                                        <div class="btn btn-icon" data-openmodal="modal-0102">
                                            <i class="icon icon-36"></i>
                                        </div>
                                    </div>

                                </li>

                            </ul>

                        </div>



                    </div>
                   
                </div>
            </div>
        </>
    );
};

export default UserInfo;
