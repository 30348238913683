import React, { useState, useEffect } from "react";
import {
    API_GET_AllCompany,
    API_GET_AdNameList,
    API_GET_Vendor3Name,
    API_GET_Customer3Name,
    API_POST_InterViewList,
    API_GET_InterViewDataCard
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { InitJS, OpenModal, MenuSet } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const InterViewQry = () => {
    var history = useHistory();

    useEffect(() => {
        //把menu顏色置換
        MenuSet("InterViewQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)

    }, []);

    //取得資料 dataCard
    function getData() {
        API_GET_InterViewDataCard()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setCompanyArray(data.result.data.companyArray);
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyId, setCompanyId] = useState();
    const [companyError, setCompanyError] = useState(false);
    //身分別
    const [typeId, setTypeId] = useState();
    const [typeArray, setTypeArray] = useState([
        { id: 0, name: "客戶" },
        { id: 1, name: "供應商" },
    ]);
    const [typeIdError, setTypeIdError] = useState(false);

    const [custom3NameDisabled, setCustom3NameDisabled] = useState(true);

    //客戶別/供應商名稱
    const [iNameArray, setINameArray] = useState([{}]);
    const [iName, setIName] = useState();

    function iNameChange(e) {
        setIName(e.target.value);

        if (e.target.value == null || e.target.value == "") {
            setINameArray([]);
            return;

        }
        if (typeId == 0) {
            //客戶
            API_GET_Customer3Name({
                customerName: e.target.value,
                companyId: companyId
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.result.data != null) {
                        setINameArray(data.result.data);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else if (typeId == 1) {
            //供應商
            API_GET_Vendor3Name({
                vendorName: e.target.value,
                companyId: companyId
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setINameArray(data.result.data);
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    //客戶別/供應商抬頭
    const [iTitleArray, setITitleArray] = useState([{}]);
    const [iTitle, setITitle] = useState();
    function iTitleChange(e) {
        setITitle(e.target.value);
        if (typeId == 0) {
            //客戶
            API_GET_Customer3Name({
                customerTitle: e.target.value,
                companyId: companyId
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.result.data != null) {
                        setITitleArray(data.result.data);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else if (typeId == 1) {
            //供應商
            API_GET_Vendor3Name({
                vendorTitle: e.target.value,
                companyId: companyId
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setITitleArray(data.result.data);
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }
    //
    const [intervieweeID, setIntervieweeID] = useState(null);
    //訪談人員ID
    const [interviewerPersonID, setInterviewPersonID] = useState(null);
    const [adNameArray, setAdNameArray] = useState([{}]);
    const [adName, setAdName] = useState();
    function adNameChange(e) {
        setAdName(e.target.value);
        getAdName();
    }

    const [qryList, setQryList] = useState([{}]);
    //打開查詢列表
    function openModal1(e) {
        if (companyId == undefined || typeId == undefined) {
            if (companyId == undefined) {
                setCompanyError(true);
            } else {
                setTypeIdError(true);
            }
        } else {
            setCompanyError(false);
            setTypeIdError(false);
            var dt = {
                companyId: companyId,
                typeId: typeId == undefined ? null : typeId,
                iName: iName == undefined ? null : iName,
                iTitle: iTitle == undefined ? null : iTitle,
                interviewerPersonID: interviewerPersonID == undefined ? null : interviewerPersonID,
                intervieweeID: intervieweeID == undefined ? null : intervieweeID,
            };

            //console.log(JSON.stringify(dt));
            API_POST_InterViewList(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        if (data.result != null) {
                            setQryList(data.result.data);
                        }
                        OpenModal("modal-1");
                    }
                    else {
                        setQryList([]);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });


        }
    }
    //訪談人員
    function getAdName() {
        API_GET_AdNameList({
            input: document.getElementById("adNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setAdNameArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    useEffect(() => {
        if (companyId == null || companyId === '' || typeId == null || typeId === '')
            setCustom3NameDisabled(true);
        else {
            setCustom3NameDisabled(false);
        }

        setIName("");
        setINameArray([]);

        setITitle("");
        setITitleArray([]);

    }, [companyId, typeId])

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container narrow">
                    <h3 className="fz-B has-border">訪談紀錄查詢</h3>
                </div>
            </div>
            <div className="container narrow">
                <div className={`form-item must ${companyError ? " show-help" : ""}`}>
                    <div className="field">負責公司</div>
                    <div className="value">
                        {companyArray.map((x) => (
                            <div>
                                <input
                                    type="radio"
                                    key={`company_${x.cmpID}`}
                                    id={`company_${x.cmpID}`}
                                    name="companyCheckBox"
                                    value={x.cmpID}
                                    checked={companyId == x.cmpID ? true : false}
                                    onClick={(e) => setCompanyId(x.cmpID)}
                                />
                                <label htmlFor={`company_${x.cmpID}`}>{x.cmpName}</label>
                            </div>
                        ))}
                    </div>
                    <span className="help-word">請選擇負責公司</span>
                </div>

                <div className={`form-item must ${typeIdError ? " show-help" : ""}`}>
                    <div className="field">身分別</div>
                    <div className="value">
                        {typeArray.map((x) => (
                            <div>
                                <input
                                    type="radio"
                                    name="iType"
                                    id={`iType_${x.id}`}
                                    checked={typeId == x.id ? true : false}
                                    onClick={(e) => setTypeId(x.id)}
                                />
                                <label htmlFor={`iType_${x.id}`}>{x.name}</label>
                            </div>
                        ))}
                    </div>
                    <span className="help-word">請輸入身分別</span>
                </div>

                <div className="form-item has-datalist" data-datalist>
                    <div className="field">客戶別/供應商</div>
                    <div className="value">
                        <input
                            id="iNameText"
                            type="text"
                            value={iName}
                            onChange={iNameChange}
                            disabled={custom3NameDisabled}
                        />
                        <ul className="datalist scrollbar">
                            {iNameArray.map((x) =>
                                typeId == 0 ? (
                                    <li
                                        data-value={x.custName}
                                        onClick={(e) => {
                                            setIntervieweeID(x.custID),
                                                setIName(x.custName),
                                                setITitle(x.custTitle);
                                        }}
                                    >
                                        <span className="flex-5 bold">{x.custName}</span>
                                        <span className="flex-3">{x.custTitle}</span>
                                        <span className="flex-3">{x.custTaxID}</span>
                                    </li>
                                ) : (
                                    <li
                                        data-value={x.vendorName}
                                        onClick={(e) => {
                                            setIntervieweeID(x.vendorID),
                                                setIName(x.vendorName),
                                                setITitle(x.vendorTitle);
                                        }}
                                    >
                                        <span className="flex-5 bold">{x.vendorName}</span>
                                        <span className="flex-3">{x.vendorTitle}</span>
                                        <span className="flex-3">{x.vendorTaxID}</span>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                    <span className="help-word">請輸入正確格式</span>
                </div>

                <div className="form-item  has-datalist" data-datalist>
                    <div className="field">公司抬頭</div>
                    <div className="value">
                        <input
                            id="iTitleText"
                            type="text"
                            value={iTitle}
                            onChange={iTitleChange}
                            disabled={custom3NameDisabled}
                        />
                        <ul className="datalist scrollbar">
                            {iTitleArray.map((x) =>
                                typeId == 0 ? (
                                    <li
                                        data-value={x.custTitle}
                                        onClick={(e) => {
                                            setIntervieweeID(x.custID),
                                                setIName(x.custName),
                                                setITitle(x.custTitle);
                                        }}
                                    >
                                        <span className="flex-5 bold">{x.custTitle}</span>
                                        <span className="flex-3">{x.custName}</span>
                                        <span className="flex-3">{x.custTaxID}</span>
                                    </li>
                                ) : (
                                    <li
                                        data-value={x.vendorTitle}
                                        onClick={(e) => {
                                            setIntervieweeID(x.vendorID),
                                                setIName(x.vendorName),
                                                setITitle(x.vendorTitle);
                                        }}
                                    >
                                        <span className="flex-5 bold">{x.vendorTitle}</span>
                                        <span className="flex-3">{x.vendorName}</span>
                                        <span className="flex-3">{x.vendorTaxID}</span>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                    <span className="help-word">請輸入正確格式</span>
                </div>

                <div className="form-item has-datalist" data-datalist>
                    <div className="field">訪談人員</div>
                    <div className="value">
                        <input id="adNameText" type="text" onChange={adNameChange} />
                        <ul className="datalist scrollbar">
                            {adNameArray.map((x) => (
                                <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => setInterviewPersonID(x.id)}  >
                                    <span className="flex-4">{x.enName}({x.tc_name})</span>
                                    <span className="flex-5">{x.mail}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="help-word">請輸入正確格式</div>
                </div>

                <div className="form-item btn-area sp">
                    <div className="left"></div>
                    <div className="right">
                        <a className="btn" onClick={openModal1}>
                            <span>查詢</span>
                        </a>
                    </div>
                </div>
            </div>
            <div id="modal-1" className="modal large" data-modal data-mid="20.1.1">
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">查詢結果</h3>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <ul className="list-table">
                            <li className="lt-row lt-th">
                                <div className="flex-2">隸屬公司</div>
                                <div className="flex-3">客戶別</div>
                                <div className="flex-3">公司抬頭</div>
                                <div className="flex-2">訪談人員</div>
                                {/*<div className="flex-2 fn"></div>*/}
                            </li>
                            {qryList.map((x) => (
                                <li className="lt-row">
                                    <div className="flex-2">{x.companyName}</div>
                                    <div className="flex-3">
                                        <a
                                            href={`/Main?path=InterViewList&companyId=${companyId}&typeId=${typeId}&intervieweeid=${x.intervieweeId}`}
                                        >
                                            {x.iName}
                                        </a>
                                    </div>
                                    <div className="flex-3">
                                        {x.iTitle }
                                    </div>
                                    <div className="flex-2">{x.interviewPersonName}</div>
                                </li>
                            ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InterViewQry;
