import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { API_Login_Change } from "../../global/constants";
import { useHistory } from "react-router-dom";

function cpwdform() {
    var history = useHistory();
    var currentModal;
    let currentLevel = 1;
    const modals = document.querySelectorAll("[data-modal]");
    const modalBtns = document.querySelectorAll("[data-openmodal]");
    modals.forEach((element) => {
        var modalBody = element.querySelector(".modal-container");
        var modalClose = element.querySelectorAll("[data-close]");
        var modalsBack = element.querySelector("[data-back]");
        ////console.log(modalClose);
        element.addEventListener("click", function (e) {
            if (e.target == modalsBack) {
                var showLayerClass = "has-layer-" + currentLevel;
                currentLevel = currentLevel - 1;
                element.classList.remove("active");
                document.body.classList.remove(showLayerClass);
            } else if (e.target == element || e.target.hasAttribute("data-close")) {
                /*這邊可能要再增加一個判斷，判斷這個[data-close]是否要到指定層級*/

                var showLayerClass = "has-layer-" + currentLevel;
                if (typeof currentModal !== "undefined") {
                    currentModal.classList.remove("active");
                } else {
                }
                currentLevel = currentLevel - 1;
                element.classList.remove(
                    "active",
                    "layer-1",
                    "layer-2",
                    "layer-3",
                    "layer-4",
                    "layer-5",
                    "layer-6"
                );
                document.body.classList.remove(showLayerClass);
            }
        });
    });
    document.onkeydown = function (event) {
        var target, code, tag;
        if (!event) {
            event = window.event;
            code = event.keyCode;
            if (code == 13) {
                next();
            }
        } else {
            target = event.target; //針對遵循w3c標準的瀏覽器
            code = event.keyCode;
            if (code == 13) {
                next();
            }
        }
    };
    const [pwd, setPWD] = useState("");
    function pwdChange(e) {
        setPWD(e.target.value);
    }

    const [cpwd, setCPWD] = useState("");
    function cpwdChange(e) {
        setCPWD(e.target.value);
    }
    const [modalStr, setModalStr] = useState("");
    const [msgStr, setMsgStr] = useState("");

    function next() {
        if (cpwd != pwd) {
            setModalStr("密碼不一致，請重新輸入");
            var id = "modal-2";
            var target = document.querySelector("#" + id);
            target.classList.add("active");
            return false;
        }

        API_Login_Change({
            NewPassword: pwd,
            RePassword: cpwd,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    localStorage.setItem("validRemainDays", 90);
                    history.push("?path=authenticator");
                } else {
                    setMsgStr(data.message);
                }
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
    }
    const showpwd1 = () => {
        if (document.getElementById("pwd").type === "password") {
            document.getElementById("pwd").type = "text";
            document.getElementById("eyeIcon").classList.add("active");
        } else {
            document.getElementById("pwd").type = "password";
            document.getElementById("eyeIcon").classList.remove("active");
        }
    };
    const showpwd2 = () => {
        if (document.getElementById("cpwd").type === "password") {
            document.getElementById("cpwd").type = "text";
            document.getElementById("eye2").classList.add("active");
        } else {
            document.getElementById("cpwd").type = "password";
            document.getElementById("eye2").classList.remove("active");
        }
    };
    return (
        <div style={{ width: "100%" }} id="div_change">
            <div className="subtitle">
                <h2 className="fz-B">變更密碼</h2>
            </div>

            <div className="form-container">
                <div className="form-item short no-field lg" id="DIV_pwd">
                    <div className="value">
                        <div className="password">
                            <input
                                type="password"
                                id="pwd"
                                value={pwd}
                                onChange={pwdChange}
                                placeholder="請輸入新密碼"
                            />
                            <i className="eye" id="eyeIcon" onClick={showpwd1}></i>
                        </div>
                    </div>
                    <span className="help-word">
                        帳號錯誤
                    </span>
                </div>
                <div className="form-item short no-field lg " id="DIV_cpwd">
                    <div className="value">
                        <div className="password">
                            <input
                                type="password"
                                id="cpwd"
                                value={cpwd}
                                onChange={cpwdChange}
                                placeholder="再次確認新密碼"
                            />
                            <i className="eye" id="eye2" onClick={showpwd2}></i>
                        </div>
                    </div>
                    <span className="help-word">
                        2 次密碼輸入不相同
                    </span>
                </div>
                <div className="form-item">
                    <div className="notification">
                        {msgStr}
                    </div>
                </div>
            </div>
            <div className="form-item short btn-area">
                <a className="btn full btn-dark" onClick={next}>
                    <span>下一步</span>
                </a>
            </div>
            <div id="modal-2" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" data-close>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default cpwdform;
