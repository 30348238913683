import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_AllCompany,
    API_GET_Customer3Name,
    API_GET_CustomerNickName,
    API_GET_CustCreditDataList,
    API_GET_CustCreditDataCard
} from "../../global/constants";
import { NumFormart, OpenModal, CloseModal, InitJS, MenuSet } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const CreditsQry = () => {
    var history = useHistory();

    useEffect(() => {
        //把menu顏色置換
        MenuSet("CreditsQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    //重新取得 客戶別 客戶別Title 客戶統編 List
    function getCustomer3Name(index) {
        API_GET_Customer3Name({
            customerName: document.getElementById("customerNameText").value,
            customerTitle: document.getElementById("customerTitleText").value,
            customerTaxId: document.getElementById("customerTaxIdText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                var dataName = [];
                var dataTitle = [];
                var dataTaxId = [];

                if (data.result != null && data.result.data != null) {
                    if (index == 1) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataName.length; j++) {
                                if (dataName[j].custName == data.result.data[i].custName) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataName.push({
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxId: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerNameArray(dataName);
                    } else if (index == 2) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTitle.length; j++) {
                                if (dataTitle[j].custTitle == data.result.data[i].custTitle) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTitle.push({
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxId: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerTitleArray(dataTitle);
                    } else if (index == 3) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTaxId.length; j++) {
                                if (dataTaxId[j].custTaxID == data.result.data[i].custTaxID) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTaxId.push({
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerTaxArray(dataTaxId);
                    }
                }
            })
            
    }

    //重新取得 客戶簡稱
    function getCustomerNickName() {
        API_GET_CustomerNickName({
            input: document.getElementById("customerNickNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setCustomerNickNameArray(data.result.data);
            })
             
    }

    function getData() {
        API_GET_CustCreditDataCard()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //負責公司
                    setCompanyArray(data.result.data.companyArray);
                }
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //查詢列表
    const [qryList, setQryList] = useState([{}]);
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [cmpName, setCmpName] = useState();

    const [F1301, setF1301] = useState();
    const [F1302, setF1302] = useState(1);
    //客戶別
    const [customerName, setCustomerName] = useState();
    const [customerNameArray, setCustomerNameArray] = useState([{}]);
    function customerNameChange(e) {
        setCustomerName(e.target.value);
        getCustomer3Name(1);
    }
    //客戶簡稱
    const [customerNickName, setCustomerNickName] = useState();
    const [customerNickNameArray, setCustomerNickNameArray] = useState([{}]);
    function customerNickNameChange(e) {
        setCustomerNickName(e.target.value);
        getCustomerNickName();
    }
    //客戶抬頭
    const [customerTitle, setCustomerTitle] = useState();
    const [customerTitleArray, setCustomerTitleArray] = useState([{}]);
    function customerTitleChange(e) {
        setCustomerTitle(e.target.value);
        getCustomer3Name(2);
    }
    //客戶統編
    const [customerTaxId, setCustomerTaxId] = useState();
    const [customerTaxArray, setCustomerTaxArray] = useState([{}]);
    function customerTaxIdChange(e) {
        var v = e.target.value.replace(/[^A-Za-z0-9]/g, '');
        setCustomerTaxId(v);
        getCustomer3Name(3);
    }
    //僅顯示
    const [searchNodata, setSearchNodata] = useState(false);
    function openModal1(e) {
        if (F1301 == undefined) {
            document.getElementById("companyDiv").classList.add("show-help");
            return false;
        } else {
            document.getElementById("companyDiv").classList.remove("show-help");
        }
        API_GET_CustCreditDataList({
            companyId: F1301,
            customerName: searchNodata == false ? document.getElementById("customerNameText").value : "",
            customerNickname: searchNodata == false ? document.getElementById("customerNickNameText").value : "",
            customerTitle: searchNodata == false ? document.getElementById("customerTitleText").value : "",
            customerTaxId: searchNodata == false ? document.getElementById("customerTaxIdText").value : "",
            searchNodata: searchNodata == true ? 1 : 0
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code != "0000" || data.result.length == 0) {
                    history.push("/");
                } else {
                    setQryList(data.result.data);
                }
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });

        OpenModal("modal-1");
    }
    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container narrow">
                    <h3 className="fz-B has-border">信用額度查詢</h3>
                </div>
            </div>
            <div className="container narrow">
                <div className="form-container">
                    <div className="form-item must" id="companyDiv">
                        <div className="field">負責公司</div>
                        <div className="value">
                            {companyArray.map((x) => (
                                <div key={x.id}>
                                    <input
                                        type="radio"
                                        id={`f1301-${x.cmpID}`}
                                        name="f1301"
                                        value={x.cmpID}
                                        checked={F1301 == x.cmpID ? "checked" : ""}
                                        onClick={(e) => {
                                            setF1301(x.cmpID), setCmpName(x.cmpName);
                                        }}
                                    />
                                    <label htmlFor={`f1301-${x.cmpID}`}>{x.cmpName}</label>
                                </div>
                            ))}
                        </div>
                        <div className="help-word">請選擇負責公司</div>
                    </div>

                    <div className="form-item">
                        <div className="field">僅顯示未設定</div>
                        <div className="value">
                            <input id="searchData" type="checkbox" checked={searchNodata ? "checked" : ""} onClick={(e) => { setSearchNodata(!searchNodata); }} />
                            <label htmlFor="searchData">是</label>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">客戶別</div>
                        <div className="value">
                            <input
                                id="customerNameText"
                                type="text"
                                onChange={customerNameChange}
                                value={customerName}
                                disabled={searchNodata ? true : false}
                            />
                            <ul className="datalist scrollbar">
                                {customerNameArray.map((x) => (
                                    <li
                                        data-value={x.custName}
                                        onClick={(e) => {
                                            setCustomerName(x.custName),
                                                setCustomerTitle(x.custTitle),
                                                setCustomerTaxId(x.custTaxId);
                                        }}
                                    >
                                        <span className="flex-4 bold">{x.custName}</span>
                                        <span className="flex-4 bold">{x.custTitle}</span>
                                        <span className="flex-4 bold">{x.custTaxId}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">客戶簡稱</div>
                        <div className="value">
                            <input
                                id="customerNickNameText"
                                type="text"
                                onChange={customerNickNameChange}
                                value={customerNickName}
                                disabled={searchNodata ? true : false}
                            />
                            <ul className="datalist scrollbar">
                                {customerNickNameArray.map((x) => (
                                    <li
                                        data-value={x.custNickName}
                                        onClick={(e) => setCustomerNickName(x.custNickName)}
                                    >
                                        <span className="flex-10 bold">{x.custNickName}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">公司抬頭</div>
                        <div className="value">
                            <input
                                id="customerTitleText"
                                type="text"
                                onChange={customerTitleChange}
                                value={customerTitle}
                                disabled={searchNodata ? true : false}
                            />
                            <ul className="datalist scrollbar">
                                {customerTitleArray.map((x) => (
                                    <li
                                        data-value={x.custTitle}
                                        onClick={(e) => {
                                            setCustomerName(x.custName),
                                                setCustomerTitle(x.custTitle),
                                                setCustomerTaxId(x.custTaxId);
                                        }}
                                    >
                                        <span className="flex-4 bold">{x.custName}</span>
                                        <span className="flex-4 bold">{x.custTitle}</span>
                                        <span className="flex-4 bold">{x.custTaxId}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">公司統編 / 編號</div>
                        <div className="value">
                            <input
                                id="customerTaxIdText"
                                type="text"
                                onChange={customerTaxIdChange}
                                value={customerTaxId}
                                maxLength="20"
                                disabled={searchNodata ? true : false}
                            />
                            <ul className="datalist scrollbar">
                                {customerTaxArray.map((x) => (
                                    <li
                                        data-value={x.custTaxID}
                                        onClick={(e) => {
                                            setCustomerName(x.custName),
                                                setCustomerTitle(x.custTitle),
                                                setCustomerTaxId(x.custTaxId);
                                        }}
                                    >
                                        <span className="flex-4 bold">{x.custName}</span>
                                        <span className="flex-4 bold">{x.custTitle}</span>
                                        <span className="flex-4 bold">{x.custTaxID}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item btn-area sp">
                        <div className="left"></div>
                        <div className="right">
                            <a className="btn" onClick={openModal1}>
                                <span>查詢</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-1" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">查詢結果</h3>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <ul className="list-table">
                            <li className="lt-row lt-th">
                                <div className="flex-2">隸屬公司</div>
                                <div className="flex-2">公司抬頭</div>
                                <div className="flex-2">統編/ 編號</div>
                                <div className="flex-2">信用額度</div>
                                <div className="flex-2">信用餘額</div>
                            </li>
                            {qryList.map((x) => (
                                <li className="lt-row">
                                    <div className="flex-2">{cmpName}</div>
                                    <div className="flex-2">
                                        <a href={`/Main?path=CreditsView&type=${F1302}&customerId=${x.custID}`}  >
                                            {x.custTitle}
                                        </a>
                                    </div>
                                    <div className="flex-2">{x.custTaxID}</div>
                                    <div className="flex-2">{x.credit}</div>
                                    <div className="flex-2">{x.creditBalance}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreditsQry;
