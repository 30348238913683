import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_AllCompany,
    API_GET_Customer3Name,
    API_GET_CloudAccountingSearchHome,
} from "../../global/constants";
import { OpenModal, CloseModal, InitJS, MenuSet } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const CATQry = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        //把menu顏色置換
        MenuSet("CATRowSetQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    var moment = require("moment");
    function getData() {
        API_GET_AllCompany()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setCompanyArray(data.result.data);
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

        API_GET_CloudAccountingSearchHome()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setProductName1Array(data.result.data.productName1Array);
                    //setLatestMonth(data.result.data.latestMonth);
                    var tmpMonthArray = [];
                    var isOK = 0;
                    for (var i = -12; i < 10; i++) {
                        if (isOK == 1) {
                            break;
                        }
                        var ym = moment().add(i, "M").format("YYYY/MM");
                        if (ym == data.result.data.latestMonth) {
                            isOK = 1;
                        }
                        tmpMonthArray.push(ym);
                    }
                    setYMArray(tmpMonthArray);
                } else {
                    setModalStr(data.message);
                    openModal("modal-error");
                }
            })
            .catch((err) => {
                 
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }

    //查詢類別
    const [qryType, setQryType] = useState(1);
    const [companyId, setCompanyId] = useState();
    const [companyIdError, setCompanyIdError] = useState(false);
    const [customerId, setCustomerId] = useState();
    const [monthError, setMonthError] = useState(false);
    const [startMonth, setStartMonth] = useState();
    const [endMonth, setEndMonth] = useState();
    const [latestMonth, setLatestMonth] = useState();
    const [productName1, setProductName] = useState();
    const [productName1Error, setProductNameError] = useState(false);
    const [productName1Array, setProductName1Array] = useState([{}]);
    const [ymArray, setYMArray] = useState([]);
    const [productId, setProductId] = useState();
    //客戶別
    const [customerName, setCustomerName] = useState();
    const [customerNameError, setCustomerNameError] = useState(false);
    const [customerNameArray, setCustomerNameArray] = useState([{}]);
    const [modalStr, setModalStr] = useState();
    function customerNameChange(e) {
        setCustomerName(e.target.value);
        getCustomer3Name(1);
    }
    //客戶抬頭
    const [customerTitle, setCustomerTitle] = useState();
    const [customerTitleArray, setCustomerTitleArray] = useState([{}]);
    function customerTitleChange(e) {
        setCustomerTitle(e.target.value);
        getCustomer3Name(2);
    }
    //客戶統編
    const [customerTaxId, setCustomerTaxId] = useState();
    const [customerTaxIdError, setCustomerTaxIdError] = useState(false);
    const [customerTaxArray, setCustomerTaxArray] = useState([{}]);
    function customerTaxIdChange(e) {
        setCustomerTaxId(e.target.value);
        getCustomer3Name(3);
    }
    //重新取得 客戶別 客戶別Title 客戶統編 List
    function getCustomer3Name(index) {
        var dt = [];
        if (index == 1) {
            dt = { customerName: document.getElementById("customerNameText").value }
        } else if (index == 2) {
            dt = { customerTitle: document.getElementById("customerTitleText").value }
        } else if (index == 3) {
            dt = { customerTaxId: document.getElementById("customerTaxIdText").value }
        }
        API_GET_Customer3Name(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                var dataName = [];
                var dataTitle = [];
                var dataTaxId = [];

                if (data.result != null && data.result.data != null) {
                    if (index == 1) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataName.length; j++) {
                                if (dataName[j].custName == data.result.data[i].custName) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataName.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxId: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerNameArray(dataName);
                    } else if (index == 2) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTitle.length; j++) {
                                if (dataTitle[j].custTitle == data.result.data[i].custTitle) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTitle.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxId: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerTitleArray(dataTitle);
                    } else if (index == 3) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTaxId.length; j++) {
                                if (dataTaxId[j].custTaxID == data.result.data[i].custTaxID) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTaxId.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerTaxArray(dataTaxId);
                    }
                } else {
                    if (index == 1) {
                        setCustomerNameArray([{}]);
                    } else if (index == 2) {
                        setCustomerTitleArray([{}]);
                    } else if (index == 3) {
                        setCustomerTaxArray([{}]);
                    }
                }
            })
            
    }

    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    //查詢
    function Qry() {
        var path = "";
        var hasError = false;
        if (qryType == "1") {
            //console.log(companyId);
            //客戶 API15
            if (companyId === null || companyId === "" || companyId == undefined) {
                setCompanyIdError(true);
                hasError = true;
            } else {
                setCompanyIdError(false);
            }
           
            if (customerId === null || customerId === "" || customerId == undefined) {
                setCustomerNameError(true);
                hasError = true;
            } else {
                setCustomerNameError(false);
            }
            
            if (customerName === null || customerName === "" || customerName == undefined) {
               
                setCustomerNameError(true);
                hasError = true;
            } else {
                setCustomerNameError(false);
            }

            if (customerTaxId === null || customerTaxId === "" || customerTaxId == undefined) {
                setCustomerTaxIdError(true);
                hasError = true;
            } else {
                setCustomerTaxIdError(false);
            }

            if (hasError == false) {
                path = "/Main?path=CATQryList1&companyId=" + companyId + "&customerId=" + customerId;
            }
        } else {
            //產品 API16
            if (companyId === null || companyId === "") {
                setCompanyIdError(true);
                hasError = true;
            } else {
                setCompanyIdError(false);
            }
            if (productName1 === null || productName1 === "" || productName1 === undefined) {
                setProductNameError(true);
                hasError = true;
            } else {
                setProductNameError(false);
            }
            
            if (hasError == false) {
                path = "/Main?path=CATQryList2&companyId=" + companyId + "&productName1=" + productName1;
            }
        }
        if (startMonth == null || startMonth == "" || endMonth == null || endMonth == "") {
            setMonthError(true);
            hasError = true;
        } else {
            setMonthError(false);
            path = path + "&startMonth=" + startMonth + "&endMonth=" + endMonth;
        }


        if (hasError == false) {
            history.push(path);
        }
    }
    return (
        <>
            <div class="content-body">
                <div class="title-area">
                    <div class="container narrow">
                        <h3 class="fz-B has-border">雲端帳務查詢</h3>
                    </div>
                </div>
                <div class="container narrow">
                    <form action="" class="form-container">
                        <div class="form-item must">
                            <div class="field">隸屬公司</div>
                            <div class="value">
                                {companyArray.map((x) => (
                                    <div key={x.id}>
                                        <input
                                            type="radio"
                                            id={`company_${x.cmpID}`}
                                            value={x.cmpID}
                                            checked={companyId == x.cmpID ? "checked" : ""}
                                            onClick={(e) => {
                                                setCompanyId(x.cmpID)
                                            }}
                                        />
                                        <label htmlFor={`company_${x.cmpID}`} >{x.cmpName}</label>
                                    </div>
                                ))}
                            </div>
                            <span class="help-word">
                                請選擇隸屬公司
                            </span>
                        </div>

                        <div class="form-item must">
                            <div class="field">身份</div>
                            <div class="value">
                                <div>
                                    <input type="radio" id="rd2-1" name="rd2" checked={qryType == 1 ? true : false} onClick={() => setQryType(1)} />
                                    <label htmlFor="rd2-1">客戶</label>
                                </div>
                                <div>
                                    <input type="radio" id="rd2-2" name="rd2" checked={qryType == 2 ? true : false} onClick={() => setQryType(2)} />
                                    <label htmlFor="rd2-2">產品</label>
                                </div>
                            </div>
                            <span class="help-word">
                                請選擇身份
                            </span>
                        </div>
                        {
                            qryType == 1 ?
                                <>
                                    <div className={`form-item must has-datalist ${customerNameError ? 'show-help' : ''}`} data-datalist>
                                        <div className="field">客戶別</div>
                                        <div className="value">
                                            <input id="customerNameText" type="text" onChange={customerNameChange} value={customerName} />
                                            <ul className="datalist scrollbar">
                                                {customerNameArray.map((x) => (
                                                    <li
                                                        data-value={x.custName}
                                                        onClick={(e) => {
                                                            setCustomerId(x.custID),
                                                                setCustomerName(x.custName),
                                                                setCustomerTitle(x.custTitle),
                                                                setCustomerTaxId(x.custTaxId);
                                                        }}
                                                    >
                                                        <span className="flex-4 bold">{x.custName}</span>
                                                        <span className="flex-4 bold">{x.custTitle}</span>
                                                        <span className="flex-4 bold">{x.custTaxId}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <span className="help-word">請輸入客戶別</span>
                                    </div>

                                    <div className={`form-item must has-datalist ${customerTaxIdError ? 'show-help' : ''}`} data-datalist>
                                        <div className="field">統編 / 編號</div>
                                        <div className="value">
                                            <input id="customerTaxIdText" type="text" onChange={customerTaxIdChange} value={customerTaxId} />
                                            <ul className="datalist scrollbar">
                                                {customerTaxArray.map((x) => (
                                                    <li data-value={x.custTaxID} onClick={(e) => {
                                                        setCustomerId(x.custName.custID),
                                                            setCustomerName(x.custName),
                                                            setCustomerTitle(x.custTitle),
                                                            setCustomerTaxId(x.custTaxId);
                                                    }}
                                                    >
                                                        <span className="flex-4 bold">{x.custName}</span>
                                                        <span className="flex-4 bold">{x.custTitle}</span>
                                                        <span className="flex-4 bold">{x.custTaxID}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <span className="help-word">請輸入正確格式</span>
                                    </div>
                                </>
                                :
                                qryType == 2 ?
                                    <div class={`form-item must  ${productName1Error ? 'show-help' : ''}`}>
                                        <div class="field">雲端細項總稱</div>
                                        <div class="value">
                                            <select onChange={e => setProductName(e.target.value)}>
                                                <option value="">請選擇</option>)
                                                {
                                                    productName1Array.map(x =>
                                                        <option value={x}>{x}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div class="help-word">
                                            請輸入雲端細項總稱
                                        </div>
                                    </div>
                                    : null
                        }


                        <div class={`form-item must ${monthError ? 'show-help' : ''}`}>
                            <div class="field">月結月份</div>
                            <div class="value multi-inline">
                                <div class="flex-5">
                                    <select onChange={(e) => setStartMonth(e.target.value)}>
                                        <option id="">請選擇</option>
                                        {
                                            ymArray.map(x =>
                                                <option value={x}>{x}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div class="flex-1 center">
                                    ~
                                </div>
                                <div class="flex-5 last">
                                    <select onChange={(e) => setEndMonth(e.target.value)}>
                                        <option id="">請選擇</option>
                                        {
                                            ymArray.map(x =>
                                                <option value={x}>{x}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                            <div class="help-word">
                                請輸入完整月結月份
                            </div>
                        </div>

                        <div class="form-item btn-area sp">
                            <div class="left">

                            </div>
                            <div class="right">
                                <a class="btn" onClick={Qry}>
                                    <span>查詢</span>
                                </a>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default CATQry;
