import React, { useState, useEffect } from "react";
import HtmlParse from 'html-react-parser';
import { useHistory } from "react-router-dom";
import {
    API_GET_InvoiceDeleteSelect,
    API_POST_InvoiceDeleteAudit,

} from "../../global/constants";
import { NumFormart, CloseModal, OpenModal, InitJS, MenuSet, SetOnbeforeunload, SetHasChange } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
import OrderDetail from "../Common/OrderDetail"

const InvoiceVoidItem = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const [reGet, setReGet] = useState(false);
    useEffect(() => {
        //把menu顏色置換
        MenuSet("StatementNew");
        //抓取資料 
        getdata();
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    useEffect(() => {
        if (reGet) {
            //內頁資料有刪除/修改 重抓
            getdata();
        }
        setReGet(false);
    }, [reGet]);
    function getdata() {

        var dt = {
            companyId: parseInt(queryParams.get("companyId")),
            incomeMonth: queryParams.get("incomeMonth"),
            customerName: queryParams.get("customerName") == "" ? null : queryParams.get("customerName"),
            customerTitle: queryParams.get("customerTitle") == "" ? null : queryParams.get("customerTitle"),
            customerTaxId: queryParams.get("customerTaxId") == "" ? null : queryParams.get("customerTaxId"),
            orderNumber: queryParams.get("orderNumber") == "" ? null : queryParams.get("orderNumber"),
        }
        console.log("發票作廢查詢資料列表請求", JSON.stringify(dt));
        API_GET_InvoiceDeleteSelect(dt)
            .then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    if (data.result.data.data != null) {
                        var dt = data.result.data.data;
                        for (var i = 0; i < dt.length; i++) {
                            dt[i].voidReason = "";
                            dt[i].isChecked = false;
                            //解除反灰
                            if (document.getElementById('voidReason_' + i)) {
                                document.getElementById('voidReason_' + i).disabled = false;
                                document.getElementById('voidReason_' + i).removeAttribute("data-requiredexp");
                                document.getElementById('voidReason_' + i).classList.remove("error");
                            }

                        }
                        setDataList(dt);
                    }
                    setCompanyName(data.result.data.companyName);
                    setIncomeMonth(data.result.data.incomeMonth)
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }
    const [companyName, setCompanyName] = useState();
    const [incomeMonth, setIncomeMonth] = useState();
    const [dataList, setDataList] = useState([]);

    const [checkArray, setCheckArray] = useState([]);
    const [orderId, setOrderId] = useState();
    const [checkSum, setCheckSum] = useState(0);
    const [modalStr, setModalStr] = useState();
    const [isControlDisabled, setIsControlDisabled] = useState(false);

    function CheckHasIllegalCharactersVoidReason(inputText) {
        let illegalCharacters = /[\\*?|]/g;
        return illegalCharacters.test(inputText);
    }

    function openModalOrder(orderid) {
        setOrderId(orderid);
        OpenModal("modal-order");
    }
    function checkChange(index, txnId, isCheck) {
        var tmpArray = [];
        var s = 0;
        var c41 = document.getElementsByName("c41");
        //判斷同樣序,就一起勾選/一起取消勾選是否完成
        var processFinish = false;
        for (var i = 0; i < c41.length; i++) {
            if (i == index) {
                c41[i].checked = isCheck;
            }
            //如果未處理樣序,就一起勾選/一起取消勾選 執行一次
            if (processFinish == false) {
                for (var j = 0; j < c41.length; j++) {
                    if (dataList[j].txnId == txnId) {
                        c41[j].checked = isCheck;
                    }
                }
                processFinish = true;
            }
            if (c41[i].checked) {
                tmpArray.push(parseInt(c41[i].value));
                s++;
            }

        }
        setCheckSum(s);
        setCheckArray(tmpArray);
    }

    //重新調整Checked
    function ReChecked(txnId, isChecked) {
        var isFirstOver = false;
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (item.txnId == txnId) {
                    if (isChecked == false) {
                        document.getElementById('voidReason_' + i).disabled = false;
                        document.getElementById('voidReason_' + i).removeAttribute("data-requiredexp");
                        document.getElementById('voidReason_' + i).classList.remove("error");
                    }
                    else {
                        if (isFirstOver && isChecked) {
                            //除了第一個 其他要反灰 解必填
                            document.getElementById('voidReason_' + i).disabled = true;
                            document.getElementById('voidReason_' + i).removeAttribute("data-requiredexp");
                        } else {
                            //反之解除反灰 上必填
                            document.getElementById('voidReason_' + i).disabled = false;
                            document.getElementById('voidReason_' + i).setAttribute("data-requiredexp", true);

                        }
                    }

                    item.isChecked = isChecked;
                    isFirstOver = true;
                }
                return item;
            });
        });
    }

    const [c40checked, setC40checked] = useState(false);
    function c40All(e) {
        var check = document.getElementById("c40").checked;
        setC40checked(check);
        setDataList(function (prev) {
            return prev.map((item, i) => {
                item.isChecked = check;
                ReChecked(item.txnId, check);
                return item;
            });
        });
    }

    //產生發票申請單
    function openModalDelete() {
        var id = "modal-del";
        if (dataList.filter(x => x.isChecked).length == 0) {
            id = "modal-error"
            setModalStr("請選擇訂單編號");
        }
        OpenModal(id);
    }
    //主畫面資料修改
    function dataChange(e, index, col) {
        SetHasChange(true);

        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "voidReason") {
                        item.voidReason = e.target.value;
                    } else if (col == "isChecked") {
                        item.isChecked = e.target.checked;
                        //如果是勾選 全部相同txnId的都要一樣
                        ReChecked(item.txnId, e.target.checked);
                    }
                }
                return item;
            });
        });
    }

    useEffect(() => {
        console.log("資料列表", dataList);
        if (dataList && dataList.length > 0 && dataList.filter(x => x.isChecked).length == dataList.length) {
            setC40checked(true);
        }
        else {
            setC40checked(false);
        }
    }, [dataList]);


    //作廢
    function del() {
        document.getElementById('delBtn').classList.add('disabled');
        var dt = [];
        var hasError = false;
        const requiredlist = document.querySelectorAll("[data-requiredexp]");
        requiredlist.forEach((element) => {
            if (element.value == "") {
                //console.log(element);
                element.classList.add("error");
                hasError = true;
            }
            else if (CheckHasIllegalCharactersVoidReason(element.value)) {
                element.classList.add("error");
                hasError = true;
            }
            else {
                element.classList.remove("error");
            }
        });

        //#region 有錯誤滑動到指定顯示
        if (hasError) {
            //發票品名
            for (var i = 0; i < dataList.length; i++) {
                if (dataList[i].isChecked == true) {
                    var voidReason = document.getElementById('voidReason_' + i).classList.contains('error');

                    if (voidReason) {
                        let section = document.querySelector(`#voidReason_${i}`);
                        section.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

                        CloseModal("modal-del");
                        document.getElementById('delBtn').classList.remove("disabled");
                        return false;
                    }
                }
            }
        }
        //#endregion

        for (var i = 0; i < dataList.length; i++) {
            if (dataList[i].isChecked) {
                dt.push(
                    {
                        orderId: dataList[i].orderId,
                        orderProductId: dataList[i].orderProductId,
                        orderType: dataList[i].orderType,
                        incomeMonth: dataList[i].incomeMonth,
                        fineTuneId: dataList[i].fineTuneId,
                        invoiceOrder: dataList[i].invoiceProject,
                        invoiceItem: dataList[i].invoiceItem,
                        item1: dataList[i].item1,
                        item2: dataList[i].item2,
                        item3: dataList[i].item3,
                        item4: dataList[i].item4,
                        item5: dataList[i].item5,
                        voidReason: dataList[i].voidReason,
                        quantity: dataList[i].quantity,
                        unitPrice: dataList[i].unitPrice,
                        txnId: dataList[i].txnId,
                    }
                );
            }
        }
        //console.log(JSON.stringify(dt));
        CloseModal("modal-del");
        API_POST_InvoiceDeleteAudit(dt)
            .then((response) => response.data)
            .then((data) => {
                //console.log(data);
                SetHasChange(false);
                document.getElementById('delBtn').classList.remove('disabled');
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setModalStr(data.message);
                if (data.code == "0000") {
                    getdata();
                    OpenModal("modal-ok");
                } else {
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {
                console.log("發票作廢失敗", err);
                CloseModal("modal-del");
                document.getElementById('delBtn').classList.remove('disabled');
            });
    }

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container full">
                    <h3 className="fz-B has-border">訂單公司:{companyName} </h3>
                </div>
            </div>
            <div className="container full">
                <div className="info-holder">
                    <div className="left">營收月份：{incomeMonth}</div>
                    <div className="right">

                    </div>
                </div>
                <div className="scroll-wrapper for-4-1">
                    <div className="list-table-wrapper">
                        <ul className="list-table">
                            <li class="lt-row lt-th sticky-head">
                                <div className="flex-1 max1">
                                    <input type="checkbox" id="c40" name="c40" onChange={c40All} checked={c40checked} />
                                    <label htmlFor="c40"></label>
                                </div>
                                <div class="flex-3">訂單編號</div>
                                <div class="flex-2">發票狀態</div>
                                <div class="flex-1 center">發票序</div>
                                <div class="flex-1 center">發票項</div>
                                <div class="flex-2 center">銷售類型</div>
                                <div class="flex-2">客戶別</div>
                                <div class="flex-3 right">費用金額</div>
                                <div class="flex-3">發票品名</div>
                                <div class="flex-3">發票產品別</div>
                                <div class="flex-4">產品細項</div>
                                <div class="flex-5">公司抬頭</div>
                                <div class="flex-3">負責業務</div>
                                <div class="flex-4 must">作廢原因</div>
                                {/*<div class="flex-3">GUID</div>*/}
                            </li>
                            {
                                dataList == null || dataList.length == 0 ? null :
                                    dataList.map((x, i) =>
                                        <li className={`lt-row ${CheckHasIllegalCharactersVoidReason(x.voidReason) ? 'ovv' : ''}`}>
                                            <div className="flex-1 max1">
                                                <input type="checkbox" id={`c41_${i}`} name="c41" value={i} onClick={e => dataChange(e, i, "isChecked")} checked={x.isChecked == null ? false : x.isChecked} />
                                                <label htmlFor={`c41_${i}`}></label>
                                            </div>
                                            <div className="flex-3">
                                                <a href={`/Main?path=SaleOrderEdit&orderId=${x.orderId}`} target="_blank" className={x.prevInvoiceState == 2 || x.tradableBalance < 0 ? 'alert' : ''}>{x.orderNumber}</a>
                                            </div>
                                            <div class="flex-2">
                                                <div class="elips-1">
                                                    {(() => {
                                                        if (x.invoiceState == 0) {
                                                            return "未開";
                                                        } else if (x.invoiceState == 1) {
                                                            return "預開";
                                                        } else if (x.invoiceState == 2) {
                                                            return "列管";
                                                        } else if (x.invoiceState == 3) {
                                                            return "作廢";
                                                        } else if (x.invoiceState == 4) {
                                                            return "作廢重開";
                                                        } else if (x.invoiceState == 5) {
                                                            return "處理中";
                                                        } else if (x.invoiceState == 6) {
                                                            return "已開";
                                                        } else if (x.invoiceState == 7) {
                                                            return "列管已開";
                                                        } else if (x.invoiceState == 8) {
                                                            return "作廢重開已開";
                                                        } else if (x.invoiceState == 100) {
                                                            return "為預開(未開)";
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                            <div class="flex-1 center">
                                                <div class="elips-1">
                                                    <div class="elips-1">{x.invoiceProject}</div>
                                                </div>
                                            </div>
                                            <div class="flex-1 center">
                                                <div class="elips-1">
                                                    <div class="elips-1">{x.invoiceItem}</div>
                                                </div>
                                            </div>
                                            <div class="flex-2 center">
                                                <div class="elips-1">
                                                    {x.salesType}
                                                </div>
                                            </div>

                                            <div class="flex-2">
                                                <div class="elips-1">{x.customerName}</div>
                                            </div>
                                            <div class="flex-3 right">
                                                <div class="elips-1">{x.currName} {NumFormart(x.amount == null ? 0 : x.amount)}</div>

                                            </div>
                                            <div class="flex-3">
                                                <div class="elips-1">{x.item1 + " " + x.item2 + " " + x.item3 + " " + x.item4 + " " + x.item5}</div>
                                            </div>
                                            <div class="flex-3">
                                                <div class="elips-1">{x.productInvoiceName}</div>
                                            </div>
                                            <div class="flex-4">
                                                <div class="elips-1">{x.productName2}</div>
                                            </div>
                                            <div class="flex-5">
                                                <div class="elips-1">{x.customerTitle}</div>
                                            </div>
                                            <div class="flex-3">
                                                <div class="elips-1">{x.personInChargeName}</div>
                                            </div>
                                            <div className={`flex-4 ${CheckHasIllegalCharactersVoidReason(x.voidReason) ? 'show-help' : ''}`}>
                                                <input type="text" id={`voidReason_${i}`} value={x.voidReason} onChange={e => dataChange(e, i, "voidReason")} />
                                                {CheckHasIllegalCharactersVoidReason(x.voidReason) ? <div className="help-word right">{`禁止輸入*?|`}</div> : ''}
                                                {/*<span className="help-word">{`禁止輸入 \ / : * ? " < > |`}</span>*/}
                                            </div>
                                            {/*<div class="flex-3">*/}
                                            {/*    {x.txnId}*/}
                                            {/*</div>*/}
                                        </li>
                                    )
                            }
                        </ul>
                    </div>

                </div>
                <div className="form-container">
                    <div className="form-item btn-area sp">
                        <div className="left">

                        </div>
                        <div className="right">
                            <a className="btn" onClick={openModalDelete} id="delBtn">
                                <span>作廢</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            <div id="modal-del" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要作廢所選的發票？
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-del")}> <span>取消</span></a>
                            <a className="btn" onClick={del}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-ok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-ok")}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr == null ? '' : HtmlParse(modalStr)}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-order" className="modal large" data-modal>
                <OrderDetail orderId={orderId} setReGet={setReGet} />
            </div>
        </div>
    );
};

export default InvoiceVoidItem;
