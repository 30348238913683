import React from 'react';
import ReactDOM from 'react-dom';
import I18n from "./i18n"; //多國語言
import './index.css';
import reportWebVitals from './reportWebVitals';
import { HashRouter, Route, BrowserRouter, Switch } from "react-router-dom";
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Main from './pages/Main';
import SSOVerify from './pages/SSOVerify';
import UserManage from './pages/UserManage';
import Error2 from './pages/Error2';
import ErrorCommon from './pages/ErrorCommon';
import ErrorInvalid from './pages/ErrorInvalid';
const root = (
    <I18n>
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route path="/Dashboard" component={Dashboard}/>
                <Route path="/Main" component={Main} />
                <Route path="/SSOVerify" component={SSOVerify} />
                <Route path="/UserManage" component={UserManage} />
                <Route path="/error2" component={Error2} />
                <Route path="/ErrorCommon" component={ErrorCommon} /> {/*系統發生錯誤*/}
                <Route path="/ErrorInvalid" component={ErrorInvalid} /> {/*帳號停用*/}
            </Switch>
        </BrowserRouter >
    </I18n>
);

ReactDOM.render(root, document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
