import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { NumFormart } from "../../../global/ToolUtil";

const AccountChargeOffReviewList = ({ qryList, setReqFrmID, qryType }) => {
    var history = useHistory();

    function openModalReview(frmID) {
        setReqFrmID(null);
        const timeout = setTimeout(() => {
            setReqFrmID(frmID);
        }, 300)
        return () => clearTimeout(timeout)
    }
    return (
        <div id={"modal-list-5-" + qryType} className="modal large" data-modal>
            <div className="modal-container">
                <div class="modal-header">
                    <h3 class="fz-B has-border">繳款憑單待審查列表</h3>
                    <span class="close" data-close>
                        <i class="icon icon-44"></i>
                    </span>
                </div>
                <div className="modal-body">
                    <ul className="list-table">
                        <li class="lt-row lt-th">
                            <div class="flex-2">申請人</div>
                            <div class="flex-2">收款日期</div>
                            <div class="flex-3">收款帳號</div>
                            <div class="flex-2 right">沖銷帳本金額</div>
                            <div class="flex-2">沖銷帳本幣別</div>
                            <div class="flex-3">申請日期</div>
                            <div className="flex-2"></div>

                        </li>
                        {
                            qryList.map((x, index) => (
                                <li key={index} className="lt-row">
                                    <div className="flex-2">{x.applicant}</div>
                                    <div className="flex-2">{x.receiveTime}</div>
                                    <div className="flex-3">{x.receipeType}</div>
                                    <div className="flex-2 right">{NumFormart((x.companyId == 0 || x.companyId == 2 ? Math.round(x.baseAmt) : x.baseAmt))}</div>
                                    <div className="flex-2">{x.baseCurr}</div>
                                    <div className="flex-3">{x.applyTime}</div>
                                    <div className="flex-2">
                                        <div className="fn-bar">
                                            <div className="btn btn-small btn-border" onClick={(e) => { openModalReview(x.id) }}>
                                                <span>查看</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AccountChargeOffReviewList;
