import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_POST_CloudAcntList1
} from "../../global/constants";
import { NumFormart, CloseModal, OpenModal, InitJS, MenuSet } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const CloudAcntList2 = () => {
    var history = useHistory();
    const [modalStr, setModalStr] = useState();
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        //把menu顏色置換
        MenuSet("CloudAcntQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    function getData() {
        var productNameStr = queryParams.get("productName");
        var parProductName = productNameStr.split(',');
        var parProductArray = [];
        for (var i = 0; i < parProductName.length; i++) {
            parProductArray.push(parProductName[i]);
        }
        var dt = {
            companyId: parseInt(queryParams.get("companyId")),
            productName1Array: parProductArray,
            customerId: parseInt(queryParams.get("customerId")),
            personInChargeId: parseInt(queryParams.get("personInChargeId")),
        }
        API_POST_CloudAcntList1(dt)
            .then((response) => response.data)
            .then((data) => {
                console.log("雲端帳號List2",data);

                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "3071") {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                    return;
                }

                if (data.code == "0000") {
                    //負責公司
                    setCompanyName(data.result.data.companyName);
                    setCustomerId(data.result.data.customerId);
                    setCustomerName(data.result.data.customerName);
                    setCustomerTitle(data.result.data.customerTitle);
                    if (data.result.data.cloudData != null) {
                        setCloudData(data.result.data.cloudData);
                    }
                }
            })
            .catch((err) => {
                console.log("List2頁面裡用API_POST_CloudAcntList1 catch err", err)
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [companyName, setCompanyName] = useState();
    const [customerId, setCustomerId] = useState();
    const [customerName, setCustomerName] = useState();
    const [customerTitle, setCustomerTitle] = useState();
    const [cloudData, setCloudData] = useState([{}]);
    function goList3(productName1, personInChargeID) {
        var path = '/Main?path=CloudAcntList3&companyId=' + parseInt(queryParams.get("companyId")) + "&productName1=" + productName1 + "&customerId=" + customerId + "&personInChargeId=" + parseInt(personInChargeID);
        history.push(path);
    }

    function goQry() {
        history.push('/Main?path=CloudAcntQry');
    }
    return (
        <>
            <div class="content-body">
                <div class="title-area">
                    <div class="container full">
                        <h3 class="fz-B has-border">隸屬公司：{companyName}</h3>
                    </div>
                </div>
                <div class="container full">
                    <div class="form-container">

                        <div class="form-item half">
                            <div class="field">客戶別</div>
                            <div class="value">
                                <input type="text" value={customerName} disabled="disabled" />
                            </div>
                            <div class="help-word">
                                請選擇負責公司
                            </div>
                        </div>

                        <div class="form-item half">
                            <div class="field">公司抬頭</div>
                            <div class="value">
                                <input type="text" value={customerTitle} disabled="disabled" />
                            </div>
                            <div class="help-word">
                                請選擇負責公司
                            </div>
                        </div>

                    </div>
                    <div class="mt-pool">
                        {
                            cloudData.map(x =>
                                <div class="mt-item row-33">
                                    <div class="mt-header">
                                        <h4>{x.productName1}</h4>
                                    </div>
                                    <div class="mt-body">
                                        <ul>
                                            <li class="field">
                                                <div class="flex-1">序號</div>
                                                <div class="flex-3">帳號 ID</div>
                                                <div class="flex-3">帳號名稱</div>
                                            </li>
                                            {
                                                x.accountArray != null && x.accountArray.length > 0 ?
                                                    x.accountArray.map((x2, i2) =>
                                                        <li>
                                                            <div class="flex-1">{(i2 + 1) < 10 ? '0' + (i2 + 1) : i2 + 1}</div>
                                                            <div class="flex-3">{x2.id}</div>
                                                            <div class="flex-3">{x2.name} </div>
                                                        </li>
                                                    ) : null
                                            }
                                        </ul>
                                    </div>
                                    <div class="mt-footer">
                                        <a class="btn btn-border" onClick={() => goList3(x.productName1, x.personInChargeID)}>
                                            <span>維護</span>
                                        </a>

                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => goQry()}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CloudAcntList2;
