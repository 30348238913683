import React, { useState, useEffect, useRef } from "react";
import HtmlParse from 'html-react-parser';
import { useHistory } from "react-router-dom";
import {
    API_GET_AllCompany,
    API_POST_SettlementData,
    API_GET_SettlementData,
    API_POST_SettlementBilling1,
    API_POST_SettlementBilling1_1,
    API_DEL_SettlementOrderProduct,
    API_POST_SettlementRefine,
    API_GET_HomeData,
    API_GET_SettlementV2_Query,
    API_GET_SettlementV2_FirstStageBillingCheck,
    API_GET_SettlementV2_FirstStageBilling,
    API_GET_SettlementV2_Delete,
    API_GET_SettlementV2_TempSave
} from "../../global/constants";
import { NumFormart, Clone, InitJS, OpenModal, CloseModal, MenuSet } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
import OrderDetail from "../Common/OrderDetail"
import { FaAngleRight } from "react-icons/fa6";

const SettlementList = () => {

    var history = useHistory();
    //console.log(window.location.search);
    const queryParams = new URLSearchParams(window.location.search);
    var sellYM = queryParams.get("sellYM");

    var queryStruct = {
        companyId: parseInt(queryParams.get("companyId")),
        targetYearMonth: queryParams.get("sellYM"),
        showNonCloseOnly: queryParams.get("showNonCloseOnly") == 1 ? true : false,
        customerName: queryParams.get("customerName"),
        customerTitle: queryParams.get("customerTitle "),
        customerTaxId: queryParams.get("customerTaxId"),
        personInChargeID: (queryParams.get("personInChargeID") == null || queryParams.get("personInChargeID") == "") ? null : parseInt(queryParams.get("personInChargeID")),
        productTypeId: queryParams.get("productTypeId") == null ? null : parseInt(queryParams.get("productTypeId")),
        productName1: queryParams.get("productName1")
    };

    const errorRefs = useRef({});


    const [getDataApiResult, setGetDataApiResult] = useState(null);

    const [reGet, setReGet] = useState(false);
    const [okta, setOkta] = useState();

    const [btnDisabled, setBtnDisabled] = useState(true);
    //毛利按鈕
    const [btnAnalysisDisabled, setBtnAnalysisDisabled] = useState(true);

    /**
     * @typedef ListItem
     * @property {number} salesOrderId
     * @property {number} salesOrderProductId
     * @property {string?} customIncomeMonth
     * @property {number?} customInvState
     * @property {number?} customQuantity
     * @property {number?} customUnitPrice
     * // 其他属性...
     */

    /**
    * @type {ListItem[]}
    */
    const [listChangeArray, setListChangeArray] = useState([]);

    const [dataCheckErrorArray, setDataCheckErrorArray] = useState([]);

    const [errorItem, setErrorItem] = useState([]);

    const [isStartBilling, setIsStartBilling] = useState(false);
    const [billCheckResult, setBillCheckResult] = useState(null);
    const [needCheckCount, setNeedCheckCount] = useState(0);
    const [alreadyCheckCount, setAlreadyCheckCount] = useState(0);

    //1=刪除、2=終止

    const [currencyId, setCurrencyId] = useState(null);
    const [exchangeRateArray, setExchangeRateArray] = new useState([]);

    //有更新
    const [hasChange, setHasChange] = useState(false);
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyName, setCompanyName] = useState();
    const [companyId, setCompanyId] = useState();
    //幣別
    const [currencyName, setCurrencyName] = useState();
    const [currencyEname, setCurrencyEname] = useState();
    const [modalStr, setModalStr] = useState("");
    //暫存訂單物件
    const [tmpRefineItem, setTmpRefineItem] = useState(null);
    const [tmpRefineId, setTmpRefineId] = useState();
    const [tmpRefineNumber, setTmpRefineNumber] = useState();

    const [dataList, setDataList] = useState([]);
    //出帳1 List
    const [billingList, setBillingList] = useState([]);

    const [forceBillingMsg, setForceBillingMsg] = useState(null);

    const [isTempSaveContinue, setIsTempSaveContinue] = useState(false);

    const [orderId, setOrderId] = useState();

    //刪除相關
    const [deleteType, setDeleteType] = useState(1);
    const [tmpDelItem, setTmpDelItem] = useState(null);


    function getOkData() {
        var fromOkta_ = (localStorage.getItem("post_logout_redirect_uri") != null ? 1 : 0);
        API_GET_HomeData({ fromOkta: fromOkta_ }).then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setOkta(data.result);
            })
            .catch(err => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function isSpecialOrderType(order) {
        if ((order.salesType == 1 && (order.invoiceState == 0 || order.invoiceState == 2)) ||  //一次型 未開 列管
            (order.salesType == 0 && (order.invoiceState == 0 || order.invoiceState == 2)) ||  //月租型 未開 列管
            order.salesType == 2 //微調型  
        ) {
            return true;
        }

        return false;
    }


    function postBilling() {
        CloseModal("modal-billingcheck1");
        CloseModal("modal-billingcheck2");

        var dataDt = [];
        for (var i = 0; i < dataList.length; i++) {
            dataDt.push({
                orderProductId: dataList[i].orderProductId,
                orderId: dataList[i].orderId,
                settlementState: dataList[i].settlementState,
                fineTuneId: dataList[i].fineTuneId,
                orderNumber: dataList[i].orderNumber,
                invoiceState: dataList[i].invoiceState,
                incomeMonth: dataList[i].incomeMonth
            });
        }
        var dt = {
            companyId: parseInt(queryParams.get("companyId")),
            billingSalesOrder: dataDt,
        };

        //API 3-4
        API_POST_SettlementBilling1_1(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setBillingList(null);
                    Billing1(false);
                }
                //不是0000 代表有訂單中還有必須採購的資料未填寫 
                else if (data.code == "2008") {
                    setBillingList(data.result.data);

                    if (data.result.data.notRevenues != null && data.result.data.notRevenues.length > 0) {
                        OpenModal("modal-billing1");
                    }
                    else if (data.result.data.isRevenues != null && data.result.data.isRevenues.length > 0) {
                        OpenModal("modal-billing1-1");

                    }
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
    }

    //出帳1確認
    function Billing1(force) {

        if (billingList != null && billingList.isRevenues != null && billingList.isRevenues.length > 0) {
            CloseModal("modal-billing1");
            OpenModal("modal-billing1-1");
        }
        else {
            postBilling_1(force);
        }
    }

    function Billing1_1(force) {
        postBilling_1(force);
    }

    function postBilling_1(force) {
        CloseModal("modal-billing-force");
        var dt = [];
        for (var i = 0; i < dataList.length; i++) {
            dt.push({
                orderProductId: dataList[i].orderProductId,
                orderId: dataList[i].orderId,
                settlementState: dataList[i].settlementState,
                fineTuneId: dataList[i].fineTuneId,
                orderNumber: dataList[i].orderNumber,
                invoiceState: dataList[i].invoiceState,
                incomeMonth: dataList[i].incomeMonth
            });
        }

        var submitDt = {
            companyId: parseInt(queryParams.get("companyId")),
            billingSalesOrder: dt
        };

        if (force) {
            submitDt.prodCostNulFlag = 1
        };

        //API 3-5
        API_POST_SettlementBilling1(submitDt)
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                //關閉彈窗
                CloseModal("modal-billing1");
                CloseModal("modal-billing1-1");
                if (data.code == "0000") {
                    //毛利 亮起來
                    setModalStr("出帳1成功");
                    OpenModal("modal-ok");
                    setReGet(true);
                }
                else if (data.code == "3083") {
                    setForceBillingMsg(data.message);
                    OpenModal("modal-billing-force");

                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
    }
    //進毛利分析List
    function goAnalysisList() {
        history.push('/Main?path=SettlementAnalysisList&companyId=' + queryParams.get("companyId") + '&incomeMonth=' + sellYM);
    }


    //資料修改  數量/單價 /發票狀態
    function dataChange(e, index, col) {
        setHasChange(true);

        let tempDataList = [...dataList];

        switch (col) {
            case "unitPrice":
                tempDataList[index].unitPrice = e.target.value.replace(/[^0-9.-]*/g, '');
                tempDataList[index].incomeSubtotal = tempDataList[index].unitPrice * tempDataList[index].quantity;
                break;
            case "quantity":
                tempDataList[index].quantity = e.target.value.replace(/[^0-9.]*/g, '');
                tempDataList[index].incomeSubtotal = tempDataList[index].unitPrice * tempDataList[index].quantity;
                break;
            case "invoiceState":
                let thisOrderProductId = tempDataList[index].orderProductId;
                let thisOrderId = tempDataList[index].orderId;

                tempDataList.forEach((item) => {
                    if (item.orderProductId == thisOrderProductId && item.orderId == thisOrderId) {
                        item.invoiceState = parseInt(e.target.value);
                    }
                })
                break;
            case "incomeMonth":
                tempDataList[index].incomeMonth = e.target.value;
                break;
        }

        setDataList(tempDataList);
    }


    function invStateMapping(invState) {
        switch (invState) {
            case 0: return "未開";
            case 1: return "預開";
            case 2: return "列管";
            case 3: return "作廢";
            case 4: return "作廢重開";
            case 6: return "已開";
            case 7: return "列管已開";
            default: return "未定義(" + invState + ")";

        }
    }



    function GetSubTotal(item) {
        let rate = GetRate(item.currencyId, item.incomeMonth);
        return NumFormart(item.unitPrice * item.quantity * rate, 2);
    }

    function GetRate(currId, incomeMonth) {
        if (currId == currencyId)
            return 1;

        var incomeMonth = new Date(incomeMonth);
        var exchangeRateItemArray = exchangeRateArray.filter(item => item.exCurrID === currId);
        exchangeRateItemArray.sort(function (lt, rt) {
            return new Date(rt.exRateYM) - new Date(lt.exRateYM);
        })

        for (var i = 0; i < exchangeRateItemArray.length; i++) {
            let exchangeRateItem = exchangeRateItemArray[i];
            var exRateYM = new Date(exchangeRateItem.exRateYM);
            if (exRateYM <= incomeMonth) {
                return exchangeRateItem.exRateRate;
            }
        }

        return null;
    }

    const handleInit = () => {
        setListChangeArray([]);
        setDataCheckErrorArray([]);
        setErrorItem([]);
        setIsStartBilling(false);
        setBillCheckResult(null)
        setNeedCheckCount(0);
        setAlreadyCheckCount(0);
        setDeleteType(1);
        setTmpDelItem(null);
        setTmpRefineItem(null);
    }


    const handleQuery = () => {
        handleInit();

        API_GET_SettlementV2_Query(queryStruct)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setGetDataApiResult(handelDataAfterHandle(data.result));
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    const handelDataAfterHandle = (data) => {
        data.salesOrderProducts = data.salesOrderProducts.map(product => {
            return { ...product, isOpen: false };
        });

        return data;
    }

    const handleFirstStageBillingCheck = () => {
        var datas = getDataApiResult.salesOrderProducts.map(x => {
            return {
                salesOrderProductId: x.salesOrderProductId,
                salesOrderNumber: x.orderNumber
            }
        })
        var dt = {
            companyId: queryStruct.companyId,
            billingSalesOrderProducts: datas,
            billingYearMonth: queryStruct.targetYearMonth
        };

        API_GET_SettlementV2_FirstStageBillingCheck(dt)
            .then((response) => response.data)
            .then((data) => {
                CloseModal('modal-billingcheck1');
                CloseModal('modal-billingcheck2');
                if (!CheckResponseCode(data)) { return; }
                if (data.code == "0000") {
                    handleFirstStageBilling();
                }
                //不是0000 代表有訂單中還有必須採購的資料未填寫 
                else if (data.code == "0002") {
                    setBillCheckResult(data.result);
                    setIsStartBilling(true);
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
    }

    const handleFirstStageBilling = () => {

        var datas = getDataApiResult.salesOrderProducts.map(x => {
            var data = {
                salesOrderProductId: x.salesOrderProductId,
                salesOrderNumber: x.orderNumber
            };
            if (listChangeArray != null) {
                var filterEmp = listChangeArray.find(emp => emp.salesOrderId == x.orderId && emp.salesOrderProductId == x.salesOrderProductId);
                if (filterEmp != null) {
                    data.customIncomeMonth = filterEmp.customIncomeMonth;
                    data.customInvState = filterEmp.customInvState;
                    data.customQuantity = filterEmp.customQuantity;
                    data.customUnitPrice = filterEmp.customUnitPrice;
                }
            }
            return data;

        })

        var dt = {
            companyId: queryStruct.companyId,
            billingSalesOrderProducts: datas,
            billingYearMonth: queryStruct.targetYearMonth
        };

        //API 3-4
        API_GET_SettlementV2_FirstStageBilling(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; }

                if (data.code == "0000") {
                    setModalStr("出帳1成功");
                    OpenModal("modal-ok");
                    setReGet(true);
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
    }

    const handleDataOpen = (salesOrderProductId, orderId, forceAction = null) => {
        var newData = { ...getDataApiResult };
        var newProduct = newData.salesOrderProducts.map(product =>
            product.salesOrderProductId == salesOrderProductId && product.orderId == orderId
                ? { ...product, isOpen: forceAction == null ? !product.isOpen : forceAction }
                : product
        );

        setGetDataApiResult({ ...newData, salesOrderProducts: newProduct });
    }

    const handleDataChange = (salesOrderProductId, orderId, column, value) => {
        var newData = { ...getDataApiResult };
        var newProduct = newData.salesOrderProducts.map(product =>
            product.salesOrderProductId == salesOrderProductId && product.orderId == orderId
                ? { ...product, [column]: value }
                : product
        );

        setGetDataApiResult({ ...newData, salesOrderProducts: newProduct });
    }

    const handleListDataChange = (event, item) => {
        const { name, value } = event.target;

        var newItem = {
            salesOrderId: item.orderId,
            salesOrderProductId: item.salesOrderProductId
        }


        switch (name) {
            case "invoiceState":
                newItem.customInvState = value;
                handleDataChange(item.salesOrderProductId, item.orderId, "invoiceState", value);
                break;
            case "incomeMonth":
                newItem.customIncomeMonth = value;
                handleDataChange(item.salesOrderProductId, item.orderId, "incomeMonth", value);
                break;
            case "quantity":
                newItem.customQuantity = value.replace(/[^0-9.]*/g, '');
                handleDataChange(item.salesOrderProductId, item.orderId, "quantity", newItem.customQuantity);
                break;
            case "unitPrice":
                newItem.customUnitPrice = value.replace(/[^0-9.-]*/g, '');
                handleDataChange(item.salesOrderProductId, item.orderId, "unitPrice", newItem.customUnitPrice);
                break;

        }

        handleAddNewItem(newItem);
    }

    const handleCheckData = () => {
        let errorArray = [];
        let errorItem = [];


        getDataApiResult.salesOrderProducts.map((item, i) => {
            let fail = false;
            if (item.quantity == null || item.quantity == '') {
                errorArray.push({
                    orderId: item.orderId,
                    orderProductId: item.orderProductId,
                    column: "quantity"
                });

                fail = true;

            }

            if (item.unitPrice == null || item.unitPrice == '') {
                errorArray.push({
                    orderId: item.orderId,
                    orderProductId: item.orderProductId,
                    column: "unitPrice"
                });

                fail = true;
            }

            if (fail) {
                errorItem.push(item);
            }

        })

        setErrorItem(errorItem);
        setDataCheckErrorArray(errorArray);

        return errorItem.length == 0;
    }

    const handleTmpSave = () => {
        if (!handleCheckData())
            return;

        var datas = getDataApiResult.salesOrderProducts.map(x => {
            var data = {
                orderProductId: x.salesOrderProductId,
                orderId: x.orderId
            };

            if (listChangeArray != null) {
                var filterEmp = listChangeArray.find(emp => emp.salesOrderId == x.orderId && emp.salesOrderProductId == x.salesOrderProductId);
                if (filterEmp != null) {
                    data.incomeYM = filterEmp.customIncomeMonth;
                    data.invoiceState = filterEmp.customInvState;
                    data.quantity = filterEmp.customQuantity ? parseInt(filterEmp.customQuantity) : null;
                    data.unitPrice = filterEmp.customUnitPrice ? Math.round(parseFloat(filterEmp.customUnitPrice) * 100) / 100 : null;
                }
            }
            return data;
        })

        var dt = {
            companyId: queryStruct.companyId,
            cacheData: datas,
            targetYearMonth: queryStruct.targetYearMonth
        }
        //console.log(JSON.stringify(dt));
        API_GET_SettlementV2_TempSave(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //成功
                    setModalStr("暫存成功");
                    OpenModal("modal-ok");
                    setReGet(true);
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
    }


    const handleOpenModalBillingCheck = () => {
        setNeedCheckCount(0);
        setAlreadyCheckCount(0);

        if (!handleCheckData())
            return;
        if (listChangeArray != null && listChangeArray.length > 0) {
            //改過資料 開先儲存在送
            OpenModal("modal-billingcheck1");
        } else {
            OpenModal("modal-billingcheck2");
        }
    }

    const handelDataErrorMark = (orderId, orderProductId, column) => {
        return dataCheckErrorArray.find(x => x.orderId == orderId && x.orderProductId == orderProductId && x.column == column);
    }

    const handleAddNewItem = (newItem) => {
        setListChangeArray(prevItems => {
            // 检查是否已存在具有相同 salesOrderId 和 salesOrderProductId 的项
            const existingItemIndex = prevItems.findIndex(item =>
                item.salesOrderId === newItem.salesOrderId &&
                item.salesOrderProductId === newItem.salesOrderProductId
            );

            // 如果找到了匹配的项，更新它
            if (existingItemIndex !== -1) {
                const updatedItems = [...prevItems];
                updatedItems[existingItemIndex] = {
                    ...updatedItems[existingItemIndex],
                    customIncomeMonth: newItem.customIncomeMonth ? newItem.customIncomeMonth : updatedItems[existingItemIndex].customIncomeMonth,
                    customInvState: newItem.customInvState ? newItem.customInvState : updatedItems[existingItemIndex].customInvState,
                    customQuantity: newItem.customQuantity ? newItem.customQuantity : updatedItems[existingItemIndex].customQuantity,
                    customUnitPrice: newItem.customUnitPrice ? newItem.customUnitPrice : updatedItems[existingItemIndex].customUnitPrice,
                };
                return updatedItems;
            }


            // 如果不存在，添加新项并返回新数组
            return [...prevItems, newItem];
        });
    };

    //計算總計，type=0為帳本幣，type=1為產品幣
    const handleCalcCurrencyTotal = (item, type) => {
        let rate = item.salesOrderProductCurrency.rate;
        let column = "companyCurrencyTotal"
        if (type == 1) {
            rate = 1;
            column = "productCurrencyTotal";
        }

        const existingItemIndex = listChangeArray.findIndex(prev =>
            prev.salesOrderId === item.salesOrderId &&
            prev.salesOrderProductId === item.salesOrderProductId
        );

        let qty = item.quantity;
        let price = item.unitPrice;
        let total = NumFormart(qty * price * rate, 2);

        if (existingItemIndex !== -1) {
            if (listChangeArray[existingItemIndex].customQuantity) {
                qty = listChangeArray[existingItemIndex].customQuantity
            }

            if (listChangeArray[existingItemIndex].customQuantity) {
                price = listChangeArray[existingItemIndex].customUnitPrice
            }

            total = NumFormart(qty * price * rate, 2);
            handleDataChange(item.salesOrderProductId, item.salesOrderId, column, total);
        }

        return total;

    }

    const handleBillCheckStagePass = (name) => {

        switch (name) {
            case "notSetProductCostProductFullName":
                CloseModal("modal-billing-force");
                break;
            case "notRevenues":
                CloseModal("modal-billing-check-result-notRevenues");
                break;
            case "isRevenues":
                CloseModal("modal-billing-check-result-isRevenues");
                break;
        }

        setAlreadyCheckCount(alreadyCheckCount + 1);
    }

    const handleOpenModelDelStage1 = (item, type) => {
        setTmpDelItem(item);
        setDeleteType(type);
        OpenModal("modal-del");
    }

    const handleOpenModelDelStage2 = () => {
        CloseModal("modal-del");
        OpenModal("modal-del2");
    }

    const handleOpenModelDelStage3 = () => {
        CloseModal("modal-del2");
        OpenModal("modal-del3");
    }

    const handleDeleteData = (type) => {
        if (!tmpDelItem)
            return;

        API_GET_SettlementV2_Delete({
            salesOrderProductId: tmpDelItem.salesOrderProductId,
            salesOrderNumber: tmpDelItem.orderNumber,
            incomeMonth: tmpDelItem.incomeMonth
        })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setModalStr(`${type == 1 ? "刪除" : "終止"}成功`);
                    OpenModal("modal-ok");

                    setReGet(true);
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
    }

    //打開訂單微調modal
    const handleOpenModalRefine = (item) => {
        setTmpRefineItem(item);
        OpenModal("modal-refine");
    }

    //執行訂單微調
    const handleRefine = () => {
        CloseModal("modal-refine");

        if (!tmpRefineItem)
            return;

        var dt = {
            orderProductId: tmpRefineItem.salesOrderProductId,
            orderNumber: tmpRefineItem.orderNumber,
            incomeMonth: tmpRefineItem.incomeMonth,
        };

        API_POST_SettlementRefine(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setModalStr("微調成功");
                    OpenModal("modal-ok");

                    setReGet(true);
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
    }

    const handleGenerateListBtn = (item) => {
        if (item.orderNumber.includes("-複製")) {
            return (
                <div className="btn btn-icon" onClick={e => handleOpenModelDelStage1(item, 1)}>
                    <i className="icon icon-37"></i>
                </div>
            )
        }

        if (item.salesType == 1) {
            return (
                <div className="btn btn-icon" onClick={e => handleOpenModalRefine(item)}>
                    <i className="icon icon-29"></i>
                </div>
            )
        }

        if (item.salesType == 0) {
            return (
                <>
                    <div className="btn btn-icon" onClick={e => handleOpenModalRefine(item)}>
                        <i className="icon icon-29"></i>
                    </div>
                    {
                        okta && okta.isMonth2 ? (okta.isMonth2 == 0 ? '' : <div className="btn btn-icon" onClick={e => handleOpenModelDelStage1(item, 2)}>
                            <i className="icon icon-35"></i>
                        </div>) : ''
                    }

                </>
            );
        }

        if (item.salesType == 2) {
            return (
                okta && okta.isMonth2 ? (okta.isMonth2 == 0 ? '' :
                    <div className="btn btn-icon" onClick={e => handleOpenModelDelStage1(item, 2)}>
                        <i className="icon icon-35"></i>
                    </div>) : ''
            );
        }

        return null;

    }

    useEffect(() => {
        //把menu顏色置換
        MenuSet("SettlementQry");
        getOkData();
        handleQuery();
        setCompanyId(queryStruct.companyId);
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);


    useEffect(() => {
        if (reGet) {
            //內頁資料有刪除/修改 重抓
            handleQuery();
        }
        setReGet(false);
    }, [reGet]);

    useEffect(() => {
        let errorKey = null;

        if (errorItem != null && errorItem.length > 0) {
            errorItem.forEach(element => {
                handleDataOpen(element.salesOrderProductId, element.orderId, true);
                if (errorKey == null) {
                    errorKey = `${element.salesOrderProductId}_${element.orderId}`;
                }
            });

            if (errorKey && errorRefs.current[errorKey]) {
                errorRefs.current[errorKey].scrollIntoView({ behavior: 'smooth', block: 'start' });
            }

            setErrorItem(null);
        }
    }, [errorItem])

    useEffect(() => {
        if (billingList != null) {
            console.log("出帳1訊息", billingList);
        }
    }, [billingList]);

    useEffect(() => {
        if (isTempSaveContinue) {
            postBilling();
            setIsTempSaveContinue(false);
        }
    }, [isTempSaveContinue])

    useEffect(() => {
        if (!getDataApiResult)
            return;

        console.log("API資料", getDataApiResult);

        if (getDataApiResult.monthlyState >= 1) {
            setBtnAnalysisDisabled(false);
        }
        else {
            setBtnAnalysisDisabled(true);
        }

        setBtnDisabled(false);

    }, [getDataApiResult])

    useEffect(() => {
        console.table(listChangeArray);
    }, [listChangeArray])

    useEffect(() => {
        if (billCheckResult != null) {
            let checkCount = 0;
            if (billCheckResult.notSetProductCostProductFullName.length > 0) {
                setForceBillingMsg(`產品細項[${billCheckResult.notSetProductCostProductFullName.join("、")}}]<br>尚未設定產品成本，確定要出帳？`)
                OpenModal("modal-billing-force");
                checkCount += 1;
            }
            if (billCheckResult.notRevenues.length > 0) {
                OpenModal("modal-billing-check-result-notRevenues");
                checkCount += 1;
            }
            if (billCheckResult.isRevenues.length > 0) {
                OpenModal("modal-billing-check-result-isRevenues");
                checkCount += 1;
            }

            setNeedCheckCount(checkCount);

        }
    }, [billCheckResult])


    useEffect(() => {
        console.log(needCheckCount, alreadyCheckCount);
        if (needCheckCount <= alreadyCheckCount && isStartBilling) {
            console.log("執行出帳1");
            handleFirstStageBilling();

            setNeedCheckCount(0);
            setAlreadyCheckCount(0);

            setIsStartBilling(false);
        }
    }, [alreadyCheckCount, isStartBilling])


    return (
        <div className="content-body">
            <div className="title-area">
                {
                    getDataApiResult == null ? null :
                        <div className="container full">

                            <h3 className="fz-B has-border" style={{ width: "60%" }}>
                                月結作業：{getDataApiResult.company == null ? null : getDataApiResult.company.cmpName}
                            </h3>
                            <div className="flex" style={{ width: "20%", }}>
                                <div className="row" style={{ fontSize: "18px", fontWeight: "500" }}>
                                    未開總金額 : {getDataApiResult.companyCurrency == null ? null : getDataApiResult.companyCurrency.currEName}
                                    {getDataApiResult.notIssueSummaryAmt.toLocaleString({ style: 'currency', currency: 'USD' })}
                                </div>
                                <div className="row" style={{ fontSize: "14px" }}>[未開]的營收小計加總</div>
                            </div>
                            <div className="flex" style={{ width: "20%" }}>
                                <div className="row" style={{ fontSize: "18px", fontWeight: "500" }}>
                                    已開總金額 : {getDataApiResult.companyCurrency == null ? null : getDataApiResult.companyCurrency.currEName}
                                    {getDataApiResult.issuedSummaryAmt.toLocaleString({ style: 'currency', currency: 'USD' })}
                                </div>
                                <div className="row" style={{ fontSize: "14px" }}>[已開]和[列管已開]的營收小計加總</div>
                            </div>
                        </div>
                }

            </div>
            <div className="container full">
                <div className="info-holder">
                    <div className="left"></div>
                    <div className="right">幣別：{getDataApiResult == null || getDataApiResult.companyCurrency == null ? null : getDataApiResult.companyCurrency.currName}</div>
                </div>
                <ul className="list-table">
                    <li className="lt-row lt-th">
                        <div className="flex-1"></div>
                        <div className="flex-4">訂單編號</div>
                        <div className="flex-2">發票狀態</div>
                        <div className="flex-2">銷售類型</div>
                        <div className="flex-2">客戶別</div>
                        <div className="flex-3">產品細項</div>
                        <div className="flex-2">營收小計</div>
                        <div className="flex-3">說明</div>
                        <div className="flex-2 fn jcfe min-3-btn"></div>
                    </li>
                    {
                        getDataApiResult == null || getDataApiResult.salesOrderProducts == null || getDataApiResult.salesOrderProducts.length == 0 ? null :
                            getDataApiResult.salesOrderProducts.map((x, i) =>
                                <>
                                    <li className={`lt-row ${x.isOpen == true ? 'highlight' : ''}`} ref={el => errorRefs.current[`${x.salesOrderProductId}_${x.orderId}`] = el}>
                                        <div className="flex-1">
                                            {x.settlementState >= 1 ? <i class="icon icon-21 forest-green small-mg"></i> : null}
                                        </div>
                                        <div className="flex-4">
                                            <a href={`/Main?path=SaleOrderEdit&orderId=${x.orderId}`} target="_blank">{x.orderNumber}</a>
                                            <div>
                                                {x.invoiceState == 2 ? <i className="alert icon icon41"></i> : null}
                                                {x.needProcurement == 1 && x.isProcured == 0 ? <i className="alert icon icon22"></i> : null}
                                            </div>

                                        </div>
                                        <div className="flex-2">
                                            {
                                                (x.invoiceState == 3 || x.invoiceState == 8) ? (x.invoiceState == 3 ? '作廢' : '作廢重開已開') :
                                                    <select name="invoiceState" value={x.invoiceState} className="no-border" onChange={(e) => handleListDataChange(e, x)}>
                                                        {
                                                            x.invStateAvailableChanges != null ? x.invStateAvailableChanges.map(x2 =>
                                                                <option key={x2.code} value={x2.code}>{x2.name}</option>
                                                            )
                                                                : null
                                                        }
                                                    </select>
                                            }

                                        </div>
                                        <div className="flex-2">
                                            <div className="elips-1">{x.salesTypeStr}</div>
                                        </div>
                                        <div className="flex-2">
                                            <div className="elips-1">{x.customerName}</div>
                                        </div>
                                        <div className="flex-3">
                                            <div className="elips-1">{x.productFullName}</div>
                                        </div>
                                        <div className="flex-2">
                                            <div className="elips-1">{handleCalcCurrencyTotal(x, 0)}</div>
                                        </div>
                                        <div className="flex-3">
                                            <div className="elips-1">{x.explanation}</div>
                                        </div>

                                        <div className="flex-2 fn jcfe min-3-btn">
                                            <div className="fn-bar">
                                                {handleGenerateListBtn(x)}

                                                <div className={`btn btn-icon ${x.isOpen == true ? 'rotate' : ''}`} onClick={() => handleDataOpen(x.salesOrderProductId, x.orderId)}>
                                                    <i className="icon icon-28"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </li>

                                    <li className={`lt-row ${x.isOpen == true ? '' : 'hide'}`}>
                                        <ul className="list-table nested">
                                            <li className="lt-row lt-th">
                                                <div className="flex-2">營收月份</div>
                                                <div className="flex-2">經銷類別</div>
                                                <div className="flex-4">公司抬頭</div>
                                                <div className="flex-2">統編/ 編號</div>
                                                <div className="flex-3">產品別</div>
                                                <div className="flex-3 right">數量</div>
                                                <div className="flex-3 right">單價</div>
                                                <div className="flex-3 right">小計</div>
                                                <div className="flex-2 center">是否請購</div>
                                            </li>
                                            <li className="lt-row">
                                                <div className="flex-2">
                                                    {(() => {
                                                        if (isSpecialOrderType(x)) {
                                                            return (
                                                                <select name="incomeMonth" value={x.incomeMonth} onChange={(e) => handleListDataChange(e, x)}>
                                                                    {
                                                                        x.incomeMonthAvailableChanges.map(x2 =>
                                                                            <option key={x2} value={x2}>{x2}</option>
                                                                        )
                                                                    }
                                                                </select>
                                                            );
                                                        } else {
                                                            return x.incomeMonth;
                                                        }
                                                    })()}
                                                </div>
                                                <div className="flex-2">
                                                    <div className="elips-1">{x.dealerType == 1 ? '是' : '否'}</div>
                                                </div>
                                                <div className="flex-4">
                                                    <div className="elips-1">{x.customerTitle}</div>
                                                </div>
                                                <div className="flex-2">
                                                    <div className="elips-1">{x.customerTaxId}</div>
                                                </div>
                                                <div className="flex-3">
                                                    <div className="elips-1"> {x.productTypeName}</div>
                                                </div>
                                                <div className="flex-3 right">
                                                    {(() => {
                                                        if (isSpecialOrderType(x)) {
                                                            return <input type="text"
                                                                className={handelDataErrorMark(x.orderId, x.orderProductId, "quantity") ? "error" : null}
                                                                name="quantity"
                                                                value={NumFormart(x.quantity)}
                                                                onChange={(e) => handleListDataChange(e, x)}
                                                                onFocus={(e) => e.target.value = (e.target.value).replace(/,/g, '')}
                                                                onBlur={(e) => e.target.value = NumFormart(e.target.value, 0)}
                                                            />
                                                        } else {
                                                            return NumFormart(x.quantity)
                                                        }
                                                    })()}
                                                </div>
                                                <div className="flex-3 right">
                                                    {(() => {
                                                        if (isSpecialOrderType(x)) {
                                                            return <input type="text"
                                                                className={handelDataErrorMark(x.orderId, x.orderProductId, "unitPrice") ? "error" : null}
                                                                name="unitPrice"
                                                                value={NumFormart(x.unitPrice)}
                                                                onChange={(e) => handleListDataChange(e, x)}
                                                                onFocus={(e) => e.target.value = (e.target.value).replace(/,/g, '')}
                                                                onBlur={(e) => e.target.value = NumFormart(e.target.value, 2)} />
                                                        } else {
                                                            return NumFormart(x.unitPrice)
                                                        }
                                                    })()}

                                                </div>
                                                <div className="flex-3 right">
                                                    {(() => {
                                                        let value = `${x.salesOrderProductCurrency.currEName} ${handleCalcCurrencyTotal(x, 1)}`;
                                                        if (isSpecialOrderType(x)) {
                                                            return <input type="text" disabled={'disabled'} value={value} />
                                                        } else {
                                                            return `${value}`
                                                        }
                                                    })()}

                                                </div>
                                                <div className="flex-2 center">
                                                    {
                                                        x.needProcurement ? "是" : "否"
                                                    }

                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </>

                            )
                    }
                </ul>

                <div className="form-container">
                    <div className="form-item btn-area sp">
                        <div className="left">
                            <a className={`btn btn-border ${btnDisabled ? 'disabled' : ''}`} onClick={handleTmpSave}>
                                <span>暫存</span>
                            </a>
                        </div>
                        <div className="right">
                            <a className={`btn ${btnAnalysisDisabled ? 'disabled' : ''}`} onClick={btnAnalysisDisabled ? null : goAnalysisList}>
                                <span>毛利分析</span>
                            </a>
                            <a className={`btn  ${btnDisabled ? 'disabled' : ''}`} onClick={btnDisabled ? null : handleOpenModalBillingCheck}>
                                <span>出帳 1 確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-del" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要{deleteType == 1 ? "刪除" : "終止"}這筆訂單?<br />
                            按下確定後會{deleteType == 1 ? "刪除" : "終止"}訂單唷 !
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" data-close>

                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={handleOpenModelDelStage2}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-del2" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            真的會直接{deleteType == 1 ? "刪除" : "終止"}訂單唷?
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" data-close>

                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={handleOpenModelDelStage3}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-del3" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            再次確認，真的會直接{deleteType == 1 ? "刪除" : "終止"}訂單唷?
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" data-close>

                                <span>取消</span>
                            </a>
                            <a className="btn" data-close onClick={() => handleDeleteData(deleteType)}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-ok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-ok")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => {
                                CloseModal("modal-error")
                            }}>

                                <span>確認</span>
                            </a>

                        </div>
                    </div>
                </div>
            </div>


            <div id="modal-billingcheck1" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            出帳時發現您有更改資料<br />
                            請問是否儲存本次更動並且執行「出帳1」？<br />
                            (只會出帳符合搜尋條件的本月訂單)

                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-billingcheck1")}> <span>取消</span></a>
                            <a className="btn" onClick={handleFirstStageBillingCheck}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-billingcheck2" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要執行「出帳1」？<br />
                            (只會出帳符合搜尋條件的本月訂單)

                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-billingcheck2")}> <span>取消</span></a>
                            <a className="btn" onClick={handleFirstStageBillingCheck}> <span>確定</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-billing-force" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{forceBillingMsg == null ? '' : HtmlParse(forceBillingMsg)}</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-billing-force")}> <span>取消</span></a>
                            <a className="btn" onClick={() => handleBillCheckStagePass("notSetProductCostProductFullName")}> <span>確定</span></a>
                        </div>
                    </div>
                </div>
            </div>


            <div id="modal-billing-check-result-notRevenues" className="modal small alert" data-modal data-mid="3-5">
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            以下為「需請購」且「尚未完成採購」之訂單，<br />
                            不允許入營收，系統將自動轉換發票狀態。<br />
                            請確認是否繼續出帳？

                            <div className="small-table">
                                <ul>
                                    {
                                        billCheckResult != null && billCheckResult.notRevenues != null ? billCheckResult.notRevenues.map((x, i) =>
                                            <li>
                                                <div className="flex-1">{i < 10 ? '0' + (i + 1) : (i + 1)}</div>
                                                <div className="flex-3">
                                                    <a href={`/Main?path=SaleOrderEdit&orderId=${x.salesOrderId}`} target="_blank">{x.salesOrderNumber}</a>
                                                </div>
                                                <div className="flex-2" style={{ textAlign: "right" }}>
                                                    {`${invStateMapping(x.sourceInvState)}`}
                                                </div>
                                                <div className="flex-1">
                                                    <FaAngleRight />
                                                </div>
                                                <div className="flex-2">
                                                    {`${invStateMapping(x.newInvState)}`}
                                                </div>
                                                <div className="flex-4"></div>
                                            </li>
                                        ) : null
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" data-close> <span>取消</span></a>
                            <a className="btn" onClick={() => handleBillCheckStagePass("notRevenues")}> <span>確定</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-billing-check-result-isRevenues" className="modal small alert" data-modal data-mid="3-5">
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            以下為為過去未列入營收之「預開訂單」，由於已<br />
                            經入營收，系統將自動轉換發票狀態。<br />
                            請確認是否繼續出帳？
                            <div className="small-table">
                                <ul>
                                    {
                                        billCheckResult != null && billCheckResult.isRevenues != null ? billCheckResult.isRevenues.map((x, i) =>

                                            <li>
                                                <div className="flex-1">{i < 10 ? '0' + (i + 1) : (i + 1)}</div>
                                                <div className="flex-3">
                                                    <a href={`/Main?path=SaleOrderEdit&orderId=${x.salesOrderId}`} target="_blank">{x.salesOrderNumber}</a>
                                                </div>
                                                <div className="flex-2" style={{ textAlign: "right" }}>
                                                    {`${invStateMapping(x.sourceInvState)}`}
                                                </div>
                                                <div className="flex-1" style={{marginTop:"1px"}}>
                                                    <FaAngleRight />
                                                </div>
                                                <div className="flex-2">
                                                    {`${invStateMapping(x.newInvState)}`}
                                                </div>
                                                <div className="flex-4"></div>
                                            </li>
                                        ) : null
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" data-close> <span>取消</span></a>
                            <a className="btn" onClick={() => handleBillCheckStagePass("isRevenues")}> <span>確定</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-refine" className="modal small alert" data-modal data-mid="3-7">
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            是否進行{tmpRefineNumber}訂單微調？
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" data-close> <span>取消</span></a>
                            <a className="btn" onClick={handleRefine}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-order" className="modal large" data-modal>
                <OrderDetail orderId={orderId} setReGet={setReGet} />
            </div>

        </div>
    );
};

export default SettlementList;
