import React, { useState, useEffect } from "react";
import {
    API_GET_AccountChargeOffHome,
    API_GET_Customer3Name,
    API_GET_AdNameList,
    API_GET_AccountChargeOffDataList2

} from "../../global/constants";
import { OpenModal, CloseModal, InitJS, MenuSet } from "../../global/ToolUtil";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
const AccChargeQry = () => {
    var history = useHistory();

    const [adID, setAdID] = useState();

    useEffect(() => {
        //把menu顏色置換
        MenuSet("AccChargeQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    function getData() {
        API_GET_AccountChargeOffHome({})
            .then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setCompanyArray(data.result.data.companyArray);
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }
    const [companyIdError, setCompanyIdError] = useState(false);
    const [companyId, setCompanyId] = useState();
    const [companyArray, setCompanyArray] = useState([{}]);

    const [custNameArray, setCustNameArray] = useState([{}]);
    const [custName, setCustName] = useState();
    function custNameChange(e) {
        if (e.target.value == null || e.target.value == "") {
            setCustName("");
            setCustTitle("");
            setCustTaxID("");
        } else {
            setCustName(e.target.value);
            getCustomer3Name(1);
        }

    }

    const [custTitleArray, setCustTitleArray] = useState([{}]);
    const [custTitle, setCustTitle] = useState();
    function custTitleChange(e) {
        if (e.target.value == null || e.target.value == "") {
            setCustName("");
            setCustTitle("");
            setCustTaxID("");
        } else {
            setCustTitle(e.target.value);
            getCustomer3Name(2);
        }

    }

    const [custTaxArray, setCustTaxArray] = useState([{}]);
    const [custTaxID, setCustTaxID] = useState();
    function custTaxIDChange(e) {
        if (e.target.value == null || e.target.value == "") {
            setCustName("");
            setCustTitle("");
            setCustTaxID("");
        } else {
            setCustTaxID(e.target.value);
            getCustomer3Name(3);
        }

    }
    const [adNameArray, setADNameArray] = useState([{}]);
    const [adName, setAdName] = useState();
    function adNameChange(e) {
        if (e.target.value == "") {
            setAdName("")
            setAdID(null);
        } else {
            setAdName(e.target.value);
            getADName();
        }

    }

    //重新取得 客戶別 客戶別Title 客戶統編 List
    function getCustomer3Name(index) {
        var dt = {};
        if (index == 1) {
            dt = { customerName: document.getElementById("custNameText").value };
        } else if (index == 2) {
            dt = {
                customerTitle: document.getElementById("custTitleText").value,
            };
        } else if (index == 3) {
            dt = {
                customerTaxId: document.getElementById("custTaxIdText").value,
            };
        }
        API_GET_Customer3Name(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                var dataName = [];
                var dataTitle = [];
                var dataTaxId = [];

                if (data.result != null && data.result.data != null) {
                    if (index == 1) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataName.length; j++) {
                                if (dataName[j].custName == data.result.data[i].custName) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataName.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                    personADName: data.result.data[i].personADName,
                                    personADID: data.result.data[i].personADID,
                                });
                            }
                        }
                        //console.log(dataName);
                        setCustNameArray(dataName);
                    } else if (index == 2) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTitle.length; j++) {
                                if (dataTitle[j].custTitle == data.result.data[i].custTitle) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTitle.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                    personADName: data.result.data[i].personADName,
                                    personADID: data.result.data[i].personADID,
                                });
                            }
                        }
                        setCustTitleArray(dataTitle);
                    } else if (index == 3) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTaxId.length; j++) {
                                if (dataTaxId[j].custTaxID == data.result.data[i].custTaxID) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTaxId.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                    personADName: data.result.data[i].personADName,
                                    personADID: data.result.data[i].personADID,
                                });
                            }
                        }
                        setCustTaxArray(dataTaxId);
                    }
                }
            })

    }
    function getADName() {
        if (document.getElementById('adNameText').value == '') {
            return false;
        }

        API_GET_AdNameList({
            input: document.getElementById('adNameText').value,
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push('/');
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setADNameArray(data.result.data);
                }
            })
            .catch(err => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push('/');
                }
            })
    }

    const [dataList, setDataList] = useState([{}]);
    function openModalQry() {
        if (companyId == null) {
            setCompanyIdError(true);
            return false;
        } else {
            setCompanyIdError(false);
        }

        if (
            (custName == "" || custName == undefined) &&
            (custTitle == "" || custTitle == undefined) &&
            (adName == "" || adName == undefined) &&
            (custTaxID == "" || custTaxID == undefined)
        ) {
            history.push(`/Main?path=AccChargeList&companyId=${companyId}`)
        }
        var dt = {
            companyId: companyId,
            customerName: custName,
            customerTitle: custTitle,
            customerTaxId: custTaxID,
            //personInChargeName: adName
            personInChargeID: adID

        }

        API_GET_AccountChargeOffDataList2(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setDataList(data.result.data);
                    OpenModal("modal-1");
                }
            })

    }
    return (
        <>
            <div className="content-body">
                <div className="title-area">
                    <div className="container narrow">
                        <h3 className="fz-B has-border">帳務沖銷</h3>
                    </div>
                </div>
                <div className="container narrow">
                    <div className="form-container">
                        <div className={`form-item must ${companyIdError ? 'show-help' : ''}`} >
                            <div className="field">沖銷公司</div>
                            <div className="value">
                                {
                                    companyArray.map((x, index) =>
                                        <div key={index}>
                                            <input type="radio" id={`company_${x.id}`} name="radio1" checked={companyId == x.id ? true : false} onClick={() => setCompanyId(x.id)} />
                                            <label htmlFor={`company_${x.id}`}>{x.name}</label>
                                        </div>
                                    )
                                }
                            </div>
                            <span className="help-word">
                                請選擇沖銷公司
                            </span>
                        </div>

                        <div className="form-item has-datalist" data-datalist>
                            <div className="field">客戶別</div>
                            <div className="value">
                                <input id="custNameText" type="text" value={custName} onChange={custNameChange} />
                                <ul className="datalist scrollbar">
                                    {custNameArray.map((x, index) => (
                                        <li key={index} data-value={x.custName} onClick={e => { setCustName(x.custName), setCustTitle(x.custTitle), setCustTaxID(x.custTaxID) }}>
                                            <span className="flex-3 bold">{x.custName}</span>
                                            <span className="flex-4 bold">{x.custTitle}</span>
                                            <span className="flex-3 bold">{x.custTaxID}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <span className="help-word">請輸入正確格式</span>
                        </div>

                        <div className="form-item  has-datalist" data-datalist>
                            <div className="field">公司抬頭</div>
                            <div className="value">
                                <input id="custTitleText" type="text" value={custTitle} onChange={custTitleChange} />
                                <ul className="datalist scrollbar">
                                    {custTitleArray.map((x, index) => (
                                        <li key={index} data-value={x.custTitle} onClick={e => { setCustName(x.custName), setCustTitle(x.custTitle), setCustTaxID(x.custTaxID) }}>
                                            <span className="flex-3 bold">{x.custName}</span>
                                            <span className="flex-4 bold">{x.custTitle}</span>
                                            <span className="flex-3 bold">{x.custTaxID}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <span className="help-word">請輸入正確格式</span>
                        </div>

                        <div className="form-item has-datalist" data-datalist>
                            <div className="field">公司統編</div>
                            <div className="value multi-inline">
                                <input id="custTaxIdText" type="text" onChange={custTaxIDChange} value={custTaxID} />
                                <ul className="datalist scrollbar">
                                    {custTaxArray.map((x, index) => (
                                        <li key={index} data-value={x.custTaxID} onClick={e => { setCustName(x.custName), setCustTitle(x.custTitle), setCustTaxID(x.custTaxID) }}>
                                            <span className="flex-3 bold">{x.custName}</span>
                                            <span className="flex-4 bold">{x.custTitle}</span>
                                            <span className="flex-3 bold">{x.custTaxID}</span>
                                        </li>
                                    ))}
                                </ul>
                                <a className="btn btn-border btn-middle" onClick={() => window.open('/Main?path=CustomerNew')}>
                                    <span>新增客戶</span>
                                </a>
                            </div>
                            <span className="help-word">請輸入正確格式</span>
                        </div>

                        <div className="form-item has-datalist" data-datalist>
                            <div className="field">負責業務</div>
                            <div className="value">
                                <input id="adNameText" type="text" onChange={adNameChange} autoComplete="off" />
                                <ul className="datalist scrollbar">
                                    {adNameArray.map((x, index) => (
                                        <li key={index} data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => { setAdName(x.enName), setAdID(x.id); }}>
                                            <span className="flex-5 bold">{x.enName}({x.tc_name})</span>
                                            <span className="flex-5">{x.mail}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="help-word">請輸入正確格式</div>
                        </div>

                        <div className="form-item btn-area sp">
                            <div className="left">

                            </div>
                            <div className="right">

                                <a className="btn" onClick={openModalQry}>
                                    <span>查詢未沖銷憑證</span>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div id="modal-1" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">未沖銷憑證列表</h3>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <ul className="list-table">
                            <li className="lt-row lt-th">
                                <div className="flex-2">沖銷公司</div>
                                <div className="flex-3">客戶別</div>
                                <div className="flex-5">公司抬頭</div>
                                <div className="flex-2">統編/ 編號</div>
                                <div className="flex-3">負責業務</div>

                            </li>
                            {
                                dataList.map((x, index) =>
                                    <li key={index} className="lt-row">
                                        <div className="flex-2">
                                            <div className="elips-1">{x.companyName}</div>
                                        </div>
                                        <div className="flex-3"><a onClick={() => history.push(`/Main?path=AccChargeList&companyId=${x.companyId}&customerId=${x.customerId}`)}>{x.customerName}</a></div>
                                        <div className="flex-5">
                                            <div className="elips-1">{x.customerTitle}</div>
                                        </div>
                                        <div className="flex-2">
                                            <div className="elips-1">{x.customerTaxId}</div>
                                        </div>
                                        <div className="flex-3">
                                            <div className="elips-1">{x.personInChargeName}</div>
                                        </div>

                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>

        </>
    );
};

export default AccChargeQry;
