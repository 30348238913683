import React, { useState, useEffect } from "react";

import {

    API_HOST,
    API_GET_SaleOrderData,
    API_DEL_SaleOrderData,
    API_GET_Customer3Name,
    API_POST_SaleOrderCustomerBalance,
    API_GET_AdNameList,
    API_GET_CurrencyComExchangeRate,
    API_GET_CompanyProductType,
    API_GET_ProductName1,
    API_GET_ProductName2,
    API_GET_SaleOrderRecord,
} from "../../global/constants";
import { NumFormart, CloseModal, OpenModal, InitJS } from "../../global/ToolUtil";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const OrderDetail = ({ orderId, setReGet }) => {
    var history = useHistory();
    useEffect(() => {
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);


    useEffect(() => {
        getData();
    }, [orderId]);



    //訂單編號
    const [orderNumber, setOrderNumber] = useState("");
    const [orderCreateDate, setOrderCreateDate] = useState("");
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyId, setCompanyId] = useState();
    const [costToOther, setCostToOther] = useState();
    //銷售類型
    const [saleType, setSaleType] = useState(0);
    function saleTypeChange(e) {
        var index = e.target.dataset.index;
        setSaleType(e.target.value);
        var elem = document.querySelector("#amortized_" + index);
        //攤提型 攤提次數為必填
        if (e.target.value == "2") {
            document.getElementById("amortized_" + index).disabled = false;
            document.getElementById("amortizedFrequency_" + index).disabled = false;
            elem.dataset.required = true;
        } else {
            //取消攤提次數必填並反灰
            document.getElementById("amortized_" + index).disabled = true;
            document.getElementById("amortizedFrequency_" + index).disabled = true;
            elem.removeAttribute("data-required");
        }
    }
    //公司匯率表
    const [exCurrID, setExCurrID] = useState();
    const [exchangeRateArray, setExchangeRateArray] = useState([{}]);
    //產品別
    const [productTypeArray, setProductTypeArray] = useState([{}]);
    //幣別
    const [currencyBaseArray, setCurrencyBaseArray] = useState([{}]);
    const [currencyEName, setCurrencyEName] = useState();
    //總金額
    const [totalAmount, setTotalAmount] = useState();
    //上傳檔案
    const [selectedFile, setSelectedFile] = useState(null);
    //攤提次數
    function amortizedChange(e) {
        var index = e.target.dataset.index;
        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.amortized = e.target.value;
                }
                return item;
            });
        });
    }
    //季月年
    function amortizedFrequencyChange(e) {
        var index = e.target.dataset.index;
        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.amortizedFrequency = e.target.value;
                }
                return item;
            });
        });
    }
    //訂單狀態
    const [monthlyState, setMonthlyState] = useState();
    //採購請款ID
    const [soProcApplyID, setSOProcApplyID] = useState();
    //營收月份
    //const [incomeMonth, setIncomeMonth] = useState();
    function incomeMonthChange(e, index) {
        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.incomeMonth = e.target.value;
                }
                return item;
            });
        });
    }
    //新增的是2022/10但在2022/11的時候進入編輯頁面，進來的時候[營收月份]還是顯示[2022/10]
    //新增的是2022 / 10但在2022 / 12的時候進入編輯頁面，進來的時候[營收月份]還是顯示[2022 / 10]，但不會有2022 / 11選項
    //營收月份[下拉選項]可以不用有[2022 / 10]選項，要有也可以。
    //但是只要改成2022 / 12，就沒辦法回朔到2022 / 10
    const [incomeMonthV, setIncomeMonthV] = useState();
    const [incomeMonthS, setIncomeMonthS] = useState();
    const [dateArray, setDateArray] = useState([]);
    //客戶簡稱
    const [custNickName, setCustNickName] = useState("");
    function custNickNameChange(e) {
        setCustNickName(e.target.value);
    }
    //客戶別
    const [custName, setCustName] = useState("");
    const [custNameArray, setCustNameArray] = useState([{}]);
    function custNameChange(e) {
        setCustName(e.target.value);
        getCustomer3Name(1);
    }
    //客戶抬頭
    const [custTitle, setCustTitle] = useState("");
    const [custTitleArray, setCustomerTitleArray] = useState([{}]);
    function custTitleChange(e) {
        setCustTitle(e.target.value);
        getCustomer3Name(2);
    }
    const [custTaxID, setCustTaxID] = useState("");
    const [custTaxIDArray, setCustomerTaxArray] = useState([{}]);
    function custTaxIDChange(e) {
        setCustTaxID(e.target.value);
        getCustomer3Name(3);
    }
    //保證金餘額
    const [margin, setMargin] = useState();
    //信用額度
    const [creditFacilities, setCreditFacilities] = useState();
    //信用餘額
    const [creditBalance, setCreditBalance] = useState();
    //儲值金額
    const [advance, setAdvance] = useState();
    //可交易餘額
    const [tradableBalance, setTradableBalance] = useState();
    const [tradbleBalanceN, setTradableBalanceN] = useState(false);
    const [custID, setCustID] = useState();
    //資安成本金額
    const [orderCost, setOrderCost] = useState();
    function orderCostChange(e) {
        var num = e.target.value.replace(/,/g, '');
        setOrderCost(num);
    }
    //是否請購
    const [isPurchase, setIsPurchase] = useState();
    //額度選項
    const [priorityPayment, setPriorityPayment] = useState();
    //備註
    const [remark, setRemark] = useState();
    function remarkChange(e) {
        setRemark(e.target.value);
    }
    const [adNameState, setAdNameState] = useState(1);
    const [adNameArray, setADNameArray] = useState([{}]);
    const [adName, setAdName] = useState();
    const [adID, setAdID] = useState();
    const [modalStr, setModalStr] = useState();
    function adNameChange(e) {
        setAdName(e.target.value);
        getADName();
    }
    //歷史紀錄
    const [qryList, setQryList] = useState([{}]);
    //抓取資料
    function getData() {
        if (orderId != null) {
            API_GET_SaleOrderData({
                orderId: orderId,
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code

                    console.log("訂單詳細資料", JSON.stringify(data));
                    if (data.code == "4017") {
                        //"找不到相對應的訂單id。"
                        //history.push("/Main?path=SaleOrderQry");
                    }

                    setCompanyArray(data.result.data.companyArray);
                    //setProductTypeArray(data.result.data.productTypeArray);
                    setCurrencyBaseArray(data.result.data.currencyArray);
                    var tmpCurrencyBaseArray = data.result.data.currencyArray;
                    setCurrencyEName(data.result.data.currencyEName);
                    //---------
                    setOrderNumber(data.result.data.orderNumber);
                    setOrderCreateDate(data.result.data.createdTime != null ? data.result.data.createdTime.split('T')[0].replace(/-/g, "/") : "");
                    setMonthlyState(data.result.data.monthlyState);
                    setCompanyId(data.result.data.companyId); //負責公司
                    setSaleType(data.result.data.salesType); //銷售類型
                    setCostToOther(data.result.data.costToOther); //經銷類別
                    setCustID(data.result.data.customerId); //客戶ID
                    setCustName(data.result.data.customerName); //客戶別
                    setCustTitle(data.result.data.customerTitle); //客戶抬頭
                    setCustTaxID(data.result.data.customerTaxId); //客戶統編
                    setAdName(data.result.data.personInChargeName); //負責業務
                    setAdID(data.result.data.adid); //負責業務ID
                    setPriorityPayment(data.result.data.priorityPayment); //額度選項
                    setMargin(data.result.data.currencyEName + " " + NumFormart(data.result.data.margin)); //保證金餘額
                    setCreditFacilities(data.result.data.currencyEName + " " + NumFormart(data.result.data.creditFacilities)); //信用額度
                    setCreditBalance(data.result.data.currencyEName + " " + NumFormart(data.result.data.creditBalance)); //信用餘額
                    setAdvance(data.result.data.currencyEName + " " + NumFormart(data.result.data.advance)); //儲值金額
                    setTradableBalance(data.result.data.currencyEName + " " + NumFormart(data.result.data.tradableBalance)); //可交易餘額
                    if (data.result.data.tradableBalance < 0) {
                        setTradableBalanceN(true);
                    }
                    setOrderCost(NumFormart(data.result.data.orderCost)); //協消成本
                    setIsPurchase(data.result.data.isPurchase); //是否請夠
                    setTotalAmount(data.result.data.orderTotalAmount); //訂單總金額
                    setProductData(data.result.data.orderProduct); //訂單總金額
                    setSOProcApplyID(data.result.data.soProcApplyId); //採購請款ID
                    setRemark(data.result.data.remark);
                    //--------------------------------
                    //匯率
                    API_GET_CurrencyComExchangeRate({
                        companyId: data.result.data.companyId,
                    })
                        .then((response) => response.data)
                        .then((data) => {
                            if (!checkAuthToken(data)) {
                                history.push("/");
                            }
                            if (!CheckResponseCode(data)) { return; } //檢查response code
                            setExchangeRateArray(data.result.data);
                            var tmpExchangeRateArray = data.result.data;

                            //用變數 不然setCurrID會延遲
                            var texCurrID = 5;
                            //有的資料沒有exRateRate == 1 因此if判斷
                            if (data.result.data.find((s) => s.exRateRate == 1) != null) {
                                setExCurrID(
                                    data.result.data.find((s) => s.exRateRate == 1).exCurrID
                                );
                                texCurrID = data.result.data.find(
                                    (s) => s.exRateRate == 1
                                ).exCurrID;
                            } else {
                                setExCurrID(5);
                            }
                            var tSum = 0.0;

                            var exEname =
                                tmpCurrencyBaseArray.find((x) => x.currID == texCurrID)
                                    .currEName + " ";

                            setProductData(function (prev) {
                                return prev.map((item) => {
                                    if (texCurrID != item.spCurrID) {
                                        var currEName =
                                            tmpCurrencyBaseArray.find((x) => x.currID == item.spCurrID)
                                                .currEName + " ";
                                        item.subtotal =
                                            currEName + NumFormart(item.spQuantity * item.spUnitPrice);
                                        item.subtotalExchangeRate = exEname + NumFormart(((item.spQuantity * item.spUnitPrice) / tmpExchangeRateArray.find((s) => s.exCurrID == item.spCurrID).exRateRate).toFixed(2));
                                        tSum = parseFloat(tSum) + parseFloat(((item.spQuantity * item.spUnitPrice) / tmpExchangeRateArray.find((s) => s.exCurrID == item.spCurrID).exRateRate).toFixed(2));
                                    } else {
                                        item.subtotal =
                                            exEname + NumFormart(item.spQuantity * item.spUnitPrice);
                                        tSum =
                                            parseFloat(tSum) +
                                            parseFloat(item.spQuantity * item.spUnitPrice);
                                    }
                                    return item;
                                });
                            });
                            //需要用延遲地 不然容易變成0
                            setTimeout(function () {
                                setTotalAmount(exEname + NumFormart(tSum.toFixed(2)));
                            }, 500);
                        });
                    //--------------------------------
                    //產品別
                    API_GET_CompanyProductType({
                        companyId: data.result.data.companyId,
                    })
                        .then((response) => response.data)
                        .then((data) => {
                            if (!checkAuthToken(data)) {
                                history.push("/");
                            }
                            if (!CheckResponseCode(data)) { return; } //檢查response code
                            setProductTypeArray(data.result.data);
                        });
                    //--------------------------------
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }


        var deArray = [];
        for (var i = 0; i <= 23; i++) {
            var futureMonth = moment().add(i, "M").format("YYYY/MM");
            deArray.push(futureMonth);
        }
        setDateArray(deArray);
    }

    //換負責公司 要額外抓匯率表/產品別
    function companyIdChange(id) {
        setCompanyId(id);
        //匯率表
        API_GET_CurrencyComExchangeRate({
            companyId: id,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setExchangeRateArray(data.result.data);
                //用變數 不然setCurrID會延遲
                var texCurrID = 5;
                //有的資料沒有exRateRate == 1 因此if判斷
                if (data.result.data.find((s) => s.exRateRate == 1) != null) {
                    setExCurrID(data.result.data.find((s) => s.exRateRate == 1).exCurrID);
                    texCurrID = data.result.data.find((s) => s.exRateRate == 1).exCurrID;
                } else {
                    setExCurrID(5);
                }
                //換了公司 則重新處理 數量單價小劇總金額
                var tSum = 0.0;
                var exEname =
                    currencyBaseArray.find((x) => x.currID == texCurrID).currEName + " ";
                setProductData(function (prev) {
                    return prev.map((item) => {
                        if (
                            item.spCurrID != null &&
                            item.spCurrID != "" &&
                            texCurrID != item.spCurrID
                        ) {
                            var currEName =
                                currencyBaseArray.find((x) => x.currID == item.spCurrID)
                                    .currEName + " ";
                            item.subtotal =
                                currEName + NumFormart(item.spQuantity * item.spUnitPrice);
                            var ename2 =
                                currencyBaseArray.find((x) => x.currID == texCurrID).currEName +
                                " ";
                            item.subtotalExchangeRate =
                                ename2 +
                                NumFormart(
                                    (
                                        item.spQuantity *
                                        item.spUnitPrice *
                                        exchangeRateArray.find((s) => s.exCurrID == item.spCurrID)
                                            .exRateRate
                                    ).toFixed(2)
                                );
                            tSum =
                                parseFloat(tSum) +
                                parseFloat(
                                    (
                                        item.spQuantity *
                                        item.spUnitPrice *
                                        exchangeRateArray.find((s) => s.exCurrID == item.spCurrID)
                                            .exRateRate
                                    ).toFixed(2)
                                );
                        } else {
                            item.subtotal =
                                exEname + NumFormart(item.spQuantity * item.spUnitPrice);
                            tSum =
                                parseFloat(tSum) +
                                parseFloat(item.spQuantity * item.spUnitPrice);
                        }
                        return item;
                    });
                });
                //需要用延遲地 不然容易變成0
                setTimeout(function () {
                    setTotalAmount(exEname + NumFormart(tSum.toFixed(2)));
                }, 500);
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        //產品別
        API_GET_CompanyProductType({
            companyId: id,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setProductTypeArray(data.result.data);

                setProductData(function (prev) {
                    return prev.map((item) => {
                        item.productName1 = "";
                        item.productName2 = "";
                        item.prodTPID = "";
                        return item;
                    });
                });
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //重新取得 客戶別 客戶別Title 客戶統編 List
    function getCustomer3Name(index) {
        var dt = [];
        if (index == 1) {
            dt = {
                customerName: document.getElementById("customerNameText").value
            }
        }
        else if (index == 2) {
            dt = {
                customerTitle: document.getElementById("customerTitleText").value
            }

        }
        else if (index == 3) {
            dt = {
                customerTaxId: document.getElementById("customerTaxIdText").value,
            }
        }
        API_GET_Customer3Name(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                var dataName = [];
                var dataTitle = [];
                var dataTaxId = [];

                if (data.result != null && data.result.data != null) {
                    if (index == 1) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataName.length; j++) {
                                if (dataName[j].custName == data.result.data[i].custName) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataName.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustNameArray(dataName);
                    } else if (index == 2) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTitle.length; j++) {
                                if (dataTitle[j].custTitle == data.result.data[i].custTitle) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTitle.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxId: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerTitleArray(dataTitle);
                    } else if (index == 3) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTaxId.length; j++) {
                                if (dataTaxId[j].custTaxID == data.result.data[i].custTaxID) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTaxId.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerTaxArray(dataTaxId);
                    }
                }
            })

    }

    function custIDChange(e) {
        API_POST_SaleOrderCustomerBalance({
            customerId: e,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                ////console.log(data);
                setAdName(data.result.data.personInChargeName);
                setAdID(data.result.data.adid);
                setMargin(data.result.data.currencyEnName + ' ' + NumFormart(data.result.data.margin));
                setCreditFacilities(data.result.data.currencyEnName + ' ' + NumFormart(data.result.data.creditFacilities));
                setCreditBalance(data.result.data.currencyEnName + ' ' + NumFormart(data.result.data.creditBalance));
                setAdvance(data.result.data.currencyEnName + ' ' + NumFormart(data.result.data.advance));
                setTradableBalance(data.result.data.currencyEnName + ' ' + NumFormart(data.result.data.tradableBalance));
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [saleTypeArray, setSaleTypeArray] = useState([
        { value: 0, name: "月租型" },
        { value: 1, name: "一次型" },
        { value: 2, name: "攤提型" },
    ]);
    const [saleClassArray, setSaleClassArray] = useState([
        { value: 1, name: "是" },
        { value: 0, name: "否" },
    ]);
    const [invStateArray, setInvStateArray] = useState([
        { id: 0, name: "未開" },
        { id: 1, name: "預開" },
        { id: 2, name: "列管" },
        { id: 3, name: "作廢" },
        { id: 4, name: "作廢重開" },
        { id: 5, name: "處理中" },
        { id: 6, name: "已開" },
        { id: 7, name: "列管已開" },
        { id: 8, name: "作廢重開已開" },
        { id: 100, name: "為預開(未開)" },
    ]);

    //產品品項
    const [productData, setProductData] = useState([
        {
            spid: null,
            prodTPID: null,
            productName1: null,
            productName2: null,
            spQuantity: null,
            spUnitPrice: null,
            spCurrID: null,
            spInvState: null,
            spRem: null,
            exchangeRate: null,
            subtotal: null,
            subtotalExchangeRate: null,
            spModiStamp: null,
            isDelete: false,
            productName1Array: [{}],
            productName2Array: [{}],
        },
    ]);
    function addProductData() {
        setProductData(function (prev) {
            return [
                ...prev,
                {
                    prodTPID: null,
                    productName1: null,
                    productName2: null,
                    spQuantity: null,
                    spUnitPrice: null,
                    spCurrID: null,
                    spInvState: 0,
                    spRem: null,
                    exchangeRate: null,
                    subtotal: null,
                    subtotalExchangeRate: null,
                    spModiStamp: null,
                    isDelete: false,
                    incomeMonth: moment().format("YYYY/MM"),
                    productName1Array: [{}],
                    productName2Array: [{}],
                },
            ];
        });
    }
    //按了新增 在按刪除的
    function delProductData(index) {
        setProductData(function (prev) {
            return prev.filter((x, i) => i != index);
        });
    }
    //原本有資料要刪除
    function delProductData2(index) {
        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.isDelete = true;
                }
                return item;
            });
        });
    }
    function productTypeIdChange(e) {
        var index = e.target.dataset.index;
        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.prodTPID = e.target.value;
                }
                return item;
            });
        });
    }
    function productName1Change(e) {
        var index = e.target.dataset.index;

        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.productName1 = e.target.value;
                    ////呼叫共用G
                    API_GET_ProductName1({
                        input: e.target.value,
                        prodTPID: item.prodTPID,
                    })
                        .then((response) => response.data)
                        .then((data) => {
                            if (!checkAuthToken(data)) {
                                history.push("/");
                            }
                            if (!CheckResponseCode(data)) { return; } //檢查response code
                            if (data.code == "0000") {
                                item.productName1Array = data.result.data;
                            }
                        });
                }
                return item;
            });
        });
    }
    //關鍵字按鈕
    function productName1Click(index, value) {
        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.productName1 = value;
                }
                return item;
            });
        });
    }
    //關鍵字按鈕
    function productName2Click(index, value) {
        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.productName2 = value;
                }
                return item;
            });
        });
    }

    function productName2Change(e) {
        var index = e.target.dataset.index;

        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.productName2 = e.target.value;
                    ////呼叫共用F
                    API_GET_ProductName2({
                        input: e.target.value,
                        prodTPID: item.prodTPID,
                    })
                        .then((response) => response.data)
                        .then((data) => {
                            if (!checkAuthToken(data)) {
                                history.push("/");
                            }
                            if (!CheckResponseCode(data)) { return; } //檢查response code
                            if (data.code == "0000") {
                                item.productName2Array = data.result.companyArray;
                            }
                        });
                }
                return item;
            });
        });
    }

    function quantityChange(e) {
        var index = e.target.dataset.index;
        //訂單總金額
        var tSum = 0.0;
        var exEname =
            currencyBaseArray.find((x) => x.currID == exCurrID).currEName + " ";
        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.spQuantity = e.target.value;
                }
                if (
                    item.spCurrID != null &&
                    item.spCurrID != "" &&
                    exCurrID != item.spCurrID
                ) {
                    var currEName =
                        currencyBaseArray.find((x) => x.currID == item.spCurrID).currEName +
                        " ";
                    item.subtotal =
                        currEName + NumFormart(item.spQuantity * item.spUnitPrice);
                    var ename2 =
                        currencyBaseArray.find((x) => x.currID == exCurrID).currEName + " ";
                    item.subtotalExchangeRate =
                        ename2 +
                        NumFormart(
                            (
                                item.spQuantity *
                                item.spUnitPrice *
                                exchangeRateArray.find((s) => s.exCurrID == item.spCurrID)
                                    .exRateRate
                            ).toFixed(2)
                        );
                    tSum = parseFloat(tSum) + parseFloat((item.spQuantity *
                        item.spUnitPrice * exchangeRateArray.find((s) => s.exCurrID == item.spCurrID).exRateRate).toFixed(2));
                } else {
                    item.subtotal = exEname + NumFormart(item.spQuantity * item.spUnitPrice);
                    tSum = parseFloat(tSum) + parseFloat(item.spQuantity * item.spUnitPrice);
                }
                return item;
            });
        });

        //需要用延遲地 不然容易變成0
        setTimeout(function () {
            setTotalAmount(exEname + NumFormart(tSum.toFixed(2)));
        }, 500);
    }
    function unitPriceChange(e) {
        var index = e.target.dataset.index;
        //訂單總金額
        var tSum = 0;
        var exEname =
            currencyBaseArray.find((x) => x.currID == exCurrID).currEName + " ";
        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.spUnitPrice = e.target.value;
                }
                if (
                    item.spCurrID != null &&
                    item.spCurrID != "" &&
                    exCurrID != item.spCurrID
                ) {
                    var currEName = currencyBaseArray.find((x) => x.currID == item.spCurrID).currEName + " ";
                    item.subtotal = currEName + NumFormart(item.spQuantity * item.spUnitPrice);
                    var ename2 = currencyBaseArray.find((x) => x.currID == exCurrID).currEName + " ";
                    item.subtotalExchangeRate = ename2 + NumFormart(((item.spQuantity * item.spUnitPrice) / exchangeRateArray.find((s) => s.exCurrID == item.spCurrID).exRateRate).toFixed(2));
                    tSum = parseFloat(tSum) + parseFloat(((item.spQuantity * item.spUnitPrice) / exchangeRateArray.find((s) => s.exCurrID == item.spCurrID).exRateRate).toFixed(2));
                } else {
                    item.subtotal = exEname + NumFormart(item.spQuantity * item.spUnitPrice);
                    tSum = parseFloat(tSum) + parseFloat(item.spQuantity * item.spUnitPrice);
                }
                return item;
            });
        });
        //需要用延遲地 不然容易變成0
        setTimeout(function () {
            setTotalAmount(exEname + NumFormart(tSum.toFixed(2)));
        }, 500);
    }

    function currencyIdChange(index) {
        //訂單總金額
        var tSum = 0;
        var exEname =
            currencyBaseArray.find((x) => x.currID == exCurrID).currEName + " ";
        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.spCurrID = document.getElementById("currencyId_" + index).value;
                }
                if (
                    item.spCurrID != null &&
                    item.spCurrID != "" &&
                    exCurrID != item.spCurrID
                ) {
                    var currEName = currencyBaseArray.find((x) => x.currID == item.spCurrID).currEName + " ";
                    item.subtotal = currEName + NumFormart(item.spQuantity * item.spUnitPrice);
                    var ename2 = currencyBaseArray.find((x) => x.currID == exCurrID).currEName + " ";
                    item.subtotalExchangeRate = ename2 + NumFormart(((item.spQuantity * item.spUnitPrice) / exchangeRateArray.find((s) => s.exCurrID == item.spCurrID).exRateRate).toFixed(2));
                    tSum = parseFloat(tSum) + parseFloat(((item.spQuantity * item.spUnitPrice) / exchangeRateArray.find((s) => s.exCurrID == item.spCurrID).exRateRate).toFixed(2));
                } else {
                    item.subtotal = exEname + NumFormart(item.spQuantity * item.spUnitPrice);
                    tSum = parseFloat(tSum) + parseFloat(item.spQuantity * item.spUnitPrice);
                }
                return item;
            });
        });
        //需要用延遲地 不然容易變成0
        setTimeout(function () {
            setTotalAmount(exEname + NumFormart(tSum.toFixed(2)));
        }, 500);
    }
    function spRemChange(e) {
        var index = e.target.dataset.index;
        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.spRem = e.target.value;
                }
                return item;
            });
        });
    }

    function getADName() {
        if (document.getElementById("adNameText").value == "") {
            return false;
        }

        API_GET_AdNameList({
            input: document.getElementById("adNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setADNameArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
    }
    var moment = require("moment");

    //前往新增客戶
    function goCustomerNew() {
        window.open("/Main?path=CustomerNew", '_blank')
    }

    //刪除
    function delData() {
        CloseModal("modal-checkdel");

        var dt = {
            orderId: orderId,
        };
        //console.log(orderId);
        API_DEL_SaleOrderData(dt)
            .then((response) => response.data)
            .then((data) => {
                ////console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                if (data.code == "0000") {
                    setModalStr("刪除成功!");
                    OpenModal("modal-success2", 3);
                    setReGet(true);
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error2", 3);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //送出
    function submit() {
        //檢查資料
        var result = checkData();

        //如果資料檢查有通過 則送出
        if (result) {
            const formData = new FormData();

            var tmpproductData = [];
            for (var i = 0; i < productData.length; i++) {
                var tmpModiStamp =
                    productData[i].spModiStamp == null
                        ? null
                        : productData[i].spModiStamp;

                if (productData[i].isDelete == true) {
                    tmpModiStamp = tmpModiStamp - 1;
                } else {
                    tmpModiStamp = tmpModiStamp != null ? tmpModiStamp + 1 : null;
                }
                if (productData[i].spid == null) {
                    var tArray = {
                        spProdID: productData[i].spProdID,
                        productTypeId: productData[i].prodTPID,
                        productName1: productData[i].productName1,
                        productName2: productData[i].productName2,
                        quantity: parseInt(productData[i].spQuantity),
                        unitPrice: parseFloat(productData[i].spUnitPrice),
                        currencyId: parseInt(productData[i].spCurrID),
                        explanation: productData[i].spRem,
                        modiStamp: tmpModiStamp,
                        incomeMonth: productData[i].incomeMonth,
                    };
                    tmpproductData.push(tArray);
                } else {
                    var tArray = {
                        spid: productData[i].spid,
                        spProdID: productData[i].spProdID,
                        productTypeId: productData[i].prodTPID,
                        productName1: productData[i].productName1,
                        productName2: productData[i].productName2,
                        quantity: parseInt(productData[i].spQuantity),
                        unitPrice: parseFloat(productData[i].spUnitPrice),
                        currencyId: parseInt(productData[i].spCurrID),
                        explanation: productData[i].spRem,
                        modiStamp: tmpModiStamp,
                        incomeMonth: productData[i].incomeMonth,
                    };
                    tmpproductData.push(tArray);
                }
            }

            var tmpArray = {
                orderId: orderId,
                companyId: companyId,
                salesType: saleType, //銷售類型
                amortized: saleType == 2 ? amortized : null, //攤提次數
                amortizedFrequency:
                    saleType == 2
                        ? parseInt(document.getElementById("amortizedFrequency").value)
                        : null, //攤提單位(月/季/年)
                costToOther: costToOther, //是否為經銷類別
                //incomeMonth: document.getElementById('incomeMonth').value,
                customerId: custID, //客戶資料ID
                salesID: adID, //負責業務ID
                priorityPayment: priorityPayment, //額度選項
                isPurchase: isPurchase, //是否請購
                orderCost: parseInt(orderCost), //協銷/ 資安成本金額
                remark: remark,
                orderProduct: tmpproductData,
            };
            ////console.log(JSON.stringify(tmpArray));
            formData.append("view", JSON.stringify(tmpArray));
            //檢查是否有檔案
            if (selectedFile != null && selectedFile.size > 0) {
                formData.append("files", selectedFile);
            }
            const requestOptions = {
                method: "PUT",
                headers: {
                    //"Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                body: formData,
            };

            var path = API_HOST + "/salesorder/data";
            fetch(path, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    //if (!checkAuthToken(data)) { history.push('/') };
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        setModalStr("修改成功");
                        OpenModal("modal-success2", 3);
                        setReGet(true);
                    } else {
                        setModalStr(data.message);
                        OpenModal("modal-error2", 3);
                    }
                });
        }
    }

    //資料檢查
    function checkData() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {
            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });

        //信用額度
        var bf122 = false;
        var f122 = document.getElementsByName("f122");
        for (var i = 0; i < f122.length; i++) {
            if (f122[i].checked) {
                bf122 = true;
                break;
            }
        }

        if (bf122 == false) {
            document.getElementById("paymentDiv").classList.add("show-help");
            result = false;
        } else {
            document.getElementById("paymentDiv").classList.remove("show-help");
        }

        //是否請購
        var bf123 = false;
        var f123 = document.getElementsByName("f123");
        for (var i = 0; i < f123.length; i++) {
            if (f123[i].checked) {
                bf123 = true;
                break;
            }
        }

        if (bf123 == false) {
            document.getElementById("isPurchaseDIV").classList.add("show-help");
            result = false;
        } else {
            document.getElementById("isPurchaseDIV").classList.remove("show-help");
        }

        let tmpSalesType0 = productData.filter(x => x.salesType == 0).length;
        let tmpSalesType1 = productData.filter(x => x.salesType == 1).length;
        let tmpSalesType2 = productData.filter(x => x.salesType == 2).length;
        let countSalesType = 0;
        if (tmpSalesType0 > 0) {
            countSalesType++;
        }
        if (tmpSalesType1 > 0) {
            countSalesType++;
        }
        if (tmpSalesType2 > 0) {
            countSalesType++;
        }
        if (countSalesType > 1) {
            result = false;
            setModalStr("產品品項之銷售類型不同，請修改為同一類型");
            OpenModal("modal-error");

            return result;
        }

        return result;
    }



    //打開歷史查詢
    function openModalH(e) {
        API_GET_SaleOrderRecord({
            orderId: parseInt(orderId),
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                if (data.code == "0000") {
                    setQryList(data.result.data);
                }
            });
        var id = "modal-h";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
        e.preventDefault();
    }


    function getFile() {
        document.getElementById("file").click();
    }


    return (
        <div className="modal-container">
            <div className="modal-header">
                <h3 className="fz-B has-border">訂單編號:{orderNumber}</h3>
                <div className="row field">
                    <div className="right">
                        <span>建立日期:{orderCreateDate}</span>
                    </div>
                </div>
                <span className="close" onClick={() => CloseModal("modal-order")}>
                    <i className="icon icon-44"></i>
                </span>
            </div>
            <div className="modal-body">
                <div className="form-container">
                    <div className="form-item must">
                        <div className="field">營收公司</div>
                        <div className="value">
                            {companyArray.map((x) => (
                                <div key={x.id}>
                                    <input
                                        type="radio"
                                        id={`f101-${x.cmpID}`}
                                        name="f101"
                                        value={x.cmpID}
                                        checked={companyId == x.cmpID ? true : false}
                                        onClick={(e) => companyIdChange(x.cmpID)}
                                    />
                                    <label htmlFor={`f101-${x.cmpID}`}>{x.cmpName}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇營收公司</span>
                    </div>

                    <div className="form-item must half">
                        <div className="field">經銷類別</div>
                        <div className="value">
                            <div>
                                <input type="radio" name="costToOther" id="costToOther_1" checked={costToOther ? true : false} onClick={(e) => setCostToOther(true)} />
                                <label htmlFor="costToOther_1">是</label>
                            </div>
                            <div>
                                <input type="radio" name="costToOther" id="costToOther_2" checked={!costToOther ? true : false} onClick={(e) => setCostToOther(false)} />
                                <label htmlFor="costToOther_2">否</label>
                            </div>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <hr />
                    <div className="form-item must half has-datalist" data-datalist>
                        <div className="field">客戶別</div>
                        <div className="value">
                            <input
                                id="customerNameText"
                                type="text"
                                value={custName}
                                onChange={custNameChange}
                                data-required
                            />
                            <ul className="datalist scrollbar">
                                {custNameArray.map((x) => (
                                    <li
                                        data-value={x.custName} onClick={(e) => { setCustName(x.custName), setCustTitle(x.custTitle), setCustTaxID(x.custTaxID), custIDChange(x.custID), setCustID(x.custID); }}>
                                        <span className="flex-4 bold">{x.custName}</span>
                                        <span className="flex-3">{x.custTitle}</span>
                                        <span className="flex-3">{x.custTaxID}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item half jcfe">
                        <a className="btn btn-border btn-middle" onClick={goCustomerNew}>
                            <span>新增客戶</span>
                        </a>
                    </div>

                    <div className="form-item must half  has-datalist" data-datalist>
                        <div className="field">公司抬頭</div>
                        <div className="value">
                            <input id="customerTitleText" type="text" onChange={custTitleChange} value={custTitle} data-required />
                            <ul className="datalist scrollbar">
                                {custTitleArray.map((x) => (
                                    <li data-value={x.name}>
                                        <span className="flex-10 bold">{x.name}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item  half  has-datalist" data-datalist>
                        <div className="field">公司統編/ 編號</div>
                        <div className="value">
                            <input id="customerTaxIdText" type="text" onChange={custTaxIDChange} value={custTaxID} />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item must half has-datalist" data-datalist>
                        <div className="field">負責業務</div>
                        <div className="value multi-inline">
                            <input id="adNameText" type="text" value={adName} onChange={adNameChange} disabled={adNameState == 1 ? true : false} autocomplete="off" data-required />
                            <ul className="datalist scrollbar">
                                {adNameArray.map((x) => (
                                    <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => { setAdName(x.enName), setAdID(x.id); }} >
                                        <span className="flex-3 bold">{x.tc_name}</span>
                                        <span className="flex-3">{x.enName}</span>
                                        <span className="flex-2">{x.mail}</span>
                                        <span className="flex-2">{x.id}</span>
                                    </li>
                                ))}
                            </ul>
                            <a
                                className="btn btn-border btn-middle"
                                onClick={(e) => setAdNameState(0)}
                            >
                                <span>修改</span>
                            </a>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <hr />

                    <div className="form-item must half" id="paymentDiv">
                        <div className="field">額度選項</div>
                        <div className="value">
                            <div>
                                <input type="radio" id="f122-1" name="f122" value="0" checked={priorityPayment == 0 ? "checked" : ""} onClick={(e) => setPriorityPayment(0)} />
                                <label htmlFor="f122-1">信用額度</label>
                            </div>
                            <div>
                                <input type="radio" id="f122-2" name="f122" value="1" checked={priorityPayment == 1 ? "checked" : ""} onClick={(e) => setPriorityPayment(1)} />
                                <label htmlFor="f122-2">預付額度</label>
                            </div>
                        </div>
                        <span className="help-word">請輸入額度選項</span>
                    </div>

                    <div className="form-item half">
                        <div className="field">保證金餘額</div>
                        <div className="value">
                            <input type="text" value={margin} disabled="disabled" />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item half">
                        <div className="field">信用額度</div>
                        <div className="value">
                            <input type="text" value={creditFacilities} disabled="disabled" />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item half">
                        <div className="field">信用餘額</div>
                        <div className="value">
                            <input type="text" value={creditBalance} disabled="disabled" />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item half">
                        <div className="field">儲值金額</div>
                        <div className="value">
                            <input type="text" value={advance} disabled="disabled" />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item half">
                        <div className="field">可交易餘額</div>
                        <div className="value">
                            <input type="text" className={`${tradbleBalanceN ? 'error' : ''}`} value={tradableBalance} disabled="disabled" />
                        </div>
                        <span className=" " style={{
                            "width": "100%",
                            "flex-shrink": 0,
                            "font-size": "0.75rem",
                            "padding-left": "130px",
                            "transition": "opacity .35s ease",
                            "pointer-events": "none",
                            "color": "#888",
                        }}>
                            信用額度-應收款項總額-訂單金額+帳務沖銷金額+預付金餘額=可交易餘額
                        </span>
                    </div>

                    <hr />

                    <div className="form-item col must">
                        <div className="field">產品品項</div>
                        <div className="value">
                            {productData.map((x, i) =>
                                x.isDelete == true ? null : (
                                    <>
                                        <ul className="list-table">
                                            <li className="lt-row intensive lt-th">
                                                <div className="flex-1 max1 text-center">序號</div>
                                                <div className="flex-2">產品別</div>
                                                <div className="flex-3">產品細項總稱</div>
                                                <div className="flex-3">產品細項</div>
                                                <div className="flex-2">營收月份</div>
                                                <div className="flex-1"> </div>
                                            </li>
                                            <li className="lt-row intensive ovv">
                                                <div className="flex-1 max1 text-center">{i + 1}</div>
                                                <div className="flex-2">
                                                    {x.spMonthCloseState == 3 ||
                                                        x.spInvState == 1 ||
                                                        x.spInvState == 5 ||
                                                        x.spInvState == 6 ? (
                                                        productTypeArray.map((x2) =>
                                                            x.prodTPID == x2.prodTPID ? x2.prodTPName : null
                                                        )
                                                    ) : (
                                                        <select name={`productType_${i}`} id={`productType_${i}`} data-index={i} onChange={(e) => productTypeIdChange(e)} data-required >
                                                            <option value="">請選擇</option>
                                                            {productTypeArray.map((x2) => (
                                                                <option value={x2.prodTPID} selected={x2.prodTPID == x.prodTPID ? true : false} >
                                                                    {x2.prodTPName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                </div>

                                                <div className="form-item flex-3 no-field no-help-word inline has-datalist" data-datalist >
                                                    <div className="value">
                                                        {x.spMonthCloseState == 3 ||
                                                            x.spInvState == 1 ||
                                                            x.spInvState == 5 ||
                                                            x.spInvState == 6 ? x.productName1 : (
                                                            <>
                                                                <input type="text" value={x.productName1} onChange={productName1Change} data-index={i} data-required />
                                                                <ul className="datalist scrollbar">
                                                                    {x.productName1Array != null
                                                                        ? x.productName1Array.map((x2) => (
                                                                            <li data-value={x2.productName1} onClick={(e) => productName1Click(i, x2.productName1)} >
                                                                                <span className="flex-3 bold">
                                                                                    {x2.productName1}
                                                                                </span>
                                                                            </li>
                                                                        ))
                                                                        : null}
                                                                </ul>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>

                                                <div
                                                    className="form-item flex-3 no-field no-help-word inline has-datalist"
                                                    data-datalist
                                                >
                                                    <div className="value">
                                                        {x.spMonthCloseState == 3 ||
                                                            x.spInvState == 1 ||
                                                            x.spInvState == 5 ||
                                                            x.spInvState == 6 ? x.productName2: (
                                                            <>
                                                                <input type="text" value={x.productName2} onChange={productName2Change} data-index={i} data-required />
                                                                <ul className="datalist scrollbar">
                                                                    {x.productName2Array != null
                                                                        ? x.productName2Array.map((x2) => (
                                                                            <li data-value={x2.productName2} onClick={(e) => productName2Click(i, x2.productName2)} >
                                                                                <span className="flex-3 bold">
                                                                                    {x2.productName1}
                                                                                </span>
                                                                                <span className="flex-3">
                                                                                    {x2.productName2}
                                                                                </span>
                                                                            </li>
                                                                        ))
                                                                        : null}
                                                                </ul>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-item flex-2 no-field no-help-word inline">
                                                    <div className="value">
                                                        <select name={`incomeMonth_${i}`} id={`incomeMonth_${i}`} onChange={(e) => incomeMonthChange(e, i)} data-required >

                                                            {dateArray.map((x) => (
                                                                <option value={x} selected={x == productData[i].incomeMonth ? true : false} > {x} </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="flex-1">
                                                    {
                                                        productData != null && (productData.filter(p => p.isDelete != true).length > 1) ? (
                                                            <div className="btn btn-icon" onClick={x.spid == null ? (e) => delProductData(i) : (e) => delProductData2(i)} >
                                                                <i className="icon icon-37"></i>
                                                            </div>
                                                        ) : null}
                                                </div>
                                            </li>
                                            <li className="lt-row intensive lt-th">
                                                <div className="flex-1">發票狀態</div>
                                                <div className="flex-1">銷售類型</div>
                                                <div className="flex-1">攤提次數</div>
                                                <div className="flex-1">數量</div>
                                                <div className="flex-1">單價</div>
                                                <div className="flex-1">
                                                    幣別
                                                    {exchangeRateArray.map((x2) =>
                                                        x2.exCurrID == x.spCurrID && x.spCurrID != exCurrID
                                                            ? x2.exRateRate + "(" + x2.exRateYM.substring(2) + ")"
                                                            : null
                                                    )}
                                                </div>
                                                <div className="flex-3">小計</div>
                                                <div className="flex-3">說明</div>
                                            </li>
                                            <li className="lt-row intensive lt-th">
                                                <div className="flex-1">
                                                    {invStateArray.map((x2) =>
                                                        x.spInvState == x2.id ? x2.name : null
                                                    )}
                                                </div>
                                                <div className="flex-1">
                                                    <select
                                                        name={`saleType_${i}`}
                                                        id={`saleType_${i}`}
                                                        onChange={saleTypeChange}
                                                        data-index={i}
                                                    >
                                                        {saleTypeArray.map((x2) => (
                                                            <option
                                                                value={x2.value}
                                                                selected={
                                                                    x2.value == x.salesType ? true : false
                                                                }
                                                            >
                                                                {x2.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="flex-1">
                                                    <div class="multi-form">
                                                        <input type="text" id={`amortized_${i}`} name={`amortized_${i}`} onChange={amortizedChange} data-index={i}
                                                            disabled={x.salesType != 2 || x.spMonthCloseState == 3 || x.spInvState == 1 || x.spInvState == 5 || x.spInvState == 6 ? 'disabled' : null} />
                                                        <div class="line"></div>
                                                        <select name={`amortizedFrequency_${i}`} id={`amortizedFrequency_${i}`}
                                                            disabled={x.salesType != 2 || x.spMonthCloseState == 3 || x.spInvState == 1 || x.spInvState == 5 || x.spInvState == 6 ? 'disabled' : null}
                                                            onChange={amortizedFrequencyChange} data-index={i} >
                                                            <option value="0" selected={x.amortized == 0 ? true : false} > 月 </option>
                                                            <option value="1" selected={x.amortized == 1 ? true : false} > 季 </option>
                                                            <option value="2" selected={x.amortized == 2 ? true : false} > 年 </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="flex-1">
                                                    {(x.spMonthCloseState == 3 ||
                                                        x.spInvState == 1 ||
                                                        x.spInvState == 5 ||
                                                        x.spInvState == 6) &&
                                                        x.salesType != 0 ? (
                                                        x.spQuantity
                                                    ) : (
                                                        <input type="number" min="1" value={x.spQuantity} onChange={quantityChange} data-index={i} data-required />
                                                    )}
                                                </div>
                                                <div className="flex-1">
                                                    {(x.spMonthCloseState == 3 ||
                                                        x.spInvState == 1 ||
                                                        x.spInvState == 5 ||
                                                        x.spInvState == 6) &&
                                                        x.salesType != 0 ? (
                                                        x.spQuantity
                                                    ) : (
                                                        <input type="number" value={x.spUnitPrice} onChange={unitPriceChange} data-index={i} data-required />
                                                    )}
                                                </div>
                                                <div className="flex-1">
                                                    {(x.spMonthCloseState == 3 ||
                                                        x.spInvState == 1 ||
                                                        x.spInvState == 5 ||
                                                        x.spInvState == 6) &&
                                                        x.salesType != 0 ? (
                                                        currencyBaseArray.map((x2) =>
                                                            x2.currID == x.spCurrID ? x2.currName : null
                                                        )
                                                    ) : (
                                                        <>
                                                            <select name="currencyBase" id={`currencyId_${i}`} onChange={(e) => currencyIdChange(i)} data-required >
                                                                <option value="">請選擇</option>
                                                                {currencyBaseArray.map((x2) => (
                                                                    <option
                                                                        value={x2.currID} selected={x2.currID == x.spCurrID ? true : false} > {x2.currName} </option>
                                                                ))}
                                                            </select>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="flex-3">
                                                    {(x.spMonthCloseState == 3 ||
                                                        x.spInvState == 1 ||
                                                        x.spInvState == 5 ||
                                                        x.spInvState == 6) &&
                                                        x.salesType != 0 ? (
                                                        <>
                                                            {x.spCurrID == exCurrID
                                                                ? x.subtotal
                                                                : x.subtotal | x.subtotalExchangeRate}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {x.spCurrID == exCurrID ? (
                                                                <input type="text" value={x.subtotal} data-index={i} disabled />
                                                            ) :

                                                                <input type="text" value={`${x.subtotal}(${x.subtotalExchangeRate})`} data-index={i} disabled />


                                                            }
                                                        </>
                                                    )}
                                                </div>
                                                <div className="flex-3">
                                                    {(x.spMonthCloseState == 3 ||
                                                        x.spInvState == 1 ||
                                                        x.spInvState == 5 ||
                                                        x.spInvState == 6) &&
                                                        x.salesType != 0 ? (
                                                        x.spRem
                                                    ) : (
                                                        <input type="text" value={x.spRem} onChange={spRemChange} data-index={i} data-required />
                                                    )}
                                                </div>
                                            </li>
                                        </ul>
                                    </>
                                )
                            )}
                            <div className="btn-area">
                                <div class="info"></div>
                                <a className="btn btn-small btn-border" onClick={addProductData} >
                                    <span>新增產品品項</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="form-item must half" id="isPurchaseDIV">
                        <div className="field">是否請購</div>
                        <div className="value">
                            <div>
                                <input
                                    type="radio" id="f123-1" name="f123" checked={isPurchase == true ? "checked" : ""} onClick={(e) => setIsPurchase(true)} />
                                <label htmlFor="f123-1">是</label>
                            </div>
                            <div>
                                <input type="radio" id="f123-2" name="f123" checked={isPurchase == false ? "checked" : ""} onClick={(e) => setIsPurchase(false)} />
                                <label htmlFor="f123-2">否</label>
                            </div>
                        </div>
                        <span className="help-word">請輸入是否請購</span>
                    </div>

                    <div className="form-item half">
                        <div className="field">訂單總金額</div>
                        <div className="value">
                            <input type="text" value={totalAmount} disabled="disabled" />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item half">
                        <div className="field">協銷/ 資安成本</div>
                        <div className="value">
                            <input type="text" value={NumFormart(orderCost)} onChange={orderCostChange} disabled={soProcApplyID == null ? true : false} />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item half">
                        <div className="field">訂單憑證</div>
                        <div className="value multi-inline">
                            <div class="fake-upload" data-fakeUpload>
                                <input type="file" id="file" className="real" style={{ "display": "none" }} onChange={(e) => setSelectedFile(e.target.files[0])} />
                                <input type="text" className="fake" readonly="readonly" placeholder="請選擇檔案" />
                            </div>
                            <a className="btn btn-middle btn-border" onClick={getFile}>
                                <span>上傳</span>
                            </a>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item">
                        <div className="field">備註</div>
                        <div className="value">
                            <textarea name="" id="" cols="30" rows="10" value={remark} onChange={remarkChange} ></textarea>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item btn-area sp">
                        <div className="left">
                            <a class="btn btn-border" onClick={openModalH}>
                                <span>歷史紀錄</span>
                            </a>
                        </div>
                        <div className="right">

                            <a className="btn btn-border" onClick={() => OpenModal("modal-checkdel", 2)}>
                                <span>刪除訂單</span>
                            </a>
                            <a className="btn" onClick={submit}>
                                <span>確認修改</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-checkdel" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確定要刪除這筆訂單?</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" style={{ "min-width": "150px" }} onClick={() => CloseModal("modal-checkdel")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" style={{ "min-width": "150px" }} onClick={delData}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-success" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">修改成功</div>
                        <div className="btn-holder">
                            <a className="btn" data-close onClick={() => CloseModal("modal-success")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-success2" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" data-close onClick={() => CloseModal("modal-success", 3)}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error2" className="modal small">
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error2", 3)}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-h" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">歷史紀錄</h3>
                        <span className="close" onClick={() => CloseModal("modal-h")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <ul className="list-table">
                            <li className="lt-row lt-th">
                                <div className="flex-3">時間</div>
                                <div className="flex-3">產品細項</div>
                                <div className="flex-2">負責業務</div>
                                <div className="flex-1">幣別</div>
                                <div className="flex-1">數量</div>
                                <div className="flex-1">單價</div>
                                <div className="flex-2">說明</div>
                            </li>
                            {qryList.map((x) => (
                                <li class="lt-row">
                                    <div class="flex-3">{x.time}</div>
                                    <div class="flex-3">{x.productName2}</div>
                                    <div class="flex-2">{x.personInChargeName}</div>
                                    <div class="flex-1">{x.currencyName}</div>
                                    <div class="flex-1">{NumFormart(x.quantity)}</div>
                                    <div class="flex-1">{NumFormart(x.unitPrice)}</div>
                                    <div class="flex-2">{x.explaination}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderDetail;
