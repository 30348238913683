import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_AllCompany,
    API_GET_Customer3Name,
    API_GET_CustomerNickName,
    API_GET_PrepassDataList,
    API_GET_PrepassRecord,
} from "../../global/constants";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, InitJS } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const Prepaid_CustomerQry = () => {
    var history = useHistory();

    useEffect(() => {
        //把menu顏色置換
        document.getElementById("menuitem-Prepaid_CustomerQry").classList.add("active");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    //重新取得 客戶別 客戶別Title 客戶統編 List
    function getCustomer3Name(index) {
        
        API_GET_Customer3Name({
            customerName: document.getElementById("customerNameText").value,
            customerTitle: document.getElementById("customerTitleText").value,
            customerTaxId: document.getElementById("customerTaxIdText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                var dataName = [];
                var dataTitle = [];
                var dataTaxId = [];

                if (data.result != null && data.result.data != null) {
                    if (index == 1) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataName.length; j++) {
                                if (dataName[j].custName == data.result.data[i].custName) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataName.push({
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxId: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerNameArray(dataName);
                    } else if (index == 2) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTitle.length; j++) {
                                if (dataTitle[j].custTitle == data.result.data[i].custTitle) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTitle.push({
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxId: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerTitleArray(dataTitle);
                    } else if (index == 3) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTaxId.length; j++) {
                                if (dataTaxId[j].custTaxID == data.result.data[i].custTaxID) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTaxId.push({
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerTaxArray(dataTaxId);
                    }
                } else {
                    if (index == 1) {
                        setCustomerNameArray([{}]);
                    } else if (index == 2) {
                        setCustomerTitleArray([{}]);
                    } else if (index == 3) {
                        setCustomerTaxArray([{}]);
                    }
                }
            })
            .catch((err) => {
                 
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //重新取得 客戶簡稱
    function getCustomerNickName() {
        API_GET_CustomerNickName({
            input: document.getElementById("customerNickNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data.length > 0) {
                    setCustomerNickNameArray(data.result.data);
                }
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function getData() {
        API_GET_AllCompany({
            input: document.getElementById("customerNickNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setCompanyArray(data.result.data);
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //查詢列表
    const [qryList, setQryList] = useState([{}]);
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);

    const [F1401, setF1401] = useState();
    const [F1402, setF1402] = useState(1);
    //客戶別
    const [customerName, setCustomerName] = useState();
    const [customerNameArray, setCustomerNameArray] = useState([{}]);
    function customerNameChange(e) {
        setCustomerName(e.target.value);
        //console.log(e.target.value);
        getCustomer3Name(1);
    }
    //客戶簡稱
    const [customerNickName, setCustomerNickName] = useState();
    const [customerNickNameArray, setCustomerNickNameArray] = useState([{}]);
    function customerNickNameChange(e) {
        setCustomerNickName(e.target.value);
        getCustomerNickName();
    }
    //客戶抬頭
    const [customerTitle, setCustomerTitle] = useState();
    const [customerTitleArray, setCustomerTitleArray] = useState([{}]);
    function customerTitleChange(e) { 
        setCustomerTitle(e.target.value);
        getCustomer3Name(2);
    }
    //客戶統編
    const [customerTaxId, setCustomerTaxId] = useState();
    const [customerTaxArray, setCustomerTaxArray] = useState([{}]);
    function customerTaxIdChange(e) {
        var v = e.target.value.replace(/[^A-Za-z0-9]/g, '');
        setCustomerTaxId(v);
        getCustomer3Name(3);
    }
    function openModal1() {
        if (F1401 == undefined) {
            document.getElementById("companyDiv").classList.add("show-help");
            return false;
        } else {
            document.getElementById("companyDiv").classList.remove("show-help");
        }
        var dt = {
            companyId: F1401,
            customerName: document.getElementById("customerNameText").value,
            customerTitle: document.getElementById("customerTitleText").value,
            customerTaxId: document.getElementById("customerTaxIdText").value,
        };
        API_GET_PrepassDataList(dt)
            .then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setQryList(data.result.data);
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        OpenModal("modal-1");
    }
    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container narrow">
                    <h3 className="fz-B has-border">客戶預付保證金查詢</h3>
                </div>
            </div>
            <div className="container narrow">
                <form action="" className="form-container">
                    <div className="form-item must" id="companyDiv">
                        <div className="field">負責公司</div>
                        <div className="value">
                            {companyArray.map((x) => (
                                <div key={x.id}>
                                    <input
                                        type="radio"
                                        id={`f1401-${x.cmpID}`}
                                        name="f1401"
                                        value={x.cmpID}
                                        checked={F1401 == x.cmpID ? "checked" : ""}
                                        onClick={(e) => setF1401(x.cmpID)}
                                    />
                                    <label htmlFor={`f1401-${x.cmpID}`}>{x.cmpName}</label>
                                </div>
                            ))}
                        </div>
                        <div className="help-word">請選擇負責公司</div>
                    </div>

                    <div className="form-item must">
                        <div className="field">查詢項目</div>
                        <div className="value">
                            <div>
                                <input
                                    type="radio"
                                    id="f1402-1"
                                    name="f1402"
                                    value="1"
                                    checked={F1402 == 1 ? "checked" : ""}
                                    onClick={(e) => setF1402(1)}
                                />
                                <label htmlFor="f1402-1">預付金</label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    id="f1402-2"
                                    name="f1402"
                                    value="2"
                                    checked={F1402 == 2 ? "checked" : ""}
                                    onClick={(e) => setF1402(2)}
                                />
                                <label htmlFor="f1402-2">保證金</label>
                            </div>
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <hr />

                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">客戶別</div>
                        <div className="value">
                            <input id="customerNameText"  type="text"  onChange={customerNameChange} value={customerName} />
                            <ul className="datalist scrollbar">
                                {customerNameArray.map((x) => (
                                    <li
                                        data-value={x.custName}
                                        onClick={(e) => {
                                            setCustomerName(x.custName),
                                                setCustomerTitle(x.custTitle),
                                                setCustomerTaxId(x.custTaxId);
                                        }}
                                    >
                                        <span className="flex-4 bold">{x.custName}</span>
                                        <span className="flex-4 bold">{x.custTitle}</span>
                                        <span className="flex-4 bold">{x.custTaxId}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">客戶簡稱</div>
                        <div className="value">
                            <input
                                id="customerNickNameText"
                                type="text"
                                onChange={customerNickNameChange}
                                value={customerNickName}
                            />
                            <ul className="datalist scrollbar">
                                {customerNickNameArray.map((x) => (
                                    <li
                                        data-value={x.custNickName}
                                        onClick={(e) => setCustomerNickName(x.custNickName)}
                                    >
                                        <span className="flex-10 bold">{x.custNickName}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">公司抬頭</div>
                        <div className="value">
                            <input id="customerTitleText" type="text" onChange={customerTitleChange} value={customerTitle}  />
                            <ul className="datalist scrollbar">
                                {customerTitleArray.map((x) => (
                                    <li
                                        data-value={x.custTitle}
                                        onClick={(e) => {
                                            setCustomerName(x.custName),
                                                setCustomerTitle(x.custTitle),
                                                setCustomerTaxId(x.custTaxId);
                                        }}
                                    >
                                        <span className="flex-4 bold">{x.custName}</span>
                                        <span className="flex-4 bold">{x.custTitle}</span>
                                        <span className="flex-4 bold">{x.custTaxId}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入公司抬頭</span>
                    </div>
                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">公司統編 / 編號</div>
                        <div className="value">
                            <input  id="customerTaxIdText"  type="text"   onChange={customerTaxIdChange}   value={customerTaxId}  maxLength="20" />
                            <ul className="datalist scrollbar">
                                {customerTaxArray.map((x) => (
                                    <li
                                        data-value={x.custTaxID}
                                        onClick={(e) => {
                                            setCustomerName(x.custName),
                                                setCustomerTitle(x.custTitle),
                                                setCustomerTaxId(x.custTaxId);
                                        }}
                                    >
                                        <span className="flex-4 bold">{x.custName}</span>
                                        <span className="flex-4 bold">{x.custTitle}</span>
                                        <span className="flex-4 bold">{x.custTaxID}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item btn-area sp">
                        <div className="left"></div>
                        <div className="right">
                            <a className="btn" onClick={openModal1}>
                                <span>查詢</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
            <div id="modal-1" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">查詢結果</h3>
                        <span className="close" onClick={ ()=>CloseModal("modal-1")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <ul className="list-table">
                            <li className="lt-row lt-th">
                                <div className="flex-1">客戶別</div>
                                <div className="flex-2">客戶簡稱</div>
                                <div className="flex-2">公司抬頭</div>
                                <div className="flex-2">統編/ 編號</div>
                            </li>
                            {qryList.map((x) => (
                                <li className="lt-row">
                                    <div className="flex-1">
                                        <a onClick={() => history.push(`/Main?path=Prepaid_CustomerView&type=${F1402}&customerId=${x.custID}`)} style={{ "cursor": "pointer" }}>
                                            {x.custName}
                                        </a>
                                    </div>
                                    <div className="flex-2">{x.custNickName}</div>
                                    <div className="flex-2">{x.custTitle}</div>
                                    <div className="flex-2">{x.custTaxID}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Prepaid_CustomerQry;
