import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_CloudAccountingGetProductsA,
    API_GET_CloudAccountingAdjust,
    API_POST_CloudAccountingAdjust
} from "../../global/constants";
import { InitJS, OpenModal, CloseModal, SetOnbeforeunload, MenuSet, SetHasChange } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const CATAdjust = () => {
    var history = useHistory();
    var onbeforeunloadFlag = false;
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        //把menu顏色置換
        MenuSet("CATRowSetQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    function getData() {
        API_GET_CloudAccountingGetProductsA()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setProductName2Array(data.result.data);
                }

            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [productName2Array, setProductName2Array] = useState([{}]);
    const [productNameId, setProductNameId] = useState();
    const [accountIdField, setAccountIdField] = useState();
    const [serviceItemsField, setServiceItemsField] = useState();
    const [currencyField, setCurrencyField] = useState();
    const [incomeField, setIncomeField] = useState();
    const [costField, setCostField] = useState();
    const [reportFieldArray, setReportFieldArray] = useState([{}]);
    const [details, setDetails] = useState([]);
    const [modalStr, setModalStr] = useState('');
    function productIdChange(e) {
        
        if (e.target.value == "") {
            setProductNameId(null);
        } else {
            setProductNameId(e.target.value);
            //呼叫API 12-9
            API_GET_CloudAccountingAdjust({
                productId: parseInt(e.target.value)
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        if (data.result.data.details != null) {
                            setDetails(data.result.data.details);
                        }
                       
                        setAccountIdField(data.result.data.accountIdField);
                        setServiceItemsField(data.result.data.serviceItemsField);
                        setCurrencyField(data.result.data.currencyField);
                        setIncomeField(data.result.data.incomeField);
                        setCostField(data.result.data.costField);
                        setReportFieldArray(data.result.data.reportField);
                    }
                })
                .catch((err) => {
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
        //console.log(costingFormula);

    }

    function AddData() {
         
        setDetails(function (prev) {
            return [...prev,
            {
                accountId: "",
                serviceItems: "",
                currency: "",
                income: "",
                cost: ""
            }];
        });
    }
    function DelData(index) { 
        setDetails(function (prev) {
            return prev.filter((x, i) => i != index);
        });
    }
    function detailsChange(e, index, col) {
       
        setDetails(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "accountId") {
                        item.accountId = e.target.value;
                    } else if (col == "serviceItems") {
                        item.serviceItems = e.target.value;
                    } else if (col == "currency") {
                        item.currency = e.target.value;
                    } else if (col == "income") {
                        item.income = e.target.value;
                    } else if (col == "cost") {
                        item.cost = e.target.value;
                    } 

                }
                return item;
            });
        });
    }
    function SaveData() {
        var dt = {
            productId:parseInt(productNameId),
            accountIdField: accountIdField,
            serviceItemsField: serviceItemsField,
            currencyField: currencyField,
            incomeField: incomeField,
            costField: costField,
            details: details,
        };
        //console.log(JSON.stringify(dt));
        API_POST_CloudAccountingAdjust(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    window.onbeforeunload = null;
                    onbeforeunloadFlag = false;
                    SetHasChange(false);
                    OpenModal("modal-ok");
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    function reflash() {
        window.location.reload();
    }
    return (
        <>
            <div className="content-body">
                <div className="title-area">
                    <div className="container full">
                        <h3 className="fz-B has-border">手動調帳</h3>
                    </div>
                </div>
                <div className="container full">
                    <form action="" className="form-container">
                        <div className="form-item half">
                            <div className="field">雲端細項</div>
                            <div className="value">
                                <select onChange={productIdChange}>
                                    <option value="">請選擇</option>
                                    {
                                        productName2Array.map(x =>
                                            <option value={x.id}>{x.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <span className="help-word">
                                請選擇雲端細項
                            </span>
                        </div>
                        <div className="form-item half jcfe">
                            {/*  幣別：新台幣*/}
                        </div>
                    </form>

                    <ul className="list-table">
                        <li className="lt-row lt-th">
                            <div className="flex-3 center"></div>
                            <div className="flex-5">帳號 ID</div>
                            <div className="flex-5">服務項目</div>
                            <div className="flex-3">幣別</div>
                            <div className="flex-3">營收</div>
                            <div className="flex-3">成本</div>
                            <div className="flex-1 fn max1"></div>
                        </li>
                        <li className="lt-row">
                            <div className="flex-3 center">
                                <strong className="fc-black fw-bold">報表欄位名稱</strong>
                            </div>
                            <div className="flex-5">
                                <select name="" id="" onChange={(e) => setAccountIdField(e.target.value)}>
                                    <option value="">請選擇</option>
                                    {
                                        reportFieldArray.map(x =>
                                            x.name == '' ? null :
                                                <option value={x.id} selected={x.id == accountIdField ? true :false }>{x.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="flex-5">
                                <select name="" id="" onChange={(e) => setServiceItemsField(e.target.value)}>
                                    <option value="">請選擇</option>
                                    {
                                        reportFieldArray.map(x =>
                                            x.name == '' ? null :
                                                <option value={x.id} selected={x.id == serviceItemsField ? true : false}>{x.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="flex-3">
                                <select name="" id="" onChange={(e) => setCurrencyField(e.target.value)}>
                                    <option value="">請選擇</option>
                                    {
                                        reportFieldArray.map(x =>
                                            x.name == '' ? null :
                                                <option value={x.id} selected={x.id == currencyField ? true : false}>{x.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="flex-3">
                                <select name="" id="" onChange={(e) => setIncomeField(e.target.value)}>
                                    <option value="">請選擇</option>
                                    {
                                        reportFieldArray.map(x =>
                                            x.name == '' ? null :
                                                <option value={x.id} selected={x.id == incomeField  ? true : false}>{x.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="flex-3">
                                <select name="" id="" onChange={(e) => setCostField(e.target.value)}>
                                    <option value="">請選擇</option>
                                    {
                                        reportFieldArray.map(x =>
                                            x.name == '' ? null :
                                                <option value={x.id} selected={x.id == costField ? true : false}>{x.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="flex-1 fn max1"></div>
                        </li>
                        {
                            details.map((x,i) =>
                                <li className="lt-row ovv">
                                    <div className="flex-3 center"></div>
                                    <div className="flex-5">
                                        <input type="text" value={x.accountId} onChange={(e) => detailsChange(e, i,"accountId")} />
                                    </div>
                                    <div className="flex-5">
                                        <input type="text" value={x.serviceItems} onChange={(e) => detailsChange(e, i, "serviceItems")} />
                                    </div>
                                    <div className="flex-3">
                                        <input type="text" value={x.currency} onChange={(e) => detailsChange(e, i, "currency")} />
                                    </div>
                                    <div className="flex-3">
                                        <input type="number" value={x.income} onChange={(e) => detailsChange(e, i, "income")} />
                                    </div>
                                    <div className="flex-3">
                                        <input type="number" value={x.cost} onChange={(e) => detailsChange(e, i, "cost")} />
                                    </div>
                                    <div className="flex-1 fn max1">
                                        <div className="btn btn-icon" onClick={ ()=>DelData(i)}>
                                            <i className="icon icon-37"></i>
                                        </div>
                                    </div>
                                </li>
                            )
                        }

                    </ul>
                    <br />
                    <div className="btn-area">
                        <div class="info"></div>
                        <div className="btn btn-small btn-border" onClick={AddData}>
                            <span>新增一筆</span>
                        </div>
                    </div>


                    <div className="form-item btn-area sp">
                        <div className="left">

                        </div>
                        <div className="right">

                            <a className="btn" onClick={SaveData}>
                                <span>確定</span>
                            </a>
                        </div>
                    </div>


                </div>
            </div>
            <div id="modal-ok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            儲存成功
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={reflash}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CATAdjust;
