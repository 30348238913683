import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_StatementHome,
    API_GET_AdNameList,
    API_GET_Customer3Name,
    API_GET_ProductName1,
    API_GET_SupplierNameList,
    //API_GET_GetExRates,
    API_GET_CurrencyComExchangeRate,
} from "../../global/constants";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, InitJS, MenuSet, SetOnbeforeunload } from '../../global/ToolUtil';
import { CheckResponseCode } from '../../global/ResponseUtil';
const StatementNew = () => {
    var history = useHistory();

    const [reGet, setReGet] = useState(false);

    useEffect(() => {
        //把menu顏色置換
        MenuSet("StatementNew");
        getdata();
        //預設為台灣果核
        setCompanyId(0);
        const timeout = setTimeout(() => {
            InitJS();
            //SetOnbeforeunload();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    //負責PM
    function getAdName() {
        API_GET_AdNameList({
            input: document.getElementById("adNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setAdNameArray(data.result.data);
                }

            })
            .catch((err) => {
                //
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
    }
    var moment = require("moment");

    function getdata() {
        API_GET_StatementHome({
            companyId: companyId
        })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //營收公司
                    setCompanyArray(data.result.data.datacard.companyArray);
                    //產品別
                    setProductTypeArray(data.result.data.datacard.productTypeArray);
                    //幣別
                    setCurrencyBaseList(data.result.data.datacard.currencyBaseList);
                    //入賬月份
                    setMonthSettleDaysArray(data.result.data.yearMonthList);
                    //預設第一個
                    setIncomeMonth(data.result.data.incomeMonth);
                }
            })
            .catch((err) => {
                //
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //負責業務
    const [adNameArray, setAdNameArray] = useState([{}]);
    const [adName, setAdName] = useState();
    const [adID, setAdID] = useState();
    function adNameChange(e) {
        if (e.target.value == "") {
            setAdID(null);
            setAdName("");
        } else {
            setAdName(e.target.value);
            getAdName();
        }

    }
    //幣別
    const [currencyBaseList, setCurrencyBaseList] = useState([{}]);
    //營收公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyError, setCompanyError] = useState(false);
    const [companyId, setCompanyId] = useState();

    useEffect(() => {
        setReGet(true);
    }, [companyId])

    useEffect(() => {
        if (reGet == true) {
            clearData();
            getdata();
            setReGet(false);
        }
    }, [reGet])

    //客戶別
    const [custID, setCustID] = useState();
    const [custName, setCustName] = useState("");
    const [custNameArray, setCustNameArray] = useState([{}]);
    function custNameChange(e) {
        setCustName(e.target.value);
        getCustomer3Name(1);
    }
    //客戶抬頭
    const [custTitle, setCustTitle] = useState("");
    const [custTitleArray, setCustomerTitleArray] = useState([{}]);
    function custTitleChange(e) {
        setCustTitle(e.target.value);
        getCustomer3Name(2);
    }
    const [custTaxID, setCustTaxID] = useState("");
    const [custTaxIDArray, setCustomerTaxArray] = useState([{}]);
    function custTaxIDChange(e) {
        setCustTaxID(e.target.value);
        getCustomer3Name(3);
    }

    //產品別
    const [productTypeArray, setProductTypeArray] = useState([{}]);
    //產品別ID
    const [prodTPID, setProdTPID] = useState();
    //產品別Name
    const [prodTPName, setProdTPName] = useState("");
    //產品細項總稱
    const [productName, setProductName] = useState("");
    const [productNameArray, setProductNameArray] = useState([{}]);
    //入賬月份清單
    const [monthSettleDaysArray, setMonthSettleDaysArray] = useState([]);
    //供應商名稱
    const [supplierName, setSupplierName] = useState("");
    function supplierNameChange(e) {
        setSupplierName(e.target.value);
        getSupplierName();
    }
    //供應商名稱datalist
    const [supplierNameArray, setSupplierNameArray] = useState([{}]);
    function getSupplierName(e) {
        API_GET_SupplierNameList({
            input: document.getElementById("supplierNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setSupplierNameArray(data.result.data);
                }
            })
            .catch((err) => {

            });
    }

    const [incomeMonth, setIncomeMonth] = useState();

    const [currencyId, setCurrencyId] = useState(5);
    //modal文字
    const [modalStr, setModalStr] = useState();

    //重新取得 客戶別 客戶別Title 客戶統編 List
    function getCustomer3Name(index) {
        var dt = [];
        if (index == 1) {
            dt = {
                customerName: document.getElementById("customerNameText").value
            }
        }
        else if (index == 2) {
            dt = {
                customerTitle: document.getElementById("customerTitleText").value
            }

        }
        else if (index == 3) {
            dt = {
                customerTaxId: document.getElementById("customerTaxIdText").value,
            }
        }
        API_GET_Customer3Name(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                var dataName = [];
                var dataTitle = [];
                var dataTaxId = [];

                if (data.result != null && data.result.data != null) {
                    if (index == 1) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataName.length; j++) {
                                if (dataName[j].custName == data.result.data[i].custName) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataName.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustNameArray(dataName);
                    } else if (index == 2) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTitle.length; j++) {
                                if (dataTitle[j].custTitle == data.result.data[i].custTitle) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTitle.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxId: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerTitleArray(dataTitle);
                    } else if (index == 3) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTaxId.length; j++) {
                                if (dataTaxId[j].custTaxID == data.result.data[i].custTaxID) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTaxId.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerTaxArray(dataTaxId);
                    }
                }
            })
            .catch((err) => {

            });
    }

    function productNameChange(e) {
        if (e.target.value != '') {
            ////呼叫共用G
            API_GET_ProductName1({
                input: e.target.value,
                prodTPID: prodTPID,
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        setProductNameArray(data.result.data);
                    }
                });
        }
    }
    const [currencyComExchangeRateArray, setCurrencyComExchangeRateArray] = useState([{}]);

    useEffect(() => {
        API_GET_CurrencyComExchangeRate({
            companyId: companyId,
            ym: incomeMonth
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push('/');
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setCurrencyComExchangeRateArray(data.result.data);
                var arr = data.result.data;
                if (arr.length > 0) {
                    for (var i = 0; i < arr; i++) {
                        if (arr[i].exRateRate == 1) {
                            setCurrencyId(arr[i].exCurrID);
                        }
                    }
                }
            })
    }, [companyId, incomeMonth])


    function goItem() {
        var path = "/Main?path=StatementItem&companyId=" + companyId;
        if (incomeMonth != undefined) {
            path += "&incomeMonth=" + incomeMonth;
        }
        if (custName != null) {
            path += "&customerName=" + custName.replace(/&/g, "%26");
        }
        if (custTitle != null) {
            path += "&customerTitle=" + custTitle.replace(/&/g, "%26");
        }
        if (custTaxID != null) {
            path += "&customerTaxId=" + custTaxID;
        }

        if (prodTPID != null) {
            path += "&productId=" + prodTPID;
        }
        if (productName != null) {
            path += "&productName1=" + productName.replace(/&/g, "%26");
        }
        if (supplierName != null) {
            path += "&supplierName=" + supplierName.replace(/&/g, "%26");
        }
        //if (adName != null) {
        //    path += "&personInChargeName=" + adName;
        //}
        if (adID != null) {
            path += "&personInChargeID=" + adID;
        }
        //console.log(path);
        history.push(path);
    }

    function clearData() {
        setAdNameArray([]);
        setAdName("");
        setAdID("");
        setCurrencyBaseList([]);
        setCustID("");
        setCustName("");
        setCustNameArray([]);
        setCustTitle("");
        setCustomerTitleArray([]);
        setCustTaxID("");
        setCustomerTaxArray([]);
        setProductTypeArray([]);
        setProdTPID("");
        setProductName("");
        setProductNameArray([]);
        setMonthSettleDaysArray([]);
        setSupplierName("");
        setSupplierNameArray([]);
        setIncomeMonth("");
    }
    
    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">建立對帳單</h3>
                </div>
            </div>
            <div className="container">
                <div className="form-container">
                    <div className={`form-item must ${companyError ? 'show-help' : ''}`}>
                        <div className="field">營收公司</div>
                        <div className="value">
                            {
                                companyArray.map(x =>
                                    <div>
                                        <input type="radio" id={`company_${x.id}`} name="company" value={x.id} onClick={e => setCompanyId(x.id)} checked={companyId == x.id ? true : false} />
                                        <label htmlFor={`company_${x.id}`}>{x.name}</label>
                                    </div>
                                )
                            }
                        </div>
                        <span className="help-word">
                            請選擇營收公司
                        </span>
                    </div>
                    <div className="form-item half must">
                        <div className="field">入帳月份</div>
                        <div className="value">
                            <select name="" id="" onChange={e => setIncomeMonth(e.target.value)}>
                                {
                                    monthSettleDaysArray.map(x =>
                                        <option value={x} selected={x == incomeMonth ? true : false}> {x} </option>
                                    )

                                }
                            </select>
                        </div>
                        <div className="help-word">
                            請輸入訂單編號
                        </div>
                    </div>

                    <div className="form-item half has-datalist" data-datalist>
                        <div className="field">客戶別</div>
                        <div className="value">
                            <input
                                id="customerNameText"
                                type="text"
                                value={custName}
                                onChange={custNameChange}
                                data-required
                            />
                            <ul className="datalist scrollbar">
                                {custNameArray.map((x) => (
                                    <li
                                        data-value={x.custName} onClick={(e) => { setCustName(x.custName), setCustTitle(x.custTitle), setCustTaxID(x.custTaxID), setCustID(x.custID); }}>
                                        <span className="flex-4 bold">{x.custName}</span>
                                        <span className="flex-3">{x.custTitle}</span>
                                        <span className="flex-3">{x.custTaxID}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item half  has-datalist" data-datalist>
                        <div className="field">公司抬頭</div>
                        <div className="value">
                            <input id="customerTitleText" type="text" onChange={custTitleChange} value={custTitle} />
                            <ul className="datalist scrollbar">
                                {custTitleArray.map((x) => (
                                    <li data-value={x.custTitle} onClick={(e) => { setCustName(x.custName), setCustTitle(x.custTitle), setCustTaxID(x.custTaxId); }}>
                                        <span className="flex-10 bold">{x.custTitle}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item  half  has-datalist" data-datalist>
                        <div className="field">公司統編/ 編號</div>
                        <div className="value">
                            <input id="customerTaxIdText" type="text" onChange={custTaxIDChange} value={custTaxID} />
                            <ul className="datalist scrollbar">
                                {custTaxIDArray.map((x) => (
                                    <li data-value={x.custTaxID} onClick={(e) => { setCustName(x.custName), setCustTitle(x.custTitle), setCustTaxID(x.custTaxId); }}>
                                        <span className="flex-10 bold">{x.custName}</span>

                                        {/*<span className="flex-2">12345678</span>*/}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item  half">
                        <div className="field">產品別</div>
                        <div className="value">
                            <select id="productDataList" onChange={e => setProdTPID(e.target.value)}>
                                <option value="" selected> 請選擇 </option>
                                {productTypeArray.map((x) => (
                                    <option value={x.id}> {x.name} </option>
                                ))}
                            </select>
                        </div>
                        <span className="help-word">請選擇產品別</span>
                    </div>

                    <div className="form-item half has-datalist" data-datalist>
                        <div className="field">供應商名稱</div>
                        <div className="value">
                            <input id="supplierNameText" type="text" onChange={supplierNameChange} value={supplierName} />
                            <ul className="datalist scrollbar">
                                {supplierNameArray.map((x) => (
                                    <li data-value={x.name} onClick={(e) => { setSupplierName(x.name); }}>
                                        <span className="flex-10 bold">{x.name}</span>

                                        {/*<span className="flex-2">12345678</span>*/}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>


                    <div className="form-item half has-datalist" data-datalist>
                        <div className="field">產品細項總稱</div>
                        <div className="value">
                            <input id="productName" type="text" onChange={productNameChange} />
                            <ul className="datalist scrollbar">
                                {productNameArray.map((x) => (
                                    <li data-value={x.productName1} onClick={(e) => { setProductName(x.productName1); }}>
                                        <span className="flex-10 bold">{x.productName1}</span>
                                        {/*<span className="flex-2">12345678</span>*/}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請選擇產品細項總稱</span>
                    </div>
                    <div className="form-item half has-datalist" data-datalist>
                        <div className="field">負責業務</div>
                        <div className="value multi-inline">
                            <input id="adNameText" type="text" onChange={adNameChange} autocomplete="off" />
                            <ul className="datalist scrollbar">
                                {adNameArray.map((x) => (
                                    <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => { setAdName(x.enName), setAdID(x.id); }} >
                                        <span className="flex-4">{x.enName}({x.tc_name})</span>
                                        <span className="flex-5">{x.mail}</span>


                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <hr />

                    <div className="form-item">
                        <div className="field">目前匯率</div>
                        <div className="value multi-inline impwarp">
                            {
                                currencyBaseList.map(x =>
                                    x.id != currencyId ? //不是自己
                                        <div className="box currency-box">
                                            <span>{x.name}</span>
                                            <div className="info currency-item">
                                                <span>

                                                    {
                                                        currencyComExchangeRateArray != null ?
                                                            currencyComExchangeRateArray.find(obj => { return obj.exCurrID == x.id }) != null ?
                                                                currencyComExchangeRateArray.find(obj => { return obj.exCurrID == x.id }).exRateRate
                                                                : '' :
                                                            null
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        : null
                                )
                            }
                        </div>
                    </div>
                    <div className="form-item btn-area sp">
                        <div className="left">

                        </div>
                        <div className="right">
                            <a className="btn" onClick={goItem}>
                                <span>顯示項目</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatementNew;
