import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_AllCompany,
    API_GET_AdNameList,
    API_GET_SupplierNameList,
    API_GET_SupplierTitleList,
    API_GET_VendorDataCard,
    API_GET_VendorList,
} from "../../global/constants";
import { checkAuthToken } from "../../global/TokenUtil";
import { CloseModal, OpenModal, InitJS, MenuSet } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const SupplierQry = () => {
    var history = useHistory();

    useEffect(() => {
        //把menu顏色置換
        MenuSet("SupplierQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);


    function getData() {
        API_GET_VendorDataCard()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //console.log(data);
                    //負責公司
                    setCompanyArray(data.result.companyArray);
                }
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //重新取得 供應商抬頭 List
    function getSupplierTitleList() {
        API_GET_SupplierTitleList({
            input: document.getElementById("supplierTitleText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setSupplierTitleArray(data.result.data);
                }
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //負責PM
    function getAdName() {
        API_GET_AdNameList({
            input: document.getElementById("adNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setAdNameArray(data.result.data);
                }
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    function getSupplierName(e) {
        API_GET_SupplierNameList({
            input: document.getElementById("supplierNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setSupplierNameArray(data.result.data);
                }
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyIdArray, setCompanyIdArray] = useState([]);
    function companyIdArrayChange() {
        var tmpArray = [];
        var f811 = document.getElementsByName("f811");
        for (var i = 0; i < f811.length; i++) {
            if (f811[i].checked) {
                tmpArray.push(parseInt(f811[i].value));
            }
        }
        setCompanyIdArray(tmpArray);
    }
    //供應商名稱
    const [supplierName, setSupplierName] = useState("");
    function supplierNameChange(e) {
        setSupplierName(e.target.value);
        getSupplierName();
    }
    //供應商名稱datalist
    const [supplierNameArray, setSupplierNameArray] = useState([{}]);

    //供應商抬頭
    const [supplierTitle, setSupplierTitle] = useState();
    const [supplierTitleArray, setSupplierTitleArray] = useState([{}]);
    function supplierTitleChange(e) {
        //console.log(e.target.value);
        setSupplierTitle(e.target.value);
        getSupplierTitleList();
    }
    //查詢列表
    const [qryList, setQryList] = useState([]);
    //產品負責人
    const [adName, setAdName] = useState("");
    const [adID, setAdId] = useState("");
    function adNameChange(e) {
        setAdName(e.target.value);
        getAdName();
    }
    //產品負責人datalist
    const [adNameArray, setAdNameArray] = useState([{}]);

    //統編
    const [venBIZNo, setVenBIZNo] = useState("");
    function venBIZNoChange(e) {
        var v = e.target.value.replace(/[^A-Za-z0-9]/g, '');
        setVenBIZNo(v);
    }

    

    //打開查詢列表
    function openModal1() {
        var tmpArrStr = "";
        var bf = false;
        var f = document.getElementsByName("f811");
        var ss = 0;
        for (var i = 0; i < f.length; i++) {
            if (f[i].checked) {
                bf = true;
                tmpArrStr = tmpArrStr + "|" + f[i].value;
            }
        }
        if (bf == false) {
            document.getElementById("companyDiv").classList.add("show-help");
            return false;
        } else {
            document.getElementById("companyDiv").classList.remove("show-help");
        }

        if (tmpArrStr.substring(0, 1) == "|") {
            tmpArrStr = tmpArrStr.replace(/^./, "");
        }

        API_GET_VendorList({
            companyId: tmpArrStr,
            vendorName: document.getElementById("supplierNameText").value,
            vendorTitle: document.getElementById("supplierTitleText").value,
            //personInChargeName: document.getElementById("adNameText").value,
            personInChargeID : adID,
            vendorTaxID: venBIZNo,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setQryList(data.result.data);
                    OpenModal("modal-1");
                }
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
       
    }
    function goEdit(vendorName, reqFrmID, companyId, vendorId) {
        var vName = vendorName;
        var path = "/Main?path=SupplierEdit&vName=" + vName.replace("&", "%26") + "&reqFrmID=" + reqFrmID + "&companyId=" + companyId + "&id=" + vendorId;
        history.push(path);
    }
    function goNew(vendorName, reqFrmID) {
        var vName = vendorName;
        var path = "/Main?path=SupplierNew&vName=" + vName.replace("&", "%26") + "&reqFrmID=" + reqFrmID + "&fromCopy=1";
        history.push(path);
    }
    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container narrow">
                    <h3 className="fz-B has-border">查詢供應商資料</h3>
                </div>
            </div>
            <div className="container narrow">
                <form action="" className="form-container">
                    <div className="form-item must" id="companyDiv">
                        <div className="field">負責公司</div>
                        <div className="value">
                            {companyArray.map((x) => (
                                <div key={x.id}>
                                    <input
                                        type="checkbox"
                                        id={`f811-${x.cmpID}`}
                                        name="f811"
                                        value={x.cmpID}
                                        onClick={companyIdArrayChange}
                                        key={x.cmpID}
                                    />
                                    <label htmlFor={`f811-${x.cmpID}`}>{x.cmpName}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇負責公司</span>
                    </div>

                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">供應商</div>
                        <div className="value">
                            <input
                                id="supplierNameText"
                                type="text"
                                onChange={supplierNameChange}
                            />
                            <ul className="datalist scrollbar">
                                {supplierNameArray.map((x) => (
                                    <li
                                        data-value={x.name}
                                        onClick={(e) => {
                                            setSupplierName(x.name);
                                        }}
                                    >
                                        <span className="flex-10 bold">{x.name}</span>

                                        {/*<span className="flex-2">12345678</span>*/}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">供應商抬頭</div>
                        <div className="value">
                            <input
                                id="supplierTitleText"
                                type="text"
                                onChange={supplierTitleChange}
                                value={supplierTitle}
                            />
                            <ul className="datalist scrollbar">
                                {supplierTitleArray.map((x) => (
                                    <li
                                        data-value={x.venTitle}
                                        onClick={(e) => {
                                            setSupplierTitle(x.venTitle);
                                        }}
                                        key={x.venID}
                                    >
                                        <span className="flex-5 bold">{x.venName}</span>
                                        <span className="flex-5">{x.venTitle}</span>
                                        {/*<span className="flex-2">12345678</span>*/}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item has-datalist" data-datalist>
                        <div className="field">負責PM</div>
                        <div className="value">
                            <input id="adNameText" type="text" autocomplete="off" onChange={adNameChange} />
                            <ul className="datalist scrollbar">
                                {adNameArray.map((x) => (
                                    <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => { setAdName(x.enName); setAdId(x.id)}}  >
                                        <span className="flex-4">{x.enName}({x.tc_name})</span>
                                        <span className="flex-5">{x.mail}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item has-datalist">
                        <div className="field">統一編號</div>
                        <div className="value">
                            <input id="venBIZNo" type="text" onChange={venBIZNoChange} />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    {/*<div className="form-item">*/}
                    {/*    <div className="field">統編/編號</div>*/}
                    {/*    <div className="value">*/}
                    {/*        <input value={venBIZNo} type="text" autoComplete="off" onChange={venBIZNoChange} />*/}
                    {/*    </div>*/}
                    {/*    <span className="help-word">請輸入正確格式</span>*/}
                    {/*</div>*/}

                    <div className="form-item btn-area sp">
                        <div className="left"></div>
                        <div className="right">
                            <a className="btn" onClick={openModal1}>
                                <span>查詢</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
            <div id="modal-1" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">查詢結果</h3>
                        <span className="close" onClick={() => CloseModal("modal-1")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <form action="">
                            <ul className="list-table">
                                <li className="lt-row lt-th">
                                    <div className="flex-2">負責公司</div>
                                    <div className="flex-3">供應商</div>
                                    <div className="flex-3">供應商抬頭</div>
                                    {/*<div className="flex-2">統編/編號</div>*/}
                                    <div className="flex-5">負責PM</div>
                                    <div className="flex-2 fn"></div>
                                </li>
                                {
                                    qryList.length == 0 ? null :
                                        qryList.map((x) => (
                                            <li className="lt-row">
                                                <div className="flex-2">{x.companyName}</div>
                                                <div className="flex-3">
                                                    <a href="#" onClick={() => goEdit(x.vendorName, x.reqFrmID, x.companyId, x.vendorID)}>
                                                        {x.vendorName}
                                                    </a>

                                                </div>
                                                <div className="flex-3">
                                                    {x.title}
                                                </div>
                                                <div className="flex-5">{x.pmName}</div>
                                                {/*<div className="flex-5">{x.venBIZNo}</div>*/}
                                                <div className="flex-2 fn">
                                                    <div className="fn-bar">
                                                        <div className="btn btn-icon" onClick={() => { goNew(x.vendorName, x.reqFrmID) }}>
                                                            <i className="icon icon-29"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupplierQry;
