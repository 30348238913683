import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_POST_CloudAccountingCustSearch,
} from "../../global/constants";
import { OpenModal, CloseModal, InitJS, NumFormart, MenuSet } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const CATQryList1 = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        //把menu顏色置換
        MenuSet("CATRowSetQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    function getData() {
        var dt = {
            companyId: parseInt(queryParams.get("companyId")),
            customerId: parseInt(queryParams.get("customerId")),
            startMonth: queryParams.get("startMonth"),
            endMonth: queryParams.get("endMonth"),
        };
        API_POST_CloudAccountingCustSearch(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setCompanyName(data.result.data.companyName)
                    setCustomerName(data.result.data.customerName)
                    setCustomerTaxId(data.result.data.customerTaxId)
                    setDataList(data.result.data.data)
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {
               
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }
    const [modalStr, setModalStr] = useState();
    const [dataList, setDataList] = useState([]);
    const [companyName, setCompanyName] = useState();
    const [customerName, setCustomerName] = useState();
    const [customerTaxId, setCustomerTaxId] = useState();
    return (
        <>
            <div class="content-body">
                <div class="title-area">
                    <div class="container full">
                        <h3 class="fz-B has-border">隸屬公司：{companyName}</h3>
                    </div>
                </div>
                <div class="container full">
                    <div class="info-holder">
                        <div class="left">
                            <span class="item">客戶別：{customerName}</span>
                            <span class="item">客戶抬頭：{customerTaxId}</span>
                        </div>
                        <div class="right">

                        </div>
                    </div>


                    <ul class="list-table">
                        <li class="lt-row lt-th">
                            <div class="flex-6">產品細項</div>
                            <div class="flex-6">月份</div>
                            <div class="flex-1 right">幣別</div>
                            <div class="flex-3 right">營收</div>
                            <div class="flex-3 right">成本</div>
                            <div class="flex-3 right">毛利</div>
                            <div class="flex-2 right">毛利率</div>
                        </li>
                        {
                            dataList == null && dataList.length == 0 ? null :
                                dataList.map(x =>
                                    <li class="lt-row">
                                        <div class="flex-6">
                                            <div class="elips-1">{x.productName2}</div>
                                        </div>

                                        <div class="flex-6">
                                            <div class="elips-1">{x.month} <span class="fz-small">{`(計費週期 ${x.month})`}</span> </div>
                                        </div>
                                        <div class="flex-1 right">
                                            <div class="elips-1">{x.currency}</div>
                                        </div>
                                        <div class="flex-3 right">
                                            <div class="elips-1">{NumFormart(x.income)}</div>
                                        </div>
                                        <div class="flex-3 right">
                                            <div class="elips-1">{NumFormart(x.cost)}</div>
                                        </div>
                                        <div class="flex-3 right">
                                            <div class="elips-1">{NumFormart(x.grossProfit)}</div>
                                        </div>
                                        <div class="flex-2 right">
                                            <div class="elips-1">{`(${x.grossProfitRate})%`}</div>
                                        </div>

                                    </li>
                                )
                        }
                    </ul>
                </div>
            </div>

            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CATQryList1;
