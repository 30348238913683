import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_CloudAccountingGetProducts,
    API_GET_CloudAccountingCost,
    API_POST_CloudAccountingCost
} from "../../global/constants";
import { InitJS, Clone, OpenModal, CloseModal, SetOnbeforeunload, MenuSet, SetHasChange } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const CATCostSet = () => {
    var history = useHistory();

    useEffect(() => {
        //把menu顏色置換
        MenuSet("CATRowSetQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    function getData() {
        API_GET_CloudAccountingGetProducts()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setProductName2Array(data.result.data.productName2Array);
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    const [productName2Array, setProductName2Array] = useState([{}]); //雲端細項名稱列表
    const [productNameId, setProductNameId] = useState(); //雲端細項id
    const [currencyName, setCurrencyName] = useState('');

    //#region 變更並取得雲端細項詳細資料
    function productIdChange(e) {
        setCurrencyName('');
        setCostingFormula([]);

        if (e.target.value == "") {
            setProductNameId(null);
        } else {
            setProductNameId(e.target.value);
            //呼叫API 12-4
            API_GET_CloudAccountingCost({
                productId: parseInt(e.target.value)
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        setCurrencyName(data.result.data.currencyName);
                        //第二層計費項目處理

                        var costingFormulaData = data.result.data.costingFormula;

                        if (costingFormulaData.length == 0) {
                            costingFormulaData = [{
                                options: -1,
                                rawDataA: null,
                                rawDataAId: null,
                                rawDataB: null,
                                rawDataBId: null,
                                billingItems: [{
                                    rawData: "",
                                    isNumber: 0,
                                    formulaSigns: "=",
                                    lowerLimit: null,
                                    upperLimit: null,
                                    value: "",
                                }]
                            }];
                        }
                        else {
                            costingFormulaData.forEach((item, index) => {
                                if (!item.billingItems) {
                                    item.billingItems = [{
                                        rawData: "",
                                        isNumber: 0,
                                        formulaSigns: "=",
                                        lowerLimit: null,
                                        upperLimit: null,
                                        value: "",
                                        canAdd: false
                                    }];
                                }
                            })
                        }

                        //if (data.result.data.costingFormula.length == 0 || data.result.data.costingFormula[0].billingItems == null || data.result.data.costingFormula[0].options == null) {
                        //    costingFormulaData = [{
                        //        options: -1,
                        //        rawDataA: null,
                        //        rawDataAId: null,
                        //        rawDataB: null,
                        //        rawDataBId: null,
                        //        billingItems: [{
                        //            rawData: null,
                        //            isNumber: 0,
                        //            formulaSigns: "=",
                        //            lowerLimit: null,
                        //            upperLimit: null,
                        //            value: null,
                        //        }]
                        //    }];
                        //} else {
                        //    for (var i = 0; i < costingFormulaData.length; i++) {
                        //        if (costingFormulaData[i].billingItems == null || costingFormulaData[i].billingItems.length == 0 || costingFormulaData[i].billingItems[0].id == null) {
                        //            costingFormulaData[i].billingItems = [{
                        //                rawData: null,
                        //                isNumber: 0,
                        //                formulaSigns: "<Y<",
                        //                lowerLimit: null,
                        //                upperLimit: null,
                        //                value: null,
                        //            }];
                        //        }
                        //    }
                        //}

                        setCostingFormula(costingFormulaData);
                        setRawDataArray(data.result.data.rawDataArray);
                    }
                    else {
                        setModalStr(data.message);
                        OpenModal("modal-error");
                    }
                })
                .catch((err) => {
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }
    //#endregion

    const [rawDataArray, setRawDataArray] = useState([{}]);
    const [costingFormula, setCostingFormula] = useState([]);
    const optionsArray = [
        { id: -1, name: "請選取計算方式" },
        { id: 0, name: "以選取的欄位當成本" },
        { id: 1, name: "選取的欄位 x 數字" },
        { id: 2, name: "固定數字" },
        { id: 3, name: "選取的欄位相乘" },
    ];

    //#region 修改計費項目資料
    function billingItemsChange(e, index, index2, col) {
        var newCostingFormula = [...costingFormula];
        var newbillingData = newCostingFormula[index].billingItems[index2];
        switch (col) {
            case "rawData":
                newbillingData.rawData = e.target.value;
                break;
            case "isNumber":
                newbillingData.isNumber = newbillingData.isNumber == 0 ? 1 : 0;
                newbillingData.formulaSigns = newbillingData.isNumber == 0 ? "=" : "<Y<";
                break;
            case "formulaSigns":
                newbillingData.formulaSigns = e.target.value;
                break;
            case "lowerLimit":
                newbillingData.lowerLimit = (e.target.value + "").replace(/[^0-9_.]/g, '');
                break;
            case "upperLimit":
                newbillingData.upperLimit = (e.target.value + "").replace(/[^0-9_.]/g, '');
                break;
            case "value":
                newbillingData.value = e.target.value;
                break;
        }
        newCostingFormula[index].billingItems[index2] = newbillingData;
        setCostingFormula(newCostingFormula);
    }
    //#endregion

    //#region 刪除計費項目資料
    function DelBillingItems(index, index2) {
        var newCostingFormula = [...costingFormula];
        var newBillingItems = [...newCostingFormula[index].billingItems];
        newBillingItems.splice(index2, 1);
        newCostingFormula[index].billingItems = newBillingItems;
        setCostingFormula(newCostingFormula);
    }
    //#endregion

    //#region 增加計費項目資料
    function AddBillingItems(index) {
        setCostingFormula(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    var dt = Clone(costingFormula[i].billingItems);
                    dt.push({
                        rawData: "",
                        isNumber: 0,
                        formulaSigns: "=",
                        lowerLimit: null,
                        upperLimit: null,
                        value: "",
                    });
                    item.billingItems = dt;

                }
                return item;
            });
        });
    }
    //#endregion

    //#region 修改成本計算式
    function costingFormulaChange(e, index, col) {
        setCostingFormula(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "options") {
                        item.options = e.target.value;
                        item.rawDataAId = null;
                        item.rawDataBId = null;
                        item.value = "";
                    } else if (col == "rawDataAId") {
                        item.rawDataAId = e.target.value;
                    } else if (col == "rawDataA") {
                        item.rawDataA = e.target.value;
                    } else if (col == "rawDataBId") {
                        item.rawDataBId = e.target.value;
                    } else if (col == "rawDataB") {
                        item.rawDataB = e.target.value;
                    } else if (col == "value") {
                        if (item.options == 1 || item.options == 2) {
                            let endsWith = e.target.value.endsWith('.');
                            var tmp = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
                            if (!isNaN(tmp)) {
                                if (!endsWith) {
                                    item.value = tmp;
                                }
                                else {
                                    item.value = tmp + ".";
                                }
                            }
                            else {
                                item.value = "";
                            }
                            //item.value = !isNaN(tmp) ? tmp : 0;
                        }
                        else {
                            item.value = e.target.value;
                        }
                    }
                }
                return item;
            });
        });
    }
    //#endregion

    //#region 新增條件算式
    function AddcostingFormula() {
        var newCostingFormula = [...costingFormula];

        newCostingFormula.push({
            options: -1,
            rawDataA: null,
            rawDataAId: null,
            rawDataB: null,
            rawDataBId: null,
            billingItems: [{
                rawData: "",
                isNumber: 0,
                formulaSigns: "=",
                lowerLimit: null,
                upperLimit: null,
                value: "",
            }]
        });

        setCostingFormula(newCostingFormula);
    }
    //#endregion

    //#region 刪除條件算式
    function DelcostingFormula(index) {
        var newCostingFormula = [...costingFormula];
        newCostingFormula.splice(index, 1);
        setCostingFormula(newCostingFormula);
    }
    //#endregion

    const [modalStr, setModalStr] = useState();

    //#region 確定按鈕
    function SaveData() {
        var result = checkData();
        if (result == false) {
            return false;
        }

        //#region 資料型態轉換
        let newCostingFormula = [...costingFormula];
        for (var i = 0; i < newCostingFormula.length; i++) {
            if (newCostingFormula[i].rawDataAId == "") {
                newCostingFormula[i].rawDataAId = null;
            }
            if (newCostingFormula[i].rawDataA == "") {
                newCostingFormula[i].rawDataA = null;
            }
            if (newCostingFormula[i].rawDataBId == "") {
                newCostingFormula[i].rawDataBId = null;
            }
            if (newCostingFormula[i].rawDataB == "") {
                newCostingFormula[i].rawDataB = null;
            }
            if (newCostingFormula[i].value == "") {
                newCostingFormula[i].value = null;
            }
            for (var j = 0; j < newCostingFormula[i].billingItems; j++) {
                if (newCostingFormula[i].billingItems[j].lowerLimit == "") {
                    newCostingFormula[i].billingItems[j].lowerLimit = null;
                }
                if (newCostingFormula[i].billingItems[j].upperLimit == "") {
                    newCostingFormula[i].billingItems[j].upperLimit = null;
                }
                if (newCostingFormula[i].billingItems[j].rawData == "") {
                    newCostingFormula[i].billingItems[j].rawData = null;
                }
                if (newCostingFormula[i].billingItems[j].value == "") {
                    newCostingFormula[i].billingItems[j].value = null;
                }
            }
        }
        //#endregion

        var dt = {
            productId: productNameId,
            costigFormula: newCostingFormula,
        };
        console.log(JSON.stringify(dt));
        API_POST_CloudAccountingCost(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    OpenModal("modal-ok");
                    window.onbeforeunload = null;
                    SetHasChange(false);
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //#endregion

    //#region 檢查所有雲端細項詳細資料(未使用)
    //const [canAddFormula, setCanAddFormula] = useState(true);
    //useEffect(() => {
    //    if (costingFormula && costingFormula.length > 0) {
    //        var tempCanAddFormula = true;
    //        var newCostingFormula = [...costingFormula];

    //        for (var costingFormulaIndex = 0; costingFormulaIndex < costingFormula.length; costingFormulaIndex++) {
    //            var selectCostingFormula = costingFormula[costingFormulaIndex];

    //            //#region 先判斷billingItem
    //            for (var itemIndex = 0; itemIndex < selectCostingFormula.billingItems.length; itemIndex++) {
    //                var selectbillingItem = selectCostingFormula.billingItems[itemIndex];

    //                //#region 判斷計費項目
    //                if (!selectbillingItem.rawData || selectbillingItem.rawData == "") {
    //                    selectCostingFormula.canAddBillingItem = false;
    //                    break;
    //                }
    //                //#endregion

    //                //#region 判斷值為數字
    //                if (selectbillingItem.isNumber == 1) { //值為數字
    //                    if (!selectbillingItem.formulaSigns || selectbillingItem.formulaSigns == "") {
    //                        selectCostingFormula.canAddBillingItem = false;
    //                        break;
    //                    }
    //                    else {
    //                        if (selectbillingItem.formulaSigns == "<Y<" ||
    //                            selectbillingItem.formulaSigns == "≦Y≦" ||
    //                            selectbillingItem.formulaSigns == "<Y≦" ||
    //                            selectbillingItem.formulaSigns == "≦Y<") {
    //                            if (!selectbillingItem.lowerLimit || selectbillingItem.lowerLimit == "") {
    //                                selectCostingFormula.canAddBillingItem = false;
    //                                break;
    //                            }
    //                            if (!selectbillingItem.upperLimit || selectbillingItem.upperLimit == "") {
    //                                selectCostingFormula.canAddBillingItem = false;
    //                                break;
    //                            }
    //                        }
    //                        else if (
    //                            selectbillingItem.formulaSigns == "Y=" ||
    //                            selectbillingItem.formulaSigns == "Y≦" ||
    //                            selectbillingItem.formulaSigns == "Y<") {
    //                            if (!selectbillingItem.upperLimit || selectbillingItem.upperLimit == "") {
    //                                selectCostingFormula.canAddBillingItem = false;
    //                                break;
    //                            }
    //                        }
    //                        else {
    //                            if (!selectbillingItem.lowerLimit || selectbillingItem.lowerLimit == "") {
    //                                selectCostingFormula.canAddBillingItem = false;
    //                                break;
    //                            }
    //                        }
    //                    }
    //                }
    //                else {
    //                    if (!selectbillingItem.formulaSigns || selectbillingItem.formulaSigns == "") {
    //                        selectCostingFormula.canAddBillingItem = false;
    //                        break;
    //                    }
    //                    if (!selectbillingItem.value || selectbillingItem.value == "") {
    //                        selectCostingFormula.canAddBillingItem = false;
    //                        break;
    //                    }
    //                }
    //                //#endregion
    //            }
    //            //#endregion

    //            //#region 判斷selectCostingFormula.canAddBillingItem
    //            if (!selectCostingFormula.canAddBillingItem) {
    //                //如果不能新增，那就直接false;
    //                tempCanAddFormula = false;
    //                newCostingFormula[costingFormulaIndex] = selectCostingFormula;
    //                break;
    //            }
    //            //#endregion

    //            //#region 判斷成本計算式
    //            if (selectCostingFormula.options < 0) {
    //                tempCanAddFormula = false;
    //                break;
    //            }
    //            //#endregion

    //            //#region 判斷成本計算式的內容
    //            switch (selectCostingFormula.options) {
    //                case 0:
    //                    if (selectCostingFormula.rawDataAId <= 0) {

    //                    }
    //                    break;
    //            }
    //            //#endregion

    //            if (disable) { break; }
    //        }

    //        setCostingFormula(newCostingFormula);
    //        setCanAddFormula(tempCanAddFormula);
    //    }
    //}, [costingFormula]);
    //#endregion

    //資料檢查(客戶)
    function checkData() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {
            if (element.name == "costing-formula") {
                if (element.value < 0) {
                    element.classList.add("error");
                    result = false;
                }
                else {
                    element.classList.remove("error");
                }
            }
            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });

        return result;
    }

    function reflash() {
        window.location.reload();
    }

    return (
        <>
            <div className="content-body">
                <div className="title-area">
                    <div className="container full">
                        <h3 className="fz-B has-border">成本計算</h3>
                    </div>
                </div>
                <div className="container">
                    <form className="form-container">
                        <div className="form-item w80">
                            <div className="field">雲端細項</div>
                            <div className="value multi-inline">
                                <div className="item">
                                    <select onChange={productIdChange} data-required>
                                        <option value="">請選擇</option>
                                        {
                                            productName2Array.map((x, index) =>
                                                <option key={index} value={x.id}>{x.name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="item">
                                    {
                                        currencyName ?
                                            <>幣別：{currencyName}</>
                                            : <></>
                                    }
                                </div>
                            </div>
                            <span className="help-word">
                                請選擇雲端細項總稱
                            </span>
                        </div>
                    </form>
                    {costingFormula.length > 0 && costingFormula[0].options != null ?
                        costingFormula.map((x, i) =>
                            <ul key={i} className="list-table transparent formula-group">
                                <li className="lt-row lt-th">
                                    <div className="flex-4">計費項目</div>
                                    <div className="flex-1">值為數字</div>
                                    <div className="flex-8">
                                        <div className="flex-3"></div>
                                        <div className="flex-2"></div>
                                        <div className="flex-3"></div>
                                    </div>
                                    <div className="flex-3"></div>
                                </li>
                                {
                                    x.billingItems != null && x.billingItems.length > 0 ?
                                        x.billingItems.map((x2, i2) =>
                                            <li key={i2} className="lt-row for-formula">
                                                <div className="flex-4">
                                                    <div className="form-item in-table no-field">
                                                        <div className="value">
                                                            <select onChange={(e) => billingItemsChange(e, i, i2, "rawData")} data-required>
                                                                <option value="" selected disabled>Raw Data</option>
                                                                {
                                                                    rawDataArray.map((x3, i3) =>
                                                                        <option key={`${i2}-${i3}`} value={x3.id} selected={x2.rawData == x3.id ? true : false}>{x3.name}</option>
                                                                    )
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="help-word right">
                                                            請選擇計費項目
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 center">
                                                    <div className="form-item in-table no-field center">
                                                        <div className="value">
                                                            <input type="radio" id={`isNumber_${i}_${i2}`} onClick={(e) => billingItemsChange(e, i, i2, "isNumber")} checked={x2.isNumber == 0 ? false : true} />
                                                            <label htmlFor={`isNumber_${i}_${i2}`}></label>
                                                        </div>
                                                        <div className="help-word right">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-8 has-nested">
                                                    {
                                                        x2.isNumber == 1 ?
                                                            <>
                                                                <div className="flex-3">
                                                                    <div className="form-item in-table no-field">
                                                                        <div className="value">
                                                                            <input type="text" placeholder="請輸入" value={x2.lowerLimit} onChange={(e) => billingItemsChange(e, i, i2, "lowerLimit")}
                                                                                disabled={
                                                                                    x2.formulaSigns == "Y=" || x2.formulaSigns == "Y≦" || x2.formulaSigns == "Y<" ?
                                                                                        true : false
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="help-word right">
                                                                            請填入
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-2">
                                                                    <div className="form-item in-table no-field">
                                                                        <div className="value">
                                                                            <select onChange={(e) => billingItemsChange(e, i, i2, "formulaSigns")} data-required>
                                                                                <option value="<Y<" selected={x2.formulaSigns == "<Y<" ? true : false}>{"<"} Y {"<"}</option>
                                                                                <option value="≦Y≦" selected={x2.formulaSigns == "≦Y≦" ? true : false}>{"≦"} Y {"≦"}</option>
                                                                                <option value="<Y≦" selected={x2.formulaSigns == "<Y≦" ? true : false}>{"<"} Y {"≦"}</option>
                                                                                <option value="≦Y<" selected={x2.formulaSigns == "≦Y<" ? true : false}>{"≦"} Y {"<"}</option>
                                                                                <option value="Y=" selected={x2.formulaSigns == "Y=" ? true : false}> Y {"="}</option>
                                                                                <option value="Y≦" selected={x2.formulaSigns == "Y≦" ? true : false}> Y {"≦"}</option>
                                                                                <option value="≦Y" selected={x2.formulaSigns == "≦Y" ? true : false}>{"≦"} Y </option>
                                                                                <option value="Y<" selected={x2.formulaSigns == "Y<" ? true : false}> Y {"<"}</option>
                                                                                <option value="<Y" selected={x2.formulaSigns == "<Y" ? true : false}>{"<"} Y </option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="help-word right">
                                                                            請填入
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-3">
                                                                    <div className="form-item in-table no-field">
                                                                        <div className="value">
                                                                            <input type="text" placeholder="請輸入" value={x2.upperLimit} onChange={(e) => billingItemsChange(e, i, i2, "upperLimit")}
                                                                                disabled={
                                                                                    x2.formulaSigns == "≦Y" || x2.formulaSigns == "<Y" ?
                                                                                        true : false
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="help-word right">
                                                                            請填入算式條件
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </> :
                                                            <>
                                                                <div className="flex-2">
                                                                    <div className="form-item in-table no-field">
                                                                        <div className="value">
                                                                            <select onChange={(e) => billingItemsChange(e, i, i2, "formulaSigns")} data-required>
                                                                                <option value="=">=</option>
                                                                                <option value="≠">≠</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="help-word right">
                                                                            請填入
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-6">
                                                                    <div className="form-item in-table no-field">
                                                                        <div className="value">
                                                                            <input type="text" placeholder="請輸入" value={x2.value} onChange={(e) => billingItemsChange(e, i, i2, "value")} data-required />
                                                                        </div>
                                                                        <div className="help-word right">
                                                                            請填入算式條件
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }
                                                </div>

                                                <div className="flex-3 no-form">
                                                    {x.billingItems.length > 1 ?
                                                        <div className="btn btn-icon" onClick={() => DelBillingItems(i, i2)}>
                                                            <i className="icon icon-37"></i>
                                                        </div>
                                                        : <></>
                                                    }
                                                    {(() => {
                                                        if (x.billingItems.length > 1 && i2 != x.billingItems.length - 1) {
                                                            return "";
                                                        }
                                                        else {
                                                            var disable = false;
                                                            for (var itemIndex = 0; itemIndex < x.billingItems.length; itemIndex++) {
                                                                //#region 判斷計費項目
                                                                if (!x.billingItems[itemIndex].rawData || x.billingItems[itemIndex].rawData == "") {
                                                                    disable = true;
                                                                    break;
                                                                }
                                                                //#endregion

                                                                //#region 判斷值為數字
                                                                if (x.billingItems[itemIndex].isNumber == 1) {
                                                                    if (!x.billingItems[itemIndex].formulaSigns || x.billingItems[itemIndex].formulaSigns == "") {
                                                                        disable = true;
                                                                        break;
                                                                    }
                                                                    else {
                                                                        if (x.billingItems[itemIndex].formulaSigns == "<Y<" ||
                                                                            x.billingItems[itemIndex].formulaSigns == "≦Y≦" ||
                                                                            x.billingItems[itemIndex].formulaSigns == "<Y≦" ||
                                                                            x.billingItems[itemIndex].formulaSigns == "≦Y<") {
                                                                            if (!x.billingItems[itemIndex].lowerLimit || (x.billingItems[itemIndex].lowerLimit + "") == "") {
                                                                                if (isNaN(parseFloat(x.billingItems[itemIndex].lowerLimit))) {
                                                                                    disable = true;
                                                                                    break;
                                                                                }
                                                                            }
                                                                            if (!x.billingItems[itemIndex].upperLimit || (x.billingItems[itemIndex].upperLimit + "") == "") {
                                                                                if (isNaN(parseFloat(x.billingItems[itemIndex].upperLimit))) {
                                                                                    disable = true;
                                                                                    break;
                                                                                }
                                                                            }
                                                                        }
                                                                        else if (
                                                                            x.billingItems[itemIndex].formulaSigns == "Y=" ||
                                                                            x.billingItems[itemIndex].formulaSigns == "Y≦" ||
                                                                            x.billingItems[itemIndex].formulaSigns == "Y<") {
                                                                            if (!x.billingItems[itemIndex].upperLimit || (x.billingItems[itemIndex].upperLimit + "") == "") {
                                                                                if (isNaN(parseFloat(x.billingItems[itemIndex].upperLimit))) {
                                                                                    disable = true;
                                                                                    break;
                                                                                }
                                                                            }
                                                                        }
                                                                        else {
                                                                            if (!x.billingItems[itemIndex].lowerLimit || (x.billingItems[itemIndex].lowerLimit + "") == "") {
                                                                                if (isNaN(parseFloat(x.billingItems[itemIndex].lowerLimit))) {
                                                                                    disable = true;
                                                                                    break;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                else {
                                                                    if (!x.billingItems[itemIndex].formulaSigns || x.billingItems[itemIndex].formulaSigns == "") {
                                                                        disable = true;
                                                                        break;
                                                                    }
                                                                    if (!x.billingItems[itemIndex].value || x.billingItems[itemIndex].value == "") {
                                                                        disable = true;
                                                                        break;
                                                                    }
                                                                }
                                                                //#endregion
                                                            }

                                                            return (
                                                                <>
                                                                    {
                                                                        disable ?
                                                                            <div className="btn btn-icon disabled">
                                                                                <i className="icon icon-25"></i>
                                                                            </div> :
                                                                            <div className="btn btn-icon" onClick={() => AddBillingItems(i)}  >
                                                                                <i className="icon icon-25"></i>
                                                                            </div>
                                                                    }
                                                                </>
                                                            );
                                                        }
                                                    })()}
                                                </div>
                                            </li>
                                        ) : null
                                }
                                <li className="lt-row lt-th">
                                    <div className="flex-4">
                                        成本計算式
                                    </div>
                                    <div className="flex-4"></div>
                                    <div className="flex-5"></div>
                                    <div className="flex-3"></div>
                                </li>
                                <li className="lt-row for-formula">
                                    <div className="flex-3">
                                        <div className="form-item in-table no-field">
                                            <div className="value">
                                                <select name="costing-formula" onChange={(e) => costingFormulaChange(e, i, "options")} data-required>
                                                    {
                                                        optionsArray.map((x2, i2) =>
                                                            <option key={i2} value={x2.id} selected={x.options == x2.id ? true : false} disabled={x2.id == -1 ? true : false}>{x2.name}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div className="help-word right">
                                                請選擇計費項目
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        x.options == -1 ?
                                            <div className="flex-4"></div>
                                            :
                                            x.options == 0 ?
                                                <div className="flex-4">
                                                    <div className="form-item in-table no-field">
                                                        <div className="value">
                                                            <select name="" id="" onChange={(e) => costingFormulaChange(e, i, "rawDataAId")} data-required>
                                                                <option value="" selected disabled>Raw Data</option>
                                                                {
                                                                    rawDataArray.map(x2 =>
                                                                        <option value={x2.id} selected={x.rawDataAId == x2.id ? true : false}>{x2.name}</option>
                                                                    )
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="help-word right">
                                                            請選擇計費項目
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                x.options == 1 ?
                                                    <>
                                                        <div className="flex-4">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <select name="" id="" onChange={(e) => costingFormulaChange(e, i, "rawDataAId")} data-required>
                                                                        {/*A*/}
                                                                        <option value="" selected disabled>Raw Data</option>
                                                                        {
                                                                            rawDataArray.map(x2 =>
                                                                                <option value={x2.id} selected={x.rawDataAId == x2.id ? true : false}>{x2.name}</option>
                                                                            )
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="help-word right">
                                                                    請選擇計費項目
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-1 no-form center">
                                                            {"X"}
                                                        </div>
                                                        <div className="flex-4">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <input type="text" value={x.value} placeholder="請輸入數字" onChange={(e) => costingFormulaChange(e, i, "value")} data-required />
                                                                </div>
                                                                <div className="help-word right">
                                                                    請選擇計費項目
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </>
                                                    :
                                                    x.options == 2 ?
                                                        <>
                                                            <div className="flex-4">
                                                                <div className="form-item in-table no-field">
                                                                    <div className="value">
                                                                        <input type="text" value={x.value} placeholder="請輸入數字" onChange={(e) => costingFormulaChange(e, i, "value")} data-required />
                                                                    </div>
                                                                    <div className="help-word right">
                                                                        請選擇計費項目
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        x.options == 3 ?
                                                            <>
                                                                <div className="flex-4">
                                                                    <div className="form-item in-table no-field">
                                                                        <div className="value">
                                                                            <select name="" id="" onChange={(e) => costingFormulaChange(e, i, "rawDataAId")} data-required>
                                                                                {/*A*/}
                                                                                <option value="" selected disabled>Raw Data</option>
                                                                                {
                                                                                    rawDataArray.map(x2 =>
                                                                                        <option value={x2.id} selected={x.rawDataAId == x2.id ? true : false}>{x2.name}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        <div className="help-word right">
                                                                            請選擇計費項目
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-1 no-form center">
                                                                    {"X"}
                                                                </div>
                                                                <div className="flex-4">
                                                                    <div className="form-item in-table no-field">
                                                                        <div className="value">
                                                                            <select name="" id="" onChange={(e) => costingFormulaChange(e, i, "rawDataBId")} data-required>
                                                                                {/*B*/}
                                                                                <option value="">Raw Data</option>
                                                                                {
                                                                                    rawDataArray.map(x2 =>
                                                                                        <option value={x2.id} selected={x.rawDataBId == x2.id ? true : false}>{x2.name}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        <div className="help-word right">
                                                                            請選擇計費項目
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null
                                    }
                                    <div className="flex-3 no-form jcfe"></div>
                                </li>
                                <li className="lt-row for-formula">
                                    <div className="flex-1 no-form jcfe">
                                        {
                                            costingFormula && costingFormula.length > 1 ?
                                                <button className={`btn btn-middle`} onClick={() => DelcostingFormula(i)} >
                                                    <span>刪除條件算式</span>
                                                </button>
                                                : null
                                        }
                                        {
                                            costingFormula && costingFormula.length == (i + 1) ?
                                                <>
                                                    {(() => {
                                                        var disable = false;
                                                        for (var formulaIndex = 0; formulaIndex < costingFormula.length; formulaIndex++) {
                                                            var selectFormula = costingFormula[formulaIndex];

                                                            for (var itemIndex = 0; itemIndex < selectFormula.billingItems.length; itemIndex++) {
                                                                var selectItem = selectFormula.billingItems[itemIndex];

                                                                //#region 判斷計費項目
                                                                if (!selectItem.rawData || selectItem.rawData == "") {
                                                                    disable = true;
                                                                    break;
                                                                }
                                                                //#endregion

                                                                //#region 判斷值為數字
                                                                if (selectItem.isNumber == 1) {
                                                                    if (!selectItem.formulaSigns || selectItem.formulaSigns == "") {
                                                                        disable = true;
                                                                        break;
                                                                    }
                                                                    else {
                                                                        if (selectItem.formulaSigns == "<Y<" ||
                                                                            selectItem.formulaSigns == "≦Y≦" ||
                                                                            selectItem.formulaSigns == "<Y≦" ||
                                                                            selectItem.formulaSigns == "≦Y<") {
                                                                            if (!selectItem.lowerLimit || (selectItem.lowerLimit + "") == "") {
                                                                                if (isNaN(parseFloat(selectItem.lowerLimit))) {
                                                                                    disable = true;
                                                                                    break;
                                                                                }
                                                                            }
                                                                            if (!selectItem.upperLimit || (selectItem.upperLimit + "") == "") {
                                                                                if (isNaN(parseFloat(selectItem.upperLimit))) {
                                                                                    disable = true;
                                                                                    break;
                                                                                }
                                                                            }
                                                                        }
                                                                        else if (
                                                                            selectItem.formulaSigns == "Y=" ||
                                                                            selectItem.formulaSigns == "Y≦" ||
                                                                            selectItem.formulaSigns == "Y<") {
                                                                            if (!selectItem.upperLimit || (selectItem.upperLimit + "") == "") {
                                                                                if (isNaN(parseFloat(selectItem.upperLimit))) {
                                                                                    disable = true;
                                                                                    break;
                                                                                }
                                                                            }
                                                                        }
                                                                        else {
                                                                            if (!selectItem.lowerLimit || (selectItem.lowerLimit + "") == "") {
                                                                                if (isNaN(parseFloat(selectItem.lowerLimit))) {
                                                                                    disable = true;
                                                                                    break;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                else {
                                                                    if (!selectItem.formulaSigns || selectItem.formulaSigns == "") {
                                                                        disable = true;
                                                                        break;
                                                                    }
                                                                    if (!selectItem.value || selectItem.value == "") {
                                                                        disable = true;
                                                                        break;
                                                                    }
                                                                }
                                                                //#endregion
                                                            }

                                                            if (disable) { break; }

                                                            //#region 判斷成本計算式
                                                            let tempOptions = parseInt(selectFormula.options);
                                                            if (tempOptions < 0) {
                                                                disable = true;
                                                                break;
                                                            }
                                                            //#endregion

                                                            //#region 判斷成本計算式內容
                                                            switch (tempOptions) {
                                                                case 0:
                                                                    if (!selectFormula.rawDataAId || parseInt(selectFormula.rawDataAId) <= 0) {
                                                                        disable = true;
                                                                    }
                                                                    break;
                                                                case 1:
                                                                    if (!selectFormula.rawDataAId || parseInt(selectFormula.rawDataAId) <= 0) {
                                                                        disable = true;
                                                                    }
                                                                    if (!selectFormula.value || selectFormula.value == "") {
                                                                        disable = true;
                                                                    }
                                                                    break;
                                                                case 2:
                                                                    if (!selectFormula.value || selectFormula.value == "") {
                                                                        disable = true;
                                                                    }
                                                                    break;
                                                                case 3:
                                                                    if (!selectFormula.rawDataAId ||
                                                                        !selectFormula.rawDataBId ||
                                                                        parseInt(selectFormula.rawDataAId) <= 0 ||
                                                                        parseInt(selectFormula.rawDataBId <= 0)) {
                                                                        disable = true;
                                                                    }
                                                                    break;
                                                            }
                                                            //#endregion

                                                            if (disable) { break; }
                                                        }
                                                        return (
                                                            <button className={`btn btn-middle ${disable ? "disabled" : ""}`} onClick={AddcostingFormula} disabled={disable}>
                                                                <span>新增條件算式</span>
                                                            </button>
                                                        );
                                                    })()}
                                                </>
                                                : null
                                        }
                                    </div>
                                </li>
                            </ul>
                        ) : null
                    }
                    <div className="form-item btn-area sp">
                        <div className="left"></div>
                        <div className="right">
                            {(() => {
                                var disable = false;
                                if (parseInt(productNameId) > 0 && costingFormula.length > 0) {
                                    for (var formulaIndex = 0; formulaIndex < costingFormula.length; formulaIndex++) {
                                        var selectFormula = costingFormula[formulaIndex];

                                        for (var itemIndex = 0; itemIndex < selectFormula.billingItems.length; itemIndex++) {
                                            var selectItem = selectFormula.billingItems[itemIndex];

                                            //#region 判斷計費項目
                                            if (!selectItem.rawData || selectItem.rawData == "") {
                                                disable = true;
                                                break;
                                            }
                                            //#endregion

                                            //#region 判斷值為數字
                                            if (selectItem.isNumber == 1) {
                                                if (!selectItem.formulaSigns || selectItem.formulaSigns == "") {
                                                    disable = true;
                                                    break;
                                                }
                                                else {
                                                    if (selectItem.formulaSigns == "<Y<" ||
                                                        selectItem.formulaSigns == "≦Y≦" ||
                                                        selectItem.formulaSigns == "<Y≦" ||
                                                        selectItem.formulaSigns == "≦Y<") {
                                                        if (!selectItem.lowerLimit || selectItem.lowerLimit == "") {
                                                            if (isNaN(parseFloat(selectItem.lowerLimit))) {
                                                                disable = true;
                                                                break;
                                                            }
                                                        }
                                                        if (!selectItem.upperLimit || selectItem.upperLimit == "") {
                                                            if (isNaN(parseFloat(selectItem.upperLimit))) {
                                                                disable = true;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                    else if (
                                                        selectItem.formulaSigns == "Y=" ||
                                                        selectItem.formulaSigns == "Y≦" ||
                                                        selectItem.formulaSigns == "Y<") {
                                                        if (!selectItem.upperLimit || selectItem.upperLimit == "") {
                                                            if (isNaN(parseFloat(selectItem.upperLimit))) {
                                                                disable = true;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        if (!selectItem.lowerLimit || selectItem.lowerLimit == "") {
                                                            if (isNaN(parseFloat(selectItem.lowerLimit))) {
                                                                disable = true;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            else {
                                                if (!selectItem.formulaSigns || selectItem.formulaSigns == "") {
                                                    disable = true;
                                                    break;
                                                }
                                                if (!selectItem.value || selectItem.value == "") {
                                                    disable = true;
                                                    break;
                                                }
                                            }
                                            //#endregion
                                        }

                                        if (disable) { break; }

                                        //#region 判斷成本計算式
                                        let tempOptions = parseInt(selectFormula.options);
                                        if (tempOptions < 0) {
                                            disable = true;
                                            break;
                                        }
                                        //#endregion

                                        //#region 判斷成本計算式內容
                                        switch (tempOptions) {
                                            case 0:
                                                if (!selectFormula.rawDataAId || parseInt(selectFormula.rawDataAId) <= 0) {
                                                    disable = true;
                                                }
                                                break;
                                            case 1:
                                                if (!selectFormula.rawDataAId || parseInt(selectFormula.rawDataAId) <= 0) {
                                                    disable = true;
                                                }
                                                if (!selectFormula.value || selectFormula.value == "") {
                                                    disable = true;
                                                }
                                                break;
                                            case 2:
                                                if (!selectFormula.value || selectFormula.value == "") {
                                                    disable = true;
                                                }
                                                break;
                                            case 3:
                                                if (!selectFormula.rawDataAId ||
                                                    !selectFormula.rawDataBId ||
                                                    parseInt(selectFormula.rawDataAId) <= 0 ||
                                                    parseInt(selectFormula.rawDataBId <= 0)) {
                                                    disable = true;
                                                }
                                                break;
                                        }
                                        //#endregion

                                        if (disable) { break; }
                                    }
                                }
                                else {
                                    disable = true;
                                }

                                return (
                                    <a className={`btn ${disable ? "disabled" : ""}`} onClick={SaveData} disabled={disable}>
                                        <span>確定</span>
                                    </a>
                                );
                            })()}
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-ok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            儲存成功
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={reflash}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CATCostSet;
