import React, { useState, useEffect } from "react";
import {
    API_GET_IsAdmin,
    API_GET_UserList,
    API_POST_PermissionListPriv,
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, InitJS, MenuSet } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const Permission = () => {
    var history = useHistory();
    useEffect(() => {
        //把menu顏色置換
        MenuSet("Permission");
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)

        getData();

        return () => clearTimeout(timeout)
    }, []);

    function getData() {
        API_GET_IsAdmin()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (data.code == "0000") {
                    if (data.result.isAdmin != 1) {
                        document.getElementById('div_1004').innerHTML = "您沒有權限申請新增/刪除/修改/查詢相關資訊";
                        OpenModal('modal-1004');
                        return;
                    }
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    const [accNameArray, setAccNameArray] = useState([{}]);
    const [accName, setAccName] = useState("");
    const [accID, setAccID] = useState("");
    function accNameChange(e) {
        setAccName(e.target.value);
        getAccName();
    }
    const [modalStr, setModalStr] = useState("");
    function getAccName() {
        API_GET_UserList({
            UserName: document.getElementById("accNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setAccNameArray(data.result);
                }
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //查詢按鈕
    function query(e) {
        //console.log(accID, accName);
        if (accID == "") {
            setModalStr("查無資料");
            OpenModal("modal-1");
            return false;
        }

        API_POST_PermissionListPriv({
            UserID: accID,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    history.push("/Main?path=PermissionEdit&userid=" + accID);
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-1");
                }
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
     
    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container narrow">
                    <h3 className="fz-B has-border">用戶權限新增/ 修改設定</h3>
                </div>
            </div>

            <div className="container narrow">
                {/*<form action="" className="form-container">*/}
                <div className="form-item has-datalist" data-datalist>
                    {/*<div className="field"> </div>*/}
                    <div className="value">
                        <input
                            id="accNameText"
                            type="search"
                            onChange={accNameChange}
                            value={accName}
                            placeholder="請輸入用戶帳號或姓名"
                        />
                        <ul className="datalist scrollbar">
                            {accNameArray.map((x) => (
                                <li
                                    data-value={x.userName}
                                    onClick={(e) => {
                                        setAccName(x.userName), setAccID(x.userID);
                                    }}
                                >
                                    <span className="flex-4">{x.userName}</span>
                                    <span className="flex-5">{x.userEmail}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <span className="help-word">請輸入正確格式</span>
                </div>

                <div className="form-item btn-area sp">
                    <div className="left"></div>
                    <div className="right">
                        <a className="btn" onClick={query}>
                            <span>查詢</span>
                        </a>
                    </div>
                </div>
                {/*</form>*/}
            </div>
            <div id="modal-1" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder sp">
                            <a className="btn" onClick={ ()=>CloseModal("modal-1")}>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Permission;
