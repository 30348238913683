import axios from 'axios';
import { getAuthToken } from './TokenUtil';

export const getHeaders = (token) => {
    return {
        'Content-Type': 'application/json',
        'Authorization': token,
    }
}

//const API_URL = process.env.REACT_APP_API_URL;
const API_URL = window.isTest ? window.testApiUrl : window.apiUrl;
const OKTA_URL = window.oktaUrl;
//API URL
//export const API_HOST = "http://172.16.5.147/api";
export const API_HOST = API_URL
export const OKTA_HOST = OKTA_URL
export const IS_TEST = window.isTest;
//"https://demo-digicentreacnt-api.summit-edu.com.tw/api";
//一般情況用
const baseRequest = axios.create({
    baseURL: API_HOST
});
const oktaRequest = axios.create({
    baseURL: OKTA_HOST
});
//下載用
export const downloadRequest = axios.create({
    baseURL: API_HOST,
    responseType: 'blob'
});


//#region 共用api
export const apiLangSetting = data => baseRequest.get('/languageData');
export const apiAreaSetting = data => baseRequest.get('/areaData');
export const API_GET_IsAdmin = data => baseRequest.get('/permission/isadmin', { params: data, headers: getHeaders(getAuthToken()) });
//#endregion

//#region 主畫面
//主畫面-取得權限
export const API_GET_HomeData = data => baseRequest.get('/home/data', { params: data, headers: getHeaders(getAuthToken()) });
//#endregion

//#region 功能-0
//0-0 產生使用者帳號
export const API_Account_Create = data => baseRequest.put('/account/createuser', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//0-1 取得產生QRCode的資訊
export const API_Auth_GetQrcode = data => baseRequest.get('/authenticate/getqrcode', { params: data, headers: getHeaders(getAuthToken()) });
//0-2 驗證6碼OTP
export const API_Login_Auth = data => baseRequest.post('/authenticate/verify', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//0-3 重置密碼
export const API_Account_ResetPWD = data => baseRequest.get('/authenticate/resetpwd', { params: data, headers: getHeaders(getAuthToken()) });
//0-4 列出使用者權限--------------------
export const API_POST_PermissionListPriv = data => baseRequest.post('/permission/listpriv', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//0-5 使用者登入
export const API_Login = data => baseRequest.post('/login/singin', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*' }
});
//0-6 變更密碼
export const API_Login_Change = data => baseRequest.post('/account/updateuserpwd', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//0-7 列出所有權限
export const API_GET_Permission_ListAll = data => baseRequest.get('/permission/listall', { headers: getHeaders(getAuthToken()) });
//0-8 變更權限
export const API_SetPrivilege = data => baseRequest.post('/permission/setprivilege', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//0-9 取得使用者資料
export const API_GET_UserList = data => baseRequest.get('/account/getuser', { params: data, headers: getHeaders(getAuthToken()) });
//0-10 更新使用者狀態
export const API_Account_UpdateStatus = data => baseRequest.post('/account/updateusrstatus', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//0-11 刪除使用者帳號
export const API_Account_DEL = data => baseRequest.post('/account/deleteusr', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//0-13合併AD帳號
export const API_OKTA_AccountMerge = data => oktaRequest.get('/account/merge', { params: data, headers: getHeaders(getAuthToken()) });
//0-14.捨棄AD帳號 
export const API_OKTA_AccountRevoke = data => oktaRequest.get('/account/revoke', { params: data, headers: getHeaders(getAuthToken()) });
//0-15.列出重複的AD帳號(帳號合併捨棄首頁)
export const API_OKTA_AccountHome = data => oktaRequest.get('/account/home', { params: data, headers: getHeaders(getAuthToken()) });
//#endregion

//#region 功能-1 銷貨訂單
//1-2 銷貨訂單-取得表單內容
export const API_GET_SaleOrderDataCard = data => baseRequest.get('/salesorder/dataCard', { headers: getHeaders(getAuthToken()) });
//1-3 銷貨訂單-列表查詢
export const API_GET_SaleOrderList = data => baseRequest.get('/salesorder/datalist', { params: data, headers: getHeaders(getAuthToken()) });
//1-4 銷貨訂單-新增(formdata)
export const API_POST_SaleOrderData = data => baseRequest.post('/salesorder/data', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//1-5 銷貨訂單-客戶ID  取得客戶各項餘額
export const API_POST_SaleOrderCustomerBalance = data => baseRequest.post('/salesorder/customerBalance', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//1-6 銷貨訂單-查詢
export const API_GET_SaleOrderData = data => baseRequest.get('/salesorder/data', { params: data, headers: getHeaders(getAuthToken()) });
//1-6 銷貨訂單-刪除
export const API_DEL_SaleOrderData = data => baseRequest.delete('/salesorder/data', { params: data, headers: getHeaders(getAuthToken()) });
//1-9 銷貨訂單-歷史紀錄
export const API_GET_SaleOrderRecord = data => baseRequest.get('/salesorder/record', { params: data, headers: getHeaders(getAuthToken()) });
//1-9 銷貨訂單-歷史紀錄
export const API_GET_SaleOrderUnclosedYearMonth = data => baseRequest.get('/salesorder/getUnclosedYearMonth', { params: data, headers: getHeaders(getAuthToken()) });
//#endregion

//#region 功能-2 產品成本
//2-1 銷貨訂單-取得表單內容
export const API_GET_ProductCostHome = data => baseRequest.get('/productcost/home', { params: data, headers: getHeaders(getAuthToken()) });
//2-2 銷貨訂單-資料查詢
export const API_GET_ProductCostData = data => baseRequest.get('/productcost/data', { params: data, headers: getHeaders(getAuthToken()) });
//2-3 銷貨訂單-儲存
export const API_POST_ProductCostData = data => baseRequest.post('/productcost/data', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//2-4 銷貨訂單-刪除
export const API_DEL_ProductCostData = data => baseRequest.delete('/productcost/data', { params: data, headers: getHeaders(getAuthToken()) });
//#endregion
//#region 功能-3 月結作業
//3-1 月結作業-主頁
export const API_GET_SettlementHome = data => baseRequest.get('/settlement/home', { params: data, headers: getHeaders(getAuthToken()) });
//3-2 月結作業-月結鎖定
export const API_POST_SettlementLock = data => baseRequest.post('/settlement/lock', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//3-3 月結作業-主頁
export const API_GET_SettlementData = data => baseRequest.get('/settlement/data', { params: data, headers: getHeaders(getAuthToken()) });
//3-4 月結作業-出帳1
export const API_POST_SettlementBilling1 = data => baseRequest.post('/settlement/billing1', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//3-5 月結作業-出帳1_1
export const API_POST_SettlementBilling1_1 = data => baseRequest.post('/settlement/billing1_1', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//3-6 月結作業-出帳2
export const API_POST_SettlementBilling2 = data => baseRequest.post('/settlement/billing2', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//3-7 月結作業-取得毛利資訊
export const API_GET_SettlementAnalysis = data => baseRequest.get('/settlement/analysis', { params: data, headers: getHeaders(getAuthToken()) });
//3-8 月結作業-刪除
export const API_DEL_SettlementOrderProduct = data => baseRequest.delete('/settlement/orderProduct', { data: data, headers: getHeaders(getAuthToken()) });
//3-9 月結作業-暫存
export const API_POST_SettlementData = data => baseRequest.post('/settlement/data', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//3-10 月結作業-訂單微調
export const API_POST_SettlementRefine = data => baseRequest.post('/settlement/refine', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//3-11 月結作業-營收明細
export const API_GET_SettlementRevenueDetail = data => baseRequest.get('/settlement/revenueDetail', { params: data, headers: getHeaders(getAuthToken()) });
//3-12 月結作業-成本明細
export const API_GET_SettlementCostDetail = data => baseRequest.get('/settlement/costDetail', { params: data, headers: getHeaders(getAuthToken()) });

export const API_GET_SettlementV2_Query = data => baseRequest.get('/SettlementV2/Query', { params: data, headers: getHeaders(getAuthToken()) });

export const API_GET_SettlementV2_FirstStageBillingCheck = data => baseRequest.post('/SettlementV2/FirstStageBillingCheck', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

export const API_GET_SettlementV2_FirstStageBilling = data => baseRequest.post('/SettlementV2/FirstStageBilling', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

export const API_GET_SettlementV2_Delete = data => baseRequest.post('/SettlementV2/Delete', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

export const API_GET_SettlementV2_TempSave = data => baseRequest.post('/SettlementV2/TempSave', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

export const API_GET_SettlementV2_GrossProfitAyalysis = data => baseRequest.get('/SettlementV2/GrossProfitAyalysis', { params: data, headers: getHeaders(getAuthToken()) });

export const API_POST_SettlementV2_SecondStageBilling = data => baseRequest.post('/SettlementV2/SecondStageBilling', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });


//#endregion
//#region 功能-4開立對帳單/發票
//4-1 對帳單-主頁
export const API_GET_StatementHome = data => baseRequest.get('/statement/home', { params: data, headers: getHeaders(getAuthToken()) });
//4-2 對帳單-寄出對帳單
export const API_POST_StatementMailSend = data => baseRequest.post('/statement/mailSend', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//4-3 對帳單-搜尋可開立的對帳單
export const API_GET_StatementData = data => baseRequest.get('/statement/data', { params: data, headers: getHeaders(getAuthToken()) });
//4-4 對帳單-暫存
export const API_POST_StatementData = data => baseRequest.post('/statement/data', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//4-5 對帳單-檢視pdf
export const API_POST_StatementPDF = data => baseRequest.post('/statement/pdf', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//4-6 發票開立-主頁
export const API_GET_InvoiceHome = data => baseRequest.get('/invoice/home', { params: data, headers: getHeaders(getAuthToken()) });
//4-7 開立發票-搜尋可開發票的項目
export const API_POST_InvoiceHome = data => baseRequest.post('/invoice/select', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//4-8 發票-暫存
export const API_PUT_InvoiceData = data => baseRequest.put('/invoice/data', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//4-9 發票開立-產出發票申請單
export const API_PUT_InvoiceOutputApply = data => baseRequest.put('/invoice/outputApply', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//4-10 發票鎖定-搜尋可以鎖定的發票
export const API_GET_InvoiceLock = data => baseRequest.get('/invoice/lock', { params: data, headers: getHeaders(getAuthToken()) });
//4-11 發票鎖定
export const API_PUT_InvoiceLock = data => baseRequest.put('/invoice/lock', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//4-12 發票鎖定-復原-搜尋可以回復的發票
export const API_GET_InvoiceChangeSelect = data => baseRequest.get('/invoice/changeSelect', { params: data, headers: getHeaders(getAuthToken()) });
//4-13 發票回復
export const API_PUT_InvoiceReduction = data => baseRequest.put('/invoice/reduction', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//4-14 發票作廢-搜尋可以作廢的發票
export const API_GET_InvoiceDeleteSelect = data => baseRequest.get('/invoice/deleteSelect', { params: data, headers: getHeaders(getAuthToken()) });
//4-15 發票作廢
export const API_GET_InvoiceDelete = data => baseRequest.get('/invoice/delete', { params: data, headers: getHeaders(getAuthToken()) });
//4-16 發票鎖定-復原-作廢的主頁
export const API_GET_InvoiceCtrlHome = data => baseRequest.get('/invoice/ctrlHome', { params: data, headers: getHeaders(getAuthToken()) });
//4-17 發票鎖定-產出發票批次檔
export const API_PUT_InvoiceOutputBatch = data => baseRequest.put('/invoice/outputBatch', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//4-18 發票作廢-發票作廢
export const API_POST_InvoiceDeleteAudit = data => baseRequest.post('/invoice/deleteAudit', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//4-19 發票作廢-查詢送審資料
export const API_GET_InvoiceDeleteQry = data => baseRequest.get('/invoice/deleteQry', { params: data, headers: getHeaders(getAuthToken()) });
//4-20 發票作廢-查詢送審資料
export const API_GET_InvoiceReviewList = data => baseRequest.get('/invoice/reviewList', { params: data, headers: getHeaders(getAuthToken()) });
//4-21 發票作廢-再次送審資料
export const API_POST_InvoiceDeleteReAudit = data => baseRequest.post('/invoice/deleteReAudit', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//#endregion
//#region 功能-5帳務沖銷
//5-0 帳務沖銷-主頁
export const API_GET_AccountChargeOffHome = data => baseRequest.get('/accountChargeOff/home', { headers: getHeaders(getAuthToken()) });
//5-1 帳務沖銷-查詢未沖銷憑證
export const API_POST_AccountChargeOffDataList1 = data => baseRequest.post('/accountChargeOff/dataList1', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//5-2 帳務沖銷-查詢關鍵字
export const API_GET_AccountChargeOffDataList2 = data => baseRequest.get('/accountChargeOff/dataList2', { params: data, headers: getHeaders(getAuthToken()) });
//5-3 帳務沖銷-查詢關鍵字2
export const API_POST_AccountChargeOffDataList22 = data => baseRequest.post('/accountChargeOff/dataList2_2', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//5-4 帳務沖銷-暫存
export const API_PUT_AccountChargeOffSave = data => baseRequest.put('/accountChargeOff/save', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//5-6 帳務沖銷-暫收款列表
export const API_GET_AccountChargeOffData = data => baseRequest.get('/accountChargeOff/data', { params: data, headers: getHeaders(getAuthToken()) });
//5-7 帳務沖銷-新增款列表
export const API_POST_AccountChargeOffData = data => baseRequest.post('/accountChargeOff/data', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//5-8 帳務沖銷-修改款列表
export const API_PUT_AccountChargeOffData = data => baseRequest.put('/accountChargeOff/data', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//5-9 帳務沖銷-刪除款列表
export const API_DEL_AccountChargeOffData = data => baseRequest.delete('/accountChargeOff/data', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//5-10 帳務沖銷-送審
export const API_POST_AccountChargeOffAudit = data => baseRequest.post('/accountChargeOff/audit', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//5-11 帳務沖銷-查詢送審(包含審核、退回)清單
export const API_GET_AccChargeOffReviewListData = data => baseRequest.get('/accountChargeOff/reviewList', { params: data, headers: getHeaders(getAuthToken()) });
//5-12-帳務沖銷-審核主頁
export const API_GET_AccChargeOffReviewAuditMain = data => baseRequest.get('/accountChargeOff/auditMain', { params: data, headers: getHeaders(getAuthToken()) });
//5-13-帳務沖銷-審核簽核
export const API_POST_AccountChargeOffauditSign = data => baseRequest.post('/accountChargeOff/auditSign', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//5-14-帳務沖銷-下載繳款憑證
export const API_POST_AccountChargeOffDownLoad = data => baseRequest.post('/accountChargeOff/downloadCert', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//5-15-帳務沖銷-退回重審主畫面
export const API_POST_AccountChargeOffreAuditMain = data => baseRequest.get('/accountChargeOff/reAuditMain', { params: data, headers: getHeaders(getAuthToken()) });
//5-16-帳務沖銷-退回重審
export const API_POST_AccountChargeOffreAudit = data => baseRequest.post('/accountChargeOff/reAudit', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//5-18-帳務沖銷-撤銷
export const API_GET_AccountChargeOffRevoke = data => baseRequest.get('/accountChargeOff/revoke', { params: data, headers: getHeaders(getAuthToken()) });

//#endregion
//#region 功能-6產品維護
//6-1 產品維護-主頁
export const API_GET_ProductHome = data => baseRequest.get('/product/home', { headers: getHeaders(getAuthToken()) });
//6-2 產品維護-刪除
export const API_DEL_ProductData = data => baseRequest.delete('/product/data', { params: data, headers: getHeaders(getAuthToken()) });
//6-4 產品維護-查詢
export const API_GET_ProductData = data => baseRequest.get('/product/data', { params: data, headers: getHeaders(getAuthToken()) });
//6-5 產品維護-查詢列表
export const API_GET_ProductDataList = data => baseRequest.get('/product/datalist', { params: data, headers: getHeaders(getAuthToken()) });
//6-6 產品維護-修改
export const API_PUT_ProductData = data => baseRequest.put('/product/data', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//6-7 產品維護-尋找請求
export const API_GET_ProductReview = data => baseRequest.get('/product/review', { params: data, headers: getHeaders(getAuthToken()) });
//6-8 產品維護-尋找請求(列表)
export const API_GET_ProductReviewList = data => baseRequest.get('/product/reviewList', { params: data, headers: getHeaders(getAuthToken()) });
//6-9 產品維護-新增
export const API_POST_ProductData = data => baseRequest.post('/product/data', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//6-10 產品維護-審核
export const API_PUT_ProductReview = data => baseRequest.put('/product/review', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//6-11 產品維護-產品別刪除
export const API_DEL_ProductType = data => baseRequest.delete('/product/productType', { params: data, headers: getHeaders(getAuthToken()) });
//6-12 產品維護-查詢
export const API_GET_ProductType = data => baseRequest.get('/product/productType', { params: data, headers: getHeaders(getAuthToken()) });
//6-13 產品維護-修改
export const API_PUT_ProductType = data => baseRequest.put('/product/productType', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//6-14 產品維護-新增
export const API_POST_ProductType = data => baseRequest.post('/product/productType', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//6-15 產品維護-新增
export const API_GET_ProdType = data => baseRequest.get('/product/prodActType', { params: data, headers: getHeaders(getAuthToken()) });

//#endregion

//#region 功能-7 客戶維護
// 7-1 客戶維護-主頁
export const API_GET_CustomerHome = data => baseRequest.get('/customer/home', { headers: getHeaders(getAuthToken()) });
// 7-2 客戶維護-刪除
export const API_DEL_CustomerData = data => baseRequest.delete('/customer/data', { data: data, headers: getHeaders(getAuthToken()) });

// 7-3 客戶維護-取得表單內容
export const API_GET_Customer_dataCard = data => baseRequest.get('/customer/dataCard', { params: data, headers: getHeaders(getAuthToken()) });
// 7-4 客戶維護-查詢
export const API_GET_CustomerData = data => baseRequest.get('/customer/data', { params: data, headers: getHeaders(getAuthToken()) });
// 7-5 客戶維護-查詢列表
export const API_GET_CustomerDataList = data => baseRequest.get('/customer/datalist', { params: data, headers: getHeaders(getAuthToken()) });
// 7-6 客戶維護-修改
export const API_PUT_CustomerData = data => baseRequest.put('/customer/data', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
// 7-7 客戶維護-尋找請求(新改刪)
export const API_GET_CustomerReview = data => baseRequest.get('/customer/review', { params: data, headers: getHeaders(getAuthToken()) });
// 7-8 客戶維護-尋找請求(新改刪)列表
export const API_GET_CustomerReviewList = data => baseRequest.get('/customer/reviewList', { params: data, headers: getHeaders(getAuthToken()) });
// 7-9 客戶維護-新增
export const API_POST_CustomerData = data => baseRequest.post('/customer/data', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
// 7-10 客戶維護-審核
export const API_PUT_CustomerReview = data => baseRequest.put('/customer/review', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
// 7-11 客戶維護-產出客戶資料卡
export const API_GET_CustomerDownLoad = data => downloadRequest.get('/customer/download', { params: data, headers: getHeaders(getAuthToken()) });

// 7-11 客戶維護-產出客戶資料卡(新版)
export const API_POST_CustomerDownLoad = data => downloadRequest.post('/customer/download', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//#endregion

//#region 功能-8 供應商維護

//8-2 供應商維護-刪除
export const API_DEL_VendorData = data => baseRequest.delete('/vendor/data', { data: data, headers: getHeaders(getAuthToken()) });
//8-3 供應商維護-取得表格
export const API_GET_VendorDataCard = data => baseRequest.get('/vendor/dataCard', { headers: getHeaders(getAuthToken()) });
//8-4 供應商維護-查詢
export const API_GET_VendorQuery = data => baseRequest.get('/vendor/query', { params: data, headers: getHeaders(getAuthToken()) });
//8-5 供應商維護-查詢列表
export const API_GET_VendorList = data => baseRequest.get('/vendor/list', { params: data, headers: getHeaders(getAuthToken()) });
//8-6 供應商維護-查詢供應商修改請求
export const API_GET_VendorReview = data => baseRequest.get('/vendor/review', { params: data, headers: getHeaders(getAuthToken()) });
//8-7 供應商維護-修改
export const API_PUT_VendorData = data => baseRequest.put('/vendor/data', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//8-8 供應商維護-尋找請求(新改刪列表)
export const API_GET_VendorReviewList = data => baseRequest.get('/vendor/reviewList', { params: data, headers: getHeaders(getAuthToken()) });
//8-9 供應商維護-新增
export const API_POST_VendorData = data => baseRequest.post('/vendor/data', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//8-10 供應商維護-審核
export const API_PUT_VendorReview = data => baseRequest.put('/vendor/review', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//8-11 供應商維護-產出資料卡
export const API_GET_VendorDownLoad = data => downloadRequest.get('/vendor/download', { params: data, headers: getHeaders(getAuthToken()) });
//8-11 供應商維護-產出資料卡(新版)
export const API_POST_VendorDownLoad = data => downloadRequest.post('/vendor/download', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//#endregion

//#region 功能-9 幣別匯率
//9-1 幣別匯率-主頁
export const API_GET_ExchangeRateHome = data => baseRequest.get('/exchangeRate/home', { params: data, headers: getHeaders(getAuthToken()) });
//9-2 幣別匯率-匯率查詢
export const API_GET_ExchangeRateData = data => baseRequest.get('/exchangeRate/data', { params: data, headers: getHeaders(getAuthToken()) });
//9-3 幣別匯率-修改匯率
export const API_PUT_ExchangeRateData = data => baseRequest.put('/exchangeRate/data', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//9-4 幣別匯率-新增匯率
export const API_POST_ExchangeRateData = data => baseRequest.post('/exchangeRate/data', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//9-5 幣別匯率-新增幣別
export const API_POST_ExchangeRateNewCurrency = data => baseRequest.post('/exchangeRate/newCurrency', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//#endregion

//#region 功能-10 採購請款
//10-1 採購請款-主頁
export const API_GET_ProcurementHome = data => baseRequest.get('/procurement/home', { params: data, headers: getHeaders(getAuthToken()) });
//10-2 採購請款-列表搜尋
export const API_GET_ProcurementDataList = data => baseRequest.get('/procurement/dataList', { params: data, headers: getHeaders(getAuthToken()) });
//10-3 採購請款-新增
export const API_POST_ProcurementData = data => baseRequest.post('/procurement/data', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//10-4 採購請款-取得供應商聯絡人資料
export const API_GET_ProcurementSupplier = data => baseRequest.get('/procurement/supplier', { params: data, headers: getHeaders(getAuthToken()) });
//10-5 採購請款-修改
export const API_PUT_ProcurementData = data => baseRequest.put('/procurement/data', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//10-6 採購請款-刪除
export const API_DEL_ProcurementData = data => baseRequest.delete('/procurement/data', { params: data, headers: getHeaders(getAuthToken()) });
//10-7 採購請款-查詢
export const API_GET_ProcurementData = data => baseRequest.get('/procurement/data', { params: data, headers: getHeaders(getAuthToken()) });
//10-8 採購請款-取得表單內容
export const API_GET_ProcurementDataCard = data => baseRequest.get('/procurement/datacard', { params: data, headers: getHeaders(getAuthToken()) });
//10-9 採購請款-產出採購表
export const API_GET_ProcurementDownload = data => downloadRequest.get('/procurement/download', { params: data, headers: getHeaders(getAuthToken()) });
//#endregion

//#region 功能-11雲端帳號

//11-1 雲端帳號-主頁
export const API_GET_CloudAcntHome = data => baseRequest.get('/cloudAcnt/home', { params: data, headers: getHeaders(getAuthToken()) });
//11-2 雲端帳號-查詢列表1(有填客戶)
export const API_POST_CloudAcntList1 = data => baseRequest.post('/cloudAcnt/dataList1', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//11-3 雲端帳號-查詢列表2(沒填客戶)
export const API_POST_CloudAcntList2 = data => baseRequest.post('/cloudAcnt/dataList2', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//11-4 雲端帳號-查詢列表3
export const API_GET_CloudAcntList3 = data => baseRequest.get('/cloudAcnt/dataList3', { params: data, headers: getHeaders(getAuthToken()) });
//11-5 雲端帳號-查詢計費項目
export const API_GET_CloudAcntDiscountFormula = data => baseRequest.get('/cloudAcnt/discountFormula', { params: data, headers: getHeaders(getAuthToken()) });
//11-6 雲端帳號-新增
export const API_POST_CloudAcntData = data => baseRequest.post('/cloudAcnt/data', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//11-7 雲端帳號-修改
export const API_PUT_CloudAcntData = data => baseRequest.put('/cloudAcnt/data', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//11-8 雲端帳號-修改(特殊產品計費公式)
export const API_PUT_CloudAcntDiscountFormula = data => baseRequest.put('/cloudAcnt/discountFormula', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//11-9 雲端帳號-新增備註
export const API_POST_CloudAcntRemark = data => baseRequest.post('/cloudAcnt/remark', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//11-10 雲端帳號-取得備註
export const API_GET_CloudAcntRemark = data => baseRequest.get('/cloudAcnt/remark', { params: data, headers: getHeaders(getAuthToken()) });
//11-11 雲端帳號-轉移
export const API_POST_CloudAcntTransfer = data => baseRequest.post('/cloudAcnt/transfer', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//11-12 雲端帳號-審核
export const API_PUT_CloudAcntReview = data => baseRequest.put('/cloudAcnt/review', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//11-14 產品維護-待審查列表
export const API_GET_CloudAcntReviewList = data => baseRequest.get('/cloudAcnt/reviewList', { params: data, headers: getHeaders(getAuthToken()) });
//11-15 產品維護-待審核單
export const API_GET_CloudAcntUnderReview = data => baseRequest.get('/cloudAcnt/underReview', { params: data, headers: getHeaders(getAuthToken()) });
//11-16 雲端帳號-取得新增資料卡
export const API_GET_CloudAcntNewDataCard = data => baseRequest.get('/cloudAcnt/newDataCard', { headers: getHeaders(getAuthToken()) });
//11-17 雲端帳號-客戶ID取得負責業務
export const API_GET_CloudAcntCustPersonInCharge = data => baseRequest.get('/cloudAcnt/custPersonInCharge', { params: data, headers: getHeaders(getAuthToken()) });
//11-21 雲端帳號-轉移客戶畫面
export const API_GET_CloudAcntTransferData = data => baseRequest.get('/cloudAcnt/transferData', { params: data, headers: getHeaders(getAuthToken()) });
//11-22 雲端帳號-列出雲端細項
export const API_GET_CloudAcntCloudProduct = data => baseRequest.get('/cloudAcnt/cloudProduct', { params: data, headers: getHeaders(getAuthToken()) });
//11-23 雲端帳號-取得公司業務負責的客戶別-客戶抬頭-客戶統編，並取出這三個資訊(輸入框專用)
export const API_GET_CloudAcntSalesGetCustomer = data => baseRequest.get('/cloudAcnt/salesGetCustomer', { params: data, headers: getHeaders(getAuthToken()) });
//11-24 雲端帳號-取得RawData列表
export const API_GET_CloudAcntGetRawDataList = data => baseRequest.get('/rawDataBack/getRawDataList', { params: data, headers: getHeaders(getAuthToken()) });
//11-25 雲端帳號-批次匯入雲端帳號
export const API_POST_CloudAcntBatchImport = data => baseRequest.post('/cloudAcnt/batchImport', { params: data, headers: getHeaders(getAuthToken()) });
//11-26 雲端帳號-下載批次匯入匯入雲端帳號範本檔
export const API_GET_CloudAcntTemplateDownload = data => downloadRequest.get('/cloudAcnt/templateDownload', { params: data, headers: getHeaders(getAuthToken()) });
//11-27 雲端帳號-帶出雲端細項總稱
export const API_GET_CloudAcntCloudProductName1 = data => baseRequest.get('/cloudAcnt/cloudProdName1', { params: data, headers: getHeaders(getAuthToken()) });

//#region 功能-12雲端帳務 
//12-1 雲端帳務 報表欄位設定主頁(取得雲端細項)
export const API_GET_CloudAccountingGetProducts = data => baseRequest.get('/cloudAccounting/getProducts', { params: data, headers: getHeaders(getAuthToken()) });
//12-2 雲端帳務 報表欄位設定-讀取
export const API_GET_RawDataBackGetFieldSettings = data => baseRequest.get('/RawdataBack/getFieldSettings', { params: data, headers: getHeaders(getAuthToken()) });
//12-3 雲端帳務 報表欄位設定-儲存
export const API_POST_RawDataBackReportFieldSetting = data => baseRequest.post('/RawdataBack/ReportFieldSettings', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//12-4 雲端帳務 成本計算-讀取
export const API_GET_CloudAccountingCost = data => baseRequest.get('/cloudAccounting/cost', { params: data, headers: getHeaders(getAuthToken()) });
//12-5 雲端帳務 成本計算-儲存
export const API_POST_CloudAccountingCost = data => baseRequest.post('/cloudAccounting/cost', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//12-6 雲端帳務 匯入帳務資料(取得未月結雲端細項)
export const API_GET_CloudAccountingGetProductsA = data => baseRequest.get('/cloudAccounting/getProductsA', { params: data, headers: getHeaders(getAuthToken()) });
//12-7 雲端帳務 匯入帳務資料-讀取
export const API_GET_CloudAccountingFileData = data => baseRequest.get('/cloudAccounting/fileData', { params: data, headers: getHeaders(getAuthToken()) });
//12-8 雲端帳務 匯入帳務資料-儲存(form-data)
export const API_GET_CloudAccountingData = data => baseRequest.get('/cloudAccounting/Data', { params: data, headers: getHeaders(getAuthToken()) });
//12-9 雲端帳務 手動調帳-讀取
export const API_GET_CloudAccountingAdjust = data => baseRequest.get('/cloudAccounting/adjust', { params: data, headers: getHeaders(getAuthToken()) });
//12-10 雲端帳務 手動調帳-儲存
export const API_POST_CloudAccountingAdjust = data => baseRequest.post('/cloudAccounting/adjust', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//12-11 雲端帳務 預覽出帳(取得雲端細項總稱)
export const API_GET_CloudAccountingProductGroup = data => baseRequest.get('/cloudAccounting/productGroup', { params: data, headers: getHeaders(getAuthToken()) });
//12-12 雲端帳務 匯入預覽
export const API_GET_CloudAccountingPreview = data => baseRequest.get('/cloudAccounting/preview', { params: data, headers: getHeaders(getAuthToken()) });
//12-13 雲端帳務 出帳
export const API_POST_CloudAccountingBilling = data => baseRequest.post('/cloudAccounting/billing', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//12-14 雲端帳務 查詢主頁
export const API_GET_CloudAccountingSearchHome = data => baseRequest.get('/cloudAccounting/searchHome', { headers: getHeaders(getAuthToken()) });
//12-15 雲端帳務 查詢清單(客戶)
export const API_POST_CloudAccountingCustSearch = data => baseRequest.post('/cloudAccounting/custSearch', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//12-16 雲端帳務 查詢清單(產品)
export const API_POST_CloudAccountingProductSearch = data => baseRequest.post('/cloudAccounting/productSearch', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//12-17 雲端帳務 下載雲端對帳單
export const API_POST_CloudAccountingDownloadStatement = data => baseRequest.post('/cloudAccounting/downloadStatement', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//12-18 雲端帳務 取消
export const API_POST_CloudAccountingDataCancel = data => baseRequest.post('/cloudAccounting/dataCancel', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//12-19 雲端帳務  
//export const API_POST_CloudAccountingDataCheck = data => baseRequest.post('/cloudAccounting/datacheck', { params: data, headers: getHeaders(getAuthToken()) });
//12-20 雲端帳務 上傳檔案紀錄
export const API_GET_CloudAccountingUploadRecord = data => baseRequest.get('/cloudAccounting/uploadrecord', { params: data, headers: getHeaders(getAuthToken()) });
//12-21 雲端帳務  (form-data)
export const API_PUT_CloudAccountingTempSave = data => baseRequest.put('/cloudAccounting/tempsave', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//12-22 雲端帳務  檢查審核
export const API_GET_CloudAccountingChkAudit = data => baseRequest.get('/cloudAccounting/chkAudit', { headers: getHeaders(getAuthToken()) });
//12-23 雲端帳務  依照產品總稱刪除檔案&資料表
export const API_GET_CloudAccountingDeleteByProd1 = data => baseRequest.get('/cloudAccounting/deleteByProd1', { params: data, headers: getHeaders(getAuthToken()) });
//12-24 雲端帳務  刪除上傳資料
export const API_GET_CloudAccountingDeleteUpload = data => baseRequest.get('/cloudAccounting/deleteUpload', { params: data, headers: getHeaders(getAuthToken()) });
//12-25 雲端帳務  取得檔案處理狀態
export const API_GET_CloudAccountingCancelTxn = data => baseRequest.get('/cloudAccounting/cancelTxn', { params: data, headers: getHeaders(getAuthToken()) });
//12-26 雲端帳務  取得檔案處理狀態
export const API_GET_CloudAccountingGetFileStatus = data => baseRequest.get('/cloudAccounting/getFileStatus', { params: data, headers: getHeaders(getAuthToken()) });
//12-27 雲端帳務  重新上傳
export const API_POST_CloudAccountingDataCancelProdId = data => baseRequest.post('/cloudAccounting/dataCancelProdId', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//12-28 雲端帳務  產生處理完成後報表
export const API_GET_CloudAccountingGenProcessedReport = data => baseRequest.get('/cloudAccounting/genProcessedReport', { params: data, headers: getHeaders(getAuthToken()) });
//12-29 雲端帳務  取得交易處理狀態
export const API_GET_CloudAccountingGetTxnStatus = data => baseRequest.get('/cloudAccounting/getTxnStatus', { params: data, headers: getHeaders(getAuthToken()) });
//12-30 雲端帳務  產生處理完成後報表
export const API_GET_CloudAccountingGetFileDownLoadPath = data => baseRequest.get('/cloudAccounting/getFileDownloadPath', { params: data, headers: getHeaders(getAuthToken()) });
//12-31 雲端帳務 匯入後臺設定
export const API_PUT_ColudAccountImportRowData = data => baseRequest.put('/cloudAccounting/importRawData', { params: data, headers: getHeaders(getAuthToken()) });
//12-32 雲端帳務 匯入帳務資料 儲存檢查
export const API_PUT_ColudAccountImportSummary = data => baseRequest.put('/cloudAccounting/importSummary', { params: data, headers: getHeaders(getAuthToken()) });
//12-33 雲端帳務 後臺欄位設定檔名取得
export const API_GET_ColudAccountRowDataFileName = data => baseRequest.get('/cloudAccounting/rawDataFileName', { params: data, headers: getHeaders(getAuthToken()) });
//12-34 雲端帳務 後臺欄位Summary 明細檔名取得
export const API_GET_ColudAccountSummaryFileName = data => baseRequest.get('/cloudAccounting/summaryFileName', { params: data, headers: getHeaders(getAuthToken()) });
//#endregion

//#region 功能-13 信用額度查詢
//13-1 信用額度-主頁
export const API_GET_CustCreditDataCard = data => baseRequest.get('/custcredit/dataCard', { headers: getHeaders(getAuthToken()) });
//13-3 信用額度-查詢 13-3
//13-2 信用額度-列表查詢 13-1
export const API_GET_CustCreditData = data => baseRequest.get('/custcredit/data', { params: data, headers: getHeaders(getAuthToken()) });
//13-3 信用額度-查詢 13-3
export const API_GET_CustCreditDataList = data => baseRequest.get('/custcredit/datalist', { params: data, headers: getHeaders(getAuthToken()) });
//13-4 信用額度-新增修改 13-4
export const API_POST_CustCreditData = data => baseRequest.post('/custcredit/data', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });
//13-5 信用額度-及時計算
export const API_GET_CustCreditCalCredBal = data => baseRequest.get('/custcredit/calcredbal', { params: data, headers: getHeaders(getAuthToken()) });
//#endregion

//#region 功能-14 保證金儲值金查詢

//14-1 客戶保證金-保證金-保證金查詢
export const API_GET_PrepassData = data => baseRequest.get('/prepass/data', { params: data, headers: getHeaders(getAuthToken()) });
//14-2 客戶保證金-保證金-查詢歷史修改  
export const API_GET_PrepassRecord = data => baseRequest.get('/prepass/Record', { params: data, headers: getHeaders(getAuthToken()) });
//14-3 客戶保證金-保證金-修改(formdata)
export const API_PUT_PrepassData = data => baseRequest.put('/prepass/data', { params: data, headers: getHeaders(getAuthToken()) });
//14-4 客戶保證金-預付金-主頁
export const API_GET_PrepassPreHome = data => baseRequest.put('/prepass/prehome', { headers: getHeaders(getAuthToken()) });
//14-5 客戶保證金-預付金-列表查詢
export const API_GET_PrepassDataList = data => baseRequest.get('/prepass/datalist', { params: data, headers: getHeaders(getAuthToken()) });
//14-6 客戶保證金-預付金-查詢
export const API_GET_PrepassPreData = data => baseRequest.get('/prepass/predata', { params: data, headers: getHeaders(getAuthToken()) });
//14-7 客戶保證金-預付金-查詢修改歷史
export const API_GET_PrepassPreRecord = data => baseRequest.get('/prepass/prerecord', { params: data, headers: getHeaders(getAuthToken()) });
//14-8 客戶保證金-預付金-修改(formdata)
export const API_PUT_PrepassPreData = data => baseRequest.put('/prepass/predata', formData, { headers: getHeaders(getAuthToken()) });
//14-9 供應商保證金-查詢
export const API_GET_PrepassVendorData = data => baseRequest.get('/prepass/vendordata', { params: data, headers: getHeaders(getAuthToken()) });
//14-10 供應商保證金-查詢歷史修改
export const API_GET_PrepassVendorRecord = data => baseRequest.get('/prepass/vendorRecord', { params: data, headers: getHeaders(getAuthToken()) });
//14-11 供應商保證金-修改(formdata)
export const API_PUT_PrepassVendorData = data => baseRequest.put('/prepass/vendordata', { params: data, headers: getHeaders(getAuthToken()) });
//14-12 供應商保證金-預付金-主頁
export const API_GET_PrepassVendorHome = data => baseRequest.get('/prepass/vendorhome', { headers: getHeaders(getAuthToken()) });
//14-13 供應商預付金-列表查詢
export const API_GET_PrepassVendorDataList = data => baseRequest.get('/prepass/vendordatalist', { params: data, headers: getHeaders(getAuthToken()) });
//14-14 供應商預付金-查詢
export const API_GET_PrepassVendorPreData = data => baseRequest.get('/prepass/vendorpredata', { params: data, headers: getHeaders(getAuthToken()) });
//14-15 供應商預付金-查詢修改歷史
export const API_GET_PrepassVendorPreRecord = data => baseRequest.get('/prepass/vendorprerecord', { params: data, headers: getHeaders(getAuthToken()) });
//14-16 供應商預付金-修改(formdata)
export const API_PUT_PrepassVendorPreData = data => baseRequest.put('/prepass/vendorpredata', { params: data, headers: getHeaders(getAuthToken()) });
//14-17 客戶-即時運算結存餘額
export const API_GET_Balance = data => baseRequest.get('/prepass/getBalance', { params: data, headers: getHeaders(getAuthToken()) });

//#endregion
//#region 功能-20 訪談記錄維護
//20-1  訪談記錄維護-主頁
export const API_GET_InterViewDataCard = data => baseRequest.get('/interview/dataCard', { params: data, headers: getHeaders(getAuthToken()) });
//20-2  訪談記錄維護-查詢列表
export const API_POST_InterViewList = data => baseRequest.post('/interview/datalist', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//20-3  訪談記錄維護-單位訪談紀錄
export const API_GET_InterViewRecords = data => baseRequest.get('/interview/records', { params: data, headers: getHeaders(getAuthToken()) });
//20-4  訪談記錄維護-帶出訪談紀錄
export const API_GET_InterViewGetData = data => baseRequest.get('/interview/getdata', { params: data, headers: getHeaders(getAuthToken()) });
//20-5  訪談記錄維護-修改訪談紀錄
export const API_PUT_InterViewEdit = data => baseRequest.put('/interview/edit', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//20-6  訪談記錄維護-新增訪談紀錄
export const API_POST_InterViewAdd = data => baseRequest.post('/interview/add', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//#endregion

//#region ERP
export const API_GET_ERP_AccountWriteOff = data => baseRequest.get('/erpapi/accountWriteOff', { params: data, headers: getHeaders(getAuthToken()) });

//#endregion
//#region 共用

//8-1 供應商維護-主頁
//13-1 信用額度-主頁
//20-1 訪談記錄維護-主頁
export const API_GET_AllCompany = data => baseRequest.get('/universal/getallcompany', { params: data, headers: getHeaders(getAuthToken()) });
//共用A
export const API_GET_GetExRates = data => baseRequest.get('/universal/GetExRates', { params: data, headers: getHeaders(getAuthToken()) });
//共用AA
export const API_GET_AdNameList = data => baseRequest.get('/universal/adName', { params: data, headers: getHeaders(getAuthToken()) });
//AA-通用-取得公司幣別中英文
export const API_GET_CompanyCurrNames = data => baseRequest.get('/universal/companyCurrNames', { params: data, headers: getHeaders(getAuthToken()) });
//共用AB
export const API_GET_FileDownload = data => downloadRequest.get('/universal/fileDownload', { params: data, headers: getHeaders(getAuthToken()) });
//共用AC
export const API_GET_customerTitleList = data => baseRequest.get('/universal/customerTitleList', { params: data, headers: getHeaders(getAuthToken()) });
//共用B
export const API_GET_SupplierNameList = data => baseRequest.get('/universal/supplierName', { params: data, headers: getHeaders(getAuthToken()) });
//共用C
export const API_GET_SupplierTitleList = data => baseRequest.get('/universal/supplierTitle', { params: data, headers: getHeaders(getAuthToken()) });
//共用D
export const API_GET_CustomerName = data => baseRequest.get('/universal/customerName', { params: data, headers: getHeaders(getAuthToken()) });
//共用E
export const API_GET_CustomerTitle = data => baseRequest.get('/universal/customerTitle', { params: data, headers: getHeaders(getAuthToken()) });
//共用F
export const API_GET_ProductName2 = data => baseRequest.get('/universal/productname2', { params: data, headers: getHeaders(getAuthToken()) });
//共用G
export const API_GET_ProductName1 = data => baseRequest.get('/universal/productname1', { params: data, headers: getHeaders(getAuthToken()) });
//共用H
export const API_GET_CurrencyExchangeRate = data => baseRequest.get('/universal/currencyExchangeRate', { params: data, headers: getHeaders(getAuthToken()) });
//共用H-2
export const API_GET_CurrencyComExchangeRate = data => baseRequest.get('/universal/currencyComExchangeRate', { params: data, headers: getHeaders(getAuthToken()) });
//共用I
export const API_GET_Customer3Name = data => baseRequest.get('/universal/customer3Name', { params: data, headers: getHeaders(getAuthToken()) });
//customerName / customerTitle / customerTaxId
//共用J
export const API_GET_CustomerTaxId = data => baseRequest.get('/universal/customerTaxId', { params: data, headers: getHeaders(getAuthToken()) });
//共用K
export const API_GET_OrderNumber = data => baseRequest.get('/universal/orderNumber', { params: data, headers: getHeaders(getAuthToken()) });
//共用L
export const API_GET_SupplierTaxId = data => baseRequest.get('/universal/vendorTaxId', { params: data, headers: getHeaders(getAuthToken()) });
//共用M
export const API_GET_ProductName1List = data => baseRequest.get('/universal/productName1List', { params: data, headers: getHeaders(getAuthToken()) });
//共用N
export const API_GET_OrderData = data => baseRequest.get('/universal/orderData', { params: data, headers: getHeaders(getAuthToken()) });
//共用O
export const API_GET_CustomerNickName = data => baseRequest.get('/universal/customerNickname', { params: data, headers: getHeaders(getAuthToken()) });
//共用R
export const API_GET_AllCurrency = data => baseRequest.get('/universal/getAllCurrency', { params: data, headers: getHeaders(getAuthToken()) });
//共用T 依公司為基準抓產品別
export const API_GET_CompanyProductType = data => baseRequest.get('/universal/companyProductType', { params: data, headers: getHeaders(getAuthToken()) });
//共用U 關鍵字撈出所有供應商別-供應商抬頭-供應商統編，並取出這三個資訊(輸入框專用)
export const API_GET_Vendor3Name = data => baseRequest.get('/universal/vendor3Name', { params: data, headers: getHeaders(getAuthToken()) });
//共用W
export const API_GET_CustomerCurrNames = data => baseRequest.get('/universal/customerCurrNames', { params: data, headers: getHeaders(getAuthToken()) });
//共用X
export const API_GET_VendorCurrNames = data => baseRequest.get('/universal/vendorCurrNames', { params: data, headers: getHeaders(getAuthToken()) });
//共用Y
export const API_GET_AuditHistory = data => baseRequest.get('/universal/auditHistory', { params: data, headers: getHeaders(getAuthToken()) });
//共用Z 審核申請撤銷
export const API_POST_Revoke = data => baseRequest.post('/universal/revoke', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//檔案下載
export const API_DOWNLOAD_FILE = `${API_HOST}/download/file?id=`;
//AD List
export const API_GET_ADList = data => baseRequest.get('/account/ADList', { params: data, headers: getHeaders(getAuthToken()) });
//下載相關(未沖銷)
export const API_GET_DownLoadGetFile = data => downloadRequest.get('/download/getfile', { params: data, headers: getHeaders(getAuthToken()) });
export const API_GET_DownLoadGetFile2 = data => downloadRequest.get('/download/DownloadFile', { params: data, headers: getHeaders(getAuthToken()) });
//#endregion









