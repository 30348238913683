import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_HOST,
    API_GET_RawDataBackGetFieldSettings,
    API_POST_RawDataBackReportFieldSetting,
    API_GET_ColudAccountRowDataFileName,
    API_GET_DownLoadGetFile,
    API_GET_ColudAccountSummaryFileName
} from "../../global/constants";
import { InitJS, Clone, OpenModal, CloseModal, SetOnbeforeunload, MenuSet, SetHasChange } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
import FileDownloadJS from "js-file-download";

const CATRowSetAdd = () => {
    var history = useHistory();
    var onbeforeunloadFlag = false;

    //url param handle
    const queryParams = new URLSearchParams(window.location.search);
    const productId = parseInt(queryParams.get("productName2Id"));

    const [productName2, setProductName2] = useState();
    const [settingData, setSettingData] = useState([{}]);
    const [changeIdArray, setChangeIdArray] = useState([]);
    const [modalStr, setModalStr] = useState();

    const [columnSettingFileName, setColumnSettingFileName] = useState(null);
    const [biliSummaryFileName, setBiliSummaryFileName] = useState(null);
    const [cloudSaleDetailFileName, setCloudSaleDetailFileName] = useState(null);

    const [columnSettingFile, setColumnSettingFile] = useState(null);
    const [biliSummaryFile, setBiliSummaryFile] = useState(null);
    const [cloudSaleDetailFile, setCloudSaleDetailFile] = useState(null);

    useEffect(() => {
        //把menu顏色置換
        MenuSet("CATRowSetQry");
        getData();
        getRawDataFileName();
        getBiliSummaryFileName();
        getCloudSaleDetailFileName();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    useEffect(() => {
        console.log("檔案", [columnSettingFileName, columnSettingFile])
    }, [columnSettingFileName, columnSettingFile])

    function getData() {
        API_GET_RawDataBackGetFieldSettings({
            productId: productId
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setProductName2(data.result.data.productName2);

                    var settingData = data.result.data.settingData;
                    if (settingData && settingData.length == 0) {
                        settingData.push({
                            id: null,
                            reportName: "",
                            rawData: "",
                            mark: "",
                        });
                    }

                    setSettingData(data.result.data.settingData);
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function getRawDataFileName() {
        API_GET_ColudAccountRowDataFileName({
            productId: productId
        })
            .then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setColumnSettingFileName(data.result.data.fileName);
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function getBiliSummaryFileName() {
        API_GET_ColudAccountSummaryFileName({
            productId: productId,
            type: 1
        })
            .then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setBiliSummaryFileName(data.result.data.fileName);
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function getCloudSaleDetailFileName() {
        API_GET_ColudAccountSummaryFileName({
            productId: productId,
            type: 2
        })
            .then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setCloudSaleDetailFileName(data.result.data.fileName);
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function GetFile(file) {
        document.getElementById(file).click();
    }

    const ModalType = {
        ColumnSetting: "modal-catrowadd-input-columnsetting",
        Summary: "modal-catrowadd-input-bilisummary",
        Detail: "modal-catrowadd-input-cloudsaledetail"
    }

    function GetFileItemName(modalType) {
        return modalType + "-file"
    }

    function GetModalApiType(modalType) {
        switch (modalType) {
            case ModalType.ColumnSetting:
                return "importRawData";
            case ModalType.Summary:
            case ModalType.Detail:
                return "importSummary";
        }
    }

    function GetModalFile(modalType) {
        switch (modalType) {
            case ModalType.ColumnSetting:
                return columnSettingFile;
            case ModalType.Summary:
                return biliSummaryFile;
            case ModalType.Detail:
                return cloudSaleDetailFile;
        }
    }

    function GetModalSetFile(modalType) {
        switch (modalType) {
            case ModalType.ColumnSetting:
                return setColumnSettingFile;
            case ModalType.Summary:
                return setBiliSummaryFile;
            case ModalType.Detail:
                return setCloudSaleDetailFile;
        }
    }

    function GetModalFileName(modalType) {
        switch (modalType) {
            case ModalType.ColumnSetting:
                return columnSettingFileName;
            case ModalType.Summary:
                return biliSummaryFileName;
            case ModalType.Detail:
                return cloudSaleDetailFileName;
        }
    }

    function GetModalSetFileName(modalType) {
        switch (modalType) {
            case ModalType.ColumnSetting:
                return setColumnSettingFileName;
            case ModalType.Summary:
                return setBiliSummaryFileName;
            case ModalType.Detail:
                return setCloudSaleDetailFileName;
        }
    }

    useEffect(() => {
        let logData = [columnSettingFile, biliSummaryFile, cloudSaleDetailFile];
        console.log("檔案狀態", logData);
    }, [columnSettingFile, biliSummaryFile, cloudSaleDetailFile])

    useEffect(() => {
        console.log("列表資料", settingData);
    }, [settingData])

    function SaveFile(modal) {
        var file = GetModalFile(modal);
        if (IsExcelFile(file)) {
            let formData = new FormData();
            formData.append('productId', productId);

            if (modal === ModalType.Summary)
                formData.append('type', 1);
            else if (modal == ModalType.Detail)
                formData.append('type', 2);

            formData.append('file', GetModalFile(modal));

            const requestOptions = {
                method: 'PUT',
                headers: {
                    "Authorization": localStorage.getItem("token"),
                },
                body: formData,
            };

            let path = API_HOST + "/cloudAccounting/" + GetModalApiType(modal);
            fetch(path, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (!CheckResponseCode(data)) {
                        CloseModalWithFile(modal);
                        return;
                    } //檢查response code
                    if (data.code == "0000") {
                        var setFileName = GetModalSetFileName(modal);
                        setFileName(data.result.data);
                        getData();

                        CloseModalWithFile(modal);
                        OpenModal("modal-success");
                    }
                    else {
                        CloseModalWithFile(modal);
                        setModalStr(data.message);
                        OpenModal("modal-error");
                    }
                })
                .catch((err) => {
                    CloseModalWithFile(modal);
                    if (err.response != null) {
                        if (err.response.data != null) {
                            if (err.response.data.code == "1001") {
                                localStorage.clear();
                                history.push("/");
                            }
                        }

                        if (err.response.status === 401) {
                            localStorage.clear();
                            history.push("/");
                        }
                    }
                    else {
                        setModalStr("未知的錯誤");
                        OpenModal("modal-error");
                    }

                });
        }
        else {
            CloseModalWithFile(modal);
            setModalStr("檔案名稱不合法！");
            OpenModal("modal-error");
        }
    }

    function FileDownload(fileName) {
        API_GET_DownLoadGetFile({
            fileName: fileName,
        }).then((response) => {
            console.log([response.data, fileName]);
            FileDownloadJS(response.data, fileName);
        })
    }

    function CloseModalWithFile(modal) {
        CloseModal(modal);
        var setFile = GetModalSetFile(modal);
        setFile(null);
    }

    function FileOnChange(e, modal) {
        var setFile = GetModalSetFile(modal);
        setFile(e.target.files[0]);
    }

    function IsFileCompliant(file) {
        if (file == null)
            return true;

        return IsExcelFile(file);
    }

    function IsExcelFile(file) {
        if (file == null)
            return false;

        // 取得檔案名稱
        var fileName = file.name;
        // 檢查檔案副檔名是否為.xlsx或.xls
        return /\.xlsx|\.xls$/.test(fileName);
    }

    function AddRawData() {
        if (onbeforeunloadFlag == false) {
            SetOnbeforeunload();
            onbeforeunloadFlag = true;
        }
        setSettingData(function (prev) {
            return [...prev,
            {
                id: null,
                mark: "",
                rawData: "",
                reportName: "",
            }];


        });
    }
    //資料修改
    function settingDataChange(e, index, col) {
        if (onbeforeunloadFlag == false) {
            SetOnbeforeunload();
            onbeforeunloadFlag = true;
        }
        setSettingData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "reportName") {
                        item.reportName = e.target.value;
                    } else if (col == "rawData") {
                        item.rawData = e.target.value;
                    } else if (col == "mark") {
                        item.mark = e.target.value;
                    }
                }
                return item;
            });
        });

        //送新增/修改 只要送有改資料的id就好 因此在這邊存ID
        if (settingData[index].id != null && settingData[index].id != "") {
            if (changeIdArray.indexOf(settingData[index].id) < 0) {
                changeIdArray.push(settingData[index].id);
            }
        }

        checkData();

    }

    function Save(e) {
        var result = checkData();
        if (result) {
            e.target.classList.add('disabled');
            var tmpSendData = [];
            var tmpData = Clone(settingData);
            for (var i = 0; i < tmpData.length; i++) {
                if (tmpData[i].id == null || tmpData[i].id == "" || changeIdArray.indexOf(settingData[i].id) >= 0) {
                    tmpSendData.push(tmpData[i]);
                }
            }
            var dt = {
                productId: parseInt(queryParams.get("productName2Id")),
                settingData: tmpData,
            };
            //console.log(JSON.stringify(dt));
            API_POST_RawDataBackReportFieldSetting(dt)
                .then((response) => response.data)
                .then((data) => {
                    e.target.classList.remove('disabled');
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        OpenModal("modal-ok");
                        window.onbeforeunload = null;
                        onbeforeunloadFlag = false;
                        SetHasChange(false);
                    }
                    else {
                        setModalStr(data.message);
                        OpenModal("modal-error");
                    }
                })
                .catch((err) => {

                    e.target.classList.remove('disabled');
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
        else {
            e.target.classList.remove('disabled');
        }
    }

    function checkData() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {
            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });
        return result;
    }

    function reflash() {
        window.location.reload();
    }
    return (
        <>
            <div className="content-body">
                <div className="title-area">
                    <div className="container">
                        <h3 className="fz-B has-border">{productName2}</h3>
                    </div>
                </div>
                <div className="container">
                    <div class="form-container for-12-1">
                        <div class="form-item no-field three">
                            <div class="field">匯入欄位設定</div>
                            <div class="value multi-inline">
                                <button class="btn btn-middle btn-border full" onClick={() => OpenModal(ModalType.ColumnSetting)}>
                                    <span>匯入欄位設定</span>
                                </button>
                            </div>
                            <div class="help-word">請輸入正確格式</div>
                            {GetModalFileName(ModalType.ColumnSetting) == "" ?
                                <>
                                    <div class="extra-filed" style={{ visibility: "hidden" }}>
                                        <div class="filename"></div>
                                        <i class="icon icon-49"></i>
                                    </div>
                                </>
                                :
                                <>
                                    <div class="extra-filed">
                                        <div class="filename">{GetModalFileName(ModalType.ColumnSetting)}</div>
                                        <i class="icon icon-49" onClick={() => FileDownload(GetModalFileName(ModalType.ColumnSetting))}></i>
                                    </div>
                                </>
                            }
                        </div>

                        <i class="line"></i>

                        <div class="form-item no-field three">
                            <div class="field">匯入Billing summary樣板</div>
                            <div class="value multi-inline">
                                <button class="btn btn-middle btn-border full" onClick={() => OpenModal(ModalType.Summary)}>
                                    <span>匯入Billing summary樣板</span>
                                </button>
                            </div>
                            <div class="help-word">請輸入正確格式</div>
                            {GetModalFileName(ModalType.Summary) == "" ?
                                <>
                                    <div class="extra-filed" style={{ visibility: "hidden" }}>
                                        <div class="filename"></div>
                                        <i class="icon icon-49"></i>
                                    </div>
                                </>
                                :
                                <>
                                    <div class="extra-filed">
                                        <div class="filename">{GetModalFileName(ModalType.Summary)}</div>
                                        <i class="icon icon-49" onClick={() => FileDownload(GetModalFileName(ModalType.Summary))}></i>
                                    </div>
                                </>
                            }
                        </div>

                        <i class="line"></i>

                        <div class="form-item no-field three">
                            <div class="field">匯入雲端對帳明細樣板</div>
                            <div class="value multi-inline">
                                <button class="btn btn-middle btn-border full" onClick={() => OpenModal(ModalType.Detail)}>
                                    <span>匯入雲端對帳明細樣板</span>
                                </button>
                            </div>
                            <div class="help-word">匯入雲端對帳明細樣板</div>
                            {GetModalFileName(ModalType.Detail) == "" ?
                                <>
                                    <div class="extra-filed" style={{ visibility: "hidden" }}>
                                        <div class="filename"></div>
                                        <i class="icon icon-49"></i>
                                    </div>
                                </>
                                :
                                <>
                                    <div class="extra-filed">
                                        <div class="filename">{GetModalFileName(ModalType.Detail)}</div>
                                        <i class="icon icon-49" onClick={() => FileDownload(GetModalFileName(ModalType.Detail))}></i>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>

                <hr />

                <div className="container">
                    <ul className="list-table database-style">
                        <li className="lt-row lt-th">
                            <div className="flex-4 center"></div>
                            <div className="flex-1 ems"></div>
                            <div className="flex-5 center" style={{ color: "#424242" }}>報表欄位名稱</div>
                            <div className="flex-1 ems"></div>
                            <div className="flex-5 center" style={{ color: "#424242" }}>Raw Data 欄位名稱</div>
                            <div className="flex-1 ems"></div>
                            <div className="flex-5 center" style={{ color: "#424242" }}>標籤</div>
                            <div className="flex-1 ems"></div>
                            <div className="flex-5 center"></div>
                        </li>
                        {
                            settingData.map((x, i) =>
                                <li key={i} className="lt-row">
                                    <div className="flex-4 center">
                                        {(i + 1)}
                                    </div>
                                    <div className="flex-1 ems">
                                        <i className="icon icon-23"></i></div>
                                    <div className="flex-5">
                                        <input type="text" value={x.reportName} onChange={(e) => settingDataChange(e, i, "reportName")} placeholder="請輸入報表欄位名稱" data-required />
                                    </div>
                                    <div className="flex-1 ems">
                                        <i className="icon icon-23"></i>
                                    </div>
                                    <div className="flex-5">
                                        <input type="text" value={x.rawData} onChange={(e) => settingDataChange(e, i, "rawData")} placeholder="請輸入Raw Data 欄位名稱" />
                                    </div>
                                    <div className="flex-1 ems">
                                        <i className="icon icon-23"></i>
                                    </div>
                                    <div className="flex-5">
                                        <input type="text" value={x.mark} onChange={(e) => settingDataChange(e, i, "mark")} placeholder="請輸入標籤名稱" />
                                    </div>

                                    <div className="flex-5">
                                        <i className="icon icon-37" onClick={() => {
                                            if (x.reportName === "果核最終成本" || x.reportName === "客戶應繳金額") {
                                                setModalStr(x.reportName + "不可刪除");
                                                OpenModal("modal-error");
                                            }
                                            else {
                                                let newDatas = [...settingData.slice(0, i), ...settingData.slice(i + 1)];
                                                setSettingData(newDatas);
                                            }
                                        }}></i>
                                    </div>
                                </li>
                            )
                        }
                    </ul>
                    <div className="btn-area jcsb">
                        <div className="info">

                        </div>
                        <div className="btn btn-icon-text clean-width" onClick={AddRawData}>
                            <i className="icon icon-25"></i>
                            <span>新增欄位</span>
                        </div>
                    </div>

                    <div className="form-item btn-area sp">
                        <div className="left">

                        </div>
                        <div className="right">

                            <button className="btn" onClick={Save}>
                                <span>儲存</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-ok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            儲存成功
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={reflash}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id={ModalType.ColumnSetting} className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">匯入欄位設定</h3>
                        <span className="close" onClick={() => CloseModalWithFile(ModalType.ColumnSetting)}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className={`form-item w80 no-field ${IsFileCompliant(columnSettingFile) ? '' : 'error show-help'}`}>
                            <div class="field">匯入欄位設定 樣板</div>
                            <div className="value multi-inline">
                                <div>
                                    <input type="file" className="real" id={GetFileItemName(ModalType.ColumnSetting)} style={{ "display": "none" }} onChange={(e) => FileOnChange(e, ModalType.ColumnSetting)} key={columnSettingFile} />
                                    <input type="text" className="fake" readonly="readonly" placeholder="請選擇檔案" value={columnSettingFile == null ? '' : columnSettingFile.name} onClick={() => GetFile(GetFileItemName(ModalType.ColumnSetting))} />
                                </div>
                                <button className="btn btn-middle btn-border" onClick={() => GetFile(GetFileItemName(ModalType.ColumnSetting))}>
                                    <span>選擇檔案</span>
                                </button>
                            </div>
                            <span className="help-word right">
                                請選擇正確的檔案格式
                            </span>
                        </div>

                        <div className="form-item btn-area">
                            <button className="btn btn-border" onClick={() => CloseModalWithFile(ModalType.ColumnSetting)}>
                                <span>取消</span>
                            </button>
                            <button className={`btn ${IsExcelFile(columnSettingFile) ? '' : 'disabled'}`}
                                onClick={() => SaveFile(ModalType.ColumnSetting)}>
                                <span>儲存</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id={ModalType.Summary} className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">匯入Billing summary樣板</h3>
                        <span className="close" onClick={() => CloseModalWithFile(ModalType.Summary)}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className={`form-item w80 no-field ${IsFileCompliant(biliSummaryFile) ? '' : 'error show-help'}`}>
                            <div class="field">匯入Billing summary樣板</div>
                            <div className="value multi-inline">
                                <div>
                                    <input type="file" className="real" id={GetFileItemName(ModalType.Summary)} style={{ "display": "none" }} onChange={(e) => FileOnChange(e, ModalType.Summary)} key={biliSummaryFile} />
                                    <input type="text" className="fake" readonly="readonly" placeholder="請選擇檔案" value={biliSummaryFile == null ? '' : biliSummaryFile.name} onClick={() => GetFile(GetFileItemName(ModalType.Summary))} />
                                </div>
                                <a className="btn btn-middle btn-border" onClick={() => GetFile(GetFileItemName(ModalType.Summary))}>
                                    <span>選擇檔案</span>
                                </a>
                            </div>
                            <span className="help-word right">
                                請選擇正確的檔案格式
                            </span>
                        </div>

                        <div className="form-item btn-area">
                            <button className="btn btn-border" onClick={() => CloseModalWithFile(ModalType.Summary)}>
                                <span>取消</span>
                            </button>
                            <button className={`btn ${IsExcelFile(biliSummaryFile) ? '' : 'disabled'}`}
                                onClick={() => SaveFile(ModalType.Summary)}>
                                <span>儲存</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id={ModalType.Detail} className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">匯入雲端對帳明細樣板</h3>
                        <span className="close" onClick={() => CloseModalWithFile(ModalType.Detail)}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className={`form-item w80 no-field ${IsFileCompliant(cloudSaleDetailFile) ? '' : 'error show-help'}`}>
                            <div class="field">匯入雲端對帳明細樣板</div>
                            <div className="value multi-inline">
                                <div>
                                    <input type="file" className="real" id={GetFileItemName(ModalType.Detail)} style={{ "display": "none" }} onChange={(e) => FileOnChange(e, ModalType.Detail)} key={cloudSaleDetailFile} />
                                    <input type="text" className="fake" readonly="readonly" placeholder="請選擇檔案" value={cloudSaleDetailFile == null ? '' : cloudSaleDetailFile.name} onClick={() => GetFile(GetFileItemName(ModalType.Detail))} />
                                </div>
                                <a className="btn btn-middle btn-border" onClick={() => GetFile(GetFileItemName(ModalType.Detail))}>
                                    <span>選擇檔案</span>
                                </a>
                            </div>
                            <span className="help-word right">
                                請選擇正確的檔案格式
                            </span>
                        </div>

                        <div className="form-item btn-area">
                            <button className="btn btn-border" onClick={() => CloseModalWithFile(ModalType.Detail)}>
                                <span>取消</span>
                            </button>
                            <button className={`btn ${IsExcelFile(cloudSaleDetailFile) ? '' : 'disabled'}`}
                                onClick={() => SaveFile(ModalType.Detail)}>
                                <span>儲存</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CATRowSetAdd;
