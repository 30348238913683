import React, { useState, useEffect, useRef } from "react";
import HtmlParse from 'html-react-parser';
import {
    API_POST_AccountChargeOffDataList22,
    API_POST_AccountChargeOffDataList1,
    API_GET_AccountChargeOffData,
    API_PUT_AccountChargeOffSave,
    API_POST_AccountChargeOffData,
    API_PUT_AccountChargeOffData,
    API_DEL_AccountChargeOffData,
    API_POST_AccountChargeOffAudit,
    API_POST_AccountChargeOffDownLoad,
    API_GET_DownLoadGetFile,
    API_GET_customerTitleList
} from "../../global/constants";
import { OpenModal, CloseModal, InitJS, NumFormart, NewNumFormart, isExistDate, UrlDecode, SetOnbeforeunload, RoundTo2Compare, MenuSet, SetHasChange } from "../../global/ToolUtil";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const AccChargeList = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        //把menu顏色置換
        MenuSet("AccChargeQry");
        getData();
        const timeout = setTimeout(() => {
            SetOnbeforeunload("未沖銷憑證尚未送出申請，", 2);
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    //const [ODataList, setODataList] = useState([{}]);
    const [custList, setcustList] = useState([{}]);
    const [arMsg, setarMsg] = useState("");
    const [TmpDate, setTmpDate] = useState({ id: "", dateStr: "" });
    const [custFilterValue, setCustFilterValue] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);

    function getData() {
        setcustList([]);
        if (queryParams.get("customerId") == null) {
            API_POST_AccountChargeOffDataList1({
                companyId: parseInt(queryParams.get("companyId")),
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        setAccountingSubjectArray(data.result.data.accountingSubjectArray);
                        setRemittanceAccount(data.result.data.remittanceAccount);
                        setCompanyName(data.result.data.companyName);
                        setCompanyId(data.result.data.companyId);
                        var dt = data.result.data.data;
                        for (var i = 0; i < dt.length; i++) {
                            dt[i].expiryDate = dt[i].expiryDate == null ? null : dt[i].expiryDate.replace(new RegExp('/', 'g'), '-');
                            dt[i].incomingDate = dt[i].incomingDate == null ? null : dt[i].incomingDate.replace('T00:00:00', ' ').trim();
                            dt[i].exchangeRate = (dt[i].exchangeRate == null || dt[i].exchangeRate == "") ? 1 : dt[i].exchangeRate;
                            dt[i].isShow = true;
                        }
                        setDataList(dt);
                        //setODataList(dt);
                        setCompanyId(data.result.data.companyId);
                        setCurrencyEn(data.result.data.currencyEn);
                        setCurrencyName(data.result.data.currencyName);
                    }
                })
                .catch((err) => {
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
        else {
            API_POST_AccountChargeOffDataList22({
                companyId: parseInt(queryParams.get("companyId")),
                customerId: parseInt(queryParams.get("customerId"))
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        setAccountingSubjectArray(data.result.data.accountingSubjectArray);
                        setRemittanceAccount(data.result.data.remittanceAccount);
                        setCompanyName(data.result.data.companyName);
                        setCompanyId(data.result.data.companyId);
                        var dt = data.result.data.data;
                        for (var i = 0; i < dt.length; i++) {
                            dt[i].expiryDate = dt[i].expiryDate == null ? null : dt[i].expiryDate.replace(new RegExp('/', 'g'), '-');
                            dt[i].isShow = true;
                        }
                        setDataList(dt);
                        setCompanyId(data.result.data.companyId);
                        setCurrencyEn(data.result.data.currencyEn);
                        setCurrencyName(data.result.data.currencyName);
                    }
                })
                .catch((err) => {
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
        API_GET_AccountChargeOffData({
            companyId: parseInt(queryParams.get("companyId"))
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setTmpDataList(data.result.data);
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });


    }
    const [modalStr, setModalStr] = useState();
    const [accountingSubjectArray, setAccountingSubjectArray] = useState([]);
    const [remittanceAccount, setRemittanceAccount] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [tmpDataList, setTmpDataList] = useState([]);
    const [companyId, setCompanyId] = useState();
    const [companyName, setCompanyName] = useState();
    const [currencyEn, setCurrencyEn] = useState();
    const [currencyName, setCurrencyName] = useState();
    const [asID, setAsID] = useState();
    const [asIDError, setasIDError] = useState(false);
    const [isTicket, setIsTicket] = useState(null);
    const [incomingAmountError, setincomingAmountError] = useState(false);
    const [exchangeRateError, setexchangeRateError] = useState(false);
    const [handlingFeeError, sethandlingFeeError] = useState(false);
    const [incomingDateError, setincomingDateError] = useState(false);

    const [newCawCustName, setNewCawCustName] = useState();
    const [newCawCustNameSave, setNewCawCustNameSave] = useState(); //儲存選擇暫收客戶名稱
    const [newCawCustNameError, setNewCawCustNameError] = useState(false);
    const [newCawAmtsIn, setNewCawAmtsIn] = useState(0);
    const [newCawAmtsInError, setNewCawAmtsInError] = useState(false);
    const [newCawAmtsExRate, setNewCawAmtsExRate] = useState(1);
    const [newCawAmtsExRateError, setNewCawAmtsExRateError] = useState(false);
    const [newCawRem, setNewCawRem] = useState();

    class DetailError {
        constructor(data) {
            this.index = data.index;
            this.column = data.column;
        }
    }
    const [detailErrorList, setDetailErrorList] = useState([]);

    function asIDChange(e) {
        setAsID(e.target.value);
        setasIDError(false);
        var ticket = false;
        for (var i = 0; i < remittanceAccount.length; i++) {
            if (e.target.value == remittanceAccount[i].asid) {
                if (remittanceAccount[i].asName.indexOf("票據") >= 0) {
                    setIsTicket(true);
                    ticket = true;
                } else {
                    setIsTicket(false);
                }
                break;
            }
        }
        if (!ticket) {
            setDataList(function (prev) {
                return prev.map((item, i) => {
                    if (item.isInAudit != 1) {
                        item.bankName = "";
                        item.bankAccount = "";
                        item.invoiceNumber2 = "";
                        item.expiryDate = null;
                        var expieryInput = document.getElementById("expiryDate" + i);
                        if (expieryInput != null) {
                            expieryInput.value = null;
                        }
                    }
                    return item;
                });
            });
        }
    }
    function c52All(e) {
        var check = document.getElementById("c52").checked;
        var tmpArray = getCheckCustNameList();
        const custGroup = dataList.filter(f => f.isShow == true).reduce((group, cust) => {
            if (group.filter(f => f.customerTitle == cust.customerTitle).length == 0 &&
                tmpArray.filter(f => f.customerTitle == cust.customerTitle).length == 0
            ) {
                group.push(cust);
            }
            return group;
        }, []);
        //console.log(custGroup);
        if (custGroup.length + tmpArray.length > 10 && check) {
            document.getElementById("c52").checked = false;
            setModalStr("一張繳款憑單最多沖銷十個客戶的資料");
            OpenModal("modal-error", 3);
        }
        else {
            setDataList(function (prev) {
                return prev.map((item, i) => {
                    //塞入目前以勾選不重複客戶
                    if (check) {
                        tmpArray.push({ customerTitle: item.customerTitle });
                    }
                    if (item.isInAudit != 1 && item.isShow) {
                        item.isChecked = check;
                    }
                    return item;
                });
            });
            //取消清空或勾選目前以勾選不重複客戶
            //if (check) {
            //    const custGroup = tmpArray.reduce((group, cust) => {
            //        if (group.filter(f => f.customerTitle == cust.customerTitle).length == 0) {
            //            group.push(cust);
            //        }
            //        return group;
            //    }, []);
            //    var tmpArray = getCheckCustNameList();
            //    if (tmpArray.length > 10) {
            //        setModalStr("一張繳款憑單最多沖銷十個客戶的資料");
            //        OpenModal("modal-error", 3);
            //    }
            //}
            //else {
            //    setcustList([]);
            //}
        }
        ////console.log(custList);
    }

    //資料修改 第一層的
    function dataChange(e, index, col) {

        var TotalCnt = dataList.filter(f => f.isShow && f.isInAudit != 1).length;
        var ChkCnt = dataList.filter(f => f.isShow && f.isChecked).length;

        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "incomingAmount") {
                        item.incomingAmount = e.target.value.replace(/,/g, '');
                    }
                    else if (col == "exchangeRate") {
                        var arr = (e.target.value + "").split('.');
                        if (arr.length >= 2) {
                            e.target.value = (arr[0] + "").replace(/[^\d]/g, '') + "." + (arr[1] + "").replace(/[^\d]/g, '').substring(0, 15);
                        }
                        item.exchangeRate = ((e.target.value + "").replace(/[^0-9_.]/g, ''));

                    }
                    else if (col == "handlingFee") {
                        item.handlingFee = e.target.value.replace(/,/g, '');
                    }
                    else if (col == "isReductFromPrepaid") {
                        ////console.log(item.isReductFromPrepaid);
                        ////console.log(e.target.checked);
                        item.isReductFromPrepaid = (item.isReductFromPrepaid == null || item.isReductFromPrepaid == 0) ? 1 : 0;
                    }
                    else if (col == "bankName") {
                        item.bankName = e.target.value;
                    }
                    else if (col == "bankAccount") {
                        item.bankAccount = e.target.value;
                    }
                    else if (col == "invoiceNumber2") {
                        item.invoiceNumber2 = e.target.value;
                    }
                    else if (col == "expiryDate") {
                        if (e.target.value != "" && e.target.value.length > 10) {
                            item.expiryDate = "";
                        }
                        else {
                            item.expiryDate = e.target.value;
                        }
                    }
                    else if (col == "incomingDate") {
                        if (e.target.value != "" && e.target.value.length > 10) {
                            item.incomingDate = "";
                        }
                        else {
                            item.incomingDate = e.target.value;
                        }
                    }
                    else if (col == "note") {
                        item.note = e.target.value;
                    }
                    else if (col == "isOpen") {
                        item.isOpen = (item.isOpen == null || item.isOpen == false) ? true : false;
                    }
                    else if (col == "isChecked") {

                        if (item.isChecked == true) {
                            item.isChecked = false;
                            ChkCnt--;
                        }
                        else {
                            var tmpArray = getCheckCustNameList();

                            if (tmpArray.length >= 10 && tmpArray.filter(f => f.customerTitle == item.customerTitle).length == 0) {
                                //console.log(custList);
                                setModalStr("一張繳款憑單最多沖銷十個客戶的資料");
                                OpenModal("modal-error", 3);
                            }
                            else {
                                item.isChecked = true;
                                ChkCnt++;
                            }
                        }

                        if (TotalCnt == ChkCnt) {
                            document.getElementById("c52").checked = true;
                        }
                        else {
                            document.getElementById("c52").checked = false;
                        }
                    }
                }
                return item;
            });
        });
    }
    function isValidDate(e, index, col) {
        var d = new Date(e.target.value);

        if (isNaN(d.getFullYear()) || isNaN(d.getMonth()) || isNaN(d.getDate())) {
            e.target.value = null;

            dataChange(e, index, col);
        }
    }
    //資料修改 第一層的(離開如果空值要回預設0.1.2)
    function dataChange2(val, index, col) {

        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "incomingAmount") {
                        item.incomingAmount = (val + "").replace(/,/g, '').replace("^[0-9]+(.[0-9]{3})?$", '');
                    }
                    else if (col == "exchangeRate") {
                        item.exchangeRate = val;
                    }
                    else if (col == "handlingFee") {

                        if ((val + "").replace(/,/g, '') == "") {
                            item.handlingFee = 0;
                        }
                        else {
                            item.handlingFee = (val + "").replace(/,/g, '').replace("^[0-9]+(.[0-9]{3})?$", '');
                        }
                    }
                    else if (col == "isOpen") {
                        item.isOpen = true;
                    }
                }
                return item;
            });
        });
    }
    function getCheckCustNameList() {
        var tmpArray = [];
        dataList.filter(f => f.isChecked == true).forEach(f => {
            if (tmpArray.filter(ff => f.customerTitle == ff.customerTitle).length <= 0) {
                tmpArray.push({ customerTitle: f.customerTitle });
            }
        });

        tmpDataList.filter(f => f.datatype != 3).forEach(f => {
            if (tmpArray.filter(ff => f.cawCustName == ff.customerTitle).length <= 0) {
                tmpArray.push({ customerTitle: f.cawCustName });
            }
        })

        return tmpArray;
    }
    function CawCustNameChange(x, index) {
        setTmpDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.cawCustName = x;
                    if (item.datatype != 1 && item.datatype != 3) {
                        item.datatype = 2;
                    }
                }
                return item;
            });
        });
    }
    //資料修改 第一層的
    function tmpdataChange(e, index, col, save) {

        setTmpDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "isEdit") {
                        /*item.isEdit = (item.isEdit == null || item.isEdit == false) ? true : false;*/
                        if (item.isEdit == null || item.isEdit == false) {
                            item.isEdit = true;
                            item.oldName = item.cawCustName;
                            item.oldRate = item.cawAmtsExRate;
                            item.oldAmt = item.cawAmtsIn;
                            item.oldRem = item.cawRem;
                        }
                        else if (save == true) {
                            item.isEdit = false;
                        }
                        else {
                            item.isEdit = false;
                            item.cawCustName = item.oldName;
                            item.cawAmtsExRate = item.oldRate;
                            item.cawAmtsIn = item.oldAmt;
                            item.cawRem = item.oldRem;
                            document.getElementById("tmpInput" + i).value = item.oldAmt;
                        }
                    }
                    else if (col == "cawCustName") {
                        item.cawCustName = e.target.value;
                        if (item.datatype != 1 && item.datatype != 3) {
                            item.datatype = 2;
                        }
                    }
                    else if (col == "cawAmtsIn") {
                        item.cawAmtsIn = e.target.value.replace(/,/g, '').replace("^[0-9]+(.[0-9]{3})?$", '');
                        if (item.datatype != 1 && item.datatype != 3) {
                            item.datatype = 2;
                        }
                    }
                    else if (col == "cawAmtsExRate") {
                        var arr = (e.target.value + "").split('.');
                        if (arr.length >= 2) {
                            e.target.value = (arr[0] + "").replace(/[^\d]/g, '') + "." + (arr[1] + "").replace(/[^\d]/g, '').substring(0, 15);
                        }
                        item.cawAmtsExRate = ((e.target.value + "").replace(/[^0-9_.]/g, ''));

                        if (item.datatype != 1 && item.datatype != 3) {
                            item.datatype = 2;
                        }
                    }
                    else if (col == "cawRem") {
                        item.cawRem = e.target.value;
                        if (item.datatype != 1 && item.datatype != 3) {
                            item.datatype = 2;
                        }
                    }
                }
                return item;
            });
        });
    }
    const [tmpIndex, setTmpIndex] = useState();
    const [tmpDataType, setTmpDataType] = useState();
    function checkDel(index, datatype) {
        setTmpIndex(index);
        setTmpDataType(datatype);
        OpenModal("modal-checkdel");
    }
    //刪除暫存檔資料
    function delData() {
        //新增的資料直接移除列表
        if (tmpDataType == 1) {
            setTmpDataList(function (prev) {
                return prev.filter((x, i) => i != tmpIndex);
            });
        } else {
            //資料改成type3 =>刪除
            setTmpDataList(function (prev) {
                return prev.map((item, i) => {
                    if (i == tmpIndex) {
                        item.datatype = 3;
                    }
                    return item;
                });
            });
        }
        CloseModal("modal-checkdel");
    }
    //暫存
    function TmpSave() {
        var dt = {
            companyid: parseInt(queryParams.get('companyId')),
            dataArray: dataList.filter(x => x.isChecked == true),
            tempAccountArray: tmpDataList,
        }
        //console.log(JSON.stringify(dt));
        API_PUT_AccountChargeOffSave(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setModalStr("暫存成功");
                    OpenModal("modal-success");
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //新增暫收款
    function AddTmpData() {

        var result = true;
        var tmpArray = getCheckCustNameList();
        //console.log(tmpArray);
        if (tmpArray.length >= 10 && tmpArray.filter(f => f.customerTitle == (newCawCustName + "").trim()).length == 0) {
            setModalStr("一張繳款憑單最多沖銷十個客戶的資料");
            OpenModal("modal-error", 3);
            result = false;
        }
        ////console.log(newCawCustName, newCawAmtsIn, newCawAmtsExRate);
        if (newCawCustName == null || newCawCustName == "") {
            setNewCawCustNameError(true);
            result = false;
        } else {
            setNewCawCustNameError(false);
        }
        //console.log(newCawAmtsIn);
        if (newCawAmtsIn == null || newCawAmtsIn == "") {

            setNewCawAmtsInError(true);
            result = false;
        } else {
            setNewCawAmtsInError(false);
        }

        if (newCawAmtsExRate == null || newCawAmtsExRate == "") {
            setNewCawAmtsExRateError(true);
            result = false;
        } else {
            setNewCawAmtsExRateError(false);
        }
        var Amt = parseFloat(newCawAmtsIn);
        //console.log(Amt);
        if (isNaN(Amt) || Amt == 0) {
            setNewCawAmtsInError(true);
            result = false;
        }
        var rate = parseFloat(newCawAmtsExRate);
        if (isNaN(rate) || rate == 0) {
            setNewCawAmtsExRateError(true);
            result = false;
        }
        if (result) {
            var dt = {
                cawCustName: (newCawCustName + "").trim(),
                cawAmtsIn: newCawAmtsIn,
                cawAmtsExRate: newCawAmtsExRate,
                cawRem: newCawRem,
                cawCmpID: parseInt(queryParams.get('companyId')),
                datatype: 1,
            }
            setTmpDataList(function (prev) {
                return [dt, ...prev];
            });
            setNewCawCustName('');
            setNewCawAmtsIn(0);
            document.getElementById("newcawAmtsInText").value = "0"
            setNewCawAmtsExRate(1);
            setNewCawRem('');
            //執行API 5-7 

        }
    }
    function DataCheck(ActionCode, isRecNumberOver = 1) {
        var hasFocus = false;
        var result = true;
        var result2 = true;
        var isFirst = true;
        var tmpArray = getCheckCustNameList();
        if (tmpArray.length > 10) {
            setModalStr("一張繳款憑單最多沖銷十個客戶的資料");
            OpenModal("modal-error", 3);
            result = false;
        }
        if (asID == "" || asID == null) {
            setasIDError(true);
            result = false;
            const section = document.querySelector('#asID');
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            hasFocus = true;
            isFirst = false;
        }
        else {
            setasIDError(false);
        }
        setincomingAmountError(false);
        setexchangeRateError(false);
        sethandlingFeeError(false);
        //收款金額0的AR編號
        var tmpAR = "";
        var tmpAR2 = "";
        hasFocus = true;
        var modelnumber = "";

        let newArray = [];

        dataList.forEach((f, i) => {
            // 收款金額未填
            var incomingAmount = parseFloat(f.incomingAmount == "" || f.incomingAmount == null ? 0 : f.incomingAmount);
            var exchangeRate = parseFloat(f.exchangeRate == "" || f.exchangeRate == null ? 0 : f.exchangeRate);
            var handlingFee = parseFloat(f.handlingFee == "" || f.handlingFee == null ? 0 : f.handlingFee);


            if (f.isChecked) {
                if ((f.incomingAmount == null || (f.incomingAmount + "").toString() == "")) {
                    result = false;
                    hasFocus = false;
                    setincomingAmountError(true);

                    newArray.push(new DetailError({ index: i, column: "incomingAmount" }));
                }
                //匯款手續費未填
                if ((f.handlingFee == null || (f.handlingFee + "").toString() == "")) {
                    result = false;
                    hasFocus = false;
                    sethandlingFeeError(true);

                    newArray.push(new DetailError({ index: i, column: "handlingFee" }));
                }
                //匯率未填
                if ((f.exchangeRate == null || (f.exchangeRate + "").toString() == "")) {
                    result = false;
                    hasFocus = false;
                    setexchangeRateError(true);

                    newArray.push(new DetailError({ index: i, column: "exchangeRate" }));
                }
                //收款日期未填
                if ((f.incomingDate == null || f.incomingDate == "")) {
                    result = false;
                    hasFocus = false;
                    setincomingDateError(true);

                    newArray.push(new DetailError({ index: i, column: "incomingDate" }));
                }
                //沖銷金額為0
                if ((incomingAmount * exchangeRate) + handlingFee == 0) {
                    tmpAR += '[' + f.arNumber + '],';
                    result = false;
                    hasFocus = false;
                }
                //如果是一開始案的時候還是要算,第二次提示訊息後如果不看就不看
                ////console.log(isRecNumberOver);
                if (isRecNumberOver == 1) {
                    //應收餘額 <0 不可送出
                    var tmpsum = parseInt(NewNumFormart((recNumber(f.balance, f.incomingAmount, f.exchangeRate, f.handlingFee, companyId))).replace(/,/g, ""));// 230630 加上公司ID判斷是否要四捨五入
                    if (isNaN(tmpsum)) {
                        tmpsum = -1;
                    }
                    if (tmpsum < 0) {
                        tmpAR2 += '[' + f.arNumber + '],';
                        //const section = document.querySelector('#st-row-' + i);
                        //section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        result2 = false;
                        hasFocus = false;
                    }
                }
                //匯款號為票據,銀行名稱 帳號 票據號碼 到期日都要必填
                if (isTicket) {
                    if ((f.bankName == null || f.bankName == "") ||
                        (f.bankAccount == null || f.bankAccount == "") ||
                        (f.invoiceNumber2 == null || f.invoiceNumber2 == "") ||
                        (f.expiryDate == null || f.expiryDate == "")) {
                        result = false;
                        OpenModal("modal-TicketError");
                    }
                }

                if (hasFocus == false) {
                    dataChange2(true, i, "isOpen");
                }
                if (hasFocus == false && isFirst) {
                    //console.log(hasFocus);
                    //console.log(isFirst);
                    const section = document.querySelector('#st-row-' + i);
                    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    hasFocus = true;
                    isFirst = false;
                }
            }
        });

        setDetailErrorList(newArray);

        if (tmpAR != "") {
            setarMsg("AR編號:" + tmpAR.substring(0, tmpAR.length - 1));
            OpenModal("modal-error-ar" + modelnumber, 3);
        } else if (tmpAR2 != "" && result) {
            setarMsg("AR編號:" + tmpAR2.substring(0, tmpAR2.length - 1));
            if (ActionCode == "Apply") {
                OpenModal("modal-error-ar2-1", 3);
            } else {
                OpenModal("modal-error-ar2-2", 3);
            }
        }
        return result && result2;
    }

    function Apply(isRecNumberOver = 1) {
        document.getElementById("applyBtn").classList.add("disabled");
        //setDataList(function (prev) {
        //    return prev.map((item, i) => {
        //        item.isShow = true;
        //        return item;
        //    });
        //});
        //document.getElementById("filterText").value = "";
        var result = DataCheck("Apply", isRecNumberOver);
        document.getElementById("applyBtn").classList.remove("disabled");
        if (result) {
            CloseModal("modal-error-ar2-1", 3);
            CloseModal("modal-error-ar2-2", 3);
            var dataArray = [];
            dataList.forEach((f, i) => {
                if (f.isChecked) {

                    dataArray.push({
                        id: f.id,
                        arNumber: f.arNumber,
                        currName: f.currName,
                        erpAmount: f.erpAmount,
                        balance: f.balance,
                        soNo: f.soNo,
                        soAmt: f.soAmt,
                        erpId: f.erpId,
                        incomingAmount: parseFloat(f.incomingAmount == "" || f.incomingAmount == null ? 0 : f.incomingAmount),
                        exchangeRate: parseFloat(f.exchangeRate == "" || f.exchangeRate == null ? 0 : f.exchangeRate),
                        handlingFee: parseFloat(f.handlingFee == "" || f.handlingFee == null ? 0 : f.handlingFee),
                        incomingDate: f.incomingDate,
                        bankName: f.bankName,
                        bankAccount: f.bankAccount,
                        invoiceNumber2: f.invoiceNumber2,
                        expiryDate: f.expiryDate == "" ? null : f.expiryDate,
                        note: f.note

                    });
                }
            });
            var dt = {
                companyId: parseInt(queryParams.get("companyId")),
                accountSubsidaryId: asID,
                dataArray: dataArray,
                tempAccountArray: tmpDataList
            }
            document.getElementById("c52").checked = false;

            setIsProcessing(true);
            API_POST_AccountChargeOffAudit(dt)
                .then((res) => res.data)
                .then((data) => {
                    console.log("回傳資料", data);
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        //window.onbeforeunload = null;
                        //window.location.reload();

                        SetHasChange(false);
                        setModalStr("送出成功");
                        getData();
                        setAsID(null);
                        setCustFilterValue("");
                        OpenModal("modal-success");
                    } else {
                        setModalStr(data.message);
                        OpenModal("modal-error");
                    }
                }).catch((err) => {
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                }).finally(() => {
                    setIsProcessing(false);
                });
        }

    }
    //下載繳款憑證
    function DownLoad(isRecNumberOver = 1) {

        var result = DataCheck("DownLoad", isRecNumberOver);
        if (result) {
            CloseModal("modal-error-ar2-1", 3);
            CloseModal("modal-error-ar2-2", 3);
            var tmpList = dataList.filter(x => x.isChecked == true);
            var lstArray = [];
            for (var i = 0; i < tmpList.length; i++) {
                var recAmt = parseFloat((tmpList[i].incomingAmount + "").trim());
                lstArray.push({
                    custNumber: tmpList[i].customerNumber,
                    erpId: tmpList[i].erpId,
                    arNumber: tmpList[i].arNumber,
                    writeOffAmt: (parseFloat(tmpList[i].incomingAmount * tmpList[i].exchangeRate) + parseFloat(tmpList[i].handlingFee)),
                    adjustAmt: parseFloat(tmpList[i].handlingFee),
                    exRate: parseFloat(tmpList[i].exchangeRate),
                    recAmt: parseFloat(recAmt),
                    recDate: tmpList[i].incomingDate,
                    remark: tmpList[i].note,
                    bankName: tmpList[i].bankName,
                    bankAccount: tmpList[i].bankAccount,
                    ticketNo: tmpList[i].invoiceNumber2,
                    expiryDate: tmpList[i].expiryDate
                });
            }
            var tmpArray = [];
            for (var i = 0; i < tmpDataList.length; i++) {
                //if (tmpDataList[i].datatype != null) {
                tmpArray.push({
                    cawid: tmpDataList[i].cawid,
                    cawCustName: tmpDataList[i].cawCustName,
                    cawAmtsIn: tmpDataList[i].cawAmtsIn,
                    writeOffAmt: parseFloat(tmpDataList[i].cawAmtsIn * tmpDataList[i].cawAmtsExRate),
                    cawRem: tmpDataList[i].cawRem,
                    datatype: tmpDataList[i].datatype,
                });
                //}
            }
            var dt = {
                companyId: parseInt(queryParams.get("companyId")),
                accountSubsidaryId: asID,
                lst: lstArray,
                tempList: tmpArray,
            };
            //console.log(JSON.stringify(dt));
            setIsProcessing(true);
            API_POST_AccountChargeOffDownLoad(dt)
                .then((res) => res.data)
                .then((data) => {
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        downLoad(data.result.data.fileName);
                    } else {
                        setModalStr(data.message);
                        OpenModal("modal-error");
                    }
                }).catch((err) => {
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                }).finally(() => {
                    setIsProcessing(false);

                });
        }
    }
    const FileDownload = require("js-file-download");
    function downLoad(fName) {
        API_GET_DownLoadGetFile({
            fileName: fName,
        }).then((response) => {
            FileDownload(response.data, fName);
        })

    }

    function getCustomerName() {
        API_GET_CustomerName({
            input: document.getElementById("customerNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setCustomerNameArray(data.result.data);
                }
            });
    }
    const [customerList, setCustomerList] = useState([]);
    function getCustomerTitle(e) {
        setNewCawCustName((e.target.value + "").trimStart());
        setNewCawCustNameSave("");
        if (e.target.value != "" && e.target.value != null) {
            API_GET_customerTitleList({
                companyId: parseInt(companyId),
                customerTitle: (e.target.value)
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.result.data != null) {
                        setCustomerList(data.result.data);
                    }
                });
        }

    }
    function getCustomerTitleChg(e, i) {
        tmpdataChange(e, i, "cawCustName");
        API_GET_customerTitleList({
            companyId: parseInt(queryParams.get("companyId")),
            customerTitle: e.target.value
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setCustomerList(data.result.data);
                }
            });
    }

    function CustFilter(e) {
        let value = e.target.value.toLowerCase();
        setCustFilterValue(value);
        var CkCnt = 0, FilterCnt = 0;
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (e.target.value != "") {
                    if (item.customerTitle.toLowerCase().indexOf(value) >= 0 ||
                        item.arNumber.toLowerCase().indexOf(value) >= 0) {
                        item.isShow = true;
                        if (item.isChecked == true) {
                            CkCnt += 1;
                        }
                        if (item.isInAudit != 1) {
                            FilterCnt += 1;
                        }
                    } else {
                        item.isShow = false;
                        item.isOpen = false;
                    }
                } else {
                    item.isShow = true;
                    if (item.isChecked == true) {
                        CkCnt += 1;
                    }
                    if (item.isInAudit != 1) {
                        FilterCnt += 1;
                    }
                }
                if (CkCnt == FilterCnt && CkCnt != 0) {
                    document.getElementById("c52").checked = true;
                }
                else {

                    document.getElementById("c52").checked = false;
                }
                return item;
            });
        });

    }

    function ChkListen(e) {

        const datalist = document.querySelectorAll("[data-datalist]");

        datalist.forEach((element) => {
            var dropmenu = element.querySelector(".datalist");
            var input = element.querySelector("input");
            if (input.keyup == null) {
                //移除監聽
                input.keyup = null;
                input.addEventListener("keyup", function () {
                    element.classList.add("show-datalist");
                });
                document.body.addEventListener("click", function (e) {
                    var target = e.target;
                    if (element.contains(target)) {
                        ////console.log(target);
                        if (dropmenu.contains(target)) {
                            var value = target.dataset.value;
                            input.value = value;
                            element.classList.remove("show-datalist");
                        } else {
                            ////console.log('ddd');
                        }
                    } else {
                        element.classList.remove("show-datalist");
                    }
                });
            }
        });
    }
    //回傳應收餘額
    function recNumber(balance, incomingAmount, exchangeRate, handlingFee, companyId) {
        balance = parseFloat((balance + "").replace(/,/g, ''));
        balance = isNaN(balance) ? 0 : balance;
        incomingAmount = parseFloat((incomingAmount + "").replace(/,/g, ''));
        incomingAmount = isNaN(incomingAmount) ? 0 : incomingAmount;
        exchangeRate = parseFloat((exchangeRate + "").replace(/,/g, ''));
        exchangeRate = isNaN(exchangeRate) ? 0 : exchangeRate;
        handlingFee = parseFloat((handlingFee + "").replace(/,/g, ''));
        handlingFee = isNaN(handlingFee) ? 0 : handlingFee;
        var val = parseFloat((balance - (incomingAmount * exchangeRate) - handlingFee + Number.EPSILON) * 100 / 100);
        if (companyId == 0 || companyId == 2) {
            val = parseFloat((balance - Math.round(incomingAmount * exchangeRate) - handlingFee + Number.EPSILON) * 100 / 100);
        }

        //var val = 0.00;

        //if (companyId == 0 || companyId == 2) {
        //    val = parseFloat((balance - Math.round(incomingAmount * exchangeRate) - handlingFee).toFixed());
        //}
        //else {
        //    val = parseFloat((balance - (incomingAmount * exchangeRate) - handlingFee).toFixed(2));
        //}

        return val;
    }

    function checkDetailError(index, column, isChecked) {


        let match = detailErrorList.find(x => x.index == index && x.column == column);

        if (match != null && isChecked == true) {
            return 'error';
        }

        return '';

    }

    useEffect(() => {
        let checkList = dataList.filter(x => x.isChecked == true);

        if (checkList.length > 0) {
            console.log("核選清單", checkList);
        }
    }, [dataList]);

    useEffect(() => {
        if (detailErrorList.length > 0) {
            console.log("錯誤清單", detailErrorList);
        }

    }, [detailErrorList])

    return (
        <>
            <div className="content-body">
                <div className="title-area">
                    <div className="container full">
                        <h3 className="fz-B has-border">未沖銷憑證：{companyName}</h3>
                        <div className="right">幣別：{currencyName}</div>
                    </div>
                </div>
                <div className="container full">
                    <div className="form-container">
                        <div className="form-item must half">
                            <div className="field">匯入款帳戶</div>
                            <div className="value multi-inline">
                                <select id="asID" className={asIDError ? 'error' : ''} data-required onChange={asIDChange}>
                                    <option value="" selected={asID == null ? true : false} disabled>請選擇</option>
                                    {
                                        remittanceAccount == null || remittanceAccount.length == 0 ? null :
                                            remittanceAccount.map(x =>
                                                <option value={x.asid} selected={asID == x.asid ? true : false}  >
                                                    {x.asName}
                                                </option>
                                            )
                                    }
                                </select>
                            </div>
                            <span className="help-word">
                                請輸入正確格式
                            </span>
                        </div>
                        <div className="form-item half">
                            <div className="field"></div>
                            <div className="value multi-inline">
                                <input type="text" id="filterText"
                                    placeholder="請輸入欲篩選的客戶名稱或AR編號"
                                    onInput={(e) => {
                                        if (e.target.composing) {
                                            setCustFilterValue(e.target.value)
                                        }
                                        else {
                                            CustFilter(e);
                                        }
                                    }}
                                    onCompositionStart={(e) => {
                                        e.target.composing = true;
                                    }}
                                    onCompositionEnd={(e) => {
                                        if (!e.target.composing) return;
                                        e.target.composing = false;
                                        CustFilter(e);
                                    }}
                                    value={custFilterValue}
                                />
                            </div>
                            <span className="help-word">

                            </span>
                        </div>
                    </div>
                    <div className="scroll-area scrollbar">
                        <ul className="list-table">
                            <li className="lt-row lt-th sticky-head">
                                <div className="flex-1 max1">
                                    <input type="checkbox" id="c52" name="c52" onClick={c52All} />
                                    <label htmlFor="c52"></label>
                                </div>
                                <div className="flex-5">客戶名稱</div>
                                <div className="flex-2">AR 傳票號碼</div>
                                <div className="flex-2">交易日期</div>
                                <div className="flex-2">GL Date</div>
                                <div className="flex-3 right">帳本幣別餘額</div>
                                <div className="flex-2">發票號碼</div>
                                <div className="flex-3 right">發票金額</div>
                                <div className="flex-4">品名</div>
                                <div className="flex-1 fn"></div>
                            </li>
                            {
                                dataList == null || dataList.length == 0 ? null :
                                    dataList.map((x, i) =>
                                        <>
                                            <li className={`lt-row ${x.isOpen == true ? 'highlight' : ''}`} id={`st-row-${i}`} style={x.isShow == false ? { display: "none" } : null}>
                                                <div className="flex-1 max1">
                                                    <input type="checkbox" id={`dataList_${i}`} onClick={(e) => dataChange(e, i, "isChecked")} checked={x.isChecked ? true : false} disabled={x.isInAudit == 1 ? true : false} />
                                                    <label htmlFor={`dataList_${i}`}></label>
                                                </div>
                                                <div className="flex-5" style={{ wordBreak: "break-all" }}>
                                                    {x.customerTitle}
                                                </div>
                                                <div className="flex-2">
                                                    <div className="elips-1">{x.arNumber}</div>
                                                </div>
                                                <div className="flex-2">
                                                    <div className="elips-1">{x.transactionDate}</div>
                                                </div>
                                                <div className="flex-2">
                                                    <div className="elips-1">{x.glDate}</div>
                                                </div>
                                                <div className="flex-3 right">
                                                    {currencyEn} {NumFormart(x.balance)}
                                                </div>
                                                <div className="flex-2" style={{ wordBreak: "break-all" }}>
                                                    {x.invoiceNumber1}
                                                </div>
                                                <div className="flex-3 right">
                                                    <div className="elips-1">{NumFormart(x.invoiceAmount)}</div>
                                                </div>
                                                <div className="flex-4" style={{ wordBreak: "break-all" }}>
                                                    {x.productName}
                                                </div>
                                                <div className="flex-1 fn">
                                                    <div className="fn-bar">
                                                        <div className={`btn btn-icon ${x.isOpen == true ? 'rotate' : ''}`} onClick={(e) => dataChange(e, i, "isOpen")}>
                                                            <i className="icon icon-28"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className={`lt-row ${x.isOpen == true ? '' : 'hide'}`} id={`st-${i}`}>
                                                <ul className="list-table nested">
                                                    <li className="lt-row lt-th">
                                                        <div className="flex-2">開立幣別</div>
                                                        <div className="flex-3 right">開立金額</div>
                                                        <div className="flex-3">訂單編號</div>
                                                        <div className="flex-3 right">訂單金額</div>
                                                        <div className="flex-3 right">收款金額</div>
                                                        <div className="flex-2 right">匯率</div>
                                                        <div className="flex-2 right">沖銷金額</div>
                                                        <div className="flex-3 center">匯款手續費/差額</div>
                                                        <div className="flex-2 right">應收餘額</div>
                                                        <div className="flex-3 ">收款日期</div>
                                                        {/* <div className="flex-3">從預付金扣除</div>*/}

                                                    </li>
                                                    <li className="lt-row">
                                                        <div className="flex-2">
                                                            <div className="elips-1">{x.currName}</div>{ /*開立幣別*/}

                                                        </div>
                                                        <div className="flex-3 right">
                                                            <div className="elips-1">{NumFormart(x.erpAmount)}</div>{ /*開立金額*/}

                                                        </div>
                                                        <div className="flex-3">
                                                            <div className="elips-1">{x.soNo}</div>{ /*訂單編號*/}

                                                        </div>
                                                        <div className="flex-3 right">
                                                            <div className="elips-1">{x.orderAmount}</div>{ /*訂單金額*/}
                                                        </div>

                                                        <div className="flex-3 right">{ /*收款金額*/}
                                                            <input className={checkDetailError(i, "incomingAmount", x.isChecked)}
                                                                type="text"
                                                                defaultValue={NumFormart(x.incomingAmount)}
                                                                onChange={(e) => { dataChange2(e.target.value, i, "incomingAmount"), setincomingAmountError(false) }}
                                                                onClick={e => {
                                                                    x.incomingAmount == "0.000" ? e.target.select() : null
                                                                }}
                                                                onMouseOut={e => { x.incomingAmount == "" ? dataChange2(0, i, "incomingAmount") : null }}
                                                                onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                                                onBlur={(e) => e.target.value = NumFormart(x.incomingAmount)}
                                                                disabled={x.isInAudit == 1 ? true : false}
                                                            />
                                                        </div>
                                                        <div className="flex-2 right">{ /*匯率*/}
                                                            <input className={checkDetailError(i, "exchangeRate", x.isChecked)}
                                                                type="text"
                                                                value={x.exchangeRate} onChange={(e) => { dataChange(e, i, "exchangeRate"), setexchangeRateError(false) }}
                                                                onClick={e => { x.exchangeRate == "1" ? e.target.select() : null }}
                                                                onMouseOut={e => { x.exchangeRate == "" ? dataChange2(1, i, "exchangeRate") : null }}
                                                                disabled={x.isInAudit == 1 ? true : false}
                                                            />
                                                        </div>
                                                        <div className="flex-2 right">{ /*沖銷金額*/}
                                                            <div className="elips-1">{(companyName == "台灣果核" || companyName == "台灣核智" ? NewNumFormart(Math.round((x.incomingAmount * x.exchangeRate)) + parseFloat(x.handlingFee)) : NewNumFormart((x.incomingAmount * x.exchangeRate) + parseFloat(x.handlingFee)))}</div>{ /*230630 加上公司ID判斷是否要四捨五入*/}
                                                        </div>
                                                        <div className="flex-3 right">
                                                            <input className={handlingFeeError && x.isChecked && (x.handlingFee + "").toString() == "" ? 'error' : ''} type="text"
                                                                defaultValue={NumFormart(x.handlingFee)}
                                                                onChange={(e) => { dataChange2(e.target.value, i, "handlingFee"), sethandlingFeeError(false) }}
                                                                onClick={e => { x.handlingFee == "0" ? e.target.select() : null }}
                                                                onMouseOut={e => { x.handlingFee == "" ? dataChange2(0, i, "handlingFee") : null }}
                                                                onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                                                onBlur={(e) => e.target.value = NumFormart(x.handlingFee)}
                                                                disabled={x.isInAudit == 1 ? true : false}
                                                            />
                                                        </div>
                                                        <div className="flex-2 right">
                                                            <div className={`elips-1`} style={RoundTo2Compare(NewNumFormart((recNumber(x.balance, x.incomingAmount, x.exchangeRate, x.handlingFee, companyId))).replace(/,/g, "")) ? null : { color: "#F37402" }}>
                                                                {NewNumFormart(recNumber(x.balance, x.incomingAmount, x.exchangeRate, x.handlingFee, companyId))}
                                                            </div>
                                                        </div>
                                                        <div className="flex-3">
                                                            <input type="date" id={`incomeDate_${i}`}
                                                                className={checkDetailError(i, "incomingDate", x.isChecked)}
                                                                value={x.incomingDate} min="2010-01-01" max="2099-12-31"
                                                                onBlur={(e) => isValidDate(e, i, "incomingDate")}
                                                                onKeyDown={(e) => { e.target.value == "" ? dataChange(e, i, "incomingDate") : null }}
                                                                onChange={(e) => { dataChange(e, i, "incomingDate"), setincomingDateError(false) }}
                                                                disabled={x.isInAudit == 1 ? true : false}
                                                            />
                                                        </div>
                                                        {/*<div className="flex-3 center">*/}
                                                        {/*    <input type="radio" id={`isReductFromPrepaid_${i}`} checked={x.isReductFromPrepaid == 1 ? 'checked' : ''}*/}
                                                        {/*        onChange={(e) => dataChange(e, i, "isReductFromPrepaid")}*/}
                                                        {/*    />*/}
                                                        {/*    <label htmlFor={`isReductFromPrepaid_${i}`}></label>*/}
                                                        {/*</div>*/}
                                                    </li>
                                                    <li className="lt-row lt-th">
                                                        <div className="flex-5">銀行名稱</div>
                                                        <div className="flex-4">帳號</div>
                                                        <div className="flex-4">票據號碼</div>
                                                        <div className="flex-4">到期日</div>
                                                        <div className="flex-5">備註</div>
                                                    </li>
                                                    <li className="lt-row lt-th">
                                                        <div className="flex-5">
                                                            <input type="text" value={x.bankName} onChange={(e) => dataChange(e, i, "bankName")} disabled={isTicket && x.isInAudit != 1 ? false : true} />
                                                        </div>
                                                        <div className="flex-4">
                                                            <input type="text" value={x.bankAccount} onChange={(e) => dataChange(e, i, "bankAccount")} disabled={isTicket && x.isInAudit != 1 ? false : true} />
                                                        </div>
                                                        <div className="flex-4">
                                                            <input type="text" value={x.invoiceNumber2} onChange={(e) => dataChange(e, i, "invoiceNumber2")} disabled={isTicket && x.isInAudit != 1 ? false : true} />
                                                        </div>
                                                        <div className="flex-4">
                                                            <input id={"expiryDate" + i} type="date" style={{ wordBreak: "break-all" }} value={x.expiryDate} onBlur={(e) => isValidDate(e, i, "expiryDate")} min="2010-01-01" max="2099-12-31" onKeyDown={(e) => { e.target.value == "" ? dataChange(e, i, "expiryDate") : null }} onChange={(e) => { dataChange(e, i, "expiryDate") }} disabled={isTicket && x.isInAudit != 1 ? false : true} />
                                                        </div>
                                                        <div className="flex-5">
                                                            <input type="text" value={x.note} onChange={(e) => dataChange(e, i, "note")} disabled={x.isInAudit == 1 ? true : false} />
                                                        </div>
                                                    </li>
                                                </ul>

                                            </li>
                                        </>
                                    )
                            }

                        </ul>
                    </div>
                    <hr className="full" />
                    <h6 className="fz-D">暫收列表</h6>
                    <div className="scroll-area scrollbar">
                        <ul className="list-table">
                            <li className="lt-row lt-th sticky-head">
                                <div className="form-item flex-5 must">客戶名稱</div>
                                <div className="flex-2 right must">收款金額</div>
                                <div className="flex-1 right must">匯率</div>
                                <div className="flex-2 right"></div>
                                <div className="flex-5">備註</div>
                                <div className="flex-4 fn"></div>
                            </li>

                            <li className="lt-row ovv" >
                                <div className="form-item flex-5 no-field no-help-word inline has-datalist" data-datalist >{ /*客戶名稱*/}
                                    {
                                        <div className="value">
                                            <input type="text" className={newCawCustNameError ? 'error' : ''} placeholder="客戶公司抬頭" value={newCawCustName} onChange={getCustomerTitle} />
                                            <ul className="datalist scrollbar" style={{ zIndex: "9999" }}>
                                                {
                                                    customerList == null && customerList.length == 0 ? null :
                                                        customerList.map((x) => (
                                                            <li data-value={x} onClick={() => { setNewCawCustNameSave(x), setNewCawCustName(x) }}>
                                                                <span className="flex-3 bold">
                                                                    {x}
                                                                </span>
                                                            </li>
                                                        ))
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>
                                <div className="flex-2 right">{ /*收款金額*/}
                                    <input type="text" id="newcawAmtsInText" className={newCawAmtsInError ? 'error' : ''} placeholder="收款金額"
                                        defaultValue="0"
                                        onChange={(e) => setNewCawAmtsIn(parseFloat((e.target.value + "").replace(/,/g, '').replace("^[0-9]+(.[0-9]{3})?$", '')) || 0)}
                                        onClick={e => { (newCawAmtsIn == "0" ? e.target.select() : null) }}
                                        onFocus={(e) => e.target.value = (newCawAmtsIn + "").replace(/,/g, '')}
                                        onBlur={(e) => e.target.value = (companyId == 0 || companyId == 2 ? NumFormart(Math.round(newCawAmtsIn * 1000) / 1000) : NumFormart(newCawAmtsIn))}// 230630 加上公司ID判斷是否要四捨五入
                                    />
                                </div>
                                <div className="flex-1 right">
                                    <input type="text" className={newCawAmtsExRateError ? 'error' : ''} placeholder="匯率" value={newCawAmtsExRate}
                                        onChange={(e) => {
                                            var arr = (e.target.value + "").split('.');
                                            if (arr.length >= 2) {


                                                e.target.value = (arr[0] + "").replace(/[^\d]/g, '') + "." + (arr[1] + "").replace(/[^\d]/g, '').substring(0, 15);
                                            }
                                            setNewCawAmtsExRate((e.target.value + "").replace(/[^0-9_.]/g, ''));
                                        }}
                                        onClick={e => { newCawAmtsExRate == "1" ? e.target.select() : null }}
                                    />
                                </div>
                                <div className="flex-2 right">
                                    <input type="text" style={{ width: "100%" }} className="no-border-but-bg" placeholder="沖銷金額" value={NewNumFormart((newCawAmtsIn * newCawAmtsExRate))} disabled="disabled" />
                                </div>
                                <div className="flex-5">
                                    <input type="text" placeholder="備註" value={newCawRem} onChange={(e) => setNewCawRem(e.target.value)} />
                                </div>
                                <div className="flex-4 fn jcfe">
                                    <button className="btn btn-border btn-middle tmpBtn" onClick={AddTmpData}>
                                        <span>新增暫收款</span>
                                    </button>
                                </div>
                            </li>

                            {
                                tmpDataList == null || tmpDataList.length == 0 ? null :
                                    tmpDataList.map((x, i) =>
                                        x.datatype != null && x.datatype == 3 ? null : //刪除的話不要顯示
                                            <li className="lt-row ovv">

                                                <div className="form-item flex-5 no-field no-help-word inline has-datalist" data-datalist >
                                                    {
                                                        x.isEdit == true ?
                                                            <div className="value">
                                                                <input type="text" className={x.cawCustName == '' ? 'error' : ''} placeholder="客戶公司抬頭" value={x.cawCustName} onChange={(e) => { getCustomerTitleChg(e, i), ChkListen(e) }} />
                                                                <ul className="datalist scrollbar" style={{ zIndex: "99999" }}>
                                                                    {
                                                                        customerList == null && customerList.length == 0 ? null :
                                                                            customerList.map((x) => (
                                                                                <li data-value={x} onClick={() => CawCustNameChange(x, i)}>
                                                                                    <span className="flex-3 bold">
                                                                                        {x}
                                                                                    </span>
                                                                                </li>
                                                                            ))
                                                                    }
                                                                </ul>
                                                            </div>
                                                            /* <input type="text" value={x.cawCustName} onChange={(e) => tmpdataChange(e, i, "cawCustName")} placeholder="客戶公司抬頭" />*/
                                                            :
                                                            x.cawCustName
                                                    }
                                                </div>
                                                <div className="flex-2 right">
                                                    {
                                                        <>
                                                            <input id={"tmpInput" + i} type="text" style={(x.isEdit == null || x.isEdit == false) ? { display: "none" } : null}
                                                                defaultValue={NumFormart(x.cawAmtsIn)}
                                                                onChange={(e) => tmpdataChange(e, i, "cawAmtsIn")} placeholder="匯入金額"
                                                                onClick={e => { x.cawAmtsIn == "0" ? e.target.select() : null }}
                                                                onFocus={(e) => e.target.value = (x.cawAmtsIn + "").replace(/,/g, '')}
                                                                onBlur={(e) => e.target.value = NumFormart(x.cawAmtsIn)}
                                                            />
                                                            <span style={x.isEdit != true ? null : { display: "none" }}>
                                                                {NumFormart(x.cawAmtsIn)}
                                                            </span>
                                                        </>
                                                    }
                                                </div>
                                                <div className="flex-1 right">
                                                    {
                                                        x.isEdit == true ?
                                                            <input type="text" value={x.cawAmtsExRate}
                                                                onChange={(e) => tmpdataChange(e, i, "cawAmtsExRate")}
                                                                onClick={e => { x.cawAmtsExRate == "1" ? e.target.select() : null }}
                                                                placeholder="匯率" />
                                                            :
                                                            x.cawAmtsExRate
                                                    }
                                                </div>
                                                <div className="flex-2 right">{ /*入帳金額*/}
                                                    <input type="text " value={companyId == 0 || companyId == 2 ? NewNumFormart(Math.round(x.cawAmtsIn * x.cawAmtsExRate)) : NewNumFormart(parseFloat((x.cawAmtsIn * x.cawAmtsExRate).toFixed(2)))} style={{ width: "100%" }} className="no-border-but-bg" placeholder="入帳金額" disabled="disabled" />{/*230630 加上公司ID判斷是否要四捨五入*/}
                                                </div>
                                                <div className="flex-5" style={{ wordBreak: "break-all" }} >
                                                    {
                                                        x.isEdit == true ?
                                                            <input type="text" value={x.cawRem} onChange={(e) => tmpdataChange(e, i, "cawRem")} placeholder="備註" />
                                                            :
                                                            x.cawRem
                                                    }
                                                </div>
                                                <div className="flex-4 fn jcfe">
                                                    <div className="fn-bar tmpDiv">
                                                        {
                                                            x.isEdit == true ?
                                                                <>
                                                                    <div className="btn btn-icon" onClick={(e) => tmpdataChange(e, i, "isEdit", true)} >
                                                                        <i className="icon icon-48"></i>
                                                                    </div>
                                                                    <div className="btn btn-icon" onClick={(e) => tmpdataChange(e, i, "isEdit")} >
                                                                        <i className="icon icon-47"></i>
                                                                    </div>
                                                                </>
                                                                :
                                                                <div className="btn btn-icon" onClick={(e) => tmpdataChange(e, i, "isEdit")}>
                                                                    <i className="icon icon-30"></i>
                                                                </div>
                                                        }

                                                        <div className="btn btn-icon" onClick={(e) => checkDel(i, x.datatype)} >
                                                            <i className="icon icon-37"></i>
                                                        </div>

                                                    </div>
                                                </div>
                                            </li>
                                    )
                            }
                        </ul>
                    </div>
                    <div className="form-item btn-area sp">
                        <div className="left"></div>
                        <div className="right">
                            <button className={`btn btn-border ${isProcessing == true ? 'disabled' : ''}`} onClick={() => DownLoad(1)}>
                                <span>下載繳款憑單</span>
                            </button>
                            <button className={`btn ${isProcessing == true ? 'disabled' : ''}`} id="applyBtn" onClick={() => Apply(1)}>
                                <span>送出申請</span>
                            </button>

                        </div>
                    </div>

                </div>
            </div>
            <div id="modal-ok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-ok")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-success" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-success")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr == null ? modalStr : HtmlParse(modalStr)}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error-ar" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{arMsg}<br />未輸入沖銷金額</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error-ar")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error-ar2-1" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{arMsg}<br />應收餘額為負數，確定要執行下一步嗎?</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-error-ar2-1")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={() => Apply(2)}>
                                <span>確定</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error-ar2-2" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{arMsg}<br />應收餘額為負數，確定要執行下一步嗎?</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-error-ar2-2")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={() => DownLoad(2)}>
                                <span>確定</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-checkdel" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確定要刪除這筆暫收資料?</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-checkdel")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={delData}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-TicketError" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            因匯入款帳戶選擇了票據帳戶<br />
                            請務必填寫[銀行名稱]、[帳號]、[票據號碼]、[到期日]
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-TicketError")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default AccChargeList;
