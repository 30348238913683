import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { OpenModal } from '../../global/ToolUtil'

const MenuItem = ({ menuID, menuName, menuDUrl, subMenu }) => {
    const subMenuCount = subMenu.length;
    const queryParams = new URLSearchParams(window.location.search);
    var history = useHistory();

    function redirect2(TP) {

        //console.log();
        var haschangetext = document.getElementById('haschangetext').value;
        if (haschangetext != "1") {
            //判斷是否該頁
            if (queryParams.get('path') == TP) {
                window.location.reload();
            } else {
                //沒有改過 直接導頁
                history.push('/Main?path=' + TP);
            }

        } else {
            //改過資料
            OpenModal("modal-leavecheck", 6);
            localStorage.setItem("temppath", TP);
        }

    }
    //最外層展開判斷
    function ActiveJudge(id) {
        if (queryParams.get('path') == id) {
            console.log(1);
            document.getElementById("menuitem-" + id).classList.add("active");
        } else {
            //不是這一頁 全部移除active再補上active
            var dmuMenu = document.querySelectorAll(".dmu");
            dmuMenu.forEach(el => {
                if (el.id != ("menuitem-" + id)) {
                    el.classList.remove('active');
                } else {
                    document.getElementById("menuitem-" + id).classList.add("active");
                }
            });
        }
    }
    return (
        <div className="item has-dropmenu dmu" id={`menuitem-${menuDUrl}`}>
            <a onClick={e => { subMenuCount > 0 ? ActiveJudge(menuDUrl) : redirect2(menuDUrl) }}>
                <i className={`icon icon-${menuID}`}></i>
                <span id={`menuitem-span-${menuDUrl}`}>{menuName}</span>
            </a>
            <div className="dropmenu">
                {subMenu.map((x) => (
                    <a key={x.menuID} onClick={e => redirect2(x.menuDUrl)}>
                        {x.menuCodeName}
                    </a>
                ))}
            </div>
        </div>
    );
};

export default MenuItem;
