import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API_Login_Auth, API_GET_HomeData } from "../../global/constants";
import { CheckResponseCode } from '../../global/ResponseUtil';

function authenticator() {
    const history = useHistory();
    const [optNum, setoptNum] = useState("");
    function optNumChange(e) {
        setoptNum(e.target.value);
    }
    const [msgStr, setMsgStr] = useState("");

    useEffect(() => {
        if (localStorage.getItem("user") == null) {
            history.push("/");
        }
    }, []);

    document.onkeydown = function (event) {
        var target, code, tag;
        if (!event) {
            event = window.event;
            code = event.keyCode;
            if (code == 13) {
                next();
            }
        } else {
            target = event.target; //針對遵循w3c標準的瀏覽器
            code = event.keyCode;
            if (code == 13) {
                next();
            }
        }
    };

    //#region opt登入
    function next() {
        var dt = {
            account: localStorage.getItem("user"),
            otp: optNum,
        };
        //console.log(dt);
        API_Login_Auth(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    localStorage.setItem("islogin", "1");
                    goDashboard();
                }
                else {
                    setoptNum("");
                    document.getElementById("optNum").classList.add("error");
                    document.getElementById("optNumDiv").classList.add("show-help");
                    setMsgStr(data.message);
                }
            })
            .catch((err) => {

                document.getElementById("optNum").classList.add("error");
                document.getElementById("optNumDiv").classList.add("show-help");
            });
    }
    //#endregion

    function goDashboard() {
        var fromOkta_ = (localStorage.getItem("post_logout_redirect_uri") != null ? 1 : 0);
        API_GET_HomeData({ fromOkta: fromOkta_ })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    localStorage.setItem("token", data.token.token);
                    localStorage.setItem(
                        "pagelist",
                        JSON.stringify(data.result.pagedata)
                    );
                    localStorage.setItem(
                        "menulist",
                        JSON.stringify(data.result.menudata)
                    );
                    localStorage.setItem(
                        "authctrl",
                        JSON.stringify(data.result.authdata)
                    );
                    localStorage.setItem("notify", JSON.stringify(data.result.notify));
                    history.push("/Dashboard");
                } else if (data.code == "1001") {
                    localStorage.clear();
                    setMsgStr(data.message);
                    history.push("/");
                }
                else {
                    setMsgStr(data.message);
                }
            })

    }
    return (
        <div style={{ width: "100%" }}>
            <div className="subtitle">
                <h2 className="fz-B">Google 第三方驗證</h2>
                <h3 className="fz-C">請輸入App所提供的6位數字</h3>
            </div>
            <div className="form-container">
                <div className="form-item short no-field lg">
                    <div className="value" id="optNumDiv">
                        <input
                            type="text"
                            id="optNum"
                            className="center"
                            value={optNum}
                            onChange={optNumChange}
                            placeholder="請輸入 6 位數的驗證碼"
                        />
                    </div>
                    <span className="help-word">驗證碼錯誤</span>
                </div>

                <div className="form-item">
                    <div className="notification">{msgStr}</div>
                </div>
                <div className="form-item short btn-area">
                    <a className="btn full btn-dark" onClick={next}>
                        <span>下一步</span>
                    </a>
                </div>
            </div>
        </div>
    );
}
export default authenticator;
