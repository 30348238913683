import React, { useState, useEffect, useRef } from "react";
import {
    API_GET_CustomerHome,
    API_GET_CustomerName,
    API_GET_CustomerTitle,
    API_GET_AdNameList,
    API_GET_CustomerDataList,
} from "../../global/constants";
import { OpenModal, CloseModal, InitJS, MenuSet } from "../../global/ToolUtil";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
import CustomerSelectComponent from "../Common/CustomerSelectComponent";


const Customer = () => {
    var history = useHistory();

    useEffect(() => {
        //把menu顏色置換
        MenuSet("Customer");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    //取得資料 dataCard
    function getData() {
        API_GET_CustomerHome({})
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setCompanyArray(data.result.companyArray);
                    setCustomerTypeArray(data.result.customerTypeArray);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyIdArray, setCompanyIdArray] = useState([]);
    function companyIdArrayChange() {
        var tmpArray = [];
        var company = document.getElementsByName("companyCheckBox");
        for (var i = 0; i < company.length; i++) {
            if (company[i].checked) {
                tmpArray.push(parseInt(company[i].value));
            }
        }
        setCompanyIdArray(tmpArray);
    }

    const [CompanyId, setCompanyId] = useState();

    const [customerTypeArray, setCustomerTypeArray] = useState([{}]);
    const [customerType, setCustomerType] = useState("");

    const [qryList, setQryList] = useState([]);
    const [custName, setCustName] = useState("");
    const [custTitle, setCustTitle] = useState("");
    const [adName, setAdName] = useState("");
    const [adId, setAdId] = useState(null);

    const [resetFlag, setResetFlag] = useState(false);

    //打開查詢列表
    function openModal1() {
        var tmpArrStr = "";
        var bf = false;
        var f = document.getElementsByName("companyCheckBox");
        for (var i = 0; i < f.length; i++) {
            if (f[i].checked) {
                bf = true;
                tmpArrStr = tmpArrStr + "|" + f[i].value;
            }
        }
        if (bf == false) {
            document.getElementById("companyDiv").classList.add("show-help");
            return false;
        } else {
            document.getElementById("companyDiv").classList.remove("show-help");
        }

        if (tmpArrStr.substring(0, 1) == "|") {
            tmpArrStr = tmpArrStr.replace(/^./, "");
        }
        var dt = {
            companyIdArray: tmpArrStr,
            CustType: customerType,
            CustName: custName,
            CustTitle: custTitle,
            ADID: adId,
        };
        //console.log(JSON.stringify(dt));
        API_GET_CustomerDataList(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    if (data.result.data.length > 0) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            data.result.data[i].custNameAes = data.result.data[i].custName.replace(/&/g, "%26");
                        }
                    }
                    setQryList(data.result.data);
                    OpenModal("modal-1");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }
    function goEdit(custName, reqFrmID, CompanyId, custID) {
        var cName = custName;
        var path = "/Main?path=CustomerEdit&cName=" + cName.replace("&", "%26") + "&ReqFrmID=" + reqFrmID + "&companyId=" + CompanyId + "&custID=" + custID;
        history.push(path);
    }
    function goNew(custName, reqFrmID, CompanyId, custID) {
        var cName = custName;
        var path = "/Main?path=CustomerNew&cName=" + cName.replace("&", "%26") + "&custID=" + custID + "&ReqFrmID=" + reqFrmID + "&companyId=" + CompanyId + "&fromCopy=1";
        history.push(path);
    }



    const handleCustNameChange = (element) => {
        setCustName(element.name);
    };

    const handleCustTitleChange = (element) => {
        setCustTitle(element.name);
    };

    const handleCustomerTypeChange = (e) => {
        setCustomerType(e.target.value);
    };


    const getCustNameHandle = (element, setElementArray) => {

        API_GET_CustomerName({
            companyId: CompanyId ? CompanyId : [],
            input: element,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setElementArray(data.result.data == null ? [] : data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    const getCustTitleHandle = (element, setElementArray) => {
        API_GET_CustomerTitle({
            input: element,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setElementArray(data.result.data == null ? [] : data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    const getADNameHandle = (element, setElementArray) => {
        API_GET_AdNameList({
            input: element,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setElementArray(data.result.data == null ? [] : data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    const adNameSelectOptionHandle = (elements, selectOptionClickHandle) => {
        return elements.map((x) => (
            <li data-value={`${x.enName}(${x.tc_name})`} onClick={() => {
                selectOptionClickHandle(x, `${x.enName}(${x.tc_name})`)
            }}>
                <span className="flex-4">{x.enName}({x.tc_name})</span>
                <span className="flex-5">{x.mail}</span>
            </li>
        ));
    }

    const customSelectResetHandle = () => {
        setResetFlag(true);
    }

    useEffect(() => {
        if (resetFlag) {
            setResetFlag(false);
        }
    }, [resetFlag]);

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container narrow">
                    <h3 className="fz-B has-border">查詢/ 修改客戶資料</h3>
                </div>
            </div>
            <div className="container narrow">
                <div className="form-item must" id="companyDiv">
                    <div className="field">負責公司</div>
                    <div className="value">
                        {companyArray.map((x) => (
                            <div>
                                <input
                                    type="radio"
                                    key={`company_${x.id}`}
                                    id={`company_${x.id}`}
                                    name="companyCheckBox"
                                    value={x.id}
                                    checked={
                                        companyIdArray.findIndex((e) => e == x.id) >= 0
                                            ? true
                                            : false
                                    }
                                    onClick={(e) => { companyIdArrayChange(), setCompanyId(e.target.value), customSelectResetHandle() }}
                                />
                                <label htmlFor={`company_${x.id}`}>{x.name}</label>
                            </div>
                        ))}
                    </div>
                    <span className="help-word">請選擇負責公司</span>
                </div>

                <div className="form-item">
                    <div className="field">客戶類別</div>
                    <div className="value">
                        <select name="customerType" value={customerType} onChange={handleCustomerTypeChange}>
                            <option value="">全部</option>
                            {customerTypeArray.map((x) => (
                                <option value={x.id}>{x.name}</option>
                            ))}
                        </select>
                    </div>
                    <span className="help-word">請輸入正確格式</span>
                </div>

                <CustomerSelectComponent selectTitle={"客戶別"} getDataHandle={getCustNameHandle} onElementChange={handleCustNameChange} resetState={resetFlag} elementNameField={'name'} shareElementName={custName} />
                <CustomerSelectComponent selectTitle={"公司抬頭"} getDataHandle={getCustTitleHandle} onElementChange={handleCustTitleChange} resetState={resetFlag} elementNameField={'name'} shareElementName={custTitle}/>
                <CustomerSelectComponent selectTitle={"負責業務"} getDataHandle={getADNameHandle} selectOptionConfig={adNameSelectOptionHandle} resetState={resetFlag} shareElementName={adName}/>

                <div className="form-item btn-area sp">
                    <div className="left"></div>
                    <div className="right">
                        <a className="btn" onClick={openModal1}>
                            <span>查詢</span>
                        </a>
                    </div>
                </div>
            </div>
            <div id="modal-1" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">查詢結果</h3>
                        <span className="close" onClick={() => CloseModal("modal-1")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <ul className="list-table">
                            <li className="lt-row lt-th">
                                <div className="flex-2">負責公司</div>
                                <div className="flex-2">客戶類別</div>
                                <div className="flex-5">客戶別</div>
                                <div className="flex-5">公司抬頭</div>
                                <div className="flex-2 fn"></div>
                            </li>
                            {qryList.length == 0 ? null :
                                qryList.map((x) => (
                                    <li className="lt-row">
                                        <div className="flex-2">{x.cmpName}</div>
                                        <div className="flex-2">{x.custTypeName}</div>
                                        <div className="flex-5">
                                            <a href={void (0)} onClick={() => { goEdit(x.custName, x.reqFrmID, CompanyId, x.custID) }}>
                                                {x.custName}
                                            </a>
                                        </div>
                                        <div className="flex-5">{x.custTitle}</div>
                                        <div className="flex-2 fn">
                                            <div className="fn-bar">
                                                <div className="btn btn-icon" onClick={() => { goNew(x.custName, x.reqFrmID, CompanyId, x.custID) }}>
                                                    <i className="icon icon-29"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Customer;
