import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const CustomerReviewList = ({ qryList, setReqFrmID, isPM }) => {
    var history = useHistory();

    function openModalReview(frmID) {
        setReqFrmID(null);
        const timeout = setTimeout(() => {
            setReqFrmID(frmID)
        }, 200)
        return () => clearTimeout(timeout)
    }
    return (
        <div id="modal-list-7-0" className="modal large" data-modal>
            <div className="modal-container">
                <div className="modal-header">
                    <h3 className="fz-B has-border">{isPM == 1 ? "客戶新增/修改審查" : "客戶退回列表"}</h3>
                    <span className="close" data-close>
                        <i className="icon icon-44"></i>
                    </span>
                </div>
                <div className="modal-body">
                    <ul className="list-table">
                        <li className="lt-row lt-th">
                            <div className="flex-2">類別</div>
                            <div className="flex-2">{isPM == 1 ? "申請者" : "審核者"}</div>
                            <div className="flex-2">客戶別</div>
                            <div className="flex-2">業務單位</div>
                            <div className="flex-2">負責業務</div>
                            <div className="flex-2">時間</div>
                            <div className="flex-2"></div>
                        </li>
                        {qryList.map((x) => (
                            <li className="lt-row">
                                <div className="flex-2">
                                    {(() => {
                                        if (x.reqFrmType == 0) {
                                            return "新增";
                                        } else if (x.reqFrmType == 1) {
                                            return "修改";
                                        } else if (x.reqFrmType == 2) {
                                            return "刪除";
                                        }
                                    })()} 
                                </div>
                                <div className="flex-2">{x.applier}</div>
                                <div className="flex-2">{x.custName}</div>
                                <div className="flex-2">{x.cmpDivName}</div>
                                <div className="flex-2">{x.personADName}</div>
                                <div className="flex-2">{x.createdTimeStr}</div>
                                <div className="flex-2">
                                    <div className="fn-bar">
                                        <div className="btn btn-small btn-border" onClick={(e) => openModalReview(x.reqFrmID)}>
                                            <span>查看</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CustomerReviewList;
