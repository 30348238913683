import React, { useState, useEffect } from "react";
import { DebounceInput } from 'react-debounce-input';
import {
    API_GET_AllCompany,
    API_GET_AllCurrency,
    API_GET_CloudAcntUnderReview,
    API_GET_Customer3Name,
    API_GET_CloudAcntCloudProduct,
    API_POST_Revoke,
    API_PUT_CloudAcntReview,
    API_GET_CloudAcntRemark,
    API_POST_CloudAcntRemark,
    API_GET_CloudAcntGetRawDataList,
    API_POST_CloudAcntData,
    API_PUT_CloudAcntData,
    API_GET_CloudAcntTransferData,
    API_GET_CloudAcntCustPersonInCharge,
    API_GET_AdNameList
} from "../../../global/constants";
import { NewNumFormart, CloseModal, OpenModal, InitJS, Clone } from "../../../global/ToolUtil";
import { checkAuthToken } from "../../../global/TokenUtil";
import { useHistory } from "react-router-dom";
import { CheckResponseCode } from '../../../global/ResponseUtil';
import moment from 'moment';

const CloudAcntDetail = ({ reqFrmID, productName1, setModalStr, reGetData }) => {
    var history = useHistory();
    const [username, setUserName] = useState('');
    const [myID, setMyID] = useState(); //自己ID
    useEffect(() => {
        if (reqFrmID != null) {
            setUserName(localStorage.getItem("userName"));
            setTmpIndex1(0);
            getData();
        }
    }, [reqFrmID]);

    //#region 重新抓取資料
    function getData() {
        setReason("");
        OpenModal("modal-cloudacntreview");

        //#region 取得所有負責公司
        API_GET_AllCompany()
            .then((response) => response.data)
            .then((data) => {

                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setCompanyArray(data.result.data);
                }
            })
        //#endregion

        //#region 取得所有幣別
        API_GET_AllCurrency()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setCurrencyArray(data.result.data);
                }
            })
        //#endregion

        //#region 取得雲端帳號詳細資料
        var dt = {
            custFormReviewId: reqFrmID,
            productName1: productName1,
        };
        API_GET_CloudAcntUnderReview(dt)
            .then((response) => response.data)
            .then((data) => {
                console.log("雲端帳號資料", data.result);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                if (data.code == "0000") {
                    setIsPM(data.result.data.isPM);
                    setMyID(data.result.data.myId);
                    setReqFrmType(data.result.data.reqFrmType);
                    setReviewFormId(data.result.data.reqFrmID);
                    //萬一後端沒有給old 會導致整個藍屏，故加此防呆
                    if (data.result.data.reqFrmType == "1") {
                        if (data.result.data.accountDataOld.length == 0) {
                            setAccountDataOld(data.result.data.accountData);
                        } else {
                            setAccountDataOld(data.result.data.accountDataOld);
                        }
                    } else {
                        setAccountDataOld(data.result.data.accountDataOld);
                    }
                    setReqFrmCFState(data.result.data.reqFrmCFState);

                    setCompanyId(data.result.data.companyId);
                    setCompanyName(data.result.data.companyName);
                    setCustomerId(data.result.data.customerId);
                    setCustomerName(data.result.data.customerName);
                    setCustomerTitle(data.result.data.customerTitle);
                    setCustomerTaxId(data.result.data.customerTaxId);
                    setWholeDiscount((data.result.data.wholeDiscount == null ? "0%" : data.result.data.wholeDiscount + "%"));
                    setWholeDiscountOld(data.result.data.wholeDiscountOld == null ? "0%" : data.result.data.wholeDiscountOld + "%");
                    setPersonInChargeId(data.result.data.personInChargeId);
                    setPersonInChargeName(data.result.data.personInChargeName);
                    setAccountData(data.result.data.accountData);

                    //退回原因
                    setReason(data.result.data.drawBackReason);
                    setAuditorName(data.result.data.auditorName)
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error", 2);
                }
            })
        //#endregion

        API_GET_CloudAcntCloudProduct({
            productName1: productName1,
            companyId: companyId
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setCloudProductName2Array(data.result.data.productName2Array);
                }
            })

        //InitJS();
    }
    //#endregion

    //是否為PM
    const [isPM, setIsPM] = useState(0);
    const [reqFrmType, setReqFrmType] = useState(0);
    const [reqFrmCFState, setReqFrmCFState] = useState();
    //審核人
    const [auditorName, setAuditorName] = useState('');
    //幣別陣列
    const [currencyArray, setCurrencyArray] = useState([{}]);
    //公司
    const [companyId, setCompanyId] = useState();
    const [companyName, setCompanyName] = useState();
    const [companyIdError, setCompanyIdError] = useState(false);
    const [companyArray, setCompanyArray] = useState([{}]);
    //雲端細項總稱
    //const [productName, setProductName] = useState();
    //雲端細項
    const [cloudProductName2Array, setCloudProductName2Array] = useState([]);
    //資料列
    const [accountData, setAccountData] = useState([{}]);
    const [accountDataOld, setAccountDataOld] = useState([{}]);
    //暫存index
    const [tmpIndex1, setTmpIndex1] = useState(0);
    const [tmpIndex2, setTmpIndex2] = useState(0);
    const [tmpIndex3, setTmpIndex3] = useState(0);
    const [remarkType, setRemarkType] = useState();
    const [subAccountComments, setSubAccountComments] = useState([]); //選中的備註
    const [subAccountCommentsOld, setSubAccountCommentsOld] = useState([]); //選中的舊備註

    const [tmpRemark, setTmpRemark] = useState();
    const [reviewData, setReviewData] = useState([{}]);
    const [reviewFormId, setReviewFormId] = useState(-1);
    const [rawDataArray, setRawDataArray] = useState([{}]);

    const [selectSpecialPricing, setSelectSpecialPricing] = useState(null); //被選中的編輯計費項目
    const [formulaData, setFormulaData] = useState([{}]);
    const [specialPricingData, setSpecialPricingData] = useState([{}]);
    const [specialPricingDataOld, setSpecialPricingDataOld] = useState([{}]);

    //#region 打開編輯計費項目
    function OpenModalFormula(index, index2, productId) {
        setTmpIndex1(index);
        setTmpIndex2(index2);
        API_GET_CloudAcntGetRawDataList({ productId: productId })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setRawDataArray(data.result.data);
                }
            })

        let tempSpecialPricing = { ...accountData[index].specialPricing[index2] };
        tempSpecialPricing.formula.map((x) => { x.relatedId = tempSpecialPricing.id; return x; });
        setSelectSpecialPricing(tempSpecialPricing);

        //var dt = Clone(accountData[index].specialPricing[index2].formula);
        //if (dt[0].length == 0) {
        //    dt = [{
        //        rawData: null,
        //        isNumber: 0,
        //        formulaSigns: "<Y<",
        //        lowerLimit: null,
        //        upperLimit: null,
        //        value: "",
        //    }];
        //}
        //for (var i = 0; i < dt.length; i++) {
        //    dt[i].relatedId = accountData[index].specialPricing[index2].id;
        //}
        //setFormulaData(dt);
        //var dt2 = Clone(accountData[index].specialPricing[index2]);
        //console.log(dt2);
        //setSpecialPricingData(dt2);
        //var dt3 = [];
        //if (accountDataOld.length > 0 && accountDataOld[index].specialPricing.length >= index2) {
        //    dt3 = Clone(accountDataOld[index].specialPricing[index2].formula);

        //    if (dt3[0].length == 0) {
        //        dt3 = [{
        //            rawData: null,
        //            isNumber: 0,
        //            formulaSigns: "<Y<",
        //            lowerLimit: null,
        //            upperLimit: null,
        //            value: "",
        //        }];
        //    }
        //    //怕有新增 索引不夠 所以先塞空陣列 
        //    //if (dt.length > dt3.length) {

        //    //    for (var s = dt3.length; s < dt.length; s++) {

        //    //        dt3.push({
        //    //            rawData: null,
        //    //            isNumber: 0,
        //    //            formulaSigns: "<Y<",
        //    //            lowerLimit: null,
        //    //            upperLimit: null,
        //    //            value: "",
        //    //        } );
        //    //    }
        //    //}
        //    //setFormulaDataOld(dt3)
        //    var dt4 = Clone(accountDataOld[index].specialPricing[index2]);

        //    //setSpecialPricingDataOld(dt4);
        //}

        OpenModal("modal-formula", 3);
    }
    //#endregion

    //#region 修改編輯計費項目
    function formulaDataChange(e, index, col) {
        let newSelectSpecialPricing = { ...selectSpecialPricing };
        let newFormulaData = newSelectSpecialPricing.formula[index];
        if (col == "formulaSigns") {
            newFormulaData.formulaSigns = e.target.value;
            if (e.target.value == "Y=" || e.target.value == "Y≦" || e.target.value == "Y<") {
                newFormulaData.upperLimit = 0;
                newFormulaData.lowerLimit = 0;
                newFormulaData.value = "";
            } else if (e.target.value == "<Y<" || e.target.value == "≦Y≦" || e.target.value == "<Y≦" || e.target.value == "<Y≦" || e.target.value == "≦Y<") {
                newFormulaData.value = "";
            } else if (e.target.value == "≦Y" || e.target.value == "<Y") {
                newFormulaData.upperLimit = 0;
                newFormulaData.lowerLimit = 0;
                newFormulaData.value = "";
            }
        }
        else if (col == "isNumber") {
            newFormulaData.formulaSigns = newFormulaData.isNumber == 0 ? "<Y<" : "=";
            newFormulaData.isNumber = newFormulaData.isNumber == 0 ? 1 : 0;
        }
        else if (col == "rawData") {
            newFormulaData.rawData = e.target.value;
        }
        else if (col == "lowerLimit") {
            newFormulaData.lowerLimit = parseFloat((e.target.value + "").replace(/[^0-9.]/g, ''));
        }
        else if (col == "upperLimit") {
            newFormulaData.upperLimit = parseFloat((e.target.value + "").replace(/[^0-9.]/g, ''));
        }
        else if (col == "value") {
            newFormulaData.value = e.target.value;
        }

        newSelectSpecialPricing.formula[index] = newFormulaData;
        setSelectSpecialPricing(newSelectSpecialPricing);


        //setFormulaData(function (prev) {
        //    return prev.map((item, i) => {
        //        if (i == index) {
        //            if (col == "formulaSigns") {
        //                item.formulaSigns = e.target.value;
        //                if (e.target.value == "Y=" || e.target.value == "Y≦" || e.target.value == "Y<") {
        //                    item.upperLimit = 0;
        //                    item.lowerLimit = 0;
        //                    item.value = 0;
        //                } else if (e.target.value == "<Y<" || e.target.value == "≦Y≦" || e.target.value == "<Y≦" || e.target.value == "<Y≦" || e.target.value == "≦Y<") {
        //                    item.value = 0;
        //                } else if (e.target.value == "≦Y" || e.target.value == "<Y") {
        //                    item.upperLimit = 0;
        //                    item.lowerLimit = 0;
        //                    item.value = 0;
        //                }
        //            } else if (col == "isNumber") {
        //                item.formulaSigns = item.isNumber == 0 ? "<Y<" : "=";
        //                item.isNumber = item.isNumber == 0 ? 1 : 0;

        //            } else if (col == "rawData") {
        //                item.rawData = e.target.value;
        //            } else if (col == "lowerLimit") {
        //                item.lowerLimit = parseFloat((e.target.value + "").replace(/[^0-9.]/g, ''));
        //            } else if (col == "upperLimit") {
        //                item.upperLimit = parseFloat((e.target.value + "").replace(/[^0-9.]/g, ''));
        //            } else if (col == "value") {
        //                item.value = e.target.value;
        //            }
        //        }
        //        return item;
        //    });
        //});
    }
    //#endregion

    //#region 修改編輯計費項目 折扣計費欄位
    function specialPricingDataChange(e, col) {
        let newSelectSpecialPricing = { ...selectSpecialPricing };
        if (col == "discountPrice") {
            let tmp = parseFloat((e.target.value + "").replace(/[^0-9.-]/g, ''));
            newSelectSpecialPricing.discountPrice = !isNaN(tmp) ? tmp : 0;
        }
        else if (col == "rawData") {
            newSelectSpecialPricing.rawData = e.target.value;
        }
        else if (col == "discountUnit") {
            newSelectSpecialPricing.discountUnit = e.target.value;
        }
        setSelectSpecialPricing(newSelectSpecialPricing);

        //var dt = Clone(specialPricingData);
        //if (col == "discountPrice") {
        //    let tmp = parseFloat((e.target.value + "").replace(/[^0-9.-]/g, ''));
        //    dt.discountPrice = !isNaN(tmp) ? tmp : 0;
        //}
        //if (col == "rawData") {
        //    dt.rawData = e.target.value;
        //}
        //if (col == "discountUnit") {
        //    dt.discountUnit = e.target.value;
        //}
        //setSpecialPricingData(dt);
    }
    //#endregion

    //#region 編輯計費項目 確定算式
    function CheckFormulaData() {
        //更新 折扣計費欄位 折扣 / 單價
        //更新整個formula 雲端帳號計算公式
        setAccountData(function (prev) {
            return prev.map((item, i) => {
                if (i == tmpIndex1) {
                    item.specialPricing.map((item2, i2) => {
                        if (i2 == tmpIndex2) {
                            item2.discountPrice = selectSpecialPricing.discountPrice;
                            item2.discountUnit = selectSpecialPricing.discountUnit;
                            item2.rawData = selectSpecialPricing.rawData;
                            item2.formula = selectSpecialPricing.formula;

                            //item2.discountPrice = specialPricingData.discountPrice;
                            //item2.discountUnit = specialPricingData.discountUnit;
                            //item2.rawData = specialPricingData.rawData;
                            //item2.formula = formulaData;
                        }
                    })
                }
                return item;
            });
        });
        CloseModal("modal-formula");
    }
    //#endregion

    //#region 編輯計費項目 清空欄位
    function ClearFormulaData() {
        setSelectSpecialPricing({
            rawData: null,
            discountPrice: 0,
            discountUnit: 0,
            formula: [{
                rawData: null,
                isNumber: 0,
                formulaSigns: "=",
                lowerLimit: null,
                upperLimit: null,
                value: "",
            }]
        });


        //var dt = [{
        //    rawData: null,
        //    isNumber: 0,
        //    formulaSigns: "<Y<",
        //    lowerLimit: null,
        //    upperLimit: null,
        //    value: null,
        //}];
        //setFormulaData(dt);
        //var dt = Clone(specialPricingData);
        //dt.rawData = null;
        //dt.discountPrice = 0;
        //dt.discountUnit = 0;
        //setSpecialPricingData(dt);
    }
    //#endregion

    //#region 編輯計費項目 新增計費公式
    function AddFormulaData() {
        let newSelectSpecialPricing = { ...selectSpecialPricing };
        newSelectSpecialPricing.formula.push({
            rawData: null,
            isNumber: 0,
            formulaSigns: "=",
            lowerLimit: null,
            upperLimit: null,
            value: "",
        });
        setSelectSpecialPricing(newSelectSpecialPricing);

        //setFormulaData(function (prev) {
        //    return [...prev,
        //    {
        //        rawData: null,
        //        isNumber: 0,
        //        formulaSigns: "<Y<",
        //        lowerLimit: null,
        //        upperLimit: null,
        //        value: "'",
        //    }];
        //});
    }
    //#endregion

    //#region 刪除編輯計費項目
    function DelFormulaData(index) {
        let newSelectSpecialPricing = { ...selectSpecialPricing };
        newSelectSpecialPricing.formula.splice(index, 1);
        setSelectSpecialPricing(newSelectSpecialPricing);


        //setFormulaData(function (prev) {
        //    return prev.filter((x, i) => i != index);
        //});
    }
    //#endregion

    //折扣
    const [wholeDiscount, setWholeDiscount] = useState("0%");
    const [wholeDiscountOld, setWholeDiscountOld] = useState();
    const [customerId, setCustomerId] = useState();
    //客戶名
    const [customerName, setCustomerName] = useState();
    const [customerNameArray, setCustomerNameArray] = useState([{}]);

    //客戶抬頭
    const [customerTitle, setCustomerTitle] = useState();
    const [customerTitleArray, setCustomerTitleArray] = useState([{}]);

    //客戶統編
    const [customerTaxId, setCustomerTaxId] = useState();
    const [customerTaxIdArray, setCustomerTaxIdArray] = useState([{}]);

    //負責業務
    const [adNameState, setAdNameState] = useState(true);
    const [personInChargeNameArray, setPersonInChargeNameArray] = useState([]);
    const [personInChargeName, setPersonInChargeName] = useState();
    const [personInChargeId, setPersonInChargeId] = useState();

    //#region 修改負責業務
    function personInChargeNameChange(e) {
        setPersonInChargeName(e.target.value);
        getPersonInChargeName();
    }
    //#endregion

    //#region 取得負責業務列表
    function getPersonInChargeName() {
        if (document.getElementById("personInChargeNameText").value == "") {
            return false;
        }

        API_GET_AdNameList({
            input: document.getElementById("personInChargeNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setPersonInChargeNameArray(data.result.data);
                }
            }).catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //#endregion

    const [tID, setTID] = useState();
    const [tCompanyId, setTCompanyId] = useState();
    const [tCompanyIdError, setTCompanyIdError] = useState(false);
    const [tCustomerId, setTCustomerID] = useState();
    const [tCustomerName, setTCustomerName] = useState();
    const [tCustomerNameError, setTCompanyNameError] = useState();
    const [tCustomerNameArray, setTCustomerNameArray] = useState([{}]);
    const [tCustomerTitle, setTCustomerTitle] = useState();
    const [tCustomerTitleArray, setTCustomerTitleArray] = useState([{}]);
    const [tCustomerTaxId, setTCustomerTaxId] = useState();
    const [tCustomerTaxIdArray, setTCustomerTaxIdArray] = useState([{}]);
    const [tAccountCode, setTAccountCode] = useState();
    const [tAccountId, setTAccountId] = useState();
    const [tPersonInChargeName, setTPersonInChargeName] = useState();
    function custNameChange(e) {
        setTCustomerName(e.target.value);
        getCustomer3NameT(1);
    }
    function custTitleChange(e) {
        setTCustomerTitle(e.target.value);
        getCustomer3NameT(2);
    }
    function custTaxIDChange(e) {
        setTCustomerTaxId(e.target.value);
        getCustomer3NameT(3);
    }
    function changeTCustomer(cid) {
        //console.log(cid);
        //改了轉移的客戶別
        //呼叫11-17重抓負責業務
        var dt = {
            customerId: cid,
        };
        API_GET_CloudAcntCustPersonInCharge(dt)
            .then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (data.code == "0000") {

                    setTPersonInChargeName(data.result.data.personInChargeName);
                }
            })
    }
    //退回原因
    const [reason, setReason] = useState();
    //資料修改 第一層的
    function accountDataChange(e, index, col) {
        setAccountData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "acntId") {
                        item.acntId = e.target.value;
                    } else if (col == "acntName") {
                        item.acntName = e.target.value;
                    } else if (col == "acntCode") {
                        item.acntCode = e.target.value;
                    } else if (col == "productId") {
                        item.productId = e.target.value;
                    } else if (col == "quotaCurrencyId") {
                        item.quotaCurrencyId = e.target.value;
                    } else if (col == "quota") {
                        let tmp = parseFloat(e.target.value.replaceAll(/,/g, '').replace(/[^0-9.]/g, ''));
                        item.quota = !isNaN(tmp) ? tmp : "";
                    } else if (col == "serviceCharge") {
                        let tmp = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
                        item.serviceCharge = !isNaN(tmp) ? tmp : 0;
                    } else if (col == "state") {
                        item.state = item.state == 1 ? 2 : 1; //如果啟用狀態本來是1 就給他2 反之2 就給1
                    } else if (col == "stateDate") {
                        item.stateDate = e.target.value;
                    } else if (col == "help") {
                        item.help = item.help == 1 ? 0 : 1; //如果技術支援本來是1 就給他0 反之0 就給1
                    } else if (col == "isOpen") {
                        item.isOpen = (item.isOpen == null || item.isOpen == false) ? true : false;
                    }
                }
                return item;
            });
        });
    }
    //資料修改 第二層的特殊產品計價
    function accountDataChange2(e, index, index2, col) {
        setAccountData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    for (var i2 = 0; i2 < item.specialPricing.length; i2++) {
                        if (i2 == index2) {

                            if (col == "title") {
                                item.specialPricing[i2].title = e.target.value;
                            } else if (col == "isCoupon") {
                                item.specialPricing[i2].isCoupon = item.specialPricing[i2].isCoupon == 1 ? 0 : 1;
                            } else if (col == "rawData") {
                                item.specialPricing[i2].rawData = e.target.value;
                            } else if (col == "discountPrice") {
                                item.specialPricing[i2].discountPrice = e.target.value;
                            } else if (col == "discountUnit") {
                                item.specialPricing[i2].discountUnit = e.target.value;
                            }
                            //rawData  discount discountUnit 是在內頁修改 所以修改玩要先把內頁的資料也更新
                            var dt = Clone(item);
                            setSpecialPricingData(dt);
                        }
                    }

                }
                return item;
            });
        });
    }
    //轉移的
    //重新取得 客戶別 客戶別Title 客戶統編 List
    function getCustomer3NameT(index) {
        var dt = [];
        if (index == 1) {
            dt = {
                customerName: document.getElementById("customerNameTextT").value
            }
        }
        else if (index == 2) {
            dt = {
                customerTitle: document.getElementById("customerTitleTextT").value
            }

        }
        else if (index == 3) {
            dt = {
                customerTaxId: document.getElementById("customerTaxIdTextT").value,
            }
        }
        API_GET_Customer3Name(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                var dataName = [];
                var dataTitle = [];
                var dataTaxId = [];
                if (data.result != null && data.result.data != null) {
                    if (index == 1) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataName.length; j++) {
                                if (dataName[j].custName == data.result.data[i].custName) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataName.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        //console.log(dataName);
                        setTCustomerNameArray(dataName);
                    } else if (index == 2) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTitle.length; j++) {
                                if (dataTitle[j].custTitle == data.result.data[i].custTitle) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTitle.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setTCustomerTitleArray(dataTitle);
                    } else if (index == 3) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTaxId.length; j++) {
                                if (dataTaxId[j].custTaxID == data.result.data[i].custTaxID) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTaxId.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setTCustomerTaxIdArray(dataTaxId);
                    }
                }
            })

    }



    //撤銷
    function Revoke() {
        document.querySelector("#modal-cloudacntreview").classList.remove("active");
        OpenModal("modal-cloudacntrevoke");

    }

    //#region 打開備註
    function OpenModalRemark(index, type) {
        setTmpRemark("");
        setTmpIndex1(index);
        setRemarkType(type);
        setSubAccountComments(accountData[index].subAccountComments.filter(x => x.type == type));
        if (accountDataOld && accountDataOld[index]) {
            setSubAccountCommentsOld(accountDataOld[index].subAccountComments.filter(x => x.type == type));
        }
        OpenModal("modal-remark", 3);
    }
    //#endregion

    //#region 修改備註
    function editRemark() {
        var newAccountData = [...accountData];
        newAccountData[tmpIndex1].subAccountComments.push({
            id: null,
            accountId: newAccountData[tmpIndex1].acntId,
            type: remarkType,
            content: tmpRemark,
            userId: parseInt(myID),
            userName: username,
            createdTime: new Date(),
            flag: "N"
        });
        setAccountData(newAccountData);

        CloseModal("modal-remark");
    }
    //#endregion

    //確認再次送審
    function AgainSend() {
        CloseModal("modal-cloudacntagain");
        if (reqFrmType == 0 || reqFrmType == 1) {//修改 執行checkdata
            var result = checkData();
            if (result == false) {
                return false;
            }
        }

        //移除備註的flag為R的資料
        let newAccountData = [...accountData];
        newAccountData.forEach((item) => {
            item.subAccountComments = item.subAccountComments.filter(x => x.flag != "R")
        });
        setAccountData(newAccountData);

        if (reqFrmType == 0) {
            //新增
            var dt = {
                reqFrmID: reqFrmID,
                companyId: companyId,
                customerId: customerId,
                customerName: customerName,
                customerTitle: customerTitle,
                customerTaxId: customerTaxId,
                personInChargeId: personInChargeId,
                wholeDiscount: (wholeDiscount == "" ? null : parseFloat(wholeDiscount.replace(/[^0-9.]/g, ''))),
                accountData: accountData,
            };
            //API 11-6
            console.log(JSON.stringify(dt));

            API_POST_CloudAcntData(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        let msg = reqFrmCFState == 2 ? "再次送審完成" : "審核完成"
                        setModalStr(msg);
                        reGetData();
                        CloseModal("modal-cloudacntreview");
                        OpenModal("modal-ok");
                    } else {
                        setModalStr(data.message);
                        OpenModal("modal-error");
                    }
                })
        } else if (reqFrmType == 1) {
            //修改
            var dt = {
                reqFrmId: reqFrmID,
                companyId: companyId,
                customerId: customerId,
                wholeDiscount: (wholeDiscount == "" ? null : parseFloat(wholeDiscount.replace(/[^0-9.]/g, ''))),
                personInChargeId: personInChargeId,
                accountData: accountData,
            };
            console.log(JSON.stringify(dt));
            API_PUT_CloudAcntData(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        let msg = reqFrmCFState == 2 ? "再次送審完成" : "審核完成"
                        setModalStr(msg);
                        reGetData();
                        CloseModal("modal-cloudacntreview");
                        OpenModal("modal-ok");
                    } else {
                        setModalStr(data.message);
                        OpenModal("modal-error");
                    }
                })
        }
    }
    //資料檢查(客戶)
    function checkData() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-required-cloudacnt]");
        requiredlist.forEach((element) => {
            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });

        return result;
    }
    //產品審核 退回
    function Reason(index) {
        CloseModal("modal-cloudacntcheckreason");
        API_PUT_CloudAcntReview({
            custFormReviewId: reqFrmID,
            reviewStatus: 2,
            reason: reason,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setModalStr(data.message);
                if (data.code == "0000" || data.code == "0001") {
                    reGetData();
                    CloseModal("modal-cloudacntreview");
                    OpenModal("modal-ok");
                }
                else {
                    OpenModal("modal-error");
                }
            })
    }

    //撤銷
    function RevokeSend() {
        CloseModal("modal-cloudacntrevoke");
        API_POST_Revoke({
            category: "CloudAcnt",
            reqFrmID: reqFrmID,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setModalStr("撤銷成功");
                    reGetData();
                    OpenModal("modal-ok");
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
    }
    //通過
    function Agree() {
        CloseModal("modal-cloudacntcheckagree");
        var finalData = {
            accountData: accountData,
            accountDataOld: accountDataOld,
            auditorName: auditorName,
            companyId: companyId,
            companyName: companyName,
            customerId: customerId,
            custFormReviewId: reqFrmID,
            customerName: customerName,
            customerTaxId: customerTaxId,
            customerTitle: customerTitle,
            drawBackReason: reason,
            isPM: isPM,
            myId: myID,
            personInChargeId: personInChargeId,
            personInChargeName: personInChargeName,
            productName1: productName1,
            reqFrmCFState: reqFrmCFState,
            reqFrmID: reqFrmID,
            reqFrmType: reqFrmType,
            wholeDiscount: (wholeDiscount == "" ? null : parseFloat(wholeDiscount.replace(/[^0-9.]/g, ''))),
            wholeDiscountOld: (wholeDiscountOld == "" ? null : parseFloat(wholeDiscountOld.replace(/[^0-9.]/g, ''))),
        };

        var dt = {
            custFormReviewId: reqFrmID,
            reviewStatus: 1,
            reason: reason == null ? "同意" : reason,
            dmlView: finalData
        };
        console.log("雲端帳號審查通過的json", JSON.stringify(dt));
        API_PUT_CloudAcntReview(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000" || data.code == "0001") {
                    setModalStr("審核完成");
                    reGetData();
                    CloseModal("modal-cloudacntreview");
                    OpenModal("modal-ok");
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
    }
    function openModalReason() {
        if (reason == "" || reason === undefined || reason == null) {
            document.getElementById("reason11").classList.add("error");
            return false;
        } else {
            document.getElementById("reason11").classList.remove("error");
        }
        OpenModal("modal-cloudacntcheckreason", 2);
    }
    const [transferData, setTransferData] = useState([{}]);
    //轉移帳號
    function OpenModalTransfer(index, cloudAccountId, transferredCustId) {
        //console.log(cloudAccountId);
        //先記錄暫存index
        setTmpIndex1(index);
        if (cloudAccountId != 0 && cloudAccountId != null) {
            var dt = {
                cloudAccountId: cloudAccountId,
                transferredCustId: transferredCustId
            }
            API_GET_CloudAcntTransferData(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        setTID(data.result.data.accountId);
                        setTCompanyId(data.result.data.companyId);
                        setTCustomerID(data.result.data.companyId);
                        setTCustomerName(data.result.data.customerName);
                        setTCustomerTitle(data.result.data.customerTitle);
                        setTCustomerTaxId(data.result.data.customerTaxId);
                        setTAccountId(data.result.data.accountId);
                        setTAccountCode(data.result.data.accountCode);
                        setTPersonInChargeName(data.result.data.personInChargeName);
                        //找暫存資料 (或許他有轉移過但沒儲存)
                        for (var i = 0; i < transferData.length; i++) {
                            //console.log(data.result.data.accountId);
                            if (transferData[i].accountId == data.result.data.accountId) {
                                setTCompanyId(transferData[i].company);
                                setTCustomerID(transferData[i].customerID);
                                setTCustomerName(transferData[i].customerName);
                                setTCustomerTitle(transferData[i].customerTitle);
                                setTCustomerTaxId(transferData[i].customerTaxId);
                                setTAccountId(transferData[i].accountId);
                                setTAccountCode(transferData[i].accountCode);
                                setTPersonInChargeName(transferData[i].personInChargeName);
                            }
                        }

                        //setTCompanyId(data.result.data.companyId);
                        //setTCustomerName(data.result.data.customerName);
                        //setTCustomerTitle(data.result.data.customerTitle);
                        //setTCustomerTaxId(data.result.data.customerTaxId);
                        //setTAccountId(data.result.data.accountId);
                        //setTAccountCode(data.result.data.accountCode);
                        //setTPersonInChargeName(data.result.data.personInChargeName);
                    }
                })
        }
        OpenModal("modal-transfer", 3);
    }
    function OpenModalCheckTransfer() {
        if (tCompanyId == null) {
            setTCompanyIdError(true);
            return false;
        } else {
            setTCompanyIdError(false);
        }
        if (tCustomerName == null || tCustomerName == '') {
            setTCompanyNameError(true);
            return false;
        } else {
            setTCompanyNameError(false);
        }
        OpenModal("modal-checktransfer", 4);
    }
    //確定轉移 寫入暫存
    function Transfer() {
        //標記有資料有轉移，外面需要呼叫11-11
        //setHasTransfer(true);
        //將外面資料的ID改成剛剛輸入的ID
        setAccountData(function (prev) {
            return prev.map((item, i) => {
                if (i == tmpIndex1) {
                    //tAccountCode 非必填 這邊簡單防呆
                    if (tAccountCode != null && tAccountCode != '') {
                        item.acntCode = tAccountCode;
                    }
                    item.transCustId = tCustomerId;
                    item.transCmpID = tCompanyId;
                    item.transCustName = tCustomerName;
                }
                return item;
            });
        });
        //將這筆資料先寫在暫存的ARRAY 等外面呼叫11-7使用
        var tmpTransferArray = Clone(transferData);
        var hasData = false;
        for (var i = 0; i < tmpTransferArray.length; i++) {
            if (tmpTransferArray[i].accountId == tAccountId) {
                hasData = true;
                tmpTransferArray[i].company = tCompanyId;
                tmpTransferArray[i].customerId = tCustomerId;
                tmpTransferArray[i].accountCode = tAccountCode;
                tmpTransferArray[i].customerName = tCustomerName;
                tmpTransferArray[i].customerTaxId = tCustomerTaxId;
                tmpTransferArray[i].customerTitle = tCustomerTitle;
                tmpTransferArray[i].personInChargeName = tPersonInChargeName;
            }
        }
        if (hasData == false) {
            //沒資料 重新寫一筆進去
            var dt = {
                accountId: tAccountId,
                customerId: tCustomerId,
                company: tCompanyId,
                accountCode: tAccountCode,
                customerName: tCustomerName,
                customerTaxId: tCustomerTaxId,
                customerTitle: tCustomerTitle,
                personInChargeName: tPersonInChargeName,
            }
            tmpTransferArray.push(dt);
        }
        //整理玩寫入TransferData
        setTransferData(tmpTransferArray);
        //關閉modal 
        CloseModal("modal-checktransfer", 4);
        CloseModal("modal-transfer", 3);
    }

    return (
        <>
            <div id="modal-cloudacntreview" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-stamp">
                        <div className="circle">
                            <strong>
                                {(() => {
                                    if (reqFrmCFState == 2) {
                                        return "退回";
                                    } else {
                                        if (reqFrmType == 0) {
                                            return "新增";
                                        } else if (reqFrmType == 1) {
                                            return "修改";
                                        } else if (reqFrmType == 2) {
                                            return "刪除";
                                        }
                                    }
                                })()}
                            </strong>
                        </div>
                    </div>
                    <div className="modal-header">
                        <h3 className="fz-B has-border"> {reqFrmType == 0 ? '待確認新增雲端帳號' : '待確認修改/移轉雲端帳號'}</h3>
                        <div className="close" data-close>
                            <i className="icon icon-44"></i>
                        </div>
                        {
                            reqFrmCFState == 2 ?
                                <div className="right">
                                    審查人：{auditorName}
                                </div> :
                                null
                        }
                    </div>
                    <div className="modal-body">
                        <div className="form-container">
                            <div className={`form-item ${companyIdError ? 'show-help' : ''}`}>
                                <div className="field">隸屬公司</div>
                                <div className="value">
                                    {companyArray.map((x) => (
                                        <div key={x.id}>
                                            <input
                                                type="radio"
                                                id={`1121-${x.cmpID}`}
                                                name="f1121"
                                                value={x.cmpID}
                                                checked={companyId == x.cmpID ? true : false}
                                                disabled="disabled"

                                            />
                                            <label htmlFor={`1121-${x.cmpID}`}>{x.cmpName}</label>
                                        </div>
                                    ))}
                                </div>
                                <div className="help-word">
                                    請選擇負責公司
                                </div>
                            </div>

                            <div className="form-item  half " >
                                <div className="field">客戶別</div>
                                <div className="value">
                                    <input type="text" value={customerName} disabled="disabled" />
                                </div>
                                <div className="help-word">
                                    請輸入正確格式
                                </div>
                            </div>

                            <div className="form-item half">
                                <div className="field">公司抬頭</div>
                                <div className="value">
                                    <input type="text" value={customerTitle} disabled="disabled" />
                                </div>
                                <span className="help-word">
                                    請輸入正確格式
                                </span>
                            </div>
                            <div className={`form-item half has-datalist ${isPM && reqFrmCFState == 2 ? "must" : ""}`} data-datalist>
                                <div className="field">負責業務</div>
                                <div className="value multi-inline">
                                    <input
                                        id="personInChargeNameText"
                                        type="text"
                                        value={personInChargeName}
                                        onChange={(e) => personInChargeNameChange(e)}
                                        disabled={!isPM || reqFrmCFState != 2 || adNameState}
                                        data-required />
                                    <ul className="datalist scrollbar">
                                        {
                                            personInChargeNameArray.map(x =>
                                                <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => { setPersonInChargeName(`${x.enName}(${x.tc_name})`), setPersonInChargeId(x.id) }}>
                                                    <span className="flex-3 bold">{x.tc_name}</span>
                                                    <span className="flex-3">{x.enName}</span>
                                                    <span className="flex-2">{x.mail}</span>
                                                    <span className="flex-2">{x.id}</span>
                                                </li>
                                            )
                                        }
                                    </ul>
                                    {isPM && reqFrmCFState == 2 ?
                                        <a className="btn btn-border btn-middle" onClick={(e) => setAdNameState(!adNameState)}>
                                            <span>修改</span>
                                        </a>
                                        : <></>
                                    }
                                </div>
                                <div className="help-word">
                                    請輸入正確格式
                                </div>
                            </div>
                            <div className="form-item half" >
                                <div className="field">公司統編/ 編號</div>
                                <div className="value">
                                    <input type="text" value={customerTaxId} disabled="disabled" />

                                </div>
                                <div className="help-word">
                                    請輸入正確格式
                                </div>
                            </div>

                            <hr />
                            <div className="form-item title">
                                <h6 className="fz-B">雲端細項總稱：{productName1}</h6>
                            </div>

                            <div className="form-item half">
                                <div className="field">折扣</div>
                                <div className="value">
                                    <DebounceInput
                                        debounceTimeout={100}
                                        type="text"
                                        className="right small"
                                        style={reqFrmCFState != 2 && wholeDiscountOld != wholeDiscount ? { "color": "#F37402" } : null}
                                        value={`${wholeDiscount}`}
                                        onClick={(e) => e.target.select()}
                                        onChange={(e) => {
                                            let tmp = e.target.value.replace(/[^0-9.]/g, '');
                                            if (tmp == "") {
                                                tmp = "0";
                                            }
                                            setWholeDiscount("");
                                            setWholeDiscount(parseFloat(tmp) + "%");
                                        }}
                                        disabled={reqFrmCFState == 2 ? null : 'disabled'}
                                        data-required-cloudacnt />
                                </div>
                                <div className="help-word">
                                    請輸入正確格式
                                </div>
                            </div>
                            <hr />

                            <div className="form-item col">
                                <div className="field">雲端帳號列表</div>
                                <div className="value">
                                    <ul className="list-table">
                                        <li className="lt-row lt-th">
                                            <div className="flex-1 max-60">序號</div>
                                            <div className="flex-5">帳號 ID</div>
                                            <div className="flex-5">帳號名稱</div>
                                            <div className="flex-5">帳號代號</div>
                                            {
                                                reqFrmType == 1 ?
                                                    <div className="flex-5">移轉後客戶別</div>
                                                    :
                                                    <></>
                                            }
                                            <div className="flex-5">產品細項</div>
                                            <div className="flex-1 fn max-60"></div>
                                        </li>
                                        {
                                            accountData.length > 0 &&
                                            accountData.map((x, i) =>
                                                <>
                                                    <li key={i} className={`lt-row ${x.isOpen == true ? 'highlight' : ''}`}>
                                                        <div className="flex-1 max-60 center" id={`st-lt-${i}`}>{i < 9 ? '0' + (i + 1) : (i + 1)}</div>
                                                        <div className="flex-5"> {/*帳號 ID*/}
                                                            {
                                                                reqFrmCFState == 2 && reqFrmType == 0 ?
                                                                    <>
                                                                        <input type="text" value={x.acntId} onChange={e => accountDataChange(e, i, "acntId")} data-required-cloudacnt />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {(() => {
                                                                            var str = "";
                                                                            var tempAccountDataOld = accountDataOld.find(y => y.id == x.id)

                                                                            if (reqFrmType == 1 && tempAccountDataOld && tempAccountDataOld.acntId != x.acntId) {
                                                                                str = "fc-orange";
                                                                            }

                                                                            return (
                                                                                <div className={`elips ${str}`}>
                                                                                    {x.acntId}
                                                                                </div>
                                                                            )
                                                                        })()}
                                                                    </>
                                                            }
                                                        </div>
                                                        <div className="flex-5"> {/*帳號名稱*/}
                                                            {
                                                                reqFrmCFState == 2 ?
                                                                    <>
                                                                        <input type="text" value={x.acntName} onChange={e => accountDataChange(e, i, "acntName")} data-required-cloudacnt />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {(() => {
                                                                            var str = "";
                                                                            var tempAccountDataOld = accountDataOld.find(y => y.id == x.id)

                                                                            if (reqFrmType == 1 && tempAccountDataOld && tempAccountDataOld.acntName != x.acntName) {
                                                                                str = "fc-orange";
                                                                            }

                                                                            return (
                                                                                <div className={`elips ${str}`}>
                                                                                    {x.acntName}
                                                                                </div>
                                                                            )
                                                                        })()}
                                                                    </>
                                                            }
                                                        </div>
                                                        <div className="flex-5"> {/*帳號代號*/}
                                                            {
                                                                reqFrmCFState == 2 ?
                                                                    <>
                                                                        <input type="text" value={x.acntCode} onChange={e => accountDataChange(e, i, "acntCode")} data-required-cloudacnt />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {(() => {
                                                                            var str = "";
                                                                            var tempAccountDataOld = accountDataOld.find(y => y.id == x.id)

                                                                            if (reqFrmType == 1 && tempAccountDataOld && tempAccountDataOld.acntCode != x.acntCode) {
                                                                                str = "fc-orange";
                                                                            }

                                                                            return (
                                                                                <div className={`elips ${str}`}>
                                                                                    {x.acntCode}
                                                                                </div>
                                                                            )
                                                                        })()}
                                                                    </>
                                                            }
                                                        </div>
                                                        {reqFrmType == 1 ?
                                                            <div className="flex-5"> {/*移轉後客戶別*/}
                                                                {
                                                                    reqFrmCFState == 2 ?
                                                                        x.transCustName != "" ?
                                                                            <>
                                                                                <span>
                                                                                    {x.transCustName}
                                                                                </span>
                                                                                <a className="btn btn-small btn-border" onClick={() => OpenModalTransfer(i, x.id, x.transCustId)}>
                                                                                    <span>變更</span>
                                                                                </a>
                                                                            </>
                                                                            :
                                                                            <a className="btn btn-small btn-border" onClick={() => OpenModalTransfer(i, x.id, x.transCustId)}>
                                                                                <span>轉移帳號</span>
                                                                            </a>
                                                                        :
                                                                        <>
                                                                            {(() => {
                                                                                var str = "";
                                                                                var tempAccountDataOld = accountDataOld.find(y => y.id == x.id)

                                                                                if (reqFrmType == 1 && tempAccountDataOld && tempAccountDataOld.transCustName != x.transCustName) {
                                                                                    str = "fc-orange";
                                                                                }

                                                                                return (
                                                                                    <div className={`elips ${str}`}>
                                                                                        {x.transCustName}
                                                                                    </div>
                                                                                )
                                                                            })()}
                                                                        </>
                                                                }
                                                            </div>
                                                            : <></>
                                                        }
                                                        <div className="flex-5">
                                                            {
                                                                reqFrmCFState == 2 ?
                                                                    <select name="" id="" onChange={e => accountDataChange(e, i, "productId")} data-required-cloudacnt>
                                                                        <option value="" disabled>請選擇</option>
                                                                        {
                                                                            cloudProductName2Array.map(c =>
                                                                                <option value={c.id} selected={x.productId == c.id ? true : false}>{c.name}</option>
                                                                            )
                                                                        }
                                                                    </select>
                                                                    :
                                                                    <>
                                                                        {(() => {
                                                                            var str = "";
                                                                            var tempAccountDataOld = accountDataOld.find(y => y.id == x.id)

                                                                            if (reqFrmType == 1 && tempAccountDataOld && tempAccountDataOld.productName2 != x.productName2) {
                                                                                str = "fc-orange";
                                                                            }

                                                                            return (
                                                                                <div className={`elips ${str}`}>
                                                                                    {x.productName2}
                                                                                </div>
                                                                            )
                                                                        })()}
                                                                    </>
                                                            }
                                                        </div>


                                                        <div className="flex-1 fn max-60">
                                                            <div className={`btn btn-icon ${x.isOpen == true ? 'rotate' : ''}`} onClick={(e) => accountDataChange(e, i, "isOpen")}>
                                                                <i className="icon icon-28"></i>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className={`lt-row ${x.isOpen == true ? '' : 'hide'}`} id={`st-${i}`}>
                                                        <ul className="list-table nested">
                                                            <li className="lt-row lt-th" >
                                                                <div className="flex-1 max-60"></div>
                                                                <div className="flex-2">額度幣別</div>
                                                                <div className="flex-3 right">帳號額度</div>
                                                                <div className="flex-1 right">服務費</div>
                                                                <div className="flex-2 center">測試</div>
                                                                <div className="flex-2">
                                                                    {x.state == 1 ? '正式啟用日期' : '測試到期日'}
                                                                </div>
                                                                <div className="flex-2 center">技術支援</div>
                                                                <div className="flex-2 fn jcfe"></div>
                                                            </li>
                                                            <li className="lt-row">
                                                                <div className="flex-1 max-60"></div>
                                                                <div className="flex-2"> {/*額度幣別*/}
                                                                    {
                                                                        reqFrmCFState == 2 ?
                                                                            <select name="" id="" onChange={e => accountDataChange(e, i, "quotaCurrencyId")} data-required-cloudacnt>
                                                                                <option value="">請選擇</option>
                                                                                {
                                                                                    currencyArray.map(c =>
                                                                                        <option value={c.currID} selected={x.quotaCurrencyId == c.currID ? true : false}>{c.currName}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                            :
                                                                            <>
                                                                                {(() => {
                                                                                    var str = "";
                                                                                    var tempAccountDataOld = accountDataOld.find(y => y.id == x.id)

                                                                                    if (reqFrmType == 1 && tempAccountDataOld && tempAccountDataOld.quotaCurrencyId != x.quotaCurrencyId) {
                                                                                        str = "fc-orange";
                                                                                    }

                                                                                    return (
                                                                                        <div className={`elips ${str}`}>
                                                                                            {
                                                                                                currencyArray.map(c =>
                                                                                                    x.quotaCurrencyId == c.currID ? c.currName : ''
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })()}
                                                                            </>
                                                                    }
                                                                </div>
                                                                <div className="flex-3 right"> {/*帳號額度*/}
                                                                    {
                                                                        reqFrmCFState == 2 ?
                                                                            //<input type="text" value={x.quota} onChange={e => accountDataChange(e, i, "quota")} data-required-cloudacnt />

                                                                            <input
                                                                                type="text"
                                                                                value={NewNumFormart(x.quota)}
                                                                                onChange={(e) => accountDataChange(e, i, "quota")}
                                                                                onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                                                                onBlur={(e) => e.target.value = NewNumFormart(x.quota)}
                                                                                data-required-cloudacnt />
                                                                            :
                                                                            <>
                                                                                {(() => {
                                                                                    var str = "";
                                                                                    var tempAccountDataOld = accountDataOld.find(y => y.id == x.id)

                                                                                    if (reqFrmType == 1 && tempAccountDataOld && tempAccountDataOld.quota != x.quota) {
                                                                                        str = "fc-orange";
                                                                                    }

                                                                                    return (
                                                                                        <div className={`elips-1 ${str}`}>
                                                                                            {NewNumFormart(x.quota)}
                                                                                        </div>
                                                                                    )
                                                                                })()}
                                                                            </>
                                                                    }
                                                                </div>
                                                                <div className="flex-1 right"> {/*服務費*/}
                                                                    {
                                                                        reqFrmCFState == 2 ?
                                                                            <input
                                                                                type="text"
                                                                                value={x.serviceCharge + "%"}
                                                                                onClick={(e) => e.target.select()}
                                                                                onFocus={(e) => e.target.value = (e.target.value + "").replace(/[^0-9.]/g, '')}
                                                                                onChange={(e) => accountDataChange(e, i, "serviceCharge")}
                                                                                onBlur={(e) => e.target.value = x.serviceCharge + "%"}
                                                                                placeholder="%"
                                                                                data-required />
                                                                            :
                                                                            <>
                                                                                {(() => {
                                                                                    var str = "";
                                                                                    var tempAccountDataOld = accountDataOld.find(y => y.id == x.id)

                                                                                    if (reqFrmType == 1 && tempAccountDataOld && tempAccountDataOld.serviceCharge != x.serviceCharge) {
                                                                                        str = "fc-orange";
                                                                                    }

                                                                                    return (
                                                                                        <div className={`elips-1 ${str}`}>
                                                                                            {x.serviceCharge ?
                                                                                                <>{x.serviceCharge + "%"}</>
                                                                                                :
                                                                                                <>0%</>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })()}
                                                                            </>
                                                                    }
                                                                </div>
                                                                <div className="flex-2 center"> {/*測試*/}
                                                                    <input type="radio" id={`state_${i}`} checked={x.state == 1 ? false : true} onClick={e => accountDataChange(e, i, "state")} disabled={reqFrmCFState == 2 ? null : 'disabled'} />
                                                                    {(() => {
                                                                        var str = "";
                                                                        var tempAccountDataOld = accountDataOld.find(y => y.id == x.id)

                                                                        if (reqFrmType == 1 && reqFrmCFState != 2 && tempAccountDataOld && tempAccountDataOld.state != x.state) {
                                                                            str = "dark-blue";
                                                                        }

                                                                        return (
                                                                            <label htmlFor={`state_${i}`} className={str}></label>
                                                                        )
                                                                    })()}
                                                                </div>
                                                                <div className="flex-2"> {/*測試到期日*/}
                                                                    {
                                                                        x.stateDate != "" && x.stateDate != null ?
                                                                            reqFrmCFState == 2 ?
                                                                                <input
                                                                                    type="date"
                                                                                    value={moment(x.stateDate).format("YYYY-MM-DD")}
                                                                                    onChange={e => accountDataChange(e, i, "stateDate")}
                                                                                    max={x.state == 0 ? moment(new Date()).format("YYYY-MM-DD") : null}
                                                                                    data-required /> :
                                                                                <>
                                                                                    {(() => {
                                                                                        var str = "";
                                                                                        var tempAccountDataOld = accountDataOld.find(y => y.id == x.id)

                                                                                        if (reqFrmType == 1 && tempAccountDataOld && tempAccountDataOld.stateDate != x.stateDate) {
                                                                                            str = "fc-orange";
                                                                                        }

                                                                                        return (
                                                                                            <div className={`${str}`}>
                                                                                                {moment(x.stateDate).format('YYYY/MM/DD')}
                                                                                            </div>
                                                                                        )
                                                                                    })()}
                                                                                </>
                                                                            : <></>
                                                                    }
                                                                </div>
                                                                <div className="flex-2 center"> {/*技術支援*/}
                                                                    <input type="radio" id={`help_${i}`} checked={x.help == 0 ? false : true} onClick={e => accountDataChange(e, i, "help")} disabled={reqFrmCFState == 2 ? null : 'disabled'} />
                                                                    {(() => {
                                                                        var str = "";
                                                                        var tempAccountDataOld = accountDataOld.find(y => y.id == x.id)

                                                                        if (reqFrmType == 1 && reqFrmCFState != 2 && tempAccountDataOld && tempAccountDataOld.help != x.help) {
                                                                            str = "dark-blue";
                                                                        }

                                                                        return (
                                                                            <label htmlFor={`help_${i}`} className={str}></label>
                                                                        )
                                                                    })()}
                                                                </div>
                                                                <div className="flex-2 fn jcfe">
                                                                    <a className="btn btn-small btn-text" onClick={() => OpenModalRemark(i, 0)}>
                                                                        <span>備註</span>
                                                                    </a>
                                                                    {
                                                                        isPM == 1 && reqFrmCFState != 2 ?
                                                                            <a className="btn btn-small btn-text" onClick={() => OpenModalRemark(i, 1)}>
                                                                                <span>PM 備註</span>
                                                                            </a>
                                                                            : null
                                                                    }
                                                                </div>
                                                            </li>
                                                            <li className="lt-row no-border">
                                                                <div className="flex-1 max-60"></div>
                                                                <div className="flex-5 fc-black fw-bold">
                                                                    <div className="elips-1">特殊產品計價</div>
                                                                </div>
                                                                <div className="flex-2 center fc-black fw-bold">
                                                                    {
                                                                        reqFrmCFState != 2 ?
                                                                            <div className="elips-1">使用代金券</div>
                                                                            : null
                                                                    }
                                                                </div>
                                                                <div className="flex-3"></div>
                                                                <div className="flex-5 fn jcfe"></div>
                                                            </li>
                                                            {
                                                                x.specialPricing != null && x.specialPricing.length > 0 ?
                                                                    x.specialPricing.map((x2, i2) =>
                                                                        <li className="lt-row no-border">
                                                                            <div className="flex-1 max-60"></div>
                                                                            <div className="flex-5">
                                                                                {
                                                                                    reqFrmCFState == 2 ?
                                                                                        <input type="text" value={x2.title} onChange={(e) => accountDataChange2(e, i, i2, "title")} />
                                                                                        :
                                                                                        <>
                                                                                            {(() => {
                                                                                                var str = "";
                                                                                                var tempAccountDataOld = accountDataOld.find(y => y.id == x.id)
                                                                                                var tempSpecialPricing = tempAccountDataOld && tempAccountDataOld.specialPricing.find(y => y.id == x2.id)

                                                                                                if (reqFrmType == 1 && tempSpecialPricing && tempSpecialPricing.title != x2.title) {
                                                                                                    str = "fc-orange";
                                                                                                }

                                                                                                return (
                                                                                                    <div className={`elips ${str}`}>
                                                                                                        {x2.title}
                                                                                                    </div>
                                                                                                )
                                                                                            })()}
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                            <div className="flex-2 center">
                                                                                {
                                                                                    reqFrmCFState != 2 ?
                                                                                        <div>
                                                                                            <input type="radio" id={`isCoupon_${i}_${i2}`} checked={x2.isCoupon == 1 ? true : false} disabled="disabled" />
                                                                                            <label htmlFor={`isCoupon_${i}_${i2}`} ></label>
                                                                                        </div>
                                                                                        :
                                                                                        null

                                                                                }
                                                                            </div>

                                                                            <div className="flex-4">
                                                                                {
                                                                                    reqFrmCFState != 2 ?
                                                                                        <a className="btn btn-text btn-small" onClick={() => OpenModalFormula(i, i2, x.productId)}>
                                                                                            <span>編輯計費項目</span>
                                                                                        </a>
                                                                                        :
                                                                                        null

                                                                                }
                                                                            </div>

                                                                            <div className="flex-3 jcfe fn balance-3"></div>
                                                                        </li>

                                                                    ) : null
                                                            }
                                                        </ul>
                                                    </li>
                                                </>
                                            )
                                        }
                                    </ul>

                                </div>
                                <div className="help-word">
                                    請輸入正確格式
                                </div>
                            </div>
                            <div className="form-item btn-area">
                                {
                                    reqFrmCFState == 2 ?
                                        <>
                                            <a className="btn btn-border" onClick={Revoke} >
                                                <span>撤銷</span>
                                            </a>
                                            <a className="btn" onClick={() => OpenModal("modal-cloudacntagain", 2)}>
                                                <span>再次送審</span>
                                            </a>
                                        </>
                                        :
                                        reqFrmCFState == 3 ? null :
                                            <>
                                                <a className="btn btn-border" onClick={openModalReason}>
                                                    <span>退回</span>
                                                </a>
                                                <a className="btn" onClick={(e) => OpenModal("modal-cloudacntcheckagree", 2)}>
                                                    <span>確定</span>
                                                </a>

                                            </>
                                }
                            </div>
                            <div className="form-item must col feedback">
                                <div className="field">退回原因</div>
                                <div className="value">
                                    <textarea
                                        name="reason"
                                        id="reason11"
                                        cols="30"
                                        rows="6"
                                        placeholder="請輸入退回原因"
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                        disabled={reqFrmCFState == 2 ? true : false}
                                    ></textarea>
                                </div>
                                <span className="help-word">請輸入退回原因</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-cloudacntcheckreason" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確認要退回此筆資料？</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-cloudacntcheckreason")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={Reason}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-cloudacntcheckagree" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確認要通過此筆資料？</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-cloudacntcheckagree")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={Agree}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-cloudacntagain" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定再次送審?
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-cloudacntagain")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={AgainSend}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-cloudacntrevoke" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定撤銷?
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-cloudacntrevoke")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={RevokeSend}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {/*備註 / PM備註 Modal*/}
            <div id="modal-remark" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">
                            {reqFrmCFState != 2 ? '' : '新增'} {" "} {accountData && accountData[tmpIndex1].acntId ? accountData[tmpIndex1].acntId : ""} {" "} {remarkType == 0 ? '' : 'PM'}備註</h3>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-container">
                            <div className="form-item no-field">
                                <div className="field">隸屬公司</div>
                                <div className="value">
                                    <div className="msg-record">
                                        {
                                            subAccountComments.map((remark, index) => {
                                                let isShowDate = true;
                                                let str = "";

                                                //判斷是不是新增的備註
                                                let selectComment = subAccountCommentsOld.find(x => x.createdTime == remark.createdTime)
                                                if (reqFrmCFState != 2 && reqFrmType == 1 && (selectComment == null || selectComment == undefined)) {
                                                    str = "orange";
                                                }

                                                //判斷要不要顯示日期
                                                if (index > 0 && moment(remark.createdTime).format('YYYY/MM/DD') == moment(subAccountComments[index - 1].createdTime).format('YYYY/MM/DD')) {
                                                    isShowDate = false;
                                                }

                                                return (
                                                    <ul key={index}>
                                                        {isShowDate ?
                                                            <li className="date">{moment(remark.createdTime).format('YYYY/MM/DD')}</li> : null
                                                        }
                                                        <li className={`${str}`}>[{moment(remark.createdTime).format('HH:mm:ss')}{" "}{remark.userName}] {remark.content}</li>
                                                    </ul>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="help-word">
                                    請選擇負責公司
                                </div>
                            </div>

                            <div className="form-item remark-block">
                                <div>
                                    <textarea name="" id="" cols="30" rows="5" value={tmpRemark} onChange={(e) => setTmpRemark(e.target.value)}>
                                    </textarea>
                                    <div className="btn-area">
                                        <a className={`btn btn-middle btn-border ${tmpRemark == null || tmpRemark == "" ? "disabled" : ""}`} onClick={editRemark} disabled={tmpRemark == null || tmpRemark == "" ? "disabled" : ""}>
                                            <span>確定送出</span>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*編輯計費項目公式 Modal*/}
            <div id="modal-formula" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">雲端帳號計費公式</h3>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <ul className="list-table transparent formula-group">
                            <li className="lt-row lt-th">
                                <div className="flex-1 fn max-60 no-form"></div>
                                <div className="flex-4">
                                    計費項目
                                </div>
                                <div className="flex-1">值為數字</div>
                                <div className="flex-8">
                                    <div className="flex-3"></div>
                                    <div className="flex-2"></div>
                                    <div className="flex-3"></div>
                                </div>
                                <div className="flex-1"></div>
                                <div className="flex-1"></div>

                            </li>
                            {
                                selectSpecialPricing &&
                                selectSpecialPricing.formula.map((x, i) =>
                                    <li className="lt-row for-formula">
                                        <div className="flex-1 fn max-60 no-form">
                                            {
                                                i > 0 ?
                                                    <a className="btn btn-icon" onClick={() => DelFormulaData(i)}>
                                                        <i className="icon icon-37"></i>
                                                    </a>
                                                    : null
                                            }
                                        </div>
                                        <div className="flex-4">
                                            <div className="form-item in-table no-field">
                                                <div className="value">
                                                    <select onChange={(e) => formulaDataChange(e, i, "rawData")}>
                                                        <option value="" disabled>Raw Data</option>
                                                        {
                                                            rawDataArray.map(x2 =>
                                                                <option value={x2.id} selected={x.rawData == x2.id ? true : false}>{x2.name}</option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                                <div className="help-word right">
                                                    請選擇計費項目
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 center">
                                            <div className="form-item in-table no-field center">
                                                <div className="value">
                                                    <input type="radio" id={`isNumber_${i}`} onClick={(e) => formulaDataChange(e, i, "isNumber")} checked={x.isNumber && x.isNumber == 1 ? true : false} />
                                                    <label htmlFor={`isNumber_${i}`}></label>
                                                </div>
                                                <div className="help-word right">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-8 has-nested">
                                            {
                                                x.isNumber == 1 ?
                                                    <>
                                                        <div className="flex-3">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <input
                                                                        type="number"
                                                                        placeholder="請輸入前範圍"
                                                                        value={x.lowerLimit}
                                                                        onChange={(e) => formulaDataChange(e, i, "lowerLimit")}
                                                                        disabled={
                                                                            x.formulaSigns == "Y=" || x.formulaSigns == "Y≦" || x.formulaSigns == "Y<" ? true : false
                                                                        } />
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-2">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <select onChange={(e) => formulaDataChange(e, i, "formulaSigns")}>
                                                                        <option value="<Y<" selected={formulaData[i].formulaSigns == "<Y<" ? true : false}>{"<"} Y {"<"}</option>
                                                                        <option value="≦Y≦" selected={formulaData[i].formulaSigns == "≦Y≦" ? true : false}>{"≦"} Y {"≦"}</option>
                                                                        <option value="<Y≦" selected={formulaData[i].formulaSigns == "<Y≦" ? true : false}>{"<"} Y {"≦"}</option>
                                                                        <option value="≦Y<" selected={formulaData[i].formulaSigns == "≦Y<" ? true : false}>{"≦"} Y {"<"}</option>
                                                                        <option value="Y=" selected={formulaData[i].formulaSigns == "Y=" ? true : false}> Y {"="}</option>
                                                                        <option value="Y≦" selected={formulaData[i].formulaSigns == "Y≦" ? true : false}> Y {"≦"}</option>
                                                                        <option value="≦Y" selected={formulaData[i].formulaSigns == "≦Y" ? true : false}>{"≦"} Y </option>
                                                                        <option value="Y<" selected={formulaData[i].formulaSigns == "Y<" ? true : false}> Y {"<"}</option>
                                                                        <option value="<Y" selected={formulaData[i].formulaSigns == "<Y" ? true : false}>{"<"} Y </option>
                                                                    </select>
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-3">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <input
                                                                        type="number"
                                                                        placeholder="請輸入後範圍"
                                                                        value={x.upperLimit}
                                                                        onChange={(e) => formulaDataChange(e, i, "upperLimit")}
                                                                        disabled={
                                                                            x.formulaSigns == "≦Y" || x.formulaSigns == "<Y" ?
                                                                                true : false
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入算式條件
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> :
                                                    <>
                                                        <div className="flex-2">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <select onChange={(e) => formulaDataChange(e, i, "formulaSigns")} >
                                                                        <option value="=" selected={formulaData[i].formulaSigns == "=" ? true : false}>=</option>
                                                                        <option value="≠" selected={formulaData[i].formulaSigns == "≠" ? true : false}>≠</option>
                                                                    </select>
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-6">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <input
                                                                        type="text"
                                                                        placeholder="請輸入參考值"
                                                                        value={x.value ? x.value : ""}
                                                                        onChange={(e) => formulaDataChange(e, i, "value")}
                                                                    />
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入算式條件
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                        <div className="flex-1 no-form center">
                                            {
                                                selectSpecialPricing.formula.length > 1 && i != selectSpecialPricing.formula.length - 1 ? 'and' :
                                                    <a className="btn btn-icon" onClick={AddFormulaData}>
                                                        <i className="icon icon-25"></i>
                                                    </a>
                                            }
                                        </div>
                                        <div className="flex-1"></div>
                                    </li>
                                )
                            }
                            <li className="lt-row border">
                            </li>
                            <li className="lt-row lt-th">
                                <div className="flex-1 fn max-60 no-form"></div>
                                <div className="flex-4">
                                    折扣計費欄位
                                </div>
                                <div className="flex-3">
                                    折扣/ 單價
                                </div>
                                <div className="flex-2"></div>
                                <div className="flex-5"></div>
                            </li>
                            <li className="lt-row for-formula">
                                <div className="flex-1 fn max-60 no-form"></div>
                                <div className="flex-4">
                                    <div className="form-item in-table no-field">
                                        <div className="value">
                                            <select onChange={e => specialPricingDataChange(e, "rawData")}>
                                                <option value="" disabled>Raw Data</option>
                                                {
                                                    rawDataArray.map(x2 =>
                                                        <option value={x2.id} selected={selectSpecialPricing && selectSpecialPricing.rawData == x2.id ? true : false}>{x2.name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className="help-word">
                                            請選擇計費項目
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-3 no-form">
                                    <div className="multi-form">
                                        <DebounceInput
                                            debounceTimeout={100}
                                            type="number"
                                            value={selectSpecialPricing ? selectSpecialPricing.discountPrice : 0}
                                            onChange={(e) => specialPricingDataChange(e, "discountPrice")} />
                                        <div className="line"></div>
                                        <select onChange={e => specialPricingDataChange(e, "discountUnit")}>
                                            <option value="0" selected={selectSpecialPricing && selectSpecialPricing.discountUnit == "0" ? true : false}>%</option>
                                            <option value="1" selected={selectSpecialPricing && selectSpecialPricing.discountUnit == "1" ? true : false}>元</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="flex-2"></div>
                                <div className="flex-5 no-form jcfe">
                                    <a className="btn btn-middle btn-border" onClick={ClearFormulaData}>
                                        <span>清空欄位</span>
                                    </a>
                                    <a className="btn btn-middle" style={{ "color": "#FFFFFF" }} onClick={CheckFormulaData}>
                                        <span>確定算式</span>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div id="modal-formula-old" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">雲端帳號計費公式</h3>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">

                        <ul className="list-table transparent formula-group">
                            <li className="lt-row lt-th">
                                <div className="flex-1 fn max-60 no-form"></div>
                                <div className="flex-4">
                                    計費項目
                                </div>
                                <div className="flex-1">值為數字</div>
                                <div className="flex-8">
                                    <div className="flex-3"></div>
                                    <div className="flex-2"></div>
                                    <div className="flex-3"></div>
                                </div>
                                <div className="flex-1"></div>
                                <div className="flex-1"></div>

                            </li>
                            {
                                formulaData.map((x, i) =>
                                    <li className="lt-row for-formula">
                                        <div className="flex-1 fn max-60 no-form">
                                            {
                                                i > 0 ?
                                                    <a className="btn btn-icon" onClick={() => DelFormulaData(i)}>
                                                        <i className="icon icon-37"></i>
                                                    </a>
                                                    : null
                                            }
                                        </div>
                                        <div className="flex-4">
                                            <div className="form-item in-table no-field">
                                                <div className="value">
                                                    <select onChange={(e) => formulaDataChange(e, i, "rawData")}>
                                                        <option value="" disabled>Raw Data</option>
                                                        {
                                                            rawDataArray.map(x2 =>
                                                                <option value={x2.id} selected={x.rawData == x2.id ? true : false}>{x2.name}</option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                                <div className="help-word right">
                                                    請選擇計費項目
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 center">
                                            <div className="form-item in-table no-field center">
                                                <div className="value">
                                                    <input type="radio" id={`isNumber_${i}`} onClick={(e) => formulaDataChange(e, i, "isNumber")} checked={x.isNumber == 0 ? false : true} />
                                                    <label htmlFor={`isNumber_${i}`}></label>
                                                </div>
                                                <div className="help-word right">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-8 has-nested">
                                            {
                                                x.isNumber == 1 ?
                                                    <>
                                                        <div className="flex-3">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <input
                                                                        type="text"
                                                                        placeholder="請輸入前範圍"
                                                                        value={x.lowerLimit}
                                                                        onChange={(e) => formulaDataChange(e, i, "lowerLimit")}
                                                                        disabled={
                                                                            x.formulaSigns == "Y=" || x.formulaSigns == "Y≦" || x.formulaSigns == "Y<" ? true : false
                                                                        } />
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-2">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <select onChange={(e) => formulaDataChange(e, i, "formulaSigns")}>
                                                                        <option value="<Y<" selected={formulaData[i].formulaSigns == "<Y<" ? true : false}>{"<"} Y {"<"}</option>
                                                                        <option value="≦Y≦" selected={formulaData[i].formulaSigns == "≦Y≦" ? true : false}>{"≦"} Y {"≦"}</option>
                                                                        <option value="<Y≦" selected={formulaData[i].formulaSigns == "<Y≦" ? true : false}>{"<"} Y {"≦"}</option>
                                                                        <option value="≦Y<" selected={formulaData[i].formulaSigns == "≦Y<" ? true : false}>{"≦"} Y {"<"}</option>
                                                                        <option value="Y=" selected={formulaData[i].formulaSigns == "Y=" ? true : false}> Y {"="}</option>
                                                                        <option value="Y≦" selected={formulaData[i].formulaSigns == "Y≦" ? true : false}> Y {"≦"}</option>
                                                                        <option value="≦Y" selected={formulaData[i].formulaSigns == "≦Y" ? true : false}>{"≦"} Y </option>
                                                                        <option value="Y<" selected={formulaData[i].formulaSigns == "Y<" ? true : false}> Y {"<"}</option>
                                                                        <option value="<Y" selected={formulaData[i].formulaSigns == "<Y" ? true : false}>{"<"} Y </option>
                                                                    </select>
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-3">

                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <input
                                                                        type="text"
                                                                        placeholder="請輸入後範圍"
                                                                        value={x.upperLimit}
                                                                        onChange={(e) => formulaDataChange(e, i, "upperLimit")}
                                                                        disabled={
                                                                            x.formulaSigns == "≦Y" || x.formulaSigns == "<Y" ?
                                                                                true : false
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入算式條件
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> :
                                                    <>
                                                        <div className="flex-2">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <select onChange={(e) => formulaDataChange(e, i, "formulaSigns")} >
                                                                        <option value="=" selected={formulaData[i].formulaSigns == "=" ? true : false}>=</option>
                                                                        <option value="≠" selected={formulaData[i].formulaSigns == "≠" ? true : false}>≠</option>
                                                                    </select>
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-6">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <input
                                                                        type="text"
                                                                        placeholder="請輸入參考值"
                                                                        value={x.value}
                                                                        onChange={(e) => formulaDataChange(e, i, "value")}
                                                                    />
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入算式條件
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                            }


                                        </div>

                                        <div className="flex-1 no-form center">
                                            {
                                                formulaData.length > 1 && i != formulaData.length - 1 ? 'and' :
                                                    <a className="btn btn-icon" onClick={AddFormulaData}>
                                                        <i className="icon icon-25"></i>
                                                    </a>
                                            }
                                        </div>
                                        <div className="flex-1"></div>
                                    </li>
                                )
                            }
                            <li className="lt-row border">
                            </li>
                            <li className="lt-row lt-th">
                                <div className="flex-1 fn max-60 no-form">

                                </div>
                                <div className="flex-4">
                                    折扣計費欄位
                                </div>
                                <div className="flex-3">
                                    折扣/ 單價
                                </div>
                                <div className="flex-2"></div>
                                <div className="flex-5"></div>


                            </li>
                            <li className="lt-row for-formula">
                                <div className="flex-1 fn max-60 no-form">

                                </div>
                                <div className="flex-4">
                                    <div className="form-item in-table no-field">
                                        <div className="value">
                                            <select onChange={e => specialPricingDataChange(e, "rawData")}>
                                                <option value="" disabled>Raw Data</option>
                                                {
                                                    rawDataArray.map(x2 =>
                                                        <option value={x2.id} selected={specialPricingData.rawData == x2.id ? true : false}>{x2.name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className="help-word">
                                            請選擇計費項目
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-3 no-form">
                                    <div className="multi-form">
                                        <input type="text" value={specialPricingData.discountPrice} onChange={e => specialPricingDataChange(e, "discountPrice")} />
                                        <div className="line"></div>
                                        <select onChange={e => specialPricingDataChange(e, "discountUnit")}>
                                            <option value="0" selected={specialPricingData.discountUnit == "0" ? true : false}>%</option>
                                            <option value="1" selected={specialPricingData.discountUnit == "1" ? true : false}>元</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="flex-2"></div>
                                <div className="flex-5 no-form jcfe">
                                    <a className="btn btn-middle btn-border" onClick={ClearFormulaData}>
                                        <span>清空欄位</span>
                                    </a>
                                    <a className="btn btn-middle" style={{ "color": "#FFFFFF" }} onClick={CheckFormulaData}>
                                        <span>確定算式</span>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div id="modal-transfer" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">{tID} 轉移對象選擇</h3>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-container">
                            <div className={`form-item must ${tCompanyIdError ? 'show-help' : ''}`}>
                                <div className="field">隸屬公司</div>
                                <div className="value">
                                    {companyArray.map((x) => (
                                        <div key={x.id}>
                                            <input type="radio" id={`tCompany-${x.cmpID}`} value={x.cmpID} checked={tCompanyId == x.cmpID ? "checked" : ""} onClick={(e) => setTCompanyId(x.cmpID)} />
                                            <label htmlFor={`tCompany-${x.cmpID}`}>{x.cmpName}</label>
                                        </div>
                                    ))}
                                </div>
                                <div className="help-word">
                                    請選擇隸屬公司
                                </div>
                            </div>
                            <div className="form-item must half has-datalist" data-datalist>
                                <div className="field">客戶別</div>
                                <div className="value">
                                    <input type="text" id="customerNameTextT" className={tCustomerNameError ? 'error' : ''} value={tCustomerName} onChange={custNameChange} />
                                    <ul className="datalist scrollbar">
                                        {tCustomerNameArray.map((x) => (
                                            <li
                                                data-value={x.custName} onClick={(e) => { setTCustomerID(x.custID), setTCustomerName(x.custName), setTCustomerTitle(x.custTitle), setTCustomerTaxId(x.custTaxID), changeTCustomer(x.custID) }}>
                                                <span className="flex-4 bold">{x.custName}</span>
                                                <span className="flex-3">{x.custTitle}</span>
                                                <span className="flex-3">{x.custTaxID}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="help-word">
                                    請輸入正確格式
                                </div>
                            </div>
                            <div className="form-item half jcfe">
                                {/*<div className="btn btn-middle btn-border" onClick={(e) => history.push('/Main?path=customernew')}>*/}
                                {/*    <span>新增客戶</span>*/}
                                {/*</div>*/}
                            </div>
                            <div className="form-item half has-datalist" data-datalist>
                                <div className="field">公司抬頭</div>
                                <div className="value">
                                    <input type="text" id="customerTitleTextT" value={tCustomerTitle} onChange={custTitleChange} />
                                    <ul className="datalist scrollbar">
                                        {
                                            tCustomerTitleArray.map((x) => (
                                                <li data-value={x.custTitle} onClick={(e) => { setTCustomerID(x.custID), setTCustomerName(x.custName), setTCustomerTitle(x.custTitle), setTCustomerTaxId(x.custTaxID), changeTCustomer(x.custID) }}>
                                                    <span className="flex-4 bold">{x.custName}</span>
                                                    <span className="flex-3">{x.custTaxID}</span>
                                                    <span className="flex-3">{x.custTitle}</span>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                <span className="help-word">
                                    請輸入正確格式
                                </span>
                            </div>
                            <div className="form-item half  has-datalist" data-datalist>
                                <div className="field">公司統編/ 編號</div>
                                <div className="value">
                                    <input type="text" id="customerTaxIdTextT" value={tCustomerTaxId} onChange={custTaxIDChange} />
                                    <ul className="datalist scrollbar">
                                        {
                                            tCustomerTaxIdArray.map(x =>
                                                <li data-value={x.custTaxID} onClick={(e) => { setTCustomerID(x.custID), setTCustomerName(x.custName), setTCustomerTitle(x.custTitle), setTCustomerTaxId(x.custTaxID), changeTCustomer(x.custID) }}>
                                                    <span className="flex-4 bold">{x.custName}</span>
                                                    <span className="flex-3">{x.custTitle}</span>
                                                    <span className="flex-3">{x.custTaxID}</span>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                                <div className="help-word">
                                    請輸入正確格式
                                </div>
                            </div>
                            <div className="form-item half">
                                <div className="field">帳號代號</div>
                                <div className="value">
                                    <input type="text" value={tAccountCode} onChange={(e) => setTAccountCode(e.target.value)} />
                                </div>
                                <div className="help-word">
                                    請輸入正確格式
                                </div>
                            </div>
                            <div className="form-item half">
                                <div className="field">負責業務</div>
                                <div className="value multi-inline">
                                    <input type="text" value={tPersonInChargeName} disabled="disabled" />
                                </div>
                                <div className="help-word">
                                    請輸入正確格式
                                </div>
                            </div>
                            <hr />
                            <div className="form-item btn-area">
                                {
                                    reqFrmCFState == 2 ?
                                        <>
                                            <a className="btn btn-border" onClick={() => CloseModal("modal-transfer", 3)}>
                                                <span>取消</span>
                                            </a>
                                            <a className="btn" onClick={OpenModalCheckTransfer}>
                                                <span>確定</span>
                                            </a>
                                        </>
                                        :
                                        <a className="btn btn-border" onClick={() => CloseModal("modal-transfer", 3)}>
                                            <span>關閉</span>
                                        </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-checktransfer" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-41"></i>
                        </div>
                        <div class="text-holder">
                            確定要轉移此帳號？
                        </div>
                        <div class="btn-holder">
                            <a class="btn btn-border" onClick={() => CloseModal("modal-checktransfer", 4)}> <span>取消</span></a>
                            <a class="btn" onClick={Transfer}> <span>確認</span></a>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
};

export default CloudAcntDetail;
