import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";


const CloudAcntRevokeList = ({ revokeList, setReqFrmID }) => {
    var history = useHistory();

    function openModalReview(frmID) {
        setReqFrmID(null);
        const timeout = setTimeout(() => {
            setReqFrmID(frmID);
        }, 300)
        return () => clearTimeout(timeout)
    }

    return (
        <div id="modal-list-11-2" className="modal large" data-modal>
            <div className="modal-container">
                <div className="modal-header">
                    <h3 className="fz-B has-border">雲端帳號已撤銷</h3>
                    <span className="close" data-close>
                        <i className="icon icon-44"></i>
                    </span>
                </div>
                <div className="modal-body">
                    <ul className="list-table">
                        <li className="lt-row lt-th">
                            <div className="flex-2">類別</div>
                            <div className="flex-2">審核者</div>
                            <div className="flex-2">隸屬公司</div>
                            <div className="flex-2">客戶別</div>
                            <div className="flex-2">公司抬頭</div>
                            <div className="flex-2">雲端細項總稱</div>
                            <div className="flex-2">負責業務</div>
                            {/*<div className="flex-2"></div>*/}
                        </li>
                        {revokeList.map((x) => (
                            <li className="lt-row">
                                <div className="flex-2">
                                    {(() => {
                                        if (x.reqFrmType == 0) {
                                            return "新增";
                                        } else if (x.reqFrmType == 1) {
                                            return "修改";
                                        } else if (x.reqFrmType == 2) {
                                            return "刪除";
                                        }
                                    })()}
                                    撤銷
                                </div>
                                <div className="flex-2">{x.applicantName}</div>
                                <div className="flex-2">{x.companyName}</div>
                                <div className="flex-2">{x.customerName}</div>
                                <div className="flex-2">{x.customerTitle}</div>
                                <div className="flex-2">{x.productName1}</div>
                                <div className="flex-2">{x.personInChargeName}</div>
                                {/*<div className="flex-2">*/}
                                {/*    <div className="fn-bar">*/}
                                {/*        <div className="btn btn-small btn-border" onClick={(e) => openModalReview(x.reqFrmID)}>*/}
                                {/*            <span>查看</span>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CloudAcntRevokeList;
