import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DebounceInput } from 'react-debounce-input';
import {
    API_GET_AllCompany,
    API_GET_AllCurrency,
    API_GET_CloudAcntList3,
    API_GET_CloudAcntCloudProduct,
    API_GET_CloudAcntGetRawDataList,
    API_GET_CloudAcntRemark,
    API_GET_CloudAcntTransferData,
    API_GET_Customer3Name,
    API_POST_CloudAcntRemark,
    API_PUT_CloudAcntData,
    API_GET_AuditHistory,
    API_GET_CloudAcntCustPersonInCharge,
    API_GET_AdNameList
} from "../../global/constants";
import { OpenModal, NewNumFormart, CloseModal, InitJS, Clone, SetOnbeforeunload, MenuSet, NumFormart, SetHasChange } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
import moment from 'moment';

const CloudAcntList3 = () => {
    var history = useHistory();
    const [username, setUserName] = useState('');
    var onbeforeunloadFlag = false;
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        //把menu顏色置換
        MenuSet("CloudAcntQry");
        setUserName(localStorage.getItem("userName"));
        getData();
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    function getData() {
        setCompanyId(parseInt(queryParams.get("companyId")));
        var dt = {
            companyId: parseInt(queryParams.get("companyId")),
            productName1: queryParams.get("productName1"),
            customerId: parseInt(queryParams.get("customerId")),
            personInChargeId: parseInt(queryParams.get("personInChargeId")),
            fromNotify: !isNaN(parseInt(queryParams.get("fromNotify"))) ? parseInt(queryParams.get("fromNotify")) : null,
        }
        console.log("雲端帳號資料Request", dt);
        API_GET_CloudAcntList3(dt)
            .then((response) => response.data)
            .then((data) => {
                console.log("雲端帳號資料", data.result);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                if (data.code == "3071") {
                    setModalStr(data.message);
                    OpenModal("modal-reload");
                    return;
                }

                if (data.code == "3072") {
                    setModalStr(data.message);
                    OpenModal("modal-goQry");
                    return;
                }

                var accDT = data.result.data.accountData;
                if (accDT != null) {
                    for (var i = 0; i < accDT.length; i++) {
                        accDT[i].isEdit = 0;
                        accDT[i].toCustomerName = "";
                        accDT[i].stateDate = (accDT[i].stateDate != null ? accDT[i].stateDate.split('T')[0].replace(/-/g, '/') : null);
                    }
                }
                //setReviewFormId(data.result.data.reviewFormId);

                setAccountData(accDT);
                setCompanyName(data.result.data.companyName);
                setCaid(data.result.data.caid);
                setCustomerName(data.result.data.customerName);
                setCustomerTaxId(data.result.data.customerTaxId);
                setCustomerTitle(data.result.data.customerTitle);
                setPersonInChargeId(data.result.data.personInChargeId);
                setPersonInChargeName(data.result.data.personInChargeName);
                if (data.result.data.reviewStatus != undefined && data.result.data.reviewStatus != null)
                    setReviewStatus(data.result.data.reviewStatus);
                setReviewerArray(data.result.data.reviewerArray);
                setWholeDiscount(data.result.data.wholeDiscount + "%");
                setDrawBackReason(data.result.data.drawBackReason);
                //是否為PM
                setIsPM(data.result.data.isPM);
                setMyID(data.result.data.myId);
                //2代表退回
                if (data.result.reviewStatus == 2) {
                    setIsDrawBack(true);
                    setDrawBacker(data.result.data.reviewerArray);
                }
                if (data.code == "4046") {
                    setIsReviewing(true);
                }
                if (data.code != "0000" && data.code != "4046") {
                    setModalStr(data.message);
                    OpenModal("modal-error", 2);
                }
            })
            .catch((err) => {
                console.log("雲端帳號資料 catch err", err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        API_GET_AllCurrency()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //負責公司
                    setCurrencyArray(data.result.data);
                }
            })

        API_GET_CloudAcntCloudProduct({
            productName1: queryParams.get("productName1"),
            companyId: parseInt(queryParams.get("companyId"))
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setCloudProductName2Array(data.result.data.productName2Array);
                }
            })
        API_GET_AllCompany()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setCompanyArray(data.result.data);
                }
            })
    }
    const [specialPricingData, setSpecialPricingData] = useState([{}]);
    function specialPricingDataChange(e, col) {

        var dt = Clone(specialPricingData);
        if (col == "discountPrice") {
            dt.discountPrice = parseFloat((e.target.value + "").replace(/[^0-9.-]/g, ''));
        }
        if (col == "rawData") {
            dt.rawData = e.target.value;
        }
        if (col == "discountUnit") {
            dt.discountUnit = e.target.value;
        }
        setSpecialPricingData(dt);
    }
    const [reviewData, setReviewData] = useState([{}]);
    //const [reviewFormId, setReviewFormId] = useState(-1);
    const [rawDataArray, setRawDataArray] = useState([{}]);
    const [formulaData, setFormulaData] = useState([{}]);
    //是否為PM
    const [isPM, setIsPM] = useState(1);
    //自己ID
    const [myID, setMyID] = useState();
    //雲端細項
    const [cloudProductName2Array, setCloudProductName2Array] = useState([]);
    //資料列
    const [accountData, setAccountData] = useState([]);
    //公司名稱
    const [companyId, setCompanyId] = useState();
    const [companyName, setCompanyName] = useState();
    const [caid, setCaid] = useState();
    //客戶別
    const [customerName, setCustomerName] = useState();
    const [customerTaxId, setCustomerTaxId] = useState();
    const [customerTitle, setCustomerTitle] = useState();
    //負責業務
    const [adNameState, setAdNameState] = useState(true);
    const [personInChargeId, setPersonInChargeId] = useState();
    const [personInChargeNameArray, setPersonInChargeNameArray] = useState([]);
    const [personInChargeName, setPersonInChargeName] = useState();

    //#region 修改負責業務
    function personInChargeNameChange(e) {
        setPersonInChargeName(e.target.value);
        getPersonInChargeName();
    }
    //#endregion

    //#region 取得負責業務列表
    function getPersonInChargeName() {
        if (document.getElementById("personInChargeNameText").value == "") {
            return false;
        }

        API_GET_AdNameList({
            input: document.getElementById("personInChargeNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setPersonInChargeNameArray(data.result.data);
                }
            }).catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //#endregion

    const [reviewStatus, setReviewStatus] = useState();
    const [reviewerArray, setReviewerArray] = useState([]);
    const [wholeDiscount, setWholeDiscount] = useState("0%");
    //退回原因
    const [drawBackReason, setDrawBackReason] = useState();
    //幣別
    const [currencyArray, setCurrencyArray] = useState([{}]);
    const [modalStr, setModalStr] = useState();
    //暫存index
    const [tmpIndex1, setTmpIndex1] = useState(0);
    const [tmpIndex2, setTmpIndex2] = useState(0);
    const [tmpIndex3, setTmpIndex3] = useState(0);
    const [subAccountComments, setSubAccountComments] = useState([]); //選中的備註
    const [remarkType, setRemarkType] = useState(0); //0:備註 1:PM備註
    const [tmpRemark, setTmpRemark] = useState();
    //轉移資訊
    const [hasTransfer, setHasTransfer] = useState(false);
    const [transferData, setTransferData] = useState([{}]);
    const [companyArray, setCompanyArray] = useState([{}]);
    const [tID, setTID] = useState();
    const [tCompanyId, setTCompanyId] = useState();
    const [tCompanyIdError, setTCompanyIdError] = useState(false);
    const [tCustomerId, setTCustomerID] = useState();
    const [tCustomerName, setTCustomerName] = useState();
    const [tCustomerNameError, setTCompanyNameError] = useState();
    const [tCustomerNameArray, setTCustomerNameArray] = useState([{}]);
    const [tCustomerTitle, setTCustomerTitle] = useState();
    const [tCustomerTitleArray, setTCustomerTitleArray] = useState([{}]);
    const [tCustomerTaxId, setTCustomerTaxId] = useState();
    const [tCustomerTaxIdArray, setTCustomerTaxIdArray] = useState([{}]);
    function custNameChange(e) {

        setTCustomerName(e.target.value);
        getCustomer3Name(1);
    }
    function custTitleChange(e) {

        setTCustomerTitle(e.target.value);
        getCustomer3Name(2);
    }
    function custTaxIDChange(e) {

        setTCustomerTaxId(e.target.value);
        getCustomer3Name(3);
    }
    const [tAccountId, setTAccountId] = useState();
    const [tAccountCode, setTAccountCode] = useState();
    const [tPersonInChargeName, setTPersonInChargeName] = useState();


    //審核名單
    const [drawBacker, setDrawBacker] = useState([]);
    //審核中
    const [isReviewing, setIsReviewing] = useState(false);
    //退回中
    const [isDrawBack, setIsDrawBack] = useState(false);
    //打開編輯計費項目
    function OpenModalFormula(index, index2, productId) {
        //console.log(productId);
        setTmpIndex1(index);
        setTmpIndex2(index2);
        API_GET_CloudAcntGetRawDataList({ productId: productId })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setRawDataArray(data.result.data);
                }
            })
        var dt = Clone(accountData[index].specialPricing[index2].formula);
        if (dt[0].length == 0) {
            dt = [{
                rawData: null,
                isNumber: 0,
                formulaSigns: "<Y<",
                lowerLimit: null,
                upperLimit: null,
                value: "",
            }];
        }
        //console.log(dt);
        for (var i = 0; i < dt.length; i++) {
            dt[i].relatedId = accountData[index].specialPricing[index2].id;
        }
        setFormulaData(dt);
        var dt2 = Clone(accountData[index].specialPricing[index2]);
        setSpecialPricingData(dt2);
        OpenModal("modal-formula");
    }
    //新增雲端帳號計費公式
    function AddFormulaData() {

        setFormulaData(function (prev) {
            return [...prev,
            {
                rawData: null,
                isNumber: 0,
                formulaSigns: "<Y<",
                lowerLimit: null,
                upperLimit: null,
                value: null,
            }];


        });
    }
    //刪除雲端帳號計費公式
    function DelFormulaData(index) {

        setFormulaData(function (prev) {
            return prev.filter((x, i) => i != index);
        });
    }

    //新增特殊產品計費項目
    function AddSpecialPricing(index, type = 1) {

        setAccountData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {

                    if (type == 2) {
                        var dt = [{
                            id: null,
                            title: null,
                            isCoupon: 0,
                            rawData: null,
                            discountPrice: 0,
                            discountUnit: 0,
                            formula: [{
                                rawData: null,
                                isNumber: null,
                                formulaSigns: null,
                                lowerLimit: null,
                                upperLimit: null,
                                value: null,
                            }]
                        }]

                    } else {
                        var dt = Clone(accountData[i].specialPricing);
                        dt.push({
                            id: null,
                            title: null,
                            isCoupon: 0,
                            rawData: null,
                            discountPrice: 0,
                            discountUnit: 0,
                            formula: [{
                                rawData: null,
                                isNumber: null,
                                formulaSigns: null,
                                lowerLimit: null,
                                upperLimit: null,
                                value: null,
                            }]
                        });
                    }
                    //console.log(dt);
                    item.specialPricing = dt;


                }
                return item;
            });
        });
    }
    //刪除特殊產品計價
    function DelSpecialPricing(index, index2) {

        setAccountData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    var dt = Clone(accountData[i].specialPricing);
                    var tmpDt = [];
                    for (var idx = 0; idx < dt.length; idx++) {
                        if (index2 != idx) {
                            tmpDt.push(dt[idx]);
                        }
                    }
                    item.specialPricing = tmpDt;
                }
                return item;
            });
        });
    }

    //資料修改 第一層的
    function accountDataChange(e, index, col) {
        setAccountData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "acntId") {
                        item.acntId = e.target.value;
                    } else if (col == "acntName") {
                        item.acntName = e.target.value;
                    } else if (col == "acntCode") {
                        item.acntCode = e.target.value;
                    } else if (col == "productId") {
                        item.productId = e.target.value;
                    } else if (col == "quotaCurrencyId") {
                        item.quotaCurrencyId = e.target.value;
                    } else if (col == "quota") {
                        let tmp = parseFloat(e.target.value.replaceAll(/,/g, '').replace(/[^0-9.]/g, ''));
                        item.quota = !isNaN(tmp) ? tmp : "";
                    } else if (col == "serviceCharge") {
                        let tmp = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
                        item.serviceCharge = !isNaN(tmp) ? tmp : 0;
                    } else if (col == "state") {
                        item.state = e.target.value;
                        if (e.target.value == "0") {
                            item.stateDate = moment().format('YYYY-MM-DD');
                        }
                    } else if (col == "stateDate") {
                        item.stateDate = e.target.value.replaceAll("/", "-");
                    } else if (col == "help") {
                        item.help = item.help == 1 ? 0 : 1; //如果技術支援本來是1 就給他0 反之0 就給1
                    } else if (col == "isEdit") {
                        item.isEdit = item.isEdit == 1 ? 0 : 1;
                    } else if (col == "isOpen") {
                        item.isOpen = (item.isOpen == null || item.isOpen == false) ? true : false;
                    }

                }
                return item;
            });
        });
    }
    //資料修改 第二層的特殊產品計價
    function accountDataChange2(e, index, index2, col) {

        setAccountData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    for (var i2 = 0; i2 < item.specialPricing.length; i2++) {
                        if (i2 == index2) {

                            if (col == "title") {
                                item.specialPricing[i2].title = e.target.value;
                            } else if (col == "isCoupon") {
                                item.specialPricing[i2].isCoupon = item.specialPricing[i2].isCoupon == 1 ? 0 : 1;
                            } else if (col == "rawData") {
                                item.specialPricing[i2].rawData = e.target.value;
                            } else if (col == "discountPrice") {
                                item.specialPricing[i2].discountPrice = e.target.value;
                            } else if (col == "discountUnit") {
                                item.specialPricing[i2].discountUnit = e.target.value;
                            }
                            //rawData  discount discountUnit 是在內頁修改 所以修改玩要先把內頁的資料也更新
                            var dt = Clone(item);
                            setSpecialPricingData(dt);
                        }
                    }

                }
                return item;
            });
        });
    }
    //雲端帳號計費公式 確定算式
    function CheckFormulaData() {

        //console.log(tmpIndex1,tmpIndex2);
        //console.log(formulaData);
        //console.log(specialPricingData);
        //更新 折扣計費欄位 折扣 / 單價
        //更新整個formula 雲端帳號計算公式
        setAccountData(function (prev) {
            return prev.map((item, i) => {
                if (i == tmpIndex1) {

                    item.specialPricing.map((item2, i2) => {
                        //console.log(item2);
                        if (i2 == tmpIndex2) {
                            item2.discountPrice = specialPricingData.discountPrice;
                            item2.discountUnit = specialPricingData.discountUnit;
                            item2.rawData = specialPricingData.rawData;
                            //不用clone的話會[]
                            var tmpDT = Clone(formulaData);
                            item2.formula = tmpDT;
                        }
                    })
                }
                return item;
            });
        });

        CloseModal("modal-formula");
    }

    function formulaDataChange(e, index, col) {
        setFormulaData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "formulaSigns") {
                        item.formulaSigns = e.target.value;
                        if (e.target.value == "Y=" || e.target.value == "Y≦" || e.target.value == "Y<") {
                            item.upperLimit = 0;
                            item.lowerLimit = 0;
                            item.value = "";
                        } else if (e.target.value == "<Y<" || e.target.value == "≦Y≦" || e.target.value == "<Y≦" || e.target.value == "<Y≦" || e.target.value == "≦Y<") {
                            item.value = "";
                        } else if (e.target.value == "≦Y" || e.target.value == "<Y") {
                            item.upperLimit = 0;
                            item.lowerLimit = 0;
                            item.value = "";
                        }
                    } else if (col == "isNumber") {
                        item.formulaSigns = item.isNumber == 0 ? "<Y<" : "=";
                        item.isNumber = item.isNumber == 0 ? 1 : 0;
                        item.upperLimit = 0;
                        item.lowerLimit = 0;
                        item.value = "";

                    } else if (col == "rawData") {
                        item.rawData = e.target.value;
                    } else if (col == "lowerLimit") {
                        item.lowerLimit = parseFloat((e.target.value + "").replace(/[^0-9.]/g, ''));
                    } else if (col == "upperLimit") {
                        item.upperLimit = parseFloat((e.target.value + "").replace(/[^0-9.]/g, ''));
                    } else if (col == "value") {
                        item.value = e.target.value;
                    }
                }
                return item;
            });
        });
    }
    //雲端帳號計費公式 清空欄位
    function ClearFormulaData() {

        var dt = [{
            rawData: null,
            isNumber: 0,
            formulaSigns: "<Y<",
            lowerLimit: null,
            upperLimit: null,
            value: null,
        }];
        setFormulaData(dt);
        //setFormulaData(initFormulaData);
        var dt = Clone(specialPricingData);
        dt.rawData = null;
        dt.discountPrice = 0;
        dt.discountUnit = 0;
        setSpecialPricingData(dt);

    }
    //打開備註
    function OpenModalRemark(index, type) {
        setTmpRemark("");
        setTmpIndex1(index);
        setRemarkType(type);
        if (type == 0) {  //備註
            setSubAccountComments(accountData[index].subAccountComments.filter(x => x.type == type));
        }
        else {  //PM備註
            var dt = {
                cloudAccountId: accountData[index].id,
                type: type,
            }

            //取得備註
            API_GET_CloudAcntRemark(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        setSubAccountComments(data.result.data.data);
                    }
                })
            //var content = accountData[index].subAccountComments[type].content;
            //console.log(content);
            //setTmpRemark(content);
        }
        OpenModal("modal-remark");
    }

    //送出 新增備註or修改備註
    function editRemark() {
        if (remarkType == 0) {  //備註
            var newAccountData = [...accountData];
            newAccountData[tmpIndex1].subAccountComments.push({
                id: null,
                accountId: newAccountData[tmpIndex1].acntId,
                type: remarkType,
                content: tmpRemark,
                userId: parseInt(myID),
                userName: username,
                createdTime: new Date(),
                flag: "N"
            });
            setAccountData(newAccountData);
            CloseModal("modal-remark");
        }
        else { //PM備註
            var dt1 = {
                cloudAccountId: accountData[tmpIndex1].id,
                type: remarkType,
                content: tmpRemark,
            };
            //console.log(JSON.stringify(dt1));
            // 呼叫11-9新增備註
            API_POST_CloudAcntRemark(dt1)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        setModalStr("新增成功");
                        setTmpRemark('');
                        CloseModal("modal-remark");
                        OpenModal("modal-ok");
                    } else {
                        setModalStr(data.message);
                        OpenModal("modal-error", 2);
                    }
                })
        }
    }
    //轉移帳號
    function OpenModalTransfer(index) {

        //先記錄暫存index
        setTmpIndex1(index);
        //本來是呼叫11-21 但這邊應該是不用抓的 審核單才要
        //審核中2023/2/1說明 審核中也無須顯示，只要未送出但有轉移帳號當下顯示即可
        //if (cloudAccountId != 0 && cloudAccountId != null) {
        //    var dt = {
        //        cloudAccountId: cloudAccountId
        //    }
        //    API_GET_CloudAcntTransferData(dt)
        //        .then((response) => response.data)
        //        .then((data) => {
        //            //console.log(data);
        //            if (!checkAuthToken(data)) {
        //                history.push("/");
        //            }
        //            if (data.code == "0000") {
        //                setTID(data.result.data.accountId);
        //                //找暫存資料 (或許他有轉移過但沒儲存)


        //                //setTCompanyId(data.result.data.companyId);
        //                //setTCustomerName(data.result.data.customerName);
        //                //setTCustomerTitle(data.result.data.customerTitle);
        //                //setTCustomerTaxId(data.result.data.customerTaxId);
        //                //setTAccountId(data.result.data.accountId);
        //                //setTAccountCode(data.result.data.accountCode);
        //                //setTPersonInChargeName(data.result.data.personInChargeName);
        //            }
        //        })
        //}
        for (var i = 0; i < transferData.length; i++) {
            if (i == index) {
                setTCompanyId(transferData[i].company);
                setTCustomerID(transferData[i].customerID);
                setTCustomerName(transferData[i].customerName);
                setTCustomerTitle(transferData[i].customerTitle);
                setTCustomerTaxId(transferData[i].customerTaxId);
                setTAccountId(transferData[i].accountId);
                setTAccountCode(transferData[i].accountCode);
                setTPersonInChargeName(transferData[i].personInChargeName);
            }
        }
        OpenModal("modal-transfer");
    }
    function changeTCustomer(cid) {

        //console.log(cid);
        //改了轉移的客戶別
        //呼叫11-17重抓負責業務
        var dt = {
            customerId: cid,
        };
        API_GET_CloudAcntCustPersonInCharge(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setTPersonInChargeName(data.result.data.personInChargeName);
                }
            })
    }
    function OpenModalCheckTransfer() {
        if (onbeforeunloadFlag == false) {
            window.onbeforeunload = function (e) { }
            onbeforeunloadFlag = true;
            SetHasChange();
            document.getElementById('div_leave').innerHTML = "是否放棄編輯此紀錄？";
        }
        if (tCompanyId == null) {
            setTCompanyIdError(true);
            return false;
        } else {
            setTCompanyIdError(false);
        }
        if (tCustomerName == null || tCustomerName == '') {
            setTCompanyNameError(true);
            return false;
        } else {
            setTCompanyNameError(false);
        }
        OpenModal("modal-checktransfer");
    }
    //確定轉移 寫入暫存
    function Transfer() {

        //標記有資料有轉移，外面需要呼叫11-11
        setHasTransfer(true);
        //將外面資料的ID改成剛剛輸入的ID
        setAccountData(function (prev) {
            return prev.map((item, i) => {
                if (i == tmpIndex1) {
                    //tAccountCode 非必填 這邊簡單防呆
                    if (tAccountCode != null && tAccountCode != '') {
                        item.acntCode = tAccountCode;
                    }
                    item.transCustId = tCustomerId;
                    item.transCmpID = tCompanyId;
                    item.toCustomerName = tCustomerName;
                }
                return item;
            });
        });
        //將這筆資料先寫在暫存的ARRAY 等外面呼叫11-7使用
        var tmpTransferArray = Clone(transferData);
        var hasData = false;
        for (var i = 0; i < tmpTransferArray.length; i++) {
            if (tmpTransferArray[i].accountId == tAccountId) {
                hasData = true;
                tmpTransferArray[i].company = tCompanyId;
                tmpTransferArray[i].customerId = tCustomerId;
                tmpTransferArray[i].accountCode = tAccountCode;
                tmpTransferArray[i].customerName = tCustomerName;
                tmpTransferArray[i].customerTaxId = tCustomerTaxId;
                tmpTransferArray[i].customerTitle = tCustomerTitle;
                tmpTransferArray[i].personInChargeName = tPersonInChargeName;
            }
        }
        if (hasData == false) {
            //沒資料 重新寫一筆進去
            var dt = {
                accountId: tAccountId,
                customerId: tCustomerId,
                company: tCompanyId,
                accountCode: tAccountCode,
                customerName: tCustomerName,
                customerTaxId: tCustomerTaxId,
                customerTitle: tCustomerTitle,
                personInChargeName: tPersonInChargeName,
            }
            tmpTransferArray.push(dt);
        }
        //整理玩寫入TransferData
        setTransferData(tmpTransferArray);
        //關閉modal 
        CloseModal("modal-checktransfer");
        CloseModal("modal-transfer");
    }
    //重新取得 客戶別 客戶別Title 客戶統編 List
    function getCustomer3Name(index) {

        var dt = [];
        if (index == 1) {
            dt = {
                customerName: document.getElementById("customerNameText").value
            }
        }
        else if (index == 2) {
            dt = {
                customerTitle: document.getElementById("customerTitleText").value
            }

        }
        else if (index == 3) {
            dt = {
                customerTaxId: document.getElementById("customerTaxIdText").value,
            }
        }
        API_GET_Customer3Name(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                var dataName = [];
                var dataTitle = [];
                var dataTaxId = [];

                if (data.result != null && data.result.data != null) {
                    if (index == 1) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataName.length; j++) {
                                if (dataName[j].custName == data.result.data[i].custName) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataName.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        //console.log(dataName);
                        setTCustomerNameArray(dataName);
                    } else if (index == 2) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTitle.length; j++) {
                                if (dataTitle[j].custTitle == data.result.data[i].custTitle) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTitle.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxId: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setTCustomerTitleArray(dataTitle);
                    } else if (index == 3) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTaxId.length; j++) {
                                if (dataTaxId[j].custTaxID == data.result.data[i].custTaxID) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTaxId.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setTCustomerTaxIdArray(dataTaxId);
                    }
                }
            })

    }

    //儲存變更
    function EditCloudAcnt() {
        CloseModal("modal-checkeditcloudacc");
        accountData.map(x => {
            x.stateDate = x.stateDate.replace('/', '-').replace('/', '-');
        });
        var dt = {
            id: caid,
            companyId: parseInt(queryParams.get("companyId")),
            customerId: parseInt(queryParams.get("customerId")),
            wholeDiscount: (wholeDiscount == "" ? null : parseFloat(wholeDiscount.replace(/[^0-9.]/g, ''))),
            personInChargeId: personInChargeId,
            accountData: accountData,
        };
        console.log("儲存變更的json", JSON.stringify(dt));


        const timeout = setTimeout(() => {
            API_PUT_CloudAcntData(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        setModalStr("儲存成功");
                        OpenModal("modal-reload");
                        window.onbeforeunload = null;
                        onbeforeunloadFlag = false;
                        SetHasChange(false);
                    } else {
                        setModalStr(data.message);
                        OpenModal("modal-error");
                        getData();
                    }
                })
        }, 500)
        return () => clearTimeout(timeout)


    }
    function OpenModalReview() {
        OpenModal("modal-review");
        //reviewFormId  >0才需要呼叫API查詢歷程

        var dt = {
            category: "CloudAcnt",
            name: companyId + "_" + parseInt(queryParams.get("customerId")) + "_" + parseInt(queryParams.get("personInChargeId")) + "_" + queryParams.get("productName1"),
            id: 0
        }
        API_GET_AuditHistory(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setReviewData(data.result.data);
                }
            })


    }
    function reload() {
        //重新導頁
        var path = "";
        if (isPM == 1) {
            path = '/Main?path=CloudAcntList3&companyId=' + companyId + "&productName1=" + queryParams.get("productName1") + "&customerId=" + parseInt(queryParams.get("customerId"));
        } else {
            path = '/Main?path=CloudAcntList3&companyId=' + parseInt(queryParams.get("companyId")) + "&productName1=" + queryParams.get("productName1") + "&customerId=" + parseInt(queryParams.get("customerId"));
        }

        getData();
        CloseModal("modal-reload");
    }

    function goQry() {
        window.onbeforeunload = null;
        window.location.href = "/Main?path=CloudAcntQry";
    }

    return (
        <>
            <div className="content-body">
                <div className="title-area">
                    <div className="container full">
                        <h3 className="fz-B has-border">隸屬公司：{companyName}</h3>
                    </div>
                </div>
                <div className="container full">
                    <div className="form-container">
                        <div className="form-item half">
                            <div className="field">客戶別</div>
                            <div className="value">
                                <input type="text" value={customerName} disabled="disabled" />
                            </div>
                            <div className="help-word">
                                請選擇負責公司
                            </div>
                        </div>
                        <div className="form-item half">
                            <div className="field">公司抬頭</div>
                            <div className="value">
                                <input type="text" value={customerTitle} disabled="disabled" />
                            </div>
                            <div className="help-word">
                                請選擇負責公司
                            </div>
                        </div>
                        <div className="form-item half">
                            <div className="field">公司統編</div>
                            <div className="value">
                                <input type="text" value={customerTaxId} disabled="disabled" />
                            </div>
                            <div className="help-word">
                                請選擇負責公司
                            </div>
                        </div>
                        <div className={`form-item half has-datalist ${isPM ? "must" : ""}`} data-datalist>
                            <div className="field">負責業務</div>
                            <div className="value multi-inline">
                                <input
                                    id="personInChargeNameText"
                                    type="text"
                                    value={personInChargeName}
                                    onChange={(e) => personInChargeNameChange(e)}
                                    disabled={!isPM || adNameState}
                                    data-required />
                                <ul className="datalist scrollbar">
                                    {
                                        personInChargeNameArray.map(x =>
                                            <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => { setPersonInChargeName(`${x.enName}(${x.tc_name})`), setPersonInChargeId(x.id) }}>
                                                <span className="flex-4">{x.enName}({x.tc_name})</span>
                                                <span className="flex-5">{x.mail}</span>
                                            </li>
                                        )
                                    }
                                </ul>
                                {isPM ?
                                    <a className="btn btn-border btn-middle" onClick={(e) => setAdNameState(!adNameState)}>
                                        <span>修改</span>
                                    </a>
                                    : <></>
                                }
                            </div>
                            <div className="help-word">
                                請選擇負責公司
                            </div>

                        </div>
                        <div className="form-item must half">
                            <div className="field">折扣</div>
                            <div className="value">
                                <DebounceInput
                                    debounceTimeout={100}
                                    type="text"
                                    className="right max-120"
                                    value={`${wholeDiscount}`}
                                    onClick={e => e.target.select()}
                                    onChange={(e) => {
                                        let tmp = e.target.value.replace(/[^0-9.]/g, '');
                                        if (tmp == "") {
                                            tmp = "0";
                                        }
                                        setWholeDiscount("");
                                        setWholeDiscount(parseFloat(tmp) + "%");
                                    }}
                                    placeholder="%"
                                    disabled={isReviewing ? 'disabled' : null}
                                    data-required />
                            </div>
                            <div className="help-word">
                                請輸入正確格式
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>

                <div className="title-area">
                    <div className="container full">
                        <h3 className="fz-B has-border">雲端細項總稱：{queryParams.get("productName1")}</h3>
                        {
                            //退回
                            isDrawBack ?
                                <>
                                    <div class="right">
                                        審查人：{auditorName}

                                        <textarea name="" id="" cols="30" rows="3">
                                            {drawBackReason}
                                        </textarea>

                                    </div>
                                    <div class="title-stamp small">
                                        <div class="circle">
                                            <strong>
                                                退回
                                            </strong>
                                        </div>
                                    </div>
                                </>
                                :
                                //審核中
                                isReviewing ?
                                    null
                                    :
                                    //都不是
                                    <div className="right">
                                        <a className="btn btn-border btn-middle" onClick={OpenModalReview}>
                                            <span>審核歷程</span>
                                        </a>
                                    </div>
                        }
                    </div>
                </div>
                {
                    isReviewing ?
                        <div class="review-area">
                            <div class="container full">
                                <div class="r-list">
                                    <strong>審查人：</strong>
                                    <ul>
                                        {
                                            reviewerArray != null ?
                                                reviewerArray.map((item, i) => (
                                                    <li key={i}>{item}</li>
                                                )) : null
                                        }
                                    </ul>
                                </div>
                                <div class="title-stamp small">
                                    <div class="circle">
                                        <strong>
                                            審查中
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }
                <div className="container full">
                    <div className="info-holder no-padding-left">
                        <div className="left" style={{ fontWeight: 500 }}>雲端帳號列表</div>
                    </div>
                    <ul className="list-table for-c11">
                        <li className="lt-row lt-th">
                            <div className="flex-3">帳號 ID</div>
                            <div className="flex-2">帳號名稱</div>
                            <div className="flex-2">帳號代號</div>
                            <div className="flex-3">產品細項</div>
                            <div className="flex-2">帳號狀態</div>
                            <div className="flex-2">帳號狀態日期</div>
                            <div className="flex-1 center max-100">技術支援</div>
                            <div className="flex-2 fn max-100"></div>
                        </li>
                        {
                            accountData.length > 0 && accountData.map((x, i) =>
                                <div key={i}>
                                    <li className={`lt-row ${x.isOpen == true ? 'highlight' : ''}`}>
                                        <div className={`flex-3 ${x.state == 0 ? 'disabled' : ''}`} > {/*帳號 ID*/}
                                            <div className="elips-1"> {x.acntId}</div>
                                        </div>
                                        <div className={`flex-2 ${x.state == 0 ? 'disabled' : ''}`}> {/*帳號名稱*/}
                                            {
                                                !isReviewing && x.isEdit == 1 ?
                                                    <input type="text" value={x.acntName} onChange={(e) => accountDataChange(e, i, "acntName")} />
                                                    :
                                                    <div className="elips-1">{x.acntName}</div>
                                            }
                                        </div>
                                        <div className={`flex-2 ${x.state == 0 ? 'disabled' : ''}`}> {/*帳號代號*/}
                                            {
                                                !isReviewing && x.isEdit == 1 ?
                                                    <input type="text" value={x.acntCode} onChange={(e) => accountDataChange(e, i, "acntCode")} />
                                                    :
                                                    <div className="elips-1">{x.acntCode} </div>
                                            }
                                        </div>
                                        <div className={`flex-3 ${x.state == 0 ? 'disabled' : ''}`}> {/*產品細項*/}
                                            {
                                                !isReviewing && x.isEdit == 1 ?
                                                    <select name="" id="" onChange={e => accountDataChange(e, i, "productId")} data-required>
                                                        <option value="" selected disabled>請選擇</option>
                                                        {
                                                            cloudProductName2Array.map(c =>
                                                                <option value={c.id} selected={x.productId == c.id ? true : false}>{c.name}</option>
                                                            )
                                                        }
                                                    </select>
                                                    :
                                                    <div className="elips-1">{x.productName2}</div>
                                            }
                                        </div>
                                        <div className="flex-2"> {/*帳號狀態*/}
                                            {
                                                !isReviewing ?
                                                    <select className="no-border" onChange={(e) => accountDataChange(e, i, "state")}>
                                                        <option value="" selected disabled>請選擇</option>
                                                        <option value="0" selected={x.state == 0 ? true : false}>停用</option>
                                                        <option value="1" selected={x.state == 1 ? true : false}>正式啟用中</option>
                                                        <option value="2" selected={x.state == 2 ? true : false}>測試啟用中</option>
                                                    </select>
                                                    :
                                                    x.state == 0 ? '停用' :
                                                        x.state == 1 ? '正式啟用中' :
                                                            x.state == 2 ? '測試啟用中'
                                                                : '無'
                                            }
                                        </div>
                                        <div className={`flex-2 ${x.state == 0 ? 'disabled' : ''}`}> {/*帳號狀態日期*/}
                                            {
                                                x.stateDate != "" && x.stateDate != null ?
                                                    <input
                                                        type="date"
                                                        value={x.stateDate.replaceAll("/", "-")}
                                                        onChange={e => accountDataChange(e, i, "stateDate")}
                                                        max={x.state == 0 ? moment(new Date()).format("YYYY-MM-DD") : null}
                                                        disabled={isReviewing ? true : false}
                                                        data-required />
                                                    : ''
                                            }
                                        </div>
                                        <div className={`flex-1 center max-100 ${x.state == 0 ? 'disabled' : ''}`}> {/*技術支援*/}
                                            <input type="radio" id={`help_${i}`} checked={x.help == 0 ? false : true} onClick={e => accountDataChange(e, i, "help")} disabled={isReviewing || x.state == 0 ? 'disabled' : null} data-required />
                                            <label htmlFor={`help_${i}`}></label>
                                        </div>
                                        <div className="flex-2 fn jcfe max-100">
                                            <div className="fn-bar">
                                                <div className={`btn btn-icon ${x.state == 0 ? 'disabled' : ''}`}>
                                                    {
                                                        isReviewing ? null :
                                                            <i className="icon icon-30" onClick={(e) => accountDataChange(e, i, "isEdit")}></i>
                                                    }
                                                </div>
                                                <div className={`btn btn-icon ${x.isOpen == true ? 'rotate' : ''}`} onClick={(e) => accountDataChange(e, i, "isOpen")}>
                                                    <i className="icon icon-28"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className={`lt-row ${x.isOpen == true ? '' : 'hide'}`} id={`st-${i}`}>
                                        <ul className="list-table nested for-c11">
                                            <li className="lt-row lt-th">
                                                <div className="flex-3"></div>
                                                <div className="flex-2">額度幣別</div>
                                                <div className="flex-3 right">帳號額度</div>
                                                <div className="flex-2 right">服務費</div>
                                                <div className="flex-1"></div>
                                                <div className="flex-3">移轉後客戶別</div>
                                                <div className="flex-1"></div>
                                                <div className="flex-2 jcfe fn"></div>
                                            </li>
                                            <li className="lt-row">
                                                <div className="flex-3"></div>
                                                <div className="flex-2"> {/*額度幣別*/}
                                                    {
                                                        !isReviewing ?
                                                            <select
                                                                className="no-border" disabled={x.state == 0 ? 'disabled' : null}
                                                                onChange={e => accountDataChange(e, i, "quotaCurrencyId")} data-required>
                                                                <option value=" selected disabled">請選擇</option>
                                                                {
                                                                    currencyArray.map((c, cIndex) =>
                                                                        <option key={`currency-${cIndex}`} value={c.currID} selected={x.quotaCurrencyId == c.currID}>{c.currName}</option>
                                                                    )
                                                                }
                                                            </select>
                                                            :
                                                            currencyArray.map(c =>
                                                                x.quotaCurrencyId == c.currID ? c.currName : ''
                                                            )
                                                    }
                                                </div>
                                                <div className="flex-3 right"> {/*帳號額度*/}
                                                    {
                                                        !isReviewing && x.isEdit == 1 ?
                                                            //<input type="number" value={x.quota} onChange={(e) => accountDataChange(e, i, "quota")} />

                                                            <input
                                                                type="text"
                                                                value={NewNumFormart(x.quota)}
                                                                onChange={(e) => accountDataChange(e, i, "quota")}
                                                                onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                                                onBlur={(e) => e.target.value = NewNumFormart(x.quota)}
                                                                data-required />
                                                            :
                                                            NumFormart(x.quota)
                                                    }
                                                </div>
                                                <div className="flex-2 right"> {/*服務費*/}
                                                    {
                                                        !isReviewing && x.isEdit == 1 ?
                                                            <input
                                                                type="text"
                                                                value={x.serviceCharge + "%"}
                                                                onClick={(e) => e.target.select()}
                                                                onFocus={(e) => e.target.value = (e.target.value + "").replace(/[^0-9.]/g, '')}
                                                                onChange={(e) => accountDataChange(e, i, "serviceCharge")}
                                                                onBlur={(e) => e.target.value = x.serviceCharge + "%"}
                                                                placeholder="%"
                                                                data-required />
                                                            :
                                                            <>
                                                                {x.serviceCharge + "%"}
                                                            </>
                                                    }
                                                </div>
                                                <div className="flex-1"></div>
                                                <div className="flex-3">
                                                    {
                                                        isReviewing ? null
                                                            :
                                                            x.toCustomerName != "" ?
                                                                <>
                                                                    <span>
                                                                        {x.toCustomerName}
                                                                    </span>
                                                                    <a className={x.state == 0 ? "btn btn-small btn-border disabled" : "btn btn-small btn-border"} onClick={() => OpenModalTransfer(i)}>
                                                                        <span>變更</span>
                                                                    </a>
                                                                </>
                                                                :
                                                                <a className={x.state == 0 ? "btn btn-small btn-border disabled" : "btn btn-small btn-border"} onClick={() => OpenModalTransfer(i)}>
                                                                    <span>轉移帳號</span>
                                                                </a>
                                                    }
                                                </div>
                                                <div className="flex-1"></div>
                                                <div className="flex-2 fn jcfe">
                                                    <a className="btn btn-small btn-text" onClick={() => OpenModalRemark(i, 0)}>
                                                        <span>備註</span>
                                                    </a>
                                                    {
                                                        isPM == 1 ?
                                                            <a className="btn btn-small btn-text" onClick={() => OpenModalRemark(i, 1)}>
                                                                <span>PM 備註</span>
                                                            </a>
                                                            : null
                                                    }
                                                </div>
                                            </li>
                                            <li className="lt-row no-border">
                                                <div className="flex-3"></div>
                                                <div className="flex-5 fc-black fw-bold">
                                                    特殊產品計價
                                                </div>
                                                <div className="flex-2 center fc-black fw-bold">
                                                    {
                                                        isPM == 1 ?
                                                            '使用代金券'
                                                            : null
                                                    }

                                                </div>

                                                <div className="flex-4">
                                                    {
                                                        //最後一比的話要顯示新增特殊產品計價
                                                        x.specialPricing != null && x.specialPricing.length == 0 && !isReviewing && x.isEdit == 1 ?
                                                            <a className="btn btn-small btn-border" onClick={() => AddSpecialPricing(i, 2)}>
                                                                <span>新增特殊產品計價</span>
                                                            </a>
                                                            : null
                                                    }
                                                </div>
                                                <div className="flex-3 jcfe fn"></div>
                                            </li>
                                            {
                                                x.specialPricing != null && x.specialPricing.length > 0 ?
                                                    x.specialPricing.map((x2, i2) =>
                                                        <li key={`specialPricing-${i2}`} className="lt-row no-border">
                                                            <div className="flex-3">
                                                                {
                                                                    i2 > 0 && !isReviewing && x.isEdit == 1 ?
                                                                        <div className="btn btn-icon" onClick={() => DelSpecialPricing(i, i2)}>
                                                                            <i className="icon icon-37"></i>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                            <div className="flex-5">
                                                                {
                                                                    !isReviewing && x.isEdit == 1 ?
                                                                        <input type="text" value={x2.title} onChange={(e) => accountDataChange2(e, i, i2, "title")} />
                                                                        :
                                                                        x2.title
                                                                }
                                                            </div>
                                                            <div className="flex-2 center">
                                                                {isPM == 1 ?
                                                                    <>
                                                                        <input type="radio" id={`isCoupon_${i}_${i2}`} onClick={(e) => accountDataChange2(e, i, i2, "isCoupon")} checked={x2.isCoupon == 1 ? true : false} disabled={isReviewing || x.state == 0 ? 'disabled' : null} />
                                                                        <label htmlFor={`isCoupon_${i}_${i2}`}></label>
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                            </div>

                                                            <div className="flex-4">
                                                                {
                                                                    isPM == 1 ?
                                                                        <a className={x.state == 0 ? "btn btn-text btn-small disabled" : "btn btn-text btn-small"} onClick={() => OpenModalFormula(i, i2, x.productId)}>
                                                                            <span>編輯計費項目</span>
                                                                        </a>
                                                                        : null
                                                                }
                                                            </div>

                                                            <div className="flex-3 fn jcfe">
                                                                {
                                                                    //最後一比的話要顯示新增特殊產品計價
                                                                    x.specialPricing.length == i2 + 1 && !isReviewing && x.isEdit == 1 ?
                                                                        <a className="btn btn-small btn-border" onClick={() => AddSpecialPricing(i)}>
                                                                            <span>新增特殊產品計價</span>
                                                                        </a>
                                                                        : null
                                                                }

                                                            </div>
                                                        </li>

                                                    ) : null
                                            }
                                        </ul>
                                    </li>
                                </div>
                            )
                        }
                    </ul>
                    <div className="form-container">
                        <div className="form-item btn-area sp">
                            <div className="left">

                            </div>
                            <div className="right">
                                <a className={`btn ${isReviewing ? 'disabled' : ''}`} onClick={() => OpenModal("modal-checkeditcloudacc")}>
                                    <span>儲存變更</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error", 2)}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-formula" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">雲端帳號計費公式</h3>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">

                        <ul className="list-table transparent formula-group">
                            <li className="lt-row lt-th">
                                <div className="flex-1 fn max-60 no-form"></div>
                                <div className="flex-4">
                                    計費項目
                                </div>
                                <div className="flex-1">值為數字</div>
                                <div className="flex-8">
                                    <div className="flex-3"></div>
                                    <div className="flex-2"></div>
                                    <div className="flex-3"></div>
                                </div>
                                <div className="flex-1"></div>
                                <div className="flex-1"></div>

                            </li>
                            {
                                formulaData.map((x, i) =>
                                    <li className="lt-row for-formula">
                                        <div className="flex-1 fn max-60 no-form">
                                            {
                                                i > 0 && isPM == 1 ?
                                                    <a className="btn btn-icon" onClick={() => DelFormulaData(i)}>
                                                        <i className="icon icon-37"></i>
                                                    </a>
                                                    : null
                                            }
                                        </div>
                                        <div className="flex-4">
                                            <div className="form-item in-table no-field">
                                                <div className="value">
                                                    {
                                                        isPM == 1 ?
                                                            <select onChange={(e) => formulaDataChange(e, i, "rawData")}>
                                                                <option value="" >Raw Data</option>
                                                                {
                                                                    rawDataArray.map((x2, x2Index) =>
                                                                        <option key={x2Index} value={x2.id} selected={x.rawData == x2.id ? true : false}>{x2.name}</option>
                                                                    )
                                                                }

                                                            </select>
                                                            :
                                                            rawDataArray.map(x2 => { x.rawData == x2.id ? x2.name : null }
                                                            )
                                                    }

                                                </div>
                                                <div className="help-word right">
                                                    請選擇計費項目
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 center">
                                            <div className="form-item in-table no-field center">
                                                <div className="value">
                                                    {
                                                        isPM == 1 ?
                                                            <>
                                                                <input type="radio" id={`isNumber_${i}`} onClick={(e) => formulaDataChange(e, i, "isNumber")} checked={x.isNumber == 0 ? false : true} />
                                                                <label htmlFor={`isNumber_${i}`}></label>
                                                            </>
                                                            :
                                                            <>
                                                                <input type="radio" id={`isNumber_${i}`} checked={x.isNumber == 0 ? false : true} disabled="disabled" />
                                                                <label htmlFor={`isNumber_${i}`}></label>
                                                            </>
                                                    }

                                                </div>
                                                <div className="help-word right">

                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-8 has-nested">
                                            {
                                                x.isNumber == 1 ?
                                                    <>
                                                        <div className="flex-3">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <input type="number" placeholder="請輸入前範圍" value={x.lowerLimit} onChange={(e) => formulaDataChange(e, i, "lowerLimit")}
                                                                        disabled={
                                                                            isPM == 0 || x.formulaSigns == "Y=" || x.formulaSigns == "Y≦" || x.formulaSigns == "Y<" ?
                                                                                true : false
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-2">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <select onChange={(e) => formulaDataChange(e, i, "formulaSigns")} disabled={isPM == 1 ? false : true}>
                                                                        <option value="<Y<" selected={x.formulaSigns == "<Y<" ? true : false}>{"<"} Y {"<"}</option>
                                                                        <option value="≦Y≦" selected={x.formulaSigns == "≦Y≦" ? true : false} >{"≦"} Y {"≦"}</option>
                                                                        <option value="<Y≦" selected={x.formulaSigns == "<Y≦" ? true : false}>{"<"} Y {"≦"}</option>
                                                                        <option value="≦Y<" selected={x.formulaSigns == "≦Y<" ? true : false} >{"≦"} Y {"<"}</option>
                                                                        <option value="Y=" selected={x.formulaSigns == "Y=" ? true : false} > Y {"="}</option>
                                                                        <option value="Y≦" selected={x.formulaSigns == "Y≦" ? true : false}> Y {"≦"}</option>
                                                                        <option value="≦Y" selected={x.formulaSigns == "≦Y" ? true : false}>{"≦"} Y </option>
                                                                        <option value="Y<" selected={x.formulaSigns == "Y<" ? true : false}> Y {"<"}</option>
                                                                        <option value="<Y" selected={x.formulaSigns == "<Y" ? true : false}>{"<"} Y </option>
                                                                    </select>
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-3">

                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <input type="number" placeholder="請輸入" value={x.upperLimit} onChange={(e) => formulaDataChange(e, i, "upperLimit")}
                                                                        disabled={
                                                                            isPM == 0 || x.formulaSigns == "≦Y" || x.formulaSigns == "<Y" ?
                                                                                true : false
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入算式條件
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> :
                                                    <>
                                                        <div className="flex-2">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <select onChange={(e) => formulaDataChange(e, i, "formulaSigns")} disabled={isPM == 1 ? false : true}>
                                                                        <option value="=">=</option>
                                                                        <option value="≠">≠</option>
                                                                    </select>
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-6">
                                                            <div className="form-item in-table no-field">
                                                                <div className="value">
                                                                    <input type="text" placeholder="請輸入" value={x.value} onChange={(e) => formulaDataChange(e, i, "value")} disabled={isPM == 1 ? false : true} />
                                                                </div>
                                                                <div className="help-word right">
                                                                    請填入算式條件
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                            }


                                        </div>

                                        <div className="flex-1 no-form center">
                                            {
                                                formulaData.length > 1 && i != formulaData.length - 1 ? 'and' :
                                                    isPM == 1 ?
                                                        <a className="btn btn-icon" onClick={AddFormulaData}>
                                                            <i className="icon icon-25"></i>
                                                        </a>
                                                        : null
                                            }
                                        </div>
                                        <div className="flex-1"></div>

                                    </li>
                                )
                            }
                            <li className="lt-row border">
                            </li>


                            <li className="lt-row lt-th">
                                <div className="flex-1 fn max-60 no-form">

                                </div>
                                <div className="flex-4">
                                    折扣計費欄位
                                </div>
                                <div className="flex-3">
                                    折扣/ 單價
                                </div>
                                <div className="flex-2"></div>
                                <div className="flex-5"></div>


                            </li>
                            <li className="lt-row for-formula">
                                <div className="flex-1 fn max-60 no-form">

                                </div>
                                <div className="flex-4">
                                    <div className="form-item in-table no-field">
                                        <div className="value">
                                            <select onChange={e => specialPricingDataChange(e, "rawData")}>
                                                <option value="" >Raw Data</option>
                                                {
                                                    rawDataArray.map((x2, x2Index) =>
                                                        <option key={x2Index} value={x2.id} selected={specialPricingData.rawData == x2.id ? true : false}>{x2.name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className="help-word right">
                                            請選擇計費項目
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-3 right no-form">
                                    <div className="multi-form">
                                        <input type="number" value={specialPricingData.discountPrice} onChange={e => specialPricingDataChange(e, "discountPrice")} className="" />
                                        <div className="line"></div>
                                        <select onChange={e => specialPricingDataChange(e, "discountUnit")}  >
                                            <option value="0" selected={specialPricingData.discountUnit == "0" ? true : false}>%</option>
                                            <option value="1" selected={specialPricingData.discountUnit == "1" ? true : false}>元</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="flex-2"></div>
                                <div className="flex-5 no-form jcfe">
                                    <a className="btn btn-middle btn-border" onClick={ClearFormulaData}>
                                        <span>清空欄位</span>
                                    </a>
                                    <a className="btn btn-middle" style={{ "color": "#FFFFFF" }} onClick={CheckFormulaData}>
                                        <span>確定算式</span>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="modal-remark" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">
                            {accountData.length > 0 && accountData[tmpIndex1].acntId ? accountData[tmpIndex1].acntId : ""}
                            {/*{isReviewing ? '' : '新增'}*/}
                            {remarkType == 0 ? '' : 'PM'}備註</h3>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-container">
                            <div className="form-item no-field">
                                <div className="field">隸屬公司</div>
                                <div className="value">
                                    <div className="msg-record">
                                        {
                                            subAccountComments.map((remark, index) => {
                                                let isShowDate = true;

                                                //判斷要不要顯示日期
                                                if (index > 0 && moment(remark.createdTime).format('YYYY/MM/DD') == moment(subAccountComments[index - 1].createdTime).format('YYYY/MM/DD')) {
                                                    isShowDate = false;
                                                }

                                                return (
                                                    <ul key={index}>
                                                        {isShowDate ?
                                                            <li className="date">{moment(remark.createdTime).format('YYYY/MM/DD')}</li> : null
                                                        }
                                                        <li>[{moment(remark.createdTime).format('HH:mm:ss')}{" "}{remark.userName}] {remark.content}</li>
                                                    </ul>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="help-word">
                                    請選擇負責公司
                                </div>
                            </div>

                            <div className="form-item remark-block">
                                <div>
                                    {
                                        isReviewing ?
                                            null :
                                            <textarea name="" id="" cols="30" rows="5" value={tmpRemark} onChange={(e) => setTmpRemark(e.target.value)}>

                                            </textarea>
                                    }

                                    <div className="btn-area">
                                        {
                                            isReviewing ?
                                                <a className="btn btn-middle btn-border" onClick={() => CloseModal("modal-remark")}>
                                                    <span>關閉</span>
                                                </a>
                                                :
                                                <a className={`btn btn-middle btn-border ${tmpRemark == null || tmpRemark == "" ? "disabled" : ""}`} onClick={editRemark} disabled={tmpRemark == null || tmpRemark == "" ? "disabled" : ""}>
                                                    <span>確定送出</span>
                                                </a>

                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-transfer" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">{tID} 轉移對象選擇</h3>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-container">
                            <div className={`form-item must ${tCompanyIdError ? 'show-help' : ''}`}>
                                <div className="field">隸屬公司</div>
                                <div className="value">
                                    {companyArray.map((x) => (
                                        <div key={x.id}>
                                            <input type="radio" id={`tCompany-${x.cmpID}`} value={x.cmpID} checked={tCompanyId == x.cmpID ? "checked" : ""} onClick={(e) => setTCompanyId(x.cmpID)} />
                                            <label htmlFor={`tCompany-${x.cmpID}`}>{x.cmpName}</label>
                                        </div>
                                    ))}
                                </div>
                                <div className="help-word">
                                    請選擇隸屬公司
                                </div>
                            </div>
                            <div className="form-item must half has-datalist" data-datalist>
                                <div className="field">客戶別</div>
                                <div className="value">
                                    <input type="text" id="customerNameText" className={tCustomerNameError ? 'error' : ''} value={tCustomerName} onChange={custNameChange} />
                                    <ul className="datalist scrollbar">
                                        {tCustomerNameArray.map((x) => (
                                            <li
                                                data-value={x.custName} onClick={(e) => { setTCustomerID(x.custID), setTCustomerName(x.custName), setTCustomerTitle(x.custTitle), setTCustomerTaxId(x.custTaxID), changeTCustomer(x.custID) }}>
                                                <span className="flex-4 bold">{x.custName}</span>
                                                <span className="flex-3">{x.custTitle}</span>
                                                <span className="flex-3">{x.custTaxID}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="help-word">
                                    請輸入正確格式
                                </div>
                            </div>

                            <div className="form-item half jcfe">
                                <div className="btn btn-middle btn-border" onClick={(e) => window.open("/Main?path=CustomerNew", "_blank")}>
                                    <span>新增客戶</span>
                                </div>
                            </div>
                            <div className="form-item half has-datalist" data-datalist>
                                <div className="field">公司抬頭</div>
                                <div className="value">
                                    <input type="text" id="customerTitleText" value={tCustomerTitle} onChange={custTitleChange} />
                                    <ul className="datalist scrollbar">
                                        <ul className="datalist scrollbar">
                                            {
                                                tCustomerTitleArray.map((x) => (
                                                    <li data-value={x.name} onClick={(e) => { setTCustomerID(x.custID), setTCustomerName(x.custName), setTCustomerTitle(x.custTitle), setTCustomerTaxId(x.custTaxID), changeTCustomer(x.custID) }}>
                                                        <span className="flex-10 bold">{x.name}</span>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </ul>
                                </div>
                                <span className="help-word">
                                    請輸入正確格式
                                </span>
                            </div>
                            <div className="form-item half  has-datalist" data-datalist>
                                <div className="field">公司統編/ 編號</div>
                                <div className="value">
                                    <input type="text" id="customerTaxIdText" value={tCustomerTaxId} onChange={custTaxIDChange} />
                                    <ul className="datalist scrollbar">
                                        {
                                            tCustomerTaxIdArray.map(x =>
                                                <li data-value={x.custTaxID} onClick={(e) => { setTCustomerID(x.custID), setTCustomerName(x.custName), setTCustomerTitle(x.custTitle), setTCustomerTaxId(x.custTaxID), changeTCustomer(x.custID) }}>
                                                    <span className="flex-4 bold">{x.custName}</span>
                                                    <span className="flex-3">{x.custTitle}</span>
                                                    <span className="flex-3">{x.custTaxID}</span>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                                <div className="help-word">
                                    請輸入正確格式
                                </div>
                            </div>
                            <div className="form-item half">
                                <div className="field">帳號代號</div>
                                <div className="value">
                                    <input type="text" value={tAccountCode} onChange={(e) => setTAccountCode(e.target.value)} />
                                </div>
                                <div className="help-word">
                                    請輸入正確格式
                                </div>
                            </div>
                            <div className="form-item half">
                                <div className="field">負責業務</div>
                                <div className="value multi-inline">
                                    <input type="text" value={tPersonInChargeName} disabled="disabled" />
                                </div>
                                <div className="help-word">
                                    請輸入正確格式
                                </div>
                            </div>
                            <hr />
                            <div className="form-item btn-area">
                                {
                                    isReviewing ?
                                        <a className="btn btn-border" onClick={() => CloseModal("modal-transfer")}>
                                            <span>關閉</span>
                                        </a> :
                                        <>
                                            <a className="btn btn-border" onClick={() => CloseModal("modal-transfer")}>
                                                <span>取消</span>
                                            </a>
                                            <a className="btn" onClick={OpenModalCheckTransfer}>
                                                <span>確定</span>
                                            </a>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-checkeditcloudacc" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-41"></i>
                        </div>
                        <div class="text-holder">
                            確定要儲存變更？
                        </div>
                        <div class="btn-holder">
                            <button class="btn btn-border" onClick={() => CloseModal("modal-checkeditcloudacc")}> <span>取消</span></button>
                            <button class="btn" onClick={EditCloudAcnt}> <span>確認</span></button>
                        </div>

                    </div>

                </div>
            </div>
            <div id="modal-checktransfer" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-41"></i>
                        </div>
                        <div class="text-holder">
                            確定要轉移此帳號？
                        </div>
                        <div class="btn-holder">
                            <button class="btn btn-border" onClick={() => CloseModal("modal-checktransfer", 2)}> <span>取消</span></button>
                            <button class="btn" onClick={Transfer}> <span>確認</span></button>
                        </div>

                    </div>

                </div>
            </div>
            <div id="modal-ok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-ok")}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-reload" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={reload}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-review" class="modal large" data-modal>
                <div class="modal-container">
                    <div class="modal-header">
                        <h3 class="fz-B has-border">審查歷程</h3>
                        <span class="close" data-close>
                            <i class="icon icon-44"></i>
                        </span>
                    </div>
                    <div class="modal-body">
                        <ul class="list-table">
                            <li class="lt-row lt-th">
                                <div class="flex-2">送審日期</div>
                                <div class="flex-3">送審人</div>
                                <div class="flex-2">審查原因</div>
                                <div class="flex-2">審查日期</div>
                                <div class="flex-3">審查人</div>
                                <div class="flex-2">審查結果</div>
                            </li>
                            {
                                reviewData.map(x =>
                                    <li class="lt-row">
                                        <div class="flex-2">
                                            <div class="elips-1">{x.applyDate}</div>
                                        </div>
                                        <div class="flex-3">
                                            <div class="elips-1">{x.auditApplier}</div>
                                        </div>
                                        <div class="flex-2">
                                            {x.auditReason}
                                        </div>
                                        <div class="flex-2">
                                            <div class="elips-1">{x.auditDate}</div>
                                        </div>
                                        <div class="flex-3">
                                            <div class="elips-1">{x.auditor}</div>
                                        </div>
                                        <div class="flex-2">
                                            {x.auditResult}
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>

            <div id="modal-goQry" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => goQry()}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CloudAcntList3;
