import React, { useState, useEffect } from "react";

import {
    API_GET_AllCompany,
    API_GET_InterViewRecords,
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { InitJS, MenuSet } from '../../global/ToolUtil';
import { CheckResponseCode } from '../../global/ResponseUtil';

const InterViewList = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        //把menu顏色置換
        MenuSet("InterViewQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    //幣別
    const [currencyArray, setCurrencyArray] = useState([{}]);
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyName, setCompanyName] = useState();

    //
    const [typeName, setTypeName] = useState();

    //客戶別/供應商名稱
    const [iName, setIName] = useState();

    //客戶別/供應商抬頭
    const [iTitle, setITitle] = useState();

    //客戶別/供應商抬頭
    const [iTaxId, setITaxId] = useState();

    //資料列
    const [dataList, setDataList] = useState([{}]);

    function getData() {
        //公司
        API_GET_AllCompany()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setCompanyArray(data.result.data);
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

        API_GET_InterViewRecords({
            companyId: parseInt(queryParams.get("companyId")),
            typeId: parseInt(queryParams.get("typeId")),
            intervieweeId: parseInt(queryParams.get("intervieweeid")),
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setIName(data.result.data.intervieweeName);
                    setDataList(data.result.data.interviewList);
                    setCompanyName(data.result.data.companyName);
                    setITitle(data.result.data.intervieweeTitle);
                    setITaxId(data.result.data.intervieweeTaxId);
                    setTypeName(data.result.data.typeName);
                }
            });
    }

    //前往查詢
    function returnQry() {
        history.push("/Main?path=InterViewQry");
    }
    //到編輯
    function goEdit(interviewId) {
        var path = "/Main?path=InterViewEdit&interviewId=" + interviewId;
        history.push(path);
    }
    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">{iName}</h3>
                </div>
            </div>
            <div className="container">
                <form action = "" className="form-container">
                    <div className="form-item half">
                        <div className="field">隸屬公司：</div>
                        <div className="value">{companyName}</div>
                        <span className="help-word"></span>
                    </div>
                    <div className="form-item  half">
                        <div className="field">身分別：</div>
                        <div className="value">{typeName}</div>
                        <span className="help-word">請選擇隸屬公司</span>
                    </div>
                    <div className="form-item  half">
                        <div className="field">公司抬頭</div>
                        <div className="value">
                            <input
                                type="text"
                                id="iTitleText"
                                name="iTitleText"
                                value={iTitle}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="form-item half">
                        <div className="field">公司統編/編號</div>
                        <div className="value">
                            <input
                                type="text"
                                id="iTaxIDText"
                                name="iTaxIDText"
                                value={iTaxId}
                                disabled
                            />
                        </div>
                    </div>
                    <br />
                    <hr style={{ marginTop: '2.5rem' }} />

                    <div className="form-item col">
                        <div className="field">訪談記錄列表</div>
                        <div className="value">
                            <ul className="list-table">
                                <li className="lt-row lt-th">
                                    <div className="flex-2">訪談時間</div>
                                    <div className="flex-3">訪談人員</div>
                                    <div className="flex-4">訪談主旨</div>
                                    <div className="flex-1">
                                        <span style={{width:"48px", textAlign: "center"}}>
                                            內容
                                        </span>
                                    </div>
                                </li>
                                {dataList.map((x) => (
                                    <li className="lt-row">
                                        <div className="flex-2">{x.interviewDate}</div>
                                        <div className="flex-3">{x.interviewer}</div>
                                        <div className="flex-4">{x.interviewTitle}</div>
                                        <div className="flex-1">
                                            <div
                                                className={`btn btn-icon`}
                                                onClick={(e) => goEdit(x.interviewId)}
                                            >
                                                <i className="icon icon-1"></i>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="form-item btn-area sp">
                        <div className="left"></div>
                        <div className="right">
                            <a className="btn btn-border" onClick={returnQry}>
                                <span>取消</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default InterViewList;
