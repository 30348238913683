import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
const InvoiceReviewListPM = ({ qryList, setCustomerName, setPersonInChargeName, setCompanyName,setInvoiceDate,setReqFrmID }) => {

    var history = useHistory();
   
    function openModalReview(frmID,customerName,personInChargeName,companyName,invoiceDate) {
        setReqFrmID(null);
        const timeout = setTimeout(() => {
            setCustomerName(customerName);
            setPersonInChargeName(personInChargeName);
            setCompanyName(companyName);
            setInvoiceDate(invoiceDate);
            setReqFrmID(frmID);
        }, 300)
        return () => clearTimeout(timeout)
    }
    return (
        <div id="modal-list-4-4" className="modal large" data-modal>
            <div className="modal-container">
                <div className="modal-header">
                    <h3 className="fz-B has-border">發票作廢退回列表</h3>
                    <span className="close" data-close>
                        <i className="icon icon-44">
                        </i>
                    </span>
                </div>
                <div className="modal-body">
                    <div>
                        <ul className="list-table">
                            <li className="lt-row lt-th">
                                <div className="flex-2">審核者</div>
                                <div className="flex-2">客戶別</div>
                                <div className="flex-2">負責業務</div>
                                <div className="flex-2">公司抬頭</div>
                                <div className="flex-2">發票開立月份</div>
                                <div className="flex-2">時間</div>
                                <div className="flex-2"></div>
                            </li>

                            {qryList.map((x) => (
                                <li className="lt-row">
                                    <div className="flex-2">{x.sender}</div>
                                    <div className="flex-2">{x.customerName}</div>
                                    <div className="flex-2">{x.personInChargeName}</div>
                                    <div className="flex-2">{x.customerTitle}</div>
                                    <div className="flex-2">{x.invoiceDate}</div>
                                    <div className="flex-2">{x.time}</div>
                                    <div className="flex-2">
                                        <div className="fn-bar">
                                            <div className="btn btn-small btn-border" onClick={(e) => openModalReview(x.reqFrmId, x.customerName, x.personInChargeName, x.companyName, x.invoiceDate)}  >
                                                <span>查看</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceReviewListPM;
