import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_ProductHome,
    API_GET_AdNameList,
    API_GET_SupplierNameList,
    API_POST_ProductData,
    API_GET_ProductType,
    API_PUT_ProductType,
    API_POST_ProductType,
    API_DEL_ProductType,
    API_GET_ProdType,
    API_GET_HomeData
} from "../../global/constants";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, InitJS, SetOnbeforeunload, MenuSet, SetHasChange } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const ProductNew = () => {
    var history = useHistory();

    useEffect(() => {
        //把menu顏色置換
        MenuSet("Product");
        //第一次近來先執行抓取product資料
        getData();
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload("是否放棄新增此產品");
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    const [hasReviewAuth, setHasReviewAuth] = useState(false);
    const [reGetHomeData, setReGetHomeData] = useState(false);

    function getData() {
        getHomeData();
        getProductHone();
    }

    function getHomeData(setReady = null) {
        API_GET_HomeData({ fromOkta: 0 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code

                console.log(data);

                let authDatas = data.result.authdata;

                let authIndex = authDatas.findIndex((x) => x.funcListID === 9);

                if (authIndex == -1) {
                    setHasReviewAuth(false);
                }
                else {
                    setHasReviewAuth(true);
                }

                if (setReady != null) {
                    setReady(true);
                }

            })
    }

    function getProductHone() {
        API_GET_ProductHome({})
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setProductData(data.result.productTypeArray);
                setProductClassArray(data.result.productClassArray);
                setCompanyArray(data.result.companyArray);
                setAccounting(data.result.accounting);
                setAccountingBranch(data.result.accountingBranch);
                setAccountingProductType(data.result.accountingProductType);
                setDepartmentArray(data.result.departmentArray);
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    useEffect(() => {
        if (reGetHomeData == true) {
            getHomeData();
            setReGetHomeData(false);
        }
    }, [reGetHomeData])



    //新增修改的modal文字
    const [modalStr, setModalStr] = useState("");
    //刪除失敗的modal文字
    const [modalDelStr, setModalDelStr] = useState("");
    //產品別
    const [productData, setProductData] = useState([{}]);
    const [prodTPID, setProductTPID] = useState();
    function ProductTPIDChange(id, name) {
        setProductTPID(id);
        setProductTPName(name);
        setProductName(name);
        setProductFullName(name);
        //setProductTPIDError(false);
        //setProductNameError(false);
        //setProductFullNameError(false);
        API_GET_ProdType({
            prodTPID: id,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setActPID(data.result.data.id);
                }
            })
    }
    const [prodTPName, setProductTPName] = useState();
    //產品細項總稱
    const [productName, setProductName] = useState("");
    function productNameChange(e) {
        //setProductNameError(false);


        if (productNameError) {
            setProductName("");
            productFullNameChange("");
        }

        //先算細項總稱數量
        var p1 = prodTPName.length;
        //變更的文字數量有>總稱
        if (e.target.value.length >= p1) {
            //如果前面一樣才可以讓他異動
            if (e.target.value.substr(0, p1) == prodTPName) {
                setProductName(e.target.value);
                setProductFullName(e.target.value);
            }
        }
    }
    //產品細項
    const [productFullName, setProductFullName] = useState("");
    function productFullNameChange(v) {
        //setProductFullNameError(false);
        //先算細項總稱數量
        var p1 = productName.length;
        //變更的文字數量有>總稱
        if (v.length >= p1) {
            //如果前面一樣才可以讓他異動
            if (v.substr(0, p1) == productName) {
                setProductFullName(v);
            }
        }
    }
    //產品類
    const [productClassArray, setProductClassArray] = useState([{}]);
    const [prodCLID, setProdCLID] = useState();
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyIdArray, setCompanyIdArray] = useState([]);
    //會計科目
    const [accounting, setAccounting] = useState([{}]);
    const [actID, setActID] = useState();
    //會計子目
    const [accountingBranch, setAccountingBranch] = useState([{}]);
    const [actCID, setActCID] = useState();
    //會計產品別
    const [accountingProductType, setAccountingProductType] = useState([{}]);
    const [actPID, setActPID] = useState();
    function actPIDChange(e) {
        setActPID(e.target.value);
        //setActPIDError(false);
    }
    //列入產品成本
    const [productInCost, setProductInCost] = useState(true);
    //產品負責人
    const [adNameArray, setAdNameArray] = useState([{}]);
    const [adName, setAdName] = useState("");
    const [adID, setAdID] = useState("");
    function adNameChange(e) {
        setAdNameError(false);
        if (e.target.value == null || e.target.value == '') {
            setAdName("");
            setAdID(null);
        } else {
            getAdName();
        }

    }
    function getAdName() {
        API_GET_AdNameList({
            input: document.getElementById("adNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setAdNameArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //產品負責單位
    const [departmentArray, setDepartmentArray] = useState([{}]);
    const [cmpDivID, setCmpDivID] = useState();
    //供應商名稱
    const [supplierName, setSupplierName] = useState("");
    function supplierNameChange(e) {
        //setSupplierNameError(false);
        setSupplierName(e.target.value);
        getSupplierName();
    }
    //供應商名稱datalist
    const [supplierNameArray, setSupplierNameArray] = useState([{}]);
    function getSupplierName(e) {

        API_GET_SupplierNameList({
            input: document.getElementById("supplierNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setSupplierNameArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //Error宣告
    const [prodTPIDError, setProductTPIDError] = useState(false);
    const [productNameError, setProductNameError] = useState(false);
    const [productFullNameError, setProductFullNameError] = useState(false);
    const [prodCLIDError, setProdCLIDError] = useState(false);
    const [actIDError, setActIDError] = useState(false);
    const [actCIDError, setActCIDError] = useState(false);
    const [actPIDError, setActPIDError] = useState(false);
    const [companyIdError, setCompanyIdError] = useState(false);
    const [supplierNameError, setSupplierNameError] = useState(false);
    const [adNameError, setAdNameError] = useState(false);
    const [productInCostError, setProductInCostError] = useState(false);
    const [cmpDivIDError, setCmpDivIDError] = useState(false);

    //執行新增產品
    function AddProduct(e) {
        e.target.classList.add("disabled");

        var isOk = 1;
        //產品別
        if (prodTPID == null || prodTPID == "") {
            setProductTPIDError(true);
            isOk = 0;
        } else {
            setProductTPIDError(false);
        }
        //產品細項總稱
        if (productName == null || productName == "" || productName == "不可空白") {
            setProductNameError(true);
            //setProductName("不可空白");
            isOk = 0;
        } else {
            setProductNameError(false);
        }
        //產品細項
        if (productFullName == null || productFullName == "" || productFullName == "不可空白") {
            setProductFullNameError(true);
            //setProductFullName("不可空白");
            isOk = 0;
        } else {
            setProductFullNameError(false);
        }
        //產品類
        if (prodCLID == null || prodCLID == "") {
            setProdCLIDError(true);
            isOk = 0;
        } else {
            setProdCLIDError(false);
        }

        //隸屬公司
        if (companyIdArray.length == 0) {
            setCompanyIdError(true);
            isOk = 0;
        } else {
            setCompanyIdError(false);
        }
        //產品負責單位
        if (cmpDivID == null || cmpDivID == "") {
            setCmpDivIDError(true);
            isOk = 0;
        } else {
            setCmpDivIDError(false);
        }

        //供應商名稱
        if (supplierName == null || supplierName == "" || supplierName == "不可空白") {
            setSupplierNameError(true);
            //setSupplierName("不可空白");
            isOk = 0;
        } else {
            setSupplierNameError(false);
        }

        //產品負責人
        //if (adName == null || adName == "" || adName == "不可空白") {
        //    setAdNameError(true);
        //    //setSupplierName("不可空白");
        //    isOk = 0;
        //} else {
        //    setAdNameError(false);
        //}
        //會計科目
        if (actID == null || actID == "") {
            setActIDError(true);
            isOk = 0;
        } else {
            setActIDError(false);
        }


        //會計子目
        if (actCID == null || actCID == "") {
            setActCIDError(true);
            isOk = 0;
        } else {
            setActCIDError(false);
        }
        //會計產品別
        if (actPID == null || actPID == "") {
            setActPIDError(true);
            isOk = 0;
        } else {
            setActPIDError(false);
        }

        if (isOk == 1) {
            var dt = {
                prodTPID: parseInt(prodTPID),
                productName: productName,
                prevProductName: productName,
                productFullName: productFullName,
                prevProductFullName: productFullName,
                prodCLID: parseInt(prodCLID),
                companyIdArray: companyIdArray,
                venName: supplierName,
                personADName: adName,
                PersonADID: adID == "" ? null : parseInt(adID),
                cmpDivID: parseInt(cmpDivID),
                actID: parseInt(actID),
                actCID: parseInt(actCID),
                actPID: parseInt(actPID),
                productInCost: productInCost
            };

            API_POST_ProductData(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setModalStr(data.message);
                    if (data.code == "0000" || data.code == "0001") {
                        e.target.classList.remove("disabled");
                        OpenModal("modal-add");
                        window.onbeforeunload = null;
                        SetHasChange(false);
                    } else {
                        e.target.classList.remove("disabled");
                        OpenModal("modal-error", 2);
                    }
                })
                .catch((err) => {

                    e.target.classList.remove("disabled");
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            e.target.classList.remove("disabled");
        }

    }
    //新增產品別
    const [addProductType, setAddProductType] = useState("");
    function addProductTypeChange(e) {
        if (document.getElementById("addProductTypeDiv").classList.contains("error")) {
            setAddProductType("");
        }
        setAddProductType(e.target.value);
    }
    function addProductTypeClick(e) {
        if (document.getElementById("addProductTypeDiv").classList.contains("error")) {
            setAddProductType("");
        }
    }
    const [addProductInvoice, setAddProductInvoice] = useState("");
    function addProductInvoiceChange(e) {

        setAddProductInvoice(e.target.value);
    }

    const [addProductReady, setAddProductReady] = useState(false);

    //執行新增產品別
    function AddProductType() {
        getHomeData(setAddProductReady);
    }

    useEffect(() => {
        if (addProductReady == false) {
            return;
        }

        setAddProductReady(false);

        if (hasReviewAuth == false) {
            setModalStr("操作失敗，沒有檢核權限");
            OpenModal("modal-error");
            CloseModal("modal-4");
            return;
        }

        var isOk = 1;
        if (addProductType == "" || addProductType == "不可空白") {
            document.getElementById("addProductTypeDiv").classList.add("error");
            setError(true);
            isOk = 0;
        } else {
            document.getElementById("addProductTypeDiv").classList.remove("error");
        }
        if (addProductInvoice == "" || addProductInvoice == "不可空白") {
            document.getElementById("addProductInvoiceDiv").classList.add("error");
            setAddProductInvoice("不可空白");
            isOk = 0;
        } else {
            document.getElementById("addProductInvoiceDiv").classList.remove("error");
        }
        if (isOk == 1) {
            API_POST_ProductType({
                ProdTPName: addProductType,
                ProdTPInvName: addProductInvoice,
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        //代表有完成新增
                        getData();
                        CloseModal("modal-4");
                        setModalStr("產品別新增完成");
                        OpenModal("modal-3")
                    } else if (data.code == "2004") {
                        document.getElementById("addProductTypeDiv").classList.add("error");
                        setAddProductType(addProductType + "(" + data.message + ")");
                    } else {
                        CloseModal("");
                        OpenModal("modal-error", 2)
                        setModalStr("新增失敗(" + data.code + ")" + data.message);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }, [addProductReady])

    //打開編輯產品別
    function openModal5(e) {
        OpenModal("modal-5");

        var prodTPID = e.target.dataset.id;
        document.getElementById("editProductTypeId").value = prodTPID;

        API_GET_ProductType({
            prodTPID: parseInt(prodTPID),
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                //寫入產品別跟發票到編輯產品別model的text
                if (data.result.data != null) {
                    setEditProductType(data.result.data[0].prodTPName);
                    setEditProductInvoice(data.result.data[0].prodTPInvName);
                    //避免有error class 抓到資料就先取消error樣式
                    document.getElementById("editProductTypeDiv").classList.remove("error");
                    document.getElementById("editProductInvoiceDiv").classList.remove("error");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        e.preventDefault();
    }
    //修改產品別
    const [editProductType, setEditProductType] = useState("");
    function editProductTypeChange(e) {
        if (document.getElementById("editProductTypeDiv").classList.contains("error")) {
            setEditProductType("");
        }
        setEditProductType(e.target.value);
    }
    function editProductTypeClick(e) {
        if (document.getElementById("editProductTypeDiv").classList.contains("error")) {
            setEditProductType("");
        }
    }
    const [editProductInvoice, setEditProductInvoice] = useState("");
    function editProductInvoiceChange(e) {

        setEditProductInvoice(e.target.value);
    }

    const [editProductReady, setEditProductReady] = useState(false);
    //執行修改產品別
    function EditProductType() {
        getHomeData(setEditProductReady);
    }

    useEffect(() => {
        if (editProductReady == false) {
            return;
        }

        setEditProductReady(false);

        if (hasReviewAuth == false) {
            setModalStr("操作失敗，沒有檢核權限");
            OpenModal("modal-error");
            CloseModal("modal-5");
            return;
        }

        var isOk = 1;
        if (editProductType == "" || editProductType == "不可空白") {
            document.getElementById("editProductTypeDiv").classList.add("error");
            setEditProductType("不可空白");
            isOk = 0;
        } else {
            document.getElementById("editProductTypeDiv").classList.remove("error");
        }
        if (editProductInvoice == "" || editProductInvoice == "不可空白") {
            document.getElementById("editProductInvoiceDiv").classList.add("error");
            setEditProductInvoice("不可空白");
            isOk = 0;
        } else {
            document
                .getElementById("editProductInvoiceDiv")
                .classList.remove("error");
        }
        if (document.getElementById("editProductTypeId").value == "") {
            isOk = 0;
        }


        if (isOk == 1) {
            var dt = {
                ProdTPID: parseInt(document.getElementById("editProductTypeId").value),
                ProdTPName: editProductType,
                ProdTPInvName: editProductInvoice,
            }

            API_PUT_ProductType(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        //代表有完成修改
                        getData();
                        CloseModal("modal-5");
                        setModalStr("產品別修改完成");
                        OpenModal("modal-3")
                    } else if (data.code == "2004") {
                        document.getElementById("editProductTypeDiv").classList.add("error");
                        setEditProductType(addProductType + "(" + data.message + ")");
                    } else {
                        setModalStr("修改失敗(" + data.code + ")" + data.message);
                        OpenModal("modal-error , 2");
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }, [editProductReady])
    
    function companyIdArrayChange(companyId) {
        //setCompanyIdError(false);
        if (companyIdArray.indexOf(companyId) >= 0) {
            setCompanyIdArray(function (prev) {
                return prev.filter(item => item != companyId);
            });
        }
        else {
            setCompanyIdArray(function (prev) {
                return [...prev, companyId];
            });
        }
    }

    const [delProductReady, setDelProductReady] = useState(false);

    //要刪除產品別 先跳提示訊息
    function DelProductType(e) {
        getHomeData(setDelProductReady);
    }

    useEffect(() => {
        if (delProductReady == false) {
            return;
        }

        setEditProductReady(false);

        CloseModal("modal-5");
        if (hasReviewAuth) {
            OpenModal("modal-1");
        }
        else {
            setModalStr("操作失敗，沒有檢核權限");
            OpenModal("modal-error");
        }

    }, [delProductReady])

    function ConfirmDelProductType(e) {
        var ProdTPID = document.getElementById("editProductTypeId").value;
        if (ProdTPID != "") {

            API_DEL_ProductType({
                ProdTPID: parseInt(ProdTPID),
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    //不管失敗或成功 都關閉提示的modal
                    CloseModal("modal-1");
                    if (data.code == "0000") {
                        setModalDelStr("產品別刪除成功");
                    } else {
                        setModalDelStr(data.message);
                    }
                    getData();
                    OpenModal("modal-2");
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }
    function reflash() {
        window.location.reload();
    }
    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">新增產品資料</h3>
                </div>
            </div>
            <div className="container">
                <div className="form-container">
                    <div className={`form-item must half ${prodTPIDError ? 'show-help error' : ''}`}>
                        <div className="field">產品別</div>
                        <div className="value">
                            <div className="dropmenu-container" data-dropmenu>
                                <div className="dropmenu-value" onClick={() => setReGetHomeData(true)}>請選擇</div>
                                <div className="dropmenu">
                                    <ul>
                                        <li className="option">
                                            <div data-id="" data-value="" onClick={()=>setProductTPID(null) }>
                                                請選擇
                                            </div>
                                        </li>
                                        {productData.map((x) => (
                                            <li className="option">
                                                <div key={x.id} data-value={x.name} onClick={() => ProductTPIDChange(x.id, x.name)} >
                                                    {x.name}
                                                </div>
                                                {hasReviewAuth == false ? null : (
                                                    <div className="fn" onClick={(e) => {
                                                        openModal5(e);
                                                    }} data-id={x.id}>
                                                        <i className="icon icon-39"></i>
                                                    </div>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                    {hasReviewAuth == false ? null : (
                                        <div className="additional" onClick={() => OpenModal("modal-4")}>
                                            <a href="#">新增產品別</a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <span className="help-word">請選擇產品別</span>
                    </div>
                    <div className={`form-item must half ${productNameError ? 'show-help error' : ''}`}>
                        <div className="field">產品細項總稱</div>
                        <div className="value">
                            <input id="productName" type="text" onChange={productNameChange} value={productName} />
                        </div>
                        <span className="help-word">
                            請選擇產品細項總稱
                        </span>
                    </div>
                    <div className={`form-item must half ${productFullNameError ? 'show-help error' : ''}`}>
                        <div className="field">產品細項</div>
                        <div className="value">
                            <input type="text" onChange={(e) => productFullNameChange(e.target.value)} value={productFullName} />
                        </div>
                        <span className="help-word">
                            請輸入產品細項
                        </span>
                    </div>

                    <div className={`form-item must half ${prodCLIDError ? 'show-help' : ''}`}>
                        <div className="field">產品類</div>
                        <div className="value">
                            {productClassArray.map((x) => (
                                <div>
                                    <input type="radio" id={`f621-${x.id}`} name="f621" checked={prodCLID == x.id ? true : false} onClick={(e) => { setProdCLID(x.id); }} />
                                    <label htmlFor={`f621-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">
                            請選擇產品類
                        </span>
                    </div>
                    <div className={`form-item must ${companyIdError ? 'show-help' : ''}`}>
                        <div className="field">隸屬公司</div>
                        <div className="value">
                            {companyArray.map((x) => (
                                <div>
                                    <input type="checkbox" id={`f622-${x.id}`} name="f622"
                                        checked={companyIdArray.findIndex((e) => e == x.id) >= 0 ? true : false}
                                        onClick={() => companyIdArrayChange(x.id)}
                                    />
                                    <label htmlFor={`f622-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇隸屬公司</span>
                    </div>

                    <div className={`form-item must half ${supplierNameError ? 'show-help error' : ''} has-datalist`} data-datalist>
                        <div className="field">供應商名稱</div>
                        <div className="value">
                            <input id="supplierNameText" type="text" onChange={supplierNameChange} value={supplierName} />
                            <ul className="datalist scrollbar">
                                {supplierNameArray.map((x) => (
                                    <li data-value={x.name} onClick={(e) => { setSupplierName(x.name); }} >
                                        <span className="flex-10 bold">{x.name}</span>
                                        {/*<span className="flex-2">12345678</span>*/}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">
                            請輸入供應商名稱
                        </span>
                    </div>
                    <div class="form-item half"></div>

                    <div className="form-item half has-datalist" data-datalist>
                        <div className="field">產品負責人</div>
                        <div className="value">
                            <input id="adNameText" type="text" onChange={adNameChange} autoComplete="off" />
                            <ul className="datalist scrollbar">
                                {adNameArray.map((x) => (
                                    <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => { setAdName(x.enName); setAdID(x.id); }} >
                                        <span className="flex-4">{x.enName}({x.tc_name})</span>
                                        <span className="flex-5">{x.mail}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">
                            請輸入正確格式
                        </span>
                    </div>

                    <div className={`form-item must half ${cmpDivIDError ? 'show-help' : ''}`}>
                        <div className="field">產品負責單位</div>
                        <div className="value">
                            {departmentArray.map((x) => (
                                <div>
                                    <input type="radio" id={`f623-${x.id}`} name="f623" checked={cmpDivID == x.id ? true : false} onClick={() => { setCmpDivID(x.id); }} />
                                    <label htmlFor={`f623-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">
                            請選擇產品負責單位
                        </span>
                    </div>

                    <hr />

                    <div className={`form-item must half ${actIDError ? 'show-help' : ''}`}>
                        <div className="field">會計科目</div>
                        <div className="value">
                            {accounting.map((x) => (
                                <div>
                                    <input type="radio" id={`f624-${x.id}`} name="f624" checked={actID == x.id ? true : false} onClick={() => { setActID(x.id); }} />
                                    <label htmlFor={`f624-${x.id}`}>
                                        {x.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">
                            請選擇會計科目
                        </span>
                    </div>

                    <div className={`form-item must half ${productInCostError ? 'show-help' : ''}`}>
                        <div className="field">列入產品成本</div>
                        <div className="value">
                            <div>
                                <input type="radio" id="f625-1" name="f625" checked={productInCost ? true : false} onClick={() => setProductInCost(true)} />
                                <label htmlFor="f625-1">是</label>
                            </div>
                            <div>
                                <input type="radio" id="f625-2" name="f625" checked={productInCost == false ? true : false} onClick={() => setProductInCost(false)} />
                                <label htmlFor="f625-2">否</label>
                            </div>
                        </div>
                        <span className="help-word">
                            請輸入列入產品成本
                        </span>
                    </div>

                    <div className={`form-item must half ${actCIDError ? 'show-help' : ''}`}>
                        <div className="field">會計子目</div>
                        <div className="value">
                            <select onChange={(e) => { setActCID(e.target.value); }}>
                                <option value="" selected disabled>請選擇會計子目</option>
                                {accountingBranch.map((x) => (
                                    <option value={x.id}>
                                        {x.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <span className="help-word">
                            請選擇會計子目
                        </span>
                    </div>

                    <div className={`form-item must half ${actPIDError ? 'show-help' : ''}`}>
                        <div className="field">會計產品別</div>
                        <div className="value">
                            <select onChange={actPIDChange}>
                                <option value="">請選擇會計產品別</option>
                                {
                                    accountingProductType == null || accountingProductType.length == 0 ? null :
                                        accountingProductType.map((x) => (
                                            <option value={x.id} selected={actPID == x.id ? true : false}>
                                                {x.name}
                                            </option>
                                        ))}
                            </select>
                        </div>
                        <span className="help-word">
                            請選擇會計產品別
                        </span>
                    </div>

                    <div className="form-item btn-area sp">
                        <div className="left"></div>
                        <div className="right">
                            <a className="btn btn-border" onClick={() => history.push("/Main?path=Product")}>
                                <span>取消</span>
                            </a>
                            <a className={`btn`} onClick={AddProduct}>
                                <span>新增</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-add" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={reflash}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error", 2)}>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-1" className="modal small alert" data-modal  >
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確定要刪除這產品別？</div>
                        <div className="btn-holder sp">
                            <button className="btn btn-border" onClick={() => CloseModal("modal-1")}>
                                <span>取消</span>
                            </button>
                            <a className="btn" onClick={ConfirmDelProductType}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-2" className="modal small alert" data-modal  >
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalDelStr}</div>
                        <div className="btn-holder">
                            <button className="btn" onClick={() => CloseModal("modal-2")}>
                                {" "}
                                <span>確認</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-3" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-3")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-4" className="modal" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">新增產品別</h3>
                        <span className="close" onClick={() => CloseModal("modal-4")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-container">
                            <div className="form-item must" id="addProductTypeDiv">
                                <div className="field">產品別</div>
                                <div className="value">
                                    <input
                                        type="text"
                                        id="addProductType"
                                        value={addProductType}
                                        onChange={addProductTypeChange}
                                        onClick={addProductTypeClick}
                                    />
                                </div>
                                <span className="help-word">已被使用，無法刪除</span>
                            </div>

                            <div className="form-item must" id="addProductInvoiceDiv">
                                <div className="field">會計產品別</div>
                                <div className="value">
                                    <input
                                        type="text"
                                        id="addProductInvoice"
                                        value={addProductInvoice}
                                        onChange={addProductInvoiceChange}
                                    />
                                </div>
                                <span className="help-word">
                                    xxx
                                </span>
                            </div>
                            <div className="form-item btn-area">
                                <a className="btn btn-border" onClick={() => CloseModal("modal-4")}>
                                    <span>取消</span>
                                </a>
                                <a className={`btn`} onClick={AddProductType}>
                                    <span>新增</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-5" className="modal" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">編輯產品別</h3>
                        <span className="close" onClick={() => CloseModal("modal-5")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-container">
                            <div className="form-item must" id="editProductTypeDiv">
                                <div className="field">產品別</div>
                                <div className="value">
                                    <input type="hidden" id="editProductTypeId" />
                                    <input
                                        type="text"
                                        id="editProductType"
                                        value={editProductType}
                                        onChange={editProductTypeChange}
                                        onClick={editProductTypeClick}
                                    />
                                </div>
                                <span className="help-word">已被使用，無法刪除</span>
                            </div>

                            <div className="form-item must" id="editProductInvoiceDiv">
                                <div className="field">會計產品別</div>
                                <div className="value">
                                    <input
                                        type="text"
                                        id="editProductInvoice"
                                        value={editProductInvoice}
                                        onChange={editProductInvoiceChange}
                                    />
                                </div>
                                <span className="help-word">
                                    加上 show-help error 樣式來呈現
                                </span>
                            </div>
                            <div className="form-item btn-area">
                                <a className="btn btn-border" onClick={DelProductType}>
                                    <span>刪除產品別</span>
                                </a>
                                <a className="btn" onClick={EditProductType}>
                                    <span>確定修改</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductNew;
