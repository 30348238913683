import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_HomeData,
    API_GET_CloudAcntHome,
    API_GET_Customer3Name,
    API_POST_CloudAcntList1,
    API_GET_AdNameList,
    API_GET_CloudAcntCustPersonInCharge,
    API_GET_CloudAcntSalesGetCustomer
} from "../../global/constants";
import { NumFormart, CloseModal, OpenModal, InitJS, MenuSet } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const CloudAcntQry = () => {
    var history = useHistory();
    const [modalStr, setModalStr] = useState();

    useEffect(() => {
        //把menu顏色置換
        MenuSet("CloudAcntQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    function getData() {
        API_GET_CloudAcntHome()
            .then((response) => response.data)
            .then((data) => {
                console.log("雲端帳號主要內容", data.result.data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setHasAuditPriv(data.result.data.hasAuditPriv);
                    //如果不是檢核權限  負責業務就是自己
                    if (data.result.data.hasAuditPriv != 1) {
                        setPersonADID(data.result.data.myId);
                        setPersonADName(data.result.data.myName);
                    }
                    //負責公司
                    setCompanyArray(data.result.data.companyArray);
                    //細項總稱
                    setProductName1Array(data.result.data.productName1Array);
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    const [hasReviewPermission, setHasReviewPermission] = useState(false);
    const [selfUserADID, setSelfUserADID] = useState();
    const [getHomeDataDone, setGetHomeDataDone] = useState(false);
    function getHomeData() {
        API_GET_HomeData({ fromOkta: 0 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                let authdatas = data.result.authdata;
                if (authdatas != null) {
                    let target = authdatas.find(x => x.funcListID == 17);
                    if (target != null) {
                        setHasReviewPermission(true);
                    }
                }

                let user = data.result.user;
                if (user != null) {
                    setSelfUserADID(user.userADID);
                }

                setGetHomeDataDone(true);
            })
    }

    //是否有檢核權限
    const [hasAuditPriv, setHasAuditPriv] = useState(0);

    //隸屬公司
    const [companyId, setCompanyId] = useState(null);
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyError, setCompanyError] = useState(false);

    //客戶別
    const [custID, setCustID] = useState();
    const [custName, setCustName] = useState("");
    const [custNameArray, setCustNameArray] = useState([{}]);
    function custNameChange(e) {
        if (e.target.value == null || e.target.value == "") {
            setCustID(null);
            setCustName("");
            setCustNameArray([]);
            setCustTaxID("");
            setCustomerTitleArray([]);
            setCustTitle("");
            setCustomerTaxArray([]);
            if (hasAuditPriv == 1) {
                setPersonADName("");
                setPersonADID(null);
            }
        } else {
            setCustName(e.target.value);
            getCustomer3Name(1);
        }
    }

    //客戶抬頭
    const [custTitle, setCustTitle] = useState("");
    const [custTitleArray, setCustomerTitleArray] = useState([{}]);
    function custTitleChange(e) {
        if (e.target.value == null || e.target.value == "") {
            setCustID(null);
            setCustName("");
            setCustNameArray([]);
            setCustTaxID("");
            setCustomerTitleArray([]);
            setCustTitle("");
            setCustomerTaxArray([]);
            if (hasAuditPriv == 1) {
                setPersonADName("");
                setPersonADID(null);
            }
        } else {
            setCustTitle(e.target.value);
            getCustomer3Name(2);
        }
    }
    //客戶統編

    const [custTaxID, setCustTaxID] = useState("");
    const [custTaxIDArray, setCustomerTaxArray] = useState([{}]);
    function custTaxIDChange(e) {
        setCustTaxID(e.target.value);
        if (e.target.value == null || e.target.value == "") {
            setCustID(null);
            setCustName("");
            setCustNameArray([]);
            setCustTaxID("");
            setCustomerTitleArray([]);
            setCustTitle("");
            setCustomerTaxArray([]);
            if (hasAuditPriv == 1) {
                setPersonADName("");
                setPersonADID(null);
            }
        } else {
            setCustTaxID(e.target.value);
            getCustomer3Name(3);
        }
    }

    const [personADNameArray, setPersonADNameArray] = useState([{}]);
    const [personADName, setPersonADName] = useState();
    const [personADID, setPersonADID] = useState();
    function personAdNameChange(e) {
        setPersonADName(e.target.value);
        getAdeName();
    }
    function getAdeName() {
        if (document.getElementById("adeNameText").value == "") {
            return false;
        }
        API_GET_AdNameList({
            input: document.getElementById("adeNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setPersonADNameArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }
    //const [qryList, setQryList] = useState([{}]);
    //雲端細項總稱
    const [productName1Array, setProductName1Array] = useState([{}]);
    const [productName1ArrayError, setProductName1ArrayError] = useState(false);

    //重新取得 客戶別 客戶別Title 客戶統編 List
    function getCustomer3Name(index) {
        var dt = {};
        if (index == 1) {
            dt = {
                companyId: companyId,
                customerName: document.getElementById("customerNameText").value,
                personInChadgeId: (personADID == null ? null : personADID),
                searchCloud: 1
            };
        } else if (index == 2) {
            dt = {
                companyId: companyId,
                customerTitle: document.getElementById("customerTitleText").value,
                personInChadgeId: (personADID == null ? null : personADID),
                searchCloud: 1
            };
        } else if (index == 3) {
            dt = {
                companyId: companyId,
                customerTaxId: document.getElementById("customerTaxIdText").value,
                personInChadgeId: (personADID == null ? null : personADID),
                searchCloud: 1
            };
        }
        API_GET_Customer3Name(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                var dataName = [];
                var dataTitle = [];
                var dataTaxId = [];

                if (data.result != null && data.result.data != null) {
                    if (index == 1) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataName.length; j++) {
                                if (dataName[j].custName == data.result.data[i].custName) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataName.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                    personADName: data.result.data[i].personADName,
                                    personADID: data.result.data[i].personADID,
                                });
                            }
                        }
                        setCustNameArray(dataName);
                    } else if (index == 2) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTitle.length; j++) {
                                if (dataTitle[j].custTitle == data.result.data[i].custTitle) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTitle.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                    personADName: data.result.data[i].personADName,
                                    personADID: data.result.data[i].personADID,
                                });
                            }
                        }
                        setCustomerTitleArray(dataTitle);
                    } else if (index == 3) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTaxId.length; j++) {
                                if (dataTaxId[j].custTaxID == data.result.data[i].custTaxID) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTaxId.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                    personADName: data.result.data[i].personADName,
                                    personADID: data.result.data[i].personADID,
                                });
                            }
                        }
                        setCustomerTaxArray(dataTaxId);
                    }
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //重新取得 客戶別 客戶別Title 客戶統編 List(未使用)
    function getCustomer3Name2(index) {
        var dt = {
            companyId: companyId,
            personInChargeId: personADID
        };

        API_GET_CloudAcntSalesGetCustomer(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                var dataName = [];
                var dataTitle = [];
                var dataTaxId = [];

                if (data.result != null && data.result.data != null) {
                    if (index == 1) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataName.length; j++) {
                                if (dataName[j].custName == data.result.data[i].customerName) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataName.push({
                                    custID: data.result.data[i].customerId,
                                    custName: data.result.data[i].customerName,
                                    custTitle: data.result.data[i].customerTitle,
                                    custTaxID: data.result.data[i].customerTaxId,
                                    personADName: personADName,
                                    personADID: personADID,
                                });
                            }
                        }
                        setCustNameArray(dataName);
                    } else if (index == 2) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTitle.length; j++) {
                                if (dataTitle[j].custTitle == data.result.data[i].customerTitle) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTitle.push({
                                    custID: data.result.data[i].customerId,
                                    custName: data.result.data[i].customerName,
                                    custTitle: data.result.data[i].customerTitle,
                                    custTaxID: data.result.data[i].customerTaxId,
                                    personADName: personADName,
                                    personADID: personADID,
                                });
                            }
                        }
                        setCustomerTitleArray(dataTitle);
                    } else if (index == 3) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTaxId.length; j++) {
                                if (dataTaxId[j].custTaxID == data.result.data[i].customerTaxId) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTaxId.push({
                                    custID: data.result.data[i].customerId,
                                    custName: data.result.data[i].customerName,
                                    custTitle: data.result.data[i].customerTitle,
                                    custTaxID: data.result.data[i].customerTaxId,
                                    personADName: personADName,
                                    personADID: personADID,
                                });
                            }
                        }
                        setCustomerTaxArray(dataTaxId);
                    }
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //雲端細項總項 全選功能
    function f112All(e) {
        var list = document.getElementsByName("f112");
        for (var i = 0; i < list.length; i++) {
            list[i].checked = document.getElementById("f112a").checked;
        }
    }
    //前往新增客戶
    function goCustomerNew() {
        window.open("/Main?path=CustomerNew", '_blank')
    }

    const [isSearchStart, setIsSearchStart] = useState(false);
    //查詢列表
    function openModal1(e) {
        getHomeData();
        setIsSearchStart(true);
    }

    function startSearch() {
        var tmpArray = [];
        var f111 = document.getElementsByName("f111");

        var result = true;

        for (var i = 0; i < f111.length; i++) {
            if (f111[i].checked) {
                tmpArray.push(parseInt(f111[i].value));
            }
        }
        if (tmpArray.length == 0) {
            setCompanyError(true);
            result = false;
        } else {
            setCompanyError(false);
        }

        var tmpArray2 = [];
        var pNameStr = "";
        var f112 = document.getElementsByName("f112");

        for (var i = 0; i < f112.length; i++) {
            if (f112[i].checked) {
                tmpArray2.push(f112[i].value);
                if (pNameStr == "") {
                    pNameStr = f112[i].value;
                } else {
                    pNameStr = pNameStr + "," + f112[i].value;
                }
            }

        }
        if (tmpArray2.length == 0) {
            setProductName1ArrayError(true);
            result = false;
        } else {
            setProductName1ArrayError(false);
        }

        if (result) {
            var p = "";
            if (custID == null) {
                //沒客戶 到11.1.1  (API-11-03)
                p = '/Main?path=CloudAcntList1' + '&companyId=' + companyId + '&productName=' + pNameStr;
                if (personADID != null) {
                    p = p + '&personInChargeId=' + personADID;
                }
                history.push(p);
            } else {
                //有客戶 到11.3  (API-11-02)
                p = '/Main?path=CloudAcntList2' + '&companyId=' + companyId + '&productName=' + pNameStr + '&customerId=' + custID;
                if (personADID != null) {
                    p = p + '&personInChargeId=' + personADID;
                }
                history.push(p);
            }
        }
    }

    useEffect(() => {
        if (isSearchStart && getHomeDataDone) {
            if (selfUserADID == personADID) {
                startSearch();
            }
            else {
                if (hasReviewPermission) {
                    startSearch();
                }
                else {
                    setModalStr("您目前權限為[業務]，只能查詢自己負責客戶的雲端帳號");
                    OpenModal("modal-error");
                }
            }

            setIsSearchStart(false);
            setGetHomeDataDone(false);

        }
    }, [isSearchStart, getHomeDataDone])

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">雲端帳號查詢</h3>
                </div>
            </div>
            <div className="container">
                <div className="form-container">
                    <div className={`form-item must ${companyError == true ? "show-help" : ""}`}>
                        <div className="field">隸屬公司</div>
                        <div className="value">
                            {companyArray.map((x, index) => (
                                <div key={index}>
                                    <input
                                        type="radio"
                                        id={`f111-${x.id}`}
                                        name="f111"
                                        value={x.id}
                                        onChange={() => setCompanyId(x.id)}
                                        checked={companyId == x.id ? true : false}
                                    />
                                    <label htmlFor={`f111-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <div className="help-word">請選擇隸屬公司</div>
                    </div>

                    <div className="form-item half  has-datalist" data-datalist>
                        <div className="field">客戶別</div>
                        <div className="value">
                            <input
                                id="customerNameText"
                                type="text"
                                value={custName}
                                onChange={custNameChange}
                                data-required
                            />
                            <ul className="datalist scrollbar">
                                {custNameArray.map((x, index) => (
                                    <li key={index}
                                        data-value={x.custName}
                                        onClick={(e) => {
                                            setCustName(x.custName),
                                                setCustTitle(x.custTitle),
                                                setCustTaxID(x.custTaxID),
                                                setCustID(x.custID),
                                                setPersonADName(x.personADName),
                                                setPersonADID(x.personADID);
                                        }}
                                    >
                                        <span className="flex-4 bold">{x.custName}</span>
                                        <span className="flex-3">{x.custTitle}</span>
                                        <span className="flex-3">{x.custTaxID}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <div className="form-item half"></div>

                    <div className="form-item half  has-datalist" data-datalist>
                        <div className="field">公司抬頭</div>
                        <div className="value">
                            <input
                                id="customerTitleText"
                                type="text"
                                onChange={custTitleChange}
                                value={custTitle}
                                data-required
                            />
                            <ul className="datalist scrollbar">
                                {custTitleArray.map((x, index) => (
                                    <li key={index}
                                        data-value={x.custTitle}
                                        onClick={(e) => {
                                            setCustName(x.custName),
                                                setCustTitle(x.custTitle),
                                                setCustTaxID(x.custTaxID),
                                                setCustID(x.custID),
                                                setPersonADName(x.personADName),
                                                setPersonADID(x.personADID);
                                        }}
                                    >
                                        <span className="flex-4 bold">{x.custName}</span>
                                        <span className="flex-3">{x.custTitle}</span>
                                        <span className="flex-3">{x.custTaxID}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item half  has-datalist" data-datalist>
                        <div className="field">公司統編/ 編號</div>
                        <div className="value">
                            <input
                                id="customerTaxIdText"
                                type="text"
                                onChange={custTaxIDChange}
                                value={custTaxID}
                            />
                            <ul className="datalist scrollbar">
                                {custTaxIDArray.map((x, index) => (
                                    <li key={index}
                                        data-value={x.custTaxID}
                                        onClick={(e) => {
                                            setCustName(x.custName),
                                                setCustTitle(x.custTitle),
                                                setCustTaxID(x.custTaxID),
                                                setCustID(x.custID),
                                                setPersonADName(x.personADName),
                                                setPersonADID(x.personADID);
                                        }}
                                    >
                                        <span className="flex-4 bold">{x.custName}</span>
                                        <span className="flex-3">{x.custTitle}</span>
                                        <span className="flex-3">{x.custTaxID}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item half has-datalist" data-datalist>
                        <div className="field">負責業務</div>
                        <div className="value">
                            {
                                hasAuditPriv != 1 ?
                                    //沒有檢核權限，只能自己且不能選
                                    <input type="text" value={personADName} disabled="disabled" /> :
                                    //有權限
                                    custID == null ?
                                        //如果沒選客戶 可以提供輸入框並搜尋(API_A)
                                        <>
                                            <input type="text" id="adeNameText" name="adeNameText" value={personADName} onChange={personAdNameChange} />
                                            <ul className="datalist scrollbar">
                                                {personADNameArray.map((x, index) =>
                                                    <li key={index}
                                                        data-value={`${x.enName}(${x.tc_name})`}
                                                        onClick={(e) => {
                                                            setPersonADID(x.id),
                                                                setPersonADName(x.enName + "(" + x.tc_name + ")")
                                                        }}
                                                    >
                                                        <span className="flex-4">{x.enName}({x.tc_name})</span>
                                                        <span className="flex-5">{x.mail}</span>

                                                    </li>
                                                )
                                                }
                                            </ul>
                                        </> :
                                        //如果有選客戶 直接帶入業務+ID 且不能選
                                        <input type="text" value={personADName} disabled="disabled" />
                            }

                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item half jcfe">
                        <a className="btn btn-border btn-middle" onClick={goCustomerNew}>
                            <span>新增客戶</span>
                        </a>
                    </div>
                    <hr />
                    <div
                        className={`form-item must aifs ${productName1ArrayError == true ? "show-help" : ""
                            }`}
                    >
                        <div className="field">雲端細項總稱</div>
                        <div className="value">
                            <div style={{ width: "100%", padding: "0px 0px 15px 0px" }}>
                                <input
                                    type="checkbox"
                                    id="f112a"
                                    name="f112a"
                                    value="f112a"
                                    onClick={f112All}

                                />
                                <label htmlFor="f112a">全選</label>
                            </div>
                            {productName1Array.map((x, i) => (

                                <div key={i} style={{ padding: "0px 0px 15px 0px" }}>
                                    <input
                                        type="checkbox"
                                        id={`f112-${i}`}
                                        name="f112"
                                        value={x.name}
                                    />
                                    <label htmlFor={`f112-${i}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <div className="help-word">請輸入雲端細項總項</div>
                    </div>
                    <hr />
                    <div className="form-item btn-area sp">
                        <div className="left"></div>
                        <div className="right">
                            <a className="btn" onClick={openModal1}>
                                <span>查詢</span>
                            </a>
                        </div>
                    </div>

                    <div id="modal-error" className="modal small alert" data-modal>
                        <div className="modal-container">
                            <div className="modal-body">
                                <div className="icon-holder">
                                    <i className="icon icon-41"></i>
                                </div>
                                <div className="text-holder">{modalStr}</div>
                                <div className="btn-holder">
                                    <a className="btn" onClick={() => location.reload()}>
                                        <span>確認</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CloudAcntQry;
