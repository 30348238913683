import React, { useState, useEffect } from "react";
import NotifyItem from "../Dashboard/NotifyItem";
import {
    API_GET_ProductReviewList,
    API_GET_CustomerReviewList,
    API_GET_VendorReviewList,
    API_GET_CloudAcntReviewList,
    API_GET_InvoiceReviewList,
    API_GET_HomeData,
    API_GET_AccChargeOffReviewListData,
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { CloseModal, InitJS, SetHasChange } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

import CustomerDetail from "./Customer/CustomerDetail";
import CustomerAgreeList from "./Customer/CustomerAgreeList";
import CustomerReviewList from "./Customer/CustomerReviewList";
import CustomerReviewListPM from "./Customer/CustomerReviewListPM";
import CustomerRevokeList from "./Customer/CustomerRevokeList";

import VendorDetail from "./Vendor/VendorDetail";
import VendorAgreeList from "./Vendor/VendorAgreeList";
import VendorReviewList from "./Vendor/VendorReviewList";
import VendorReviewListPM from "./Vendor/VendorReviewListPM";
import VendorRevokeList from "./Vendor/VendorRevokeList";

import ProductDetail from "./Product/ProductDetail";
import ProductAgreeList from "./Product/ProductAgreeList";
import ProductReviewList from "./Product/ProductReviewList";
import ProductReviewListPM from "./Product/ProductReviewListPM";
import ProductRevokeList from "./Product/ProductRevokeList";

import CloudAcntDetail from "./CloudAcnt/CloudAcntDetail";
import CloudAcntAgreeList from "./CloudAcnt/CloudAcntAgreeList";
import CloudAcntReviewList from "./CloudAcnt/CloudAcntReviewList";
import CloudAcntReviewListPM from "./CloudAcnt/CloudAcntReviewListPM";
import CloudAcntRevokeList from "./CloudAcnt/CloudAcntRevokeList";

import InvoiceDetail from "./Invoice/InvoiceDetail";
import InvoiceAgreeList from "./Invoice/InvoiceAgreeList";
import InvoiceReviewList from "./Invoice/InvoiceReviewList";
import InvoiceReviewListPM from "./Invoice/InvoiceReviewListPM";
import InvoiceRevokeList from "./Invoice/InvoiceRevokeList";

import AccountChargeOffReviewList from "./AccountChargeOff/AccountChargeOffReviewList";
import AccountChargeOffDetail from "./AccountChargeOff/AccountChargeOffDetail";
import AccChargeOffRevokeList from "./AccountChargeOff/AccountChargeOffRevokeList";
import AccChargeOffAgreeList from "./AccountChargeOff/AccountChargeOffAgreeList";
import AccountChargeOffReturn from "./AccountChargeOff/AccountChargeOffReturn";
import AccChargeOffIsRevokeList from "./AccountChargeOff/AccountChargeOffIsRevokeList";

const Notify = () => {
    var history = useHistory();
    const [invoiceReqFrmID, setInvoiceReqFrmID] = useState();
    const [productReqFrmID, setProductReqFrmID] = useState();
    const [customerReqFrmID, setCustomerReqFrmID] = useState();
    const [vendorReqFrmID, setVendorReqFrmID] = useState();
    const [custFormReviewId, setCustFormReviewId] = useState();
    const [notifyArray, setNotifyArray] = useState([]); //通知列表
    const [isCloudPM, setIsCloudPM] = useState(0); //是否為雲端帳號PM
    const [isCustPM, setIsCustPM] = useState(0); //是否為客戶維護PM
    const [isProductPM, setIsProductPM] = useState(0); //是否為產品維護PM
    const [isVendorPM, setIsVendorPM] = useState(0); //是否為供應商維護PM
    const [isInvoicePM, setIsInvoicePM] = useState(0); //是否為發票作廢PM
    const [modalStr, setModalStr] = useState();
    //發票作廢列表
    const [qryList4, setQryList4] = useState([{}]);
    const [qryList4_2, setQryList4_2] = useState([{}]);
    const [revokeList4, setRevokeList4] = useState([{}]);
    const [agreeList4, setAgreeList4] = useState([{}]);
    const [customerName, setCustomerName] = useState();
    const [personInChargeName, setPersonInChargeName] = useState();
    const [companyName, setCompanyName] = useState();
    const [invoiceDate, setInvoiceDate] = useState();
    //產品維護列表
    const [qryList6, setQryList6] = useState([{}]);
    const [qryList6_2, setQryList6_2] = useState([{}]);
    const [revokeList6, setRevokeList6] = useState([{}]);
    const [agreeList6, setAgreeList6] = useState([{}]);
    //客戶列表
    const [qryList7, setQryList7] = useState([{}]);
    const [qryList7_2, setQryList7_2] = useState([{}]);
    const [revokeList7, setRevokeList7] = useState([{}]);
    const [agreeList7, setAgreeList7] = useState([{}]);
    //供應商列表
    const [qryList8, setQryList8] = useState([{}]);
    const [qryList8_2, setQryList8_2] = useState([{}]);
    const [revokeList8, setRevokeList8] = useState([{}]);
    const [agreeList8, setAgreeList8] = useState([{}]);

    //雲端帳號列表
    const [qryList11, setQryList11] = useState([{}]);
    const [qryList12, setQryList12] = useState([{}]);
    const [revokeList11, setRevokeList11] = useState([{}]);
    const [agreeList11, setAgreeList11] = useState([{}]);
    const [productName, setProductName] = useState();
    //帳務沖銷列表&ID
    const [AccChargeReviewId, setAccChargeReviewId] = useState();
    const [AccChargeReturnId, setAccChargeReturnId] = useState();
    const [qryList5_1, setQryList5_1] = useState([{}]);
    const [qryList5_2, setQryList5_2] = useState([{}]);
    const [revokeList5, setRevokeList5] = useState([{}]);
    const [isRevokeList5, setIsRevokeList5] = useState([{}]);
    const [agreeList5, setAgreeList5] = useState([{}]);
    const [ReviewType, setReviewType] = useState('0'); //1:主管 3:出納

    //#region 初始載入
    useEffect(() => {
        //console.log(localStorage.getItem("hasManyADAcnt"));
        if (localStorage.getItem("hasManyADAcnt") != '1') {

            //抓取通知列表
            getData();
            //抓取審核產品維護列表資料
            getProduct();
            //抓取供應商維護列表資料
            getVendor();
            //抓取客戶維護列表資料
            getCustomer();
            //抓取雲端帳號維護列表資料
            getCloudAcnt();
            //抓發票作廢列表資料
            getInvoice();
            //抓取帳務沖銷資料
            getAccChargeOff();
        }
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    //#endregion

    //#region 取得通知列表
    function getData() {
        var fromOkta_ = (localStorage.getItem("post_logout_redirect_uri") != null ? 1 : 0);
        API_GET_HomeData({ fromOkta: fromOkta_ })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                console.log("通知列表", data.result);
                setIsCloudPM(data.result.isCloudPM);
                setIsCustPM(data.result.isCustPM);
                setIsProductPM(data.result.isProductPM);
                setIsVendorPM(data.result.isVendorPM);
                setIsInvoicePM(data.result.isInvoicePM);
                //console.log("通知列表", data.result.notify);
                setNotifyArray(data.result.notify);
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //#endregion

    //#region 取得產品維護列表
    function getProduct() {
        API_GET_ProductReviewList({})
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setQryList6(data.result.data);
                }
            })
        API_GET_ProductReviewList({ type: 2 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setRevokeList6(data.result.data);
                }
            })
        API_GET_ProductReviewList({ type: 3 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setAgreeList6(data.result.data);
                }
            })

        API_GET_ProductReviewList({ type: 4 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setQryList6_2(data.result.data);
                }
            })
    }
    //#endregion

    //#region 取得客戶列表
    function getCustomer() {
        API_GET_CustomerReviewList({})
            .then((response) => response.data)
            .then((data) => {
                //if (!checkAuthToken(data)) { history.push('/') };
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setQryList7(data.result.data);
                }
            })
        API_GET_CustomerReviewList({ type: 2 })
            .then((response) => response.data)
            .then((data) => {
                //if (!checkAuthToken(data)) { history.push('/') };
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setRevokeList7(data.result.data);
                }
            })
        API_GET_CustomerReviewList({ type: 3 })
            .then((response) => response.data)
            .then((data) => {
                //if (!checkAuthToken(data)) { history.push('/') };
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setAgreeList7(data.result.data);
                }
            })

        API_GET_CustomerReviewList({ type: 4 })
            .then((response) => response.data)
            .then((data) => {
                //if (!checkAuthToken(data)) { history.push('/') };
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setQryList7_2(data.result.data);
                }
            })
    }
    //#endregion

    //#region 取得供應商列表
    function getVendor() {
        API_GET_VendorReviewList({})
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setQryList8(data.result.data);
                }
            })

        API_GET_VendorReviewList({ type: 2 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setRevokeList8(data.result.data);
                }
            })
        API_GET_VendorReviewList({ type: 3 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setAgreeList8(data.result.data);
                }
            })

        API_GET_VendorReviewList({ type: 4 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setQryList8_2(data.result.data);
                }
            })
    }
    //#endregion

    //#region 取得雲端帳號維護列表
    function getCloudAcnt() {
        API_GET_CloudAcntReviewList({})
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setQryList11(data.result.data);
                }
            })

        API_GET_CloudAcntReviewList({ type: 2 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setRevokeList11(data.result.data);
                }
            })
        API_GET_CloudAcntReviewList({ type: 3 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setAgreeList11(data.result.data);
                }
            })
        API_GET_CloudAcntReviewList({ type: 4 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setQryList12(data.result.data);
                }
            })
    }
    //#endregion

    //#region 取得發票作廢列表
    function getInvoice() {
        API_GET_InvoiceReviewList({})
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setQryList4(data.result.data);
                }
            })

        API_GET_InvoiceReviewList({ type: 2 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setRevokeList4(data.result.data);
                }
            })

        API_GET_InvoiceReviewList({ type: 3 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    console.log("發票作廢通過", data.result.data);
                    setAgreeList4(data.result.data);
                }
            })

        API_GET_InvoiceReviewList({ type: 4 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    setQryList4_2(data.result.data);
                }
            })
    }
    //#endregion

    //#region 取得帳務沖銷列表
    function getAccChargeOff() {
        API_GET_AccChargeOffReviewListData({ type: 1 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    //console.log("第一審查列表", data.result.data);
                    setQryList5_1(data.result.data);
                }
            })
        API_GET_AccChargeOffReviewListData({ type: 2 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    //console.log("第二審查列表", data.result.data);
                    setQryList5_2(data.result.data);
                }
            })
        API_GET_AccChargeOffReviewListData({ userId: parseInt(localStorage.getItem('userID')), type: 3 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    //console.log("同意列表", data.result.data);
                    setAgreeList5(data.result.data);
                }
            })

        API_GET_AccChargeOffReviewListData({ userId: parseInt(localStorage.getItem('userID')), type: 4 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    //console.log("被退回列表", data.result.data);
                    setRevokeList5(data.result.data);
                }
            })
        API_GET_AccChargeOffReviewListData({ userId: parseInt(localStorage.getItem('userID')), type: 5 })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result != null) {
                    //console.log("被撤銷列表", data.result.data);
                    setIsRevokeList5(data.result.data);
                }
            })
    }
    //#endregion

    function reGetData() {
        SetHasChange(false);
        window.onbeforeunload = null;
        getData();
        //抓取審核產品維護列表資料
        getProduct();
        //抓取供應商維護列表資料
        getVendor();
        //抓取客戶維護列表資料
        getCustomer();
        //抓取雲端帳號維護列表資料
        getCloudAcnt();
        //抓取發票作廢列表資料
        getInvoice();
        //抓取帳務沖銷資料
        getAccChargeOff();
    }

    return (
        <>
            <section className={`title ${localStorage.getItem("hasManyADAcnt") == '1' ? "disabled" : ""}`}>
                <i className="icon icon-42"></i>
                <h3 className="fz-B">通知</h3>
            </section>
            <section>
                {
                    localStorage.getItem("hasManyADAcnt") == '1' ?
                        <></>
                        :
                        notifyArray.length === 0 ?
                            <> 無任何通知</>
                            :
                            notifyArray.map((x, index) => (
                                <NotifyItem
                                    key={index}
                                    nid={x.id}
                                    ntext={x.text}
                                    ncount={x.count}
                                    date={x.lastDate}
                                    setType={setReviewType}
                                    isCloudPM={isCloudPM}
                                    isCustPM={isCustPM}
                                    isProductPM={isProductPM}
                                    isVendorPM={isVendorPM}
                                    isInvoicePM={isInvoicePM}
                                />
                            ))
                }
            </section>

            {/*產品維護待審查列表*/}
            <ProductReviewList qryList={qryList6} setReqFrmID={setProductReqFrmID} isPM={isProductPM} />
            <ProductReviewListPM qryList={qryList6_2} setReqFrmID={setProductReqFrmID} />
            {/*產品維護撤銷列表*/}
            <ProductRevokeList revokeList={revokeList6} setReqFrmID={setProductReqFrmID} />
            <ProductAgreeList agreeList={agreeList6} />
            <ProductDetail reqFrmID={productReqFrmID} setModalStr={setModalStr} reGetData={reGetData} />

            <CustomerReviewList qryList={qryList7} setReqFrmID={setCustomerReqFrmID} isPM={isCustPM} />
            <CustomerReviewListPM qryList={qryList7_2} setReqFrmID={setCustomerReqFrmID} />
            <CustomerRevokeList revokeList={revokeList7} setReqFrmID={setCustomerReqFrmID} />
            <CustomerAgreeList agreeList={agreeList7} />
            <CustomerDetail reqFrmID={customerReqFrmID} setModalStr={setModalStr} reGetData={reGetData} />

            <VendorReviewList qryList={qryList8} setReqFrmID={setVendorReqFrmID} isPM={isVendorPM} />
            <VendorReviewListPM qryList={qryList8_2} setReqFrmID={setVendorReqFrmID} />
            <VendorRevokeList revokeList={revokeList8} setReqFrmID={setVendorReqFrmID} />
            <VendorAgreeList agreeList={agreeList8} />
            <VendorDetail reqFrmID={vendorReqFrmID} setModalStr={setModalStr} reGetData={reGetData} />

            {/*雲端帳號待審查列表*/}
            <CloudAcntReviewList qryList={qryList11} setReqFrmID={setCustFormReviewId} setProductName={setProductName} isPM={isCloudPM} />
            <CloudAcntReviewListPM qryList={qryList12} setReqFrmID={setCustFormReviewId} setProductName={setProductName} />
            {/*雲端帳號撤銷列表*/}
            <CloudAcntRevokeList revokeList={revokeList11} setReqFrmID={setCustFormReviewId} />
            {/*雲端帳號同意列表*/}
            <CloudAcntAgreeList agreeList={agreeList11} />
            {/*雲端帳號詳細內容*/}
            <CloudAcntDetail reqFrmID={custFormReviewId} productName1={productName} setModalStr={setModalStr} reGetData={reGetData} />

            <InvoiceReviewList qryList={qryList4}
                setCustomerName={setCustomerName}
                setPersonInChargeName={setPersonInChargeName}
                setCompanyName={setCompanyName}
                setInvoiceDate={setInvoiceDate}
                setReqFrmID={setInvoiceReqFrmID}
                isPM={isInvoicePM}
            />

            <InvoiceReviewListPM qryList={qryList4_2}
                setCustomerName={setCustomerName}
                setPersonInChargeName={setPersonInChargeName}
                setCompanyName={setCompanyName}
                setInvoiceDate={setInvoiceDate}
                setReqFrmID={setInvoiceReqFrmID}
            />
            <InvoiceRevokeList revokeList={revokeList4}
                setCustomerName={setCustomerName}
                setPersonInChargeName={setPersonInChargeName}
                setCompanyName={setCompanyName}
                setInvoiceDate={setInvoiceDate}
                setReqFrmID={setInvoiceReqFrmID}
            />
            <InvoiceAgreeList
                agreeList={agreeList4}
                setCustomerName={setCustomerName}
                setPersonInChargeName={setPersonInChargeName}
                setCompanyName={setCompanyName}
                setInvoiceDate={setInvoiceDate}
                setReqFrmID={setInvoiceReqFrmID}
            />
            <InvoiceDetail
                reqFrmID={invoiceReqFrmID}
                customerName={customerName}
                personInChargeName={personInChargeName}
                companyName={companyName}
                invoiceDate={invoiceDate}
                setModalStr={setModalStr}
                reGetData={reGetData}
            />

            {/*待審查列表*/}
            <AccountChargeOffReviewList
                qryList={qryList5_1}
                setReqFrmID={setAccChargeReviewId}
                qryType={1}
            />

            {/*待出納審查列表*/}
            <AccountChargeOffReviewList
                qryList={qryList5_2}
                setReqFrmID={setAccChargeReviewId}
                qryType={2}
            />

            {/*帳務沖銷已完成列表*/}
            <AccChargeOffAgreeList
                qryList={agreeList5}
                setReqFrmID={setAccChargeReviewId}
                qryType={3}
            />

            {/*帳務沖銷退回列表*/}
            <AccChargeOffRevokeList
                qryList={revokeList5}
                setReqFrmID={setAccChargeReturnId}
                qryType={4}
            />

            {/*帳務沖銷被撤銷列表*/}
            <AccChargeOffIsRevokeList
                qryList={isRevokeList5}
                setReqFrmID={setAccChargeReturnId}
                qryType={5}
            />

            {/*帳務沖銷詳細內容*/}
            <AccountChargeOffDetail
                reqFrmID={AccChargeReviewId}
                ReviewType={ReviewType}
                setModalStr={setModalStr}
                reGetData={reGetData}
            />

            {/*帳務沖銷退回內容*/}
            <AccountChargeOffReturn
                reqFrmID={AccChargeReturnId}
                ReviewType={ReviewType}
                modalStr={modalStr}
                setModalStr={setModalStr}
                reGetData={reGetData}
            />

            <div id="modal-ok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-ok")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Notify;
