import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_AllCompany,
    API_GET_Customer3Name,
    API_GET_CloudAccountingSearchHome,
    API_POST_CloudAccountingDownloadStatement,
    API_GET_FileDownload

} from "../../global/constants";
import { InitJS, OpenModal, CloseModal, UrlDecode, MenuSet } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const CATDownLoad = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        //把menu顏色置換
        MenuSet("CATRowSetQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    var moment = require("moment");
    function getData() {
        API_GET_AllCompany()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setCompanyArray(data.result.data);
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

        API_GET_CloudAccountingSearchHome()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //setProductName1Array(data.result.data.productName1Array);
                    //setLatestMonth(data.result.data.latestMonth);
                    var tmpMonthArray = [];
                    var isOK = 0;
                    for (var i = -12; i < 10; i++) {
                        if (isOK == 1) {
                            break;
                        }
                        var ym = moment().add(i, "M").format("YYYY/MM");
                        if (ym == data.result.data.latestMonth) {
                            isOK = 1;
                        }
                        tmpMonthArray.push(ym);
                    }
                    setYMArray(tmpMonthArray);
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
    }
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyId, setCompanyId] = useState();
    const [companyIdError, setCompanyIdError] = useState(false);
    const [customerId, setCustomerId] = useState();
    const [monthError, setMonthError] = useState(false);
    const [startMonth, setStartMonth] = useState();

    const [ymArray, setYMArray] = useState([]);
    const [productId, setProductId] = useState();
    //客戶別
    const [customerName, setCustomerName] = useState();
    const [customerNameError, setCustomerNameError] = useState(false);
    const [customerNameArray, setCustomerNameArray] = useState([{}]);
    function customerNameChange(e) {
        setCustomerName(e.target.value);
        getCustomer3Name(1);
    }
    //客戶抬頭
    const [customerTitle, setCustomerTitle] = useState();
    const [customerTitleArray, setCustomerTitleArray] = useState([{}]);
    function customerTitleChange(e) {
        setCustomerTitle(e.target.value);
        getCustomer3Name(2);
    }
    //客戶統編
    const [customerTaxId, setCustomerTaxId] = useState();
    const [customerTaxIdError, setCustomerTaxIdError] = useState(false);
    const [customerTaxArray, setCustomerTaxArray] = useState([{}]);
    function customerTaxIdChange(e) {
        setCustomerTaxId(e.target.value);
        getCustomer3Name(3);
    }
    const [modalStr, setModalStr] = useState();
    //重新取得 客戶別 客戶別Title 客戶統編 List
    function getCustomer3Name(index) {
        var dt = [];
        if (index == 1) {
            dt = { customerName: document.getElementById("customerNameText").value }
        } else if (index == 2) {
            dt = { customerTitle: document.getElementById("customerTitleText").value }
        } else if (index == 3) {
            dt = { customerTaxId: document.getElementById("customerTaxIdText").value }
        }
        API_GET_Customer3Name(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                var dataName = [];
                var dataTitle = [];
                var dataTaxId = [];

                if (data.result != null && data.result.data != null) {
                    if (index == 1) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataName.length; j++) {
                                if (dataName[j].custName == data.result.data[i].custName) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataName.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxId: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerNameArray(dataName);
                    } else if (index == 2) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTitle.length; j++) {
                                if (dataTitle[j].custTitle == data.result.data[i].custTitle) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTitle.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxId: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerTitleArray(dataTitle);
                    } else if (index == 3) {
                        for (var i = 0; i < data.result.data.length; i++) {
                            var h = false;
                            for (var j = 0; j < dataTaxId.length; j++) {
                                if (dataTaxId[j].custTaxID == data.result.data[i].custTaxID) {
                                    h = true;
                                }
                            }
                            if (h == false) {
                                dataTaxId.push({
                                    custID: data.result.data[i].custID,
                                    custName: data.result.data[i].custName,
                                    custTitle: data.result.data[i].custTitle,
                                    custTaxID: data.result.data[i].custTaxID,
                                });
                            }
                        }
                        setCustomerTaxArray(dataTaxId);
                    }
                } else {
                    if (index == 1) {
                        setCustomerNameArray([{}]);
                    } else if (index == 2) {
                        setCustomerTitleArray([{}]);
                    } else if (index == 3) {
                        setCustomerTaxArray([{}]);
                    }
                }
            })
           
    }

    function DownLoad() {
        var hasError = false;
        //客戶 API15
        if (companyId === null || companyId === "" || companyId == undefined) {
            setCompanyIdError(true);
            hasError = true;
        } else {
            setCompanyIdError(false);
        }

        if (customerId === null || customerId === "" || customerId == undefined) {
            setCustomerNameError(true);
            hasError = true;
        } else {
            setCustomerNameError(false);
        }

        if (customerName === null || customerName === "" || customerName == undefined) {

            setCustomerNameError(true);
            hasError = true;
        } else {
            setCustomerNameError(false);
        }

        if (customerTaxId === null || customerTaxId === "" || customerTaxId == undefined) {
            setCustomerTaxIdError(true);
            hasError = true;
        } else {
            setCustomerTaxIdError(false);
        }
        if (startMonth == null || startMonth == "") {
            setMonthError(true);
            hasError = true;
        } else {
            setMonthError(false);
        }
        if (hasError == false) {
            OpenModal("modal-downlonding");
            var dt = {
                companyId: companyId,
                customerId: customerId,
                month: startMonth,
            }
            //console.log(JSON.stringify(dt));

            API_POST_CloudAccountingDownloadStatement(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        downLoad(data.result.data.downloadPath);
                    } else {
                        setModalStr(data.message);
                        CloseModal("modal-downlonding");
                        OpenModal("modal-error");
                    }
                })
        }
    }
    const FileDownload = require("js-file-download");
    function downLoad(path) {
        //console.log(path);
        API_GET_FileDownload({
            fileName: path,
        }).then((response) => {

            const disposition = response.headers['content-disposition'];
            var filename = disposition.split("''")[1];
            FileDownload(response.data, UrlDecode(filename));
            CloseModal("modal-downlonding");
        }).catch((err) => {
           
            CloseModal("modal-downlonding");
        });
       
    }


    return (
        <>
            <div className="content-body">
                <div className="title-area">
                    <div className="container narrow">
                        <h3 className="fz-B has-border">下載雲端對帳單</h3>
                    </div>
                </div>
                <div className="container narrow">
                    <form action="" className="form-container">
                        <div className={`form-item must  ${companyIdError ? 'show-help' : ''}`} >
                            <div className="field">隸屬公司</div>
                            <div className="value">
                                {companyArray.map((x) => (
                                    <div key={x.id}>
                                        <input
                                            type="radio"
                                            id={`company_${x.cmpID}`}
                                            value={x.cmpID}
                                            checked={companyId == x.cmpID ? "checked" : ""}
                                            onClick={(e) => {
                                                setCompanyId(x.cmpID)
                                            }}
                                        />
                                        <label htmlFor={`company_${x.cmpID}`} >{x.cmpName}</label>
                                    </div>
                                ))}
                            </div>
                            <span className="help-word">
                                請選擇隸屬公司
                            </span>
                        </div>


                        <div className={`form-item must has-datalist ${customerNameError ? 'show-help' : ''}`} data-datalist>
                            <div className="field">客戶別</div>
                            <div className="value">
                                <input id="customerNameText" type="text" onChange={customerNameChange} value={customerName} />
                                <ul className="datalist scrollbar">
                                    {customerNameArray.map((x) => (
                                        <li
                                            data-value={x.custName}
                                            onClick={(e) => {
                                                setCustomerId(x.custID),
                                                    setCustomerName(x.custName),
                                                    setCustomerTitle(x.custTitle),
                                                    setCustomerTaxId(x.custTaxId);
                                            }}
                                        >
                                            <span className="flex-4 bold">{x.custName}</span>
                                            <span className="flex-4 bold">{x.custTitle}</span>
                                            <span className="flex-4 bold">{x.custTaxId}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="help-word">
                                請輸入客戶別
                            </div>
                        </div>

                        <div className={`form-item must has-datalist ${customerNameError ? 'show-help' : ''}`} data-datalist>
                            <div className="field">公司抬頭</div>
                            <div className="value">
                                <input id="customerNameText" type="text" onChange={customerTitleChange} value={customerTitle} />
                                <ul className="datalist scrollbar">
                                    {customerNameArray.map((x) => (
                                        <li
                                            data-value={x.custTitle}
                                            onClick={(e) => {
                                                setCustomerId(x.custID),
                                                    setCustomerName(x.custName),
                                                    setCustomerTitle(x.custTitle),
                                                    setCustomerTaxId(x.custTaxId);
                                            }}
                                        >
                                            <span className="flex-4 bold">{x.custName}</span>
                                            <span className="flex-4 bold">{x.custTitle}</span>
                                            <span className="flex-4 bold">{x.custTaxId}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="help-word">
                                請輸入公司抬頭
                            </div>
                        </div>
                        <div className={`form-item  has-datalist ${customerNameError ? 'show-help' : ''}`} data-datalist>
                            <div className="field">公司統編</div>
                            <div className="value">
                                <input id="customerTaxIdText" type="text" onChange={customerTaxIdChange} value={customerTaxId} />
                                <ul className="datalist scrollbar">
                                    {customerTaxArray.map((x) => (
                                        <li data-value={x.custTaxID} onClick={(e) => {
                                            setCustomerId(x.custName.custID),
                                                setCustomerName(x.custName),
                                                setCustomerTitle(x.custTitle),
                                                setCustomerTaxId(x.custTaxId);
                                        }}
                                        >
                                            <span className="flex-4 bold">{x.custName}</span>
                                            <span className="flex-4 bold">{x.custTitle}</span>
                                            <span className="flex-4 bold">{x.custTaxID}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <span className="help-word">請輸入公司統編</span>
                        </div>

                        <div className="form-item must">
                            <div className="field">月結月份</div>
                            <div className="value">
                                <select onChange={(e) => setStartMonth(e.target.value)}>
                                    <option id="">請選擇</option>
                                    {
                                        ymArray.map(x =>
                                            <option value={x}>{x}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="help-word">
                                請輸入月結月份
                            </div>
                        </div>

                        <div className="form-item btn-area sp">
                            <div className="left">

                            </div>
                            <div className="right">

                                <a className="btn" onClick={DownLoad}>
                                    <span>下載</span>
                                </a>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            <div id="modal-ok" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-43"></i>
                        </div>
                        <div class="text-holder">
                            {modalStr}
                        </div>
                        <div class="btn-holder">
                            <button class="btn" onClick={() => CloseModal("modal-ok")}> <span>確認</span></button>
                        </div>

                    </div>

                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error", 4)}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-downlonding" class="modal" data-modal>
                <div class="modal-container">

                    <div class="modal-body">
                        <div class="text-holder">
                            <h6 class="fz-B aic">檔案產生中 <span class="inline-loading"></span></h6>


                        </div>

                    </div>
                </div>

            </div>
        </>
    );
};

export default CATDownLoad;
