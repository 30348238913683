import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_CloudAccountingPreview,
    API_POST_CloudAccountingBilling,
    API_POST_CloudAccountingDataCancel,
    API_GET_FileDownload
} from "../../global/constants";
import { InitJS, OpenModal, CloseModal, UrlDecode, MenuSet } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const CATPreviewList = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        //把menu顏色置換
        MenuSet("CATRowSetQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    //var moment = require("moment");
    function getData() {
        //setProductName(queryParams.get("productName1"));
        API_GET_CloudAccountingPreview({
            productName1: queryParams.get("productName1"),
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setProductName(data.result.data.productName1);
                    setIncomeYM(data.result.data.month);
                    setProductName2Array(data.result.data.productName2Array);
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
                //var futureMonth = moment().format("YYYY/MM");
                //setIncomeYM(futureMonth);
                //var ApiMonth = moment().add(-1, "M").format("YYYY/MM");
                //setApiincomeYM(ApiMonth);
            })
            .catch((err) => {
                
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [productName2Array, setProductName2Array] = useState([{}]);
    const [incomeYM, setIncomeYM] = useState();
    const [apiincomeYM, setApiincomeYM] = useState();
    const [productName, setProductName] = useState();
    const [tmpId, setTmpId] = useState();
    const [tmpName, setTmpName] = useState();
    const [modalStr, setModalStr] = useState();
    function OpenModalReUpload(id, name) {
        setTmpId(id);
        setTmpName(name);
        OpenModal("modal-reupload");
    }
    //重新上傳
    function reupload() {
        var isOK = true;
        var dt = {
            productFullName: tmpName,
            incomeYM: incomeYM,
        };
        //console.log(JSON.stringify(dt));
        //API12-18
        API_POST_CloudAccountingDataCancel(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    
                } else {

                    isOK = false;
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }

            })
        //導到12.3.1畫面
        if (isOK) {
            var path = "/Main?path=CATExport";
            history.push(path);
        }
    }
    function Billing() {
        CloseModal("modal-billing");
        var dt = {
            productName1: queryParams.get("productName1")
        };
        API_POST_CloudAccountingBilling(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    downLoad(data.result.data.downloadPath);
                } else {

                    isOK = false;
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }

            })
    }
    const FileDownload = require("js-file-download");
    function downLoad(path) {
        //console.log(path);
        API_GET_FileDownload({
            fileName: path,
        }).then((response) => {

            const disposition = response.headers['content-disposition'];
            var filename = disposition.split("''")[1];
            FileDownload(response.data, UrlDecode(filename));
            CloseModal("modal-downlonding");
        }).catch((err) => {
            
            CloseModal("modal-downlonding");
        });

    }
    function reflash() {
        window.location.reload();
    }
    return (
        <>
            <div className="content-body">
                <div className="title-area">
                    <div className="container">
                        <h3 className="fz-B has-border">匯入預覽</h3>
                    </div>
                </div>
                <div className="container">
                    <div className="info-holder">
                        <div className="left">
                            雲端細項總稱：{queryParams.get("productName1")}
                        </div>
                        <div className="right">
                            月結月份：{incomeYM}
                        </div>
                    </div>
                    <ul className="list-table">
                        <li className="lt-row lt-th">
                            <div className="flex-8">產品細項</div>

                            <div className="flex-1 fn"></div>
                        </li>
                        {
                            productName2Array.map((x, i) =>
                                <li className="lt-row">
                                    <div className="flex-8">
                                        <div className="elips-1">{x.name}</div>
                                    </div>
                                    <div className="flex-1 fn">
                                        <a className="btn btn-border btn-middle" onClick={() => OpenModalReUpload(x.id, x.name)}>
                                            <span>重新上傳</span>
                                        </a>
                                    </div>

                                </li>
                            )
                        }
                    </ul>
                    <div className="form-item btn-area sp">
                        <div className="left">
                            <a className="btn btn-border" onClick={() => history.push("/Main?path=CATAdjust")}>
                                <span>手動調帳</span>
                            </a>
                        </div>
                        <div className="right">

                            <a className="btn" onClick={() => OpenModal("modal-billing")}>
                                <span>確認出帳</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-reupload" className="modal small alert" data-modal >
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            重新上傳會先刪除本月匯入此細項的所有資料 才可重新匯入。
                        </div>
                        <div className="btn-holder">
                            <button className="btn btn-border" onClick={() => CloseModal("modal-reupload")}> <span>取消</span></button>
                            <button className="btn" onClick={reupload} > <span>確定</span></button>
                        </div>

                    </div>

                </div>
            </div>
            <div id="modal-billing" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要執行「出帳」? <br /> 出帳後將不可修改！
                        </div>
                        <div className="btn-holder">
                            <button className="btn btn-border" onClick={() => CloseModal("modal-billing")}> <span>取消</span></button>
                            <button className="btn" onClick={Billing}> <span>確定</span></button>
                        </div>

                    </div>

                </div>
            </div>
            <div id="modal-ok" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-43"></i>
                        </div>
                        <div class="text-holder">
                            {modalStr}
                        </div>
                        <div class="btn-holder">
                            <button class="btn" onClick={() => CloseModal("modal-ok")}> <span>確認</span></button>
                        </div>

                    </div>

                </div>
            </div>
            <div id="modal-reflash" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-43"></i>
                        </div>
                        <div class="text-holder">
                            {modalStr}
                        </div>
                        <div class="btn-holder">
                            <button class="btn" onClick={reflash}> <span>確認</span></button>
                        </div>

                    </div>

                </div>
            </div> 
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CATPreviewList;
