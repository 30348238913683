import '../css/style.css';
import Notify from '../components/Dashboard/Notify'
import PermissionPanel from '../components/Dashboard/PermissionPanel'
import AddUser from '../components/Dashboard/AddUser'
import { OpenModal, CloseModal, InitJS, UrlDecode } from '../global/ToolUtil';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { isExpired, decodeToken } from "react-jwt";
import { API_GET_AllCompany, API_GET_ERP_AccountWriteOff, API_GET_HomeData } from '../global/constants';
import { CheckResponseCode } from '../global/ResponseUtil';

function dashboard() {
    const history = useHistory();
    const [username, setUserName] = useState('');
    const [validRemainDays, setValidRemainDays] = useState();
    const [pageList, setPageList] = useState([]);


    useEffect(() => {
        window.onbeforeunload = null;
        if (localStorage.getItem('islogin') == null && localStorage.getItem('islogin') != '1') {

            localStorage.clear();
            history.push('/');
        }
        if (localStorage.getItem('validRemainDays') <= 7) {
            setValidRemainDays(localStorage.getItem('validRemainDays'));
            OpenModal("modal-cpw");
        }
        // 更新body的class
        document.body.classList.remove('login');
        document.body.classList.add('dashboard');
        document.getElementById('root').classList.remove('center');
        setUserName(localStorage.getItem("userName"));
        getData();
        //延遲一秒在執行js事件綁定 在取消掉timeout 避免一直執行 導致一個功能要按好幾次才會出現
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    let menuArray = [];
    let menuArrayLen = 0;
    if (localStorage.getItem('islogin') == '1') {
        menuArray = JSON.parse(localStorage.getItem('menulist'));
        menuArrayLen = menuArray.filter(item => item.funcListType == "-1").length;
    } else {
        localStorage.clear();
        history.push('/');
    }
    const [companyId, setCompanyId] = useState();
    const [companyArray, setCompanyArray] = useState([]);
    const [modalStr, setModalStr] = useState();
    function getData() {
        //匯率表
        API_GET_AllCompany({})
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setCompanyArray(data.result.data);
            })
            .catch(err => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
        //重抓權限MENU等等
        var fromOkta_ = (localStorage.getItem("post_logout_redirect_uri") != null ? 1 : 0);
        API_GET_HomeData({ fromOkta: fromOkta_ })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    localStorage.setItem("token", data.token.token);
                    localStorage.setItem(
                        "pagelist",
                        JSON.stringify(data.result.pagedata)
                    );
                    setPageList(data.result.pagedata);
                    localStorage.setItem(
                        "menulist",
                        JSON.stringify(data.result.menudata)
                    );
                    localStorage.setItem(
                        "authctrl",
                        JSON.stringify(data.result.authdata)
                    );
                    localStorage.setItem("hasManyADAcnt", data.result.hasManyADAcnt);
                    localStorage.setItem("notify", JSON.stringify(data.result.notify));
                    history.push("/Dashboard");
                } else if (data.code == "1001") {
                    localStorage.clear();
                    setMsgStr(data.message);
                    history.push("/");
                }
            })
    }

    function logout() {
        var pth = resultPth();
        if (pth.indexOf("okta") >= 0) {
            window.location.href = pth;
        }
        history.push(pth);
    }

    function resultPth() {
        var result = "/";
        var id_token = localStorage.getItem('id_token');
        var okta_url = localStorage.getItem('okta_url');
        var post_logout_redirect_uri = localStorage.getItem('post_logout_redirect_uri');
        var check_okta_url = window.okta_url;
        var check_post_logout_redirect_uri = window.post_logout_redirect_uri;
        if (post_logout_redirect_uri != null && post_logout_redirect_uri != '') {
            //jwt解析
            var jwttoken = decodeToken(id_token);
            //token過期 
            if ((jwttoken.exp * 1000) < Date.now()) {
                result = "/";
            }
            //判斷後台傳遞的位址是否與前端JS設定一致
            else if (check_okta_url.trim() == okta_url && check_post_logout_redirect_uri.trim() == post_logout_redirect_uri) {
                result = UrlDecode(okta_url + "?id_token_hint=" + id_token + "&post_logout_redirect_uri=" + post_logout_redirect_uri);
            }
        }
        localStorage.clear();
        return result;
    }
    const [isOpen, setIsOpen] = useState(false);
    function OpenErpCompany() {
        setIsOpen((isOpen == true ? false : true));
    }
    function erprefresh(cmpID) {
        setIsOpen(false);
        API_GET_ERP_AccountWriteOff({
            companyId: cmpID
        })
            .then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (data == "OK") {
                    OpenModal("modal-ErpOK");
                } else {
                    OpenModal("modal-ErpError");
                }
            })

        //document.getElementById("erpbtn").value = "";
    }
    function goChangePwd() {
        history.push('/?path=cpwdform');
    }
    return (
        <>
            <div className="dashboard-header">
                <div>
                    <div className="left">
                        <div className="title">
                            <i className="icon  icon-31"></i>
                            <h1>果核營運系統</h1>
                        </div>
                        <div className="welcome">
                            <h6 className="fz-B">welcome !</h6>
                            <h2 className="fz-A">{username}</h2>
                        </div>
                    </div>
                    <div className="right">
                        <div className="btn-logout" onClick={logout}>
                            <i className="icon icon-38" ></i>
                            <span>登出</span>
                        </div>
                        <div className="fn">
                            {
                                localStorage.getItem("hasManyADAcnt") == '1' ?
                                    <></> :
                                    menuArrayLen === 1 ?
                                        <div className={`dropbuntton ${isOpen ? 'active' : ''}`} data-dropbuntton>
                                            <div className="btn btn-middle" onClick={OpenErpCompany} data-trigger >
                                                <span>ERP 刷新</span>
                                                <i></i>
                                            </div>
                                            <div className="dropmenu" data-dropmenu>
                                                {
                                                    companyArray.map(x =>
                                                        <a href="#" onClick={() => erprefresh(x.cmpID)}>{x.cmpName}</a>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="dashboard-body ">
                <div className="scrollbar">
                    <div className="container">
                        <div className={`left has-border ${pageList.length == 0 || localStorage.getItem("hasManyADAcnt") == '1' ? " has-border" : ""}`} style={{ "height": "600px" }}>
                            <Notify />
                        </div>
                        <div className="right scrollbar" style={{ "height": "600px", "padding": "0px 20px 0px 40px" }}>
                            <PermissionPanel />
                        </div>
                        <AddUser />
                        <div className="bottom copyright">
                            <span>2021 Digicentre Company Limited. All Rights Reserved.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-cpw" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            提醒您<br />
                            密碼剩餘{validRemainDays}天到期，請進行密碼變更

                        </div>
                        <div className="btn-holder sp">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-cpw")}> <span>稍後進行</span></a>
                            <a className="btn" onClick={goChangePwd}> <span>變更密碼</span></a>
                        </div>

                    </div>

                </div>
            </div>
            <div id="modal-ErpOK" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            ERP更新完成
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-ErpOK")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-ErpError" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            未知的錯誤
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-ErpError")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default dashboard;