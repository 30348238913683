import React, { useState, useEffect } from "react";
import {
    API_GET_CustomerReview,
    API_GET_AdNameList,
    API_POST_Revoke,
    API_DEL_CustomerData,
    API_PUT_CustomerData,
    API_POST_CustomerData,
    API_PUT_CustomerReview
} from "../../../global/constants";
import { Clone, CloseModal, OpenModal } from "../../../global/ToolUtil";
import { checkAuthToken } from "../../../global/TokenUtil";
import { useHistory } from "react-router-dom";
import { CheckResponseCode } from '../../../global/ResponseUtil';

const CustomerDetail = ({ reqFrmID, setModalStr, reGetData }) => {
    var history = useHistory();

    useEffect(() => {
        if (reqFrmID != null) {
            getData();
        }
    }, [reqFrmID]);

    //重新抓取資料
    function getData() {
        setReason("");
        OpenModal("modal-customerreview");
        API_GET_CustomerReview({
            reqFrmID: reqFrmID,
        })
            .then((response) => response.data)
            .then((data) => {
                console.log("客戶維護單子回傳資料", data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setReqFrmType(data.result.data.reqFrmType);
                setReqFrmCFState(data.result.data.reqFrmCFState);
                //負責公司
                setCompanyArray(data.result.data.dataCard.companyArray);
                //客戶產業Array
                setCustIndustryArray(data.result.data.dataCard.customerIndustriesArray);
                //交易幣別Array
                setCurrencyBaseArray(data.result.data.dataCard.currencyArray);
                //國家Array
                setCountryArray(data.result.data.dataCard.countryArray);
                //業務單位
                setDepartmentArray(data.result.data.dataCard.departmentArray);
                //客戶別
                setCustTypeArray(data.result.data.dataCard.customerTypeArray);
                //付款條件
                setPaymentTermsArray(data.result.data.dataCard.paymentTermsArray);

                //--------------------------------------//
                setCompanyIdArray(data.result.data.cust.companyIdArray);
                if (data.result.data.cust.companyIdArray.length > 0) {
                    setCompanyId(data.result.data.cust.companyIdArray[0]);
                }
                //交易幣別
                setCurrencyID(data.result.data.cust.currID);
                //console.log(data.result.data.cust.currID);
                //公司統編/ 編號
                setCustTaxID(data.result.data.cust.custTaxID);
                //國家
                setCountryID(data.result.data.cust.cutID);
                var elem = document.querySelector("#custTaxID");
                var elem2 = document.querySelector("#custBankPass");
                //console.log(elem.dataset);
                if (data.result.data.cust.cutID == "0") {
                    setTax("應稅");
                    setInvoice("發票");
                    elem.dataset.required = true;
                    elem2.dataset.required = true;
                    setTaxIDrequired(true);
                    setBankPassrequired(true);
                } else {
                    setTax("免稅");
                    setInvoice("Invoice");
                    setTaxIDrequired(false);
                    setBankPassrequired(false);
                    elem.removeAttribute("data-requiredcust");
                    elem2.removeAttribute("data-requiredcust");
                }
                //setTitleName(data.result.data.cust.custName);
                //客戶別
                setCustName(data.result.data.cust.custName);
                //custNumber
                setCustNumber(data.result.data.cust.custNumber);
                //客戶類別
                setCustTypeID(data.result.data.cust.custTypeID);
                //公司抬頭
                setCustTitle(data.result.data.cust.custTitle);
                //客戶產業
                setCustINDID(data.result.data.cust.custINDID);
                //簡稱
                setCustNickName(data.result.data.cust.custNickName);
                //對帳單密碼是否與統編相同
                setF726(data.result.data.cust.custIsBankTxtID);
                if (data.result.data.cust.custIsBankTxtID == true) {
                    document.getElementById("custBankPass").disabled = true;
                }
                //銷帳帳號
                setCustChargeOffAcc(data.result.data.cust.custChargeOffAcc);
                //匯款銀行
                setBankData(data.result.data.cust.custBanks);
                //對帳單密碼
                setCustBankPass(data.result.data.cust.custBankPass);
                //負責人
                setAdeID(data.result.data.cust.personADID);
                setAdeName(data.result.data.cust.personADName);
                //業務單位
                setCmpDivID(data.result.data.cust.cmpDivID);
                //付款條件
                setCustPayID(data.result.data.cust.custPayID);
                //ICP客戶
                setCustIsICP(data.result.data.cust.custIsICP);
                //對帳單寄送
                setCustIsSend(data.result.data.cust.custIsSend);
                //雲端寄送
                setCloudSend(data.result.data.cust.custIsCloudDetail)
                //業務聯絡人
                if (data.result.data.cust.custSales != undefined && data.result.data.cust.custSales != null) {
                    setContactData(data.result.data.cust.custSales);
                }
                else {
                    setContactData([]);
                }
                //帳務聯絡人
                if (data.result.data.cust.custAccounts != undefined && data.result.data.cust.custAccounts != null) {
                    setAccContactData(data.result.data.cust.custAccounts);
                }
                else {
                    setAccContactData([]);
                }
                //客戶地址
                setCustAddr(data.result.data.cust.custAddress);
                //原始ID
                setOldCustidArray(data.result.data.cust.oldCustidArray);
                //退回原因
                setReason(data.result.data.reqFrmProcData);
                //審查人
                setAuditorName(data.result.data.auditorName);

            })
            .catch((err) => {
                console.log("API_GET_CustomerReview的失敗回傳", err)
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }
    const [isSameContactData, setIsSameContactData] = useState(false);
    function sameContactData() {
        //本來不是同客戶聯絡人,按了就要變成是了
        if (isSameContactData == false) {
            var tmpArray = [];
            setAccContactData([]);
            var tmpContactData = Clone(contactData);
            var tmpArray = [];
            for (var i = 0; i < tmpContactData.length; i++) {
                tmpArray.push({
                    id: null,
                    custActName: tmpContactData[i].custSalesName,
                    custActTel: tmpContactData[i].custSalesTel,
                    custActMail: tmpContactData[i].custSalesMail,
                    isEdit: true,
                });
            }
            setAccContactData(tmpArray);
        }
        setIsSameContactData(!isSameContactData);
    }
    //資料檢查(客戶)
    function checkData() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-requiredcust]");
        requiredlist.forEach((element) => {
            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });
        if (companyIdArray.length == 0) {
            result = false;
            document.getElementById("companyDIV").classList.add("show-help");
        } else {
            document.getElementById("companyDIV").classList.remove("show-help");
        }
        var bf722 = false;
        var f722 = document.getElementsByName("f722");
        for (var i = 0; i < f722.length; i++) {
            if (f722[i].checked) {
                bf722 = true;
                break;
            }
        }
        if (bf722 == false) {
            document.getElementById("departmentDIV").classList.add("show-help");
            result = false;
        } else {
            document.getElementById("departmentDIV").classList.remove("show-help");
        }
        //付款條件
        var bf723 = false;
        var f723 = document.getElementsByName("f723");
        for (var i = 0; i < f723.length; i++) {
            if (f723[i].checked) {
                bf723 = true;
                break;
            }
        }
        if (bf723 == false) {
            document.getElementById("paymentTermsDIV").classList.add("show-help");
            result = false;
        } else {
            document.getElementById("paymentTermsDIV").classList.remove("show-help");
        }
        //ICP客戶
        var bf724 = false;
        var f724 = document.getElementsByName("f724");
        for (var i = 0; i < f724.length; i++) {
            if (f724[i].checked) {
                bf724 = true;
                break;
            }
        }
        if (bf724 == false) {
            document.getElementById("ICPDIV").classList.add("show-help");
            result = false;
        } else {
            document.getElementById("ICPDIV").classList.remove("show-help");
        }
        //對帳單寄送 
        if (custIsSend == null) {
            document.getElementById("isSendDiv").classList.add("show-help");
            result = false;
        } else {
            document.getElementById("isSendDiv").classList.remove("show-help");
        }
        //雲端明細寄送
        if (cloudSend == null) {
            document.getElementById("isCloudSendDiv").classList.add("show-help");
            result = false;
        } else {
            document.getElementById("isCloudSendDiv").classList.remove("show-help");
        }
        for (var i = 0; i < bankData.length; i++) {
            if (
                bankData[i].custBankCurrencies == null ||
                bankData[i].custBankCurrencies.length == 0
            ) {
                document
                    .getElementById("bank_" + i)
                    .setAttribute("style", "border-color:#F37402;color:#F37402");
                result = false;
            } else {
                document.getElementById("bank_" + i).removeAttribute("style");
            }
        }

        return result;
    }
    //統編/對帳單密碼必填
    const [taxIDrequired, setTaxIDrequired] = useState(true);
    const [bankPassrequired, setBankPassrequired] = useState(true);
    //審核
    const [reqFrmCFState, setReqFrmCFState] = useState();
    const [reqFrmType, setReqFrmType] = useState();
    //審核人
    const [auditorName, setAuditorName] = useState('');
    //退回原因
    const [reason, setReason] = useState();
    //陣列幾
    const [currencyIndex, setCurrencyIndex] = useState();
    //打開幣別
    function openModalCurrency(index) {
        setCurrencyIndex(index);

        var a = bankData[index].custBankCurrencies;

        if (a == null) a = [];

        var tmpb = [];
        for (var i = 0; i < a.length; i++) {

            tmpb.push(a[i].currID);

        }
        var f727 = document.getElementsByName("f727");
        for (var i = 0; i < f727.length; i++) {
            if (tmpb.indexOf(parseInt(f727[i].value)) >= 0) {
                f727[i].checked = true;
            } else {
                f727[i].checked = false;
            }
        }
        OpenModal("modal-currency", 2);
    }

    //隸屬公司
    const [companyId, setCompanyId] = useState();
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyIdArray, setCompanyIdArray] = useState([]);
    function companyIdArrayChange(id) {
        var tmpArray = [];
        var has01 = false;
        var has23 = false;
        var f622 = document.getElementsByName(id);
        for (var i = 0; i < f622.length; i++) {
            if (f622[i].checked) {
                tmpArray.push(parseInt(f622[i].value));
                if (i == 0 || i == 1) {
                    has01 = true;
                }
                if (i == 2 || i == 3) {
                    has23 = true;
                }
            }
        }
        if (has01 && has23) {
            setCustChargeOffAcc("");
        } else {
            if (has01) {
                setCustChargeOffAcc("8851+24359007");
            }
            else if (has23) {
                setCustChargeOffAcc("8851+54995834");
            }
        }
        setCompanyIdArray(tmpArray);
    }
    //供應商名稱
    const [supplierName, setSupplierName] = useState("");
    function supplierNameChange(e) {
        setSupplierName(e.target.value);
        getSupplierName();
    }
    function getSupplierName() {
        API_GET_SupplierNameList({
            input: document.getElementById("supplierNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setSupplierNameArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
    }
    //供應商名稱datalist
    const [supplierNameArray, setSupplierNameArray] = useState([{}]);
    const [adeName, setAdeName] = useState();
    const [adeNameArray, setADENameArray] = useState([{}]);
    function adeNameChange(e) {
        setAdeName(e.target.value);
        getAdeName();
    }
    function getAdeName() {
        if (document.getElementById("adeNameText").value == "") {
            return false;
        }
        API_GET_AdNameList({
            input: document.getElementById("adeNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setADENameArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
    }

    //產品負責單位
    const [departmentArray, setDepartmentArray] = useState([{}]);
    const [cmpDivID, setCmpDivID] = useState();
    //會計科目
    const [accounting, setAccounting] = useState([{}]);
    const [actID, setActID] = useState();
    //列入產品成本
    const [productInCost, setProductInCost] = useState(true);
    //會計子目
    const [accountingBranch, setAccountingBranch] = useState([{}]);
    const [actCID, setActCID] = useState();
    const [actPID, setActPID] = useState();
    //會計產品別
    const [accountingProductType, setAccountingProductType] = useState([{}]);
    //月結天數
    const [settlementArray, setSettlementArray] = useState([{}]);
    const [setlementId, setSetlementId] = useState();
    //統編
    const [venBIZNo, setVenBIZNo] = useState("");
    //供應商抬頭
    const [supplierTitle, setSupplierTitle] = useState("");
    //銀行(客戶)
    const [bankData, setBankData] = useState([{}]);
    function bankDataChange(e, index, col) {
        setBankData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "custBankName") {
                        item.custBankName = e.target.value;
                    }
                    else if (col == "custBankAct") {
                        item.custBankAct = e.target.value;
                    }
                    else if (col == "custBankBranch") {
                        item.custBankBranch = e.target.value;
                    }
                }
                return item;
            });
        });
    }

    function addBankData() {
        setBankData(function (prev) {
            return [...prev, { custBankName: null, custBankAct: null, custBankBranch: null },];
        });
    }

    //帳務聯絡人(客戶)
    const [contactData, setContactData] = useState([]);
    function addContactData() {
        setContactData(function (prev) {
            return [
                ...prev,
                { custSalesName: null, custSalesTel: null, custSalesMail: null },
            ];
        });
    }
    //聯絡人
    function contactChange(e, index, col) {
        setContactData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "custSalesName") {
                        item.custSalesName = e.target.value;
                    } else if (col == "custSalesMail") {
                        item.custSalesMail = e.target.value;
                    } else if (col == "custSalesTel") {
                        item.custSalesTel = e.target.value;
                    }

                }
                return item;
            });
        });
    }

    //刪除聯絡人
    function delContactItem(index) {
        setContactData(function (prev) {
            return prev.filter((x, i) => i != index);
        });
    }

    function returnContactItem(index, isEdit) {
        setContactData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.isEdit = isEdit;
                }
                return item;
            });
        });
    }

    //交易幣別Array
    const [currencyBaseArray, setCurrencyBaseArray] = useState([{}]);
    //客戶別
    const [custName, setCustName] = useState("");
    //客戶Number
    const [custNumber, setCustNumber] = useState("");
    //客戶抬頭
    const [custTitle, setCustTitle] = useState("");
    //銷帳帳號
    const [custChargeOffAcc, setCustChargeOffAcc] = useState();
    const [tax, setTax] = useState("應稅");
    //客戶地址
    const [custAddr, setCustAddr] = useState("");
    //是否ICP客戶
    const [custIsICP, setCustIsICP] = useState();
    //是否寄送
    const [custIsSend, setCustIsSend] = useState();
    //雲端寄送
    const [cloudSend, setCloudSend] = useState();
    //對帳單密碼
    const [custBankPass, setCustBankPass] = useState("");
    function custBankPassChange(e) {
        setCustBankPass(e.target.value);
    }
    //公司統編
    const [custTaxID, setCustTaxID] = useState("");
    function custTaxIDChange(e) {
        setCustTaxID(e.target.value);
        if (F726) {
            setCustBankPass(e.target.value);
        }
    }
    //客戶簡稱
    const [custNickName, setCustNickName] = useState("");
    //客戶類別Array
    const [custTypeArray, setCustTypeArray] = useState([{}]);
    const [custTypeID, setCustTypeID] = useState();
    //客戶產業Array
    const [custIndustryArray, setCustIndustryArray] = useState([{}]);
    const [custINDID, setCustINDID] = useState();
    //國家Array
    const [countryArray, setCountryArray] = useState([{}]);
    //國家ID
    const [countryID, setCountryID] = useState();
    const [memo, setMemo] = useState();
    function countryChange(e) {
        setCountryID(e.target.value);
        var elem = document.querySelector("#custTaxID");
        var elem2 = document.querySelector("#custBankPass");
        if (e.target.value == "0") {
            setTax("應稅");
            elem.dataset.requiredcust = true;
            elem2.dataset.requiredcust = true;
            setTaxIDrequired(true);
            setBankPassrequired(true);
            setInvoice("發票");
        } else {
            setTax("免稅");
            setInvoice("Invoice");
            setTaxIDrequired(false);
            setBankPassrequired(false);
            elem.removeAttribute("data-requiredcust");
            elem2.removeAttribute("data-requiredcust");
        }
    }
    //交易幣別
    const [currencyID, setCurrencyID] = useState();
    function currencyDataChange() {
        var tmpArray = [];
        var f727 = document.getElementsByName("f727");
        for (var i = 0; i < f727.length; i++) {
            if (f727[i].checked) {
                tmpArray.push({ currID: parseInt(f727[i].value) });
            }
        }
        setBankData(function (prev) {
            return prev.map((item, i) => {
                if (i == currencyIndex) {
                    item.custBankCurrencies = tmpArray;
                }
                return item;
            });
        });
    }
    //付款條件
    const [paymentTermsArray, setPaymentTermsArray] = useState([{}]);
    const [custPayID, setCustPayID] = useState();
    const [F726, setF726] = useState(true);
    function F726change(e) {
        if (F726) {
            setF726(false);
            document.getElementById("custBankPass").disabled = false;
        } else {
            setF726(true);
            setCustBankPass(custTaxID);
            document.getElementById("custBankPass").disabled = true;
        }
    }
    //帳務聯絡人
    const [accContactData, setAccContactData] = useState([{}]);
    function addAccContactData() {
        setAccContactData(function (prev) {
            return [...prev, { id: null, CustActName: null, CustActTel: null, CustActMail: null },];
        });
    }
    function accContactChange(e, col) {
        var index = e.target.dataset.index;
        setAccContactData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "custActName") {
                        item.custActName = e.target.value;
                    }
                    else if (col == "custActTel") {
                        item.custActTel = e.target.value;
                    }
                    else if (col == "custActMail") {
                        item.custActMail = e.target.value;
                    }
                }
                return item;
            });
        });
    }

    //刪除帳務聯絡人
    function delAccContactItem(index) {
        setAccContactData(function (prev) {
            return prev.filter((x, i) => i != index);
        });
    }
    const [Invoice, setInvoice] = useState();
    const [adeID, setAdeID] = useState();
    const [productFullName, setProductFullName] = useState();
    const [productName, setProductName] = useState();
    const [oldCustidArray, setOldCustidArray] = useState([]);

    //撤銷
    function Revoke() {
        document.querySelector("#modal-customerreview").classList.remove("active");
        OpenModal("modal-customerrevoke");

    }

    //確認再次送審
    function AgainSend() {
        var isOK = true;
        CloseModal("modal-customeragain");
        if (reqFrmType == 0 || reqFrmType == 1) {//修改 執行checkdata
            var result = checkData();
            if (result == false) {
                return false;
            }
        }
        if (reqFrmType == 0) {
            //新增
            var dt = {
                companyIdArray: companyIdArray,
                custName: custName,
                custNumber: custNumber,
                custTitle: custTitle,
                custTaxID: custTaxID,
                custNickName: custNickName,
                custTypeID: parseInt(custTypeID),
                custINDID: parseInt(custINDID),
                cutID: parseInt(countryID),
                currID: parseInt(currencyID),
                custAddress: custAddr,
                personADID: parseInt(adeID),
                personADName: adeName,
                cmpDivID: parseInt(cmpDivID),
                custPayID: parseInt(custPayID),
                custIsICP: custIsICP,
                custChargeOffAcc: custChargeOffAcc,
                custIsSend: custIsSend,
                custIsCloudDetail: cloudSend,
                custBankPass: custBankPass,
                custIsBankTxtID: document.querySelector('input[name="f726"]').checked,
                custSales: contactData,
                custBanks: bankData,
                custAccounts: accContactData,
            };
            //console.log(JSON.stringify(dt));
            API_POST_CustomerData(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setModalStr(data.message);
                    if (data.code == "0000" || data.code == "0001") {
                        reGetData();
                        CloseModal("modal-customerreview");
                        OpenModal("modal-ok");
                    } else {
                        OpenModal("modal-error");
                    }
                })
        } else if (reqFrmType == 1) {
            //修改
            var dt = {
                oldCustomerName: custName,
                oldCustidArray: oldCustidArray,
                companyIdArray: companyIdArray,
                custName: custName,
                custNumber: custNumber,
                custTitle: custTitle,
                custTaxID: custTaxID,
                custNickName: custNickName,
                custTypeID: parseInt(custTypeID),
                custINDID: parseInt(custINDID),
                cutID: parseInt(countryID),
                currID: parseInt(currencyID),
                custAddress: custAddr,
                personADID: parseInt(adeID),
                personADName: adeName,
                cmpDivID: parseInt(cmpDivID),
                custPayID: parseInt(custPayID),
                custIsICP: custIsICP,
                custChargeOffAcc: custChargeOffAcc,
                custIsSend: custIsSend,
                custIsCloudDetail: cloudSend,
                custBankPass: custBankPass,
                custIsBankTxtID: document.querySelector('input[name="f726"]').checked,
                custSales: contactData,
                custBanks: bankData,
                custAccounts: accContactData,
                reqFrmID: reqFrmID,
            };
            //console.log(JSON.stringify(dt));
            API_PUT_CustomerData(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setModalStr(data.message);
                    if (data.code == "0000" || data.code == "0001") {
                        reGetData();
                        CloseModal("modal-customerreview");
                        OpenModal("modal-ok");
                    } else {
                        OpenModal("modal-error");
                    }
                })

        } else if (reqFrmType == 2) {
            //刪除
            API_DEL_CustomerData({
                companyId: companyId,
                oldCustomerName: custName,
                reqFrmID: reqFrmID,
                customerSales: contactData,
                customerBank: bankData,
                customerAccounting: accContactData,
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setModalStr(data.message);
                    if (data.code == "0000" || data.code == "0001") {
                        reGetData();
                        CloseModal("modal-customerreview");
                        OpenModal("modal-ok");
                    } else {
                        OpenModal("modal-error");
                    }
                })
        }
        //if (isOK) {

        //    if (reqFrmCFState == 2) {
        //        setModalStr("再次送審完成");
        //    } else {
        //        setModalStr("審核完成");
        //    }
        //    reGetData();
        //    CloseModal("modal-customerreview");
        //    OpenModal("modal-ok");
        //} else {
        //    OpenModal("modal-error");
        //}
    }
    //客戶審核 退回
    function Reason(index) {
        var isOK = true;
        CloseModal("modal-customercheckreason");
        API_PUT_CustomerReview({
            reqFrmID: reqFrmID,
            reviewStatus: 2,
            reason: reason,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setModalStr(data.message);
                if (data.code == "0000" || data.code == "0001") {
                    reGetData();
                    CloseModal("modal-customerreview");
                    OpenModal("modal-ok");
                }
                else {
                    OpenModal("modal-error");
                }

                //if (data.code != "0000" && data.code != "0001") {
                //    setModalStr(data.message);
                //} else {
                //    setModalStr("審核完成");
                //    reGetData();
                //}
            })
        //if (isOK) {
        //    if (reqFrmCFState == 2) {
        //        setModalStr("再次送審完成");
        //    } else {
        //        setModalStr("審核完成");
        //    }
        //    reGetData();
        //    CloseModal("modal-customerreview");
        //    OpenModal("modal-ok");
        //} else {
        //    OpenModal("modal-error");
        //}
    }

    //撤銷
    function RevokeSend() {
        CloseModal("modal-customerrevoke");
        API_POST_Revoke({
            category: "Customer",
            reqFrmID: reqFrmID,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setModalStr("撤銷成功");
                    reGetData();
                    OpenModal("modal-ok");
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
    }
    //通過
    function Agree() {
        CloseModal("modal-customercheckagree");
        API_PUT_CustomerReview({
            reqFrmID: reqFrmID,
            reviewStatus: 1,
            reason: reason == null ? "同意" : reason,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000" || data.code == "0001") {
                    setModalStr("審核完成");
                    reGetData();
                    CloseModal("modal-customerreview");
                    OpenModal("modal-ok");
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
    }
    function openModalReason() {
        if (reason == "" || reason === undefined || reason == null) {
            document.getElementById("reason7").classList.add("error");
            return false;
        } else {
            document.getElementById("reason7").classList.remove("error");
        }
        OpenModal("modal-customercheckreason", 2);
    }
    //帳務聯絡人判斷同客戶聯絡人是否亮起
    useEffect(() => {
        if (accContactData.length != contactData.length) {
            setIsSameContactData(false);
            return false;
        }
        var isSame = true;
        for (var i = 0; i < accContactData.length; i++) {
            if (accContactData[i].custActName != contactData[i].custSalesName) {
                isSame = false;
                break;
            }
            if (accContactData[i].custActTel != contactData[i].custSalesTel) {
                isSame = false;
                break;
            }
            if (accContactData[i].custActMail != contactData[i].custSalesMail) {
                isSame = false;
                break;
            }
        }
        setIsSameContactData(isSame);
    }, [contactData, accContactData]);

    function IsAllDisabled() {

        if (reqFrmCFState == 2) {
            if (reqFrmType == 2)
                return true;
            else
                return false;
        }

        return true;
    }

    return (
        <>
            <div id="modal-customerreview" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-stamp">
                        <div className="circle">
                            <strong>
                                {(() => {
                                    if (reqFrmCFState == 2) {
                                        return "退回";
                                    } else {
                                        if (reqFrmType == 0) {
                                            return "新增";
                                        } else if (reqFrmType == 1) {
                                            return "修改";
                                        } else if (reqFrmType == 2) {
                                            return "刪除";
                                        }
                                    }
                                })()}
                            </strong>
                        </div>
                    </div>
                    <div className="modal-header">
                        <h3 className="fz-B has-border">{custName}</h3>
                        <div className="close" data-close>
                            <i className="icon icon-44"></i>
                        </div>
                        {
                            reqFrmCFState == 2 ?
                                <div className="right">
                                    審查人：{auditorName}
                                </div> :
                                null
                        }
                    </div>

                    <div className="modal-body">
                        <div className="form-container">
                            <div className="form-item must" id="companyDIV">
                                <div className="field">負責公司</div>
                                <div className="value">
                                    {companyArray.map((x) => (
                                        <div key={x.id}>
                                            <input
                                                type={reqFrmType == 1 ? "radio" : "checkbox"}
                                                id={`721-${x.id}`}
                                                name="f721"
                                                value={x.id}
                                                checked={companyIdArray.findIndex((e) => e == x.id) >= 0 ? true : false}
                                                disabled={'disabled'}
                                                onClick={e => companyIdArrayChange('f721')}
                                                data-requiredcust
                                            />
                                            <label for={`721-${x.id}`}>{x.name}</label>
                                        </div>
                                    ))}
                                </div>
                                <span className="help-word">請選擇負責公司</span>
                            </div>
                            <div className="form-item must half">
                                <div className="field">客戶別</div>
                                <div className="value">
                                    <input type="text" value={custName} onChange={e => setCustName(e.target.value)} disabled={IsAllDisabled()} data-requiredcust />
                                </div>
                                <span className="help-word">XXX</span>
                            </div>
                            <div className="form-item half">
                                <div className="field">客戶Number</div>
                                <div className="value">
                                    <input type="text" value={custNumber} onChange={e => setCustNumber(e.target.value)} disabled={IsAllDisabled()} />
                                </div>
                                <span className="help-word">XXX</span>
                                <span class="remark-word">
                                    客戶共用公司抬頭時須填寫相同客戶Number
                                </span>
                            </div>
                            <div className="form-item must half">
                                <div className="field">公司抬頭</div>
                                <div className="value">
                                    <input type="text" value={custTitle} onChange={e => setCustTitle(e.target.value)} disabled={IsAllDisabled()} data-requiredcust />
                                </div>
                                <span className="help-word">XXX</span>
                            </div>

                            <div className={`form-item half ${taxIDrequired ? 'must' : ''}`}>
                                <div className="field">公司統編/ 編號</div>
                                <div className="value">
                                    <input type="text" id="custTaxID" value={custTaxID}
                                        onChange={custTaxIDChange}
                                        disabled={IsAllDisabled()} />
                                </div>
                                <span className="help-word">XXX</span>
                            </div>

                            <div className="form-item must half">
                                <div className="field">客戶簡稱</div>
                                <div className="value">
                                    <input type="text" value={custNickName}
                                        onChange={e => setCustNickName(e.target.value)}
                                        disabled={IsAllDisabled()} data-requiredcust />

                                </div>
                                <span className="help-word">XXX</span>
                            </div>

                            <div className="form-item must half">
                                <div className="field">客戶類別</div>
                                <div className="value">
                                    <select name="" id="" value={custTypeID}
                                        onChange={(e) => setCustTypeID(e.target.value)}
                                        disabled={IsAllDisabled()} data-requiredcust>
                                        {custTypeArray.map((x) => (
                                            <option value={x.id} selected={custTypeID == x.id ? true : false} > {x.name} </option>))}
                                    </select>
                                </div>
                                <span className="help-word">XXX</span>
                            </div>

                            <div className="form-item must half">
                                <div className="field">客戶產業</div>
                                <div className="value">
                                    <select name="" id=""
                                        onChange={e => setCustINDID(e.target.value)}
                                        disabled={IsAllDisabled()} data-requiredcust>
                                        {custIndustryArray.map((x) => (
                                            <option value={x.id} selected={custINDID == x.id ? true : false} >
                                                {x.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <span className="help-word">xxx</span>
                            </div>

                            <div className="form-item must half">
                                <div className="field">隸屬國家</div>
                                <div className="value multi-inline">
                                    <select name="countryID" id="countryID" onChange={countryChange} disabled={IsAllDisabled()} data-requiredcust>
                                        <option value="">請選擇</option>
                                        {countryArray.map((x) => (
                                            <option value={x.id} selected={countryID == x.id ? true : false}  >
                                                {x.name}
                                            </option>
                                        ))}
                                    </select>
                                    {/*<div className="info">*/}
                                    {/*    <span>{tax}</span>*/}
                                    {/*</div>*/}
                                </div>
                                <span className="help-word">請輸入隸屬國家</span>
                                <span class="remark-word">
                                    發票應稅，Invoice免稅
                                </span>
                            </div>
                            <div className="form-item must half">
                                <div className="field">客戶地址</div>
                                <div className="value">
                                    <input id="custAddrText" type="text" value={custAddr} onChange={e => setCustAddr(e.target.value)} disabled={IsAllDisabled()} data-requiredcust />
                                </div>
                                <span className="help-word">請輸入客戶地址</span>
                            </div>

                            {/*  <div className="form-item must half"></div>*/}
                            <div className="form-item must half">
                                <div className="field">交易幣別</div>
                                <div className="value">
                                    <select name="currencyID" id="currencyID" onChange={e => setCurrencyID(e.target.value)} disabled={IsAllDisabled()}>
                                        <option value="">請選擇</option>
                                        {currencyBaseArray.map((x) => (
                                            <option value={x.id} selected={currencyID == x.id ? true : false} >
                                                {x.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <span className="help-word">xxx</span>
                            </div>

                            {/*<div className="form-item half">*/}
                            {/*    <div className="field">發票類別</div>*/}
                            {/*    <div className="value">*/}
                            {/*        <input type="text" value={Invoice}*/}
                            {/*            disabled='disabled' />*/}
                            {/*    </div>*/}
                            {/*    <span className="help-word"></span>*/}
                            {/*</div>*/}
                            <div className="form-item must half has-datalist" data-datalist>
                                <div className="field">負責業務</div>
                                <div className="value">
                                    <input id="adeNameText" type="text" value={adeName} onChange={adeNameChange} autocomplete="off" disabled={IsAllDisabled()} data-requiredcust />
                                    <ul className="datalist scrollbar">
                                        {adeNameArray.map((x) => (
                                            <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => { setAdeName(x.enName), setAdeID(x.id) }}>
                                                <span className="flex-4">{x.enName}({x.tc_name})</span>
                                                <span className="flex-5">{x.mail}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <span className="help-word">
                                    XXX
                                </span>
                            </div>

                            <div className="form-item must half  " id="departmentDIV">
                                <div className="field">業務單位</div>
                                <div className="value">
                                    {departmentArray.map((x) => (
                                        <>
                                            <input
                                                key={x.id}
                                                type="radio"
                                                id={`722-${x.id}`}
                                                name="f722"
                                                value={x.id}
                                                checked={cmpDivID == x.id ? "checked" : ""}
                                                onClick={(e) => { setCmpDivID(x.id); }}
                                                disabled={IsAllDisabled()}
                                            />
                                            <label htmlFor={`722-${x.id}`}>{x.name + "　"}</label>
                                        </>
                                    ))}
                                </div>
                                <span className="help-word"></span>
                            </div>

                            <div className="form-item col must">
                                <div className="field">客戶聯絡人</div>
                                <div className="value">
                                    <ul className="list-table">
                                        <li className="lt-row lt-th">
                                            <div className="flex-1">序號</div>
                                            <div className="flex-3">姓名</div>
                                            <div className="flex-3">聯絡電話</div>
                                            <div className="flex-3">聯絡信箱</div>
                                            <div className="flex-2"></div>
                                        </li>
                                        {contactData.map((x, i) =>
                                            IsAllDisabled() == false ? (
                                                <li className="lt-row">
                                                    <div className="flex-1">
                                                        {i < 9 ? `0${i + 1}` : i + 1}
                                                    </div>
                                                    <div className="flex-3">
                                                        <input type="text" value={x.custSalesName} onChange={e => contactChange(e, i, "custSalesName")} data-requiredcust />
                                                    </div>
                                                    <div className="flex-3">
                                                        <input type="tel" value={x.custSalesTel} onChange={e => contactChange(e, i, "custSalesTel")} maxLength="12" data-requiredcust />
                                                    </div>
                                                    <div className="flex-3">
                                                        <input type="email" value={x.custSalesMail} onChange={e => contactChange(e, i, "custSalesMail")} data-requiredcust />
                                                    </div>
                                                    <div className="flex-2 fn">
                                                        {
                                                            i == 0 ? null :
                                                                <div className={`btn btn-small btn-border`} onClick={(e) => { x.custSalesID == undefined ? delContactItem(i) : returnContactItem(i, false); }}>
                                                                    <span>取消 </span>
                                                                </div>
                                                        }
                                                    </div>
                                                </li>
                                            )
                                                :
                                                (
                                                    <li className="lt-row">
                                                        <div className="flex-1">
                                                            {i < 11 ? `0${i + 1}` : i + 1}
                                                        </div>
                                                        <div className="flex-3">{x.custSalesName}</div>
                                                        <div className="flex-3">{x.custSalesTel}</div>
                                                        <div className="flex-3">{x.custSalesMail}</div>
                                                        <div className="flex-2 fn"></div>
                                                    </li>
                                                )
                                        )}
                                    </ul>

                                    {
                                        reqFrmCFState == 2 ?
                                            <div className="btn-area">
                                                <div class="info"></div>
                                                <div className={`btn btn-small btn-border ${IsAllDisabled() == true ? "disabled" : ""}`} onClick={addContactData}>
                                                    <span>新增業務聯絡人</span>
                                                </div>
                                            </div>
                                            : null
                                    }

                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>

                            <hr />

                            <div className="form-item must" id="paymentTermsDIV">
                                <div className="field">付款條件</div>
                                <div className="value">
                                    {paymentTermsArray.map((x) => (
                                        <div>
                                            <input
                                                type="radio"
                                                id={`f723-${x.id}`}
                                                name="f723"
                                                value={x.id}
                                                key={x.id}
                                                checked={custPayID == x.id ? "checked" : ""}
                                                disabled={IsAllDisabled()}
                                                onClick={(e) => { setCustPayID(x.id); }} />
                                            <label htmlFor={`f723-${x.id}`}>
                                                {" "}
                                                {x.name == "0" ? "預付" :
                                                    x.name == "1" ? "即期" :
                                                        x.name + "天"}
                                                {" "}
                                            </label>
                                        </div>
                                    ))}

                                </div>
                                <span className="help-word">請選擇付款條件</span>
                            </div>
                            <div className="form-item must half" id="ICPDIV">
                                <div className="field">ICP 客戶</div>
                                <div className="value">
                                    <div>
                                        <input
                                            type="radio"
                                            id="f724-1"
                                            name="f724"
                                            value="true"
                                            checked={custIsICP == true ? "checked" : ""}
                                            onClick={(e) => { setCustIsICP(true); }}
                                            disabled={IsAllDisabled()}
                                        />
                                        <label htmlFor="f724-1">是</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            id="f724-2"
                                            name="f724"
                                            value="false"
                                            checked={custIsICP == false ? "checked" : ""}
                                            onClick={(e) => { setCustIsICP(false); }} disabled={IsAllDisabled()}
                                        />
                                        <label for="f724-2">否</label>
                                    </div>

                                </div>
                                <span className="help-word">請輸入ICP客戶</span>
                            </div>
                            <div className="form-item half">
                                <div className="field">銷帳帳號</div>
                                <div className="value">
                                    <input type="text" value={custChargeOffAcc} onChange={e => setCustChargeOffAcc(e.target.value)} disabled={IsAllDisabled()} />
                                </div>
                                <span class="help-word">
                                    請輸入正確格式
                                </span>
                            </div>

                            <div className="form-item col">
                                <div className="field">匯款銀行</div>
                                <div className="value">
                                    <ul className="list-table">
                                        <li className="lt-row lt-th">
                                            <div className="flex-1">序號</div>
                                            <div className="flex-3">銀行名稱</div>
                                            <div className="flex-3">分行</div>
                                            <div className="flex-3">匯款帳號</div>
                                            <div className="flex-2">幣別</div>
                                            <div className="flex-2"></div>
                                        </li>
                                        {bankData && bankData.map((x, i) => (
                                            IsAllDisabled() == false ? (
                                                <li className="lt-row">
                                                    <div className="flex-1">
                                                        {i < 11 ? `0${i + 1}` : i + 1}
                                                    </div>
                                                    <div className="flex-3">
                                                        <input type="text" value={x.custBankName} onChange={e => bankDataChange(e, i, "custBankName")} disabled={IsAllDisabled()} data-requiredcust />
                                                    </div>
                                                    <div className="flex-3">
                                                        <input type="tel" value={x.custBankBranch} onChange={e => bankDataChange(e, i, "custBankBranch")} disabled={IsAllDisabled()} maxLength="12" data-requiredcust />
                                                    </div>
                                                    <div className="flex-3">
                                                        <input type="email" value={x.custBankAct} onChange={e => bankDataChange(e, i, "custBankAct")} disabled={IsAllDisabled()} data-requiredcust />
                                                    </div>
                                                    <div className="flex-2">
                                                        {
                                                            x.custBankCurrencies == null || x.custBankCurrencies.length == 0 ?
                                                                <div className={`btn btn-small btn-border`} id={`bank_${i}`} onClick={(e) => openModalCurrency(i)}>
                                                                    <span>選擇幣別</span>
                                                                </div>
                                                                :
                                                                <a href="#" onClick={(e) => openModalCurrency(i)}>
                                                                    {
                                                                        x.custBankCurrencies.map((x2, i2) =>
                                                                            currencyBaseArray.map(x3 =>
                                                                                x3.id == x2.currID ? x3.name + (i2 == x.custBankCurrencies.length - 1 ? '' : '/') : null
                                                                            )
                                                                        )
                                                                    }

                                                                </a>
                                                        }
                                                    </div>
                                                    <div className="flex-2 fn">
                                                        {
                                                            i == 0 ? null :
                                                                x.id == undefined ?
                                                                    (
                                                                        <div
                                                                            className={`btn btn-small btn-border ${IsAllDisabled() == true ? "disabled" : ""}`} onClick={(e) => delVBankItem(i)} data-index={i} >
                                                                            <span>取消</span>
                                                                        </div>
                                                                    )
                                                                    : null
                                                        }

                                                    </div>
                                                </li>
                                            )
                                                :
                                                (
                                                    <li className="lt-row">
                                                        <div className="flex-1">
                                                            {i < 11 ? `0${i + 1}` : i + 1}
                                                        </div>
                                                        <div className="flex-3">{x.custBankName}</div>
                                                        <div className="flex-3">{x.custBankBranch}</div>
                                                        <div className="flex-3">{x.custBankAct}</div>
                                                        <div className="flex-2">
                                                            {
                                                                x.custBankCurrencies == null || x.custBankCurrencies.length == 0
                                                                    ? ""
                                                                    : x.custBankCurrencies.map((x2, i2) =>
                                                                        currencyBaseArray.map(x3 =>
                                                                            x3.id == x2.currID ? x3.name + (i2 == x.custBankCurrencies.length - 1 ? '' : '/') : null
                                                                        )
                                                                    )
                                                            }
                                                        </div>
                                                        <div className="flex-2 fn">

                                                        </div>
                                                    </li>
                                                )

                                        ))}
                                    </ul>
                                    {
                                        reqFrmCFState == 2 ?
                                            <div className="btn-area">
                                                <div className="info"></div>
                                                <div className={`btn btn-middle btn-border ${IsAllDisabled() == true ? "disabled" : ""}`} onClick={addBankData}  >
                                                    <span>新增匯款銀行</span>
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <hr />
                            <div className="form-item must half auto-field-xl" id="isSendDiv">
                                <div className="field">對帳單寄送</div>
                                <div className="value">
                                    <div>
                                        <input type="radio" id="f725-1" name="f725-1"
                                            checked={custIsSend == true ? true : false}
                                            onClick={(e) => { setCustIsSend(true) }}
                                            disabled={IsAllDisabled()}
                                        />
                                        <label htmlFor="f725-1">是</label>
                                    </div>
                                    <div>
                                        <input type="radio" id="f725-2" name="f725-1"
                                            checked={custIsSend == false ? true : false}
                                            onClick={(e) => { setCustIsSend(false); }}
                                            disabled={IsAllDisabled()}
                                        />
                                        <label htmlFor="f725-2">否</label>
                                    </div>
                                </div>
                                <span className="help-word">請選擇對帳單寄送</span>
                            </div>

                            <div className="form-item must half auto-field-xl" id="isCloudSendDiv">
                                <div className="field">雲端明細寄送</div>
                                <div className="value">
                                    <div>
                                        <input type="radio" id="f725-3" name="f725-2" checked={cloudSend ? true : false}
                                            onClick={() => setCloudSend(true)}
                                            disabled={IsAllDisabled()}
                                        />
                                        <label htmlFor="f725-3">是</label>
                                    </div>
                                    <div>
                                        <input type="radio" id="f725-4" name="f725-2" checked={cloudSend == false ? true : false}
                                            onClick={() => setCloudSend(false)}
                                            disabled={IsAllDisabled()}
                                        />
                                        <label htmlFor="f725-4">否</label>
                                    </div>
                                </div>
                                <span className="help-word">請選擇雲端明細寄送</span>
                            </div>

                            <div className={`form-item half auto-field-xl `}>
                                <div className="field">對帳單密碼</div>
                                <div className="value multi-inline">
                                    <input type="text" id="custBankPass" value={custBankPass}
                                        onChange={custBankPassChange}
                                        disabled={IsAllDisabled()}
                                        data-required={false}
                                    />
                                    <div>
                                        <input
                                            type="radio"
                                            id="f726-1"
                                            name="f726"
                                            onClick={F726change}
                                            checked={F726 == true ? true : false}
                                            disabled={IsAllDisabled()}
                                        />
                                        <label htmlFor="f726-1">與統編相同</label>
                                    </div>
                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>

                            <div className="form-item col must">
                                <div className="field">
                                    帳務聯絡人
                                    {
                                        IsAllDisabled() == false ?
                                            <div>
                                                <input type="radio" id="sameContactData" onClick={sameContactData} checked={isSameContactData ? true : false} />
                                                <label htmlFor="sameContactData">同客戶聯絡人</label>
                                            </div>
                                            : null
                                    }

                                </div>
                                <div className="value">
                                    <ul className="list-table">
                                        <li className="lt-row lt-th">
                                            <div className="flex-1">序號</div>
                                            <div className="flex-3">姓名</div>
                                            <div className="flex-3">聯絡電話</div>
                                            <div className="flex-3">聯絡信箱</div>
                                        </li>
                                        {
                                            accContactData == null || accContactData.length == 0 ? null :
                                                accContactData.map((x, i) => (
                                                    IsAllDisabled() == false ? (
                                                        <li className="lt-row">
                                                            <div className="flex-1">
                                                                {i < 11 ? `0${i + 1}` : i + 1}
                                                            </div>
                                                            <div className="flex-3">
                                                                <input
                                                                    type="text"
                                                                    value={x.custActName}
                                                                    onChange={e => accContactChange(e, "custActName")}
                                                                    data-index={i}
                                                                    data-requiredcust
                                                                />
                                                            </div>
                                                            <div className="flex-3">
                                                                <input
                                                                    type="tel"
                                                                    value={x.custActTel}
                                                                    onChange={e => accContactChange(e, "custActTel")}
                                                                    maxLength="12"
                                                                    data-index={i}
                                                                    data-requiredcust
                                                                />
                                                            </div>
                                                            <div className="flex-3">
                                                                <input
                                                                    type="email"
                                                                    value={x.custActMail}
                                                                    onChange={e => accContactChange(e, "custActMail")}
                                                                    data-index={i}
                                                                    data-requiredcust
                                                                />
                                                            </div>
                                                            <div className="flex-2 fn">
                                                                {
                                                                    accContactData.length <= 1 ? null :
                                                                        <div className="btn btn-small btn-border" onClick={(e) => { delAccContactItem(i) }}>
                                                                            <span>取消 </span>
                                                                        </div>
                                                                }

                                                            </div>
                                                        </li>
                                                    )
                                                        :
                                                        (
                                                            <li className="lt-row">
                                                                <div className="flex-1">
                                                                    {i < 11 ? `0${i + 1}` : i + 1}
                                                                </div>
                                                                <div className="flex-3">{x.custActName}</div>
                                                                <div className="flex-3">{x.custActTel}</div>
                                                                <div className="flex-3">{x.custActMail}</div>
                                                            </li>
                                                        )

                                                ))}
                                    </ul>
                                    {
                                        reqFrmCFState == 2 ?
                                            <div className="btn-area">
                                                <div class="info"></div>
                                                <div className={`btn btn-small btn-border ${IsAllDisabled() == true ? 'disabled' : ''}`} onClick={addAccContactData}  >
                                                    <span>新增客戶聯絡人</span>
                                                </div>
                                            </div> : null
                                    }
                                </div>
                                <span className="help-word">請輸入正確格式</span>
                            </div>

                            <div className="form-item btn-area">
                                {
                                    reqFrmCFState == 2 ?
                                        <>
                                            <a className="btn btn-border" onClick={Revoke} >
                                                <span>撤銷</span>
                                            </a>
                                            <a className="btn" onClick={() => OpenModal("modal-customeragain", 2)}>
                                                <span>再次送審</span>
                                            </a>
                                        </>
                                        :
                                        reqFrmCFState == 3 ? null :
                                            <>
                                                <a className="btn btn-border" onClick={openModalReason}>
                                                    <span>退回</span>
                                                </a>
                                                <a className="btn" onClick={(e) => OpenModal("modal-customercheckagree", 2)}>
                                                    <span>確定</span>
                                                </a>

                                            </>
                                }


                            </div>
                            <div className="form-item must col feedback">
                                <div className="field">退回原因</div>
                                <div className="value">
                                    <textarea
                                        name="reason"
                                        id="reason7"
                                        cols="30"
                                        rows="6"
                                        placeholder="請輸入退回原因"
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                        disabled={reqFrmCFState == 2 || reqFrmCFState == 3 ? true : false}
                                    ></textarea>
                                </div>
                                <span className="help-word">請輸入退回原因</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-customercheckreason" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確認要退回此筆資料？</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-customercheckreason")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={Reason}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-customercheckagree" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確認要通過此筆資料？</div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-customercheckagree")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={Agree}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            <div id="modal-customeragain" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定再次送審?
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-customeragain")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={AgainSend}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-customerrevoke" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定撤銷?
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-customerrevoke")}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={RevokeSend}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-currency" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">選擇幣別</h3>
                        <span className="close" onClick={() => CloseModal("modal-currency")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-item no-field">
                            <div className="field">選擇幣別</div>
                            <div className="value">
                                {currencyBaseArray.map((x) => (
                                    <div>
                                        <input
                                            type="checkbox"
                                            id={`f727-${x.id}`}
                                            name="f727"
                                            value={x.id}
                                            key={x.id}
                                            onClick={currencyDataChange}
                                            disabled={reqFrmCFState == 2 ? null : 'disabled'}
                                        />
                                        <label htmlFor={`f727-${x.id}`}>{x.name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-currency")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomerDetail;
