import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_AdNameList,
    API_PUT_VendorData,
    API_DEL_VendorData,
    API_GET_VendorQuery,
    API_POST_VendorDownLoad,
    API_GET_AuditHistory
} from "../../global/constants";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, UrlDecode, InitJS, SetOnbeforeunload, MenuSet, SetHasChange } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
import { clone, cloneDeep } from 'lodash';

const SupplierEdit = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);

    const [forceDisabled, setForceDisabled] = useState(false);

    const [bankDataErrorIndexs, setBankDataErrorIndexs] = useState([]);

    useEffect(() => {
        //把menu顏色置換
        MenuSet("SupplierQry");
        getdata();
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    function reflash() {

        window.location.reload();
    }
    function returnQry() {
        console.log("跳到查詢頁");
        history.push("/Main?path=SupplierQry");
    }
    //負責PM
    function getAdName() {
        API_GET_AdNameList({
            input: document.getElementById("adNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {

                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setAdNameArray(data.result.data);
                }
            }).catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }


    function getdata() {
        var dt = {
            vendorName: queryParams.get("vName"),
            ReqFrmID: parseInt(queryParams.get("reqFrmID")),
            companyId: queryParams.get("companyId"),
            id: queryParams.get("id"),
        };
        //console.log(JSON.stringify(dt));
        API_GET_VendorQuery(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                //審核中
                if (data.code == "4046") {
                    setIsReviewing(true);
                    setReviewerArr(data.result.data.vendor.reviewerArr);
                    //顯示modal
                    OpenModal("modal-review");
                }
                //沒有供應商回傳4035
                if (data.code == "4035") {
                    setModalStr(data.message);
                    OpenModal("modal-4035", 2)
                    return;
                }
                //2代表退回
                if (data.result.reviewStatus == 2) {
                    setIsDrawBack(true);
                    setDrawBackReason(data.result.drawBackReason);
                    setDrawBacker(data.result.drawBacker);
                }

                //負責公司
                setCompanyArray(data.result.data.dataCard.companyArray);
                //業務單位
                setDepartmentArray(data.result.data.dataCard.departmentArray);
                //付款條件
                setSettlementArray(data.result.data.dataCard.monthSettleDaysArray);
                //交易幣別Array
                setCurrencyBaseArray(data.result.data.dataCard.currencyBaseList);
                //-----------------------------------------//
                setTitleName(data.result.data.vendor.venName);
                setCompanyIdArray(data.result.data.vendor.companyIdArray);
                setOldVendoridArray(data.result.data.vendor.oldVendoridArray);
                setVenBIZNo(data.result.data.vendor.venBIZNo);
                setVenNumber(data.result.data.vendor.venNumber);
                setVenPhone(data.result.data.vendor.venPhone);
                setVenFax(data.result.data.vendor.venFax);
                setVenAddress(data.result.data.vendor.venAddress);
                setVenID(data.result.data.vendor.venID);
                setAdName(data.result.data.pmName);
                setAdID(data.result.data.vendor.pmid);
                setSupplierName(data.result.data.vendor.venName);
                setSupplierTitle(data.result.data.vendor.venTitle);
                //月結天數
                setSetlementId(data.result.data.vendor.venMonID);
                //負責單位
                setCmpDivID(data.result.data.vendor.cmpDivID);
                setMemo(data.result.data.vendor.venREM);
                setBankData(data.result.data.vendor.vendorBanks.map(obj => ({ ...obj, IsEdit: false })));
                setBankDataSource(cloneDeep(data.result.data.vendor.vendorBanks.map(obj => ({ ...obj, IsEdit: false }))));
                setContactData(data.result.data.vendor.vendorContacts.map(obj => ({ ...obj, IsEdit: false })));
                setContactDataSource(cloneDeep(data.result.data.vendor.vendorContacts.map(obj => ({ ...obj, IsEdit: false }))));
                //審查人
                setAuditorName(data.result.data.auditorName);
                if (localStorage.getItem("userID") == data.result.cfUserID && data.result.reviewStatus == 2) {
                    setIsDrawBack(true);

                }
                //退回原因
                setDrawBackReason(data.result.drawBackReason);

            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //審核中
    const [isReviewing, setIsReviewing] = useState(false);
    //審核人
    const [reviewerArr, setReviewerArr] = useState([]);
    const [reviewerShowArr, setReviewerShowArr] = useState([]);
    useEffect(() => {
        if (reviewerArr && reviewerArr.length > 0) {
            const chunkedArray = [];
            for (let i = 0; i < reviewerArr.length; i += 3) {
                chunkedArray.push(reviewerArr.slice(i, i + 3));
            }
            setReviewerShowArr(chunkedArray);
        }
    }, [reviewerArr]);
    //退回中
    const [isDrawBack, setIsDrawBack] = useState(false);
    //退回的審核人
    const [drawBacker, setDrawBacker] = useState('');
    //退回原因
    const [drawBackReason, setDrawBackReason] = useState();
    //審核人
    const [auditorName, setAuditorName] = useState('');
    //修改文字
    const [modalStr, setModalStr] = useState();
    //帳務聯絡人
    const [contactData, setContactData] = useState([{ venConName: null, venConTel: null, venConEmail: null },]);
    const [contactDataSource, setContactDataSource] = useState([{ venConName: null, venConTel: null, venConEmail: null },]);

    //要修改原本的資料,把isEdit變成true
    function changeContactItem(index, isEdit) {
        SetHasChange(true);

        setContactData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.IsEdit = isEdit;
                    if (!isEdit) {
                        item = cloneDeep(contactDataSource.find(x => x.venConID == item.venConID));
                    }
                }
                return item;
            });
        });
    }


    //刪除帳務聯絡人
    function delContactItem(index) {
        SetHasChange(true);

        var newContactData = [...contactData];

        var tempContactData = newContactData[index];
        if (tempContactData.venConID && tempContactData.venConID > 0) {
            tempContactData.flag = "D";

            newContactData[index] = tempContactData;
            setContactData(newContactData);
        }
        else {
            setContactData(function (prev) {
                return prev.filter((x, i) => i != index);
            });
        }
    }
    function addContactData() {
        SetHasChange(true);

        setContactData(function (prev) {
            return [...prev, { flag: "A", venConName: null, venConTel: null, venConEmail: null, IsEdit: true },];
        });
    }
    function contactDataChange(e, index, col) {
        SetHasChange(true);

        setContactData(function (prev) {
            return prev.map((item, i) => {
                if (item.venConID && item.flag != "D") {
                    item.flag = "M";
                }

                if (i == index) {
                    if (col == "venConName") {
                        item.venConName = e.target.value;
                    } else if (col == "venConTel") {
                        item.venConTel = e.target.value;
                    } else if (col == "venConEmail") {
                        item.venConEmail = e.target.value;
                    }
                }
                return item;
            });
        });
    }
    //交易幣別Array
    const [currencyBaseArray, setCurrencyBaseArray] = useState([{}]);
    function currencyDataChange() {

        var tmpArray = [];
        var f827 = document.getElementsByName("f827");
        for (var i = 0; i < f827.length; i++) {
            if (f827[i].checked) {
                tmpArray.push({ venBankCurrID: parseInt(f827[i].value) });
            }
        }
        setBankData(function (prev) {
            return prev.map((item, i) => {
                if (i == currencyIndex) {
                    item.venBankExRates = tmpArray;
                }
                return item;
            });
        });
        //console.log(bankData);
    }

    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyIdArray, setCompanyIdArray] = useState([]);
    function companyIdArrayChange() {

        var tmpArray = [];
        var f821 = document.getElementsByName("f821");
        for (var i = 0; i < f821.length; i++) {
            if (f821[i].checked) {
                tmpArray.push(parseInt(f821[i].value));
            }
        }

        //有找到香港果核
        if (tmpArray.find(x => x == 1) != null) {
            let tmp = settlementArray.find(x => x.name == 90);
            if (tmp && tmp.id == setlementId) {
                setSetlementId(null);
            }
        }

        setCompanyIdArray(tmpArray);
    }
    const [isCompanyError, setIsCompanyError] = useState(false);

    const [oldVendoridArray, setOldVendoridArray] = useState([]);
    const [adName, setAdName] = useState("");
    function adNameChange(e) {
        // setAdName(e.target.value);
        // getAdName();
        if (e.target.value == '') {
            setAdName("");
            setAdID(null);
        } else {
            setAdName(e.target.value);
            getAdName();
        }
    }
    const [adID, setAdID] = useState();
    //產品負責人datalist
    const [adNameArray, setAdNameArray] = useState([{}]);

    //開畫面被選擇的幣別
    const [currencyData, setCurrencyData] = useState([]);


    //客戶別
    const [titleName, setTitleName] = useState("");
    //業務單位
    const [departmentArray, setDepartmentArray] = useState([{}]);
    const [cmpDivID, setCmpDivID] = useState();
    const [isCmpError, setIsCmpError] = useState(false);
    //月結天數
    const [settlementArray, setSettlementArray] = useState([{}]);
    const [setlementId, setSetlementId] = useState();
    const [isCustPayError, setIsCustPayError] = useState(false);

    //供應商
    const [supplierName, setSupplierName] = useState("");
    function supplierNameChange(e) {
        setSupplierName(e.target.value);
    }
    //供應商抬頭
    const [supplierTitle, setSupplierTitle] = useState("");
    function supplierTitleChange(e) {

        setSupplierTitle(e.target.value);
    }
    const [venID, setVenID] = useState();
    //統編
    const [venBIZNo, setVenBIZNo] = useState("");
    //ERPID
    const [venNumber, setVenNumber] = useState();
    //公司電話
    const [venPhone, setVenPhone] = useState();
    //傳真
    const [venFax, setVenFax] = useState();
    //傳真
    const [venAddress, setVenAddress] = useState();
    function venBIZNoChange(e) {
        var v = e.target.value.replace(/[^A-Za-z0-9]/g, '');
        setVenBIZNo(v);
    }
    //銀行序號
    const [bankData, setBankData] = useState([
        {
            venBankRemitBank: null,
            benBankRemitBranch: null,
            venBankRemitAcnt: null,
            VenBankExRates: [],
        },
    ]);

    const [bankDataSource, setBankDataSource] = useState([
        {
            venBankRemitBank: null,
            benBankRemitBranch: null,
            venBankRemitAcnt: null,
            VenBankExRates: [],
        },
    ]);

    function addBankData() {
        SetHasChange(true);

        setBankData(function (prev) {
            return [
                ...prev,
                {
                    flag: "A",
                    venBankRemitBank: null,
                    venBankRemitBranch: null,
                    venBankRemitAcnt: null,
                    venBankExRates: [],
                    IsEdit: true
                },
            ];
        });
    }

    //要修改原本的資料,把isEdit變成true
    function changeBankItem(index, isEdit) {
        SetHasChange(true);

        setBankData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.IsEdit = isEdit;
                    if (!isEdit) {
                        item = cloneDeep(bankDataSource.find(x => x.venBankID == item.venBankID));
                    }
                }
                return item;
            });
        });
    }

    function venBankDataChange(e, index, col) {
        setBankData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (item.venBankID && item.flag != "D") {
                        item.flag = "M"
                    }

                    if (col == "venBankRemitBranch") {
                        item.venBankRemitBranch = e.target.value;
                    } else if (col == "venBankRemitAcnt") {
                        item.venBankRemitAcnt = e.target.value;
                    } else if (col == "venBankRemitBank") {
                        item.venBankRemitBank = e.target.value;
                    } else if (col == "venBankRemitSWIFTCode") {
                        item.venBankRemitSWIFTCode = e.target.value;
                    }
                }
                return item;
            });
        });
    }

    //刪除匯款銀行
    function delBankItem(index) {
        SetHasChange(true);

        var newBankData = [...bankData];

        var tempBankData = newBankData[index];
        if (tempBankData.venBankID && tempBankData.venBankID > 0) {
            tempBankData.flag = "D";

            newBankData[index] = tempBankData;
            setBankData(newBankData);
        }
        else {
            setBankData(function (prev) {
                return prev.filter((x, i) => i != index);
            });
        }
    }
    //備註
    const [memo, setMemo] = useState();
    function memoChange(e) {

        setMemo(e.target.value);
    }

    //陣列幾
    const [currencyIndex, setCurrencyIndex] = useState();
    //打開幣別
    function openModal10(index) {
        setCurrencyIndex(index);
        var a = bankData[index].venBankExRates;
        if (a == null) a = [];
        var tmpb = [];
        for (var i = 0; i < a.length; i++) {
            tmpb.push(a[i].venBankCurrID);
        }

        var f827 = document.getElementsByName("f827");
        for (var i = 0; i < f827.length; i++) {
            if (tmpb.indexOf(parseInt(f827[i].value)) >= 0) {
                f827[i].checked = true;
            } else {
                f827[i].checked = false;
            }
        }
        OpenModal("modal-10");
    }
    //資料檢查
    function checkData() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {
            if (element.value == "") {
                element.classList.add("error");
                result = false;
            }
            else {
                element.classList.remove("error");
            }
        });
        //負責公司
        if (companyIdArray.length == 0) {
            result = false;
            setIsCompanyError(true);
        } else {
            setIsCompanyError(false);
        }

        //負責單位
        var bf822 = false;
        var f822 = document.getElementsByName("f822");
        for (var i = 0; i < f822.length; i++) {
            if (f822[i].checked) {
                bf822 = true;
                break;
            }
        }
        if (bf822 == false) {
            setIsCmpError(true);
            result = false;
        } else {
            setIsCmpError(false);
        }
        //月結天數
        var bf823 = false;
        var f823 = document.getElementsByName("f823");
        for (var i = 0; i < f823.length; i++) {
            if (f823[i].checked) {
                bf823 = true;
                break;
            }
        }
        if (bf823 == false) {
            setIsCustPayError(true);
            result = false;
        } else {
            setIsCustPayError(false);
        }

        //選擇幣別
        let tempArray = [...bankDataErrorIndexs];
        for (var i = 0; i < bankData.length; i++) {
            let tempTargetIndex = tempArray.findIndex(x => x.index === i);
            if ((bankData[i].venBankExRates == null || bankData[i].venBankExRates.length == 0) && bankData[i].flag != "D") {
                changeBankItem(i, true);

                if (tempTargetIndex === -1) {
                    tempArray.push({
                        index: i,
                        action: "add"
                    });
                }
                else {
                    tempArray[tempTargetIndex].action = "add";
                }
                result = false;

            } else {
                if (tempTargetIndex === -1) {
                    tempArray.push({
                        index: i,
                        action: "del"
                    });
                }
                else {
                    tempArray[tempTargetIndex].action = "del";
                }
            }
        }

        setBankDataErrorIndexs(tempArray);
        return result;
    }

    useEffect(() => {
        console.log(bankDataErrorIndexs);
        bankDataErrorIndexs.forEach(element => {
            if (document.getElementById("bank_" + element.index)) {
                if (element.action == "add") {
                    document.getElementById("bank_" + element.index).setAttribute("style", "border-color:#F37402;color:#F37402");
                }
                else if (element.action == "del") {
                    document.getElementById("bank_" + element.index).removeAttribute("style");
                }
            }
        });

    }, [bankDataErrorIndexs])

    function GeneratePostData() {
        var VendorsArray = [];
        for (var i = 0; i < companyIdArray.length; i++) {
            VendorsArray.push({
                VenName: supplierName,
                VenTitle: supplierTitle,
                VenBIZNo: venBIZNo,
                VenPhone: venPhone,
                VenFax: venFax,
                VenAddress: venAddress,
                VenNumber: venNumber,
                CmpID: parseInt(companyIdArray[i]), //
                CmpDivID: parseInt(cmpDivID), //負責單位
                VenMonID: parseInt(setlementId), //月結天數
                PMID: adID,
                VenRem: memo,
                reqFrmID: queryParams.get("reqFrmID"),
            });
        }

        return {
            companyIdArray: companyIdArray,
            oldVendoridArray: oldVendoridArray,
            VendorContacts: contactData,
            VendorBanks: bankData,
            Vendors: VendorsArray,
        };
    }

    //確定修改
    function Edit(e) {
        //禁止連點
        e.target.classList.add("disabled");
        var result = checkData();
        if (result) {

            var dt = GeneratePostData();

            API_PUT_VendorData(dt)
                .then((response) => response.data)
                .then((data) => {
                    e.target.classList.remove("disabled");
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setModalStr(data.message);
                    if (data.code == "0000" || data.code == "0001") {
                        OpenModal("modal-edit");
                        SetHasChange(false);
                    } else {
                        OpenModal("modal-error");
                    }
                })
                .catch((err) => {
                    e.target.classList.remove("disabled");

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            e.target.classList.remove("disabled");
        }
    }

    //確定刪除
    function Del(e) {
        var VendorsArray = [];
        for (var i = 0; i < companyIdArray.length; i++) {
            VendorsArray.push({
                venID: venID,
                VenName: supplierName,
                VenTitle: supplierTitle,
                VenBIZNo: venBIZNo,
                CmpID: parseInt(companyIdArray[i]), //
                CmpDivID: parseInt(cmpDivID), //負責單位
                VenMonID: parseInt(setlementId), //月結天數
                PMID: adID,
                VenRem: memo,
                reqFrmID: queryParams.get("reqFrmID"),
            });
        }
        var dt = {
            companyIdArray: companyIdArray,
            oldVendoridArray: oldVendoridArray,
            vendorName: titleName,
            reqFrmID: queryParams.get("reqFrmID"),
            vendorBanks: bankData,
            vendorContacts: contactData,
            Vendors: VendorsArray,
        };
        //console.log(JSON.stringify(dt));
        setForceDisabled(true)

        API_DEL_VendorData(dt)
            .then((response) => response.data)
            .then((data) => {
                //if (!checkAuthToken(data)) { history.push('/') };
                if (!CheckResponseCode(data)) { return; } //檢查response code
                var id = "modal-del";
                var target = document.querySelector("#" + id);
                var layerClass = "layer-1";
                target.classList.remove("active");
                target.classList.remove(layerClass);
                setModalStr(data.message);
                if (data.code == "0000" || data.code == "0001") {
                    OpenModal("modal-delok");
                } else {
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            }).finally(() => {
                setForceDisabled(false);

            });
    }


    //下載資料卡
    const FileDownload = require("js-file-download");
    function downLoad() {
        if (oldVendoridArray.length > 0) {
            for (var i = 0; i < oldVendoridArray.length; i++) {
                var dt = GeneratePostData();
                dt.DownloadFrom = 1;

                API_POST_VendorDownLoad(dt).then((response) => {
                    const disposition = response.headers['content-disposition'];
                    var filename = disposition.split("''")[1];
                    FileDownload(response.data, UrlDecode(filename));
                })
                    .catch((err) => {
                        if (err.response.status === 401 || (err.response.data != null && err.response.data.code == "1001")) {
                            localStorage.clear();
                            history.push("/");
                        }

                        setModalStr(err.message);
                        OpenModal("modal-error");
                    });
            }
        }
    }

    const [historyList, setHistoryList] = useState([{}]);
    //顯示審核歷程
    function openModalHistory() {

        API_GET_AuditHistory({
            id: venID,
            category: "Vendor",
            name: queryParams.get("vName"),
            companyId: queryParams.get("companyId")
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setHistoryList(data.result.data);

            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        OpenModal("modal-history");
    }

    useEffect(() => {
        console.log("匯款銀行_ViewData", bankData);
        console.log("聯絡人_ViewData", contactData);
    }, [bankData, contactData]);

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">{titleName}</h3>
                    <div class="right">
                        <button class="btn btn-border btn-middle" onClick={openModalHistory}>
                            <span>審核歷程</span>
                        </button>
                    </div>
                </div>
            </div>
            {
                isReviewing ?
                    <div className="review-area">
                        <div className="container">
                            <div className="r-container">
                                <strong>審查人：</strong>
                                {
                                    reviewerShowArr &&
                                    reviewerShowArr.map((item, index) => {
                                        return (
                                            <ul key={index}>
                                                {item.map((subitem, innerIndex) => (
                                                    <li key={innerIndex}>{subitem}</li>
                                                ))}
                                            </ul>
                                        )
                                    })
                                }
                                {/*<ul>*/}
                                {/*    {*/}
                                {/*        reviewerArr.map((item, i) => (*/}
                                {/*            <li key={i}>{item}</li>*/}
                                {/*        ))*/}
                                {/*    }*/}
                                {/*</ul>*/}
                            </div>
                            <div className="title-stamp small">
                                <div className="circle">
                                    <strong>審查中</strong>
                                </div>
                            </div>

                        </div>

                    </div>
                    :
                    null
            }
            {
                isDrawBack ?
                    <div className="review-area">
                        <div className="container">
                            <div className="r-comment">
                                <strong>審查人：{drawBacker}</strong>
                                <textarea name="" id="" cols="30" rows="3" class="short" value={drawBackReason}>
                                </textarea>
                            </div>
                            <div className="title-stamp small">
                                <div className="circle">
                                    <strong>退回</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            <div className="container">
                <form action="" className="form-container">
                    <div className={`form-item must ${isCompanyError == true ? 'show-help' : ''}`}>
                        <div className="field">負責公司</div>
                        <div className="value">
                            {companyArray.map((x) => (
                                <div>
                                    <input
                                        type="radio"
                                        id={`f821-${x.id}`}
                                        name="f821"
                                        value={x.id}
                                        checked={
                                            companyIdArray.findIndex((e) => e == x.id) >= 0
                                                ? true
                                                : false
                                        }
                                        onClick={companyIdArrayChange}
                                        disabled={isReviewing ? 'disabled' : null}
                                    />
                                    <label htmlFor={`f821-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇負責公司</span>
                    </div>
                    <div className="form-item must half">
                        <div className="field">供應商</div>
                        <div className="value">
                            <input
                                type="text"
                                value={supplierName}
                                onChange={supplierNameChange}
                                disabled={isReviewing ? 'disabled' : null}
                                data-required
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item  half">
                        <div className="field">供應商Number</div>
                        <div className="value">
                            <input
                                type="text"
                                value={venNumber}
                                onChange={(e) => setVenNumber(e.target.value)}
                                disabled={isReviewing ? 'disabled' : null}
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item must half">
                        <div className="field">供應商抬頭</div>
                        <div className="value">
                            <input
                                type="text"
                                value={supplierTitle}
                                onChange={supplierTitleChange}
                                disabled={isReviewing ? 'disabled' : null}
                                data-required
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item half">
                        <div className="field">供應商統編/ 編號</div>
                        <div className="value">
                            <input
                                type="text"
                                value={venBIZNo}
                                onChange={venBIZNoChange}
                                disabled={isReviewing ? 'disabled' : null}
                                maxLength="20"
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>


                    <div className="form-item half">
                        <div className="field">公司電話</div>
                        <div className="value">
                            <input
                                type="text"
                                value={venPhone}
                                onChange={(e) => setVenPhone(e.target.value)}
                                disabled={isReviewing ? 'disabled' : null}
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item half">
                        <div className="field">公司傳真</div>
                        <div className="value">
                            <input
                                type="text"
                                value={venFax}
                                onChange={(e) => setVenFax(e.target.value)}
                                disabled={isReviewing ? 'disabled' : null}
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item must">
                        <div className="field">公司地址</div>
                        <div className="value">
                            <input
                                type="text"
                                value={venAddress}
                                onChange={(e) => setVenAddress(e.target.value)}
                                disabled={isReviewing ? 'disabled' : null}
                                data-required
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className={`form-item must half ${isCmpError ? 'show-help' : ''}`}>
                        <div className="field">負責單位</div>
                        <div className="value">
                            {departmentArray.map((x) => (
                                <div>
                                    <input
                                        type="radio"
                                        id={`822-${x.id}`}
                                        name="f822"
                                        value={x.id}
                                        key={x.id}
                                        checked={cmpDivID == x.id ? "checked" : ""}
                                        onClick={(e) => setCmpDivID(x.id)}
                                        disabled={isReviewing ? 'disabled' : null}
                                    />
                                    <label htmlFor={`822-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇負責單位</span>
                    </div>
                    <div className="form-item must half has-datalist" data-datalist>
                        <div className="field">負責PM</div>
                        <div className="value">
                            <input
                                id="adNameText"
                                type="text"
                                value={adName}
                                onChange={adNameChange}
                                disabled={isReviewing ? 'disabled' : null}
                                autocomplete="off"
                                data-required
                            />
                            <ul className="datalist scrollbar">
                                {adNameArray.map((x) => (
                                    <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => { setAdName(`${x.enName}(${x.tc_name})`), setAdID(x.id); }} >
                                        <span className="flex-4">{x.enName}({x.tc_name})</span>
                                        <span className="flex-5">{x.mail}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <hr />

                    <div className={`form-item must ${isCustPayError ? 'show-help' : ''}`}>
                        <div className="field">月結天數</div>
                        <div className="value">
                            {settlementArray.map((x) => {
                                if (companyIdArray.find(x => x == 1) != null && x.name == 90) {

                                }
                                else {
                                    return (
                                        <div>
                                            <input
                                                type="radio"
                                                id={`823-${x.id}`}
                                                name="f823"
                                                value={x.id}
                                                key={x.id}
                                                checked={setlementId == x.id ? "checked" : ""}
                                                disabled={isReviewing ? 'disabled' : null}
                                                onClick={(e) => setSetlementId(x.id)}
                                            />
                                            <label htmlFor={`823-${x.id}`}>
                                                {
                                                    x.name == "1" ? "即期" : x.name + "天"
                                                }
                                            </label>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <span className="help-word">請選擇月結天數</span>
                    </div>

                    <div className="form-item col must">
                        <div className="field">匯款銀行</div>
                        <div className="value">
                            <ul className="list-table">
                                <li className="lt-row lt-th">
                                    <div className="flex-1">序號</div>
                                    <div className="flex-3">銀行名稱</div>
                                    <div className="flex-3">分行</div>
                                    <div className="flex-3">SWIFT Code</div>
                                    <div className="flex-3">匯款帳號</div>
                                    <div className="flex-2">幣別</div>
                                    <div className="flex-2 fn"></div>
                                </li>

                                {(() => {
                                    let rows = [];
                                    let index = 0;

                                    bankData.map((x, i) => {
                                        if (x.flag != "D") {
                                            rows.push(
                                                x.IsEdit == true ?
                                                    (
                                                        <li className="lt-row">
                                                            <div className="flex-1">{index + 1 < 10 ? `0${index + 1}` : indexi + 1}</div>
                                                            <div className="flex-3">
                                                                {
                                                                    isReviewing ?
                                                                        x.venBankRemitBank
                                                                        :
                                                                        <input
                                                                            type="text"
                                                                            value={x.venBankRemitBank ? x.venBankRemitBank : ""}
                                                                            onChange={(e) => venBankDataChange(e, i, "venBankRemitBank")}
                                                                            data-required
                                                                        />
                                                                }
                                                            </div>
                                                            <div className="flex-3">
                                                                {
                                                                    isReviewing ?
                                                                        x.venBankRemitBranch
                                                                        :
                                                                        <input
                                                                            type="text"
                                                                            value={x.venBankRemitBranch ? x.venBankRemitBranch : ""}
                                                                            onChange={(e) => venBankDataChange(e, i, "venBankRemitBranch")}
                                                                            placeholder="非必填"
                                                                        />
                                                                }
                                                            </div>
                                                            <div className="flex-3">
                                                                {
                                                                    isReviewing ?
                                                                        x.venBankRemitSWIFTCode
                                                                        :
                                                                        <input
                                                                            type="text"
                                                                            value={x.venBankRemitSWIFTCode ? x.venBankRemitSWIFTCode : ""}
                                                                            onChange={(e) => venBankDataChange(e, i, "venBankRemitSWIFTCode")}
                                                                            placeholder="非必填"
                                                                        />
                                                                }
                                                            </div>
                                                            <div className="flex-3">
                                                                {
                                                                    isReviewing ?
                                                                        x.venBankRemitAcnt
                                                                        :
                                                                        <input
                                                                            type="text"
                                                                            value={x.venBankRemitAcnt ? x.venBankRemitAcnt : ""}
                                                                            onChange={(e) => venBankDataChange(e, i, "venBankRemitAcnt")}
                                                                            data-required
                                                                        />
                                                                }
                                                            </div>
                                                            <div className="flex-2">
                                                                {
                                                                    x.venBankExRates == null || x.venBankExRates.length == 0 ?
                                                                        <div className={`btn btn-small btn-border ${isReviewing ? 'disabled' : ''}`} id={`bank_${i}`} onClick={(e) => openModal10(i)}>
                                                                            <span>選擇幣別</span>
                                                                        </div>
                                                                        :
                                                                        <a href="#" onClick={(e) => openModal10(i)}>
                                                                            {
                                                                                x.venBankExRates.map((x2, i2) =>
                                                                                    currencyBaseArray.map(x3 =>
                                                                                        x3.id == x2.venBankCurrID ? x3.name + (i2 == x.venBankExRates.length - 1 ? '' : '/') : null
                                                                                    )
                                                                                )
                                                                            }
                                                                        </a>
                                                                }
                                                            </div>
                                                            <div className="flex-2 fn">
                                                                {
                                                                    x.venBankID == null ?
                                                                        <div className={`btn btn-icon ${isReviewing ? 'disabled' : ''}`} onClick={(e) => { delBankItem(i) }}>
                                                                            <i className="icon icon-37"></i>
                                                                        </div>
                                                                        : <div className="btn btn-small btn-border" onClick={(e) => changeBankItem(i, false)} disabled={isReviewing ? 'disabled' : null}>
                                                                            <span>復原</span>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </li>

                                                    )
                                                    : (
                                                        <li className="lt-row">
                                                            <div className="flex-1">{index + 1 < 10 ? `0${index + 1}` : index + 1}</div>
                                                            <div className="flex-3">{x.venBankRemitBank} </div>
                                                            <div className="flex-3">{x.venBankRemitBranch}</div>
                                                            <div className="flex-3">{x.venBankRemitSWIFTCode}</div>
                                                            <div className="flex-3">{x.venBankRemitAcnt}</div>
                                                            <div className="flex-2">
                                                                {
                                                                    x.venBankExRates == null || x.venBankExRates.length == 0 ? '' :
                                                                        x.venBankExRates.map((x2, i2) =>
                                                                            currencyBaseArray.map(x3 =>
                                                                                x3.id == x2.venBankCurrID ? x3.name + (i2 == x.venBankExRates.length - 1 ? '' : '/') : null
                                                                            )
                                                                        )

                                                                }
                                                            </div>
                                                            <div className="flex-2 fn">
                                                                <div className="btn btn-icon">
                                                                    {
                                                                        isReviewing ? null :
                                                                            <i className="icon icon-30" onClick={(e) => changeBankItem(i, true)} ></i>
                                                                    }
                                                                </div>
                                                                {

                                                                    isReviewing || bankData.filter(y => y.flag != "D") == null || bankData.filter(y => y.flag != "D").length <= 1 ? null
                                                                        : <div className="btn btn-icon" onClick={(e) => delBankItem(i)} >
                                                                            <i className="icon icon-37"></i>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </li>

                                                    )
                                            )
                                            index += 1;
                                        }
                                    })

                                    return rows;
                                })()}
                            </ul>
                            {console.log(isReviewing)}
                            {
                                isReviewing ?
                                    <div className="btn-area">
                                        <div class="info"></div>
                                        <div className="btn btn-middle btn-border disabled">
                                            <span>新增匯款銀行</span>
                                        </div>
                                    </div>
                                    :
                                    <div className="btn-area">
                                        <div class="info"></div>
                                        <div className="btn btn-middle btn-border" onClick={addBankData} >
                                            <span>新增匯款銀行</span>
                                        </div>
                                    </div>
                            }

                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item col must">
                        <div className="field">聯絡人</div>
                        <div className="value">
                            <ul className="list-table">
                                <li className="lt-row lt-th">
                                    <div className="flex-1">序號</div>
                                    <div className="flex-3">姓名</div>
                                    <div className="flex-3">連絡電話</div>
                                    <div className="flex-3">聯絡信箱</div>
                                    <div className="flex-2" />
                                </li>

                                {(() => {
                                    let rows = [];
                                    let index = 0
                                    contactData.map((x, i) => {
                                        if (x.flag != "D") {
                                            rows.push(
                                                x.IsEdit == true ?
                                                    (< li className="lt-row" key={i} >
                                                        <div className="flex-1">{index + 1 < 10 ? `0${index + 1}` : index + 1}</div>
                                                        <div className="flex-3">
                                                            {
                                                                isReviewing ?
                                                                    x.venConName
                                                                    :
                                                                    <input
                                                                        type="text"
                                                                        value={x.venConName ? x.venConName : ""}
                                                                        onChange={e => contactDataChange(e, i, "venConName")}
                                                                        disabled={isReviewing ? 'disabled' : null}
                                                                        data-required
                                                                    />
                                                            }

                                                        </div>
                                                        <div className="flex-3">
                                                            {
                                                                isReviewing ?
                                                                    x.venConTel
                                                                    :
                                                                    <input
                                                                        type="tel"
                                                                        value={x.venConTel ? x.venConTel : ""}
                                                                        onChange={e => contactDataChange(e, i, "venConTel")}
                                                                        disabled={isReviewing ? 'disabled' : null}
                                                                        maxLength="12"
                                                                        data-required
                                                                    />
                                                            }

                                                        </div>
                                                        <div className="flex-3">
                                                            {
                                                                isReviewing ?
                                                                    x.venConEmail
                                                                    :
                                                                    <input
                                                                        type="email"
                                                                        value={x.venConEmail ? x.venConEmail : ""}
                                                                        onChange={e => contactDataChange(e, i, "venConEmail")}
                                                                        disabled={isReviewing ? 'disabled' : null}
                                                                        data-required
                                                                    />
                                                            }
                                                        </div>
                                                        <div className="flex-2 fn">
                                                            {
                                                                x.venConID == null ?
                                                                    <div className={`btn btn-icon ${isReviewing ? 'disabled' : ''}`} onClick={(e) => { delContactItem(i) }}>
                                                                        <i className="icon icon-37"></i>
                                                                    </div>
                                                                    : <div className="btn btn-small btn-border" onClick={(e) => changeContactItem(i, false)} disabled={isReviewing ? 'disabled' : null}>
                                                                        <span>復原</span>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </li>)
                                                    :
                                                    (< li className="lt-row" key={i} >
                                                        <div className="flex-1">{index + 1 < 10 ? `0${index + 1}` : index + 1}</div>
                                                        <div className="flex-3">{x.venConName}</div>
                                                        <div className="flex-3">{x.venConTel}</div>
                                                        <div className="flex-3">{x.venConEmail}</div>
                                                        <div className="flex-2 fn">
                                                            <div className="btn btn-icon">
                                                                {
                                                                    isReviewing ? null :
                                                                        <i className="icon icon-30" onClick={(e) => changeContactItem(i, true)} ></i>
                                                                }
                                                            </div>
                                                            {
                                                                isReviewing || contactData.filter(y => y.flag != "D") == null || contactData.filter(y => y.flag != "D").length <= 1 ? null
                                                                    : <div className="btn btn-icon" onClick={(e) => delContactItem(i)} >
                                                                        <i className="icon icon-37"></i>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </li>)
                                            )
                                            index++;
                                        }
                                    })
                                    return rows;
                                })()}
                            </ul>
                            {
                                isReviewing ?
                                    <div className="btn-area">
                                        <div class="info"></div>
                                        <div className="btn btn-middle btn-border disabled"  >
                                            <span>新增聯絡人</span>
                                        </div>
                                    </div>
                                    :
                                    <div className="btn-area">
                                        <div class="info"></div>
                                        <div className="btn btn-middle btn-border" onClick={addContactData} >
                                            <span>新增聯絡人</span>
                                        </div>
                                    </div>
                            }

                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item aifs">
                        <div className="field">備註</div>
                        <div className="value">
                            <textarea
                                name="memo"
                                id="memo"
                                cols="30"
                                rows="10"
                                value={memo}
                                disabled={isReviewing ? 'disabled' : null}
                                onChange={memoChange}
                                style={{ borderRadius: "15px" }}
                            ></textarea>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item btn-area sp">
                        <div className="left">
                            <a className={`btn btn-border ${isReviewing || forceDisabled ? 'disabled' : null}`} onClick={() => isReviewing ? null : downLoad()}>
                                <span>產出供應商資料卡</span>
                            </a>
                        </div>
                        <div className="right">
                            <a className={`btn btn-border ${isReviewing || forceDisabled ? 'disabled' : null}`} onClick={() => isReviewing ? null : OpenModal("modal-del")}>
                                <span>刪除供應商</span>
                            </a>
                            <a className={`btn ${isReviewing || forceDisabled ? 'disabled' : null}`} onClick={isReviewing ? null : Edit}>
                                <span>{isDrawBack ? '再次送審' : '確認修改'}</span>
                            </a>
                        </div>
                    </div>
                    {
                        isDrawBack ?
                            <div className="form-item must col feedback">
                                <div className="field">退回原因</div>
                                <div className="value">
                                    <textarea name="reason" cols="30" rows="6" value={drawBackReason}>

                                    </textarea>
                                </div>
                                <span className="help-word">請輸入退回原因</span>
                            </div>
                            : null
                    }
                </form>
            </div >
            <div id="modal-10" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">選擇幣別</h3>
                        <span className="close" onClick={() => CloseModal("modal-10")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-item no-field">
                            <div className="field">選擇幣別</div>
                            <div className="value">
                                {currencyBaseArray.map((x) => (
                                    <div>
                                        <input
                                            type="checkbox"
                                            id={`f827-${x.id}`}
                                            name="f827"
                                            value={x.id}
                                            key={x.id}
                                            disabled={isReviewing ? 'disabled' : null}
                                            onClick={currencyDataChange}
                                        />
                                        <label htmlFor={`f827-${x.id}`}>{x.name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="btn-holder">
                            <button className="btn" onClick={() => CloseModal("modal-10")}>
                                <span>確認</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-edit" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className={`btn`} onClick={reflash}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-del" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確定要刪除這筆資料？</div>
                        <div className="btn-holder sp">
                            <a className={`btn btn-border ${forceDisabled ? 'disabled' : ''}`} onClick={() => CloseModal("modal-del")}>
                                <span>取消</span>
                            </a>
                            <a className={`btn ${forceDisabled ? 'disabled' : ''}`} onClick={Del}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-delok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={(e) => returnQry()}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-history" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">審查歷程</h3>
                        <div className="close" onClick={() => CloseModal("modal-history")}>
                            <i className="icon icon-44"></i>
                        </div>
                    </div>
                    <div className="modal-body">
                        <ul className="list-table">
                            <li class="lt-row lt-th">
                                <div class="flex-3">送審日期</div>
                                <div class="flex-3">送查人</div>
                                <div class="flex-3">審查原因</div>
                                <div class="flex-3">審查日期</div>
                                <div class="flex-3">審查人</div>
                                <div class="flex-3">審查結果</div>
                            </li>
                            {
                                historyList.map(x =>
                                    <li className="lt-row">
                                        <div className="flex-3">{x.applyDate}</div>
                                        <div className="flex-3">{x.auditApplier}</div>
                                        <div className="flex-3">{x.auditReason}</div>
                                        <div className="flex-3">{x.auditDate}</div>
                                        <div className="flex-3">{x.auditor}</div>
                                        <div className="flex-3">{x.auditResult}</div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div id="modal-review" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            該資料審查中
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-review")}>
                                <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-4035" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-41"></i>
                        </div>
                        <div class="text-holder">
                            {modalStr}
                        </div>
                        <div class="btn-holder">
                            <a class="btn" data-close onClick={e => history.push("/Main?path=SupplierQry")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default SupplierEdit;
