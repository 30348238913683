import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_HOST,
    API_GET_CloudAccountingGetProductsA,
    API_GET_CloudAccountingUploadRecord,
    API_GET_CloudAccountingChkAudit,
    API_GET_CloudAccountingDeleteUpload,
    API_GET_CloudAccountingCancelTxn,
    API_POST_CloudAccountingDataCancelProdId,
    API_GET_CloudAccountingGenProcessedReport,
    API_GET_CloudAccountingGetTxnStatus,
    API_GET_FileDownload,
    API_GET_CloudAccountingGetFileDownLoadPath
} from "../../global/constants";
import { InitJS, OpenModal, CloseModal, UrlDecode, MenuSet } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const CATExport = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        //把menu顏色置換
        MenuSet("CATRowSetQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    var moment = require("moment");
    function getData() {
        API_GET_CloudAccountingGetProductsA()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setProductName2Array(data.result.data);
                }

                var futureMonth = moment().format("YYYY/MM");
                setIncomeYM(futureMonth);
                var ApiMonth = moment().add(-1, "M").format("YYYY/MM");
                setApiincomeYM(ApiMonth);
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [productName2Array, setProductName2Array] = useState([{}]);
    const [productNameId, setProductNameId] = useState();
    const [incomeYM, setIncomeYM] = useState();
    const [apiincomeYM, setApiincomeYM] = useState();
    const [modalStr, setModalStr] = useState();
    function getFile() {
        document.getElementById("file").click();
    }
    //上傳檔案
    const [selectedFileError, setSelectedFileError] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadFileNameArray, setUploadFileNameArray] = useState([]);
    const [selectedFileErrorTxt, setSelectedFileErrorTxt] = useState();
    function selectedFileChange(e) {
        //console.log(e.target.files);
        var fileExt = getFileExt(e.target.files[0].name);
        if (fileExt != "xlsx" && fileExt != "csv" && fileExt != "xls") {
            setSelectedFileErrorTxt("檔案格式錯誤");
            setSelectedFileError(true);
            return false;
        } else {
            setSelectedFileErrorTxt("");
        }
        setSelectedFile(e.target.files)
        var hasFileName = false;
        for (var i = 0; i < recordList.length; i++) {
            if (recordList[i].fileName == e.target.files[0].name) {
                hasFileName = true;
                break;
            }
        }

        if (hasFileName) {
            setSelectedFileError(true);
            setSelectedFileErrorTxt("檔案名稱重複，請重新選擇檔案或重新命名");
        } else {
            setSelectedFileError(false);
            setSelectedFiles(function (prev) {
                return [...prev, e.target.files[0]];
            });
            //let date = new Date();
            //console.log(date.toISOString().split('.')[0]);
            //2023-02-10T08:19:30
            setRecordList(function (prev) {
                return [...prev, { fileName: e.target.files[0].name, fileStatus: 0 }];
            });
            setUploadFileNameArray(function (prev) {
                return [...prev, e.target.files[0].name];
            });
        }
        setSelectedFile(null);
        //var filestr = "";
        //if (e.target.files.length > 1) {
        //    for (var i = 0; i < e.target.files.length; i++) {
        //        if (i == 0) {
        //            filestr = e.target.files[i].name;
        //        } else {
        //            filestr = filestr + "," + e.target.files[i].name;
        //        }

        //    }
        //    //document.getElementById("faketext").value = filestr;
        //}
    }
    function getFileExt(filename) {
        var a = filename.split(".");
        if (a.length === 1 || (a[0] === "" && a.length === 2)) {
            return "";
        }
        return a.pop();

    }
    const [recordList, setRecordList] = useState([{}]);
    function productIdChange(e) {
        //console.log(e.target.value);
        if (e.target.value == "" || e.target.value == null) {
            setProductNameId(null);
            setRecordList([{}]);
        } else {
            setProductNameId(e.target.value);
            var dt = {
                productId: parseInt(e.target.value),
                sellYM: apiincomeYM
            };
            //呼叫API 12-20
            API_GET_CloudAccountingUploadRecord(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        setRecordList(data.result.data);
                    }
                })
                .catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }
    function reProductIdChange() {
        var dt = {
            productId: parseInt(productNameId),
            sellYM: apiincomeYM
        };
        //呼叫API 12-20
        API_GET_CloudAccountingUploadRecord(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setRecordList(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }
    function ProcessData() {
        if (recordList.length === 0) {
            setModalStr("請選擇上傳檔案");
            OpenModal("modal-error", 4);
            return false;
        }
        //呼叫12-22 檢查有沒有審核中
        API_GET_CloudAccountingChkAudit()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //沒有審核中直接進行 API 12-8
                    Save();
                }
                else if (data.code == "9999") {
                    setModalStr(data.message);
                    OpenModal("modal-error", 4);
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-hasview");
                }
            }).catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }
    const [tmpfileId, setTmpfileId] = useState();
    const [tmpfileName, setTmpfileName] = useState();
    function OpenModalRecord(fileId, filename) {
        setTmpfileId(fileId);
        setTmpfileName(filename);
        OpenModal("modal-chkdel");

    }
    //刪除檔案
    function DelRecord() {
        CloseModal("modal-chkdel");
        var isNewFile = false;
        for (var i = 0; i < uploadFileNameArray.length; i++) {
            if (tmpfileName == uploadFileNameArray[i]) {
                isNewFile = true;
                //刪除紀錄的資料 
                setRecordList(function (prev) {
                    return prev.filter((x, i) => x.fileName != tmpfileName);
                });

                //刪除要送出檔案的資料
                setSelectedFiles(function (prev) {
                    return prev.filter((x, i) => x.name != tmpfileName);
                });
                break;
            }
        }
        if (!isNewFile) {
            var dt = {
                productId: parseInt(productNameId),
                fileId: tmpfileId
            };
            //console.log(JSON.stringify(dt));
            //呼叫API
            API_GET_CloudAccountingDeleteUpload(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        //刪除紀錄的資料 
                        setRecordList(function (prev) {
                            return prev.filter((x, i) => x.fileId != tmpfileId);
                        });
                    } else {
                        setModalStr(data.message);
                        OpenModal("modal-error", 4);
                    }
                }).catch((err) => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });

        }
    }
    //暫存 API-12-21
    function tmpSave() {
        CloseModal("modal-chktmpsave");
        if (uploadFileNameArray.length === 0) {
            setModalStr("無上傳檔案，無須執行暫存");
            OpenModal("modal-ok");
            return;
        }
        const formData = new FormData();
        var dt = {
            productId: productNameId,
            fileList: uploadFileNameArray
        }
        //console.log(JSON.stringify(dt), selectedFiles);
        formData.append("view", JSON.stringify(dt));
        //檢查是否有檔案
        for (var i = 0; i < uploadFileNameArray.length; i++) {
            formData.append("formFiles", selectedFiles[i]);
        }


        const requestOptions = {
            method: "PUT",
            headers: {
                //"Content-Type": "multipart/form-data",
                Authorization: localStorage.getItem("token"),
            },
            body: formData,
        };

        var path = API_HOST + "/cloudAccounting/tempsave";
        fetch(path, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                //if (!checkAuthToken(data)) { history.push('/') };
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setModalStr("暫存成功");
                    OpenModal("modal-ok");
                    reProductIdChange();
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error", 4);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [nowPercentage, setNowPercentage] = useState(0);
    //處理
    async function Save() {
        setCancelflag(false);
        //這次要處理的資料數量
        var dataCount = recordList.filter(x => x.fileStatus == 0).length;
        //關閉提示視窗
        CloseModal("modal-hasview");
        OpenModal("modal-processing");

        const formData = new FormData();
        var files = [];
        for (var i = 0; i < recordList.length; i++) {
            //如果不是新增或是暫存的都跳過
            if (recordList[i].fileStatus != 0) {
                continue;
            }

            files.push({ fileId: recordList[i].fileId, fileName: recordList[i].fileName });
        }
        var dt = {
            productId: productNameId,
            incomeYM: apiincomeYM,
            files: files
        }
        //console.log(JSON.stringify(dt), selectedFiles[s]);
        formData.append("view", JSON.stringify(dt));

        for (var s = 0; s < selectedFiles.length; s++) {
            formData.append("formFiles", selectedFiles[s]);
        }
        const requestOptions = {
            method: "POST",
            headers: {
                //"Content-Type": "multipart/form-data",
                Authorization: localStorage.getItem("token"),
            },
            body: formData,
        };

        var path = API_HOST + "/cloudAccounting/data";
        fetch(path, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "3052") {
                    setNowPercentage(0);
                    //被下中斷了
                    CloseModal("modal-processing");
                    CloseModal("modal-stoping", 4);
                    setModalStr("已停止處理");
                    setSelectedFiles([]);
                    OpenModal("modal-reflash");
                    return false;
                }
                else if (data.code == "3054" || data.code == "3045" || data.code == "3053" || data.code == "9999") {
                    setNowPercentage(0);
                    //找不到檔案
                    CloseModal("modal-processing");
                    setModalStr(data.message);
                    OpenModal("modal-error", 4);
                    return false;
                }
                //console.log('完成送出');
            }).catch(err => {
                //console.log('Error: ', err)
            });

        var isError = false;
        var isOver = false;
        for (var k = 0; k < 200; k++) {
            await delay(10);
            //回傳-1 或是中斷 或是已經完成 就跳離這個迴圈
            if (isError == true || cancelflag == true || isOver == true) {
                break;
            }
            //API12-29 取得處理狀態
            API_GET_CloudAccountingGetTxnStatus({
                sellYM: apiincomeYM,
                productId: productNameId
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        if (data.result.data.status == -1) {  //回傳-1 代表後端中斷了
                            setModalStr("上傳中斷");
                            setNowPercentage(0);
                            isError = true;
                        } else {
                            if (data.result.data.status > 98) {
                                isOver = true;
                            }
                            setNowPercentage(data.result.data.status);
                        }
                    } else {
                        setNowPercentage(0);
                        isError = true;
                        setModalStr(data.message);
                    }
                })
        }

        reProductIdChange();
        CloseModal("modal-processing");
        CloseModal("modal-stoping");
        //沒有被中斷
        if (cancelflag == false) {
            if (isError) {
                OpenModal("modal-error", 4);
            } else {
                OpenModal("modal-dataok");
            }
        }

        setNowPercentage(0);
    }


    function delay(n) {
        return new Promise(function (resolve) {
            setTimeout(resolve, n * 1000);
        });
    }
    const [cancelflag, setCancelflag] = useState(false);
    //確認取消處理
    function CancelTxn() {
        CloseModal("modal-stop", 3);
        //呼叫API 12-25
        var dt = {
            productId: parseInt(productNameId),
            sellYM: apiincomeYM,
        }
        API_GET_CloudAccountingCancelTxn(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code = "0000") {
                    setCancelflag(true);
                    CloseModal("modal-processing");
                    CloseModal("modal-stoping");
                    setModalStr("重新上傳會先刪除本月匯入此細項的所有資料 才可重新匯入。");
                    OpenModal("modal-reflash");
                    reProductIdChange();
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //重新上傳 
    function DataCancel() {
        var fileArray = [];
        for (var i = 0; i < recordList.length; i++) {
            fileArray.push(recordList[i].fileName);
        }
        var dt = {
            productId: parseInt(productNameId),
            incomeYM: apiincomeYM,
            fileNames: fileArray
        }
        //console.log(JSON.stringify(dt));
        API_POST_CloudAccountingDataCancelProdId(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setSelectedFiles([]);
                    setSelectedFile(null);
                    CloseModal("modal-dataok");
                    CloseModal("modal-reupload", 3);
                    setModalStr("重新上傳會先刪除本月匯入此細項的所有資料 才可重新匯入。");
                    OpenModal("modal-reflash");
                    reProductIdChange();
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    async function Export() {
        OpenModal("modal-downlonding", 3);
        var isError = false;
        var dt = {
            productId: parseInt(productNameId),
            sellYM: apiincomeYM,
        }
        API_GET_CloudAccountingGenProcessedReport(dt)
            .then((response) => response.data)
            .then((data) => {
                CloseModal("modal-downlonding", 3);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    downLoad(data.result.data.downloadFile);
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error", 4);
                    isError = true;
                }
            })

        var hasFile = false;
        if (isError == true) {
            return false;
        }

        for (var k = 0; k < 200; k++) {
            await delay(10);
            //有檔案 跳離迴圈
            if (hasFile || isError) {
                break;
            }
            //API12-30  
            API_GET_CloudAccountingGetFileDownLoadPath({
                type: "Summary",
                productId: productNameId
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        if (data.result.data != null) {
                            if (data.result.data.filePath != null && data.result.data.filePath != "") {
                                hasFile = true;
                                downLoad(data.result.data.filePath);
                            }
                        }

                    } else {
                        isError = true;
                        CloseModal("modal-processing")
                        CloseModal("modal-downlonding", 3);
                        setModalStr(data.message);
                        OpenModal("modal-error", 4);

                    }
                })
        }

    }


    //下載資料卡
    const FileDownload = require("js-file-download");
    function downLoad(path) {
        API_GET_FileDownload({
            fileName: path,
        }).then((response) => {
            const disposition = response.headers['content-disposition'];
            var filename = disposition.split("''")[1];
            FileDownload(response.data, UrlDecode(filename));
        });
    }
    function reflash() {
        window.location.reload();
    }
    return (
        <>
            <div className="content-body">
                <div className="title-area">
                    <div className="container narrow">
                        <h3 className="fz-B has-border">匯入帳務資料</h3>
                    </div>
                </div>
                <div className="container narrow">
                    <form action="" className="form-container">
                        <div className="form-item must">
                            <div className="field">雲端細項</div>
                            <div className="value">
                                <select name="" id="" onChange={productIdChange}>
                                    <option value="">請選擇</option>
                                    {
                                        productName2Array.map(x =>
                                            <option value={x.id}>{x.name}</option>
                                        )
                                    }

                                </select>
                            </div>
                            <span className="help-word">
                                請選擇隸屬公司
                            </span>
                        </div>

                        <div className="form-item">
                            <div className="field">月結月份</div>
                            <div className="value">
                                <input type="text" value={incomeYM} disabled="disabled" />
                            </div>
                            <div className="help-word">
                                請輸入正確格式
                            </div>
                        </div>

                        <hr />
                        <div className={`form-item inline ${selectedFileError ? 'show-help' : ''}`}>
                            <div className="field">上傳檔案</div>
                            <div className="value multi-inline">
                                <input type="file" id="file" className="real" style={{ "display": "none" }} onChange={selectedFileChange} />
                                <a className={`btn btn-middle btn-border ${productNameId == null ? 'disabled' : ''}`} onClick={getFile}  >
                                    <span>選擇檔案</span>
                                </a>
                                <span class="help-word">

                                    <i class="icon icon-41 alert"></i>
                                    {selectedFileErrorTxt}
                                </span>
                            </div>

                        </div>

                        <div className="form-item col">
                            <div className="field"></div>
                            <div className="value">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1 center">序號</div>
                                        <div className="flex-8">檔案名稱</div>
                                        <div className="flex-3">上傳時間</div>
                                        <div className="flex-1 fn max1"></div>
                                    </li>
                                    {
                                        recordList.map((x, i) =>
                                            x.fileName != null && x.fileName != "" ?
                                                <li className="lt-row">
                                                    <div className="flex-1 center">{i < 9 ? ('0' + (i + 1)) : (i + 1)}</div>
                                                    <div className="flex-8" style={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                                                        <div className="elips-1" style={{ display: 'table-cell', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} title={x.fileName}>{x.fileName}</div>
                                                    </div>
                                                    <div className="flex-3">
                                                        <div className="elips-1">
                                                            {
                                                                x.fileDate != null && x.fileDate != "" ?
                                                                    x.fileDate.split('T')[0] + " " + x.fileDate.split('T')[1]
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="flex-1 fn max1">
                                                        <div className="btn btn-icon" onClick={() => OpenModalRecord(x.fileId, x.fileName)}>
                                                            <i className="icon icon-37"></i>
                                                        </div>
                                                    </div>
                                                </li> : null
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="form-item btn-area sp">
                            <div className="left">

                            </div>
                            <div className="right">
                                <a className={`btn btn-border ${productNameId == null ? 'disabled' : ''}`} onClick={() => OpenModal("modal-chktmpsave")}>
                                    <span>暫存</span>
                                </a>
                                <a className={`btn ${productNameId == null ? 'disabled' : ''}`} onClick={ProcessData}>
                                    <span>處理資料</span>
                                </a>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            <div id="modal-ok" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-43"></i>
                        </div>
                        <div class="text-holder">
                            {modalStr}
                        </div>
                        <div class="btn-holder">
                            <button class="btn" onClick={() => CloseModal("modal-ok")}> <span>確認</span></button>
                        </div>

                    </div>

                </div>
            </div>
            <div id="modal-reflash" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-43"></i>
                        </div>
                        <div class="text-holder">
                            {modalStr}
                        </div>
                        <div class="btn-holder">
                            <button class="btn" onClick={reflash}> <span>確認</span></button>
                        </div>

                    </div>

                </div>
            </div>

            <div id="modal-hasview" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-41"></i>
                        </div>
                        <div class="text-holder">
                            {modalStr}
                        </div>
                        <div class="btn-holder">
                            <button class="btn btn-border" onClick={() => CloseModal("modal-hasview")}> <span>取消</span></button>
                            <button class="btn" onClick={Save}> <span>仍要處理資料</span></button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-processing" class="modal" data-modal>
                <div class="modal-container">

                    <div class="modal-body">
                        <div class="text-holder">
                            <h6 class="fz-B aic">資料處理中 <span class="inline-loading"></span></h6>
                            <div class="progress-bar">
                                <div class="thumb" style={{ "width": `${nowPercentage}%` }}></div>
                            </div>

                        </div>
                        <div class="btn-holder center">
                            <button class="btn" onClick={() => OpenModal("modal-stop", 3)}> <span>取消處理</span></button>

                        </div>

                    </div>
                </div>

            </div>

            <div id="modal-dataok" class="modal" data-modal>
                <div class="modal-container">
                    <div class="modal-header">
                        <span class="close" data-close>
                            <i class="icon icon-44"></i>
                        </span>
                    </div>
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-43"></i>
                        </div>
                        <div class="text-holder">
                            <h6 class="fz-B">資料處理完成</h6>

                        </div>
                        <div class="btn-holder center">
                            <a class="btn" onClick={Export}> <span>匯出資料</span></a>
                            <a class="btn" onClick={() => OpenModal("modal-reupload", 3)}> <span>重新上傳</span></a>
                        </div>

                    </div>
                </div>

            </div>


            <div id="modal-downlonding" class="modal" data-modal>
                <div class="modal-container">

                    <div class="modal-body">
                        <div class="text-holder">
                            <h6 class="fz-B aic">檔案產生中 <span class="inline-loading"></span></h6>


                        </div>

                    </div>
                </div>

            </div>

            <div id="modal-stop" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-41"></i>
                        </div>
                        <div class="text-holder">
                            要停止處理資料？
                        </div>
                        <div class="btn-holder">
                            <button class="btn btn-border" onClick={CancelTxn}> <span>停止處理</span></button>
                            <button class="btn" onClick={() => CloseModal("modal-stop", 3)}> <span>繼續處理</span></button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-stoping" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-41"></i>
                        </div>
                        <div class="text-holder">
                            正在停止處理中，請稍後
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-reupload" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-41"></i>
                        </div>
                        <div class="text-holder">
                            重新上傳會先刪除本月匯入此細項的所有資料 才可重新匯入。
                        </div>
                        <div class="btn-holder">
                            <button class="btn btn-border" onClick={() => CloseModal("modal-reupload", 3)}> <span>取消</span></button>
                            <button class="btn" onClick={DataCancel}> <span>確定</span></button>
                        </div>

                    </div>

                </div>
            </div>

            <div id="modal-chkdel" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-41"></i>
                        </div>
                        <div class="text-holder">
                            確定要將{tmpfileName}執行「刪除檔案」?
                        </div>
                        <div class="btn-holder">
                            <button class="btn btn-border" onClick={() => CloseModal("modal-chkdel")}> <span>取消</span></button>
                            <button class="btn" onClick={DelRecord}> <span>確定</span></button>
                        </div>

                    </div>

                </div>
            </div>
            <div id="modal-chktmpsave" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-41"></i>
                        </div>
                        <div class="text-holder">
                            確定執行暫存?
                        </div>
                        <div class="btn-holder">
                            <button class="btn btn-border" onClick={() => CloseModal("modal-chktmpsave")}> <span>取消</span></button>
                            <button class="btn" onClick={tmpSave}> <span>確定</span></button>
                        </div>

                    </div>

                </div>
            </div>

            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error", 4)}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CATExport;
