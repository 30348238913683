import React, { useState, useEffect } from "react";
import { 
    API_GET_CustomerCurrNames,
    API_GET_PrepassData,
    API_GET_PrepassPreData,
} from "../../global/constants";
import { NumFormart, InitJS} from "../../global/ToolUtil";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const Prepaid_CustomerView = () => {
   
    const queryParams = new URLSearchParams(window.location.search);
    var history = useHistory();
    useEffect(() => {
        //把menu顏色置換
        document.getElementById("menuitem-Prepaid_CustomerQry").classList.add("active");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    const [typeName, setTypeName] = useState();
    const [type, setType] = useState();
    const [dataid, setDataid] = useState();
    const [currId, setCurrId] = useState();
    const [currencyEName, setCurrencyEName] = useState();
    const [currencyName, setCurrencyName] = useState();
    const [customerId, setCustomerId] = useState();
    const [companyName, setCompanyName] = useState();
    const [customerName, setCustomerName] = useState();
    const [customerNickName, setCustomerNickName] = useState();
    const [customerTaxId, setCustomerTaxId] = useState();
    const [customerTitle, setCustomerTitle] = useState();
    const [balance, setBalance] = useState();
    const [historyExplanation, setHistoryExplanation] = useState();
    function getData() {
        var path = "";
        var tmpStr = "";
        setCustomerId(queryParams.get("customerId"));
        if (queryParams.get("type") == "1") {
            setType("1");
            setTypeName("儲值金");
            path = API_GET_PrepassPreData + "?customerId=" + queryParams.get("customerId");
            API_GET_PrepassPreData({
                customerId: queryParams.get("customerId"),
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code != "0000" || data.result.length == 0) {
                        history.push("/");
                    } else {
                        setCompanyName(data.result.data.companyName);
                        setCustomerName(data.result.data.customerName);
                        setCustomerNickName(data.result.data.customerNickName);
                        setCustomerTaxId(data.result.data.customerTaxId);
                        setCustomerTitle(data.result.data.customerTitle);
                        if (queryParams.get("type") == "1") {
                            setDataid(data.result.data.advanceId);
                        } else {
                            setDataid(data.result.data.assurId);
                        }

                        setBalance(NumFormart(data.result.data.balance));
                        for (
                            var i = 0;
                            i < data.result.data.historyExplanation.length;
                            i++
                        ) {
                            if (i == 0) {
                                tmpStr = data.result.data.historyExplanation[i];
                            } else {
                                tmpStr += "\n" + data.result.data.historyExplanation[i];
                            }
                        }
                        setHistoryExplanation(tmpStr);
                    }
                })
                .catch((err) => {
                    
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else if (queryParams.get("type") == "2") {
            setType("2");
            setTypeName("保證金");
            API_GET_PrepassData({
                customerId: queryParams.get("customerId"),
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code != "0000" || data.result.length == 0) {
                        history.push("/");
                    } else {
                        setCompanyName(data.result.data.companyName);
                        setCustomerName(data.result.data.customerName);
                        setCustomerNickName(data.result.data.customerNickName);
                        setCustomerTaxId(data.result.data.customerTaxId);
                        setCustomerTitle(data.result.data.customerTitle);
                        if (queryParams.get("type") == "1") {
                            setDataid(data.result.data.advanceId);
                        } else {
                            setDataid(data.result.data.assurId);
                        }

                        setBalance(NumFormart(data.result.data.balance));
                        for (
                            var i = 0;
                            i < data.result.data.historyExplanation.length;
                            i++
                        ) {
                            if (i == 0) {
                                tmpStr = data.result.data.historyExplanation[i];
                            } else {
                                tmpStr += "\n" + data.result.data.historyExplanation[i];
                            }
                        }
                        setHistoryExplanation(tmpStr);
                    }
                })
                .catch((err) => {
                    
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
        //共用W抓幣別
        API_GET_CustomerCurrNames({ customerId: queryParams.get("customerId"), })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setCurrId(data.result.data.currId);
                    setCurrencyName(data.result.data.currencyName);
                    setCurrencyEName(data.result.data.currencyEName);
                }
            })
    }

    function goEdit() {
        var path = "/Main?path=Prepaid_CustomerEdit&type=" +  type + "&customerId=" + customerId;
        history.push(path);
    }
    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">{companyName}</h3>
                    <span>幣別：{currencyName}</span>
                </div>
            </div>
            <div className="container">
                <div className="form-container">
                    <div className="form-item half">
                        <div className="field">客戶別</div>
                        <div className="value">
                            <input type="text" value={customerName} disabled="disabled" />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>
                    <div className="form-item half">
                        <div className="field">客戶簡稱</div>
                        <div className="value">
                            <input type="text" value={customerNickName} disabled="disabled" />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>
                    <div className="form-item half">
                        <div className="field">公司抬頭</div>
                        <div className="value">
                            <input type="text" value={customerTitle} disabled="disabled" />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <div className="form-item half">
                        <div className="field">公司統編/ 編號</div>
                        <div className="value">
                            <input type="text" value={customerTaxId} disabled="disabled" />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <hr />

                    <div className="form-item half">
                        <div className="field">{typeName}餘額</div>
                        <div className="value multi-inline">
                            <input type="text" value={balance} disabled="disabled" />
                            <div className="btn-area">
                                <a className="btn btn-middle btn-border" onClick={(e)=> history.push(`/Main?path=Prepaid_CustomerList&dataid=${dataid}&type=${type}&customerId=${customerId}&customername=${customerName}`)} >
                                    <span>查看紀錄</span>
                                </a>
                            </div>
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <div className="form-item half"></div>

                    <div className="form-item">
                        <div className="field">歷程說明</div>
                        <div className="value">
                            <textarea
                                name=""
                                id=""
                                cols="30"
                                rows="10"
                                value={historyExplanation}
                                disabled="disabled"
                            ></textarea>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div className="form-item btn-area sp">
                        <div className="left"></div>
                        <div className="right">
                            <a className="btn" onClick={goEdit}>
                                <span>維護資料</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Prepaid_CustomerView;
