import React, { useState, useEffect } from "react";
import { API_GET_CustCreditData, API_GET_AllCompany, API_POST_CustCreditData, API_GET_CustCreditCalCredBal } from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { NumFormart, CloseModal, OpenModal, InitJS, MenuSet } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const CreditsView = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        //把menu顏色置換
        MenuSet("CreditsQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyId, setCompanyId] = useState();
    const [F1301, setF1301] = useState();
    const [customerName, setCustomerName] = useState();
    const [customerNickName, setCustomerNickName] = useState();
    const [customerTaxId, setCustomerTaxId] = useState();
    const [customerTitle, setCustomerTitle] = useState();
    //備註
    const [memo, setmemo] = useState();
    //信用額度
    const [creditFacilities, setCreditFacilities] = useState();
    var moment = require("moment");
    function creditFacilitiesChange(e) {
        var v = e.target.value
        if (v == "") {
            v = "0";
        } else {
            v = v.replace(/,/g, '');
        }
        setCreditFacilities(v);
        var futureMonth = moment().add(-1, "M").format("YYYY/MM");
        var dt = {
            creditQuota: v,
            customerId: queryParams.get("customerId"),
            incomeMonth: futureMonth
        };
        ////console.log(JSON.stringify(dt));
        API_GET_CustCreditCalCredBal(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setCreditBalance(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //	信用餘額
    const [creditBalance, setCreditBalance] = useState();
    function creditBalanceChange(e) {
        var v = e.target.value
        if (v == "") {
            v = "0";
        } else {
            v = v.replace(/,/g, '');
        }
        setCreditBalance(v);
    }
    //可交易餘額
    const [tradableBalance, setTradableBalance] = useState();
    function tradableBalanceChange(e) {
        var v = e.target.value
        if (v == "") {
            v = "0";
        } else {
            v = v.replace(/,/g, '');
        }
        setTradableBalance(v);
    }
    //應收款項
    const [accountsPayable, setAccountsPayable] = useState();
    //預付款項餘額
    const [advance, setAdvance] = useState();
    function advanceChange(e) {
        var v = e.target.value
        if (v == "") {
            v = "0";
        } else {
            v = v.replace(/,/g, '');
        }
        setAdvance(v);
    }
    //保證金額逾
    const [margin, setMargin] = useState();
    function marginChange(e) {
        var v = e.target.value
        if (v == "") {
            v = "0";
        } else {
            v = v.replace(/,/g, '');
        }
        setMargin(v);
    }
    //資料ID
    const [dataID, setDataID] = useState();
    //幣別文字
    const [currencyName, setCurrencyName] = useState();
    const [modalStr, setModalStr] = useState();
    function getData() {
        API_GET_AllCompany()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //負責公司
                    setCompanyArray(data.result.data);
                }
            })


        API_GET_CustCreditData({
            customerId: queryParams.get("customerId"),
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setDataID(data.result.data.id);
                    setCompanyId(data.result.data.companyId);
                    setCustomerName(data.result.data.customerName);
                    setCustomerNickName(data.result.data.customerNickname);
                    setCustomerTaxId(data.result.data.customerTaxId);
                    setCustomerTitle(data.result.data.customerTitle);
                    setCreditFacilities(data.result.data.creditFacilities);
                    setCreditBalance(data.result.data.creditBalance);
                    setTradableBalance(data.result.data.tradableBalance);
                    setAccountsPayable(data.result.data.accountsPayable);
                    setAdvance(data.result.data.advance);
                    setMargin(data.result.data.margin);
                    setCurrencyName(data.result.data.currencyName);
                    setmemo(data.result.data.memo);
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }

            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    function submit() {
        var dt = {
            customerName: customerName,
            customerNickname: customerNickName,
            customerTitle: customerTitle,
            customerTaxId: customerTaxId,
            creditFacilities: creditFacilities,
            creditBalance: creditBalance,
            tradableBalance: parseInt(tradableBalance),
            accountsPayable: accountsPayable,
            advance: advance,
            margin: margin,
            currencyName: currencyName,
            id: dataID,
            companyId: companyId,
            customerId: parseInt(queryParams.get("customerId")),
            memo: memo
        }
        //console.log(dt);
        API_POST_CustCreditData(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    OpenModal("modal-ok");
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error2");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    function returnQry() {
        var path = "/Main?path=CreditsQry";
        history.push(path);
    }
    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">信用額度設定</h3>
                    <span>幣別：{currencyName}</span>
                </div>
            </div>
            <div className="container">
                <div className="form-container">
                    <div className="form-item" id="companyDiv">
                        <div className="field">負責公司</div>
                        <div className="value">
                            {companyArray.map((x) => (
                                <div key={x.id}>
                                    <input
                                        type="radio"
                                        id={`f1301-${x.cmpID}`}
                                        name="f1301"
                                        value={x.cmpID}
                                        checked={companyId == x.cmpID ? "checked" : ""}
                                        onClick={() => { setCompanyId(x.cmpID) }}
                                    />
                                    <label htmlFor={`f1301-${x.cmpID}`}>{x.cmpName}</label>
                                </div>
                            ))}
                        </div>
                        <div className="help-word">請選擇負責公司</div>
                    </div>

                    <div className="form-item half">
                        <div className="field">客戶別</div>
                        <div className="value">
                            <input type="text" value={customerName} disabled="disabled" />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>
                    <div className="form-item half">
                        <div className="field">客戶簡稱</div>
                        <div className="value">
                            <input type="text" value={customerNickName} disabled="disabled" />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>
                    <div className="form-item half">
                        <div className="field">公司抬頭</div>
                        <div className="value">
                            <input type="text" value={customerTitle} disabled="disabled" />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <div className="form-item half">
                        <div className="field">公司統編/ 編號</div>
                        <div className="value">
                            <input type="text" value={customerTaxId} disabled="disabled" />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <hr />

                    <div className="form-item half">
                        <div className="field">信用額度</div>
                        <div className="value">
                            <input type="text"
                                defaultValue={NumFormart(creditFacilities)}
                                onChange={creditFacilitiesChange}
                                onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                onBlur={(e) => e.target.value = NumFormart(creditFacilities)}
                            />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <div className="form-item half">
                        <div className="field">信用餘額</div>
                        <div className="value">
                            <input type="text" value={NumFormart(creditBalance)} onChange={creditBalanceChange} disabled="disabled" />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <div className="form-item half">
                        <div className="field">可交易餘額</div>
                        <div className="value">
                            <input type="text" value={NumFormart(tradableBalance)} onChange={tradableBalanceChange} disabled="disabled" />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <div className="form-item half">
                        <div className="field">應收款項總額</div>
                        <div className="value">
                            <input type="text" value={NumFormart(accountsPayable)} disabled="disabled" />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <div className="form-item half">
                        <div className="field">預付款項</div>
                        <div className="value">
                            <input type="text" value={NumFormart(advance)} onChange={advanceChange} disabled="disabled" />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>

                    <div className="form-item half">
                        <div className="field">保證金</div>
                        <div className="value">
                            <input type="text" value={NumFormart(margin)} onChange={marginChange} disabled="disabled" />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>
                    <div className="form-item">
                        <div className="field">備註</div>
                        <div className="value">
                            <input type="text" value={memo} onChange={(e) => setmemo(e.target.value)} />
                        </div>
                        <div className="help-word">請輸入正確格式</div>
                    </div>
                    <div className="form-item btn-area sp">

                        <div className="left">
                            <a className="btn btn-border" onClick={returnQry}>
                                <span>返回查詢</span>
                            </a>
                        </div>
                        <div className="right">
                            <a className="btn " onClick={submit}>
                                <span>儲存</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-ok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            儲存成功
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-ok")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={returnQry}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error2" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error2")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreditsView;
