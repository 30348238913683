import React, { useState, useEffect, Children } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_ProductHome,
    API_GET_AdNameList,
    API_GET_SupplierNameList,
    API_GET_ProductData,
    API_DEL_ProductData,
    API_PUT_ProductData,
    API_GET_AuditHistory
} from "../../global/constants";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, InitJS, SetOnbeforeunload, MenuSet, SetHasChange } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const ProductEdit = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const [getproductName2, setGetProductName2] = useState(
        queryParams.get("productName2")
    );
    const [reqFrmID, setReqFrmID] = useState(
        queryParams.get("reqFrmID")
    );
    const [isPM, setIsPM] = useState(false);

    useEffect(() => {
        //把menu顏色置換
        MenuSet("Product");
        //第一次近來先執行抓取product資料
        getProduct();
        //抓取該筆資料
        getData();
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload("是否放棄修改此產品");
        }, 300)
        return () => clearTimeout(timeout)

    }, []);


    function getProduct() {
        API_GET_ProductHome({})
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setProductData(data.result.productTypeArray);
                setProductClassArray(data.result.productClassArray);
                setCompanyArray(data.result.companyArray);
                setAccounting(data.result.accounting);
                setAccountingBranch(data.result.accountingBranch);
                setAccountingProductType(data.result.accountingProductType);
                setDepartmentArray(data.result.departmentArray);
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
    }
    function getData(dt = null) {
        ////console.log(getproductName2);
        if (dt == null) {
            dt = {
                productName2: getproductName2,
                reqFrmID: queryParams.get("reqFrmID")
            };
        }
        //console.log(JSON.stringify(dt));
        API_GET_ProductData(dt)
            .then((response) => response.data)
            .then((data) => {
                console.log("產品資料", data)
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "4046") {
                    setIsReviewing(true);
                    setReviewerArr(data.result.reviewerArr);
                    //顯示modal
                    OpenModal("modal-review");
                }
                if (data.code == "4038") {
                    setModalStr(data.message);
                    OpenModal("modal-4038", 2)
                    return;
                }
                //2代表退回
                if (data.result.reviewStatus == 2) {
                    setIsDrawBack(true);
                    //setDrawBackReason(data.result.drawBackReason);
                    setDrawBacker(data.result.drawBacker);
                }

                setProductidArray(data.result.productidArray);
                setProdTPID(data.result.prodTPID);
                setProductName1(data.result.productName);
                setPrevProductName(data.result.productName);
                setProductDetail(data.result.productFullName);
                setPrevProductFullName(data.result.productFullName);
                if (data.result.personInChargeName != null && data.result.personInChargeName != "") {
                    setAdName(data.result.personInChargeName);
                }
                else {
                    setAdName(data.result.personADName);
                }
                setSupplierName(data.result.supplierName);
                setProductInCost(data.result.productInCost);
                setActCID(data.result.actCID);
                setActPID(data.result.actPID);
                setActID(data.result.actID);
                setCmpDivID(data.result.cmpDivID);
                setProdCLID(data.result.prodCLID);
                setProdTPID(data.result.prodTPID);
                setCompanyIdArray(data.result.companyIdArray);
                setAdID(data.result.personADID);
                //審查人
                setAuditorName(data.result.auditorName);
                if (localStorage.getItem("userID") == data.result.cfUserID && data.result.reviewStatus == 2) {
                    setIsDrawBack(true);

                }
                setDrawBackReason(data.result.drawBackReason);

            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
    }

    //刪除modal文字
    const [modalStr, setModalStr] = useState("");
    //產品ID
    const [productidArray, setProductidArray] = useState([]);
    //產品別
    const [productData, setProductData] = useState([{}]);
    //產品別ID
    const [prodTPID, setProdTPID] = useState();
    //產品細項總稱
    const [productName, setProductName1] = useState("");

    function productNameChange(e) {
        //先算細項總稱數量
        var prodTPName = productData.find(x => x.id == prodTPID).name;
        var p1 = prodTPName.length;
        //變更的文字數量有>總稱
        if (e.target.value.length >= p1) {
            //如果前面一樣才可以讓他異動
            if (e.target.value.substr(0, p1) == prodTPName) {
                setProductName1(e.target.value);
                setProductDetail(e.target.value);
            }
        }
    }
    //產品細項總稱(改變前)
    const [prevProductName, setPrevProductName] = useState("");
    //審核中
    const [isReviewing, setIsReviewing] = useState(false);
    //顯示審核中審核人
    //const [showReview, setShowReview] = useState(false);
    //審核人
    const [reviewerArr, setReviewerArr] = useState([]);
    const [reviewerShowArr, setReviewerShowArr] = useState([]);
    useEffect(() => {
        if (reviewerArr && reviewerArr.length > 0) {
            const chunkedArray = [];
            for (let i = 0; i < reviewerArr.length; i += 3) {
                chunkedArray.push(reviewerArr.slice(i, i + 3));
            }
            setReviewerShowArr(chunkedArray);
        }
    }, [reviewerArr]);
    //審核人
    const [auditorName, setAuditorName] = useState('');
    //退回中
    const [isDrawBack, setIsDrawBack] = useState(false);
    //退回的審核人
    const [drawBacker, setDrawBacker] = useState('');
    //退回原因
    const [drawBackReason, setDrawBackReason] = useState();
    //const [reviewStatus, setReviewStatus] = useState(-1);


    //產品細項
    const [productDetail, setProductDetail] = useState("");
    //產品細項(改變前)
    const [prevProductFullName, setPrevProductFullName] = useState("");
    function productDetailChange(e) {
        //先算細項總稱數量
        var p1 = productName.length;
        //變更的文字數量有>總稱
        if (e.target.value.length >= p1) {
            //如果前面一樣才可以讓他異動
            if (e.target.value.substr(0, p1) == productName) {
                setProductDetail(e.target.value);
            }
        }
    }

    //產品類
    const [productClassArray, setProductClassArray] = useState([{}]);
    const [prodCLID, setProdCLID] = useState();
    function prodCLIDChange(e) {

        setProdCLID(parseInt(e.target.value));
    }
    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyIdArray, setCompanyIdArray] = useState([]);
    function companyIdArrayChange() {
        var tmpArray = [];
        var f622 = document.getElementsByName("f622");
        for (var i = 0; i < f622.length; i++) {
            if (f622[i].checked) {
                tmpArray.push(parseInt(f622[i].value));
            }
        }
        setCompanyIdArray(tmpArray);
    }

    //會計科目
    const [accounting, setAccounting] = useState([{}]);
    const [actID, setActID] = useState();
    function actIDChange(e) {

        setActID(parseInt(e.target.value));
    }
    //會計子目
    const [accountingBranch, setAccountingBranch] = useState([{}]);
    const [actCID, setActCID] = useState();
    const [actPID, setActPID] = useState();
    //會計產品別
    const [accountingProductType, setAccountingProductType] = useState([{}]);
    //產品細項datalist
    const [productDetailArray, setProductDetailArray] = useState([{}]);
    //產品負責人
    const [adName, setAdName] = useState("");
    const [adID, setAdID] = useState();
    //產品負責單位
    const [departmentArray, setDepartmentArray] = useState([{}]);
    const [cmpDivID, setCmpDivID] = useState();

    function adNameChange(e) {
        if (e.target.value == '') {
            setAdName("");
            setAdID(null);
        } else {
            setAdName(e.target.value);
            getAdName();
        }
    }
    //產品負責人datalistg
    const [adNameArray, setAdNameArray] = useState([{}]);

    //供應商名稱
    const [supplierName, setSupplierName] = useState("");
    function supplierNameChange(e) {
        setSupplierName(e.target.value);
        getSupplierName();
    }
    //供應商名稱datalist
    const [supplierNameArray, setSupplierNameArray] = useState([{}]);


    //列入產品成本
    const [productInCost, setProductInCost] = useState(true);
    function costChange(e) {
        setProductInCost(e.target.value == "true" ? true : false);
    }
    function getAdName() {
        API_GET_AdNameList({
            input: document.getElementById("adNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setAdNameArray(data.result.data);
                    //console.log(adNameArray);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function getSupplierName(e) {

        API_GET_SupplierNameList({
            input: document.getElementById("supplierNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data != null) {
                    setSupplierNameArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    function reflash() {
        if (isPM) {
            window.location.href = `/Main?path=ProductEdit&productName2=${getproductName2}&reqFrmID=${reqFrmID}`;
        }
        else {
            window.location.reload();
        }
    }
    //執行修改產品
    function modifyData(e) {
        //禁止連點
        e.target.classList.add("disabled");
        var isOk = 1;

        //產品細項總稱
        if ((productName == "") || (productName == "不可空白")) {
            document.getElementById("productName").classList.add("error");
            setProductDetail("不可空白");
            isOk = 0;
        } else {
            document.getElementById("productName").classList.remove("error");
        }
        //產品細項
        if (productDetail == "" || productDetail == "不可空白") {
            document.getElementById("productName2").classList.add("error");
            setProductDetail("不可空白");
            isOk = 0;
        } else {
            document.getElementById("productName2").classList.remove("error");
        }
        //產品類
        var bf621 = false;
        var f621 = document.getElementsByName("f621");
        for (var i = 0; i < f621.length; i++) {
            if (f621[i].checked) {
                bf621 = true;
                break;
            }
        }
        if (bf621 == false) {
            document.getElementById("Div_productclass").classList.add("show-help");
            isOk = 0;
        } else {
            document.getElementById("Div_productclass").classList.remove("show-help");
        }

        //隸屬公司
        var companyIdArray = [];

        var bf622 = false;
        var f622 = document.getElementsByName("f622");
        for (var i = 0; i < f622.length; i++) {
            if (f622[i].checked) {
                bf622 = true;
                //這邊是checkbox 所以順便加入陣列 不break
                companyIdArray.push(parseInt(f622[i].value));
            }
        }

        if (bf622 == false) {
            document.getElementById("Div_company").classList.add("show-help");
            isOk = 0;
        } else {
            document.getElementById("Div_company").classList.remove("show-help");
        }
        //產品負責單位
        var bf623 = false;
        var f623 = document.getElementsByName("f623");
        for (var i = 0; i < f623.length; i++) {
            if (f623[i].checked) {
                bf623 = true;
                break;
            }
        }
        if (bf623 == false) {
            document.getElementById("Div_department").classList.add("show-help");
            isOk = 0;
        } else {
            document.getElementById("Div_department").classList.remove("show-help");
        }
        //供應商名稱
        if (supplierName == "" || supplierName == "不可空白") {
            document.getElementById("supplierNameText").classList.add("error");
            setSupplierName("不可空白");
            isOk = 0;
        } else {
            document.getElementById("supplierNameText").classList.remove("error");
        }
        // 230628 產品負責人可為空白
        //產品負責人
        //if (adName == "" || adName == "不可空白") {
        //    document.getElementById("adNameText").classList.add("error");
        //    setAdName("不可空白");
        //    isOk = 0;
        //} else {
        //    document.getElementById("adNameText").classList.remove("error");
        //}
        //會計科目
        var bf624 = false;
        var f624 = document.getElementsByName("f624");
        for (var i = 0; i < f624.length; i++) {
            if (f624[i].checked) {
                bf624 = true;
                break;
            }
        }
        if (bf624 == false) {
            document.getElementById("Div_accounting").classList.add("show-help");
            isOk = 0;
        } else {
            document.getElementById("Div_accounting").classList.remove("show-help");
        }

        //會計子目
        if (document.getElementById("accountingBranchList").value == "") {
            document.getElementById("Div_accountingbranch").classList.add("show-help");
            isOk = 0;
        } else {
            document.getElementById("Div_accountingbranch").classList.remove("show-help");
        }
        //會計產品別
        if (document.getElementById("accountingProductTypeList").value == "") {
            document.getElementById("Div_accountingproducttype").classList.add("show-help");
            isOk = 0;
        } else {
            document.getElementById("Div_accountingproducttype").classList.remove("show-help");
        }

        if (isOk == 1) {
            var dt = {
                oldProductidArray: productidArray,
                prodTPID: prodTPID,
                productName: productName,
                prevProductName: prevProductName,
                productFullName: productDetail,
                prevProductFullName: prevProductFullName,
                prodCLID: parseInt(
                    document.querySelector('input[name="f621"]:checked').value
                ),
                companyIdArray: companyIdArray,
                venName: document.getElementById("supplierNameText").value,
                personADName: document.getElementById("adNameText").value,
                personADID: adID,
                cmpDivID: parseInt(
                    document.querySelector('input[name="f623"]:checked').value
                ),
                actID: parseInt(
                    document.querySelector('input[name="f624"]:checked').value
                ),
                actCID: parseInt(document.getElementById("accountingBranchList").value),
                actPID: parseInt(
                    document.getElementById("accountingProductTypeList").value
                ),
                productInCost:
                    document.querySelector('input[name="f625"]:checked').value == "true"
                        ? true
                        : false,
                reqFrmID: reqFrmID
            };
            //console.log(JSON.stringify(dt));
            API_PUT_ProductData(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setModalStr(data.message);
                    e.target.classList.remove("disabled");
                    if (data.code == "0000" || data.code == "0001") {
                        if (data.code == "0001") {
                            setIsPM(true);
                        }
                        else {
                            setIsPM(false);
                        }

                        setGetProductName2(productDetail);
                        OpenModal("modal-3");
                        SetHasChange(false);
                    } else {
                        OpenModal("modal-2");
                    }
                })
                .catch((err) => {

                    e.target.classList.remove("disabled");
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            e.target.classList.remove("disabled");
        }

    }

    const [historyList, setHistoryList] = useState([{}]);
    //顯示審核歷程
    function openModalHistory() {
        API_GET_AuditHistory({
            id: productidArray[0],
            category: "Product",
            name: getproductName2,

        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setHistoryList(data.result.data);

            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        OpenModal("modal-history");
    }
    //確認刪除
    function confirmDel(e) {
        API_DEL_ProductData({
            productName2: getproductName2,
            reqFrmID: reqFrmID,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                CloseModal("modal-1");
                setModalStr(data.message);
                if (data.code == "0000") {
                    OpenModal("modal-delok");
                } else {

                    OpenModal("modal-delerror");
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //取消 回查詢頁
    function cancel() {
        history.push("/Main?path=Product");
    }
    const handleProdTypeChange = (e) => {
        let name = productData[e.target.selectedIndex-1].name;
        setProductName1(name);
        setProductDetail(name);

    }
    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">{getproductName2}</h3>
                    <div className="right">
                        <a className="btn btn-border btn-middle" onClick={openModalHistory}>
                            <span>審查歷程</span>
                        </a>
                    </div>
                </div>
            </div>
            {
                isDrawBack ?
                    <div class="review-area">
                        <div class="container">
                            <div class="right">
                                <strong>審查人：{auditorName}</strong>
                            </div>
                            <div class="title-stamp small">
                                <div class="circle">
                                    <strong>退回</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            {
                //審核狀態且非退回
                isReviewing && !isDrawBack ?
                    <div className="review-area">
                        <div className="container">
                            <div className="r-container">
                                <strong>審查人：</strong>
                                {
                                    reviewerShowArr &&
                                    reviewerShowArr.map((item, index) => {
                                        return (
                                            <ul key={index}>
                                                {item.map((subitem, innerIndex) => (
                                                    <li key={innerIndex}>{subitem}</li>
                                                ))}
                                            </ul>
                                        )
                                    })
                                }
                                {/*<ul>*/}
                                {/*    {*/}
                                {/*        reviewerArr != null ?*/}
                                {/*            reviewerArr.map((item, i) => (*/}
                                {/*                <li key={i}>{item}</li>*/}
                                {/*            )) : null*/}
                                {/*    }*/}
                                {/*</ul>*/}
                            </div>
                            <div className="title-stamp small">
                                <div className="circle">
                                    <strong>審查中</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            <div className="container">
                <div className="form-container">
                    <div className="form-item must half" id="Div_product">
                        <div className="field">產品別</div>
                        <div className="value">
                            <select id="productDataList" disabled={isReviewing ? 'disabled' : null} onChange={(e) => handleProdTypeChange(e)}>
                                <option >
                                    請選擇
                                </option>
                                {productData.map((x) => (
                                    <option value={x.id} selected={prodTPID == x.id}>
                                        {x.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <span className="help-word">請選擇產品別</span>
                    </div>
                    <div className="form-item must half" id="Div_productname1">
                        <div className="field">產品細項總稱</div>
                        <div className="value">
                            <input id="productName" type="text" disabled={isReviewing ? 'disabled' : null} onChange={productNameChange} value={productName}
                            />
                        </div>
                        <span className="help-word">請選擇產品細項總稱</span>
                    </div>
                    <div className="form-item must half">
                        <div className="field">產品細項</div>

                        <div className="value">
                            <input id="productName2" type="text" disabled={isReviewing ? 'disabled' : null} onChange={productDetailChange} value={productDetail} />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item must half" id="Div_productclass">
                        <div className="field">產品類</div>
                        <div className="value">
                            {productClassArray.map((x) => (
                                <div>
                                    <input key={`f621-${x.id}`} type="radio" disabled={isReviewing ? 'disabled' : null}
                                        id={`f621-${x.id}`} name="f621" value={x.id} checked={x.id == prodCLID ? true : false} onClick={prodCLIDChange}
                                    />
                                    <label htmlFor={`f621-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇產品類</span>
                    </div>
                    <div className="form-item must" id="Div_company">
                        <div className="field">隸屬公司</div>
                        <div className="value">
                            {companyArray.map((x) => (
                                <div>
                                    <input
                                        type="checkbox"
                                        key={`f622-${x.id}`}
                                        id={`f622-${x.id}`}
                                        name="f622"
                                        value={x.id}
                                        disabled={isReviewing ? 'disabled' : null}
                                        checked={
                                            companyIdArray != null && companyIdArray.findIndex((e) => e == x.id) >= 0
                                                ? true
                                                : false
                                        }
                                        onClick={companyIdArrayChange}
                                    />
                                    <label htmlFor={`f622-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇隸屬公司</span>
                    </div>

                    <div className="form-item must half has-datalist" data-datalist>
                        <div className="field">供應商名稱</div>
                        <div className="value">
                            <input
                                id="supplierNameText"
                                type="text"
                                onChange={supplierNameChange}
                                value={supplierName}
                                disabled={isReviewing ? 'disabled' : null}
                            />
                            <ul className="datalist scrollbar">
                                {supplierNameArray.map((x) => (
                                    <li
                                        data-value={x.name}
                                        onClick={(e) => {
                                            setSupplierName(x.name);
                                        }}
                                    >
                                        <span className="flex-10 bold">{x.name}</span>

                                        {/*<span className="flex-2">12345678</span>*/}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <div class="form-item half"></div>

                    <div className="form-item half has-datalist" data-datalist>
                        <div className="field">產品負責人</div>
                        <div className="value">
                            <input
                                id="adNameText"
                                type="text"
                                value={adName}
                                onChange={adNameChange}
                                disabled={isReviewing ? 'disabled' : null}
                            />
                            <ul className="datalist scrollbar">
                                {adNameArray.map((x) => (
                                    <li data-value={`${x.enName}(${x.tc_name})`}
                                        onClick={(e) => {
                                            setAdName(`${x.enName}(${x.tc_name})`);
                                            setAdID(x.id);
                                        }}
                                    >
                                        <span className="flex-4">{x.enName}({x.tc_name})</span>
                                        <span className="flex-5">{x.mail}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item must half" id="Div_department">
                        <div className="field">產品負責單位</div>
                        <div className="value">
                            {departmentArray.map((x) => (
                                <div>
                                    <input
                                        type="radio"
                                        key={`f623-${x.id}`}
                                        id={`f623-${x.id}`}
                                        name="f623"
                                        value={x.id}
                                        checked={x.id == cmpDivID ? true : false}
                                        onClick={(e) => setCmpDivID(parseInt(e.target.value))}
                                        disabled={isReviewing ? 'disabled' : null}
                                    />
                                    <label htmlFor={`f623-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇產品負責單位</span>
                    </div>

                    <hr />

                    <div className="form-item must half" id="Div_accounting">
                        <div className="field">會計科目</div>
                        <div className="value">
                            {accounting.map((x) => (
                                <div>
                                    <input
                                        type="radio"
                                        key={`f624-${x.id}`}
                                        id={`f624-${x.id}`}
                                        name="f624"
                                        value={x.id}
                                        checked={x.id == actID ? true : false}
                                        onClick={actIDChange}
                                        disabled={isReviewing ? 'disabled' : null}
                                    />
                                    <label htmlFor={`f624-${x.id}`}>
                                        {x.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇會計科目</span>
                    </div>

                    <div className="form-item must half">
                        <div className="field">列入產品成本</div>
                        <div className="value">
                            <div>
                                <input
                                    type="radio"
                                    id="f625-1"
                                    name="f625"
                                    value="true"
                                    checked={productInCost}
                                    onClick={costChange}
                                    disabled={isReviewing ? 'disabled' : null}
                                />
                                <label htmlFor="f625-1">是</label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    id="f625-2"
                                    name="f625"
                                    value="false"
                                    checked={!productInCost}
                                    onClick={costChange}
                                    disabled={isReviewing ? 'disabled' : null}
                                />
                                <label htmlFor="f625-2">否</label>
                            </div>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item must half" id="Div_accountingbranch">
                        <div className="field">會計子目</div>
                        <div className="value">
                            <select id="accountingBranchList" disabled={isReviewing ? 'disabled' : null}>
                                <option value="" selected disabled>請選擇會計子目</option>
                                {accountingBranch.map((x) => (
                                    <option value={x.id} selected={actCID == x.id}>
                                        {x.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <span className="help-word">請選擇會計子目</span>
                    </div>

                    <div className="form-item must half" id="Div_accountingproducttype">
                        <div className="field">會計產品別</div>
                        <div className="value">
                            <select id="accountingProductTypeList" disabled={isReviewing ? 'disabled' : null}>
                                <option value="" selected disabled>請選擇會計產品別</option>
                                {accountingProductType.map((x) => (
                                    <option value={x.id} selected={actPID == x.id}>
                                        {x.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <span className="help-word">請選擇會計產品別</span>
                    </div>

                    <div className="form-item btn-area sp">
                        <div className="left">
                            <a className="btn btn-border" onClick={cancel}>
                                <span>取消</span>
                            </a>
                        </div>

                        <div className="right">
                            <a className={`btn btn-border ${isReviewing ? 'disabled' : null}`} onClick={() => isReviewing ? null : OpenModal("modal-1")}>
                                <span>刪除產品</span>
                            </a>
                            <a className={`btn ${isReviewing ? 'disabled' : null}`} onClick={isReviewing ? null : modifyData}>
                                <span>{isDrawBack ? '再次送審' : '確認修改'}</span>
                            </a>
                        </div>
                    </div>
                    {
                        isDrawBack ?
                            <div className="form-item must col feedback">
                                <div className="field">退回原因</div>
                                <div className="value">
                                    <textarea name="reason" cols="30" rows="6" value={drawBackReason}>

                                    </textarea>
                                </div>
                                <span className="help-word">請輸入退回原因</span>
                            </div>
                            : null
                    }
                </div>
            </div>

            <div id="modal-1" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">確定要刪除這產品？</div>
                        <div className="btn-holder sp">
                            <button className="btn btn-border" onClick={() => CloseModal("modal-1")}>
                                {" "}
                                <span>取消</span>
                            </button>
                            <button className="btn" onClick={confirmDel}>
                                {" "}
                                <span>確認</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-2" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder sp">
                            <a className="btn" onClick={() => CloseModal("modal-2")}>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-3" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => reflash()}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-delok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={cancel}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-delerror" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-delerror")}>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-review" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-41"></i>
                        </div>
                        <div class="text-holder">
                            該產品資料審查中
                        </div>
                        <div class="btn-holder">

                            <a class="btn" onClick={() => CloseModal("modal-review")}> <span>確認</span></a>
                        </div>

                    </div>

                </div>

            </div>
            <div id="modal-history" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">審查歷程</h3>
                        <div className="close" onClick={() => CloseModal("modal-history")}>
                            <i className="icon icon-44"></i>
                        </div>
                    </div>
                    <div className="modal-body">
                        <ul className="list-table">
                            <li class="lt-row lt-th">
                                <div class="flex-3">送審日期</div>
                                <div class="flex-3">送查人</div>
                                <div class="flex-3">審查原因</div>
                                <div class="flex-3">審查日期</div>
                                <div class="flex-3">審查人</div>
                                <div class="flex-3">審查結果</div>
                            </li>
                            {
                                historyList.map(x =>
                                    <li className="lt-row">
                                        <div className="flex-3">{x.applyDate}</div>
                                        <div className="flex-3">{x.auditApplier}</div>
                                        <div className="flex-3">{x.auditReason}</div>
                                        <div className="flex-3">{x.auditDate}</div>
                                        <div className="flex-3">{x.auditor}</div>
                                        <div className="flex-3">{x.auditResult}</div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div id="modal-4038" class="modal small alert" data-modal>
                <div class="modal-container">
                    <div class="modal-body">
                        <div class="icon-holder">
                            <i class="icon icon-41"></i>
                        </div>
                        <div class="text-holder">
                            {modalStr}
                        </div>
                        <div class="btn-holder">
                            <a class="btn" onClick={() => history.push("/Main?path=Product")}> <span>確認</span></a>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    );
};

export default ProductEdit;
