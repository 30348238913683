import React, { useState, useEffect } from 'react';

import {
    API_HOST,
    API_GET_SaleOrderDataCard,
    API_GET_Customer3Name,
    API_POST_SaleOrderCustomerBalance,
    API_GET_AdNameList,
    API_GET_CurrencyComExchangeRate,
    API_GET_CompanyProductType,
    API_GET_ProductName1,
    API_GET_ProductName2,
    API_GET_SaleOrderUnclosedYearMonth
} from '../../global/constants';
import { OpenModal, CloseModal, NewNumFormart, InitJS, SetOnbeforeunload, MenuSet, setha, SetHasChange } from "../../global/ToolUtil";
import { useHistory } from 'react-router-dom';
import { checkAuthToken } from '../../global/TokenUtil';
import { CheckResponseCode } from '../../global/ResponseUtil';
import CustomerSelectComponent from "../Common/CustomerSelectComponent";


const SaleOrderNew = () => {
    var history = useHistory();
    var today = new Date();
    var currentYM = today.getUTCFullYear() + "/" + (today.getUTCMonth() + 1);
    var moment = require('moment');

    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyId, setCompanyId] = useState();
    const [costToOther, setCostToOther] = useState();

    const [modalStr, setModalStr] = useState();

    //公司匯率表
    const [exCurrID, setExCurrID] = useState();
    const [exchangeRateArray, setExchangeRateArray] = useState([]);
    //產品別
    const [productTypeArray, setProductTypeArray] = useState([{}]);
    //幣別
    const [currencyBaseArray, setCurrencyBaseArray] = useState([]);
    //總金額
    const [totalAmount, setTotalAmount] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [exchangeError, setExchangeError] = useState(false);

    const [saleTypeArray, setSaleTypeArray] = useState([{ value: 0, name: "月租型" }, { value: 1, name: "一次型" }, { value: 2, name: "攤提型" }]);
    const [dateArray, setDateArray] = useState([]);
    const [isGetDateArray, setIsGetDateArray] = useState(false);

    const [custID, setCustID] = useState();
    const [adID, setAdID] = useState();

    const [custName, setCustName] = useState("");
    const [custTitle, setCustTitle] = useState("");
    const [custTaxID, setCustTaxID] = useState('');
    const [adName, setAdName] = useState('');

    const [adNameState, setAdNameState] = useState(1);

    //保證金餘額
    const [margin, setMargin] = useState(null);
    //信用額度
    const [creditFacilities, setCreditFacilities] = useState(null);
    //信用餘額
    const [creditBalance, setCreditBalance] = useState(null);
    //儲值金額
    const [advance, setAdvance] = useState(null);
    //可交易餘額
    const [tradableBalance, setTradableBalance] = useState(null);


    //是否請購
    const [isPurchase, setIsPurchase] = useState();
    //額度選項
    const [priorityPayment, setPriorityPayment] = useState(0);
    //備註
    const [remark, setRemark] = useState();

    //產品品項
    const [productData, setProductData] = useState([{ salesType: 0, amortized: null, amortizedFrequency: null, productTypeId: null, productName1: null, productName2: null, quantity: null, unitPrice: null, currencyId: null, explanation: null, exchangeRate: null, subTotal1: null, subTotal2: null, incomeMonth: moment().format("YYYY/MM"), productName1Array: [{}], productName2Array: [{}] }]);

    //資安成本金額
    const [orderCost, setOrderCost] = useState("");

    const [resetFlag, setResetFlag] = useState(false);

    const getCustNameHandle = (element, setElementArray) => {
        if (element && element != '') {
            API_GET_Customer3Name({
                customerName: element,
                companyId: companyId
            }).then((response) => response.data)
                .then((data) => {
                    customer3NameDataToResult(data, setElementArray, 'custName');
                })
                .catch((err) => {

                    if (err.response.status === 401) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    const getCustTitleHandle = (element, setElementArray) => {
        if (element && element != '') {
            API_GET_Customer3Name({
                customerTitle: element,
                companyId: companyId
            }).then((response) => response.data)
                .then((data) => {
                    customer3NameDataToResult(data, setElementArray, 'custTitle');
                })
                .catch((err) => {

                    if (err.response.status === 401) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    const getTaxIdHandle = (element, setElementArray) => {
        if (element && element != '') {
            API_GET_Customer3Name({
                customerTaxId: element,
                companyId: companyId
            }).then((response) => response.data)
                .then((data) => {

                    customer3NameDataToResult(data, setElementArray, 'custTaxID');
                })
                .catch((err) => {

                    if (err.response.status === 401) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    const customer3NameDataToResult = (data, setElementArray, targetField) => {
        if (!checkAuthToken(data)) {
            history.push('/');
        }
        if (!CheckResponseCode(data)) { return; } //檢查response code

        var eArray = [];

        for (var i = 0; i < data.result.data.length; i++) {
            var h = false;
            for (var j = 0; j < eArray.length; j++) {
                if (eArray[j][targetField] == data.result.data[i][targetField]) {
                    h = true;
                }
            }
            if (h == false) {
                eArray.push(data.result.data[i]);
            }
        }

        setElementArray(eArray);
    }

    const getADNameHandle = (element, setElementArray) => {
        API_GET_AdNameList({
            input: element,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setElementArray(data.result.data == null ? [] : data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    const adNameSelectOptionHandle = (elements, selectOptionClickHandle) => {
        return elements.map((x) => (
            <li data-value={`${x.enName}(${x.tc_name})`} onClick={() => {
                setAdID(x.id);
                selectOptionClickHandle(x, `${x.enName}(${x.tc_name})`)
            }}>
                <span className="flex-4">{x.enName}({x.tc_name})</span>
                <span className="flex-5">{x.mail}</span>
            </li>
        ));
    }

    const taxIdSelectOptionHandle = (elements, selectOptionClickHandle) => {

        return elements.map((x) => (
            <li data-value={x.custTaxID} onClick={() => {
                setCustTitle(x.custTitle);
                setCustTaxID(x.custTaxID);
                setCustName(x.custName);
                setAdName(x.personADName)

                setCustID(x.custID);
                selectOptionClickHandle(x, x.custTaxID);
            }}>
                <span className="flex-4 bold">{x.custName}</span>
                <span className="flex-3">{x.custTitle}</span>
                <span className="flex-3">{x.custTaxID}</span>
            </li>
        ));
    }

    const handle3NameChange = (element) => {
        setCustTitle(element.custTitle);
        setCustTaxID(element.custTaxID);
        setCustName(element.custName);
        setAdName(element.personADName)
        setCustID(element.custID);
        setAdID(element.personADID);
    };

    const handleGetDateArray = (companyId) => {
        API_GET_SaleOrderUnclosedYearMonth({
            companyId: companyId,
        })
            .then((response) => response.data)
            .then((data) => {
                setDateArray(data.result.data);
                setIsGetDateArray(true);
            })
            .catch(err => {

                if (err.response.status === 401 || (err.response.data && err.response.data.code == "1001")) {
                    localStorage.clear();
                    history.push('/');
                }
            });
    }

    function getData() {
        API_GET_SaleOrderDataCard()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) { history.push('/') };
                if (!CheckResponseCode(data)) { return; } //檢查response code

                setCompanyArray(data.result.data.companyArray);
                setCurrencyBaseArray(data.result.data.currencyBaseList);
                setProductTypeArray(data.result.data.productTypeArray);
            })
            .catch(err => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function costToOtherChange(v) {
        setCostToOther(v);
        //產品別
        var dt = {
            companyId: companyId,
            isCostToOther: v
        };

        getCompanyProductType(dt, true, "更換經銷類別刷新相關資料");
    }

    //換負責公司 要額外抓匯率表/產品別
    function companyIdChange(id) {
        setCompanyId(id);
        customSelectResetHandle();
        let islog = true;

        getCurrencyComExchangeRate({
            companyId: id,
            searchMax: 0,
            ym: currentYM
        }, islog, "更換負責公司刷新相關資料")

        getCompanyProductType({
            companyId: id,
            isCostToOther: costToOther
        }, islog, "更換負責公司刷新相關資料");
    }

    function orderCostChange(e) {

        var num = e.target.value.replace(/,/g, '');
        setOrderCost(num);
    }

    function addProductData() {
        SetHasChange(true, "是否放棄新增此筆訂單？");

        setProductData(
            function (prev) {
                return [...prev, { salesType: 0, amortized: null, amortizedFrequency: null, productTypeId: null, productName1: null, productName2: null, quantity: null, unitPrice: null, currencyId: null, explanation: null, exchangeRate: null, subTotal1: null, subTotal2: null, incomeMonth: moment().format("YYYY/MM"), productName1Array: [{}], productName2Array: [{}] }];
            }
        )
    }

    function delProductData(index) {
        SetHasChange(true, "是否放棄新增此筆訂單？");

        setProductData(
            function (prev) {
                return prev.filter((x, i) => i != index);
            }
        );
    }

    function calculateCurrencyContent(productData) {
        if (
            exchangeRateArray != null &&
            exchangeRateArray.length > 0 &&
            productData.currencyId !== exCurrID &&
            exchangeRateArray.filter(item => item.exCurrID === productData.currencyId).length > 0
        ) {
            let rate = GetRate(productData.currencyId, productData.incomeMonth);
            if (rate != null) {
                return (
                    rate.rate +
                    '(' + rate.rateYM.substring(2) + ')'
                )

            }
        }
        return null;
    }

    function GetRate(currencyId, incomeMonth) {
        var incomeMonth = new Date(incomeMonth);
        var exchangeRateItemArray = exchangeRateArray.filter(item => item.exCurrID === currencyId);
        exchangeRateItemArray.sort(function (lt, rt) {
            return new Date(rt.exRateYM) - new Date(lt.exRateYM);
        })

        for (var i = 0; i < exchangeRateItemArray.length; i++) {
            let exchangeRateItem = exchangeRateItemArray[i];
            var exRateYM = new Date(exchangeRateItem.exRateYM);
            if (exRateYM <= incomeMonth) {
                return {
                    rate: exchangeRateItem.exRateRate,
                    rateYM: exchangeRateItem.exRateYM
                };
            }
        }

        return null;
    }

    function dataChange(val, index, col = "", e) {
        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "productTypeId") {
                        item.productTypeId = val;
                        //呼叫共用G
                        var dt = {
                            input: "",
                            productTypeId: val,
                            companyId: companyId,
                            isCostToOther: (costToOther == true ? 1 : 0),
                        };
                        API_GET_ProductName1(dt)
                            .then((response) => response.data)
                            .then((data) => {
                                if (!checkAuthToken(data)) {
                                    history.push("/");
                                }
                                if (!CheckResponseCode(data)) { return; } //檢查response code
                                if (data.code == "0000") {
                                    item.productName1Array = data.result.data;
                                }
                            });
                    } else if (col == "productName1") {
                        item.productName1 = val;
                        var firstShowDom = e.target.closest(".has-datalist");
                        firstShowDom.classList.remove("show-datalist");

                        //呼叫共用F
                        var dt = {
                            input: "",
                            productTypeId: item.productTypeId,
                            productName1: val,
                            companyId: companyId,
                            isCostToOther: (costToOther == true ? 1 : 0),
                        }

                        API_GET_ProductName2(dt).then((response) => response.data)
                            .then((data) => {
                                if (!checkAuthToken(data)) {
                                    history.push('/');
                                }
                                if (!CheckResponseCode(data)) { return; } //檢查response code
                                if (data.code == "0000") {
                                    if (data.result.companyArray != null) {
                                        item.productName2Array = data.result.companyArray;
                                    }
                                }
                            })

                    } else if (col == "incomeMonth") {
                        item.incomeMonth = val;
                    } else if (col == "productName2") {
                        item.productName2 = val;
                        var firstShowDom = e.target.closest(".has-datalist");
                        firstShowDom.classList.remove("show-datalist");
                    } else if (col == "explanation") {
                        item.explanation = val;
                    } else if (col == "salesType") {
                        item.salesType = val;
                        var elem = document.querySelector('#amortized_multi_' + index);
                        if (val == "2") {
                            //如果銷售類型是攤提型 單位預設 月
                            item.amortizedFrequency = "0";
                            elem.dataset.required = true;
                        } else {
                            item.amortizedFrequency = null;
                            item.amortized = null;
                            elem.removeAttribute('data-required');
                            elem.classList.remove('error');
                        }
                    } else if (col == "amortizedFrequency") {
                        item.amortizedFrequency = val;
                    } else if (col == "amortized") {
                        item.amortized = val.replace(/[^0-9]/g, '') == '' ? '' : parseInt(val);

                    } else if (col == "productId") {
                        item.productId = val;
                    }

                }
                return item;
            });
        });

        resetAmount(2, index, "incomeMonth", val);
    }

    function productName1Change(e) {

        var index = e.target.dataset.index;

        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.productName1 = e.target.value;
                    ////呼叫共用G 
                    var dt = {
                        input: e.target.value,
                        productTypeId: item.productTypeId,
                        companyId: companyId,
                        isCostToOther: costToOther,
                    };

                    API_GET_ProductName1(dt)
                        .then((response) => response.data)
                        .then((data) => {
                            if (!checkAuthToken(data)) {
                                history.push('/')
                            }
                            if (!CheckResponseCode(data)) { return; } //檢查response code
                            if (data.code == "0000") {
                                item.productName1Array = data.result.data;

                                //呼叫共用F
                                var dt = {
                                    input: "",
                                    productTypeId: item.productTypeId,
                                    productName1: e.target.value,
                                    companyId: companyId,
                                    isCostToOther: (costToOther == true ? 1 : 0),
                                }

                                API_GET_ProductName2(dt).then((response) => response.data)
                                    .then((data) => {
                                        if (!checkAuthToken(data)) {
                                            history.push('/');
                                        }
                                        if (!CheckResponseCode(data)) { return; } //檢查response code
                                        if (data.code == "0000") {
                                            if (data.result.companyArray != null) {
                                                item.productName2Array = data.result.companyArray;
                                            }
                                        }
                                    })
                            }
                        })
                }
                return item;
            });
        });

    }

    function productName2Change(e) {
        var index = e.target.dataset.index;
        setProductData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.productName2 = e.target.value;
                    //呼叫共用F
                    API_GET_ProductName2({
                        input: e.target.value,
                        productTypeId: item.productTypeId,
                        productName1: item.productName1,
                        companyId: companyId,
                        isCostToOther: costToOther,
                    }).then((response) => response.data)
                        .then((data) => {
                            if (!checkAuthToken(data)) {
                                history.push('/');
                            }
                            if (!CheckResponseCode(data)) { return; } //檢查response code
                            if (data.code == "0000") {
                                item.productName2Array = data.result.companyArray;
                            }
                        }).catch((err) => {

                            if (err.response.status === 401 || err.response.data.code == "1001") {
                                localStorage.clear();
                                history.push("/");
                            }
                        });
                }
                return item;
            });
        });

    }

    function resetAmount(type, index, col = "", val = "") {
        //幣別
        var exEname = currencyBaseArray.filter(x => x.id == exCurrID).length > 0 ? (currencyBaseArray.find(x => x.id == exCurrID).ename + ' ') : '';
        var tSum = 0.00;
        if (type == 1) { //全資料重新計算
            //換了公司 則重新處理 數量/單價/小計/總金額
            var tSum = 0.00;
            setProductData(function (prev) {
                return prev.map((item) => {
                    if (item.currencyId != null && item.currencyId != '' && exCurrID != item.currencyId) {
                        var ename = currencyBaseArray.filter(x => x.id == item.currencyId).length > 0 ? (currencyBaseArray.find(x => x.id == item.currencyId).ename + ' ') : '';
                        item.subTotal1 = ename + NewNumFormart(item.quantity * item.unitPrice);
                        var ename2 = currencyBaseArray.filter(x => x.id == exCurrID).length > 0 ? (currencyBaseArray.find(x => x.id == exCurrID).ename + ' ') : ' ';

                        let rate = GetRate(item.currencyId, item.incomeMonth);

                        if (exchangeRateArray != null && exchangeRateArray.length > 0 && rate != null) {
                            item.subTotal2 = ename2 + NewNumFormart((item.quantity * item.unitPrice * rate.rate).toFixed(2));
                            tSum = parseFloat(tSum) + parseFloat((item.quantity * item.unitPrice * rate.rate).toFixed(2));
                        }
                    } else {
                        item.subTotal1 = exEname + NewNumFormart(item.quantity * item.unitPrice);
                        tSum = parseFloat(tSum) + parseFloat(item.quantity * item.unitPrice);
                    }
                    return item;
                });
            });
        } else if (type == 2) { //單筆更新與訂單總金額 
            var noHasExchange = false; //判斷有沒有幣別沒設定到還選到的
            if (currencyBaseArray.filter(x => x.id == exCurrID).length > 0) {

                let newProductData = [...productData];
                if (col == "currencyId") {
                    newProductData[index].currencyId = parseInt(document.getElementById('currencyId_' + index).value);
                }
                else if (col == "quantity") {
                    let tmp = parseFloat(val.replace(/[^0-9]/g, ''));
                    newProductData[index].quantity = !isNaN(tmp) ? tmp : "";
                }
                else if (col == "unitPrice") {
                    let tmp = parseFloat(val.replace(/[^0-9.]/g, ''));
                    newProductData[index].unitPrice = !isNaN(tmp) ? tmp : "";
                }

                newProductData.map((item, index) => {
                    if (newProductData[index].currencyId !== null && newProductData[index].currencyId !== '' && exCurrID != item.currencyId) {
                        var ename = currencyBaseArray.find(x => x.id == item.currencyId).ename + ' ';
                        item.subTotal1 = ename + NewNumFormart(item.quantity * item.unitPrice);

                        var ename2 = currencyBaseArray.find(x => x.id == exCurrID).ename + ' ';

                        if (item.quantity == 0 || item.unitPrice == 0) {
                            item.subTotal2 = 0;
                        }
                        else {
                            let rate = GetRate(item.currencyId, item.incomeMonth);
                            if (exchangeRateArray != null && exchangeRateArray.length > 0 && rate != null) {
                                item.subTotal2 = ename2 + NewNumFormart(((item.quantity * item.unitPrice) * rate.rate).toFixed(2));
                                tSum = parseFloat(tSum) + parseFloat(((item.quantity * item.unitPrice) * rate.rate).toFixed(2));
                            } else {
                                item.subTotal2 = 0;
                                noHasExchange = true;
                            }
                        }
                    }
                    else {
                        item.subTotal1 = exEname + NewNumFormart(item.quantity * item.unitPrice);
                        tSum = parseFloat(tSum) + parseFloat(item.quantity * item.unitPrice);
                    }
                });

                setProductData(newProductData);
                setTotalAmount(exEname + NewNumFormart(parseFloat(tSum.toFixed(2))));
            }
            if (noHasExchange) {
                setExchangeError(true);
            } else {
                setExchangeError(false);
            }
        }
    }

    //前往新增客戶
    function goCustomerNew() {
        window.open("/Main?path=CustomerNew", '_blank')
    }

    //前往查詢
    function returnQry() {
        history.push('/Main?path=SaleOrderQry');
    }

    //送出
    function submit(e) {
        e.target.classList.add("disabled");
        //檢查資料
        var result = checkData();
        //如果資料檢查有通過 則送出
        if (result) {

            const formData = new FormData();

            var tmpproductData = [];
            for (var i = 0; i < productData.length; i++) {
                var tArray = {
                    salesType: productData[i].salesType,
                    amortized: productData[i].amortized,
                    amortizedFrequency: productData[i].amortizedFrequency,
                    productTypeId: productData[i].productTypeId,
                    productName1: productData[i].productName1,
                    productName2: productData[i].productName2,
                    quantity: parseInt(productData[i].quantity),
                    unitPrice: parseFloat(productData[i].unitPrice),
                    currencyId: parseInt(productData[i].currencyId),
                    explanation: productData[i].explanation,
                    incomeMonth: productData[i].incomeMonth,
                    productId: parseInt(productData[i].productId),
                }

                tmpproductData.push(tArray);
            }

            var tmpArray = {
                companyId: companyId,
                costToOther: costToOther == 1 ? true : false,//是否為經銷類別
                customerId: custID,//客戶資料ID
                salesID: adID, //負責業務ID
                priorityPayment: priorityPayment,//額度選項
                isPurchase: isPurchase,//是否請購
                orderCost: orderCost == 0 ? 0 : parseInt(orderCost > 999 ? orderCost.replace(/,/g, '') : orderCost), //協銷/ 資安成本金額
                remark: remark,
                orderProduct: tmpproductData
            };

            formData.append("view", JSON.stringify(tmpArray));
            //檢查是否有檔案
            if (selectedFile != null && selectedFile.size > 0) {
                formData.append("files", selectedFile);
            }

            const requestOptions = {
                method: 'POST',
                headers: {
                    //"Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem("token"),
                },
                body: formData,
            };

            var path = API_HOST + "/salesorder/data";
            fetch(path, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    //if (!checkAuthToken(data)) { history.push('/') };
                    e.target.classList.remove("disabled");
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    if (data.code == "0000") {
                        OpenModal("modal-success");
                    }
                    else {
                        setModalStr(data.message);
                        OpenModal("modal-error");
                    }
                })
                .catch((err) => {

                    e.target.classList.remove("disabled");
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });;

        } else {
            e.target.classList.remove("disabled");
        }
    }

    //資料檢查
    function checkData() {
        var result = true;
        const requiredlist = document.querySelectorAll('[data-required]');
        requiredlist.forEach(element => {
            if (element.value == '') {
                element.classList.add('error');
                result = false;
            } else {
                element.classList.remove('error');
            }

            if (element.classList.contains('multi-form')) {
                var inputs = element.getElementsByTagName("input");

                for (let i = 0; i < inputs.length; i++) {
                    if (inputs[i].value == null || inputs[i].value == '') {
                        if (element.getAttribute("data-required") == 'true') {
                            element.classList.add('error');
                            result = false;
                            break;
                        }
                    }

                    element.classList.remove('error');
                }

            }
        });

        //信用額度
        var bf122 = false;
        var f122 = document.getElementsByName('f122');
        for (var i = 0; i < f122.length; i++) {
            if (f122[i].checked) {
                bf122 = true;
                break;
            }
        }

        if (bf122 == false) {
            document.getElementById('paymentDiv').classList.add('show-help');
            result = false;
        } else {
            document.getElementById('paymentDiv').classList.remove('show-help');
        }

        //是否請購
        var bf123 = false;
        var f123 = document.getElementsByName('f123');
        for (var i = 0; i < f123.length; i++) {
            if (f123[i].checked) {
                bf123 = true;
                break;
            }
        }

        if (bf123 == false) {
            document.getElementById('isPurchaseDIV').classList.add('show-help');
            result = false;
        } else {
            document.getElementById('isPurchaseDIV').classList.remove('show-help');
        }

        if (exchangeError) {
            result = false;
            setModalStr("匯率設定不完整，無法送出訂單");
            OpenModal("modal-error");

            return result;
        }

        let isSameSaleType = productData
            .every((item, index, array) => {
                return item.salesType == array[0].salesType;
            })

        if (isSameSaleType == false) {
            result = false;
            setModalStr("產品品項之銷售類型不同，請修改為同一類型");
            OpenModal("modal-error");

            return result;
        }

        let firstIncomeMonth = productData[0].incomeMonth;

        let hasDifferentIncomeMonth = productData.some(function (obj) {
            return obj.incomeMonth !== firstIncomeMonth
        })

        if (hasDifferentIncomeMonth) {
            setModalStr("產品品項之營收月份不同，請修改為同一月份");
            OpenModal("modal-error");

            result = false;
            return result;
        }

        return result;
    }

    function reflash() {
        window.location.reload();
    }

    function getFile() {
        document.getElementById("file").click();
    }

    function getCompanyProductType(dt, islog = false, stage = "") {
        console.log(islog ? `[${stage}]→更新公司產品別` : "", dt);

        API_GET_CompanyProductType(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push('/')
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code

                console.log(islog ? `[${stage}]→成功取得公司產品別` : "", data.result.data);

                setProductTypeArray(data.result.data);

                setProductData(function (prev) {
                    return prev.map((item, i) => {
                        item.productName1 = '';
                        item.productName2 = '';
                        item.productTypeId = '';
                        document.getElementById("productType_" + i).value = "";
                        return item;
                    });
                });

            })
            .catch(err => {
                console.log(islog ? `[${stage}]→發生未知錯誤` : "", err);

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function getCurrencyComExchangeRate(dt, islog = false, stage = "") {
        console.log(islog ? `[${stage}]→重新抓取匯率表` : "", dt);
        //匯率表
        API_GET_CurrencyComExchangeRate(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push('/');
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.result.data.length == 0) {
                    setModalStr("該負責公司尚未建立匯率表");
                    OpenModal("modal-error");
                }

                console.log(islog ? `[${stage}}]→成功取得匯率表` : "", data.result.data);

                setExchangeRateArray(data.result.data);

                //有的資料沒有exRateRate == 1 因此if判斷
                if (data.result.data.filter(s => s.exRateRate == 1).length > 0) {
                    setExCurrID(data.result.data.find(s => s.exRateRate == 1).exCurrID);
                } else {
                    setExCurrID(5);
                }
                //延遲呼叫
                setTimeout(function () {
                    console.log(islog ? `[${stage}]→重設產品品項金額` : "");
                    resetAmount(1, 0)
                }, 500);

            })
            .catch(err => {
                console.log(islog ? `[${stage}]→發生未知錯誤` : "", err);

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    const customSelectResetHandle = () => {
        setResetFlag(true);
        setMargin('');
        setCreditFacilities('');
        setCreditBalance('');
        setAdvance('');
        setTradableBalance('');
    }


    useEffect(() => {
        //把menu顏色置換
        MenuSet("SaleOrderQry");
        getData();
        //預設為台灣果核
        //companyIdChange(0);
        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload("是否放棄新增此筆訂單？");
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    useEffect(() => {
        if (resetFlag) {
            setResetFlag(false);
        }
    }, [resetFlag]);

    useEffect(() => {
        if (custID) {
            API_POST_SaleOrderCustomerBalance({
                customerId: custID
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push('/');
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setMargin(data.result.data.currencyEnName + ' ' + NewNumFormart(data.result.data.margin));
                    setCreditFacilities(data.result.data.currencyEnName + ' ' + NewNumFormart(data.result.data.creditFacilities));
                    setCreditBalance(data.result.data.currencyEnName + ' ' + NewNumFormart(data.result.data.creditBalance));
                    setAdvance(data.result.data.currencyEnName + ' ' + NewNumFormart(data.result.data.advance));
                    setTradableBalance(data.result.data.currencyEnName + ' ' + NewNumFormart(data.result.data.tradableBalance));

                })
                .catch(err => {

                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push('/');
                    }
                })
        }

    }, [custID])

    useEffect(() => {
        if (companyId) {
            handleGetDateArray(companyId);
        }
    }, [companyId])

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">新增訂單</h3>
                </div>
            </div>
            <div className="container">
                <form action="" className="form-container">
                    <div className="form-item must">
                        <div className="field">負責公司</div>
                        <div className="value">
                            {
                                companyArray.map(x =>
                                    <div key={x.id} >
                                        <input type="radio" id={`f101-${x.id}`} name="f101" value={x.id} checked={companyId == x.id ? true : false} onClick={e => companyIdChange(x.id)} />
                                        <label htmlFor={`f101-${x.id}`}>{x.name}</label>
                                    </div>
                                )
                            }
                        </div>
                        <span className="help-word">
                            請選擇負責公司
                        </span>
                    </div>

                    <div className="form-item must half">
                        <div className="field">經銷類別</div>
                        <div className="value">
                            <div>
                                <input type="radio" name="costToOther" id="costToOther_1" checked={costToOther == 1 ? true : false} onClick={e => costToOtherChange(1)} />
                                <label htmlFor="costToOther_1">是</label>
                            </div>
                            <div>
                                <input type="radio" name="costToOther" id="costToOther_2" checked={costToOther == 0 ? true : false} onClick={e => costToOtherChange(0)} />
                                <label htmlFor="costToOther_2">否</label>
                            </div>
                        </div>
                        <span className="help-word">
                            請輸入正確格式
                        </span>
                    </div>

                    <hr />

                    <CustomerSelectComponent
                        selectTitle={"客戶別"}
                        getDataHandle={getCustNameHandle}
                        onElementChange={handle3NameChange}
                        resetState={resetFlag}
                        shareElementName={custName}
                        elementNameField={'custName'}
                        fromItemClass={'must half'}
                    />

                    <div className="form-item half jcfe">
                        <a className="btn btn-border btn-middle" onClick={goCustomerNew}>
                            <span>新增客戶</span>
                        </a>
                    </div>

                    <CustomerSelectComponent
                        selectTitle={"公司抬頭"}
                        getDataHandle={getCustTitleHandle}
                        onElementChange={handle3NameChange}
                        resetState={resetFlag}
                        shareElementName={custTitle}
                        elementNameField={'custTitle'}
                        fromItemClass={'must half'}
                    />

                    <CustomerSelectComponent
                        selectTitle={"公司統編/ 編號"}
                        getDataHandle={getTaxIdHandle}
                        selectOptionConfig={taxIdSelectOptionHandle}
                        resetState={resetFlag}
                        shareElementName={custTaxID}
                        fromItemClass={'half'}

                    />

                    <CustomerSelectComponent
                        selectTitle={"負責業務"}
                        getDataHandle={getADNameHandle}
                        selectOptionConfig={adNameSelectOptionHandle}
                        resetState={resetFlag}
                        isDisabled={adNameState == 1 ? true : false}
                        shareElementName={adName}
                        fromItemClass={'must half'}

                    />

                    <div>
                        <a className="btn btn-border btn-middle" onClick={() => setAdNameState(0)}>
                            <span>修改</span>
                        </a>
                    </div>

                    <hr />

                    <div className="form-item must half" id="paymentDiv">
                        <div className="field">額度選項</div>
                        <div className="value">
                            <div>
                                <input type="radio" id="f122-1" name="f122" value="0" checked={priorityPayment == 0 ? 'checked' : ''} onClick={e => setPriorityPayment(0)} />
                                <label htmlFor="f122-1">信用額度</label>
                            </div>
                            <div>
                                <input type="radio" id="f122-2" name="f122" value="1" checked={priorityPayment == 1 ? 'checked' : ''} onClick={e => setPriorityPayment(1)} />
                                <label htmlFor="f122-2">預付額度</label>
                            </div>

                        </div>
                        <span className="help-word">
                            請輸入額度選項
                        </span>
                    </div>

                    <div className="form-item half">
                        <div className="field">保證金餘額</div>
                        <div className="value">
                            <input type="text" value={margin} disabled="disabled" />
                        </div>
                        <span className="help-word">
                            請輸入正確格式
                        </span>
                    </div>
                    <div className="form-item half">
                        <div className="field">信用額度</div>
                        <div className="value">
                            <input type="text" value={creditFacilities} disabled="disabled" />
                        </div>
                        <span className="help-word">
                            請輸入正確格式
                        </span>
                    </div>
                    <div className="form-item half">
                        <div className="field">信用餘額</div>
                        <div className="value">
                            <input type="text" value={creditBalance} disabled="disabled" />
                        </div>
                        <span className="help-word">
                            請輸入正確格式
                        </span>
                    </div>
                    <div className="form-item half">
                        <div className="field">儲值金額</div>
                        <div className="value">
                            <input type="text" value={advance} disabled="disabled" />
                        </div>
                        <span className="help-word">
                            請輸入正確格式
                        </span>
                    </div>
                    <div className="form-item half">
                        <div className="field">可交易餘額</div>
                        <div className="value">
                            <input type="text" value={tradableBalance} disabled="disabled" />
                        </div>
                        <span className="help-word">
                            請輸入正確格式
                        </span>
                    </div>

                    <hr />

                    <div className="form-item col must">
                        <div className="field">產品品項</div>
                        <div className="value">
                            {
                                productData.map((x, i) =>
                                    <ul key={i} className="list-table">
                                        <li className="lt-row intensive lt-th">
                                            <div className="form-item flex-1 max1 text-center">序號</div>
                                            <div className="form-item flex-2">產品別</div>
                                            <div className="form-item flex-2">產品細項總稱</div>
                                            <div className="form-item flex-3">產品細項</div>
                                            <div className="form-item flex-2">營收月份</div>
                                        </li>
                                        <li className="lt-row intensive ovv">
                                            <div className="form-item flex-1 max1 text-center">
                                                {i + 1}
                                            </div>
                                            <div className="form-item  flex-2">
                                                <select
                                                    name={`productType_${i}`}
                                                    id={`productType_${i}`}
                                                    value={x.productTypeId}
                                                    data-index={i}
                                                    onChange={(e) => dataChange(e.target.value, i, "productTypeId")}
                                                    disabled={companyId != null && (costToOther == 0 || costToOther == 1) ? false : true}
                                                    data-required>
                                                    <option value="" disabled selected>請選擇</option>
                                                    {
                                                        productTypeArray.map((productType, productTypeIndex) =>
                                                            <option key={productType.prodTPID} value={productType.prodTPID}>{productType.prodTPName}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>

                                            <div className="first-show form-item flex-2 no-field no-help-word inline has-datalist" data-datalist>
                                                <div className="value">
                                                    <input
                                                        type="text"
                                                        id={`productName1_${i}`}
                                                        value={x.productName1}
                                                        onClick={(e) => {
                                                            if (e.target.value == "") {
                                                                var firstShowDom = e.target.closest(".has-datalist");
                                                                firstShowDom.classList.add("show-datalist");
                                                            }
                                                        }}
                                                        onChange={productName1Change}
                                                        disabled={companyId != null && (costToOther == 0 || costToOther == 1) ? false : true}
                                                        autoComplete="off"
                                                        data-index={i}
                                                        data-required />
                                                    <ul className="datalist scrollbar">
                                                        {
                                                            x.productName1Array != null ?
                                                                x.productName1Array.map((x2, xIndex2) =>
                                                                    <li key={xIndex2} data-value={x2.productName1} onClick={e => dataChange(x2.productName1, i, "productName1", e)} >
                                                                        <span className="flex-3 bold">{x2.productName1}</span>
                                                                    </li>
                                                                )
                                                                :
                                                                null
                                                        }
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="form-item flex-3 no-field no-help-word inline has-datalist" data-datalist>
                                                <div className="value">
                                                    <input
                                                        type="text"
                                                        id={`productName2_${i}`}
                                                        value={x.productName2}
                                                        onClick={(e) => {
                                                            if (e.target.value == "") {
                                                                var firstShowDom = e.target.closest(".has-datalist");
                                                                firstShowDom.classList.add("show-datalist");
                                                            }
                                                        }}
                                                        onChange={productName2Change}
                                                        disabled={companyId != null && (costToOther == 0 || costToOther == 1) ? false : true}
                                                        autoComplete="off"
                                                        data-index={i}
                                                        data-required />
                                                    <ul className="datalist scrollbar">
                                                        {
                                                            x.productName2Array != null ?
                                                                x.productName2Array.map(x2 =>
                                                                    <li data-value={x2.productName2} onClick={e => { dataChange(x2.productName2, i, "productName2", e), dataChange(x2.productId, i, "productId") }} >
                                                                        <span className="flex-3 bold">{x2.productName1}</span>
                                                                        <span className="flex-3">{x2.productName2}</span>
                                                                    </li>
                                                                )
                                                                :
                                                                null
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="form-item flex-2 no-field no-help-word inline" >
                                                <div className="value">
                                                    <select name={`incomeMonth_${i}`} id={`incomeMonth_${i}`} disabled={isGetDateArray ? false : true} onChange={e => dataChange(e.target.value, i, "incomeMonth")} data-required>
                                                        {
                                                            dateArray.map(x => {
                                                                let optionDate = new Date(x);
                                                                let currectDate = new Date(currentYM);
                                                                if (optionDate.getTime() == currectDate.getTime()) {
                                                                    return <option value={x} selected>{x}</option>

                                                                }
                                                                else
                                                                    return <option value={x}>{x}</option>
                                                            }
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            {
                                                productData != null && productData.length > 1 ?
                                                    <div className="flex-1">
                                                        <div className="btn btn-icon" onClick={e => delProductData(i)}  >
                                                            <i className="icon icon-37"></i>
                                                        </div>
                                                    </div>
                                                    : null
                                            }

                                        </li>
                                        <li className="lt-row intensive lt-th">
                                            <div className="flex-1">
                                                銷售類型
                                            </div>
                                            <div className="flex-1">攤提次數</div>
                                            <div className="flex-1 right">數量</div>
                                            <div className="flex-1 right">單價(含稅)</div>
                                            <div className="flex-3">
                                                幣別 {calculateCurrencyContent(productData[i], exchangeRateArray, exCurrID)}

                                            </div>
                                            <div className="flex-3">小計(含稅)</div>
                                            <div className="flex-2">說明</div>

                                        </li>
                                        <li className="lt-row intensive lt-th">
                                            <div className="flex-1">
                                                <select name={`saleType_${i}`} id={`saleType_${i}`} onChange={(e) => dataChange(e.target.value, i, "salesType")} data-index={i}>
                                                    {
                                                        saleTypeArray.map(
                                                            x => <option value={x.value}>{x.name}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div className="flex-1">
                                                <div id={`amortized_multi_${i}`} className={`multi-form ${x.salesType == 2 ? '' : 'disabled'}`} data-required={x.salesType == 2}>
                                                    <input type="text" value={x.amortized == null ? '' : x.amortized} id={`amortized_${i}`} name={`amortized_${i}`} onChange={(e) => dataChange(e.target.value, i, "amortized")} data-index={i} disabled={x.salesType == 2 ? null : 'disabled'} />
                                                    <div className="line"></div>
                                                    <select name={`amortizedFrequency_${i}`} id={`amortizedFrequency_${i}`} disabled={x.salesType == 2 ? null : 'disabled'} onChange={(e) => dataChange(e.target.value, i, "amortizedFrequency")} data-index={i} >
                                                        <option value="0"> 月 </option>
                                                        <option value="1"> 季 </option>
                                                        <option value="2"> 年 </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="flex-1 right"> {/*數量*/}
                                                <input
                                                    type="text"
                                                    defaultValue={NewNumFormart(x.quantity)}
                                                    onChange={(e) => resetAmount(2, i, "quantity", e.target.value)}
                                                    onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                                    onBlur={(e) => e.target.value = NewNumFormart(x.quantity)}
                                                    data-required />
                                            </div>
                                            <div className="flex-1 right"> {/*單價*/}
                                                <input
                                                    type="text"
                                                    defaultValue={NewNumFormart(x.unitPrice)}
                                                    onChange={(e) => resetAmount(2, i, "unitPrice", e.target.value)}
                                                    onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                                    onBlur={(e) => e.target.value = NewNumFormart(x.unitPrice)}
                                                    data-required />
                                            </div>
                                            <div className="flex-3">
                                                <select name="currencyBase" id={`currencyId_${i}`} onChange={(e) => resetAmount(2, i, "currencyId", e.target.value)} data-required>
                                                    <option value="" selected disabled>請選擇</option>
                                                    {
                                                        currencyBaseArray.map(x =>
                                                            <option value={x.id}>{x.name}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div className="flex-3"> {/*小計*/}
                                                {
                                                    x.currencyId === null || x.currencyId === '' || x.currencyId == exCurrID ?
                                                        <input type="text" value={x.subTotal1} data-index={i} disabled />
                                                        :
                                                        <>
                                                            <input type="text" value={`${x.subTotal1}(${x.subTotal2})`} data-index={i} disabled />
                                                        </>
                                                }

                                            </div>
                                            <div className="flex-2">
                                                <input type="text" value={x.explanation} onChange={(e) => dataChange(e.target.value, i, "explanation")} />
                                            </div>

                                        </li>

                                    </ul>
                                )
                            }
                            <div className="btn-area">
                                <div className="info"></div>
                                <a className="btn btn-small btn-border" onClick={addProductData}>
                                    <span>新增產品品項</span>
                                </a>
                            </div>
                        </div>

                    </div>

                    <hr />

                    <div className="form-item must half" id="isPurchaseDIV">
                        <div className="field">是否請購</div>
                        <div className="value">
                            <div>
                                <input type="radio" id="f123-1" name="f123" checked={isPurchase == true ? 'checked' : ''} onClick={e => setIsPurchase(true)} />
                                <label htmlFor="f123-1">是</label>
                            </div>
                            <div>
                                <input type="radio" id="f123-2" name="f123" checked={isPurchase == false ? 'checked' : ''} onClick={e => setIsPurchase(false)} />
                                <label htmlFor="f123-2">否</label>
                            </div>
                        </div>
                        <span className="help-word">
                            請輸入是否請購
                        </span>
                    </div>

                    <div className={`form-item half ${exchangeError ? 'show-help' : ''}`}>
                        <div className="field">訂單總金額(含稅)</div>
                        <div className="value">
                            <input type="text" value={exchangeError ? '' : totalAmount} disabled="disabled" />
                        </div>
                        <span className="help-word right">
                            該營收公司匯率設定不完整，無法計算
                        </span>
                    </div>

                    <div className="form-item half">
                        <div className="field">協銷/ 資安成本(含稅)</div>
                        <div className="value">
                            <input type="text"
                                onChange={orderCostChange}
                                onFocus={(e) => e.target.value = (e.target.value + "").replace(/,/g, '')}
                                onBlur={(e) => e.target.value = NewNumFormart(orderCost)}
                            />
                        </div>
                        <span className="help-word ">
                            請輸入正確格式
                        </span>
                    </div>

                    <div className="form-item half">
                        <div className="field">訂單憑證</div>
                        <div className="value multi-inline">
                            <div className="fake-upload" data-fakeUpload>
                                <input type="file" id="file" className="real" style={{ "display": "none" }} onChange={(e) => setSelectedFile(e.target.files[0])} />
                                <input type="text" className="fake" readonly="readonly" placeholder="請選擇檔案" />
                            </div>
                            <a className="btn btn-middle btn-border" onClick={getFile}>
                                <span>上傳</span>
                            </a>
                        </div>
                        <span className="help-word">
                            請輸入正確格式
                        </span>
                    </div>

                    <div className="form-item">
                        <div className="field">備註</div>
                        <div className="value">
                            <textarea name="" id="" cols="30" rows="10" value={remark} onChange={(e) => setRemark(e.target.value)}>

                            </textarea>
                        </div>
                        <span className="help-word">
                            請輸入正確格式
                        </span>
                    </div>


                    <div className="form-item btn-area sp">
                        <div className="left">

                        </div>
                        <div className="right">
                            <a className="btn btn-border" onClick={returnQry}>
                                <span>取消</span>
                            </a>
                            <a className="btn" onClick={submit}>
                                <span>新增</span>
                            </a>
                        </div>
                    </div>


                </form>
            </div>
            <div id="modal-success" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            新增成功
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={returnQry}><span>確認</span></a>
                        </div>
                    </div>

                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder" style={{ maxHeight: "300px", overflow: "auto" }} >
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}> <span>確認</span></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
}

export default SaleOrderNew;