import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_SettlementHome,
    API_POST_SettlementLock
} from "../../global/constants";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal, InitJS, MenuSet } from "../../global/ToolUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
const SettlementLockQry = () => {
    var history = useHistory();

    useEffect(() => {
        //把menu顏色置換
        MenuSet("SettlementQry");
        getData();
        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);

    function getData() {
        API_GET_SettlementHome()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //負責公司
                    setCompanyArray(data.result.data.companyArray);
                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }

            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }


    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyId, setCompanyId] = useState();

    const [companyError, setCompanyError] = useState(false);

    //營收月份
    const [incomeMonth, setIncomeMonth] = useState();
    const [dateArray, setDateArray] = useState([]);
    const [modalStr, setModalStr] = useState();

    function submit() {
        console.log(incomeMonth);

        if (companyId == undefined) {
            setCompanyError(true);
            return false;
        } else {
            setCompanyError(false);
        }

        history.push('/Main?path=SettlementAnalysisList&companyId=' + companyId + '&incomeMonth=' + incomeMonth);

    }

    function ClickCompany(companyId) {
        setCompanyId(companyId);

        API_GET_SettlementHome({
            companyId: companyId
        })
            .then((response) => response.data)
            .then((data) => {
                setDateArray(data.result.data.yearMonthList);

                if (data.result.data.yearMonthList.includes(data.result.data.incomeMonth)) {
                    setIncomeMonth(data.result.data.incomeMonth);
                }
                else {
                    setIncomeMonth(data.result.data.yearMonthList[0]);
                }
            });

    }


    return (
        <>
            <div className="content-body">
                <div className="title-area">
                    <div className="container">
                        <h3 className="fz-B has-border">查詢已月結鎖定</h3>
                    </div>
                </div>
                <div className="container">
                    <div className="form-container">
                        <div className={`form-item must ${companyError ? 'show-help' : ''}`}>
                            <div className="field">營收公司</div>
                            <div className="value">
                                {
                                    companyArray.map(x =>
                                        <div>
                                            <input type="radio" id={`cmp_${x.id}`} name="company" value={x.id} onClick={() => ClickCompany(x.id)} />
                                            <label htmlFor={`cmp_${x.id}`}>{x.name}</label>
                                        </div>
                                    )

                                }
                            </div>
                            <span className="help-word">請選擇營收公司</span>
                        </div>
                        <div className={`form-item ${(dateArray == null || dateArray.length == 0) ? 'disabled' : ''}`}>
                            <div className="field">營收月份</div>
                            <div className="value">
                                <select name="incomeMonth" id="incomeMonth" onChange={(e) => setIncomeMonth(e.target.value)} disabled={dateArray == null || dateArray.length == 0} >
                                    {
                                        dateArray.map((x) => (
                                            <option value={x} selected={x == incomeMonth ? "selected" : ""}> {x} </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="help-word">請輸入訂單編號</div>
                        </div>

                        <div className="form-item btn-area sp">
                            <div className="left"></div>
                            <div className="right">
                                <a className={`btn ${dateArray == null || dateArray.length == 0 ? "disabled" : ""}`} onClick={submit}>
                                    <span>確定</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SettlementLockQry;
