import React, { useState, useEffect } from "react";

const NotifyItem = ({ nid, ntext, ncount, date, setType, isCloudPM, isCustPM, isProductPM, isVendorPM, isInvoicePM }) => {
    ////console.log(nid, ntext, ncount, date);
    function openModal(text) {
        var index = 0;
        var type = 0;
        if (text.indexOf("產品資料") >= 0) {
            index = 6;
            if (text.indexOf("退回") >= 0 && isProductPM == 1) {
                type = 4;
            }
        }
        if (text.indexOf("客戶資料") >= 0) {
            index = 7;
            if (text.indexOf("退回") >= 0 && isCustPM == 1) {
                type = 4;
            }
        }
        if (text.indexOf("供應商資料") >= 0) {
            index = 8;
            if (text.indexOf("退回") >= 0 && isVendorPM == 1) {
                type = 4;
            }
        }
        if (text.indexOf("雲端帳號") >= 0) {
            index = 11;
            if (text.indexOf("退回") >= 0 && isCloudPM == 1) {
                type = 4;
            }
        }
        if (text.indexOf("發票") >= 0) {
            index = 4;
            if (text.indexOf("退回") >= 0 && isInvoicePM == 1) {
                type = 4;
            }
        }
        if (text.indexOf("撤銷") >= 0) {
            type = 2;
        } else if (text.indexOf("通過") >= 0) {
            type = 3;
        }
        if (text.indexOf("帳務沖銷") >= 0) {
            index = 5;
            if (text.indexOf("被撤銷") >= 0) {
                type = 5;
                setType('5');
            }else if (text.indexOf("出納") < 0) {
                type = 1;
                setType('1');
            } 
            else {
                type = 2;
                setType('2');
            }
            if (text.indexOf("退回") >= 0) {
                type = 4;
            } else if (text.indexOf("通過") >= 0) {
                type = 3;
            }
        }

        var id = "modal-list-" + index + "-" + type;
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    return (
        <div>
            <a href="#" className="notice-item">
                <h6>{ntext}</h6>
                <div className="fn">
                    <span className="date">{date}</span>
                    <div className="btn btn-small" onClick={(e) => openModal(ntext)}>
                        <span>前往查看</span>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default NotifyItem;
