import React, { useState, useEffect } from "react";
import '../css/style.css';
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
const Error2 = () => {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        document.body.classList.add('login');
        document.body.classList.remove('dashboard');
        document.getElementById('root').classList.remove('center');
        var type = queryParams.get('type');
        if (type == "2") {
            setMsgStr('您的帳號已停用');
        } else {
            setMsgStr('授權失敗');
        }
    }, []);
    const [msgStr, setMsgStr] = useState("");
    return (
        <div className="full center">
            <div className="login-box">
                <div className="subtitle">
                    <i className="icon icon-31"></i>
                    <h1 className="fz-hero">
                        <FormattedMessage id="systemName" /> {/*系統名稱*/}
                    </h1>
                </div>
                <div className="form-container" style={{ height: "80%" }}>
                    Sorry,{msgStr}
                </div>
                <div className="form-item short btn-area">
                    <a className="btn full btn-dark" >
                        <span>XXX</span>
                    </a>
                </div>
            </div >
        </div >
    );
};
export default Error2;