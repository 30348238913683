import React, { useState, useEffect } from "react";
import PermissionBtn from "../Dashboard/PermissionBtn";
import { useHistory } from "react-router-dom";
import { CheckResponseCode } from '../../global/ResponseUtil';
import { API_GET_HomeData } from "../../global/constants";
const PermissionPanel = () => {
    const history = useHistory();
    let menuArray = [];
    let menuArrayLen = 0;
    if (checkgetLogin(localStorage.getItem("islogin"))) {
        menuArray = JSON.parse(localStorage.getItem("menulist"));
        menuArrayLen = menuArray.filter((item) => item.funcListType == "-1").length;
    } else {
        localStorage.clear();
        history.push("/");
    }

    function checkgetLogin(data) {
        if (data.length != 1) {
            return false;
        }
        if (data == "1") {
            return true;
        } else {
            return false;
        }
    }

    //重新抓取資料
    function refresh() {
        var fromOkta_ = (localStorage.getItem("post_logout_redirect_uri") != null ? 1 : 0);
        API_GET_HomeData({ fromOkta: fromOkta_ })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    localStorage.setItem("token", data.token.token);
                    localStorage.setItem(
                        "pagelist",
                        JSON.stringify(data.result.pagedata)
                    );
                    localStorage.setItem(
                        "menulist",
                        JSON.stringify(data.result.menudata)
                    );
                    localStorage.setItem(
                        "authctrl",
                        JSON.stringify(data.result.authdata)
                    );
                    localStorage.setItem("hasManyADAcnt", data.result.hasManyADAcnt);
                    localStorage.setItem("notify", JSON.stringify(data.result.notify));
                    history.push("/Dashboard");
                } else if (data.code == "1001") {
                    localStorage.clear();
                    setMsgStr(data.message);
                    history.push("/");
                }
            })

    }
    return (
        <>
            {
                localStorage.getItem('hasManyADAcnt') == 1 ?
                    <div style={{ textAlign: "center", 'padding': '10px' }}>
                        後台有多筆您的帳號，請洽權限管理員處理合併或捨棄原帳號
                    </div>
                    :
                    <>
                        <section className="section for-admin">
                            {menuArray.filter((item) => item.funcListType == "-1")
                                .map((x) => (
                                    <PermissionBtn
                                        menuid={x.menuID}
                                        targetHref={x.menuDUrl}
                                        name={x.menuCodeName}
                                        key={x.menuID}
                                    />
                                ))}
                            {menuArrayLen === 1 ? (
                                <a href={void (0)} className="btn btn-large" onClick={() => history.push('/UserManage')}  >
                                    <span>管理使用者帳號</span>
                                </a>
                            ) : null}
                        </section>

                        <section className="section">
                            {menuArray
                                .filter((item) => item.funcListType == "0")
                                .map((x) => (
                                    <PermissionBtn
                                        menuid={x.menuID}
                                        targetHref={x.menuDUrl}
                                        name={x.menuCodeName}
                                        key={x.menuID}
                                    />
                                ))}
                        </section>

                        {menuArray.length === 0 ? (
                            <div c style={{ textAlign: "center" }}>
                                <br />
                                <br />
                                <div style={{ textAlign: "left", width: "fit-content", margin: "auto" }}>
                                    若您無法顯示功能選項，請向管理員確認您的權限設定，
                                    <br />
                                    或是按下方按鈕重新整理頁面
                                </div>
                                <br />
                                <br />
                                <a href="#" className="btn btn-large btn-border" onClick={refresh} style={{ borderRadius: "32px" }}>
                                    <span>重新整理</span>
                                </a>
                            </div>
                        ) : (
                            <hr />
                        )}
                        <section className="section">
                            {menuArray
                                .filter((item) => item.funcListType == "1")
                                .map((x) => (
                                    <PermissionBtn
                                        menuid={x.menuID}
                                        targetHref={x.menuDUrl}
                                        name={x.menuCodeName}
                                        key={x.menuID}
                                    />
                                ))}
                        </section>
                    </>

            }

        </>
    );
};

export default PermissionPanel;
