import "../../css/style.css";
import React, { useState } from "react";
import { API_Login } from "../../global/constants";
import { useHistory } from "react-router-dom";

function Loginform() {
    var history = useHistory();
    function showpwd() {
        if (document.getElementById("Tpwd").type === "password") {
            document.getElementById("Tpwd").type = "text";
            document.getElementById("eye").classList.add("active");
        } else {
            document.getElementById("Tpwd").type = "password";
            document.getElementById("eye").classList.remove("active");
        }
    }

    document.onkeydown = function (event) {
        var target, code, tag;
        if (!event) {
            event = window.event;
            code = event.keyCode;
            if (code == 13) {
                submit();
            }
        } else {
            target = event.target; //針對遵循w3c標準的瀏覽器
            code = event.keyCode;
            if (code == 13) {
                submit();
            }
        }
    };

    const [username, setUserName] = useState("");
    function usernameChange(e) {
        setUserName(e.target.value);
    }

    const [pwd, setpwd] = useState("");
    function pwdChange(e) {
        setpwd(e.target.value);
    }
    const [msgStr, setMsgStr] = useState("");
    const [userError, setUserError] = useState(false);
    const [userPwdError, setUserPwdError] = useState(false);
    const [tpwdError, setTpwdError] = useState(false);

    function submit() {
        if (username === "" || pwd === "") {
            alert("請輸入帳密");
            return false;
        }
        //console.log(username , pwd);
        API_Login({
            UserNo: username,
            Password: pwd,
        })
            .then((response) => response.data)
            .then((data) => {
                //console.log("使用者資料", data);
                setMsgStr("");
                if (data.code == "1025" || data.code == "1023") {
                    localStorage.setItem("token", data.token.token);
                    localStorage.setItem("user", data.result.loginData.userNo);
                    localStorage.setItem("userName", data.result.loginData.userNo + "(" + data.result.loginData.userName + ")");
                    localStorage.setItem("userID", data.result.loginData.userID);
                    localStorage.setItem("validRemainDays", data.result.validRemainDays);
                    history.push("?path=cpwdform");
                } else if (data.code == "0000") {

                    //判斷是否被停用，true:失效
                    if (data.result.loginData.userInvalid) {
                        history.push("?path=error&type=2");
                        return;
                    }

                    localStorage.setItem("token", data.token.token);
                    localStorage.setItem("user", data.result.loginData.userNo);
                    localStorage.setItem("userName", data.result.loginData.userNo + "(" + data.result.loginData.userName + ")");
                    localStorage.setItem("userID", data.result.loginData.userID);
                    localStorage.setItem("validRemainDays", data.result.validRemainDays);
                    history.push("?path=authenticator");
                    //getDashboard();
                }
                else if (data.code == "1099") {
                    window.location.href = "/ErrorInvalid";
                    return;
                }
                else if (data.code == "1002") {
                    setUserError(true);
                    setUserPwdError(false);
                    setTpwdError(false);
                } else if (data.code == "1020") {
                    setMsgStr(data.message);
                    setUserError(false);
                    setUserPwdError(true);
                    setTpwdError(true);
                } else {
                    setMsgStr(data.message);
                }

                //console.log(localStorage.getItem('menulist'));
            })

    }

    return (
        <div id="div_login">
            <form action="" className="form-container">
                <div className={`form-item lg ${userError == true ? 'show-help' : ''}`}>
                    <div className="field">
                        <i className="icon icon-45"></i>
                    </div>
                    <div className="value">
                        <input
                            type="text"
                            id="UserNo"
                            value={username}
                            onChange={usernameChange}
                            placeholder="請輸入帳號"
                            className={`${userError == true ? 'error' : ''}`}
                        />
                    </div>
                    <span className="help-word">帳號錯誤</span>
                </div>
                <div className={`form-item has-fn lg ${userPwdError == true ? 'show-help' : ''}`}>
                    <div className="field">
                        <i className="icon icon-46"></i>
                    </div>
                    <div className="value">
                        <div className="password">
                            <input
                                type="password"
                                id="Tpwd"
                                value={pwd}
                                onChange={pwdChange}
                                placeholder="請輸入密碼"
                                className={tpwdError ? 'error' : ''}
                            />
                            <i className="eye" id="eye" onClick={showpwd}></i>
                        </div>
                    </div>
                    <span className="help-word">密碼錯誤　　</span>
                </div>
                <div className="form-item">
                    <div className="notification">{msgStr}</div>
                </div>
                <div className="form-item short btn-area">
                    <a className="btn full btn-dark" onClick={submit}>
                        <span>登入</span>
                    </a>
                </div>
            </form>
        </div>
    );
}
export default Loginform;
