import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_SettlementAnalysis,
    API_POST_SettlementBilling2,
    API_GET_SettlementCostDetail,
    API_GET_AllCurrency,
    API_POST_SettlementLock,
    API_GET_DownLoadGetFile2,
    API_GET_HomeData,
    API_GET_SettlementV2_GrossProfitAyalysis,
    API_POST_SettlementV2_SecondStageBilling

} from "../../global/constants";
import { NumFormart, OpenModal, CloseModal, InitJS, MenuSet } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';
import FileDownloadJS from "js-file-download";
import HtmlParse from 'html-react-parser';

const SettlementAnalysisList = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);

    const [okta, setOkta] = useState();
    const [reGet, setReGet] = useState(false);

    const [companyId, setCompanyId] = useState();
    //公司
    const [companyName, setCompanyName] = useState();
    //幣別
    const [currencyName, setCurrencyName] = useState();
    //資料列
    const [dataList, setDataList] = useState([]);
    const [mCloseStatus, setMCloseStatus] = useState(1);
    const [modalStr, setModalStr] = useState();
    const [isAlreadyGetData, setIsAlreadyGetData] = useState(false);


    //幣別
    const [currencyArray, setCurrencyArray] = useState([{}]);


    const [productCostData, setProductCostData] = useState({});
    const [procureCostData, setProcureCostData] = useState([]);
    const [cloudCostData, setCloudCostData] = useState([]);

    const [costDetailHandling, setCostDetailHandling] = useState(false);

    const [costDetailTargetPruductName, setCostDetailTargetPruductName] = useState("");


    function getOkData() {
        var fromOkta_ = (localStorage.getItem("post_logout_redirect_uri") != null ? 1 : 0);
        API_GET_HomeData({ fromOkta: fromOkta_ }).then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                setOkta(data.result);
            })
            .catch(err => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //出帳2再次確認
    function openModalBilling2_2() {
        CloseModal("modal-billing2");
        OpenModal("modal-billing2_2");
    }

    //出帳2
    function Billing2() {
        CloseModal("modal-billing2_2");
        //API 3-6
        API_POST_SettlementV2_SecondStageBilling({
            companyId: parseInt(queryParams.get("companyId")),
            billingYearMonth: queryParams.get("incomeMonth")
        })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    console.table(data);

                    let dwnPath = data.result;
                    FileDownload(dwnPath)

                    setReGet(true);

                    setModalStr("出帳2成功");
                    OpenModal("modal-ok");

                } else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
    }

    async function FileDownload(fileName) {
        API_GET_DownLoadGetFile2({
            fileName: fileName,
        }).then((response) => {
            FileDownloadJS(response.data, fileName);
        })
    }

    function OpenModalCostDetail(productId, productTypeName, incomeYM) {
        setCostDetailHandling(true);
        setCostDetailTargetPruductName(productTypeName);

        API_GET_SettlementCostDetail({
            productId: productId,
            belongCompanyId: companyId,
            incomeYM: incomeYM
        })
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setProductCostData(data.result.data.productCostData);
                    setProcureCostData(data.result.data.procureCostData);
                    setCloudCostData(data.result.data.cloudCostData);

                    if (data.result.data.productCostData != null && data.result.data.productCostData.length != 0) {
                        OpenModal("modal-costdetail");
                    }

                    if (data.result.data.procureCostData != null && data.result.data.procureCostData.length != 0) {
                        OpenModal("modal-costdetail-procure");
                    }

                    if (data.result.data.cloudCostData != null && data.result.data.cloudCostData.length != 0) {
                        OpenModal("modal-costdetail-cloudCostData");
                    }
                }
                else if (data.code == "SC01") {
                    setModalStr(data.message);
                    OpenModal("modal-error-back-qry");
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }


                setCostDetailHandling(false);
            })
    }

    //鎖定
    function Lock() {
        CloseModal("modal-checklock");
        var dt = {
            companyId: parseInt(queryParams.get("companyId"))
        };
        API_POST_SettlementLock(dt)
            .then((response) => response.data)
            .then((data) => {
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    OpenModal("modal-lockok");
                }
                else if (data.code == "SC01") {
                    OpenModal("modal-lock1Error");
                }
                else if (data.code == "SC02") {
                    OpenModal("modal-lock2Error");
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
    }


    const handleGetData = () => {

        API_GET_SettlementV2_GrossProfitAyalysis({
            companyId: parseInt(queryParams.get("companyId")),
            incomeYM: queryParams.get("incomeMonth")
        })
            .then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //負責公司
                    setCompanyName(data.result.companyName);
                    //幣別
                    setCurrencyName(data.result.currencyName);
                    //
                    setDataList(data.result.data);
                    //
                    setMCloseStatus(data.result.mCloseStatus);
                    setIsAlreadyGetData(true);

                }
                else if (data.code == "SC01") {
                    setModalStr(data.message);
                    OpenModal("modal-error-back-qry");
                }
                else {
                    setModalStr(data.message);
                    OpenModal("modal-error");
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        API_GET_AllCurrency()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setCurrencyArray(data.result.data);
                }
            })
    }

    const handleLockBtnDisabled = () => {

        if (!isAlreadyGetData)
            return 'disabled';

        if (mCloseStatus == 2 && okta && okta.isMonth2 && okta.isMonth2 != 0) {
            return '';
        }
        else {
            return 'disabled';
        }
    }

    const handleSubmitBtnDisabled = () => {
        if (!isAlreadyGetData)
            return 'disabled';

        if (mCloseStatus != 3 && okta && okta.isMonth2 && okta.isMonth2 != 0) {
            return '';
        }
        else {
            return 'disabled';
        }
    }

    useEffect(() => {
        //把menu顏色置換
        MenuSet("SettlementQry");
        setCompanyId(parseInt(queryParams.get("companyId")));
        getOkData();
        handleGetData();

        const timeout = setTimeout(() => {
            InitJS();
        }, 300)
        return () => clearTimeout(timeout)

    }, []);

    useEffect(() => {
        if (reGet) {
            getOkData();
            handleGetData();
            setReGet(false);
        }
    }, [reGet]);

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container full">
                    <h3 className="fz-B has-border">毛利分析：{companyName}</h3>
                </div>
            </div>
            <div className="container full">
                <div className="info-holder">
                    <div className="left"></div>
                    <div className="right">幣別：{currencyName}</div>
                </div>
                <ul className="list-table">
                    <li className="lt-row lt-th">
                        <div className="flex-3">產品別</div>
                        <div className="flex-4">產品細項</div>
                        <div className="flex-3">供應商</div>
                        <div className="flex-2">營收</div>
                        <div className="flex-2">成本</div>
                        <div className="flex-2">毛利</div>
                        <div className="flex-1 jcfe">毛利率</div>
                        <div className="flex-4 fn jcfe"></div>
                    </li>
                    {
                        dataList == null || dataList.length == 0 ? null : dataList.map(x =>
                            <li className="lt-row">
                                <div className="flex-3">
                                    <div className="elips-1">{x.productTypeName}</div>
                                </div>

                                <div className="flex-4">
                                    <div className="elips-1">{x.productName2}</div>
                                </div>
                                <div className="flex-3">
                                    <div className="elips-1">{x.vendorName}</div>
                                </div>
                                <div className="flex-2">
                                    <div className="elips-1">{NumFormart(x.revenue, 2)}</div>
                                </div>
                                <div className="flex-2">
                                    <div className="elips-1">{NumFormart(x.cost, 2)}</div>
                                </div>
                                <div className="flex-2">
                                    <div className="elips-1">{NumFormart(x.grossProfit, 2)}</div>
                                </div>
                                <div className="flex-1 jcfe">
                                    <div className="elips-1">{NumFormart(x.grossProfitRate, 2)}%</div>
                                </div>

                                <div className="flex-4 fn jcfe">
                                    <div className="btn btn-small btn-border" onClick={(e) => history.push("/Main?path=SettlementProductList&companyId=" + companyId + "&productName2=" + x.productName2 + "&incomeMonth=" + queryParams.get("incomeMonth"))}>
                                        <span>營收明細</span>
                                    </div>
                                    <div className="btn btn-small btn-border" onClick={() => OpenModalCostDetail(x.productId, x.productName2, x.incomeYM)}>
                                        <span>成本明細</span>
                                    </div>
                                </div>
                            </li>

                        )
                    }

                </ul>

                <div className="form-container">
                    <div className="form-item btn-area sp">
                        <div className="left">
                            <a className={`btn ${handleLockBtnDisabled()}`} onClick={() => mCloseStatus == 2 ? OpenModal("modal-checklock") : null}>
                                <span>
                                    {mCloseStatus == 3 ? '已鎖定' : '鎖定'}
                                </span>
                            </a>
                        </div>
                        <div className="right">
                            <a className={`btn ${handleSubmitBtnDisabled()}`} onClick={() => OpenModal("modal-billing2")}>
                                <span>出帳 2 確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-billing2" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要執行「出帳2」？
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" data-close> <span>取消</span></a>
                            <a className="btn" onClick={openModalBilling2_2}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-billing2_2" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要執行「出帳2」？ <br />
                            確定後將不可修改!

                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-billing2_2")}> <span>取消</span></a>
                            <a className="btn" onClick={Billing2}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-costdetail" className="modal large" data-modal>
                {
                    productCostData == null ? null :
                        <div className="modal-container">
                            <div className="modal-header">
                                <h3 className="fz-B has-border">成本明細：{costDetailTargetPruductName}</h3>
                                <span className="close" onClick={() => CloseModal("modal-costdetail")}>
                                    <i className="icon icon-44"></i>
                                </span>
                            </div>
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-2">供應商</div>
                                        <div className="flex-2">產品別</div>
                                        <div className="flex-4">產品細項</div>
                                        <div className="flex-4">說明 1</div>
                                        <div className="flex-4">說明 2</div>
                                        <div className="flex-2">上期成本</div>

                                    </li>

                                    <li className="lt-row">
                                        <div className="flex-2">{productCostData.supplierName}</div>
                                        <div className="flex-2">
                                            {productCostData.productTypeName}
                                        </div>
                                        <div className="flex-4">{productCostData.productName2}</div>
                                        <div className="flex-4">
                                            <input type="text" value={productCostData.explanation1} disabled={true} />
                                        </div>
                                        <div className="flex-4">
                                            <input type="text" value={productCostData.explanation2} disabled={true} />
                                        </div>
                                        <div className="flex-2">{NumFormart(productCostData.lastTimeCost)}</div>

                                    </li>

                                    <li className="lt-row">
                                        <ul className="list-table nested">
                                            <li className="lt-row lt-th">
                                                <div className="flex-1"> </div>
                                                <div className="flex-2">幣別</div>
                                                <div className="flex-2">項目</div>
                                                <div className="flex-8">金額</div>
                                                <div className="flex-1"> </div>
                                            </li>
                                            <li className="lt-row aifs">
                                                <div className="flex-1"></div>
                                                <div className="flex-2">
                                                    <div className="form-item independent no-field">
                                                        <div className="field">請選擇幣別</div>
                                                        <div className="value">
                                                            <select className="radius" disabled={true}>
                                                                {currencyArray.map((x2) => (
                                                                    <option value={x2.currID} selected={productCostData.advanceReduceBalanceCurrId == x2.currID ? true : false}>
                                                                        {x2.currName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-item independent no-field">
                                                        <div className="field"></div>
                                                        <div className="value"></div>
                                                    </div>
                                                    <div className="form-item independent no-field">
                                                        <div className="field">請選擇幣別</div>
                                                        <div className="value">
                                                            <select className="radius" disabled={true}>
                                                                {currencyArray.map((x2) => (
                                                                    <option value={x2.currID} selected={productCostData.requisitionCostCurrCurrId == x2.currID ? true : false}>
                                                                        {x2.currName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-item independent no-field">
                                                        <div className="field"></div>
                                                        <div className="value"></div>
                                                    </div>
                                                    <div className="form-item independent no-field">
                                                        <div className="field">請選擇幣別</div>
                                                        <div className="value">
                                                            <select className="radius" disabled={true} >
                                                                {currencyArray.map((x2) => (
                                                                    <option value={x2.currID} selected={productCostData.estimatedCostCurrCurrId == x2.currID ? true : false}>
                                                                        {x2.currName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-2">
                                                    <ul className="col-table">
                                                        <li>預付轉成本</li>
                                                        <li>請款成本</li>
                                                        <li>預估成本</li>
                                                        <li>預付成本餘額</li>
                                                        <li className="space"></li>
                                                        <li>新增預付金 (供)</li>
                                                    </ul>
                                                </div>
                                                <div className="flex-8">
                                                    <ul className="col-table flex-3">
                                                        <li>
                                                            <input type="text" className="right" value={NumFormart(productCostData.advanceReduceBalance)} disabled={true} />
                                                        </li>
                                                        <li>
                                                            <input type="text" className="right" value={NumFormart(productCostData.requisitionCost)} disabled={true} />
                                                        </li>
                                                        <li>
                                                            <input type="text" className="right" value={NumFormart(productCostData.estimatedCost)} disabled={true} />
                                                        </li>
                                                        <li>
                                                            <input type="text" className="right" value={productCostData.advanceBalance} disabled="disabled" />
                                                        </li>
                                                        <li className="space"></li>
                                                        <li>
                                                            <input type="text" className="right" value={productCostData.addBalance} disabled={true} />
                                                        </li>
                                                    </ul>
                                                    <ul className="col-table flex-5">
                                                        <li>  </li>
                                                        <li>  </li>
                                                        <li>
                                                            <div className="flex">
                                                                <div className="field right">本期成本</div>
                                                                <div className="value">
                                                                    <input type="text" disabled="disabled" value={NumFormart(productCostData.currCost)} />
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex">
                                                                <div className="field right">本期預付成本餘額</div>
                                                                <div className="value">
                                                                    <input type="text" value={NumFormart(productCostData.newAdvanceBalance)} disabled="disabled" />
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="space"></li>
                                                        <li>
                                                            <input type="text" value={productCostData.addBalanceExplanation} disabled={true} />
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="flex-1"></div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                }

            </div>

            <div id="modal-costdetail-procure" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">成本明細：{costDetailTargetPruductName}</h3>
                        <span className="close" onClick={() => CloseModal("modal-costdetail-procure")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="info-holder">
                        <div className="left"></div>
                        <div className="right">幣別：{currencyName}</div>
                    </div>
                    <div className="modal-body">
                        <ul className="list-table">
                            <li className="lt-row lt-th">
                                <div className="flex-3">採購單號</div>
                                <div className="flex-3">請購單號</div>
                                <div className="flex-4">客戶別</div>
                                <div className="flex-4">公司抬頭</div>
                                <div className="flex-3 right">採購金額(原幣)</div>
                                <div className="flex-3 right">採購金額</div>
                            </li>
                            {
                                procureCostData == null || procureCostData.length == 0
                                    ? null
                                    : procureCostData.map((x, i) =>
                                        <>
                                            <li className={`lt-row`}>
                                                {/* 採購單號 */}
                                                <div className="flex-3">
                                                    <a href={`/Main?path=PurchaseRequestEdit&purchaseId=${x.procId}`} target="_blank">{"[採購]"}<br />{x.procSignNo}</a>
                                                </div>

                                                {/* 請購單號 */}
                                                <div className="flex-3">
                                                    <a href={`/Main?path=PurchaseRequestEdit&purchaseId=${x.procId}`} target="_blank">{"[請購]"}<br />{x.salesProcSignNo}</a>
                                                </div>

                                                {/* 客戶別 */}
                                                <div className="flex-4">
                                                    <div className="">{x.custName}</div>
                                                </div>

                                                {/* 公司抬頭 */}
                                                <div className="flex-4">
                                                    <div className="">{x.custTitle}</div>
                                                </div>

                                                {/* 採購金額(原幣) */}
                                                <div className="flex-3 right">
                                                    <div className="">{x.procCurrName} {NumFormart(x.procAmtCurr, 2)}</div>
                                                </div>

                                                {/* 採購金額 */}
                                                <div className="flex-3 right">
                                                    <div className="">{NumFormart(x.procAmt, 2)}</div>
                                                </div>
                                            </li>
                                        </>
                                    )
                            }
                        </ul>
                    </div>
                </div>
            </div>

            <div id="modal-costdetail-cloudCostData" className="modal large" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">成本明細：{costDetailTargetPruductName}</h3>
                        <span className="close" onClick={() => CloseModal("modal-costdetail-cloudCostData")}>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="info-holder">
                        <div className="left"></div>
                        <div className="right">幣別：{currencyName}</div>
                    </div>
                    <div className="modal-body">
                        <ul className="list-table">
                            <li className="lt-row lt-th">
                                <div className="flex-3">訂單編號</div>
                                <div className="flex-4">客戶別</div>
                                <div className="flex-4">公司抬頭</div>
                                <div className="flex-3 right">成本(原幣)</div>
                                <div className="flex-3 right">成本</div>
                            </li>
                            {
                                cloudCostData == null || cloudCostData.length == 0
                                    ? null
                                    : cloudCostData.map((x, i) =>
                                        <>
                                            <li className={`lt-row`}>
                                                {/* 訂單編號 */}
                                                <div className="flex-3">
                                                    <a href={`/Main?path=SaleOrderEdit&orderId=${x.orderId}`} target="_blank">{x.orderNumber}</a>
                                                </div>

                                                {/* 客戶別 */}
                                                <div className="flex-4">
                                                    <div className="">{x.custName}</div>
                                                </div>

                                                {/* 公司抬頭 */}
                                                <div className="flex-4">
                                                    <div className="">{x.custTitle}</div>
                                                </div>

                                                {/* 成本(原幣) */}
                                                <div className="flex-3 right">
                                                    <div className="">{x.procCurrName} {x.orderPriceCurr}</div>
                                                </div>

                                                {/* 成本 */}
                                                <div className="flex-3 right">
                                                    <div className="">{x.orderPrice}</div>
                                                </div>
                                            </li>
                                        </>
                                    )
                            }
                        </ul>
                    </div>
                </div>
            </div>

            <div id="modal-checklock" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            確定要執行「鎖定」？ <br />
                            確定後將不可修改!

                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-checklock")}> <span>取消</span></a>
                            <a className="btn" onClick={Lock}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-lock1Error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            有訂單尚未出帳1導致無法鎖定 <br />
                            請進入月結查詢功能確認!

                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={(e) => {
                                history.push("/Main?path=SettlementQry");
                            }}> <span>確定</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-lock2Error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">
                            有訂單尚未出帳2導致無法鎖定 <br />
                            點擊確定系統將刷新頁面取得資料!

                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={(e) => {
                                CloseModal("modal-lock2Error");
                                setReGet(true);
                            }}> <span>確定</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-lockok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            已完成鎖定
                        </div>
                        <div className="btn-holder">
                            <a className="btn btn-border" onClick={() => CloseModal("modal-lockok")}> <span>取消</span></a>
                            <a className="btn" onClick={() => history.push("/Main?path=SettlementQry")}> <span>確認</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr == null ? '' : HtmlParse(modalStr)}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                <span>確定</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-error-back-qry" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr == null ? '' : HtmlParse(modalStr)}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => {
                                CloseModal("modal-error-back-qry")
                                history.push("/Main?path=SettlementQry");
                            }}>
                                <span>確定</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-ok" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-ok")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettlementAnalysisList;
