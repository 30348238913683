import React, { useState, useEffect } from "react";
import {
    API_GET_AdNameList,
    API_POST_CustomerData,
    API_GET_Customer_dataCard,
    API_GET_CustomerData,
    API_POST_CustomerDownLoad
} from "../../global/constants";
import { Clone, UrlDecode, CloseModal, OpenModal, InitJS, SetOnbeforeunload, MenuSet, SetHasChange } from "../../global/ToolUtil";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { CheckResponseCode } from '../../global/ResponseUtil';

const CustomerNew = () => {
    var history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    var onbeforeunloadFlag = false;
    const [getcustomerid, setGetCustomerID] = useState(null);

    useEffect(() => {
        //把menu顏色置換
        MenuSet("Customer");
        //從雲端帳號超連結過來的要關掉那個menu
        if (document.getElementById("menuitem-CloudAcntQry")) {
            document.getElementById("menuitem-CloudAcntQry").classList.remove("active");
        }
        if (queryParams.get('cName') != null && queryParams.get('cName') != '') {
            getdata();
        } else {
            getdataCard();
        }

        const timeout = setTimeout(() => {
            InitJS();
            SetOnbeforeunload();
        }, 300)
        return () => clearTimeout(timeout)
    }, []);


    //聯絡人序號
    const [contactData, setContactData] = useState([
        { custSalesName: null, custSalesTel: null, custSalesMail: null },
    ]);
    function addContactData() {
        SetHasChange(true);

        setContactData(function (prev) {
            return [
                ...prev,
                { custSalesName: null, custSalesTel: null, custSalesMail: null },
            ];
        });
    }


    function contactDataChange(e, index, col) {

        setContactData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "custSalesName") {
                        item.custSalesName = e.target.value;
                    } else if (col == "custSalesMail") {
                        item.custSalesMail = e.target.value;
                    } else if (col == "custSalesTel") {
                        item.custSalesTel = e.target.value;
                    }

                }
                return item;
            });
        });
    }

    //銀行序號
    const [bankData, setBankData] = useState([
        //{
        //    CustBankName: null,
        //    CustBankBranch: null,
        //    CustBankAct: null,
        //    custBankCurrencies: null,
        //},
    ]);
    function addBankData() {
        SetHasChange(true);

        setBankData(function (prev) {
            return [
                ...prev,
                { custBankName: null, custBankAct: null, custBankBranch: null },
            ];
        });
    }
    function bankDataChange(e, index, col) {

        setBankData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "custBankName") {
                        item.custBankName = e.target.value;
                    } else if (col == "custBankAct") {
                        item.custBankAct = e.target.value;
                    } else if (col == "custBankBranch") {
                        item.custBankBranch = e.target.value;
                    }

                }
                return item;
            });
        });
    }


    //帳務聯絡人
    const [accContactData, setAccContactData] = useState([
        { custActName: null, custActTel: null, custActMail: null },
    ]);
    function addAccContactData() {
        SetHasChange(true);

        setAccContactData(function (prev) {
            return [
                ...prev,
                { id: null, custActName: null, custActTel: null, custActMail: null },
            ];
        });
    }
    function accContactDataChange(e, index, col) {

        setAccContactData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    if (col == "custActName") {
                        item.custActName = e.target.value;
                    } else if (col == "custActTel") {
                        item.custActTel = e.target.value;
                    } else if (col == "custActMail") {
                        item.custActMail = e.target.value;
                    }

                }
                return item;
            });
        });
    }


    //隸屬公司
    const [companyArray, setCompanyArray] = useState([{}]);
    const [companyIdArray, setCompanyIdArray] = useState([]);
    function companyIdArrayChange() {
        var has01 = false;
        var has23 = false;
        var tmpArray = [];
        var f721 = document.getElementsByName("f721");
        for (var i = 0; i < f721.length; i++) {
            if (f721[i].checked) {
                tmpArray.push(parseInt(f721[i].value));
                if (i == 0 || i == 1) {
                    has01 = true;
                }
                if (i == 2 || i == 3) {
                    has23 = true;
                }
            }
        }
        if (has01 && has23) {
            setCustChargeOffAcc("");
        } else {
            if (has01) {
                setCustChargeOffAcc("8851+24359007");
            }
            else if (has23) {
                setCustChargeOffAcc("8851+54995834");
            }
        }
        setCompanyIdArray(tmpArray);
    }
    //客戶產業Array
    const [custIndustryArray, setCustIndustryArray] = useState([{}]);
    const [custINDID, setCustINDID] = useState();
    //交易幣別Array
    const [currencyBaseArray, setCurrencyBaseArray] = useState([{}]);
    //交易幣別
    const [currencyID, setCurrencyID] = useState();
    //開畫面被選擇的幣別
    const [currencyData, setCurrencyData] = useState([]);
    function currencyDataChange() {

        var tmpArray = [];
        var f727 = document.getElementsByName("f727");
        for (var i = 0; i < f727.length; i++) {
            if (f727[i].checked) {
                tmpArray.push({ currID: parseInt(f727[i].value) });
            }
        }
        //console.log(tmpArray);
        setBankData(function (prev) {
            return prev.map((item, i) => {
                if (i == currencyIndex) {
                    item.custBankCurrencies = tmpArray;
                }
                return item;
            });
        });
    }
    //陣列幾
    const [currencyIndex, setCurrencyIndex] = useState();
    //國家Array
    const [countryArray, setCountryArray] = useState([{}]);
    const [countryID, setCountryID] = useState([{}]);
    const [taxIDrequired, setTaxIDrequired] = useState(false);
    const [bankPassrequired, setBankPassrequired] = useState(false);
    function countryChange(e) {

        //setCountryID(e.target.value);
        if (e.target.value == "0") {
            setTax("應稅");
            setTaxIDrequired(true);
            setBankPassrequired(true);
            setInvoice("發票");
        } else {
            setTax("免稅");
            setInvoice("Invoice");
            setTaxIDrequired(false);
            setBankPassrequired(false);
        }
    }
    //客戶類別Array
    const [custTypeArray, setCustTypeArray] = useState([{}]);
    const [custTypeID, setCustTypeID] = useState();
    //業務單位
    const [departmentArray, setDepartmentArray] = useState([{}]);
    const [cmpDivID, setCmpDivID] = useState();
    //付款條件
    const [paymentTermsArray, setPaymentTermsArray] = useState([{}]);
    const [custPayID, setCustPayID] = useState();
    //是否ICP客戶
    const [custIsICP, setCustIsICP] = useState();
    //地址
    const [custAddr, setCustAddr] = useState();
    //客戶別
    //const [custNameArray, setCustNameArray] = useState([{}]);
    //客戶簡稱
    const [custNickName, setCustNickName] = useState("");
    function custNickNameChange(e) {

        setCustNickName(e.target.value);
    }
    //公司統編
    const [custTaxID, setCustTaxID] = useState("");
    function custTaxIDChange(e) {
        var v = e.target.value.replace(/[^A-Za-z0-9]/g, '');
        setCustTaxID(v);
        if (F726) {
            setCustBankPass(v);
        }
    }
    //客戶別
    const [custName, setCustName] = useState("");
    function custNameChange(e) {
        setCustName(e.target.value);
    }
    //客戶Number
    const [custNumber, setCustNumber] = useState();
    //客戶抬頭
    const [custTitle, setCustTitle] = useState("");
    function custTitleChange(e) {

        setCustTitle(e.target.value);
    }
    //銷帳帳號
    const [custChargeOffAcc, setCustChargeOffAcc] = useState("8851+24359007");
    function custChargeOffAccChange(e) {

        setCustChargeOffAcc(e.target.value);
    }
    const [tax, setTax] = useState("應稅");
    //對帳單寄送
    const [custIsSend, setCustIsSend] = useState();
    //雲端明細寄送
    const [cloudSend, setCloudSend] = useState();

    //對帳單密碼
    const [custBankPass, setCustBankPass] = useState("");

    function custBankPassChange(e) {

        setCustBankPass(e.target.value);
    }
    const [adeNameArray, setADENameArray] = useState([{}]);
    const [adeName, setAdeName] = useState();
    function adeNameChange(e) {

        setAdeName(e.target.value);
        getADEName();
    }
    const [adeID, setAdeID] = useState();
    const [Invoice, setInvoice] = useState();
    const [F726, setF726] = useState(false);
    const [modalStr, setModalStr] = useState();
    function F726change(e) {

        if (F726) {
            setF726(false);
            document.getElementById("custBankPass").disabled = false;
        } else {
            setF726(true);
            document.getElementById("custBankPass").disabled = true;
            setCustBankPass(custTaxID);
        }
    }


    function getADEName() {
        if (document.getElementById("adeNameText").value == "") {
            return false;
        }

        API_GET_AdNameList({
            input: document.getElementById("adeNameText").value,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    setADENameArray(data.result.data);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    history.push("/");
                }
            });
    }
    //複製
    function getdata() {

        var dt = {
            custID: queryParams.get("custID"),
            companyId: queryParams.get("companyId"),
            CustName: queryParams.get("cName"),
            reqFrmID: queryParams.get("ReqFrmID"),
        };
        //console.log(JSON.stringify(dt));
        API_GET_CustomerData(dt)
            .then((response) => response.data)
            .then((data) => {
                console.log("新增複製客戶資料", data);
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                //負責公司
                setCompanyArray(data.result.dataCard.companyArray);
                //客戶產業Array
                setCustIndustryArray(data.result.dataCard.customerIndustriesArray);
                //交易幣別Array
                setCurrencyBaseArray(data.result.dataCard.currencyArray);
                //國家Array
                setCountryArray(data.result.dataCard.countryArray);
                //幣別
                setCurrencyBaseArray(data.result.dataCard.currencyArray);
                //業務單位
                setDepartmentArray(data.result.dataCard.departmentArray);
                //客戶別
                setCustTypeArray(data.result.dataCard.customerTypeArray);
                //付款條件
                setPaymentTermsArray(data.result.dataCard.paymentTermsArray);
                //--------------------------------------//
                setCompanyIdArray(data.result.companyIdArray);
                //交易幣別
                setCurrencyID(data.result.currID);
                //公司統編/ 編號
                setCustTaxID(data.result.custTaxID);
                //國家
                setCountryID(data.result.cutID);
                if (data.result.cutID == "0") {
                    setTax("應稅");
                    setInvoice("發票");
                    setTaxIDrequired(true);
                    setBankPassrequired(true);
                } else {
                    setTax("免稅");
                    setInvoice("Invoice");
                    setTaxIDrequired(false);
                    setBankPassrequired(false);
                }
                //setTitleName(data.result.custName);
                //客戶別
                setCustName(data.result.custName);
                //客戶Number
                setCustNumber(data.result.custNumber);
                //客戶類別
                setCustTypeID(data.result.custTypeID);
                //公司抬頭
                setCustTitle(data.result.custTitle);
                //客戶產業
                setCustINDID(data.result.custINDID);
                //客戶地址
                setCustAddr(data.result.custAddress);
                //簡稱
                setCustNickName(data.result.custNickName);
                //對帳單密碼是否與統編相同
                setF726(data.result.custIsBankTxtID);
                if (data.result.custIsBankTxtID == true) {
                    document.getElementById("custBankPass").disabled = true;
                }

                //銷帳帳號
                setCustChargeOffAcc(data.result.custChargeOffAcc);
                //匯款銀行
                setBankData(data.result.custBanks);
                //對帳單密碼
                setCustBankPass(data.result.custBankPass);
                //負責人
                setAdeID(data.result.personADID);
                setAdeName(data.result.personInChargeName);
                //業務單位
                setCmpDivID(data.result.cmpDivID);
                //付款條件
                setCustPayID(data.result.custPayID);
                //ICP客戶
                setCustIsICP(data.result.custIsICP);
                //對帳單寄送
                setCustIsSend(data.result.custIsSend);
                //雲端明細寄送
                setCloudSend(data.result.custIsCloudDetail);
                //業務聯絡人
                setContactData(data.result.custSales);
                //帳務聯絡人
                setAccContactData(data.result.custAccounts);
                setAdeName(data.result.personInChargeName);
                document.getElementById("adeNameText").value = data.result.personInChargeName;

            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //一般新增
    function getdataCard() {

        API_GET_Customer_dataCard({})
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    history.push("/");
                }
                if (!CheckResponseCode(data)) { return; } //檢查response code
                if (data.code == "0000") {
                    //負責公司
                    setCompanyArray(data.result.companyArray);
                    //客戶產業Array
                    setCustIndustryArray(data.result.customerIndustriesArray);
                    //交易幣別Array
                    setCurrencyBaseArray(data.result.currencyArray);
                    //國家Array
                    setCountryArray(data.result.countryArray);
                    //幣別
                    setCurrencyBaseArray(data.result.currencyArray);
                    //業務單位
                    setDepartmentArray(data.result.departmentArray);
                    //客戶別
                    setCustTypeArray(data.result.customerTypeArray);
                    //付款條件
                    setPaymentTermsArray(data.result.paymentTermsArray);
                }
            })
            .catch((err) => {

                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //刪除聯絡人
    function delContactItem(index) {

        setContactData(function (prev) {
            return prev.filter((x, i) => i != index);
        });
    }

    //要修改原本的資料,把ID變成NULL即可(希望不要說還要變回原本無法編輯的樣式)
    function changeContactItemDIV(index) {

        setContactData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.id = null;
                }
                return item;
            });
        });
    }
    //刪除匯款銀行
    function delBankItem(index) {

        setBankData(function (prev) {
            return prev.filter((x, i) => i != index);
        });
    }



    //刪除帳務聯絡人
    function delAccContactItem(index) {
        //console.log(index);
        //console.log(accContactData);
        setAccContactData(function (prev) {
            return prev.filter((x, i) => i != index);
        });
    }

    //要修改原本的資料,把ID變成NULL即可(希望不要說還要變回原本無法編輯的樣式)
    function changeAccContactItemDIV(index) {

        setAccContactData(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.id = null;
                }
                return item;
            });
        });
    }
    //打開幣別
    function openModal10(index) {
        setCurrencyIndex(index);
        //console.log(currencyIndex);
        var a = bankData[index].custBankCurrencies;
        if (a == null) a = [];
        var tmpb = [];
        for (var i = 0; i < a.length; i++) {
            tmpb.push(a[i].currID);
        }

        var f727 = document.getElementsByName("f727");
        for (var i = 0; i < f727.length; i++) {
            if (tmpb.indexOf(parseInt(f727[i].value)) >= 0) {
                f727[i].checked = true;
            } else {
                f727[i].checked = false;
            }
        }
        OpenModal("modal-10");

    }
    const [isSameContactData, setIsSameContactData] = useState(false);
    function sameContactData() {
        //本來不是同客戶聯絡人,按了就要變成是了
        if (isSameContactData == false) {
            var tmpArray = [];
            setAccContactData([]);
            var tmpContactData = Clone(contactData);
            var tmpArray = [];
            for (var i = 0; i < tmpContactData.length; i++) {
                tmpArray.push({
                    id: null,
                    custActName: tmpContactData[i].custSalesName,
                    custActTel: tmpContactData[i].custSalesTel,
                    custActMail: tmpContactData[i].custSalesMail
                });
            }
            setAccContactData(tmpArray);
        }
        setIsSameContactData(!isSameContactData);
    }
    //資料檢查
    function checkData() {
        var result = true;

        const requiredlist = document.querySelectorAll('[data-required]');
        requiredlist.forEach((element) => {
            element.classList.remove("error");
        });

        const requiredTrueList = document.querySelectorAll('[data-required="true"]');
        requiredTrueList.forEach((element) => {
            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });

        if (companyIdArray.length == 0) {
            result = false;
            document.getElementById("companyDIV").classList.add("show-help");
        } else {
            document.getElementById("companyDIV").classList.remove("show-help");
        }
        var bf722 = false;
        var f722 = document.getElementsByName("f722");
        for (var i = 0; i < f722.length; i++) {
            if (f722[i].checked) {
                bf722 = true;
                break;
            }
        }
        if (bf722 == false) {
            document.getElementById("departmentDIV").classList.add("show-help");
            result = false;
        } else {
            document.getElementById("departmentDIV").classList.remove("show-help");
        }
        //付款條件
        var bf723 = false;
        var f723 = document.getElementsByName("f723");
        for (var i = 0; i < f723.length; i++) {
            if (f723[i].checked) {
                bf723 = true;
                break;
            }
        }
        if (bf723 == false) {
            document.getElementById("paymentTermsDIV").classList.add("show-help");
            result = false;
        } else {
            document.getElementById("paymentTermsDIV").classList.remove("show-help");
        }
        //ICP客戶
        var bf724 = false;
        var f724 = document.getElementsByName("f724");
        for (var i = 0; i < f724.length; i++) {
            if (f724[i].checked) {
                bf724 = true;
                break;
            }
        }
        if (bf724 == false) {
            document.getElementById("ICPDIV").classList.add("show-help");
            result = false;
        } else {
            document.getElementById("ICPDIV").classList.remove("show-help");
        }
        //對帳單寄送
        if (custIsSend == null) {
            document.getElementById("isSendDiv").classList.add("show-help");
            result = false;
        } else {
            document.getElementById("isSendDiv").classList.remove("show-help");
        }

        //雲端明細寄送
        if (cloudSend == null) {
            document.getElementById("isCloudSendDiv").classList.add("show-help");
            result = false;
        } else {
            document.getElementById("isCloudSendDiv").classList.remove("show-help");
        }

        for (var i = 0; i < bankData.length; i++) {
            if (bankData[i].custBankCurrencies == null || bankData[i].custBankCurrencies.length == 0) {
                if (document.getElementById("bank_" + i)) {
                    document.getElementById("bank_" + i).setAttribute("style", "border-color:#F37402;color:#F37402");
                }
                result = false;
            } else {
                if (document.getElementById("bank_" + i)) {
                    document.getElementById("bank_" + i).removeAttribute("style");
                }

            }
        }

        return result;
    }

    function GeneratePostData() {
        return {
            companyIdArray: companyIdArray,
            preCustName: custName,
            CustName: custName,
            CustTitle: custTitle,
            CustTaxID: custTaxID,
            CustNumber: custNumber,
            CustNickName: custNickName,
            CustTypeID: parseInt(document.getElementById("custType").value),
            CustINDID: parseInt(document.getElementById("custINDID").value),
            CutID: parseInt(document.getElementById("countryID").value),
            CurrID: parseInt(document.getElementById("currencyID").value),
            CustAddress: custAddr,
            PersonADID: parseInt(adeID),
            PersonADName: adeName,
            CmpDivID: parseInt(
                document.querySelector('input[name="f722"]:checked').value
            ),
            CustPayID: parseInt(
                document.querySelector('input[name="f723"]:checked').value
            ),
            CustIsICP:
                document.querySelector('input[name="f724"]:checked').value == "true"
                    ? true
                    : false,
            CustChargeOffAcc: custChargeOffAcc,
            CustIsSend: custIsSend,
            CustIsCloudDetail: cloudSend,
            CustBankPass: custBankPass,
            CustIsBankTxtID: document.querySelector('input[name="f726"]').checked,
            CustSales: contactData,
            CustBanks: bankData,
            CustAccounts: accContactData,
            fromCopy: queryParams.get('fromCopy') != undefined && queryParams.get('fromCopy') != null ? parseInt(queryParams.get('fromCopy')) : 0
        }
    }

    //確定修改
    function Edit(e) {
        e.target.classList.add("disabled");
        var result = checkData();
        if (result) {
            var dt = GeneratePostData();
            if (queryParams.get("custID")) {
                dt = { custID: queryParams.get("custID"), ...dt }
            }
            API_POST_CustomerData(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    if (!CheckResponseCode(data)) { return; } //檢查response code
                    setModalStr(data.message);
                    if (data.code == "0000" || data.code == "0001") {
                        e.target.classList.remove("disabled");
                        OpenModal("modal-add");
                        window.onbeforeunload = null;
                        onbeforeunloadFlag = false;
                        SetHasChange(false);
                    } else {
                        e.target.classList.remove("disabled");
                        OpenModal("modal-error");
                    }
                })
                .catch((err) => {

                    e.target.classList.remove("disabled");
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
            //console.log(body);
        } else {
            e.target.classList.remove("disabled");
        }
    }

    const FileDownload = require("js-file-download");
    function downLoad(e) {
        var result = checkData();
        if (result) {
            var dt = GeneratePostData();

            dt.DownloadFrom = 0;

            API_POST_CustomerDownLoad(dt).then((response) => {
                const disposition = response.headers['content-disposition'];
                var filename = disposition.split("''")[1];
                FileDownload(response.data, UrlDecode(filename));
            })
                .catch((err) => {
                    if (err.response.status === 401 || (err.response.data != null && err.response.data.code == "1001")) {
                        localStorage.clear();
                        history.push("/");
                    }

                    setModalStr(err.message);
                    OpenModal("modal-error");
                });
        }
    }

    function reflash() {
        window.location.reload();
    }
    function returnQry() {
        history.push("/Main?path=Customer");
    }
    const [tmpisSame, setTmpIsSame] = useState(0);
    //帳務聯絡人判斷同客戶聯絡人是否亮起
    useEffect(() => {
        if (accContactData.length != contactData.length) {
            setIsSameContactData(false);
            return false;
        }
        var isSame = true;
        for (var i = 0; i < accContactData.length; i++) {
            if (accContactData[i].custActName != contactData[i].custSalesName) {
                isSame = false;
                break;
            }
            if (accContactData[i].custActTel != contactData[i].custSalesTel) {
                isSame = false;
                break;
            }
            if (accContactData[i].custActMail != contactData[i].custSalesMail) {
                isSame = false;
                break;
            }
        }

        if (tmpisSame > 0) {
            setIsSameContactData(isSame);
        }
        setTmpIsSame(1);
    }, [contactData, accContactData]);

    return (
        <div className="content-body">
            <div className="title-area">
                <div className="container">
                    <h3 className="fz-B has-border">新增客戶資料</h3>
                </div>
            </div>
            <div className="container">
                <div className="form-container">
                    <div className="form-item must" id="companyDIV">
                        <div className="field">負責公司</div>
                        <div className="value">
                            {companyArray.map((x) => (
                                <div key={x.id}>
                                    <input
                                        type="checkbox"
                                        id={`721-${x.id}`}
                                        name="f721"
                                        value={x.id}
                                        onClick={companyIdArrayChange}
                                        checked={
                                            companyIdArray.findIndex((e) => e == x.id) >= 0
                                                ? true
                                                : false
                                        }
                                    />
                                    <label htmlFor={`721-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇負責公司</span>
                    </div>
                    <div className="form-item must half">
                        <div className="field">客戶別</div>
                        <div className="value">
                            <input
                                id="custNameText"
                                type="text"
                                value={custName}
                                onChange={custNameChange}
                                data-required
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item half">
                        <div className="field">客戶Number</div>
                        <div className="value">
                            <input type="text" value={custNumber} onChange={(e) => setCustNumber(e.target.value)} />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                        <span class="remark-word">
                            客戶共用公司抬頭時須填寫相同客戶Number
                        </span>
                    </div>
                    <div className="form-item must half">
                        <div className="field">公司抬頭</div>
                        <div className="value">
                            <input
                                type="text"
                                value={custTitle}
                                onChange={custTitleChange}
                                data-required
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className={`form-item half ${taxIDrequired ? 'must' : ''}`}>
                        <div className="field">公司統編/ 編號</div>
                        <div className="value">
                            <input
                                type="text"
                                maxlength="20"
                                id="custTaxID"
                                value={custTaxID}
                                onChange={custTaxIDChange}
                                maxLength="20"
                                data-required={taxIDrequired ? true : false}
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item must half">
                        <div className="field">客戶簡稱</div>
                        <div className="value">
                            <input
                                type="text"
                                value={custNickName}
                                onChange={custNickNameChange}
                                data-required
                            />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item must half">
                        <div className="field">客戶類別</div>
                        <div className="value">
                            <select name="custType" id="custType" data-required>
                                <option value="" selected disabled>請選擇</option>
                                {custTypeArray.map((x) => (
                                    <option value={x.id} selected={x.id == custTypeID ? true : false}>{x.name}</option>
                                ))}
                            </select>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item must half">
                        <div className="field">客戶產業</div>
                        <div className="value">
                            <select name="custINDID" id="custINDID" data-required>
                                <option value="" selected disabled>請選擇</option>
                                {custIndustryArray.map((x) => (
                                    <option value={x.id} selected={x.id == custINDID ? true : false}>{x.name}</option>
                                ))}
                            </select>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item must half">
                        <div className="field">隸屬國家</div>
                        <div className="value multi-inline">
                            <select name="countryID" id="countryID" onChange={countryChange} data-required >
                                <option value="" selected disabled>請選擇</option>
                                {countryArray.map((x) => (
                                    <option value={x.id} selected={x.id == countryID ? true : false}>{x.name}</option>
                                ))}
                            </select>
                            {/*<div className="info">*/}
                            {/*    <span>{tax}</span>*/}
                            {/*</div>*/}
                        </div>
                        <span className="help-word">
                            請輸入正確格式
                        </span>
                        <span class="remark-word">
                            發票應稅，Invoice免稅
                        </span>
                    </div>
                    <div className="form-item must half">
                        <div className="field">客戶地址</div>
                        <div className="value">
                            <input type="text" value={custAddr} onChange={e => setCustAddr(e.target.value)} data-required />
                        </div>
                        <span className="help-word">請選擇客戶地址</span>
                    </div>
                    <div className="form-item must half">
                        <div className="field">交易幣別</div>
                        <div className="value">
                            <select name="currencyID" id="currencyID" data-required>
                                <option value="" selected disabled>請選擇</option>
                                {currencyBaseArray.map((x) => (
                                    <option value={x.id} selected={x.id == currencyID ? true : false}>{x.name}</option>
                                ))}
                            </select>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    {/*<div className="form-item half">*/}
                    {/*    <div className="field">發票類別</div>*/}
                    {/*    <div className="value">*/}
                    {/*        <input type="text" value={Invoice} disabled="disabled" />*/}
                    {/*    </div>*/}
                    {/*    <span className="help-word">請輸入正確格式</span>*/}
                    {/*</div>*/}

                    <div className="form-item must half has-datalist" data-datalist>
                        <div className="field">負責業務</div>
                        <div className="value">
                            <input
                                id="adeNameText"
                                type="text"
                                onChange={adeNameChange}
                                autocomplete="off"
                                data-required
                            />
                            <ul className="datalist scrollbar">
                                {adeNameArray.map((x) => (
                                    <li data-value={`${x.enName}(${x.tc_name})`} onClick={(e) => { setAdeName(x.enName), setAdeID(x.id); }} >
                                        <span className="flex-5 bold">{x.enName} ({x.tc_name})</span>
                                        <span className="flex-5">{x.mail}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item must half" id="departmentDIV">
                        <div className="field">業務單位</div>
                        <div className="value">
                            {departmentArray.map((x) => (
                                <div>
                                    <input
                                        type="radio"
                                        id={`722-${x.id}`}
                                        name="f722"
                                        value={x.id}
                                        checked={x.id == cmpDivID ? true : false}
                                        onClick={() => setCmpDivID(x.id)}
                                    />
                                    <label htmlFor={`722-${x.id}`}>{x.name}</label>
                                </div>
                            ))}
                        </div>
                        <span className="help-word">請選擇業務單位</span>
                    </div>

                    <div className="form-item col must">
                        <div className="field">客戶聯絡人</div>
                        <div className="value">
                            <ul className="list-table">
                                <li className="lt-row lt-th">
                                    <div className="flex-1">序號</div>
                                    <div className="flex-3">姓名</div>
                                    <div className="flex-3">聯絡電話</div>
                                    <div className="flex-3">聯絡信箱</div>
                                    <div className="flex-2 fn"></div>
                                </li>
                                {
                                    contactData == null || contactData.length == 0 ? null :
                                        contactData.map((x, i) =>
                                            <li className="lt-row">
                                                <div className="flex-1">
                                                    {i < 11 ? `0${i + 1}` : i + 1}
                                                </div>
                                                <div className="flex-3">
                                                    <input
                                                        type="text"
                                                        value={x.custSalesName}
                                                        onChange={(e) => contactDataChange(e, i, "custSalesName")}
                                                        data-required
                                                    />
                                                </div>
                                                <div className="flex-3">
                                                    <input
                                                        type="tel"
                                                        value={x.custSalesTel}
                                                        onChange={(e) => contactDataChange(e, i, "custSalesTel")}
                                                        maxLength="255"
                                                        data-required
                                                    />
                                                </div>
                                                <div className="flex-3">
                                                    <input
                                                        type="email"
                                                        value={x.custSalesMail}
                                                        onChange={(e) => contactDataChange(e, i, "custSalesMail")}
                                                        data-required
                                                    />
                                                </div>
                                                <div className="flex-2 fn">
                                                    {
                                                        contactData.length <= 1 ? null :
                                                            <div className="btn btn-icon" onClick={() => delContactItem(i)}>
                                                                <i className="icon icon-37"></i>
                                                            </div>
                                                    }

                                                </div>
                                            </li>

                                        )}
                            </ul>
                            <div className="btn-area">
                                <div class="info"></div>
                                <div className="btn btn-small btn-border" onClick={addContactData} >
                                    <span>新增業務聯絡人</span>
                                </div>
                            </div>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <hr />

                    <div className="form-item must" id="paymentTermsDIV">
                        <div className="field">付款條件</div>
                        <div className="value">
                            {paymentTermsArray.map((x) => (
                                <div>
                                    <input
                                        type="radio"
                                        id={`f723-${x.id}`}
                                        name="f723"
                                        value={x.id}
                                        checked={custPayID == x.id ? true : false}
                                        onClick={() => setCustPayID(x.id)}
                                    />
                                    <label htmlFor={`f723-${x.id}`}>
                                        {" "}
                                        {x.name == "0" ? "預付" :
                                            x.name == "1" ? "即期" :
                                                x.name + "天"}
                                        {" "}
                                    </label>
                                </div>
                            ))}

                        </div>
                        <span className="help-word">請選擇付款條件</span>
                    </div>

                    <div className="form-item must half" id="ICPDIV">
                        <div className="field">ICP 客戶</div>
                        <div className="value">
                            <div>
                                <input type="radio" id="f724-1" name="f724" value="true" checked={custIsICP == true ? "checked" : ""} onClick={() => setCustIsICP(true)} />
                                <label for="f724-1">是</label>
                            </div>
                            <div>
                                <input type="radio" id="f724-2" name="f724" value="false" checked={custIsICP == false ? "checked" : ""} onClick={() => setCustIsICP(false)} />
                                <label for="f724-2">否</label>
                            </div>
                        </div>
                        <span className="help-word">請輸入ICP客戶</span>
                    </div>

                    <div className="form-item half">
                        <div className="field">銷帳帳號</div>
                        <div className="value">
                            <input type="text" value={custChargeOffAcc} onChange={custChargeOffAccChange} />
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item col">
                        <div className="field">匯款銀行</div>
                        <div className="value">
                            <ul className="list-table">
                                <li className="lt-row lt-th">
                                    <div className="flex-1">序號</div>
                                    <div className="flex-3">銀行名稱</div>
                                    <div className="flex-3">分行</div>
                                    <div className="flex-3">匯款帳號</div>
                                    <div className="flex-1 min-100">幣別</div>
                                    <div className="flex-2 fn"></div>
                                </li>
                                {bankData.length == 0 ? null :
                                    bankData.map((x, i) =>

                                        <li className="lt-row">
                                            <div className="flex-1">
                                                {i < 11 ? `0${i + 1}` : i + 1}
                                            </div>
                                            <div className="flex-3">
                                                <input type="text" value={x.custBankName} onChange={(e) => bankDataChange(e, i, "custBankName")} data-required />
                                            </div>
                                            <div className="flex-3">
                                                <input type="text" value={x.custBankBranch} onChange={(e) => bankDataChange(e, i, "custBankBranch")} placeholder="非必填" />
                                            </div>
                                            <div className="flex-3">
                                                <input type="text" value={x.custBankAct} onChange={(e) => bankDataChange(e, i, "custBankAct")} placeholder="非必填" />
                                            </div>
                                            <div className="flex-1 min-100">
                                                {
                                                    x.custBankCurrencies == null || x.custBankCurrencies.length == 0 ?
                                                        <div className="btn btn-small btn-border" id={`bank_${i}`} onClick={(e) => openModal10(i)}>
                                                            <span>選擇幣別</span>
                                                        </div>
                                                        :
                                                        <a href="#" onClick={(e) => openModal10(i)}>
                                                            {
                                                                x.custBankCurrencies.map((x2, i2) =>
                                                                    currencyBaseArray.map(x3 =>
                                                                        x3.id == x2.currID ? x3.name + (i2 == x.custBankCurrencies.length - 1 ? '' : '/') : null
                                                                    )
                                                                )
                                                            }
                                                        </a>

                                                }
                                            </div>
                                            <div className="flex-2 fn">


                                                <div className="btn btn-icon" onClick={() => delBankItem(i)}>
                                                    <i className="icon icon-37"></i>
                                                </div>



                                            </div>
                                        </li>

                                    )}
                            </ul>
                            <div className="btn-area">
                                <div class="info"></div>
                                <div className="btn btn-middle btn-border" onClick={addBankData} >
                                    <span>新增匯款銀行</span>
                                </div>
                            </div>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    <hr />
                    <div className="form-item must half auto-field-xl" id="isSendDiv">
                        <div className="field">對帳單寄送</div>
                        <div className="value">
                            <div>
                                <input type="radio" id="f725-1" name="f725-1" checked={custIsSend ? true : false} onClick={() => setCustIsSend(true)} />
                                <label htmlFor="f725-1">是</label>
                            </div>
                            <div>
                                <input type="radio" id="f725-2" name="f725-1" checked={custIsSend == false ? true : false} onClick={() => setCustIsSend(false)} />
                                <label htmlFor="f725-2">否</label>
                            </div>
                        </div>
                        <span className="help-word">請選擇對帳單寄送</span>
                    </div>

                    <div className="form-item must half auto-field-xl" id="isCloudSendDiv">
                        <div className="field" >雲端明細寄送</div>
                        <div className="value" >
                            <div>
                                <input type="radio" id="f725-3" name="f725-2" checked={cloudSend ? true : false} onClick={() => setCloudSend(true)} />
                                <label htmlFor="f725-3">是</label>
                            </div>
                            <div>
                                <input type="radio" id="f725-4" name="f725-2" checked={cloudSend == false ? true : false} onClick={() => setCloudSend(false)} />
                                <label htmlFor="f725-4">否</label>
                            </div>
                        </div>
                        <span className="help-word">請選擇雲端明細寄送</span>
                    </div>
                    <div className={`form-item half auto-field-xl `}>
                        <div className="field">對帳單密碼</div>
                        <div className="value multi-inline">
                            <input
                                type="text"
                                id="custBankPass"
                                value={custBankPass}
                                onChange={custBankPassChange}
                                data-required={false}
                            />
                            <div>
                                <input
                                    type="radio"
                                    id="f726-1"
                                    name="f726"
                                    value="true"
                                    onClick={F726change}
                                    checked={F726 == true ? true : false}
                                />
                                <label htmlFor="f726-1">與統編相同</label>
                            </div>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>

                    <div className="form-item col must">
                        <div className="field">
                            帳務聯絡人
                            <div>
                                <input type="radio" id="sameContactData" onClick={sameContactData} checked={isSameContactData ? true : false} />
                                <label htmlFor="sameContactData">同客戶聯絡人</label>
                            </div>
                        </div>
                        <div className="value">
                            <ul className="list-table">
                                <li className="lt-row lt-th">
                                    <div className="flex-1">序號</div>
                                    <div className="flex-3">姓名</div>
                                    <div className="flex-3">聯絡電話</div>
                                    <div className="flex-3">聯絡信箱</div>
                                    <div className="flex-2"></div>
                                </li>
                                {accContactData == null || accContactData.length == 0 ? null :
                                    accContactData.map((x, i) =>
                                        <li className="lt-row">
                                            <div className="flex-1">
                                                {i < 11 ? `0${i + 1}` : i + 1}
                                            </div>
                                            <div className="flex-3">
                                                <input type="text" value={x.custActName} onChange={(e) => accContactDataChange(e, i, "custActName")} data-required />
                                            </div>
                                            <div className="flex-3">
                                                <input type="tel" value={x.custActTel} onChange={(e) => accContactDataChange(e, i, "custActTel")} maxLength="255" data-required />
                                            </div>
                                            <div className="flex-3">
                                                <input type="email" value={x.custActMail} onChange={(e) => accContactDataChange(e, i, "custActMail")} data-required />
                                            </div>

                                            <div className="flex-2 fn">
                                                {
                                                    accContactData.length <= 1 ? null :
                                                        <div className="btn btn-icon" onClick={() => delAccContactItem(i)}>
                                                            <i className="icon icon-37"></i>
                                                        </div>
                                                }

                                            </div>
                                        </li>
                                    )}
                            </ul>
                            <div className="btn-area">
                                <div class="info"></div>
                                <div className="btn btn-small btn-border" onClick={addAccContactData} >
                                    <span>新增帳務聯絡人</span>
                                </div>
                            </div>
                        </div>
                        <span className="help-word">請輸入正確格式</span>
                    </div>
                    {getcustomerid != null ? (
                        <div className="form-item btn-area sp">
                            <div className="left">
                                <button className="btn btn-border">
                                    <span>產出客戶資料卡</span>
                                </button>
                            </div>
                            <div className="right">
                                <a className="btn btn-border">
                                    <span>刪除客戶</span>
                                </a>
                                <a className="btn" onClick={Edit}>
                                    <span>確定修改</span>
                                </a>
                            </div>
                        </div>
                    ) : (
                        <div className="form-item btn-area sp">
                            <div className="left">
                                <a className="btn btn-border" onClick={downLoad}>
                                    <span>產出客戶資料卡</span>
                                </a>
                            </div>
                            <div className="right">
                                <a className="btn btn-border" onClick={returnQry}>
                                    <span>取消</span>
                                </a>
                                <a className="btn" onClick={Edit}>
                                    <span>新增</span>
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div id="modal-10" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="fz-B has-border">選擇幣別</h3>
                        <span className="close" data-close>
                            <i className="icon icon-44"></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-item no-field">
                            <div className="field">選擇幣別</div>
                            <div className="value">
                                {currencyBaseArray.map((x) => (
                                    <div>
                                        <input
                                            type="checkbox"
                                            id={`f727-${x.id}`}
                                            name="f727"
                                            value={x.id}
                                            key={x.id}
                                            onClick={currencyDataChange}
                                        />
                                        <label for={`f727-${x.id}`}>{x.name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-10")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-add" className="modal small alert" data-modal data-mid="7.0.2">
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={reflash}>
                                {" "}
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-error" className="modal small alert" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-41"></i>
                        </div>
                        <div className="text-holder">{modalStr}</div>
                        <div className="btn-holder">
                            <a className="btn" onClick={() => CloseModal("modal-error")}>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerNew;
